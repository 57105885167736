import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import ApiService from 'app/services/config';
import moment from "moment";
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import WorkerColumn from "../agenda_prestador/WorkerColumn";

function Schedule({ prestadorId }) {
    const [expanded, setExpanded] = React.useState(false);
    const [users, setUsers] = useState([]);
    const [dateSelected, setDateSelected] = useState(
        moment().format("YYYY-MM-DD")
    );
    console.log('schedule worker', prestadorId)

    const [days, setDay] = useState([
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado"
      ]);
    
    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getUsers = React.useCallback(async () => {
        try {
        //   const name = userSelected?.selected?.nome;
        //   const storedUserId = localStorage.getItem('id');
    
        //   const id = location.pathname == '/schedule-worker-logged' ? storedUserId : null;
          console.log('getUsers id', id)
          let apiUrl = `/users/model?id=${prestadorId}`;
    
          await ApiService.get(apiUrl)
            .then((response) => {
              setUsers(response.data?.usersWithoutPassword);
            })
            .catch((error) => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("error", error.message);
              }
            });
        } catch (err) {
          console.log(err);
        }
      }, [setUsers]);

    const { id } = useParams();

    const { enqueueSnackbar } = useSnackbar();


    // Verifica se todos os campos do endereço estão vazios
    // const allFieldsEmpty = Object.values(address).every((value) => !value);


    React.useEffect(() => {
        getUsers();
      }, []);

    return (
        <div>
            <Grid container>
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '25px' }}
                >
                    Agenda
                </Typography>
            </Grid>

            <Paper
                style={{
                padding: "0 10px 0 0",
                marginBottom: "15px",
                overflowX: "auto",
                maxHeight: "650px",
                overflowY: "auto",
                }}
                square={false}
                elevation={3}
            >
                <Box display={"flex"} >
                {days?.map((worker, index) => (
                    <Box display={"flex"} flexDirection={"column"}>
                    <Paper
                        sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        }}
                        elevation={3}
                    >
                        <Typography
                        noWrap
                        variant="h3"
                        padding={2}
                        sx={{ textAlign: "center" }}
                        >
                        {worker}
                        </Typography>
                    </Paper>
                    <Box
                        sx={{
                        bgcolor: "background.paper",
                        borderRadius: "16px",
                        }}
                    >
                        {console.log(users)}
                        <WorkerColumn worker={users[0]} dateSelected={dateSelected} isDisabled={true} index={index} workerId={prestadorId} />
                    </Box>
                    </Box>
                ))}
                </Box>
            </Paper>
        </div>
    );
}

export default Schedule;
