import { useState, useEffect, useContext, createContext } from 'react'
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

export const PermissionContext = createContext();


export const PermissionProvider = ({children}) => {
    const {authToken, userPermissions} = useJumboAuth();

    const hasPermission = (permission, action) => {
      let hasPermission = false;
      userPermissions?.forEach((permissionObj) => {
        if (permissionObj.Pagina.nome === permission && permissionObj[action]) {
          hasPermission = true;
        }
      });
      return hasPermission;
    };
    


    return (
        <PermissionContext.Provider value={{ hasPermission }}>
          {children}
        </PermissionContext.Provider>
      );
}
