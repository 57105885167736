import React, { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useParams } from 'react-router-dom';
import ApiService from 'app/services/config';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FieldArray } from 'formik';
import { TelephoneItem } from 'app/components/TelephoneItem/TelephoneItem';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { FormHelperText } from '@mui/material';

const Phones = ({
    infos,
    values,
    setFieldValue,
    handleChange,
    telephone,
    setTelephone,
}) => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});
    const allFieldsEmpty = Object.values(telephone).every((value) => !value);

    const telephoneSchema = Yup.object().shape({
        tipo_de_telefone: Yup.string().required(
            'O tipo de telefone é obrigatório.'
        ),
        ddd: Yup.string().required('O DDD é obrigatório.'),
        numero: Yup.string().required('O número de telefone é obrigatório.'),
    });
    console.log(telephone);

    const handleEditTelephone = (telephoneToEdit) => {
        setTelephone(telephoneToEdit);
        setIsEditMode(true);
    };
    const modalAlert = (telephoneId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                console.log('bem bem antes', values.Telefones);
                removeTelephone(telephoneId);
            }
        });
    };

    const removeTelephone = useCallback(
        async (telephoneId) => {
            ApiService.delete(`/telephones/${telephoneId}`)
                .then((response) => {
                    const telephoneEdit = telephone.id === telephoneId;
                    const removedTelephone = values.Telefones.filter(
                        (tel) => tel.id !== telephoneId
                    );
                    setFieldValue('Telefones', removedTelephone);
                    enqueueSnackbar('Telefone removido com sucesso!', {
                        variant: 'success',
                        
                    });
                    if (telephoneEdit) {
                        setIsEditMode(false);
                        setTelephone({
                            // Limpa o estado do telefone
                            tipo_de_telefone: '',
                            ddd: '',
                            numero: '',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [enqueueSnackbar, setFieldValue, values.Telefones]
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '15px' }}
                >
                    Telefones
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <FormControl
                    fullWidth
                    variant='outlined'
                    error={!!errors.tipo_de_telefone}
                >
                    <InputLabel id='Telefones.tipo_de_telefone'>
                        Tipo de Telefone
                    </InputLabel>
                    <Select
                        name='Telefones.tipo_de_telefone'
                        label='Tipo de Telefone'
                        value={telephone.tipo_de_telefone}
                        onChange={(event) => {
                            setTelephone({
                                ...telephone,
                                tipo_de_telefone: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={'Residencial'}>Residencial</MenuItem>
                        <MenuItem value={'Comercial'}>Comercial</MenuItem>
                        <MenuItem value={'Movel'}>Móvel</MenuItem>
                    </Select>
                    {errors.tipo_de_telefone && (
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors.tipo_de_telefone}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <InputMask
                    mask='(99)'
                    value={telephone.ddd}
                    onChange={(event) => {
                        setTelephone({
                            ...telephone,
                            ddd: event.target.value,
                        });
                    }}
                >
                    {(inputProps) => (
                        <TextField
                            fullWidth
                            name='Telefones.ddd'
                            label='DDD'
                            error={!!errors.ddd}
                            helperText={errors.ddd}
                            {...inputProps}
                        />
                    )}
                </InputMask>
            </Grid>
            <Grid item xs={2}>
                <InputMask
                    mask='99999-9999'
                    value={telephone.numero}
                    onChange={(event) => {
                        setTelephone({
                            ...telephone,
                            numero: event.target.value,
                        });
                    }}
                >
                    {(inputProps) => (
                        <TextField
                            fullWidth
                            name='Telefones.telefone'
                            label='Telefone'
                            error={!!errors.telefone}
                            helperText={errors.telefone}
                            {...inputProps}
                        />
                    )}
                </InputMask>
            </Grid>
            <Grid item xs={4}>
                {id ? (
                    <Grid xs={6}>
                        <Button
                            variant='contained'
                            style={{ marginTop: '10px' }}
                            disabled={allFieldsEmpty}
                            onClick={() => {
                                telephoneSchema
                                    .validate(telephone, { abortEarly: false })
                                    .then(() => {
                                        setErrors({});
                                        if (isEditMode) {
                                            console.log(telephone);
                                            const telephoneId = telephone.id;

                                            ApiService.put(
                                                `/telephones/${telephoneId}`,
                                                telephone
                                            )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Telefone atualizado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    const updatedTodos =
                                                        values.Telefones.map(
                                                            (todo) => {
                                                                if (
                                                                    todo.id ===
                                                                    response
                                                                        .data.id
                                                                ) {
                                                                    return response.data; // Retorna o telefone atualizado
                                                                }
                                                                return todo;
                                                            }
                                                        );
                                                    setFieldValue(
                                                        'Telefones',
                                                        updatedTodos
                                                    ); // Atualiza a lista de telefones com o telefone atualizado
                                                    setIsEditMode(false); // Sai do modo de edição
                                                    setTelephone({
                                                        // Limpa o estado do telefone
                                                        tipo_de_telefone: '',
                                                        ddd: '',
                                                        numero: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        } else {
                                            const userTelephone = {
                                                user_id: Number(id),
                                                tipo_de_telefone:
                                                    telephone.tipo_de_telefone,
                                                ddd: telephone.ddd,
                                                numero: telephone.numero,
                                            };

                                            ApiService.post(
                                                `/telephones`,
                                                userTelephone
                                            )
                                                .then((response) => {
                                                    const newTelephone = [
                                                        ...values.Telefones,
                                                        response.data,
                                                    ];
                                                    enqueueSnackbar(
                                                        'Telefone criado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    setFieldValue(
                                                        'Telefones',
                                                        newTelephone
                                                    );
                                                    setTelephone({
                                                        tipo_de_telefone: '',
                                                        ddd: '',
                                                        numero: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        }
                                    })
                                    .catch((validationErrors) => {
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                enqueueSnackbar(error.message, {
                                                    variant: 'error',
                                                    
                                                });
                                            }
                                        );
                                        const errors = {};
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                errors[error.path] =
                                                    error.message;
                                            }
                                        );
                                        console.log(errors);
                                        setErrors(errors);
                                    });
                            }}
                        >
                            {isEditMode
                                ? 'Editar Telefone'
                                : 'Incluir Telefone'}
                        </Button>
                    </Grid>
                ) : undefined}
            </Grid>

            {id ? (
                <Grid item xs={12}>
                    <FieldArray name='Telefones'>
                        {({ remove, push }) =>
                            values?.Telefones?.map((tel) => (
                                <TelephoneItem
                                    telephone={tel}
                                    handleEditTelephone={handleEditTelephone}
                                    modalAlert={modalAlert}
                                />
                            ))
                        }
                    </FieldArray>
                </Grid>
            ) : undefined}
        </Grid>
    );
};

export default Phones;
