import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ApiService from 'app/services/config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { Box, IconButton, Tooltip, Zoom } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { formatDateWithMoment } from '@jumbo/utils';

export const QUIZZES_EVALUATIONS_IDS = [59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75];

const InterdisciplinaryAssessment = () => {
    const navigate = useNavigate();
    let { state } = useLocation();
    const { userId } = useParams();

    const [quizzes, setQuizzes] = useState([]);
    const [userQuizz, setUserQuizz] = useState([]);
    const [quizSelected, setQuizSelected] = useState({
        avaliacao: '',
        sensorial: '',
        fono: '',
        psicopedagogia: '',
    });
    const [evaluationSelected, setEvaluationSelected] = useState(null);

    const getQuizzes = useCallback(async () => {
        try {
            await ApiService.get(`/quizzes?userId=${userId}`)
                .then((response) => {
                    console.log('getQuizzes',response.data);

                    setQuizzes(response?.data?.quizzes);
                    setUserQuizz(response?.data?.respostasAgrupadas);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setQuizzes]);

    useEffect(() => {
        getQuizzes();
    }, [getQuizzes]);

    console.log('userQuizz', userQuizz);

    const columns = [
        {
            name: 'id',
            label: 'Id',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: 'respostas',
            label: 'Ficha',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const userId = tableMeta.rowData[0];
                    return value[0]?.Pergunta?.Questionario?.nome;
                },
            },
        },
        {
            name: 'horario',
            label: 'Data e horário',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const userId = tableMeta.rowData[0];
                    return formatDateWithMoment(value)
                },
            },
        },

        {
            name: 'action',
            label: 'Ações',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const quizId =
                        tableMeta.rowData[1][0]?.Pergunta?.Questionario?.id;
                    const queryData = data[tableMeta.rowIndex];
                    let urlQuizEdit = "";
                    let searchParams = "";
                    if(QUIZZES_EVALUATIONS_IDS.some(id => id === quizId)) {
                        urlQuizEdit = `/evaluation/${quizId}/patient/${userId}/edit`;
                        searchParams = `?date=${tableMeta.rowData[2]}`;
                    } else {
                        urlQuizEdit = `/sheet/${quizId}/user/${userId}/edit`;
                        searchParams = `?date=${queryData?.horario}`;
                    }
                    return (
                        <Box display={'flex'} gap={'1rem'}>
                            <Tooltip
                                title='Visualizar'
                                TransitionComponent={Zoom}
                            >
                                <Link
                                    to={{
                                        pathname: urlQuizEdit,
                                        search: searchParams,
                                    }}
                                    state={{ fromTab: 3 }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <IconButton
                                        aria-label='visualizar'
                                        color='info'
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
    ];

    const options = {
        /* changeRowsPerPage: perPage, */
        filterType: 'dropdown',
        filter: false,
        selectableRows: 'none',
        searchAlwaysOpen: true,
        searchable: true,
        /* serverSide: true,
        page: page,
        count: count, */
        selectToolbarPlacement: 'above',

        /* onTableChange: (action, tableState) => {
            console.log(action);
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'sort':
                    setSortBy(tableState.sortOrder.name);
                    setSortOrder(tableState.sortOrder.direction);
                    break;
                case 'search':
                    handleSearch(tableState.searchText);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log('action not handled.');
            }
        }, */
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'of',
            },
        },
    };

    const data = Object.entries(userQuizz).map(([horario, respostas]) => ({
        horario,
        respostas,
    }));

    return (
        <div>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Divider>Nova Ficha de Atendimento</Divider>
                </Grid>

                <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Checklist Denver Avaliação (nível 1 ao 4)
                        </InputLabel>
                        <Select
                            label='Checklist Denver Avaliação (nível 1 ao 4)'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                avaliacao: e.target.value,
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes(
                                    'Checklist Denver Avaliação'
                                ) ? (
                                    <MenuItem value={quiz.id}>
                                        {quiz.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.avaliacao}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.avaliacao}/user/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.avaliacao}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.avaliacao}/user/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                {/* <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Perfil sensorial
                        </InputLabel>
                        <Select
                            label='Perfil sensorial'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                sensorial: e.target.value,
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes(
                                    'Perfil Sensorial'
                                ) ? (
                                    <MenuItem value={quiz.id}>
                                        {quiz.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                           
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.sensorial}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.sensorial}/user/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.sensorial}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.sensorial}/user/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid> */}

                <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Avaliação Fonoaudiológica
                        </InputLabel>
                        <Select
                            label='Avaliação Fonoaudiológica'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                fono: e.target.value,
                            })}
                        >
                           
                            <MenuItem value={23}>Avaliação Fonoaudiológica</MenuItem>
                            <MenuItem value={59}>Avaliação de Fluência Infantil</MenuItem>
                            <MenuItem value={60}>Avaliação de Transtornos Motores da Fala</MenuItem>
                            <MenuItem value={63}>Avaliação Motora da Fala</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.fono}
                            onClick={() => {
                                if(QUIZZES_EVALUATIONS_IDS.some(id => id === quizSelected.fono)) {
                                    navigate(
                                        `/evaluation/${quizSelected.fono}/patient/${userId}`, {
                                            state: {
                                                fromTab: 3
                                            }
                                        }
                                    )
                                
                                } else {
                                    navigate(
                                        `/sheet/${quizSelected.fono}/user/${userId}`, {
                                            state: {
                                                fromTab: 3
                                            }
                                        }
                                    )
                                }
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    {/* <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.fono}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.fono}/user/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip> */}
                </Grid>

                <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Outras avaliações
                        </InputLabel>
                        <Select
                            label='Outras avaliações'
                            onChange={(e) => setEvaluationSelected(e.target.value)}
                        >
                            <MenuItem value={62}>Teste M-Chat</MenuItem>
                            <MenuItem value={64}>PROTEA-R</MenuItem>
                            <MenuItem value={65}>Questionário de Avaliação de Autismo (ASQ)</MenuItem>
                            <MenuItem value={66}>ATA - Escala de Avaliação de Traços Autísticos</MenuItem>
                            <MenuItem value={67}>CARS (Escala de Classificação de Autismo na Infância)</MenuItem>
                            <MenuItem value={68}>Checklist - Protocolo de Reabilitação Cognitiva</MenuItem>
                            <MenuItem value={69}>Vineland - Escala de Comportamento Adaptativo</MenuItem>
                            <MenuItem value={70}>Guia Portage de Educação Pré-Escolar - 0 a 1 ano</MenuItem>
                            <MenuItem value={71}>Guia Portage de Educação Pré-Escolar - 1 a 2 anos</MenuItem>
                            <MenuItem value={72}>Guia Portage de Educação Pré-Escolar - 2 a 3 anos</MenuItem>
                            <MenuItem value={73}>Guia Portage de Educação Pré-Escolar - 3 a 4 anos</MenuItem>
                            <MenuItem value={74}>Guia Portage de Educação Pré-Escolar - 4 a 5 anos</MenuItem>
                            <MenuItem value={75}>Guia Portage de Educação Pré-Escolar - 5 a 6 anos</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!evaluationSelected}
                            onClick={() => {
                                navigate(
                                    `/evaluation/${evaluationSelected}/patient/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!evaluationSelected}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${evaluationSelected}/user/${userId}`, {
                                        state: {
                                            fromTab: 3
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>

                </Grid>

                <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                        Avaliação Psicopedagogia
                        </InputLabel>
                        <Select
                        label='Avaliação Psicopedagogia'
                        onChange={(e) => setEvaluationSelected(e.target.value)}
                        >
                        <MenuItem value={61}>Avaliação Psicopedagogia</MenuItem>

                        {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                        variant='outlined'
                        style={{ marginLeft: '5px' }}
                        disabled={!evaluationSelected}
                        onClick={() => {
                            navigate(
                            `/evaluation/${evaluationSelected}/patient/${userId}`, {
                                state: {
                                    fromTab: 3
                                }
                            }
                        );
                        }}
                        >
                        <QuizIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={12}>
                    <Divider>Fichas de atendimentos existentes</Divider>
                </Grid>

                <Grid item xs={8}>
                    {/* espacinho ;P */}
                </Grid>

                <Grid item xs={4}>
                    <Button variant='outlined' style={{ marginLeft: '5px' }}>
                        Visualizar todas as fichas
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {/* <DataTable
                        title={'Visualizacao das ultimas 90 fichas'}
                        columns={columns}
                    /> */}
                    <MUIDataTable
                        title={'Visualizacao das fichas'}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default InterdisciplinaryAssessment;
