import React, { useCallback, useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import InputMask from "react-input-mask";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { updateInputValue } from "app/utils/appHelpers";
import { useParams } from "react-router-dom";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { FieldArray } from "formik";
import { RegistrationBoardItem } from "app/components/RegistrationBoardItem/RegistrationBoardItem";
import { Box, Button, FormHelperText } from "@mui/material";
import { AgreementAuthItem } from "app/components/AgreemenAuthtItem/AgreementAuthItem";
import CurrencyInput from "react-currency-input-field";

const style = {
  marginTop: 14,
  width: "100%",
  height: 50,
  padding: "10px 14px",
  fontSize: "1rem",
  borderRadius: 4,
  border: "1px solid #ced4da",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  color: "#475763",
};

const Authorization = ({
  infos,
  values,
  setFieldValue,
  agreementAuth,
  setAgreementAuth,
  handleChange,
  reqUserSelected,
  setReqUserSelected,
  infosSelected,
  setInfosSelected,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  const allFieldsEmpty = Object.values(agreementAuth).every((value) => !value);

  const agreementAuthSchema = Yup.object().shape({
    Convenio: Yup.object().shape({
      nome: Yup.string().required("Cartão Convênio é obrigatório"),
    }),
  });

  const getUsers = useCallback(async () => {
    try {
      await ApiService.get("/users")
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleEditAgreementAuth = (agreementAuthToEdit) => {
    console.log(agreementAuthToEdit);
    setAgreementAuth(agreementAuthToEdit);
    setInfosSelected({
      colaborador_solicitante: agreementAuthToEdit?.colaborador_solicitante,
      Convenio: agreementAuthToEdit?.Convenio,
    });
    setIsEditMode(true);
  };
  const modalAlert = (agreementAuthId) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        console.log("bem bem antes", values.Autorizacao);
        removeAgreementAuth(agreementAuthId);
      }
    });
  };

  const removeAgreementAuth = useCallback(
    async (agreementAuthId) => {
      ApiService.delete(`/agreements-auth/${agreementAuthId}`)
        .then((response) => {
          const agreementAuthEdit = agreementAuth.id === agreementAuthId;
          const removedAgreementAuth = values.Autorizacao.filter(
            (item) => item.id !== agreementAuthId
          );
          setFieldValue("Autorizacao", removedAgreementAuth);
          enqueueSnackbar("Autorização removida com sucesso!", {
            variant: "success",
            
          });
          if (agreementAuthEdit) {
            setIsEditMode(false);
            setAgreementAuth({
              convenio_id: "",
              colaborador_solicitante_id: "",
              procedimento: "",
              data_autorizacao: "",
              codigo_autorizacao: "",
              guia: "",
              cid: "",
              facial: false,
              numero_total_sessoes: "",
              quantidade_horario_sessao: "",
              metodo_de_tratamento: "",
              quantidade_por_frequencia: "",
              frequencia: "",
              sessoes_realizadas: "",
              valor: "",
              desconto: "",
              valor_final: "",
              codigo: "",
            });
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: "error",
              
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: "error",
              
            });
          }
        });
    },
    [enqueueSnackbar, setFieldValue, values.Autorizacao]
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            gutterBottom
            style={{ marginBottom: "15px" }}
          >
            Autorização
          </Typography>
        </Grid>

        <Grid xs={4}>
          <Autocomplete
            disablePortal
            name="Autorizacao.convenio_id"
            getOptionLabel={(option) => option.nome}
            options={infos?.Convenios}
            disabled={Object.keys(infos.Convenios).length === 0}
            value={infosSelected?.Convenio ?? null}
            onChange={(event, newValue) => {
              setInfosSelected((prevValues) => ({
                ...prevValues,
                Convenio: newValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Autorizacao.convenio_id"
                label="Cartao Convênio"
                placeholder="Cartao Convênio"
                error={!!errors["Convenio.nome"]}
                helperText={!!errors["Convenio.nome"]}
              />
            )}
          />
        </Grid>

        <Grid xs={4}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option.nome}
            options={users}
            value={infosSelected?.colaborador_solicitante ?? null}
            onChange={(event, newValue) => {
              setInfosSelected((prevValues) => ({
                ...prevValues,
                colaborador_solicitante: newValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Solicitante"
                placeholder="Solicitante"
              />
            )}
          />
        </Grid>

        <Grid xs={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">
              Procedimento/Sessão
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Procedimento/Sessão"
              value={agreementAuth.procedimento}
              onChange={(event) => {
                setAgreementAuth({
                  ...agreementAuth,
                  procedimento: event.target.value,
                });
              }}
            >
              <MenuItem value={"Natação Kids - 1x Na Semana - Plano Anual"}>
                Natação Kids - 1x Na Semana - Plano Anual
              </MenuItem>
              <MenuItem
                value={"Vivência Aquática - 1x Na Semana - Plano Anual"}
              >
                Vivência Aquática - 1x Na Semana - Plano Anual
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={3}>
          <TextField
            fullWidth
            name="Autorizacao.data_autorizacao"
            label="Data Autorizacao"
            type="date"
            value={agreementAuth.data_autorizacao}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                data_autorizacao: event.target.value,
              });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={3}>
          <TextField
            fullWidth
            name="Autorizacao.codigo_autorizacao"
            label="Codigo Autorização"
            value={agreementAuth.codigo_autorizacao}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                codigo_autorizacao: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid xs={3}>
          <TextField
            fullWidth
            name="Autorizacao.guia"
            label="Guia"
            value={agreementAuth.guia}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                guia: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid xs={3}>
          <TextField
            fullWidth
            name="Autorizacao.cid"
            label="CID"
            value={agreementAuth.cid}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                cid: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid xs={3}>
          <TextField
            fullWidth
            name="Autorizacao.totalSessoes"
            label="Nº Total de Sessões"
            value={agreementAuth.numero_total_sessoes}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                numero_total_sessoes: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid xs={3}>
          <TextField
            fullWidth
            name="Autorizacao.quantidade_por_frequencia"
            label="Qntd Por Frequência"
            value={agreementAuth.quantidade_por_frequencia}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                quantidade_por_frequencia: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid xs={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">Frequência</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Frequência"
              value={agreementAuth.frequencia}
              onChange={(event) => {
                setAgreementAuth({
                  ...agreementAuth,
                  frequencia: event.target.value,
                });
              }}
            >
              <MenuItem value={"Diario"}>Diário</MenuItem>
              <MenuItem value={"Semanal"}>Semanal</MenuItem>
              <MenuItem value={"Mensal"}>Mensal</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={3}>
          <InputLabel htmlFor={"Valor"} sx={{ my: -2 }}>
            Valor
          </InputLabel>
          <CurrencyInput
            placeholder="Valor"
            style={style}
            id={"valor"}
            name={"Autorizacao.valor"}
            prefix={"R$ "}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            step={0.01}
            value={agreementAuth.valor}
            onValueChange={(value) => {
              if (value === undefined || value === null || value === "") {
                setAgreementAuth({
                  ...agreementAuth,
                  valor: 0,
                });
              } else {
                setAgreementAuth({
                  ...agreementAuth,
                  valor: value,
                });
              }
            }}
          />
        </Grid>

        <Grid xs={3}>
        <InputLabel htmlFor={"desconto"} sx={{ my: -2 }}>
            Desconto
          </InputLabel>
          <CurrencyInput
            placeholder="Desconto"
            style={style}
            id={"desconto"}
            name={"Autorizacao.desconto"}
            prefix={"R$ "}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            step={0.01}
            value={agreementAuth.desconto}
            onValueChange={(value) => {
              if (value === undefined || value === null || value === "") {
                setAgreementAuth({
                  ...agreementAuth,
                  desconto: 0,
                });
              } else {
                setAgreementAuth({
                  ...agreementAuth,
                  desconto: value,
                });
              }
            }}
          />
        </Grid>

        <Grid xs={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">
              Método de Tratamento
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Método de Tratamento"
              value={agreementAuth.metodo_de_tratamento}
              onChange={(event) => {
                setAgreementAuth({
                  ...agreementAuth,
                  metodo_de_tratamento: event.target.value,
                });
              }}
            >
              <MenuItem value={"Normal"}>Normal</MenuItem>
              <MenuItem value={"ABA"}>ABA</MenuItem>
              <MenuItem value={"Denver"}>Denver</MenuItem>
              <MenuItem value={"Autismo"}>Autismo</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={3}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Facial
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="Autorizacao.facial"
              value={agreementAuth.facial}
              onChange={(event) => {
                setAgreementAuth({
                  ...agreementAuth,
                  facial: event.target.value,
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid xs={3}>
          <FormControl>
            <FormLabel id="Autorizacao.quantidade_horario_sessao">
              Qntd Horários Sessões
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="Autorizacao.quantidade_horario_sessao"
              value={agreementAuth.quantidade_horario_sessao}
              onChange={(event) => {
                setAgreementAuth({
                  ...agreementAuth,
                  quantidade_horario_sessao: Number(event.target.value),
                });
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="1" />
              <FormControlLabel value={2} control={<Radio />} label="2" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <TextField
            fullWidth
            style={{ marginTop: "12px", marginBottom: "10px" }}
            name="Autorizacao.observacao"
            label="Observação"
            multiline
            rows={4}
            value={agreementAuth.observacao}
            onChange={(event) => {
              setAgreementAuth({
                ...agreementAuth,
                observacao: event.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={4}>
          {id ? (
            <Grid xs={6}>
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                disabled={!infosSelected?.Convenio}
                onClick={() => {
                  agreementAuthSchema
                    .validate(infosSelected, {
                      abortEarly: false,
                    })
                    .then(() => {
                      setErrors({});
                      if (isEditMode) {
                        const agreementId = agreementAuth.id;
                        console.log(agreementAuth);
                        const editValues = {
                          ...agreementAuth,
                          Convenio: undefined,
                          colaborador_solicitante: undefined,
                          User: undefined,
                          convenio_id: Number(infosSelected?.Convenio?.id),
                          colaborador_solicitante_id: Number(
                            infosSelected?.colaborador_solicitante?.id
                          ),
                          numero_total_sessoes: Number(
                            agreementAuth.numero_total_sessoes
                          ),
                          quantidade_horario_sessao: Number(
                            agreementAuth.quantidade_horario_sessao
                          ),
                          quantidade_por_frequencia: Number(
                            agreementAuth.quantidade_por_frequencia
                          ),
                          facial:
                            agreementAuth.facial === "true" ? true : false,
                        };

                        ApiService.put(
                          `/agreements-auth/${agreementId}`,
                          editValues
                        )
                          .then((response) => {
                            console.log(response.data);
                            enqueueSnackbar(
                              "Autorização atualizada com sucesso!",
                              {
                                variant: "success",
                                
                              }
                            );
                            const updatedTodos = values.Autorizacao.map(
                              (todo) => {
                                if (todo.id === response.data.id) {
                                  return response.data; // Retorna o convenio atualizado
                                }
                                return todo;
                              }
                            );
                            setFieldValue("Autorizacao", updatedTodos); // Atualiza a lista de autorização com a autorizacao atualizada
                            setIsEditMode(false); // Sai do modo de edição
                            setInfosSelected({});
                            setAgreementAuth({
                              convenio_id: "",
                              colaborador_solicitante_id: "",
                              procedimento: "",
                              data_autorizacao: "",
                              codigo_autorizacao: "",
                              guia: "",
                              cid: "",
                              facial: false,
                              numero_total_sessoes: "",
                              quantidade_horario_sessao: "",
                              metodo_de_tratamento: "",
                              quantidade_por_frequencia: "",
                              frequencia: "",
                              sessoes_realizadas: "",
                              valor: "",
                              desconto: "",
                              valor_final: "",
                              codigo: "",
                              observacao: "",
                            });
                          })
                          .catch((error) => {
                            const errorMessage = error.response.data.message;
                            console.log(errorMessage);
                            if (Array.isArray(errorMessage)) {
                              enqueueSnackbar(`${errorMessage[0]}`, {
                                variant: "error",
                                
                              });
                            } else {
                              enqueueSnackbar(`${errorMessage}`, {
                                variant: "error",
                                
                              });
                            }
                          });
                      } else {
                        console.log(infosSelected);

                        const userAgreement = {
                          user_id: Number(id),
                          convenio_id: infosSelected?.Convenio?.id,
                          colaborador_solicitante_id:
                            infosSelected?.colaborador_solicitante?.id,
                          procedimento: agreementAuth.procedimento,
                          data_autorizacao: agreementAuth.data_autorizacao,
                          codigo_autorizacao: agreementAuth.codigo_autorizacao,
                          guia: agreementAuth.guia,
                          cid: agreementAuth.cid,
                          facial: Boolean(agreementAuth.facial),
                          numero_total_sessoes: Number(
                            agreementAuth.numero_total_sessoes
                          ),
                          quantidade_horario_sessao: Number(
                            agreementAuth.quantidade_horario_sessao
                          ),
                          metodo_de_tratamento:
                            agreementAuth.metodo_de_tratamento,
                          quantidade_por_frequencia: Number(
                            agreementAuth.quantidade_por_frequencia
                          ),
                          frequencia: agreementAuth.frequencia,
                          sessoes_realizadas: agreementAuth.sessoes_realizadas,
                          valor: agreementAuth.valor,
                          desconto: agreementAuth.desconto,
                          valor_final: agreementAuth.valor_final,
                          codigo: agreementAuth.codigo,
                        };

                        ApiService.post(`/agreements-auth`, userAgreement)
                          .then((response) => {
                            const newAgreement = [
                              ...values.Autorizacao,
                              response.data,
                            ];
                            enqueueSnackbar("Autorização criada com sucesso!", {
                              variant: "success",
                              
                            });
                            setFieldValue("Autorizacao", newAgreement);
                            setInfosSelected({});
                            setAgreementAuth({
                              convenio_id: "",
                              colaborador_solicitante_id: "",
                              procedimento: "",
                              data_autorizacao: "",
                              codigo_autorizacao: "",
                              guia: "",
                              cid: "",
                              facial: false,
                              numero_total_sessoes: "",
                              quantidade_horario_sessao: "",
                              metodo_de_tratamento: "",
                              quantidade_por_frequencia: "",
                              frequencia: "",
                              sessoes_realizadas: "",
                              valor: "",
                              desconto: "",
                              valor_final: "",
                              codigo: "",
                            });
                          })
                          .catch((error) => {
                            const errorMessage = error.response.data.message;
                            console.log(errorMessage);
                            if (Array.isArray(errorMessage)) {
                              enqueueSnackbar(`${errorMessage[0]}`, {
                                variant: "error",
                                
                              });
                            } else {
                              enqueueSnackbar(`${errorMessage}`, {
                                variant: "error",
                                
                              });
                            }
                          });
                      }
                    })
                    .catch((validationErrors) => {
                      console.log(validationErrors);
                      validationErrors.inner?.forEach((error) => {
                        enqueueSnackbar(error.message, {
                          variant: "error",
                          
                        });
                      });
                      const errors = {};
                      validationErrors.inner?.forEach((error) => {
                        errors[error.path] = error.message;
                      });
                      console.log(errors);
                      setErrors(errors);
                    });
                }}
              >
                {isEditMode ? "Editar Autorização" : "Incluir Autorização"}
              </Button>
            </Grid>
          ) : undefined}
        </Grid>
        {id ? (
          <Grid item xs={12}>
            <FieldArray name="Autorizacao">
              {({ remove, push }) =>
                values?.Autorizacao?.map((agremeent) => (
                  <AgreementAuthItem
                    /*  key={agreement.id} */
                    agreement={agremeent}
                    handleEditAgreement={handleEditAgreementAuth}
                    modalAlert={modalAlert}
                  />
                ))
              }
            </FieldArray>
          </Grid>
        ) : undefined}

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 15 }}>
          <Grid xs={12} sm={4} md={2}>
            <Typography variant="h3" gutterBottom>
              Código
            </Typography>
          </Grid>
          <Grid xs={12} sm={4} md={2}>
            <Typography variant="h3" gutterBottom>
              Inclusão
            </Typography>
          </Grid>
          <Grid xs={12} sm={4} md={3}>
            <Typography variant="h3" gutterBottom>
              Colaborador Inclusão
            </Typography>
          </Grid>
          <Grid xs={12} sm={4} md={2}>
            <Typography variant="h3" gutterBottom>
              Alteração
            </Typography>
          </Grid>
          <Grid xs={12} sm={4} md={3}>
            <Typography variant="h3" gutterBottom>
              Colaborador Alteração
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Authorization;
