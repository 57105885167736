import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Stack, Typography } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";

const ChatUploadModal = ({ isOpen, onClose, file, handleSendMessage }) => {
  const [fileType, setFileType] = useState(null);

  const getFileType = (fileName) => {
    if (fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
      return "image";
    } else if (fileName.endsWith(".mp4")) {
      return "video";
    } else if (fileName.endsWith(".pdf")) {
      return "pdf";
    } else if (fileName.endsWith(".docx") || fileName.endsWith(".xls")) {
      return "document";
    } else {
      return "unsupported";
    }
  };

  const getFileIconAndText = (fileType) => {
    switch (fileType) {
      case "image":
        return {
          icon: <img src={URL.createObjectURL(file)} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />,
          text: "Imagem",
        };
      case "video":
        return {
          icon: <SmartDisplayIcon style={{ fontSize: 120 }} />,
          text: "Vídeo",
        };
      case "pdf":
        return {
          icon: <PictureAsPdfIcon style={{ fontSize: 120 }} />,
          text: "Arquivo PDF",
        };
      case "document":
        return {
          icon: <ArticleIcon style={{ fontSize: 120 }} />,
          text: "Documento",
        };
      default:
        return {
          icon: <Typography>Não foi possível carregar o arquivo.</Typography>,
          text: "Tipo de arquivo não suportado",
        };
    }
  };

  useEffect(() => {
    if (file) {
      const type = getFileType(file.name);
      setFileType(type);
    }
  }, [file]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-upload-title"
      aria-describedby="modal-upload-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        {file && (
          <Box sx={{ textAlign: 'center' }}>
            {getFileIconAndText(fileType).icon}
            {fileType !== "image" && (
              <Typography variant="h6" mt={2}>
                {getFileIconAndText(fileType).text}
              </Typography>
            )}
          </Box>
        )}
        <Stack direction={"row"} gap={2} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant='outlined' onClick={onClose}>Cancelar</Button>
          <Button variant="contained" endIcon={<SendIcon />} onClick={() => {
            handleSendMessage(file);
            onClose(); 
          }}>Enviar</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ChatUploadModal;