import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import ApiService from 'app/services/config';
import Patient from './Patient';
import Anamnesis from './Anamnesis';
import Avaliation from './Avaliation';
import DailyChips from './DailyChips';
import Evolution from './Evolution';
import Files from './FilesList';
import InterventionPlanList from './InterventionPlanList';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import InterdisciplinaryAssessment from "./InterdisciplinaryAssessment";
import { Breaks } from './Breaks';
import { InterdisciplinaryEvolution } from './InterdisciplinaryEvolution';
import { FeedbackFromParents } from './FeedbackFromParents';
import { SupervisionTherapists } from './SupervisionTherapists';
import { SpecialPrograms } from './SpecialPrograms';
import ChatApp from './ChatApp';
import VideosList from './VideosList';
import InfosForParents from './InfosForParents';
import AvaliationRegister from './AvaliationRegister';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
      
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function EvolutionSheetIndex() {
    const initialValues = {
        Anamnese: [],
        Gestacao: [],
        Sono: [],
        Saude: [],
        Anamnese_Alimentar: [],
        Fono: [],
        Avaliacao: [],
        Evolucao: [],
        Info_Documento: [],
        Arquivo: [],
        Video: [],
        Plano_De_Intervencao: [],
        /* historico_alimentar: [], */
    };

    const storedPermissions = JSON.parse(localStorage.getItem("storedPermissions"))
    const isUserhasParentPermission = storedPermissions.some((item => item.perfil_id === 3))
    const { enqueueSnackbar } = useSnackbar();
    let { state } = useLocation();
    const { userId } = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [infos, setInfos] = useState(initialValues);

    useEffect(() => {
        if (localStorage.getItem('tabIndex')) {
            setValue(localStorage.getItem('tabIndex'))

            const timeout = setTimeout(() => {
                localStorage.removeItem('tabIndex');
            }, 1000);
            return () => clearTimeout(timeout);
        }
      }, []);

    const [anamnesis, setAnamnesis] = useState({
        queixa: '',
        parecer: '',
        data: '',
        Gestacao: [
            {
                condicoes_saude_mae_gravidez: '',
                queda_ou_susto_forte: '',
                condicoes_emocionais_mae: '',
                idade_gestacional: null,
                peso_nascimento: null,
                comprimento_nascimento: null,
                tipo_parto: '',
                tipo_parto_obs: '',
                idade_andar: null,
                idade_deixar_fraldas: null,
                data_problema_percebido: '',
                procurou_especialistas: false,
                faz_tratamento: false,
                acompanhamento_psicologico_SUS: false,
            },
        ],
        Sono: [
            {
                tranquilo: false,
                inquieto: false,
                agitado: false,
                refere_pesadelos: false,
                acorda_varias_vezes: false,
                horario_de_dormir: '',
                dorme_sozinho: '',
            },
        ],
        Saude: [
            {
                sofreu_acidente: false,
                submeteu_cirurgia: false,
                possui_alergias: false,
                bronquite_ou_asma: false,
                problema_visao: false,
                problema_audicao: false,
                dor_cabeca: false,
                desmaiou_alguma_vez: false,
                teve_ou_tem_convulsoes: false,
                problema_desmaio_convulsoes_familia_apresenta: false,
            },
        ],
        Alimentacao: [
            {
                paciente_amamentado: false,
                alimenta_se_bem: false,
                toma_cafe_da_manha: false,
                faz_lanche_manha: false,
                almoco: false,
                faz_lanche_tarde: false,
                jantar: false,
                faz_lanche_noite: false,
                leva_lanche_escola: false,
            },
        ],
        Desenvolvimento: [
            {
                tranquilidade: false,
                agressividade: false,
                amorosa: false, //
                quietude: false,
                fechada: false,
                passividade: false,
                medrosa: false,
                desligada: false,
                reacao_contrariedade: '',
                atividades_preferidas: '',
                brinca_carrinho_boneca: false,
                aceita_dividir_brinquedos: false,
                gosta_escola: false,
                repetiu_serie_escola: false,
                gosta_estudar: false,
                horario_estudo: false,
                pais_ajudam_estudo: false,
            },
        ],
        Anamnese_Extensao: [
            {
                queixa: '',
                data: '',
                Historico_Maternal_Gestacional: [
                    {
                        gravidez: '',
                        tabagismo: false,
                        etilismo: false,
                        intercorrencias_pre_natais: false,
                        qual_intercorrencias_pre_natais: '',
                        consanguinidade: false,
                        grau_de_parentesco: '',
                        antecedentes_familiares: false,
                        observacoes: '',
                    },
                ],
                Parto_Periodo_Neonatal: [
                    {
                        idade_gestacional_semanas: '',
                        idade_gestacional_dias: '',
                        prematuridade: '', // pre-termo
                        tipo_de_parto: '',
                        adequacao_de_peso: '', // aig, cic, pig
                        apgar_1: '',
                        apgar_2: '',
                        apgar_3: '',
                        intercorrencias_peri_pos_natais_internacao: false,
                        tempo_peri_pos_natais_internacao: '',
                        amamentou: false,
                        tempo_amamentou: '',
                        dificuldades_na_amamentacao: false,
                        quais_dificuldades_na_amamentacao: '',
                        fez_uso_de_mamadeira: false,
                        tempo_uso_de_mamadeira: '',
                        fez_uso_de_chupeta: false,
                        tempo_uso_de_chupeta: '',
                        habitos_orais_deleterios: false,
                        tempo_orais_deleterios: '',
                        observacao: '',
                    },
                ],
                Habito_Sono: [
                    {
                        dorme_bem: false,
                        dorme_de_luz_acesa: false,
                        baba_no_travesseiro: false,
                        ronca: false,
                        sono_agitado: false,
                        dorme_com_boca_aberta: false,
                    },
                ],
                Desenvolvimento_Neuropsicomotor: [
                    {
                        sustentar_a_cabeca: false,
                        quando_sustentar_a_cabeca: '',
                        sentar_sem_apoio: false,
                        quando_sentar_sem_apoio: '',
                        engatinhar: false,
                        quando_engatinhar: '',
                        andar: false,
                        quando_andar: '',
                        controle_de_esfincter: false,
                        quando_controle_de_esfincter: '',
                        vocalizacoes: false,
                        quando_vocalizacoes: '',
                        balbuciar: false,
                        quando_balbuciar: '',
                        falar: false,
                        quando_falar: '',
                        formar_primeiras_frases: false,
                        quando_formar_primeiras_frases: '',
                    },
                ],
                Percepcao_Visual_Auditiva: [
                    {
                        identifica_cores: false,
                        acuidade_visual: '',
                        outra_acuidade_visual: '',
                        uso_lentes_corretivas: '',
                        reacao_luzes: '',
                        percebe_sons: false,
                        discrimina_sons: false,
                        compreende_executa_comandos: false,
                        latencia_compreensao_execucao: false,
                        realizou_exames_auditivos: false,
                        resultados_exames_auditivos: '',
                        uso_aparelho_de_amplificacao_sonora_individual: '',
                    },
                ],
                Acompanhamento_Especializado: [
                    {
                        realizou_ou_realiza: false,
                        local_realizou_ou_realiza: '',
                        ido_ao_dentista: false,
                        ultima_ida: '',
                        desde_quando: '',
                        fonoaudiologia: false,
                        terapia_ocupacional: false,
                        psicologia: false,
                        observacoes: '',
                        condutas: '',
                        encaminhamentos: '',
                    },
                ],
            }
        ]
    });

    const [foodAnamnesis, setFoodAnamnesis] = useState({
        historico_alimentar: [
            {
                restricao: false,
                qual_restricao: '',
                via_alimentacao: '',
                qual_mista: '',
                apresenta_alergia: false,
                qual_alergia: '',
                apresenta_intolerancia: false,
                qual_intolerancia: '',
                problema_respiratorio_recorrente: false,
                qual_problema_respiratorio_recorrente: '',
                historico_rge: false,
                periodo_diagnostico_rge: '',
                tratamento_rge: false,
                outro_problema_gastrico_diagnosticado: false,
                qual_problema_gastrico_diagnosticado: '',
                uso_regular_medicacao: false,
                qual_medicacao: '',
                episodios_engasgo: false,
                detalhe_episodio_engasgo: '',
                episodio_regurgitamento: false,
                data_episodio_regurgitamento: '',
                processo_introducao_alimentar: '',
                texturas_aceitas: false,
                mastigacao_crianca: '',
                comentario: '',
                deglutinacao_crianca: '',
            },
        ],
        comportamento_alimentar: [
            {
                dificuldades_mastigar_alimentos: false,
                evita_comer_frutas: false,
                gofa_ou_vomita_apos_refeicao: false,
                come_grande_quantidade: false,
                engole_alimento_sem_mastigar_direito: false,
                evita_comer_vegetais_crus_ou_cozidos: false,
                sempre_usa_mesmos_utensilios: false,
                pega_comida_sem_permissao_fora_horario_refeicao: false,
                mastiga_boca_aberta: false,
                dificuldade_sentar_se_mesa: false,
                come_sempre_mesmo_lugar: false,
                pega_comida_outras_pessoas_sem_permissao: false,
                dificuldade_usar_talheres_utensilios: false,
                leva_objetos_estranhos_a_boca: false,
                quer_comer_alimentos_cores_semelhantes: false,
                ritual_rigido_comer: false,
                dificuldade_lateralizar_alimento_boca: false,
                derrama_muita_comida_mesa_roupa: false,
                quer_comer_sempre_mesmos_alimentos: false,
                tosse_durante_deglutinacao: false,
                inquietacao_agitacao_dificulta_sentar_se_mesa: false,
                vomita_durante_ou_logo_apos_refeicao: false,
                quer_comer_alimentos_mesma_marca_embalagem: false,
                tosse_seca_apos_alimentacao: false,
            },
        ],
        habito_alimentar: [
            {
                alimento_preferido_crianca: '',
                outros_comportamentos_relatar: '',
                tempo_aproximado_refeicao: '',
                local_refeicoes_filho: '',
                quem_escolhe_tipo_quantidade_alimento: '',
                uso_talheres: false,
                tipo_talher_uso: '',
                utiliza_mamadeira_chupeta: false,
                quantas_vezes_mamadeira_chupeta_dia: 0,
                chupa_dedo_bruxismo_roe_unha: false,
                qual_chupa_dedo_bruxismo_roe_unha: '',
                familiar_seletivo: false,
                qual_familiar_seletivo: '',
                importancia_alimentacao: '',
                familia_faz_refeicoes_juntas: '',
                problema_questoes_sensoriais: false,
                qual_problema_sensorial: '',
            },
        ],
    });

    const [childrensAnamnesis, setChildrensAnamnesis] = useState([]);

    const [phono, setPhono] = useState({
        queixa: '',
        data: '',
        Historico_Maternal_Gestacional: [
            {
                gravidez: '',
                tabagismo: false,
                etilismo: false,
                intercorrencias_pre_natais: false,
                qual_intercorrencias_pre_natais: '',
                consanguinidade: false,
                grau_de_parentesco: '',
                antecedentes_familiares: false,
                observacoes: '',
            },
        ],
        Parto_Periodo_Neonatal: [
            {
                idade_gestacional_semanas: '',
                idade_gestacional_dias: '',
                prematuridade: '', // pre-termo
                tipo_de_parto: '',
                adequacao_de_peso: '', // aig, cic, pig
                apgar_1: '',
                apgar_2: '',
                apgar_3: '',
                intercorrencias_peri_pos_natais_internacao: false,
                tempo_peri_pos_natais_internacao: '',
                amamentou: false,
                tempo_amamentou: '',
                dificuldades_na_amamentacao: false,
                quais_dificuldades_na_amamentacao: '',
                fez_uso_de_mamadeira: false,
                tempo_uso_de_mamadeira: '',
                fez_uso_de_chupeta: false,
                tempo_uso_de_chupeta: '',
                habitos_orais_deleterios: false,
                tempo_orais_deleterios: '',
                observacao: '',
            },
        ],
        Habito_Sono: [
            {
                dorme_bem: false,
                dorme_de_luz_acesa: false,
                baba_no_travesseiro: false,
                ronca: false,
                sono_agitado: false,
                dorme_com_boca_aberta: false,
            },
        ],
        Desenvolvimento_Neuropsicomotor: [
            {
                sustentar_a_cabeca: false,
                quando_sustentar_a_cabeca: '',
                sentar_sem_apoio: false,
                quando_sentar_sem_apoio: '',
                engatinhar: false,
                quando_engatinhar: '',
                andar: false,
                quando_andar: '',
                controle_de_esfincter: false,
                quando_controle_de_esfincter: '',
                vocalizacoes: false,
                quando_vocalizacoes: '',
                balbuciar: false,
                quando_balbuciar: '',
                falar: false,
                quando_falar: '',
                formar_primeiras_frases: false,
                quando_formar_primeiras_frases: '',
            },
        ],
        Percepcao_Visual_Auditiva: [
            {
                identifica_cores: false,
                acuidade_visual: '',
                outra_acuidade_visual: '',
                uso_lentes_corretivas: '',
                reacao_luzes: '',
                percebe_sons: false,
                discrimina_sons: false,
                compreende_executa_comandos: false,
                latencia_compreensao_execucao: false,
                realizou_exames_auditivos: false,
                resultados_exames_auditivos: '',
                uso_aparelho_de_amplificacao_sonora_individual: '',
            },
        ],
        Acompanhamento_Especializado: [
            {
                realizou_ou_realiza: false,
                local_realizou_ou_realiza: '',
                ido_ao_dentista: false,
                ultima_ida: '',
                desde_quando: '',
                fonoaudiologia: false,
                terapia_ocupacional: false,
                psicologia: false,
                observacoes: '',
                condutas: '',
                encaminhamentos: '',
            },
        ],
    });

    const [avaliation, setAvaliation] = useState({
        linguagem_oral: '',
        fluencia: '',
        conhecimentos_previos: '',
        linguagem_escrita: '',
        aspectos_congnitivos: '',
        reconhece_em_sequencia_alfabeto: false,
        reconhece_em_aleatorio_alfabeto: false,
        detalhes_de_reconhece_alfabeto: '',
        reconhece_vogais: '',
        reconhece_numeros: '',
        reconhece_quantidades: '',
        reconhece_em_sequencia_numeros: '',
        reconhece_formas_geometricas: '',
        reconhece_grandezas: '',
        reconhece_cores: '',
        reconhece_escrita: '',
        nivel_de_escrita: '',
        escrita_de_frases: '',
        leitura: '',
        observacoes: '',
        data: '',
    });

    const [evolution, setEvolution] = useState({
        conduta: '',
        cid: '',
        data: '',
    });

    const [pregnancy, setPregnancy] = useState({
        condicoes_saude_mae_gravidez: '',
        queda_ou_susto_forte: '',
        condicoes_emocionais_mae: '',
        idade_gestacional: '',
        peso_nascimento: '',
        comprimento_nascimento: '',
        tipo_parto: ' ',
        tipo_parto_obs: ' ',
        idade_andar: '',
        idade_deixar_fraldas: '',
        data_problema_percebido: '',
        procurou_especialistas: false,
        faz_tratamento: false,
        acompanhamento_psicologico_SUS: false,
    });

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getInfosById = useCallback(async () => {
        try {
            await ApiService.get(`/users/${userId}`)
                .then((response) => {
                    console.log(response.data);
                    const data = response?.data;
                    const organizerInfos = {
                        username: data?.nome,
                        mae: data?.mae,
                        pai: data?.pai,
                        pessoa_responsavel: data?.pessoa_responsavel,
                        observacao: data?.observacao,
                        Agendamento: data?.Agendamento,
                        Anamnese: data.Anamnese,
                        Gestacao: data.Anamnese[0]?.Gestacao,
                        Sono: data.Anamnese[0]?.Sono,
                        Saude: data.Anamnese[0]?.Saude,
                        Alimentacao: data.Anamnese[0]?.Alimentacao,
                        Desenvolvimento: data.Anamnese[0]?.Desenvolvimento,
                        Anamnese_Extensao: data.Anamnese[0]?.Anamnese_Extensao,
                        Anamnese_Alimentar: data.Anamnese_Alimentar,
                        historico_alimentar:
                            data.Anamnese_Alimentar[0]?.historico_alimentar,
                        comportamento_alimentar:
                            data.Anamnese_Alimentar[0]?.comportamento_alimentar,
                        habito_alimentar:
                            data.Anamnese_Alimentar[0]?.habito_alimentar,

                        Fono: data.Fono,
                        Historico_Maternal_Gestacional:
                            data.Fono[0]?.Historico_Maternal_Gestacional,
                        Parto_Periodo_Neonatal:
                            data.Fono[0]?.Parto_Periodo_Neonatal,
                        Habito_Sono: data.Fono[0]?.Habito_Sono,
                        Desenvolvimento_Neuropsicomotor:
                            data.Fono[0]?.Desenvolvimento_Neuropsicomotor,
                        Percepcao_Visual_Auditiva:
                            data.Fono[0]?.Percepcao_Visual_Auditiva,
                        Acompanhamento_Especializado:
                            data.Fono[0]?.Acompanhamento_Especializado,
                        Avaliacao: data.Avaliacao,
                        Evolucao: data.Evolucao,
                        Info_Documento: data.Info_Documento,
                        Documento_Geral:
                            data.Info_Documento[0]?.Documento_Geral,
                        Arquivo: data?.Arquivo,
                        Video: data?.Video,
                        Plano_De_Intervencao: data?.Plano_De_Intervencao,
                    };
                    console.log(organizerInfos)
                    setInfos(organizerInfos);
                    /* setUserSelected(organizerInfos?.Pessoa);
                    setInfosSelected(organizerInfos?.Autorizacao); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            getInfosById();
        }
    }, [
        getInfosById,
        userId,
        anamnesis,
        foodAnamnesis,
        childrensAnamnesis,
        phono,
        avaliation,
        evolution,
    ]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                for (let innerKey in values[key]) {
                    if (values[key].hasOwnProperty(innerKey)) {
                        if (
                            values[key][innerKey] === '' ||
                            values[key][innerKey] === null ||
                            values[key][innerKey] === undefined
                        ) {
                            delete values[key][innerKey];
                        }
                    }
                }
            }
        }
        console.log(values);
        await ApiService.post('/users', values)
            .then((response) => {
                console.log(response.data);

                enqueueSnackbar('Usuário Criado com sucesso!', {
                    variant: 'success',
                    
                });
            })
            .catch((error) => {
                console.log(error.response.data.message);
                enqueueSnackbar(`${error.response.data.message[0]}`, {
                    variant: 'error',
                    
                });
            });

        setSubmitting(false);
    };

    return (
        <>
            <HeaderBreadcrumbs
                title={'Paciente'}
                subtitle={'Editar Evolução'}
                titleUrl={'/evolution'}
            />
            <Button
                sx={{ width: '80px', marginBottom: '1rem' }}
                variant='contained'
                color='success'
                onClick={() =>
                    navigate(state?.backUrl ? state.backUrl : '/evolution')
                }
            >
                Voltar
            </Button>
            <Paper style={{ padding: '30px' }} square={false} elevation={3}>
                <Formik
                    initialValues={infos}
                    enableReinitialize
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        isSubmitting,
                        handleChange,
                        setFieldValue,
                    }) => (
                        <Form
                            style={{ width: '100%' }}
                            noValidate
                            autoComplete='off'
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                    <Box sx={{ flexGrow: 1, display: 'flex', height: 500 }}>
                                        <Tabs
                                            orientation='vertical'
                                            variant="scrollable"
                                            value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value}
                                            onChange={handleChangeTabs}
                                            aria-label='basic tabs example'
                                        >
                                            <Tab
                                                label='Paciente'
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label='Anamnese'
                                                {...a11yProps(1)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            {/* <Tab label='Fono' {...a11yProps(2)} /> */}
                                            <Tab
                                                label='Avaliação'
                                                sx={{display: 'none'}}
                                                {...a11yProps(2)}
                                            />
                                            <Tab
                                                label='Avaliação interdisciplinar'
                                                {...a11yProps(3)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                             <Tab
                                                label='Registro do Desempenho em Avaliação e Reavaliação'
                                                {...a11yProps(4)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Fichas Diárias'
                                                {...a11yProps(5)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Intervalos'
                                                {...a11yProps(6)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Programas Especiais'
                                                {...a11yProps(7)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Evolução Interdisciplinar'
                                                {...a11yProps(8)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Devolutiva de Responsáveis'
                                                {...a11yProps(9)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Supervisão de Terapeutas'
                                                {...a11yProps(10)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Plano de Intervenção'
                                                {...a11yProps(11)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            <Tab
                                                label='Evolução'
                                                {...a11yProps(12)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />
                                            {/* <Tab
                                                label='Documentos Gerais'
                                                {...a11yProps(11)}
                                            /> */}
                                            <Tab
                                                label='Arquivos'
                                                {...a11yProps(13)}
                                                sx={{display: isUserhasParentPermission ? 'none' : undefined}}
                                            />

                                            <Tab
                                                label='Vídeos'
                                                {...a11yProps(14)}
                                            />

                                            <Tab
                                                label='Gráfico de Níveis das Estimulações'
                                                {...a11yProps(15)}
                                            />

                                            <Tab
                                                label='Chat'
                                                {...a11yProps(16)}
                                            />

                                        </Tabs>
                                    </Box>
                                </Grid>
                                <Grid item xs={7} sm={8} md={9} lg={10}>
                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={0}>
                                        <Patient infos={infos} setValue={setValue} />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={1}>
                                        <Anamnesis
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            anamnesis={anamnesis}
                                            setAnamnesis={setAnamnesis}
                                            foodAnamnesis={foodAnamnesis}
                                            setFoodAnamnesis={setFoodAnamnesis}
                                            childrensAnamnesis={childrensAnamnesis}
                                            setChildrensAnamnesis={setChildrensAnamnesis}
                                            phono={phono}
                                            setPhono={setPhono}
                                        />
                                    </CustomTabPanel>

                                    {/* <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={2}>
                                        <Fono
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            phono={phono}
                                            setPhono={setPhono}
                                        />
                                    </CustomTabPanel> */}

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={2} style={{display: 'none'}}>
                                        <Avaliation
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            avaliation={avaliation}
                                            setAvaliation={setAvaliation}
                                        />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={3}>
                                        <InterdisciplinaryAssessment />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={4}>
                                        <AvaliationRegister />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={5}>
                                        <DailyChips tabIndex={5} />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={6}>
                                        <Breaks tabIndex={6}/>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={7}>
                                        <SpecialPrograms tabIndex={7}/>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={8}>
                                        <InterdisciplinaryEvolution tabIndex={8} />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={9}>
                                        <FeedbackFromParents tabIndex={9} />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={10}>
                                        <SupervisionTherapists tabIndex={10} />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={11}>
                                        <InterventionPlanList
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            userId={userId}
                                            tabIndex={11}
                                        />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={12}>
                                        <Evolution
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            evolution={evolution}
                                            setEvolution={setEvolution}
                                        />
                                    </CustomTabPanel>

                                    {/* <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={11}>
                                        <GeneralDocumentsList
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            userId={userId}
                                        />
                                    </CustomTabPanel> */}

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={13}>
                                        <Files
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            userId={userId}
                                        />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={14}>
                                        <VideosList
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            userId={userId}
                                        />
                                    </CustomTabPanel>
                                    
                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={15}>
                                        <InfosForParents
                                            values={values}
                                            infos={infos}
                                            setFieldValue={setFieldValue}
                                            userId={userId}
                                        />
                                    </CustomTabPanel>

                                    <CustomTabPanel value={localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : value} index={16}>
                                        <ChatApp />
                                    </CustomTabPanel>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </>
    );
}

export default EvolutionSheetIndex;
