import Chip from "@mui/material/Chip";
import DataTable from "../DataTable";
import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Box, Grid, OutlinedInput } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { PermissionContext } from "app/contexts/PermissionContext";

const validationSchema = yup.object().shape({
  descricao: yup.string().required("Campo obrigatório"),
});

const NovaEspecialidade = () => {
  const initialValues = {
    descricao: "",
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [especialidade, setEspecialidade] = useState(initialValues);
  const { hasPermission } = useContext(PermissionContext);

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const valuesEditados = values;
        delete valuesEditados.id;
        delete valuesEditados.prestador_id;
        delete valuesEditados.createdAt;
        delete valuesEditados.updatedAt;
        delete valuesEditados.deletedAt;
        await ApiService.put(`/specialties/${id}`, values).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Atualizado com sucesso!", {
              variant: "success",
            });
            navigate("/especialidades");
          }
        });
      } else {
        await ApiService.post("/specialties", values).then((response) => {
          if (response.status === 201) {
            enqueueSnackbar("Criado com sucesso!", {
              variant: "success",
            });
            navigate("/especialidades");
          }
        });
      }
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const getEspecialidade = async (id) => {
    try {
      await ApiService.get(`/specialties/${id}`).then((response) => {
        if (response.status === 200) {
          setEspecialidade(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getEspecialidade(id);
    }
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs title={"Especialidades"} titleUrl={"/Especialidades"} />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Formik
          initialValues={especialidade}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems={"center"}
              >
                <Grid item xs={6}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="descricao"
                        value={values.descricao}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="outlined-basic"
                        label="Especialidade"
                        variant="outlined"
                      />
                      <ErrorMessage
                        name="descricao"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                style={{
                  height: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => handleSubmit(values)}
                    disabled={id ? !hasPermission('Especialidades', 'update') : !hasPermission('Especialidades', 'create')}
                  >
                    {id ? "Atualizar" : "Criar"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/especialidades");
                    }}
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default NovaEspecialidade;
