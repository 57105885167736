import {
  Alert,
  Box,
  Button,
  Fade,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ChatUserMessage from "../ChatUserMessage/ChatUserMessage";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ChatHeader from "../ChatHeader/ChatHeader";
import { useEffect, useRef, useState } from "react";
import ApiService from "app/services/config";
import socket from "app/services/websockets";
import ChatDetails from "../ChatDetails/ChatDetails";
import ChatUploadModal from "../ChatUploadModal/ChatUploadModal";
import { useSnackbar } from "notistack";

export const ChatContent = ({
  selectedRoom,
  handleSelectRoom,
  handleDeleteChatroom,
}) => {
  const USER_LOGGED_ID = Number(localStorage.getItem("id"));
  const [currentRoom, setCurrentRoom] = useState(selectedRoom);
  const [messages, setMessages] = useState(selectedRoom?.Mensagens);
  const [messageInput, setMessageInput] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [isConnectionWithError, setIsConnectionWithError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(!isConnectionWithError);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalPreviewFileOpen, setIsModalPreviewFileOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();


  const websocket = socket;

  const handleOpenModalDetails = () => {
    setIsModalDetailsOpen(!isModalDetailsOpen);
  };

  const getMessagesForChatRoom = async () => {
    try {
      await ApiService.get(`/message/chatroom/${selectedRoom?.id}`).then(
        (response) => {
          if (response.status === 200) {
            setMessages(response.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = (files) => {
    const file = files[0];
    setSelectedFile(file);
    setIsModalPreviewFileOpen(true);
  };

  const handleOpenModalPreviewUpload = () => {
    setIsModalPreviewFileOpen(!isModalPreviewFileOpen);
  };

  const handleSendMessage = async () => {
    if (sendingMessage) {
      return; 
    }
    setSendingMessage(true);
    try {
      const formData = new FormData();
      formData.append("user_id", USER_LOGGED_ID);
      formData.append("sala_do_chat_id", selectedRoom.id);

      if (selectedFile) {
        formData.append("urlImagem", selectedFile);
        formData.append("conteudo", "arquivo");
      } else {
        formData.append("conteudo", messageInput);
      }

      await ApiService.post("/message", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.status === 201) {
          setMessageInput("");
          websocket.emit("sendMessage", {
            user_id: USER_LOGGED_ID,
            sala_do_chat_id: selectedRoom.id,
            conteudo: selectedFile ? "arquivo" : messageInput,
            imagemUrl: response.data.imagemUrl,
            username: localStorage.getItem("username"),
            avatar_url: localStorage.getItem("avatarUrl"),
            users_ids: selectedRoom?.users?.map((user) => user?.id),
          });
          setSelectedFile(null);
          setMessages((prevMessages) => [...prevMessages, response.data]);
        }
      });
    } catch (error) {
      console.error(error);
    }
    setSendingMessage(false);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!selectedRoom) {
      setMessages([]);
      return (
        <Paper
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">Selecione uma sala de chat</Typography>
        </Paper>
      );
    } else {
      setCurrentRoom(selectedRoom);
      getMessagesForChatRoom();
    }
  }, [selectedRoom]);

  useEffect(() => {

    websocket.on("connect", () => {
      console.log("Conectado");
      setIsConnectionWithError(false);
    });

    websocket.emit("userLogin", { user_id: USER_LOGGED_ID });

    websocket.on("connect_error", (error) => {
      console.log("Erro");
      console.log(error);
      setIsConnectionWithError(true);
      setTimeout(() => {
        websocket.connect();
      }, [1000]);
    });

    websocket.on("disconnect", () => {
      console.log("Erro");
      setIsConnectionWithError(true);
      websocket.emit("userDisconnected", { user_id: USER_LOGGED_ID });
      setTimeout(() => {
        websocket.connect();
      }, [1000]);
      setIsConnectionWithError(false);
    });

    websocket.on("message", (message) => {
      console.log(message);
      if (
        message.sala_do_chat_id === selectedRoom?.id &&
        message.user_id !== USER_LOGGED_ID &&
        !messages.some((msg) => msg.id === message.id)
      ) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            ...message,
            user: { nome: message.username, avatar_url: message.avatar_url },
          },
        ]);
      }
    });

    websocket.on("newUsersRoom", (newUsers) => {
      if (newUsers?.roomId === selectedRoom?.id) {
        setShowInfoMessage(true);
        setInfoMessage(newUsers?.textInfo);
        //handleSelectRoom(newUsers?.newRoomState);
      }
    });

    websocket.on("userRemovedFromRoom", (removedUser) => {
      console.log("room id ", selectedRoom.id)
      if (removedUser?.roomId === selectedRoom?.id) {
        setShowInfoMessage(true);
        setInfoMessage(removedUser?.textInfo);
        //handleSelectRoom(removedUser?.newRoomState);
      }
    });

    websocket.on('roomRemoved', (room) => {
      console.log(`Sala ${room.roomId} excluída`)
      if(room?.users?.some(user => user.id === USER_LOGGED_ID) && room?.roomId === selectedRoom?.id) {
        handleSelectRoom(null);
        enqueueSnackbar(`${room.textInfo}`, {
          variant: "warning"
        })
      }
    });


    return () => {
      websocket.close();
    };
  }, [selectedRoom]);

  useEffect(() => {
    if (!isConnectionWithError) {
      setShowSuccess(true);
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [isConnectionWithError]);

  useEffect(() => {
    if (showInfoMessage) {
      const timer = setTimeout(() => {
        setShowInfoMessage(false);
        setInfoMessage("");
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [showInfoMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  return (
    <div
      style={{
        padding: "16px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <ChatHeader
        selectedRoom={selectedRoom}
        handleDeleteChatroom={async () => {
          const isChatDeleted = await handleDeleteChatroom();
          if (isChatDeleted) {
            setMessages([]);
          }
        }}
        handleOpenModalDetails={handleOpenModalDetails}
      />
      <ChatDetails
        selectedRoom={selectedRoom}
        handleSelectRoom={handleSelectRoom}
        isModalDetailsOpen={isModalDetailsOpen}
        setIsModalDetailsOpen={handleOpenModalDetails}
      />
      <Paper
        style={{
          flex: 1,
          marginTop: "16px",
          padding: "16px",
          overflowY: "auto",
          maxHeight: 400,
        }}
      >
        {selectedRoom !== null && messages && messages.length > 0 ? (
          messages.map((message) => (
            <ChatUserMessage
              userId={message.user_id}
              messageContent={message.conteudo}
              messageDate={message.createdAt}
              arquivo={message.imagemUrl}
              username={message?.user?.nome}
              avatarUrl={message?.user?.avatar_url}
            />
          ))
        ) : (
          <Typography variant="body1">
            Mensagens da sala{" "}
            {selectedRoom?.nome ? selectedRoom?.nome : "escolhida"} aparecerão
            aqui.
          </Typography>
        )}
        {isConnectionWithError ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Alert severity="error">
              Houve um problema na conexão! Tentando reconectar...
            </Alert>
          </Stack>
        ) : (
          showSuccess && (
            <Fade in={showSuccess} timeout={500}>
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Alert severity="success">Conectado!</Alert>
              </Stack>
            </Fade>
          )
        )}
        {showInfoMessage && (
          <Fade in={showInfoMessage} timeout={500}>
            <Stack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Alert severity="info">{infoMessage}</Alert>
            </Stack>
          </Fade>
        )}
        <div ref={messagesEndRef} />
      </Paper>
      <Stack direction="row" width={"100%"} my={1}>
        <TextField
          id="outlined-basic"
          label={
            !selectedRoom?.users?.some((user) => user.id === USER_LOGGED_ID)
              ? "Você não pertence a este chat e não pode conversar nele"
              : "Digite sua mensagem..."
          }
          multiline
          variant="outlined"
          sx={{
            width: "85%",
          }}
          value={messageInput}
          onChange={(event) => {
            setMessageInput(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              handleSendMessage();
              event.preventDefault();
            }
          }}
          disabled={
            !selectedRoom?.users?.some((user) => user.id === USER_LOGGED_ID)
          }
        />
        <Stack
          width="15px"
          gap={2}
          direction={"row"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <input
            type="file"
            id="fileInput"
            accept=".png, .jpg, .jpeg, .mp4, .pdf, .docx, .xls"
            style={{ display: "none" }}
            onChange={(event) => handleFileUpload(event.target.files)}
            disabled={
              !selectedRoom?.users?.some((user) => user.id === USER_LOGGED_ID)
            }
          />
          <AttachFileIcon
            sx={{ cursor: "pointer" }}
            onClick={() => document.getElementById("fileInput").click()}
          />
          <SendIcon
            sx={{ cursor: "pointer" }}
            color={sendingMessage ? "disabled" : "primary"}
            onClick={handleSendMessage}
            disabled={sendingMessage}
          />
          <ChatUploadModal
            isOpen={isModalPreviewFileOpen}
            onClose={handleOpenModalPreviewUpload}
            file={selectedFile}
            handleSendMessage={handleSendMessage}
          />
        </Stack>
      </Stack>
    </div>
  );
};
