import React, { useEffect, useState } from "react";

import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Card,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ChatAppSidebar from "app/components/Chat/ChatAppSidebar";
import { ChatContent } from "app/components/Chat/ChatContent/ChatContent";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import socket from "app/services/websockets";

const ChatApp = () => {
  const USER_LOGGED_ID = Number(localStorage.getItem("id"));
  const {perfil_id} = JSON.parse(localStorage.getItem("storedPermissions"))[0];
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useJumboTheme();
  const { userId } = useParams();

  const md = useMediaQuery(theme.breakpoints.down("md"));

  const websocket = socket;

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          width: 280,
          height: 500,
          marginRight: 0,
          borderRight: 1,
          borderRightColor: (theme) => theme.palette.divider,
          [theme.breakpoints.down("md")]: {
            width: "auto",
            border: "none",
          },
        },
      },
      wrapper: {
        component: Card,
        sx: {
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        },
      },
    }),
    [theme]
  );

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [chatRoomList, setChatRoomList] = useState([]);

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
  };

  const getChatRoomsForCurrentUser = async () => {
    try {
      await ApiService.get(
        `/chatroom/get-chatrooms-for-user/${Number(userId)}`
      ).then((response) => {
        if (response.status === 200) {
          let chatsOfUser;
          if(perfil_id === 4) {
            chatsOfUser = response.data;
          } else {
            chatsOfUser = response?.data?.filter((chat) =>
              chat.users.some((user) => user.id === USER_LOGGED_ID)
            );
          }
          setChatRoomList(chatsOfUser);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteChatroom = async () => {
    try {
      const isChatDeleted = await ApiService.delete(
        `/chatroom/${selectedRoom.id}`
      ).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Sala excluída com sucesso!", {
            variant: "success",
          });
          websocket.emit('removeRoom', {
            roomId: selectedRoom.id,
            roomName: selectedRoom.nome,
            users: selectedRoom.users,
            userWhoDeleted: localStorage.getItem('username')
          })
          getChatRoomsForCurrentUser();
          setSelectedRoom(null);
          return true;
        }
      });
      return isChatDeleted;
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Ocorreu um erro ao excluir a sala!", {
        variant: "error",
      });
    }
  };

  return (
    <JumboContentLayout
      sidebar={
        <ChatAppSidebar
          onRoomSelect={handleRoomSelect}
          selectedRoom={selectedRoom}
          getChatRoomsForCurrentUser={getChatRoomsForCurrentUser}
          chatRoomList={chatRoomList}
        />
      }
      layoutOptions={layoutOptions}
    >
      {selectedRoom && (
        <ChatContent
          selectedRoom={selectedRoom}
          handleSelectRoom={handleRoomSelect}
          handleDeleteChatroom={handleDeleteChatroom}
        />
      )}
    </JumboContentLayout>
  );
};

export default ChatApp;
