import React, { useCallback, useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DataTable from '../DataTable';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ApiService from 'app/services/config';
import { FilesItem } from 'app/components/FilesItem/FilesItem';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import FileListSlimTwo from 'app/components/FileUploadSlimTwo/FileListSlimTwo';
import MUIDataTable from 'mui-datatables';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { PermissionContext } from 'app/contexts/PermissionContext';

const VideosList = ({ values, infos, setFieldValue }) => {
  const { userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState([]);

  const storedUserId = localStorage.getItem('id');
  const {hasPermission}  = useContext(PermissionContext);

  const [filesInfo, setFilesInfo] = useState({
    nome: '',
    tipo_de_arquivo: '',
  });

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);

  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [openVideo, setOpenVideo] = useState(false);

  const getUsers = useCallback(async () => {
    try {
      await ApiService.get('/users')
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== '') {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/prestadores?searchTerm=${inputValue}`
      );
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    /*  setFiles([...files, file]); */

    setFilesSelected([...filesSelected, file]); // Mantém filesSelected em sincronia com files
    setPreviewImage([...previewImage, URL.createObjectURL(file)]);
  };

  const handleEditFile = (fileToEdit) => {
    console.log(fileToEdit);
    setFilesInfo(fileToEdit);
    setUserSelected({ select: fileToEdit?.Prestador });
    setFiles([fileToEdit]);
    setIsEditMode(true);
    setOpen(true);
  };

  console.log(userSelected);
  const removeFile = (index) => {
    /* setFiles(files.filter((file, i) => i !== index)); */

    const updatedFiles = filesSelected.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setFilesSelected(updatedFiles); // Mantém filesSelected em sincronia com files
    const updatedPreviewImage = [...previewImage];
    updatedPreviewImage.splice(index, 1); // Remove a imagem correspondente
    setPreviewImage(updatedPreviewImage);
  };

  const handleDownload = async (docName) => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_KEY}/public/uploads/videos/${docName}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', docName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Erro ao fazer download:', error);
    }
  };

  const modalAlert = (docsId) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeDoc(docsId);
      }
    });
  };

  const removeDoc = useCallback(
    async (docsId) => {
      ApiService.delete(`/videos/${docsId}`)
        .then((response) => {
          const removedEvolution = values.Video.filter(
            (item) => item.id !== docsId
          );
          setFieldValue('Video', removedEvolution);
          enqueueSnackbar('Documento removido com sucesso!', {
            variant: 'success',
          });
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: 'error',
            });
          }
        });
    },
    [enqueueSnackbar, setFieldValue, values.Video]
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFilesInfo([]);
    setPreviewImage([]);
    setFilesSelected([]);
    setFiles([]);
    setUserSelected(null);
    setOpen(false);
    setIsEditMode(false);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'Prestador.nome',
      label: 'Prestador',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'tipo_de_arquivo',
      label: 'Finalidade do arquivo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'nome',
      label: 'Info Complementar',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value ? (
              <Tooltip title='Visualizar arquivo' TransitionComponent={Zoom}>
                <IconButton
                  onClick={() => {
                    setOpenVideo(true);
                    setFilesInfo(values?.Video[tableMeta?.rowIndex]);
                  }}
                >
                  <PlayCircleIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Typography mt={1}> Não possui </Typography>
            )}
          </>
        ),
      },
    },

    {
      name: 'action',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        display: `${!hasPermission('Paciente Evolução', 'update') ? 'none' : undefined}`,
        customBodyRender: (value, tableMeta, updateValue) => {
          const fileId = tableMeta.rowData[0];
          const docName = tableMeta.rowData[3];
          console.log(tableMeta.rowIndex);
          return (
            <Box display={'flex'} gap={1}>
              {/* <Tooltip title='Download' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='download'
                  color='success'
                  download={docName}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => handleDownload(docName)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip title='Editar' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='editar'
                  color='info'
                  onClick={() =>
                    handleEditFile(values?.Video[tableMeta?.rowIndex])
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title='Remover' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='remover'
                  color='error'
                  onClick={() => modalAlert(fileId)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    searchAlwaysOpen: true,
    searchable: true,
    enableNestedDataAccess: '.',
    textLabels: {
      body: {
        noMatch: 'Nenhum resultado encontrado',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'de',
      },
    },
  };

  return (
    <div>
      <Grid container rowSpacing={2}>
        {!hasPermission('Paciente Evolução', 'create') ? undefined : (
          <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            <Button variant='outlined' onClick={handleOpen}>
              Enviar Vídeos
            </Button>
          </Box>
        )}

        <Grid item xs={12}>
          <MUIDataTable
            title={'Vídeos'}
            data={values?.Video}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: 300, sm: 1000 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant='h1' gutterBottom>
            Envio de Vídeos
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4}>
              <TextField
                fullWidth
                label='Paciente'
                value={values?.username}
                disabled
                /*  value={phono?.queixa}
                                 onChange={(event) => {
                                     setPhono({
                                         ...phono,
                                         queixa: event.target.value,
                                     });
                                 }} */
              />
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                title='Digite o nome para buscar a pessoa no sistema'
                TransitionComponent={Zoom}
                placement='top-end'
              >
                <Autocomplete
                  fullWidth
                  disablePortal
                  getOptionLabel={(option) => option.nome}
                  options={optionsList}
                  loading={loading}
                  inputValue={userInput}
                  onInputChange={handleInputChange}
                  value={userSelected?.select ?? null}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setUserSelected((prevValues) => ({
                        select: newValue,
                      }));
                    }
                  }}
                  noOptionsText={'Nenhum resultado encontrado'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Prestador'
                      placeholder='Prestador'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? 'carregando...' : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id='tipo_de_arquivo'>Tipo de arquivo</InputLabel>
                <Select
                  labelId='tipo_de_arquivo'
                  id='tipo_de_arquivo'
                  label='Tipo de Vídeo'
                  value={filesInfo?.tipo_de_arquivo}
                  onChange={(event) => {
                    setFilesInfo({
                      ...filesInfo,
                      tipo_de_arquivo: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={'atestado'}>Atestado</MenuItem>
                  <MenuItem value={'cirurgia'}>Cirurgia</MenuItem>
                  <MenuItem value={'contrato'}>Contrato</MenuItem>
                  <MenuItem value={'exame'}>Exame</MenuItem>
                  <MenuItem value={'outros'}>Outros</MenuItem>
                  <MenuItem value={'prontuario'}>Prontuário</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={3}
              >
                {!isEditMode && (
                  <Button
                    variant='contained'
                    component='label'
                    color='info'
                    sx={{
                      textAlign: 'center',
                      width: '15%',
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                    <input
                      type='file'
                      hidden
                      name='docs'
                      onChange={uploadHandler}
                    />
                  </Button>
                )}

                <Button
                  variant='outlined'
                  endIcon={<SendIcon />}
                  onClick={() => {
                    if (isEditMode) {
                      const docId = filesInfo.id;
                      const userFilesEdit = {
                        ...filesInfo,
                        Prestador: undefined,
                        User: undefined,
                        prestador_id: userSelected?.select?.id,
                      };
                      ApiService.put(`/videos/${docId}`, userFilesEdit)
                        .then((response) => {
                          console.log(response.data);
                          enqueueSnackbar('Vídeo atualizado com sucesso!', {
                            variant: 'success',
                          });
                          const updatedTodos = values.Video.map((todo) => {
                            if (todo.id === response.data.id) {
                              return response.data; // Retorna a avaliacao atualizado
                            }
                            return todo;
                          });
                          setFieldValue('Video', updatedTodos);

                          setFilesInfo([]);
                          setPreviewImage([]);
                          setFilesSelected([]);
                          setFiles([]);
                          setOpen(false);
                          /* getInfosDoc(); */
                        })
                        .catch((error) => {
                          const errorMessage = error.response.data.message;
                          console.log(errorMessage);
                          if (Array.isArray(errorMessage)) {
                            enqueueSnackbar(`${errorMessage[0]}`, {
                              variant: 'error',
                            });
                          } else {
                            enqueueSnackbar(`${errorMessage}`, {
                              variant: 'error',
                            });
                          }
                        });
                    } else {
                      const userDoc = {
                        user_id: Number(userId),
                        prestador_id: userSelected?.select?.id,
                        nome: filesInfo.nome,
                        tipo_de_arquivo: filesInfo.tipo_de_arquivo,
                      };
                      console.log(userDoc);

                      const formData = new FormData();
                      formData.append('user_id', userDoc.user_id);
                      formData.append('prestador_id', userDoc.prestador_id);

                      formData.append('nome', userDoc.nome);

                      formData.append(
                        'tipo_de_arquivo',
                        userDoc.tipo_de_arquivo
                      );

                      for (let i = 0; i < filesSelected.length; i++) {
                        formData.append('files', filesSelected[i]);
                      }

                      ApiService.post(`/videos`, formData)
                        .then((response) => {
                          console.log(response.data);
                          console.log(values);
                          enqueueSnackbar('Vídeo criado com sucesso!', {
                            variant: 'success',
                          });
                          setFieldValue('Video', [
                            ...values.Video,
                            ...response.data,
                          ]);
                          setFilesInfo({
                            nome: '',
                            tipo_de_arquivo: '',
                          });
                          setPreviewImage([]);
                          setFilesSelected([]);
                          setUserSelected(null);
                          setOpen(false);
                        })
                        .catch((error) => {
                          const errorMessage = error.response?.data?.message;
                          console.log(error);
                          if (Array.isArray(errorMessage)) {
                            enqueueSnackbar(`${errorMessage[0]}`, {
                              variant: 'error',
                            });
                          } else {
                            enqueueSnackbar(`${errorMessage}`, {
                              variant: 'error',
                            });
                          }
                        });
                    }
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FileListSlimTwo
                files={files}
                filesSelected={filesSelected}
                removeFile={removeFile}
                previewImage={previewImage}
                isEditMode={isEditMode}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openVideo}
        onClose={handleCloseVideo}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: 300, sm: 1000 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <video
            id='modal-modal-description'
            controls
            style={{ width: '100%', height: 'auto' }}
          >
            <source
              src={`${process.env.REACT_APP_API_KEY}/public/uploads/videos/${filesInfo.nome}`}
              type='video/mp4'
            />
            O seu navegador nao suporta o video
          </video>
        </Box>
      </Modal>
    </div>
  );
};

export default VideosList;
