import React, { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, CircularProgress, FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LocationItem } from 'app/components/LocationItem/LocationItem';
import { Field, FieldArray } from 'formik';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import axios from 'axios';

function Locations({ address, setAddress, values, setFieldValue }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { id } = useParams();

    const addressSchema = Yup.object().shape({
        /* cep: Yup.string().required('CEP é obrigatório'),
        logradouro: Yup.string().required('Logradouro é obrigatório'),
        numero: Yup.string().required('Número é obrigatório'),
        complemento: Yup.string(),
        uf: Yup.string().required('UF é obrigatória'),
        bairro: Yup.string().required('Bairro é obrigatório'),
        cidade: Yup.string().required('Cidade é obrigatória'), */
        tipo_de_endereco: Yup.string().when(
            [
                'cep',
                'logradouro',
                'numero',
                'complemento',
                'uf',
                'bairro',
                'cidade',
            ],
            {
                is: (
                    cep,
                    logradouro,
                    numero,
                    complemento,
                    uf,
                    bairro,
                    cidade
                ) =>
                    cep ||
                    logradouro ||
                    numero ||
                    complemento ||
                    uf ||
                    bairro ||
                    cidade,
                then: Yup.string().required('Tipo de endereço é obrigatório'),
                otherwise: Yup.string(),
            }
        ),
    });

    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const Swal = useSwalWrapper();

    const handleEditAddress = (addressToEdit) => {
        setAddress(addressToEdit);
        setIsEditMode(true);
    };
    const { enqueueSnackbar } = useSnackbar();
    const modalAlert = (addressId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                console.log('bem bem antes', values.Enderecos);
                removeAddress(addressId);
            }
        });
    };

    const removeAddress = useCallback(
        async (addressId) => {
            ApiService.delete(`/addresses/${addressId}`)
                .then((response) => {
                    const addressEdit = address.id === addressId;
                    const removedAddress = values.Enderecos.filter(
                        (address) => address.id !== addressId
                    );
                    setFieldValue('Enderecos', removedAddress);
                    enqueueSnackbar('Endereço removido com sucesso!', {
                        variant: 'success',
                        
                    });
                    if (addressEdit) {
                        setIsEditMode(false);
                        setAddress({
                            // Limpa o estado do endereço
                            cep: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            uf: '',
                            bairro: '',
                            cidade: '',
                            tipo_de_endereco: '',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [enqueueSnackbar, setFieldValue, values.Enderecos]
    );

    // Verifica se todos os campos do endereço estão vazios
    const allFieldsEmpty = Object.values(address).every((value) => !value);

    const checkCep = async (e) => {
        setIsLoading(true);
        const { value } = e.target;
        const cep = value.replace(/\D/g, '');
        await axios
            .get(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => {
                console.log(res);
                setAddress((prevAddress) => ({
                    ...prevAddress,
                    logradouro: res.data.logradouro,
                    bairro: res.data.bairro,
                    cidade: res.data.localidade,
                    uf: res.data.uf,
                }));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    return (
        <div>
            <Grid container>
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '15px' }}
                >
                    Endereços
                </Typography>
            </Grid>

            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid xs={2}>
                    <InputMask
                        mask='99999-999'
                        value={address.cep}
                        onChange={(event) => {
                            setAddress({
                                ...address,
                                cep: event.target.value,
                            });
                        }}
                        onBlur={(e) => checkCep(e)}
                    >
                        {(inputProps) => (
                            <TextField
                                fullWidth
                                name='Enderecos.cep'
                                label='CEP'
                                error={!!errors.cep} // Verifica se há erro no campo 'cep'
                                helperText={errors.cep} // Exibe a mensagem de erro do campo 'cep'
                                {...inputProps}
                            />
                        )}
                    </InputMask>
                </Grid>
                {isLoading && (
                    <CircularProgress style={{ alignSelf: 'center' }} />
                )}
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        name='Enderecos.logradouro'
                        label='Logradouro'
                        value={address.logradouro}
                        onChange={(event) => {
                            setAddress({
                                ...address,
                                logradouro: event.target.value,
                            });
                        }}
                        error={!!errors.logradouro} // Verifica se há erro no campo 'logradouro'
                        helperText={errors.logradouro}
                    />
                </Grid>

                <Grid xs={2}>
                    <TextField
                        fullWidth
                        name='Enderecos.numero'
                        label='Numero'
                        value={address.numero}
                        onChange={(event) => {
                            setAddress({
                                ...address,
                                numero: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid xs={4}>
                    <TextField
                        fullWidth
                        name='Enderecos.complemento'
                        label='Complemento'
                        value={address.complemento}
                        onChange={(event) => {
                            setAddress({
                                ...address,
                                complemento: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid xs={2}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='UF'>UF</InputLabel>
                        <Select
                            labelId='UF'
                            id='UF'
                            value={address.uf}
                            onChange={(event) => {
                                setAddress({
                                    ...address,
                                    uf: event.target.value,
                                });
                            }}
                            label='UF'
                        >
                            <MenuItem value={'AC'}>Acre</MenuItem>
                            <MenuItem value={'AL'}>Alagoas</MenuItem>
                            <MenuItem value={'AP'}>Amapá</MenuItem>
                            <MenuItem value={'AM'}>Amazonas</MenuItem>
                            <MenuItem value={'BA'}>Bahia</MenuItem>
                            <MenuItem value={'CE'}>Ceará</MenuItem>
                            <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                            <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                            <MenuItem value={'GO'}>Goiás</MenuItem>
                            <MenuItem value={'MA'}>Maranhão</MenuItem>
                            <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                            <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                            <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                            <MenuItem value={'PA'}>Pará</MenuItem>
                            <MenuItem value={'PB'}>Paraíba</MenuItem>
                            <MenuItem value={'PR'}>Paraná</MenuItem>
                            <MenuItem value={'PE'}>Pernambuco</MenuItem>
                            <MenuItem value={'PI'}>Piauí</MenuItem>
                            <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                            <MenuItem value={'RN'}>
                                Rio Grande do Norte
                            </MenuItem>
                            <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                            <MenuItem value={'RO'}>Rondônia</MenuItem>
                            <MenuItem value={'RR'}>Roraima</MenuItem>
                            <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                            <MenuItem value={'SP'}>São Paulo</MenuItem>
                            <MenuItem value={'SE'}>Sergipe</MenuItem>
                            <MenuItem value={'TO'}>Tocantins</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid xs={6}>
                    <TextField
                        fullWidth
                        name='Enderecos.bairro'
                        label='Bairro'
                        value={address.bairro}
                        onChange={(event) => {
                            setAddress({
                                ...address,
                                bairro: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid xs={4}>
                    <TextField
                        fullWidth
                        name='Enderecos.cidade'
                        label='Cidade'
                        value={address.cidade}
                        onChange={(event) => {
                            setAddress({
                                ...address,
                                cidade: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid xs={4}>
                    <FormControl
                        fullWidth
                        variant='outlined'
                        error={!!errors.tipo_de_endereco}
                    >
                        <InputLabel id='tipo_de_endereco'>
                            Tipo de Endereço
                        </InputLabel>
                        <Select
                            labelId='tipo_de_endereco'
                            id='tipo_de_endereco'
                            value={address.tipo_de_endereco}
                            onChange={(event) => {
                                setAddress({
                                    ...address,
                                    tipo_de_endereco: event.target.value,
                                });
                            }}
                            label='Tipo de Endereço'
                        >
                            <MenuItem value={'Residencial'}>
                                Residencial
                            </MenuItem>
                            <MenuItem value={'Comercial'}>Comercial</MenuItem>
                        </Select>
                        {errors.tipo_de_endereco && (
                            <FormHelperText sx={{ color: 'red' }}>
                                {errors.tipo_de_endereco}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {id ? (
                    <Grid xs={6}>
                        <Button
                            variant='contained'
                            style={{ marginTop: '10px' }}
                            disabled={allFieldsEmpty}
                            onClick={() => {
                                addressSchema
                                    .validate(address, { abortEarly: false })
                                    .then(() => {
                                        setErrors({});
                                        if (isEditMode) {
                                            console.log(address);
                                            const addressId = address.id;

                                            ApiService.put(
                                                `/addresses/${addressId}`,
                                                address
                                            )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Endereço atualizado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    const updatedTodos =
                                                        values.Enderecos.map(
                                                            (todo) => {
                                                                if (
                                                                    todo.id ===
                                                                    response
                                                                        .data.id
                                                                ) {
                                                                    return response.data; // Retorna o endereço atualizado
                                                                }
                                                                return todo;
                                                            }
                                                        );
                                                    setFieldValue(
                                                        'Enderecos',
                                                        updatedTodos
                                                    ); // Atualiza a lista de endereços com o endereço atualizado
                                                    setIsEditMode(false); // Sai do modo de edição
                                                    setAddress({
                                                        // Limpa o estado do endereço
                                                        cep: '',
                                                        logradouro: '',
                                                        numero: '',
                                                        complemento: '',
                                                        uf: '',
                                                        bairro: '',
                                                        cidade: '',
                                                        tipo_de_endereco: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        } else {
                                            const userAddress = {
                                                user_id: Number(id),
                                                cep: address.cep,
                                                logradouro: address.logradouro,
                                                numero: address.numero,
                                                complemento:
                                                    address.complemento,
                                                uf: address.uf,
                                                bairro: address.bairro,
                                                cidade: address.cidade,
                                                tipo_de_endereco:
                                                    address.tipo_de_endereco,
                                            };

                                            ApiService.post(
                                                `/addresses`,
                                                userAddress
                                            )
                                                .then((response) => {
                                                    const newAddress = [
                                                        ...values.Enderecos,
                                                        response.data,
                                                    ];
                                                    enqueueSnackbar(
                                                        'Endereço criado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    setFieldValue(
                                                        'Enderecos',
                                                        newAddress
                                                    );
                                                    setAddress({
                                                        cep: '',
                                                        logradouro: '',
                                                        numero: '',
                                                        complemento: '',
                                                        uf: '',
                                                        bairro: '',
                                                        cidade: '',
                                                        tipo_de_endereco: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        }
                                    })
                                    .catch((validationErrors) => {
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                enqueueSnackbar(error.message, {
                                                    variant: 'error',
                                                    
                                                });
                                            }
                                        );
                                        const errors = {};
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                errors[error.path] =
                                                    error.message;
                                            }
                                        );
                                        console.log(errors);
                                        setErrors(errors);
                                    });
                            }}
                        >
                            {isEditMode
                                ? 'Editar Endereço'
                                : 'Incluir Endereço'}
                        </Button>
                    </Grid>
                ) : undefined}
            </Grid>

            {id ? (
                <Box marginTop={3}>
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleAccordionChange('panel1')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                >
                                    <PostAddIcon />
                                </span>
                                Residencial
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FieldArray name='Enderecos'>
                                {({ remove, push }) =>
                                    values?.Enderecos?.filter(
                                        (endereco) =>
                                            endereco.tipo_de_endereco ===
                                            'Residencial'
                                    ).map((endereco) => (
                                        <LocationItem
                                            address={endereco}
                                            handleEditAddress={
                                                handleEditAddress
                                            }
                                            modalAlert={modalAlert}
                                        />
                                    ))
                                }
                            </FieldArray>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel2'}
                        onChange={handleAccordionChange('panel2')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                >
                                    <PostAddIcon />
                                </span>
                                Comercial
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FieldArray name='Enderecos'>
                                {({ remove, push }) =>
                                    values?.Enderecos?.filter(
                                        (endereco) =>
                                            endereco.tipo_de_endereco ===
                                            'Comercial'
                                    ).map((endereco) => (
                                        <LocationItem
                                            address={endereco}
                                            handleEditAddress={
                                                handleEditAddress
                                            }
                                            modalAlert={modalAlert}
                                        />
                                    ))
                                }
                            </FieldArray>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            ) : undefined}
        </div>
    );
}

export default Locations;
