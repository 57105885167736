import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback } from "react";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

const todasPerguntas = [
  {
    categoria: "COMUNICAÇÃO RECEPTIVA",
    perguntas: [
      "Presta atenção e junta-se com interesse durante 5-10 minutos enquanto o adulto lê um livro familiar usando frases simples",
      "Segue ordens novas de um passo envolvendo objetos /ações familiares",
      "Identifica muitos objetos comuns e as suas imagens: peças de roupa, objetos relacionados com refeições, higiene, jogo e alimentos (50 objetos)",
      'Responde apropriadamente a perguntas "Sim/Não" sobre preferências',
      "Identifica cinco ou mais ações em imagens ou livros",
      "Segue duas ou mais instruções dadas em rotinas situacionais (hora de dormir: pegar num livro e entrar na cama;escovar os dentes: pegar na escova e na pasta)",
      "Percebe as relações espaciais envolvendo objetos (p.e., debaixo de, perto de)",
      "Diferencia os conceitos de tamanhos iniciais grande/pequeno",
      "Diferencia pelo menos quatro cores diferentes quando solicitado",
      "Identifica 20 itens pelo som (p.e., animais, telefone,\"Qual animal que faz 'Miau, miau'?\")",
      "Compreende a função de objetos comuns (passear, cortar, comer, dormir, calçar pés, beber, etc.)",
      'Compreende os pronomes pessoais "meu" e "teu"',
      "Identifica 10 ações através de imagens, escolhas, representações",
      "Segue duas ou mais ações não relacionadas num contexto novo",
    ],
  },
  {
    categoria: "COMUNICAÇÃO EXPRESSIVA",
    perguntas: [
      "Produz uma combinação de duas a três palavras para uma variedade de intenções comunicativas (p.e., pedir, cumprimentar, chamar a atenção, protestar)",
      "Produz enunciados de duas ou mais palavras para fazer comentários a outra pessoa",
      "Nomeia ações em imagens ou livros",
      "Faz comentários e pedidos de localização (cima, baixo, dentro, em cima)",
      "Faz comentários e pedidos usando formas possessivas iniciais (meu, teu)",
      'Gesticula ou vocaliza "Não sei" em contexto',
      "Usa de forma consistente o nome das outras pessoas para chamar a atenção",
      'Transmite uma mensagem simples a outra pessoa ("Vai dizer Olá à mãe")',
      'Diz "Olá" e "Adeus" apropriadamente, tanto iniciando como respondendo',
      'Usa pronomes para si e para os outros "variantes do "eu" e "tu")',
      "Usa palavras e gestos simples para descrever experiências pessoais",
      "Nomeia uma ou duas cores",
      'Responde apropriadamente a questões "O quê?"',
      'Responde apropriadamente a questões "Onde?"',
      'Responde apropriadamente a questões "Quem?"',
      'Faz perguntas simples de "Sim/Não" usando uma entonação ascendente (pode ser um enunciado de uma palavra com uma entoação ascendente.)',
      'Faz perguntas de "O quê?" e "Onde?"',
      "Responde a perguntas sobre informações simples: nome, idade, cor da camisola, etc",
    ],
  },
  {
    categoria: "COMPETÊNCIAS SOCIAIS: ADULTOS E PARES",
    perguntas: [
      "Joga jogos simples de motricidade grossa (p.e., bola, escondidas, rodas de canções)",
      "Partilha e mostra objeto quando o par pede",
      "Imita e realiza novas canções/jogos de dedos em situação de grupo",
      "Responde apropriadamente a pedidos/instruções simples dos pares",
      "Inicia interações e imitações dos pares",
      "Participa na rotina de jogo dramático familiar com o parceiro em jogo paralelo",
      "Toma a vez em jogos de tabuleiro simples",
      'Usa termos de cortesia: "Por favor", "Obrigada", "Desculpe"',
      'Imita uma série de novas ações motoras em pé e em movimento como em "Segue o líder" ou movimentos de animais',
      "Participa em atividades de jogo que envolvam guiões verbais",
      "Chama frequentemente a atenção dos outros para os objetos verbalizando e gesticulando para comentar, mostrar, partilhar e pedir",
      "Responde às licitações dos outros para a atenção conjunta olhando e comentando",
      "Identifica receptivamente o afeto (feliz, triste, zangada,assustada) a partir de fotografias, nos outros, e/ou em desenhos a lápis",
      "Identifica expressivamente o afeto a partir de fotografias, nos outros, e/ou em desenhos a lápis",
      "Faz o seu próprio rosto refletir uma emoção (feliz, triste, zangada, assustada)",
    ],
  },
  {
    categoria: "COGNIÇÃO",
    perguntas: [
      "Faz correspondência de letras no próprio nome",
      "Faz correspondência de letras",
      "Faz correspondência de palavras",
      "Faz correspondência de números",
      "Identifica receptiva e expressivamente algumas letras, números, formas e cores",
      "Joga jogos que envolvam a memória para objetos escondidos",
      "Categoriza objetos/imagens em oito classes",
      "Compreende a relação entre as quantidades e os símbolos numéricos até o número cinco",
      "Conta o número correto de objetos até cinco",
      'Sequencia três ou mais imagens pela ordem correta e narra a sequência de imagens usando linguagem como "primeiro, depois"',
    ],
  },
  {
    categoria: "JOGO",
    perguntas: [
      "Faz jogo construtivo envolvendo esquemas complexos de sequenciação de vários objetos coordenados (p.e., os caminhões na estrada, blocos para fazer uma construção, contas para fazer um colar)",
      "Liga três ou mais ações relacionadas numa sequência de jogo",
      "Executa duas ou mais ações ligadas numa boneca ou animal quando instruída",
      "Coloca fisicamente figuras em miniaturas de móveis, veículos, etc., quando apropriado",
      "Realiza ações sobre bonecas ou animais de brincar espontaneamente",
      "Organiza adereços para o tema",
    ],
  },
  {
    categoria: "MOTRICIDADE FINA",
    perguntas: [
      "Completa um puzzle de cinco a seis peças interligadas",
      "Imita o desenho de um círculo, cruz, quadrado, linha diagonal",
      "Imita e constrói diferentes estruturas de blocos usando uma variedade de materiais de construção (blocos, legos, tinkers, etc.)",
      "Faz enfiamentos de ponto corrido (alinhavo)",
      "Traça linhas e curvas com o dedo ou com material de escrita",
      "Usa uma variedade de utensílios para pegar e largar objetos: pinças, garfos",
      "Traça uma variedade de formas",
      "Usa uma tesoura com o domínio apropriado e usa a mão oposta para estabilizar e virar o papel",
      "Corta numa linha - linhas retas e curvas",
      "Realiza simples projetos de expressão plástica com duas etapas (cortar e colar, carimbar com a almofada de tinta;dobrar papel e cortar uma linha)",
      "Realiza vários esquemas diferentes com plasticina (massinha) - usa uma variedade de utensílios",
    ],
  },
  {
    categoria: "MOTRICIDADE GROSSA",
    perguntas: [
      "Conduz bem o triciclo (pedais e volante, segue uma rota)",
      "Chuta de forma adequada e com equilíbrio",
      "Usa todos os equipamentos do parque infantil com ajuda",
      'Joga jogos de "perseguição" com os adultos e os pares, correndo suavemente, mudando de direção com bom equilíbrio',
      "Imita ações de motricidade grossa com movimentos para canções e música",
      "Atira ao alvo dissimuladamente",
      "Salta com os dois pés juntos",
      "Salta com um pé só",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL",
    perguntas: [
      "Usa a colher, o garfo e o copo ordenadamente e sem entornar",
      "Comporta-se apropriadamente num restaurante",
      "Usa ícones ou outros sistemas de símbolos para as escolhas, os horários, etc., de forma autônoma, se necessário em casa e na escola",
      "Leva os seus materiais para o carro, a casa e a escola",
      "Abre e fecha a mochila de forma autônoma; coloca e remove objetos quando solicitado",
      "Veste-se e despe-se quando é apropriado (desaperta roupas com fechos- fecho-éclair e molas)",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL: HIGIENE",
    perguntas: [
      "Usa o banheiro de forma autônoma, faz todas as etapas, por iniciativa própria ou quando mandada",
      "Lida bem com a roupa no banheiro, exceto os fechos",
      "Completa todos os passos da lavagem de mãos de forma autônoma",
      "Limpa o rosto com uma toalha quente quando a toalha é entregue",
      "Passa a escova ou o pente no cabelo",
      "Tapa a boca quando tosse ou espirra",
      "Ajuda ativamente no banho e a secar-se após o banho",
      "Lavas os dentes com a escova, aplicando pelo menos algumas escovadelas",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL: TAREFAS",
    perguntas: [
      "Alimenta/dá água a um animal",
      "Ajuda a levantar a mesa",
      "Ajuda a tirar a louça da máquina",
      "Põe a roupa lavada nas gavetas",
      "Pega os seus pertences quando solicitado",
    ],
  },
];

const InterdisciplinaryThree = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { quizzId, userId } = useParams();
  const location = useLocation();

  const storedColaboratorId = localStorage.getItem("id");

  const [quizzes, setQuizzes] = useState([]);
  const [answers, setAnswers] = useState([]);

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const date = query.get("date");

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}`)
        .then((response) => {
          console.log(response.data?.pergunta);

          setQuizzes(response.data?.pergunta);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, quizzId]);

  const getQuizzesAnswers = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}/user/${userId}?date=${date}`)
        .then((response) => {
          console.log(response.data);

          setQuizzes(response.data?.pergunta);

          /* setAnswers(respostasFormatadas); */
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname.includes("edit")) {
      getQuizzesAnswers();
    } else {
      getQuizzes();
    }
  }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

  console.log(answers);

  const getSelectedAnswer = (respostas, perguntaId) => {
    // Verifica se há uma resposta salva no estado answers para a pergunta específica
    const respostaSalva = answers.find(
      (resposta) => resposta.pergunta_id === perguntaId
    );

    // Se houver uma resposta salva, retorna o ID dessa resposta
    if (respostaSalva) {
      return respostaSalva.resposta_id;
    }

    // Se não houver uma resposta salva, verifica se há uma resposta do servidor
    const respostaUsuario = respostas.find(
      (resposta) =>
        resposta?.Resposta_Usuario?.length > 0 &&
        resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
    );

    // Se houver uma resposta do servidor, retorna o ID dessa resposta
    if (respostaUsuario) {
      return respostaUsuario.id;
    }

    // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
    return "";
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Aba de Avaliação Interdisciplinar"}
        subtitle={"Nível 3"}
        titleUrl={"/"}
      />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                {todasPerguntas.map((categoria, index) => (
                  <Accordion defaultExpanded my={2}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography variant={"h3"} fontWeight={500}>{categoria.categoria}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        {categoria.perguntas.map((pergunta, index) => (
                          <Grid item xs={12} key={index}>
                            <Stack
                              direction="row"
                              gap={2}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              width="100%"
                            >
                              <Typography>
                                {index + 1} - {pergunta}
                              </Typography>
                              <Select sx={{ minWidth: "150px" }}>
                                <MenuItem value={"adquirido"}>
                                  Adquirido
                                </MenuItem>
                                <MenuItem value={"parcial"}>Parcial</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"nao"}>Não</MenuItem>
                                <MenuItem value={"nao_observado"}>
                                  Não observado
                                </MenuItem>
                              </Select>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">Inclusão</TableCell>
                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                    <TableCell align="center">Alteração</TableCell>
                    <TableCell align="center">
                      {" "}
                      Colaborador Alteração{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                if (location?.pathname.includes("edit")) {
                  ApiService.put(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                }
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default InterdisciplinaryThree;
