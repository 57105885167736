import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import ApiService from 'app/services/config';
import { Link, useParams } from 'react-router-dom';

import { Box, IconButton, Tooltip, Typography, Zoom } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const InterventionPlanList = ({ values, infos, setFieldValue, tabIndex }) => {
  const { userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();

  const modalAlert = (interventionPlanId) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeInterventionPlan(interventionPlanId);
      }
    });
  };

  const removeInterventionPlan = useCallback(
    async (interventionPlanId) => {
      ApiService.delete(`/intervention-plans/${interventionPlanId}`)
        .then((response) => {
          const removedEvolution = values.Plano_De_Intervencao.filter(
            (item) => item.id !== interventionPlanId
          );
          setFieldValue('Plano_De_Intervencao', removedEvolution);
          enqueueSnackbar('Plano de intervenção removido com sucesso!', {
            variant: 'success',
          });
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: 'error',
            });
          }
        });
    },
    [enqueueSnackbar, setFieldValue, values.Plano_De_Intervencao]
  );

  const interventionPlanLabels = {
    nivel_1: 'Nível 1',
    nivel_2: 'Nível 2',
    nivel_3: 'Nível 3',
    nivel_4: 'Nível 4',
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'Prestador.nome',
      label: 'Prestador',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'plano_de_intervencao_modelo',
      label: 'Finalidade do arquivo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{interventionPlanLabels[value]}</Typography>;
        },
      },
    },

    {
      name: 'action',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const interventionPlanId = tableMeta.rowData[0];
          const docName = tableMeta.rowData[3];
          console.log(tableMeta.rowIndex);
          return (
            <Box display={'flex'} gap={1}>
              <Tooltip title='Editar' TransitionComponent={Zoom}>
                <Link
                  to={`/evolution/user/${userId}/intervention/edit/${interventionPlanId}`}
                  style={{ textDecoration: 'none' }}
                  state={{
                    fromTab: tabIndex,
                  }}
                >
                  <IconButton aria-label='editar' color='info'>
                    <EditIcon />
                  </IconButton>
                </Link>
              </Tooltip>

              <Tooltip title='Gerar pdf e incluir arquivos' TransitionComponent={Zoom}>
                <Link
                  to={`/evolution/user/${userId}/intervention/file/${interventionPlanId}`}
                  style={{ textDecoration: 'none' }}
                  state={{
                    fromTab: tabIndex
                  }}
                >
                  <IconButton aria-label='Gerar pdf e incluir arquivos' color='secondary'>
                    <PictureAsPdfIcon />
                  </IconButton>
                </Link>
              </Tooltip>

              <Tooltip title='Remover' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='remover'
                  color='error'
                  onClick={() => modalAlert(interventionPlanId)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    searchAlwaysOpen: true,
    searchable: true,
    enableNestedDataAccess: '.',
    textLabels: {
      body: {
        noMatch: 'Nenhum resultado encontrado',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'de',
      },
    },
  };
  return (
    <div>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Link
            to={`/evolution/user/${userId}/intervention/new`}
            style={{ textDecoration: 'none' }}
            state={{
              fromTab: tabIndex
            }}
          >
            <Button variant='outlined'>Novo lançamento</Button>
          </Link>
        </Box>

        <Grid item xs={12}>
          <MUIDataTable
            title={'Plano de Intervenção'}
            data={values?.Plano_De_Intervencao}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InterventionPlanList;
