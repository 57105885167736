import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Zoom,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ApiService from "app/services/config";
import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { formatDateWithMoment } from "@jumbo/utils";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const GenerateInterventionPlanPDF = ({
  tabIndex,
  interventionName,
  patient,
}) => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const { userId } = useParams();

  const [quizzes, setQuizzes] = useState([]);
  const [userQuizz, setUserQuizz] = useState([]);
  const [quizSelected, setQuizSelected] = useState({
    checklist: "",
  });
  const [evaluationNameSelected, setEvaluationNameSelected] = useState(null);

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(
        `/quizzes?userId=${userId}&type1=${interventionName}`
      )
        .then((response) => {
          console.log(response.data);

          const respostasAgrupadas = response.data?.respostasAgrupadas;

          const quizzesAvaliacaoInterdisciplinar = Object.values(
            respostasAgrupadas
          ).flatMap((quizzes) =>
            quizzes.filter((quiz) => {
              const idDoQuestionario = quiz?.Pergunta?.Questionario?.id;
              return [11, 12, 13, 14].includes(idDoQuestionario);
            })
          );

          const quizzesUnicos = Array.from(
            quizzesAvaliacaoInterdisciplinar
              .reduce((map, quiz) => {
                const key = quiz.data_horario_resposta;
                if (!map.has(key)) map.set(key, quiz);
                return map;
              }, new Map())
              .values()
          );

          setUserQuizz(quizzesUnicos);

          setQuizzes(response?.data?.quizzes);
          //setUserQuizz(quizzesAvaliacaoInterdisciplinar);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, userId, interventionName]);

  useEffect(async () => {
    getQuizzes();
    setEvaluationNameSelected(interventionName);
  }, [userId, interventionName]);

  const getQuizzesAnswers = useCallback(
    async (quizId, date) => {
      try {
        await ApiService.get(
          `/quizzes/document/info/${quizId}/user/${userId}?date=${date}`
        )
          .then(async (response) => {
            /*  setQuizInfo(response.data?.userQuizz);
          setQuizzes(response.data?.userQuizz?.secoes); */
            if (response.status === 200) {
              generatePDF(response.data?.userQuizz);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [userId]
  );

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          console.log(tableMeta?.rowData[1]?.Pergunta?.Questionario?.id);
          return tableMeta?.rowData[1]?.Pergunta?.Questionario?.id;
        },
      },
    },
    {
      name: "respostas",
      label: "Ficha",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          return value?.Pergunta?.Questionario?.nome;
        },
      },
    },
    {
      name: "horario",
      label: "Data e horário",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          return formatDateWithMoment(value);
        },
      },
    },

    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quizId = tableMeta?.rowData[1]?.Pergunta?.Questionario?.id;
          const queryData = tableMeta.rowData[2];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Gerar pdf" TransitionComponent={Zoom}>
                {/* <Link
                  to={{
                    pathname: `/sheet/${quizId}/user/${userId}/edit`,
                    search: `?date=${queryData?.horario}`,
                  }}
                  state={{ fromTab: tabIndex }}
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton aria-label='Gerar pdf' color='secondary'>
                    <PictureAsPdfIcon />
                  </IconButton>
                </Link> */}
                <IconButton
                  aria-label="Gerar pdf"
                  color="secondary"
                  onClick={() => getQuizzesAnswers(quizId, queryData)}
                >
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    download: false,
    print: false,
    viewColumns: false,
    selectToolbarPlacement: "above",
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const data = userQuizz.map((quiz) => ({
    id: quiz.id,
    horario: quiz.data_horario_resposta,
    respostas: quiz,
  }));

  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(path).then((data) => data.blob());
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  const calcularIdade = (dataNascimento) => {
    if (!dataNascimento) return "N/I";

    const dataNasc = moment(dataNascimento);
    const hoje = moment();
    const anos = hoje.diff(dataNasc, "years");
    dataNasc.add(anos, "years");
    const meses = hoje.diff(dataNasc, "months");

    if (meses > 0) {
      return `${anos} anos e ${meses} meses`;
    } else {
      return `${anos} anos`;
    }
  };

  const generatePDF = async (data) => {
    console.log(data);
    console.log(interventionName);
    console.log(`Patient name: ${patient?.nome}`);

    const TODAY_DATE = moment(new Date()).format("DD/MM/YYYY");
    const questions = await data.pergunta?.map((item, index) => {
      const respostaUsuario = item?.Resposta_Usuario?.[0]?.resposta_id;
      const respostaObj = item?.Resposta.find(
        (res) => res.id === respostaUsuario
      );
      const secaoPergunta = data.secoes?.find(
        (secao) => secao.id === item.secao_id
      );
      const numQuestionItem = secaoPergunta?.perguntas?.findIndex(
        (pergunta) => pergunta.id === item.id
      );

      let fillColor = "#EAA90F";
      let respostaText = "N/I";

      if (respostaObj) {
        switch (respostaObj.resposta) {
          case "suporte_parcial":
            fillColor = "#DFE293";
            respostaText = "Suporte parcial";
            break;
          case "nao":
            fillColor = "#D1974B";
            respostaText = "Não adquirido";
            break;
          default:
            fillColor = "#EAA90F";
            respostaText = "N/I";
            break;
        }
      }

      return [
        {
          text: item?.Secao?.nome,
          bold: true,
          alignment: "center",
          fillColor: "#DFE293",
        },
        {
          text: numQuestionItem + 1,
          bold: true,
          alignment: "center",
          fillColor: "#DFE293",
        },
        { text: item.nome, bold: true, alignment: "center" },
        { text: respostaText, bold: true, alignment: "center", fillColor },
      ];
    });

    const docDefinition = {
      header: {
        margin: 10,
        columns: [
          {
            image: await obterUrlImagem("/header_docs_mae_coruja.png"),
            width: 550,
          },
        ],
      },
      content: [
        {
          text: "Identificação do paciente",
          fontSize: 16,
          bold: true,
          decoration: "underline",
          alignment: "center",
          margin: [0, 120, 0, 10],
        },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "*", "auto", "*", "*", "*"],
            body: [
              [
                { text: "Nome", bold: true, fillColor: "#80C5C5" },
                { text: "Responsáveis", bold: true, fillColor: "#80C5C5" },
                { text: "Data de Avaliação", bold: true, fillColor: "#80C5C5" },
                { text: "Motivo/Finalidade", bold: true, fillColor: "#80C5C5" },
                {
                  text: "Data de Nascimento",
                  bold: true,
                  fillColor: "#80C5C5",
                },
                { text: "Idade", bold: true, fillColor: "#80C5C5" },
              ],
              [
                patient?.nome ? patient?.nome : "N/I",
                "N/I",
                "N/I",
                "N/I",
                patient?.data_de_nascimento
                  ? moment(patient?.data_de_nascimento).format("DD/MM/YYYY")
                  : "N/I",
                calcularIdade(patient?.data_de_nascimento)
                  ? calcularIdade(patient?.data_de_nascimento)
                  : "N/I",
              ],
            ],
          },
        },
        {
          text: "Plano de Intervenção Individual",
          fontSize: 16,
          bold: true,
          decoration: "underline",
          alignment: "center",
          margin: [0, 30, 0, 10],
        },
        {
          text: [
            {
              text: `${patient?.nome} encontra-se em atendimento nessa unidade terapêutica `,
              alignment: "justify",
              margin: [0, 5, 0, 10],
            },
            {
              text: "Clínica Mãe Coruja – Clínica de Psicologia Materno Infantil e Juventude LTDA",
              bold: true,
              alignment: "justify",
              margin: [0, 0, 0, 10],
            },
            { text: ", modelo ", alignment: "justify", margin: [0, 0, 0, 10] },
            {
              text: "ABA/DENVER.",
              bold: true,
              alignment: "justify",
              margin: [0, 0, 0, 10],
            },
          ],
        },
        {
          text: "A seguir, será apresentado o plano de intervenção individual, com o objetivo de promover o desenvolvimento das habilidades do paciente, com base em sua real necessidade.",
          alignment: "justify",
          margin: [0, 5, 0, 10],
        },
        {
          text: `1. Habilidades a serem desenvolvidas - ${
            String(interventionName?.Questionario?.nome).split("-")[1]
          }`,
          bold: true,
          alignment: "left",
          margin: [0, 5, 0, 10],
        },
        {
          table: {
            headerRows: 1,
            margin: [0, 80, 0, 10],
            widths: ["*", "*", "*", "*"],
            body: [
              [
                {
                  text: `${
                    String(interventionName?.Questionario?.nome).split("-")[1]
                  }`,
                  bold: true,
                  fontSize: 18,
                  fillColor: "#EAA90F",
                  colSpan: 4,
                  alignment: "center",
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: `Habilidade`,
                  bold: true,
                  alignment: "center",
                  fillColor: "#EAA90F",
                },
                {
                  text: `Item`,
                  bold: true,
                  alignment: "center",
                  fillColor: "#EAA90F",
                },
                {
                  text: `Competência`,
                  bold: true,
                  alignment: "center",
                  fillColor: "#EAA90F",
                },
                {
                  text: `Status Aquisição`,
                  bold: true,
                  alignment: "center",
                  fillColor: "#EAA90F",
                },
              ],
              ...questions,
            ],
          },
        },
        {
          text: `2. Orientação parental`,
          bold: true,
          alignment: "left",
          margin: [0, 5, 0, 10],
        },
        {
          text: `As habilidades apresentadas acima, referem-se aos comportamentos que ${patient?.nome} realiza de forma parcial, ou seja, ainda necessita de auxílio para fazê-los. Dessa forma, buscamos trabalhar essas habilidades em um período de três meses, a fim de torná-las adquiridas, para que assim Maria realize com autonomia e sem ajuda.`,
          alignment: "justify",
          margin: [0, 5, 0, 10],
        },
        {
          text: `Portanto, a seguir será apresentado a descrição de como trabalhar cada uma dessas habilidades no lar, para potencializar o desenvolvimento de ${patient?.nome}.`,
          alignment: "justify",
          margin: [0, 5, 0, 10],
        },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*"],
            body: [
              [
                { text: "Seção", alignment: "center", bold: true },
                { text: "Habilidade", alignment: "center", bold: true },
                { text: "Descrição", alignment: "center", bold: true },
                { text: "Como fazer", alignment: "center", bold: true },
              ],
              ...data.pergunta.map((pergunta) => [
                {
                  text: pergunta?.Secao?.nome,
                  alignment: "center",
                },
                {
                  text: pergunta?.nome,
                  alignment: "center",
                },
                {
                  text: pergunta?.como_fazer,
                  alignment: "center",
                },
                {
                  text: pergunta?.descricao,
                  alignment: "center",
                },
              ]),
            ],
          },
        },
        {
          text: `Responsável declara que recebeu as informações necessárias para aplicar o PEI em casa.`,
          alignment: "justify",
          margin: [0, 20, 0, 10],
        },
        {
          text: `Assinatura do responsável: __________________________________________`,
          alignment: "justify",
          margin: [0, 5, 0, 10],
        },
        {
          text: `Presidente Prudente, ${TODAY_DATE}.`,
          alignment: "right",
          margin: [0, 5, 0, 10],
        },
        {
          text: `___________________`,
          alignment: "center",
          margin: [0, 40, 0, 10],
        },
        {
          text: `Ana Maria P. Cacciatore`,
          alignment: "center",
          margin: [0, 2, 0, 10],
        },
        {
          text: `Psicóloga e Neuropsicóloga`,
          alignment: "center",
          margin: [0, 2, 0, 10],
        },
        { text: `CRP: 06/50824-7`, alignment: "center", margin: [0, 2, 0, 10] },
      ],
      footer: function (currentPage, pageCount) {
        return {
          columns: [
            { text: "" },
            {
              text: `Página ${currentPage} de ${pageCount}`,
              alignment: "right",
              margin: [0, 0, 20, 0],
            },
          ],
          margin: [10, 0],
          background: "#C5A5C2",
          fontSize: 10,
          width: "*",
        };
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
    };

    pdfMake
      .createPdf(docDefinition)
      .download(`Relatorio_de_Habilidades_${patient?.nome}.pdf`);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MUIDataTable
            title={"Visualizacao das fichas"}
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};
