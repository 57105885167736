import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Tooltip, Zoom } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ApiService from "app/services/config";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  colaborador_inclusao_id: yup
    .number()
    .required("ID do Colaborador obrigatório"),
  nome_turma: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
  alunosIds: yup
    .array()
    .of(yup.number())
    .required("Escolha no mínimo 1 aluno")
    .min(1, "Escolha no mínimo 1 aluno"),
});

const NewTeam = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const colaboradorId = Number(localStorage.getItem("id"));
  const initialValues = {
    colaborador_inclusao_id: colaboradorId,
    nome_turma: "",
    descricao: "",
    alunosIds: [],
  };
  const [alunos, setAlunos] = useState([]);
  const [alunoEscolhido, setAlunoEscolhido] = useState([]);
  const [turma, setTurma] = useState(initialValues);
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");

  const navigate = useNavigate();
  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
    }
  };

  const modalAlert = () => {
    Swal.fire({
      title: `Tem certeza que deseja cancelar ${
        id ? "a atualização" : "o cadastro"
      } da turma?`,
      text: `Você perderá os dados já preenchidos!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return navigate("/team");
      }
    });
  };

  const modalAlertDeleteAluno = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja remover o aluno desta turma?`,
      text: `Você perderá inserir o aluno na turma novamente depois!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteAluno(id);
      }
    });
  };

  const getAlunos = async () => {
    try {
      await ApiService.get("/users/all/patients").then((response) => {
        if (response.status === 200) {
          setAlunos(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users/all/patients?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAluno = async (alunoId) => {
    try {
      await ApiService.put(
        `/class/${id}/remove/student/${Number(alunoId)}`
      ).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Aluno removido da turma!", {
            variant: "success",
            
          });
          getClass(id);
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (id) {
        delete values.Alunos;
        delete values.colaborador_alteracao;
        delete values.colaborador_inclusao;
        delete values.createdAt;
        delete values.updatedAt;
        delete values.deletedAt;
        delete values.id;
        await ApiService.put(`/class/${id}`, values).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Turma atualizada!", {
              variant: "success",
              
            });
            navigate("/team");
          }
        });
      } else {
        await ApiService.post("/class", values).then((response) => {
          if (response.status === 201) {
            enqueueSnackbar("Turma criada!", {
              variant: "success",
              
            });
            navigate("/team");
          }
        });
      }
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

  const getClass = async (id) => {
    try {
      await ApiService.get(`/class/${id}`).then((response) => {
        if (response.status === 200) {
          setTurma({
            ...response.data,
            alunosIds: response.data?.Alunos.map((aluno) => aluno),
            colaborador_alteracao_id: Number(colaboradorId),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAlunos();
    if (id) {
      getClass(id);
    }
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs
          title={'Turmas'}
          subtitle={id ? "Atualizar Turma" : "Criar Turma"}
          titleUrl={'/Team'}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Formik
          initialValues={turma}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            errors,
            isValid,
          }) => (
            <Form sx={{ width: "100%" }}>
              <>
                {/* <Typography
                  variant="h1"
                  gutterBottom
                  style={{ marginBottom: "15px" }}
                >
                  {id ? "Atualizar Turma" : "Criar Turma"}
                </Typography> */}
                <Grid
                  container
                  // justifyContent="center"
                  // alignItems="center"
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Turma"
                      name="nome_turma"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome_turma}
                      variant="outlined"
                    />
                    <ErrorMessage
                      name="nome_turma"
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-flexible"
                      label="Descrição"
                      name="descricao"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.descricao}
                      multiline
                      maxRows={4}
                      InputProps={{ style: { minHeight: "100px" } }}
                    />
                    <ErrorMessage
                      name="descricao"
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </Grid>
                  <Paper
                    style={{
                      padding: "10px",
                      marginTop: "50px",
                      marginBottom: "50px",
                      marginLeft: "24px",
                      overflowX: "auto",
                      overflowY: "auto",
                      backgroundColor: "#f5f5f5",
                    }}
                    square={false}
                    elevation={3}
                  >
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ display:'flex', alignContent: 'center' }}
                    >
                      <Grid item textAlign={"center"} xs={12}>
                        <Typography variant="h1" gutterBottom>
                          Alunos da turma
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={{ height: "100%" }} />
                      </Grid>
                      <FieldArray name="alunosIds">
                        {({ push, remove }) => (
                          <>
                            <Grid item xs={6}>
                              <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">

                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  options={optionsList}
                                  loading={loading}
                                  getOptionLabel={(option) => option.nome}
                                  onInputChange={handleInputChange}
                                  onChange={(event, option) => {
                                    if (option) {
                                      setAlunoEscolhido(option.id);
                                    }
                                  }}
                                  inputValue={userInput}
                                  noOptionsText={"Nenhum resultado encontrado"}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Buscar Aluno" />
                                  )}
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={5}>
                              <Button
                                endIcon={<PersonAddAlt1Icon color="white"/>}
                                color="success"
                                variant="contained"
                                style={{ marginTop: "7px" }}
                                onClick={() => {
                                  console.log('alunoEscolhido', alunoEscolhido)
                                  if (alunoEscolhido) {
                                    push({id: alunoEscolhido});
                                    setAlunoEscolhido(null);
                                  }
                                }}
                              >
                                Adicionar
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Table
                                sx={{ minWidth: 650, border: "1" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      sx={{ border: "1" }}
                                    >
                                      Nome Aluno
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ border: "1" }}
                                    >
                                      Ações
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.alunosIds &&
                                    values.alunosIds.length > 0 &&
                                    values.alunosIds.map((row) => (
                                      <TableRow key={row.name}>
                                        <TableCell
                                          align="center"
                                          sx={{ border: "1" }}
                                        >
                                          {" "}
                                          {alunos.map((aluno) => {
                                            if (
                                              aluno.id === row ||
                                              aluno.id == row.id
                                            ) {
                                              return <>{aluno.nome}</>;
                                            }
                                          })}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{
                                            border: "1",
                                            display: "flex",
                                            justifyContent: "center",
                                            gap: 2,
                                          }}
                                        >
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                          >
                                            <VisibilityIcon />
                                          </Button>

                                          <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {
                                              const index =
                                                values.alunosIds?.findIndex(
                                                  (alunoId) => alunoId === row
                                                );
                                              if (row.id) {
                                                modalAlertDeleteAluno(row.id);
                                              } else {
                                                remove(index);
                                              }
                                            }}
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </TableCell>
                                        {/* <TableCell align="center" sx={{border: 'none' }}>{row.colaborador_inclusao}</TableCell> */}
                                        {/* <TableCell align="center" sx={{border: 'none' }}>{row.alteracao}</TableCell> */}
                                        {/* <TableCell align="center" sx={{border: 'none' }}>{row.colaborador_alteracao}</TableCell> */}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                      <ErrorMessage
                        name="alunosIds"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Grid>
                  </Paper>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "10px",
                          boxShadow: "none",
                        }}
                      >
                        <Table sx={{ minWidth: 650 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Código</TableCell>
                              <TableCell align="center">Inclusão</TableCell>
                              <TableCell align="center">
                                Colaborador Inclusão
                              </TableCell>
                              <TableCell align="center">Alteração</TableCell>
                              <TableCell align="center">
                                Colaborador Alteração
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                                {id ? turma.id : "---"}
                              </TableCell>
                              <TableCell align="center">
                                {id
                                  ? moment(turma.createdAt).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : "---"}
                              </TableCell>
                              <TableCell align="center">
                                {id ? turma.colaborador_inclusao?.nome : "---"}
                              </TableCell>
                              <TableCell align="center">
                                {id
                                  ? moment(turma.updatedAt).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : "---"}
                              </TableCell>
                              <TableCell align="center">
                                {id ? turma.colaborador_alteracao?.nome : "---"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} />
                    <Grid item md={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit(values)}
                      >
                        {id ? "Atualizar" : "Criar"}
                      </Button>
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          modalAlert();
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
              </>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default NewTeam;
