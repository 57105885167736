
import Chip from "@mui/material/Chip";
import DataTable from "../DataTable";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Box, Grid, OutlinedInput } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import { DateRangePicker } from "react-dates";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ProtocoloReabilitação = () => {
    return (
        <div>
            <HeaderBreadcrumbs title={"Protocolo de Reabilitação Cognitiva e Comportamental"} titleUrl={"/"} />
            <Paper style={{ padding: "10px", marginBottom: "15px", }} square={false} elevation={3}>
                <Divider  style={{ height: '100%', marginBottom:'20px', marginTop:'20px'}} />
                <Grid container spacing={2} justifyContent="center" alignItems={"center"}>
                    <Grid item xs={8}>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Atividades Realizadas"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Acontecimentos"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Dificuldade"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Rotina"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Observação"
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Tipo de resultado</FormLabel>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"name="row-radio-buttons-group">
                                        <FormControlLabel value="Alcançada" control={<Radio />} label="Alcançada" />
                                        <FormControlLabel value="Parcialmente Alcancada" control={<Radio />} label="Parcialmente Alcancada" />
                                        <FormControlLabel value="Nao Alcancada" control={<Radio />} label="Nao Alcancada" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{ height: "100%", marginBottom: "20px", marginTop: "20px", }} />
                <Grid container justifyContent="center" alignItems="center" rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item xs={12}>
                        <Paper sx={{ backgroundColor: "#f5f5f5", borderRadius: "10px", boxShadow: "none", }} >
                            <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Código</TableCell>
                                    <TableCell align="center">Inclusão</TableCell>
                                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                                    <TableCell align="center">Alteração</TableCell>
                                    <TableCell align="center"> Colaborador Alteração </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, }} >
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
                <Divider style={{ height: "100%", marginBottom: "20px", marginTop: "20px", }} />
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button variant="outlined">Salvar</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined">Voltar</Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default ProtocoloReabilitação