import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { FieldArray } from 'formik';
import ApiService from 'app/services/config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { EvolutionItem } from 'app/components/EvolutionItem/EvolutionItem';
import { format } from 'date-fns';

const Evolution = ({
    values,
    infos,
    setFieldValue,
    evolution,
    setEvolution,
}) => {
    const { id, userId} = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const storedUsername = localStorage.getItem('username');
    const storedUserId = localStorage.getItem('id');

    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setEvolution({
            conduta: '',
            cid: '',
            data: '',
        });
        setIsEditMode(false);
    };

    const handleEditEvolution = (evolutionToEdit) => {
        console.log(evolutionToEdit);
        setEvolution(evolutionToEdit);
        setIsEditMode(true);
        setOpen(true);
    };

    console.log(evolution);

    const modalAlert = (evolutionId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                removeEvolution(evolutionId);
            }
        });
    };

    const removeEvolution = useCallback(
        async (evolutionId) => {
            ApiService.delete(`/evolutions/${evolutionId}`)
                .then((response) => {
                    const evolutionEdit = evolution.id === evolutionId;
                    const removedEvolution = values.Evolucao.filter(
                        (item) => item.id !== evolutionId
                    );
                    setFieldValue('Evolucao', removedEvolution);
                    enqueueSnackbar('Evolução removida com sucesso!', {
                        variant: 'success',
                        
                    });
                    if (evolutionEdit) {
                        setIsEditMode(false);
                        setEvolution({
                            // Limpa o estado de evolucao
                            conduta: '',
                            cid: '',
                            data: '',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [
            enqueueSnackbar,
            setFieldValue,
            values.Evolucao,
            evolution,
            setEvolution,
        ]
    );

    return (
        <div>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} container justifyContent='center'>
                    <Button
                        variant='outlined'
                        style={{ marginLeft: '5px' }}
                        onClick={handleOpen}
                    >
                        Nova Evolução
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <FieldArray name='Evolucao'>
                        {({ remove, push }) =>
                            values?.Evolucao?.map((item, index) => (
                                <EvolutionItem
                                    evolution={item}
                                    index={index}
                                    handleEditEvolution={handleEditEvolution}
                                    modalAlert={modalAlert}
                                />
                            ))
                        }
                    </FieldArray>
                    {values?.Evolucao?.length === 0 && (
                        <Typography
                            variant='h5'
                            textAlign={'center'}
                            marginTop={3}
                        >
                            Registros não encontrados
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: 300, sm: 1000 },
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant='h1' marginBottom={3}>
                        Evolução
                    </Typography>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid xs={12} sm={2} md={3}>
                            <TextField
                                fullWidth
                                id='standard-basic'
                                label='Data'
                                variant='outlined'
                                type='date'
                                InputLabelProps={{ shrink: true }}
                                value={evolution?.data}
                                onChange={(event) => {
                                    setEvolution({
                                        ...evolution,
                                        data: event.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid xs={12} sm={5} md={6}>
                            <TextField
                                fullWidth
                                id='standard-basic'
                                label='Prestador'
                                variant='outlined'
                                value={storedUsername}
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} sm={2} md={3}>
                            <TextField
                                fullWidth
                                id='standard-basic'
                                label='CID'
                                variant='outlined'
                                value={evolution?.cid}
                                onChange={(event) => {
                                    setEvolution({
                                        ...evolution,
                                        cid: event.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextField
                                fullWidth
                                name='Detalhe'
                                label='Atendimento/Conduta'
                                multiline
                                rows={4}
                                value={evolution?.conduta}
                                onChange={(event) => {
                                    setEvolution({
                                        ...evolution,
                                        conduta: event.target.value,
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant='outlined'
                                endIcon={<SendIcon />}
                                onClick={() => {
                                    if (isEditMode) {
                                        const evolutionId = evolution.id;
                                        console.log(evolution);
                                        const userEvolutionEdit = {
                                            ...evolution,
                                            prestador_id: Number(storedUserId),
                                        };
                                        console.log(userEvolutionEdit);

                                        ApiService.put(
                                            `/evolutions/${evolutionId}`,
                                            userEvolutionEdit
                                        )
                                            .then((response) => {
                                                console.log(response.data);
                                                enqueueSnackbar(
                                                    'Evolução atualizada com sucesso!',
                                                    {
                                                        variant: 'success',
                                                        
                                                    }
                                                );
                                                const updatedTodos =
                                                    values.Evolucao.map(
                                                        (todo) => {
                                                            if (
                                                                todo.id ===
                                                                response.data.id
                                                            ) {
                                                                return response.data; // Retorna a evolucao atualizado
                                                            }
                                                            return todo;
                                                        }
                                                    );
                                                setFieldValue(
                                                    'Evolucao',
                                                    updatedTodos
                                                ); // Atualiza a lista de avaliacao com o evolucao atualizado
                                                setIsEditMode(false); // Sai do modo de edição
                                                setEvolution({
                                                    // Limpa o estado de evolucao
                                                    conduta: '',
                                                    cid: '',
                                                    data: '',
                                                });
                                                setOpen(false);
                                            })
                                            .catch((error) => {
                                                const errorMessage =
                                                    error.response.data.message;
                                                console.log(errorMessage);
                                                if (
                                                    Array.isArray(errorMessage)
                                                ) {
                                                    enqueueSnackbar(
                                                        `${errorMessage[0]}`,
                                                        {
                                                            variant: 'error',
                                                            
                                                        }
                                                    );
                                                } else {
                                                    enqueueSnackbar(
                                                        `${errorMessage}`,
                                                        {
                                                            variant: 'error',
                                                            
                                                        }
                                                    );
                                                }
                                            });
                                    } else {
                                        const userEvolution = {
                                            user_id: Number(userId),
                                            prestador_id: Number(storedUserId),
                                            conduta: evolution.conduta,
                                            cid: evolution.cid,
                                            data: evolution.data,
                                        };
                                        console.log(userEvolution);

                                        ApiService.post(
                                            `/evolutions`,
                                            userEvolution
                                        )
                                            .then((response) => {
                                                console.log(response);
                                                const newEvolution = [
                                                    ...values.Evolucao,
                                                    response.data,
                                                ];
                                                enqueueSnackbar(
                                                    'Evolução criada com sucesso!',
                                                    {
                                                        variant: 'success',
                                                        
                                                    }
                                                );
                                                setFieldValue(
                                                    'Evolucao',
                                                    newEvolution
                                                );
                                                setEvolution({
                                                    // Limpa o estado de evolucao
                                                    conduta: '',
                                                    cid: '',
                                                    data: '',
                                                });
                                                setOpen(false);
                                            })
                                            .catch((error) => {
                                                const errorMessage =
                                                    error.response.data.message;
                                                console.log(errorMessage);
                                                if (
                                                    Array.isArray(errorMessage)
                                                ) {
                                                    enqueueSnackbar(
                                                        `${errorMessage[0]}`,
                                                        {
                                                            variant: 'error',
                                                            
                                                        }
                                                    );
                                                } else {
                                                    enqueueSnackbar(
                                                        `${errorMessage}`,
                                                        {
                                                            variant: 'error',
                                                            
                                                        }
                                                    );
                                                }
                                            });
                                    }
                                }}
                            >
                                {isEditMode ? 'Salvar' : 'Salvar'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
};

export default Evolution;
