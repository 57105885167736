import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Delete, PhotoCamera } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { idID } from "@mui/material/locale";

const UpdatePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const id = Number(localStorage.getItem('id'));
  const initialValues = {
    current_password: "",
    new_password: "",
    confirmar_senha: "",
  };
  const location = useLocation();
  const Swal = useSwalWrapper();

  const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
    status: false,
    mensagem: "",
  });


  const validationSchema = yup.object().shape(
    {
      current_password: yup.string().required("Campo obrigatório"),  
      new_password: yup.string().required("Campo obrigatório"),
      confirmar_senha: yup
        .string()
        .required("Campo obrigatório")
        .oneOf([yup.ref("new_password"), null], "As senhas devem ser iguais"),
      // .test('passwords-match', 'As senhas devem ser iguais', function (value) { return this.parent.senha === value })
      // .required("Preenchimento obrigatório"),
      // .test('passwords-match', 'As senhas devem ser iguais', function (value) { return this.parent.senha === value })
    },
    []
  );



  const [user, setUser] = useState(initialValues);
  const [roles, setRoles] = useState([]);
  const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);

  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });



  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await ApiService.put(`users/password-user/${id}`, values)
      .then((response) => {
        if(response.status === 200) {
          enqueueSnackbar("Senha atualizada com sucesso!", {
            variant: "success",
          });
          resetForm();
        }
        
      })
      .catch((error) => {
        console.log("Ocorreu um erro");
        if(error?.response?.status === 401) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Ocorreu um erro", {
            variant: "error",
          });
        }
        
      
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
    
  };

  const getUser = async () => {
    try {
      await ApiService.get(`/users/${id}`)
      .then((response) => {
        if(response.status === 200) {
          setUser(response.data);
        }
      })
    } catch (error) { 
      console.log(error);
    }
  }

  useEffect(() => {
    if(id) {
      getUser();
    }
  }, [id])

  return (
    <div>
      <Grid container>
        <HeaderBreadcrumbs
          title={"Alterar senha"}
          titleUrl={"/alterar-senha"}
        />
      </Grid>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper
            style={{
              padding: "10px",
              marginBottom: "15px",
              overflowX: "auto",
              overflowY: "auto",
            }}
            square={false}
            elevation={3}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                errors,
                resetForm
              }) => (
                <Form style={{ width: "100%" }} noValidate autoComplete="off">
                  <Grid container spacing={3} alignContent={"center"}>
                    <Grid item xs={12}>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <Avatar
                          alt="Foto do perfil"
                          src={
                            user.avatar_url
                              ? `${process.env.REACT_APP_API_KEY}/public/uploads/${user?.avatar_url}`
                              : previewFotoPerfil
                          }
                          sx={{
                            width: 140,
                            height: 140,
                            marginTop: 2,
                            bgcolor: "success",
                          }}
                        >
                          {user.nome ? user.nome.charAt(0).toUpperCase() : "P"}
                        </Avatar>
                      </Box>
                      <Box width={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        my={2}>
                        <Typography variant={"h2"}>{user?.nome}</Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    width={"100%"}
                    spacing={3}
                    alignContent={"center"}
                    justifyContent={"center"}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  >
                    <Grid item xs={3}>
                      <JumboTextField
                        fullWidth
                        name="current_password"
                        label="Senha atual"
                        value={values.current_password}
                        onChange={handleChange}
                        // handleChange={handleChange}
                        // disabled={!hasPermission('Perfis', 'editar')}
                        type={showPassword.password ? "text" : "password"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPassword((prevState) => ({
                                    ...prevState,
                                    password: !prevState.password,
                                  }))
                                }
                                edge="end"
                              >
                                {showPassword.password ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        fullWidth
                        name="new_password"
                        label="Nova senha"
                        value={values.new_password}
                        onChange={handleChange}
                        type={
                          showPassword.newPassword ? "text" : "password"
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPassword((prevState) => ({
                                    ...prevState,
                                    newPassword: !prevState.newPassword,
                                  }))
                                }
                                edge="end"
                              >
                                {showPassword.confirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <JumboTextField
                        fullWidth
                        name="confirmar_senha"
                        label="Confirmar senha"
                        value={values.confirmar_senha}
                        onChange={handleChange}
                        type={
                          showPassword.confirmPassword ? "text" : "password"
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowPassword((prevState) => ({
                                    ...prevState,
                                    confirmPassword: !prevState.confirmPassword,
                                  }))
                                }
                                edge="end"
                              >
                                {showPassword.confirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container width={"100%"}
                    spacing={3}
                    alignContent={"center"}
                    justifyContent={"center"}
                    sx={{ marginTop: 2, marginBottom: 2 }}>
                    <Grid item>
                        <LoadingButton
                        color="success"
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                            maxWidth: {
                            md: "150px",
                            mt: "1rem",
                            },
                        }}
                        /* disabled */
                        loading={isSubmitting}
                        >
                        {"Salvar"}
                        </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdatePassword;
