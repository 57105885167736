import {
    Autocomplete,
    Box,
    Button,
    Card,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';

export const AnamnesisItem = ({
    anamnesis,
    index,
    handleEditAnamnesis,
    modalAlert,
    anamnesisSelected,
    setAnamnesisSelected,
}) => {
    return (
        <Card sx={{ mb: 2, padding: 2, borderRadius: 3 }} elevation={0}>
            <Box display='flex' justifyContent='space-between'>
                <Box width={'50%'}>
                    {/* <Typography mt={1}>{index + 1}</Typography> */}
                    <Autocomplete
                        disablePortal
                        getOptionLabel={(option) =>
                            option?.data?.split('-')?.reverse()?.join('/')
                        }
                        options={anamnesis}
                        value={anamnesisSelected?.select ?? null}
                        onChange={(event, newValue) => {
                            setAnamnesisSelected((prevValues) => ({
                                ...prevValues,
                                select: newValue,
                            }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Anamneses'
                                placeholder='Anamneses'
                                fullWidth
                            />
                        )}
                        fullWidth
                    />
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Ação
                    </Typography>
                    <Box display={'flex'} gap={1}>
                        <Tooltip title='Visualizar' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='visualizar'
                                color='info'
                                disabled={!anamnesisSelected?.select}
                                onClick={() =>
                                    handleEditAnamnesis(
                                        anamnesisSelected?.select
                                    )
                                }
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title='Remover' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='remover'
                                color='error'
                                disabled={!anamnesisSelected?.select}
                                onClick={() =>
                                    modalAlert(anamnesisSelected?.select?.id)
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};
