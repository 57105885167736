import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ApiService from 'app/services/config';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import {
    Box,
    Checkbox,
    IconButton,
    Toolbar,
    Tooltip,
    Zoom,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { PermissionContext } from "app/contexts/PermissionContext";

function People() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState('');
    const { hasPermission } = useContext(PermissionContext);

    const getUsers = useCallback(async () => {
        try {
            let apiUrl = `/users?page=${
                page + 1
            }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

            if (searchTerm) {
                apiUrl += `&searchTerm=${searchTerm}`;
            }
            ApiService.get(apiUrl)
                .then((response) => {
                    console.log(response.data);

                    setUsers(response.data?.usersWithoutPassword);
                    setCount(response.data?.total);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [page, perPage, sortBy, sortOrder, searchTerm]);

    const handleSearch = async (newSearchTerm) => {
        if (
            searchTerm === '' ||
            searchTerm === null ||
            searchTerm === undefined
        ) {
            setPage(1);
        }
        setSearchTerm(newSearchTerm);
        setPage(0);
    };

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const deleteUser = useCallback(async (id) => {
        ApiService.delete(`/users/${id}`)
            .then((response) => {
                getUsers();
            })
            .catch((error) => {
                const message = error.response.data.message;
                console.error(message);
            });
    }, []);

    const columns = [
        {
            name: 'id',
            label: 'Id',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: 'nome',
            label: 'Nome',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: 'action',
            label: 'Ações',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const userId = tableMeta.rowData[0];
                    return (
                        <Box display={'flex'} gap={'1rem'}>
                            <Tooltip
                                title='Visualizar'
                                TransitionComponent={Zoom}
                            >
                                <Link
                                    to={!hasPermission('Pessoas', 'update') ? '#' : `/people/edit/${userId}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <IconButton
                                        aria-label='visualizar'
                                        color='info'
                                        disabled={!hasPermission('Pessoas', 'update')}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>

                            <Tooltip title='Remover' TransitionComponent={Zoom}>
                                <IconButton
                                    aria-label='remover'
                                    color='error'
                                    disabled={!hasPermission('Pessoas', 'delete')}
                                    onClick={() => deleteUser(userId)} // Função para deletar usuário
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
    ];

    const options = {
        changeRowsPerPage: perPage,
        filterType: 'dropdown',
        filter: false,
        selectableRows: 'none',
        searchAlwaysOpen: true,
        searchable: true,
        serverSide: true,
        page: page,
        count: count,
        selectToolbarPlacement: 'above',

        onTableChange: (action, tableState) => {
            console.log(action);
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'sort':
                    setSortBy(tableState.sortOrder.name);
                    setSortOrder(tableState.sortOrder.direction);
                    break;
                case 'search':
                    handleSearch(tableState.searchText);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'of',
            },
        },
    };

    return (
        <div>
            <Grid container>
                <HeaderBreadcrumbs
                    title={'Pessoa'}
                    titleUrl={'/People'}
                />
            </Grid>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <MUIDataTable
                        title={'Pessoas'}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <br />
            <Link to={!hasPermission('Pessoas', 'create') ? '/people' : '/people/create'} style={{ textDecoration: 'none' }} >
                <Button variant='contained' startIcon={<PersonAddIcon />} disabled={!hasPermission('Pessoas', 'create')}>
                    Criar
                </Button>
            </Link>
        </div>
    );
}

export default People;
