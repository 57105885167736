import Chip from "@mui/material/Chip";
import DataTable from "../DataTable";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Box, Grid, OutlinedInput, Tooltip, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/pt-br";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import moment from "moment";

const situacaoAgendaObj = {
  agendado: "Agendado",
  agendando: "Agendando",
  aguardando: "Aguardando",
  atendido: "Atendido",
  confirmado_presenca: "Confirmado Presença",
  desistiu: "Desistiu",
  desmarcou: "Desmarcou",
  em_atendimento: "Em Atendimento",
  em_dilatacao_primeira_gota: "Em Dilatação 1ª Gota",
  em_dilatacao_segunda_gota: "Em Dilatação 2ª Gota",
  falta_justificada: "Falta Justificada",
  falta_nao_justificada: "Falta Não Justificada",
  livre: "Livre",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NewAgreement = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dadosConvenios, setDadosConvenios] = useState([]);
  const [statusConvenio, setStatusConvenio] = useState("");
  const [situacaoAgenda, setSituacaoAgenda] = useState([]);
  const [prestadorSelecionado, setPrestadorSelecionado] = useState([]);
  const [convenioSelecionado, setConvenioSelecionado] = useState(null);
  const [registroConvenio, setRegistroConvenio] = useState("");
  const [codigoAutorizacao, setCodigoAutorizacao] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [listaConvenios, setListaConvenios] = useState([]);
  const [prestadores, setPrestadores] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const getConvenios = async () => {
    try {
      await ApiService.get("/agreements").then((response) => {
        if (response.status === 200) {
          setListaConvenios(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDataConvenios = async () => {
    let filtros = {};

    filtros.page = page;
    filtros.perPage = perPage;
    filtros.sortBy = sortBy;
    filtros.sortOrder = sortOrder;

    if (statusConvenio !== "") {
      filtros.status_convenio = statusConvenio;
    }

    if (situacaoAgenda && situacaoAgenda.length > 0) {
      filtros.status_agenda = situacaoAgenda;
    }

    if (convenioSelecionado !== null) {
      filtros.convenio_id = convenioSelecionado;
    }

    if (userSelected) {
      filtros.prestador_id = userSelected?.id;
    }

    if (startDate && endDate) {
      filtros.data_inicio = startDate;
      filtros.data_final = endDate;
    }

    if (searchTerm) {
      filtros.searchTerm = searchTerm;
    }

    await ApiService.post(`/schedules/registros/convenios`, filtros)
      .then((response) => {
        if (response.status === 201) {
          setDadosConvenios(response.data.conveniosData);
          setCount(response.data.count);
        }
      })
      .catch((error) => {
        enqueueSnackbar("Erro ao realizar a busca!", {
          variant: "error",
        });
        console.log(error);
      });
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users?searchTerm=${inputValue}`);
      const users = response.data?.usersWithoutPassword || [];
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleLimparFiltros = () => {
    setSituacaoAgenda([]);
    setStartDate(null);
    setEndDate(null);
    setCodigoAutorizacao("");
    setRegistroConvenio("");
    setPrestadorSelecionado(null);
    setConvenioSelecionado(null);
    setUserSelected(null);
  };

  const handleChangeSituacaoAgenda = (event) => {
    const {
      target: { value },
    } = event;
    setSituacaoAgenda(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const columns = [
    {
      name: "createdAt",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD/MM/YYYY HH:mm:ss")}</>;
        },
      },
    },
    { name: "", label: "Dia semana", options: { filter: true, sort: true } },
    { name: "", label: "Obs.", options: { filter: true, sort: true } },
    {
      name: "Convenio",
      label: "Convênio",
      options: {
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome;
        },
      },
    },
    {
      name: "User",
      label: "Cartão",
      options: {
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.convenio?.[0]?.numero_convenio;
        },
      },
    },
    { name: "", label: "Autorização", options: { filter: true, sort: true } },
    {
      name: "",
      label: "Ses. restantes",
      options: { filter: true, sort: true },
    },
    {
      name: "User",
      label: "Paciente",
      options: {
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome;
        },
      },
    },
    {
      name: "User",
      label: "Situação",
      options: {
        filter: true,
        sort: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{situacaoAgendaObj[value?.Agendamento?.[0]?.status]}</>;
        },
      },
    },
    {
      name: "",
      label: "Lanç. Convenio",
      options: { filter: true, sort: true },
    },
    {
      name: "",
      label: "Registro convênio",
      options: { filter: true, sort: true },
    },
    { name: "", label: "Biometria", options: { filter: true, sort: true } },
    { name: "", label: "Convenio", options: { filter: true, sort: true } },
    { name: "", label: "ABAI / Denver", options: { filter: true, sort: true } },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  useEffect(() => {
    fetchUsers();
    getConvenios();
  }, []);

  useEffect(() => {
    getDataConvenios();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  return (
    <div>
      <HeaderBreadcrumbs title={"Novo Convênio"} titleUrl={"/NewAgreement"} />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
        }}
        square={false}
        elevation={3}
      >
        <Grid item>
          <Typography
            variant="h2"
            gutterBottom
            style={{ marginBottom: "15px" }}
          >
            Controle de registro em convênio
          </Typography>
        </Grid>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={3} md={3}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Pendente Convênio
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="SIM"
                  control={
                    <Radio
                      onChange={(event) => {
                        if (event.target.checked) {
                          setStatusConvenio("sim");
                        }
                      }}
                    />
                  }
                  label="SIM"
                />
                <FormControlLabel
                  value="NÃO"
                  control={
                    <Radio
                      onChange={(event) => {
                        if (event.target.checked) {
                          setStatusConvenio("nao");
                        }
                      }}
                    />
                  }
                  label="NÃO"
                />
                <FormControlLabel
                  value="AMBOS  "
                  control={
                    <Radio
                      onChange={(event) => {
                        if (event.target.checked) {
                          setStatusConvenio("ambos");
                        }
                      }}
                    />
                  }
                  label="AMBOS"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={2} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Situação Agenda
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={situacaoAgenda}
                onChange={handleChangeSituacaoAgenda}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Div sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={situacaoAgendaObj[value]} />
                    ))}
                  </Div>
                )}
                MenuProps={MenuProps}
                getOptionLabel={(chave) => situacaoAgendaObj[chave]}
              >
                {Object.keys(situacaoAgendaObj).map((chave, index) => (
                  <MenuItem key={chave} value={chave}>
                    {situacaoAgendaObj[chave]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} md={3}>
            <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
              <Autocomplete
                fullWidth
                disablePortal
                getOptionLabel={(option) => option?.nome}
                  options={optionsList.filter(
                    (option) => option.Agendamento_Prestador.length > 0
                  )}
                // options={optionsList}
                loading={loading}
                inputValue={userInput}
                onInputChange={handleInputChange}
                onChange={(event, newValue) => {
                  setUserSelected(newValue);
                  setPrestadorSelecionado(newValue?.id);
                }}
                value={userSelected ?? null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prestador"
                    placeholder="Prestador"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? "carregando..." : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
              </Tooltip>
          </Grid>
          <Grid item xs={2} md={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listaConvenios}
              getOptionLabel={(option) => option?.nome}
              onChange={(event, option) => {
                if (option) {
                  setConvenioSelecionado(option.id);
                }
              }}
              value={convenioSelecionado?.id ?? null}
              renderInput={(params) => (
                <TextField {...params} label="Convênio" />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems={"center"} my={1}>
          <Grid item xs={1} md={3}>
            <DateRangePicker
              small={true}
              startDate={startDate}
              startDatePlaceholderText="Data inicial"
              startDateId="data_inicio"
              endDate={endDate}
              endDateId="data_final"
              endDatePlaceholderText="Data final"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={handleFocusChange}
              isOutsideRange={() => false}
            />
          </Grid>

          <Grid item xs={2} md={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              renderInput={(params) => (
                <TextField {...params} label="Prestador Autorização" />
              )}
              disabled
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <TextField
              fullWidth
              id="validade-inicial"
              label="Registro Convênio"
              variant="outlined"
              value={registroConvenio}
              onChange={(event) => {
                setRegistroConvenio(event.target.value);
              }}
              type="text"
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <TextField
              fullWidth
              id="validade-inicial"
              label="Codigo Autorização"
              variant="outlined"
              value={codigoAutorizacao}
              onChange={(event) => {
                setCodigoAutorizacao(event.target.value);
              }}
              type="text"
            />
          </Grid>
        </Grid>
        <Divider />
        <Div
          sx={{
            marginY: 1,
            width: "100%",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Box>
            <Button
              endIcon={<SearchIcon />}
              variant="outlined"
              disabled={statusConvenio === ""}
              onClick={getDataConvenios}
            >
              Buscar
            </Button>
          </Box>
          <Box>
            <Button
              endIcon={<CleaningServicesIcon />}
              variant="outlined"
              onClick={handleLimparFiltros}
            >
              Limpar
            </Button>
          </Box>
        </Div>
      </Paper>
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        {/* <Typography variant='h2' gutterBottom style={{ marginBottom: '15px' }} >
                    Novo Convênio
                </Typography> */}
        {/* <Divider  style={{ height: '100%', marginBottom:'20px', marginTop:'20px'}} /> */}
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} md={11}>
            <Grid
              container
              alignItems="center"
              direction="row"
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item>
                {userSelected && (
                  <Typography fontWeight={500}>
                    Prestador: {userSelected?.nome}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Button fullWidth variant="outlined">
                  Agendamentos pendentes
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <MUIDataTable
              columns={columns}
              data={dadosConvenios}
              options={options}
              key={forceUpdate}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];
export default NewAgreement;
