import { Box, Button, Card, IconButton, Tooltip, Typography, Zoom } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { getCustomDate, getCustomDateTimeUtc } from "@jumbo/utils";

export const AgreementItem = ({
    agreement,
    handleEditAgreement,
    modalAlert,
}) => {
    return (
        <Card
            sx={{ mb: 2, padding: 2, borderRadius: 3, width: '1400px' }}
            elevation={0}
        >
            <Box display='flex' justifyContent='space-between'>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Convênio
                    </Typography>

                    <Typography mt={1}>{agreement?.nome}</Typography>
                </Box>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Cartão
                    </Typography>

                    <Typography mt={1}>{agreement?.numero_convenio}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Validade
                    </Typography>

                    <Typography mt={1}>
                        {getCustomDateTimeUtc(agreement?.validade, false) ?? '-'}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Acomodação
                    </Typography>

                    <Typography mt={1}>
                        {agreement?.acomodacao ? 'Sim' : 'Não'}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Rede Atendimento
                    </Typography>

                    <Typography mt={1}>
                        {agreement?.rede_atendimento}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Plano Regulamentado
                    </Typography>

                    <Typography mt={1}>
                        {agreement?.plano_regulamentado}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Abrangência
                    </Typography>

                    <Typography mt={1}>{agreement?.abrangencia}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Cobertura Parc. ou Temp.
                    </Typography>

                    <Typography mt={1}>
                        {agreement?.cobertura ? 'Sim' : 'Não'}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Tipo de Plano
                    </Typography>

                    <Typography mt={1}>
                        {agreement?.tipo_plano_convenio}
                    </Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Ação
                    </Typography>
                    <Box display={'flex'} gap={1}>
                        <Tooltip title='Editar' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='editar'
                                color='info'
                                onClick={() => handleEditAgreement(agreement)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title='Remover' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='remover'
                                color='error'
                                onClick={() => modalAlert(agreement.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};
