import { Box, Grid, Typography, Zoom } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import { useContext, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { Cancel, Check } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { PermissionContext } from "app/contexts/PermissionContext";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";


const Atendimentos = () => {
  const prestadorId = Number(localStorage.getItem("id"));
  const prestadorNome = localStorage.getItem("username");
  const { enqueueSnackbar } = useSnackbar();
  const [atendimentos, setAtendimentos] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [atendimentoData, setAtendimentoData] = useState({
    paciente_id: "",
    prestador_id: prestadorId,
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [pacienteId, setPacienteId] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");
  const [dataSelecionada, setDataSelecionada] = useState(null);
  const [criarNovo, setCriarNovo] = useState(false);
  const { hasPermission } = useContext(PermissionContext);
  const [clearFiltersKey, setClearFiltersKey] = useState(false);

  const Swal = useSwalWrapper();

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };


  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users/all/patients?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja excluir o atendimento?`,
      text: `Você perderá os dados do atendimento cadastrado!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteAtendimento(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleResetFiltros = () => {
    setPage(0);
    setPerPage(10);
    setSearchTerm("");
    setSortBy("id");
    setSortOrder("desc");
    setDataSelecionada("");
    setPacienteId(null);
    setUserSelected(null);
    setClearFiltersKey(!clearFiltersKey);
    setForceUpdate((prev) => !prev);
  };

  const handleDeleteAtendimento = async (id) => {
    try {
      await ApiService.delete(`/healthcare/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Atendimento excluído!", {
            variant: "success",
            
          });
          getAtendimentos();
          setForceUpdate((prev) => !prev);
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "success",
        
      });
      console.log(error);
    }
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "prestador",
      label: "Colaborador",
      options: {
        enableNestedDataAccess: ".",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome ?? "";
        },
      },
    },
    {
      name: "createdAt",
      label: "Inclusão",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD/MM/YYYY HH:mm:ss")}</>;
        },
      },
    },
    {
      name: "paciente",
      label: "Paciente",
      options: {
        enableNestedDataAccess: ".",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome ?? "";
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const atendimentoId = tableMeta.rowData[0];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Remover" TransitionComponent={Zoom}>
                <IconButton
                  aria-label="remover"
                  color="error"
                  onClick={() => modalAlert(atendimentoId)}
                  disabled={!hasPermission('Atendimentos', 'delete')}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "de",
      },
    },
  };

  const getAtendimentos = async () => {
    try {
      let apiUrl = `/healthcare/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&prestadorId=${prestadorId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (pacienteId) {
        apiUrl += `&pacienteId=${pacienteId?.paciente_id}`;
      }

      if (dataSelecionada) {
        apiUrl += `&dataAtendimento=${dataSelecionada}`;
      }

      await ApiService.get(apiUrl).then((response) => {
        if (response.status === 200) {
          setAtendimentos(response.data.atendimentos);
          setCount(response.data.count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPacientes = async () => {
    try {
      await ApiService.get("/users/all/patients").then((response) => {
        if (response.status === 200) {
          setPacientes(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setAtendimentoData({
      paciente_id: "",
      prestador_id: prestadorId,
    });
    setCriarNovo(!criarNovo);
  };

  const handleSave = () => {
    handleCreateAtendimento(atendimentoData);
    setAtendimentoData({
      paciente_id: "",
      prestador_id: prestadorId,
    });
    setForceUpdate((prev) => !prev);
    setCriarNovo(!criarNovo);
  };

  const handleCreateAtendimento = async (data) => {
    try {
      await ApiService.post("/healthcare", data).then((response) => {
        if (response.status === 201) {
          enqueueSnackbar("Atendimento registrado!", {
            variant: "success",
            
          });
          getAtendimentos();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAtendimentos();
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    dataSelecionada,
    pacienteId,
  ]);

  useEffect(() => {
    getPacientes();
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Relatórios"}
        subtitle={"Atendimentos"}
        titleUrl={"/atendimentos"}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid item>
          <Typography
            variant="h2"
            gutterBottom
            style={{ marginBottom: "15px" }}
          >
            Gerar atendimento
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setCriarNovo(!criarNovo)}
              style={{ marginBottom: "15px" }}
              disabled={!hasPermission('Atendimentos', 'create')}
            >
              Novo
            </Button>
          </Grid>
        </Grid>
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid item>
          <Typography
            variant="h2"
            gutterBottom
            style={{ marginBottom: "15px" }}
          >
            Buscar paciente
          </Typography>
        </Grid>
        {!criarNovo ? (
          <div>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              width="100%"
            >
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  type="date"
                  id="outlined-basic"
                  label="Data"
                  variant="outlined"
                  value={dataSelecionada}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    console.log(`DATA: ${event.target.value}`);
                    setDataSelecionada(event.target.value);
                  }}
                  defaultValue={dataSelecionada}
                />
              </Grid>
              <Grid item xs={4}>
                <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                  <Autocomplete
                    key={clearFiltersKey}
                    fullWidth
                    disablePortal
                    getOptionLabel={(option) => option.nome}
                    options={optionsList}
                    loading={loading}
                    inputValue={userInput}
                    onInputChange={handleInputChange}
                    onChange={(event, newValue) => {
                      if (newValue) { 
                        setUserSelected((prevValues) => ({
                          selected: newValue,
                        }));
                        setAtendimentoData((prevData) => ({
                          ...prevData,
                          paciente_id: Number(newValue.id)
                        }));
                        setPacienteId((prevData) => ({
                          ...prevData,
                          paciente_id: Number(newValue.id)
                        }));
                      }
                    }}
                    noOptionsText={"Nenhum resultado encontrado"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Paciente"
                        placeholder="Paciente"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? "carregando..." : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={handleResetFiltros} endIcon={<FilterAltOffIcon />}>
                  Limpar filtros
                </Button>
              </Grid>
            </Grid>
            <Divider
              style={{
                height: "100%",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            />
          </div>
        ) : (
          <Grid container spacing={2} my={2}>
            <Grid item xs={5}>
              <TextField
                label="Prestador"
                variant="outlined"
                fullWidth
                value={prestadorNome}
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                <Autocomplete
                  fullWidth
                  disablePortal
                  getOptionLabel={(option) => option.nome}
                  options={optionsList}
                  loading={loading}
                  inputValue={userInput}
                  onInputChange={handleInputChange}
                  onChange={(event, newValue) => {
                    if (newValue) { 
                      setUserSelected((prevValues) => ({
                        selected: newValue,
                      }));
                      setAtendimentoData((prevData) => ({
                        ...prevData,
                        paciente_id: Number(newValue.id)
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Paciente"
                      placeholder="Paciente"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? "carregando..." : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleCancel} label="Cancelar">
                <Cancel color="error" />
              </IconButton>
              <IconButton onClick={handleSave}>
                <Check color="success" />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <MUIDataTable
          key={forceUpdate ? "force-update" : "normal"}
          columns={columns}
          data={atendimentos}
          options={options}
          title={"Atendimentos"}
        />
      </Paper>
    </div>
  );
};

export default Atendimentos;
