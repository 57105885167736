import {
    Box,
    Button,
    Card,
    IconButton,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const EvolutionItem = ({
    evolution,
    index,
    handleEditEvolution,
    modalAlert,
}) => {
    return (
        <Card sx={{ mb: 2, padding: 2, borderRadius: 3 }} elevation={0}>
            <Box display='flex' justifyContent='space-between'>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    flexDirection='column'
                >
                    <Box display='flex' alignItems='baseline' gap={1}>
                        <Typography fontSize={'12px'} variant={'h6'} margin={0}>
                            Data:
                        </Typography>

                        <Typography margin={0}>
                            {evolution?.data.split('-').reverse().join('/')}
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='baseline' gap={1}>
                        <Typography fontSize={'12px'} variant={'h6'} margin={0}>
                            Prestador:
                        </Typography>

                        <Typography margin={0} color={'text.secondary'}>
                            {evolution?.Prestador?.nome}
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='baseline' gap={1}>
                        <Typography fontSize={'12px'} variant={'h6'} margin={0}>
                            Conduta:
                        </Typography>

                        <Typography margin={0} color={'text.secondary'}>
                            {evolution?.conduta}
                        </Typography>
                    </Box>

                    <Box display='flex' alignItems='baseline' gap={1}>
                        <Typography fontSize={'12px'} variant={'h6'} margin={0}>
                            CID:
                        </Typography>

                        <Typography margin={0} color={'text.secondary'}>
                            {evolution?.cid}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography fontSize={'12px'} variant={'h6'}>
                        Ação
                    </Typography>
                    <Box display={'flex'} gap={1}>
                        <Tooltip title='Editar' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='editar'
                                color='info'
                                onClick={() => handleEditEvolution(evolution)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title='Remover' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='remover'
                                color='error'
                                onClick={() => modalAlert(evolution.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};
