import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import KeyIcon from '@mui/icons-material/Key';
import ButtonGroup from '@mui/material/ButtonGroup';

const FinancialData = () => {
    return (
        <>
            <Paper style={{ padding: '10px', marginBottom: '15px', overflowX: 'auto', maxHeight: '650px', overflowY: 'auto' }} square={false} elevation={3}>
            <Typography variant='h1' gutterBottom style={{ marginBottom: '15px'}}>
                Dados Financeiros
            </Typography>
            
                <Divider textAlign="left" style={{marginBottom:'10px'}}><Typography variant="h4" gutterBottom> Lançamentos </Typography></Divider>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs='12' style={{ textAlign: 'center' }}>
                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                <Button>Adicionar Exame / Procedimento</Button>
                                <Button>Novo Lancamento</Button>
                                <Button>Novo Produto</Button>
                            </ButtonGroup>
                        </Grid>
                        {/* to usando esses br apenas pra simular um espaço vazio */}
                        <Grid item xs='12'>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </Grid>
                    </Grid>
                <Divider textAlign="left" style={{marginBottom:'10px'}}><Typography variant="h4" gutterBottom> Parcelamento </Typography></Divider>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* to usando esses br apenas pra simular um espaço vazio */}
                        <Grid item xs='12'>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </Grid>
                    </Grid>
            </Paper>

        
        </>
    );
}

export default FinancialData