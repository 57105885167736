import { Avatar, Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";

const ChatUserMessage = ({
  userId,
  username,
  arquivo,
  avatarUrl,
  messageDate,
  messageContent,
}) => {
  const userLoggedId = Number(localStorage.getItem("id"));

  return (
    <Stack
      gap={2}
      direction="row"
      my={1}
      width="100%"
      justifyContent={userId === userLoggedId ? "flex-end" : "flex-start"}
    >
      {userId !== userLoggedId && (
        <Box>
          <Avatar
            src={`${process.env.REACT_APP_API_KEY}/public/uploads/${avatarUrl}`}
            sizes="sm"
          >
            {String(username).charAt(0).toLocaleUpperCase()}
          </Avatar>
        </Box>
      )}
      <Stack
        gap={1}
        direction="column"
        alignItems={userId === userLoggedId ? "flex-end" : "flex-start"}
        sx={{ maxWidth: "60%" }}
      >
        <Box>
          <Typography fontSize={14} color="gray">
            {username} - {moment(messageDate).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 1,
            maxWidth: "100%",
            color: userId === userLoggedId ? "white" : "#5B6565",
            background: userId === userLoggedId ? "#7230C4" : "#DDDDDD",
            alignSelf: userId === userLoggedId ? "flex-end" : "flex-start",
            wordBreak: "break-word",
            display: "inline-block",
            ...(arquivo ? { width: 250, height: 250 } : {}),
          }}
          borderRadius={2}
        >
          {arquivo &&
          (String(arquivo).endsWith(".png") ||
            String(arquivo).endsWith(".jpg") ||
            String(arquivo).endsWith(".jpeg")) ? (
            <Link
              to={`${process.env.REACT_APP_API_KEY}/public/uploads/chat/${arquivo}`}
              target="_blank"
            >
              <img
                src={`${process.env.REACT_APP_API_KEY}/public/uploads/chat/${arquivo}`}
                alt="Imagem"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Link>
          ) : arquivo && String(arquivo).endsWith(".mp4") ? (
            <Link
              to={`${process.env.REACT_APP_API_KEY}/public/uploads/chat/${arquivo}`}
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack alignItems="center" direction={"column"} gap={1}>
                <SmartDisplayIcon style={{ fontSize: 120 }} />
                <Typography fontSize={24}>Vídeo</Typography>
              </Stack>
            </Link>
          ) : arquivo && String(arquivo).endsWith(".pdf") ? (
            <Link
              to={`${process.env.REACT_APP_API_KEY}/public/uploads/chat/${arquivo}`}
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack alignItems="center" direction={"column"} gap={1}>
                <PictureAsPdfIcon style={{ fontSize: 120 }} />
                <Typography fontSize={24}>Arquivo PDF</Typography>
              </Stack>
            </Link>
          ) : arquivo &&
            (String(arquivo).endsWith(".docx") ||
              String(arquivo).endsWith(".xls")) ? (
            <Link
              to={`${process.env.REACT_APP_API_KEY}/public/uploads/chat/${arquivo}`}
              target="_blank"
              style={{
                color: "white",
                textDecoration: "none",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack alignItems="center" direction={"column"} gap={1}>
                <ArticleIcon style={{ fontSize: 120 }} />
                <Typography fontSize={24}>Arquivo</Typography>
              </Stack>
            </Link>
          ) : (
            String(messageContent)
              .split("\n")
              .map((row) => (
                <span>
                  {row}
                  <br />
                </span>
              ))
          )}
        </Box>
      </Stack>
      {userId === userLoggedId && (
        <Box>
          <Avatar
            src={`${process.env.REACT_APP_API_KEY}/public/uploads/${avatarUrl}`}
            sizes="sm"
          >
            {String(username).charAt(0).toLocaleUpperCase()}
          </Avatar>
        </Box>
      )}
    </Stack>
  );
};

export default ChatUserMessage;
