import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import {
    Box,
    Grid,
    OutlinedInput,
    TextField,
    Button,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback } from 'react';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';
import { formatDateWithMoment, getCustomDateTimeUtc } from '@jumbo/utils';

const KitchenTherapy = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { quizId, userId } = useParams();
    const location = useLocation();

    const storedColaboratorId = localStorage.getItem('id');

    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [who, setWho] = useState([]);

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    const date = query.get('date');

    const getQuizzes = useCallback(async () => {
        try {
            await ApiService.get(`/quizzes/${quizId}`)
                .then((response) => {
                    console.log(response.data?.pergunta);

                    setQuizzes(response.data?.pergunta);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setQuizzes, quizId]);

    const getQuizzesAnswers = useCallback(async () => {
        try {
            await ApiService.get(
                `/quizzes/${quizId}/user/${userId}?date=${date}`
            )
                .then((response) => {
                    console.log(response.data);

                    setQuizzes(response.data?.userQuizz?.pergunta);
                    setWho(response.data?.findWhoCreatedAndUpdated);

                    /* setAnswers(respostasFormatadas); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (location?.pathname.includes('edit')) {
            getQuizzesAnswers();
        } else {
            getQuizzes();
        }
    }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

    console.log(answers);

    const getSelectedAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) =>
                resposta?.Resposta_Usuario?.length > 0 &&
                resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.id;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    const getObsAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) => resposta?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.observacao;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    console.log(answers);

    return (
        <div>
            <HeaderBreadcrumbs title={'Terapia de Pares'} titleUrl={'/'} />
            <Paper
                style={{ padding: '10px', marginBottom: '15px' }}
                square={false}
                elevation={3}
            >
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignItems={'center'}
                >
                    <Grid item xs={10}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        Terapia na Cozinha
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) => (
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        {item?.nome !==
                                                        'Observação adicional' ? (
                                                            <>
                                                                <InputLabel id='demo-simple-select-label'>
                                                                    {item?.nome}
                                                                </InputLabel>
                                                                <Select
                                                                    labelId='demo-simple-select-label'
                                                                    id='demo-simple-select'
                                                                    value={getSelectedAnswer(
                                                                        item?.Resposta,
                                                                        item?.id
                                                                    )}
                                                                    label={
                                                                        item?.nome
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAnswers(
                                                                            (
                                                                                prevAnswers
                                                                            ) => {
                                                                                const userAnswered =
                                                                                    item?.Resposta?.find(
                                                                                        (
                                                                                            resposta
                                                                                        ) =>
                                                                                            resposta
                                                                                                ?.Resposta_Usuario
                                                                                                ?.length >
                                                                                                0 &&
                                                                                            resposta
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.pergunta_id ===
                                                                                                item?.id
                                                                                    );
                                                                                const newAnswer =
                                                                                    {
                                                                                        id: userAnswered
                                                                                            ?.Resposta_Usuario[0]
                                                                                            ?.id,
                                                                                        pergunta_id:
                                                                                            item.id,
                                                                                        resposta_id:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        data_horario_resposta:
                                                                                            userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.data_horario_resposta ||
                                                                                            date,
                                                                                    };
                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                const existingAnswerIndex =
                                                                                    prevAnswers.findIndex(
                                                                                        (
                                                                                            ans
                                                                                        ) =>
                                                                                            ans.pergunta_id ===
                                                                                            item.id
                                                                                    );
                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                if (
                                                                                    existingAnswerIndex !==
                                                                                    -1
                                                                                ) {
                                                                                    const updatedAnswers =
                                                                                        [
                                                                                            ...prevAnswers,
                                                                                        ];
                                                                                    updatedAnswers[
                                                                                        existingAnswerIndex
                                                                                    ] =
                                                                                        newAnswer;
                                                                                    return updatedAnswers;
                                                                                } else {
                                                                                    return [
                                                                                        ...prevAnswers,
                                                                                        newAnswer,
                                                                                    ];
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {item?.Resposta?.map(
                                                                        (
                                                                            resposta
                                                                        ) => (
                                                                            <MenuItem
                                                                                value={
                                                                                    resposta?.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    resposta?.resposta
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </>
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    id='outlined-multiline-static'
                                                                    multiline
                                                                    rows={4}
                                                                    label='Observaçao adicional'
                                                                    value={getObsAnswer(
                                                                        item?.Resposta_Usuario,
                                                                        item?.id
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAnswers(
                                                                            (
                                                                                prevAnswers
                                                                            ) => {
                                                                                const userAnswered =
                                                                                    item?.Resposta?.find(
                                                                                        (
                                                                                            resposta
                                                                                        ) =>
                                                                                            resposta
                                                                                                ?.Resposta_Usuario
                                                                                                ?.length >
                                                                                                0 &&
                                                                                            resposta
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.pergunta_id ===
                                                                                                item?.id
                                                                                    );
                                                                                const newAnswer =
                                                                                    {
                                                                                        id: item
                                                                                            ?.Resposta_Usuario[0]
                                                                                            ?.id,
                                                                                        pergunta_id:
                                                                                            item.id,
                                                                                        observacao:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        data_horario_resposta:
                                                                                            userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.data_horario_resposta ||
                                                                                            date,
                                                                                    };
                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                const existingAnswerIndex =
                                                                                    prevAnswers.findIndex(
                                                                                        (
                                                                                            ans
                                                                                        ) =>
                                                                                            ans.pergunta_id ===
                                                                                            item.id
                                                                                    );
                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                if (
                                                                                    existingAnswerIndex !==
                                                                                    -1
                                                                                ) {
                                                                                    const updatedAnswers =
                                                                                        [
                                                                                            ...prevAnswers,
                                                                                        ];
                                                                                    updatedAnswers[
                                                                                        existingAnswerIndex
                                                                                    ] =
                                                                                        newAnswer;
                                                                                    return updatedAnswers;
                                                                                } else {
                                                                                    return [
                                                                                        ...prevAnswers,
                                                                                        newAnswer,
                                                                                    ];
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </Grid>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {location.pathname.includes('edit') && (
                    <>
                        <Divider
                            style={{
                                height: '100%',
                                marginBottom: '20px',
                                marginTop: '20px',
                            }}
                        />
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            rowSpacing={5}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '10px',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>
                                                    Código
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Inclusão
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Inclusão{' '}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Alteração
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Alteração{' '}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell align='center'>
                                                    -
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {formatDateWithMoment(
                                                        who?.data_horario_resposta
                                                    ) ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Incluiu
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {getCustomDateTimeUtc(
                                                        who?.updatedAt
                                                    )}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Alteracao
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                if (location?.pathname.includes('edit')) {
                                    ApiService.put(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                } else {
                                    ApiService.post(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                }
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default KitchenTherapy;
