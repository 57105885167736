import React, { useState, useEffect, useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Checkbox, FormGroup, FormHelperText, Grid, Tooltip, Zoom } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ApiService from 'app/services/config';
import { formatDate } from '@jumbo/utils';
import { useSnackbar } from 'notistack';
import { PermissionContext } from "app/contexts/PermissionContext";

const ScheduleCopy = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [userSelected, setUserSelected] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const { hasPermission } = useContext(PermissionContext);

    const [copyInfos, setCopyInfos] = useState({
        filial: '',
        validade_inicial: '',
        validade_final: '',
        agenda: '',
        id: '',
        Disponibilidade_Prestador: [],
    });
    let copyModel = {
        prestador_id: userSelected?.selected?.id,
        filial: copyInfos?.filial,
        somente_dias_uteis: true,
        tipo_criacao_agenda: 'periodo',
        validade_inicial: copyInfos?.validade_inicial,
        validade_final: copyInfos?.validade_final,
        multiplos_atendentes: false,
        quantidade_vagas_por_horarios: 1,
        servico_agendamento: 'prestador',
        Disponibilidade_Prestador: [
            {
                havera_atendimento: true,
                dia_semana: '1',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '2',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '3',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '4',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '5',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '6',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '17:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: false,
                dia_semana: '0',
                dia_especifico: '',
                filial: '',
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '',
                        horario_inicio: '',
                        horario_fim: '',
                        tempo_atendimento: 0,
                        tipo_agendamento: '',
                    },
                ],
            },
        ],
    };

    const [model, setModel] = useState(copyModel);

    const fetchUsers = async (inputValue) => {
        setLoading(true);
        try {
            const response = await ApiService.get(
                `/users?searchTerm=${inputValue}`
            );
            const users = response.data?.usersWithoutPassword || [];
            console.log(users);
            setOptions(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setUserInput(newInputValue);
        if (newInputValue !== '') {
            fetchUsers(newInputValue);
        } else {
            setOptions([]);
            setUserSelected(null);
        }
    };

    console.log(copyInfos);
    console.log(userSelected);
    return (
        <div>
            <HeaderBreadcrumbs
                title={'Agenda'}
                subtitle={'Cópia'}
                titleUrl={'/schedule-worker'}
            />
            <Paper sx={{ paddingY: 3 }} square={false} elevation={3}>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    spacing={3}
                >
                    <Grid item xs={10}>
                        {/* <Paper sx={{ padding: 3 }} square={false} elevation={3}> */}
                            <Typography variant='h2' gutterBottom>
                                Cópia de agendamento
                            </Typography>
                            <Divider
                                style={{
                                    height: '100%',
                                    marginBottom: '20px',
                                    marginTop: '20px',
                                }}
                            />
                            <Grid container alignItems='center' spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id='demo-simple-select-label'>
                                            Filial
                                        </InputLabel>
                                        <Select
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            value={copyInfos?.filial}
                                            label='Filial'
                                            onChange={(e) =>
                                                setCopyInfos({
                                                    ...copyInfos,
                                                    filial: e.target.value,
                                                })
                                            }
                                        >
                                            <MenuItem value={'pp'}>PP</MenuItem>
                                            <MenuItem value={'aa'}>AA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            getOptionLabel={(option) => option.nome}
                                            options={options.filter(
                                                (option) =>
                                                    option.Agendamento_Prestador
                                                        .length > 0
                                            )}
                                            loading={loading}
                                            inputValue={userInput}
                                            onInputChange={handleInputChange}
                                            onChange={(event, newValue) => {
                                                setUserSelected((prevValues) => ({
                                                    selected: newValue,
                                                }));
                                                const disponibility = newValue?.Disponibilidade_Prestador_Infos.filter(x => x?.Disponibilidade_Prestador[0]?.agenda_modelo == true)

                                                setModel({
                                                    ...model,
                                                    Disponibilidade_Prestador: disponibility[0]?.Disponibilidade_Prestador,
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Prestador'
                                                    placeholder='Prestador'
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading
                                                                    ? 'carregando...'
                                                                    : null}
                                                                {
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment
                                                                }
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        id='validade-final'
                                        label='Validade Inicial'
                                        variant='outlined'
                                        type='date'
                                        InputLabelProps={{ shrink: true }}
                                        value={copyInfos?.validade_inicial}
                                        onChange={(e) =>
                                            setCopyInfos({
                                                ...copyInfos,
                                                validade_inicial:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        id='validade-final'
                                        label='Validade Final'
                                        variant='outlined'
                                        type='date'
                                        InputLabelProps={{ shrink: true }}
                                        value={copyInfos?.validade_final}
                                        onChange={(e) =>
                                            setCopyInfos({
                                                ...copyInfos,
                                                validade_final: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Agenda
                                        </FormLabel>
                                        <RadioGroup
                                            
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={copyInfos?.agenda}
                                            onChange={(e) =>
                                                setCopyInfos({
                                                    ...copyInfos,
                                                    agenda: e.target.value,
                                                })
                                            }
                                        >
                                            <FormControlLabel
                                                value='modelo'
                                                control={<Radio />}
                                                label='Modelo'
                                            />
                                            <FormControlLabel
                                                value='producao'
                                                control={<Radio />}
                                                label='Produção'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl component="fieldset">
                                        {/* <FormLabel component="legend">Configurações de Agendamento</FormLabel> */}
                                        <FormGroup>
                                            <FormControlLabel
                                            control={
                                                <Checkbox
                                                checked={checked}
                                                onChange={(e) => setChecked(e.target.checked)}
                                                inputProps={{ 'aria-label': 'Copiar agendamentos do prestador' }}
                                                />
                                            }
                                            label="Copiar agendamentos"
                                            />
                                        </FormGroup>
                                        <FormHelperText>
                                            Selecione esta opção para copiar todos os agendamentos do prestador durante o periodo selecionado.
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                {copyInfos?.agenda === 'producao' ? (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id='demo-simple-select-label'>
                                                Periodo á copiar
                                            </InputLabel>
                                            <Select
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                value={copyInfos?.id}
                                                label='Periodo á copiar'
                                                onChange={(e) =>
                                                    setCopyInfos({
                                                        ...copyInfos,
                                                        id: e.target.value,
                                                    })
                                                }
                                            >
                                                {options[0]?.Disponibilidade_Prestador_Infos?.map(
                                                    (item) => (
                                                        <MenuItem
                                                            value={item.id}
                                                        >
                                                            {`De ${formatDate(
                                                                item.validade_inicial
                                                            )} até ${formatDate(
                                                                item?.validade_final
                                                            )}`}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} textAlign={'center'}>
                                    <Grid item container spacing={2}>
                                        <Grid item xs>
                                            <Button
                                                fullWidth
                                                variant='outlined'
                                                disabled={!hasPermission('Agenda', 'create')}
                                                onClick={() => {
                                                    const periodSelect =
                                                        options[0]?.Disponibilidade_Prestador_Infos.find(
                                                            (item) =>
                                                                item?.id ===
                                                                copyInfos?.id
                                                        );
                                                    const oldInitDate = periodSelect?.validade_inicial;

                                                    const oldEndDate = periodSelect?.validade_final;
                                                      
                                                    let copyCreated;

                                                    if (
                                                        copyInfos?.agenda ===
                                                        'producao'
                                                    ) {
                                                        copyCreated = {
                                                            ...copyInfos,
                                                            id: undefined,
                                                            copy_schedules: checked,
                                                            antiga_validade_inicial: oldInitDate,
                                                            antiga_validade_final: oldEndDate,
                                                            prestador_id:
                                                                userSelected
                                                                    ?.selected
                                                                    ?.id,
                                                            somente_dias_uteis:
                                                                periodSelect?.somente_dias_uteis,
                                                            tipo_criacao_agenda:
                                                                periodSelect?.tipo_criacao_agenda,
                                                            validade_inicial:
                                                                copyInfos?.validade_inicial,
                                                            validade_final:
                                                                copyInfos?.validade_final,
                                                            multiplos_atendentes:
                                                                periodSelect?.multiplos_atendentes,
                                                            quantidade_vagas_por_horarios:
                                                                periodSelect?.quantidade_vagas_por_horarios,
                                                            servico_agendamento:
                                                                periodSelect?.servico_agendamento,

                                                            Disponibilidade_Prestador:
                                                                periodSelect?.Disponibilidade_Prestador,
                                                        };
                                                    } else {
                                                        copyCreated = {
                                                            agenda: 'modelo',
                                                            copy_schedules: checked,
                                                            ...model,
                                                            prestador_id:
                                                                userSelected
                                                                    ?.selected
                                                                    ?.id,
                                                            somente_dias_uteis:
                                                                periodSelect?.somente_dias_uteis,
                                                            tipo_criacao_agenda:
                                                                periodSelect?.tipo_criacao_agenda,
                                                            validade_inicial:
                                                                copyInfos?.validade_inicial,
                                                            validade_final:
                                                                copyInfos?.validade_final,
                                                            multiplos_atendentes:
                                                                periodSelect?.multiplos_atendentes,
                                                            quantidade_vagas_por_horarios:
                                                                periodSelect?.quantidade_vagas_por_horarios,
                                                            servico_agendamento:
                                                                periodSelect?.servico_agendamento,
                                                            
                                                        };
                                                    }

                                                    console.log(copyCreated);

                                                    ApiService.post(
                                                        `/worker-availability-infos/copy`,
                                                        copyCreated
                                                    )
                                                        .then((response) => {
                                                            console.log(
                                                                response.data
                                                            );
                                                            enqueueSnackbar(
                                                                'Cópia criada com sucesso!',
                                                                {
                                                                    variant:
                                                                        'success',
                                                                    
                                                                }
                                                            );
                                                        })
                                                        .catch((error) => {
                                                            const errorMessage =
                                                                error.response
                                                                    .data
                                                                    .message;
                                                            console.log(
                                                                errorMessage
                                                            );
                                                            if (
                                                                Array.isArray(
                                                                    errorMessage
                                                                )
                                                            ) {
                                                                enqueueSnackbar(
                                                                    `${errorMessage[0]}`,
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                        
                                                                    }
                                                                );
                                                            } else {
                                                                enqueueSnackbar(
                                                                    `${errorMessage}`,
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                        
                                                                    }
                                                                );
                                                            }
                                                        });
                                                }}
                                            >
                                                Gerar Cópia
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider
                                style={{
                                    height: '100%',
                                    marginBottom: '20px',
                                    marginTop: '20px',
                                }}
                            />
                        {/* </Paper> */}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default ScheduleCopy;
