import { Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ApiService from "app/services/config";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";

const PatientsList = ({ prestadorId }) => {
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const [filiais, setFiliais] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermission } = useContext(PermissionContext);
  const [patients, setPatients] = useState([]);
  console.log('patients', patients)

  const DIAS_SEMANA = {
    0: "Domingo",
    1: "Segunda-feira",
    2: "Terça-feira",
    3: "Quarta-feira",
    4: "Quinta-feira",
    5: "Sexta-feira",
    6: "Sábado",
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "user_id",
      label: "User Id",
      options: {
        display: false,
      },
    },
    {
      name: "dia_semana",
      label: "Dia da semana",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" gap={2}>
              <Typography>{DIAS_SEMANA[value]}</Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "horario",
      label: "Horário",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "User",
      label: "Paciente",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const name = value.nome;
          return (
            <Stack direction="row" gap={2}>
              <Typography>{name}</Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "tipo_de_agendamento",
      label: "Tipo de agendamento",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "Convenio",
      label: "Convênio",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const name = value.nome;
          return (
            <Stack direction="row" gap={2}>
              <Typography>{name}</Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "status",
      label: "Situação da agenda",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "acoes",
      label: "Ações",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const scheduleId = tableMeta.rowData[0];
          const userId = tableMeta.rowData[1];
          return (
            <Stack direction="row" gap={2}>
              <Button
                onClick={() => navigate(`/people/edit/${userId}`)}
                disabled={!hasPermission('Pessoas', 'update')}
                style={{ marginLeft: '5px', minWidth: 'auto' }}>
                  <VisibilityIcon />
              </Button>

              <Button
                onClick={() => modalAlert(scheduleId)}
                disabled={!hasPermission('Filiais', 'delete')}
                style={{ marginLeft: '5px', minWidth: 'auto' }}>
                  <DeleteIcon />
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    selectToolbarPlacement: "above",
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja excluir o agendamento?`,
      text: `Você não poderá reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteUserFromModel(id);
      }
    });
  };

  const getFiliais = async () => {
    try {
      let apiUrl = `/users/model/${prestadorId}`;
  
      await ApiService.get(apiUrl).then((response) => {
        if (response.status === 200) {
          setPatients(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteUserFromModel = async (id) => {
    try {
      await ApiService.delete(`/schedules/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Excluído com sucesso!", {
            variant: "success",
          });
          getFiliais();
          setForceUpdate(!forceUpdate);
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  useEffect(() => {
    getFiliais();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  return (
    <div>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                <MUIDataTable
                  data={patients}
                  columns={columns}
                  options={options}
                  // key={forceUpdate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(`/schedule-worker/pacient-schedule/new?workerId=${prestadorId}&model=true`, {
                  state: {
                    backUrl: '/ScheduleModel'
                  }
                });
              }}
              // disabled={!hasPermission('Filiais', 'create')}
            >
              Criar agendamento
            </Button>
          </Grid>
        </Grid>
    </div>
  );
};

export default PatientsList;
