import React, { useState, useEffect, useCallback, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "../DataTable";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useNavigate } from "react-router-dom";
import ApiService from "app/services/config";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from "app/contexts/PermissionContext";
import { useSnackbar } from "notistack";
import { getCustomDateTimeBd } from "@jumbo/utils";

const UserPool = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const { hasPermission } = useContext(PermissionContext);
  const [userInput, setUserInput] = useState("");
  
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
    
  const modalAlert = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja remover do acesso externo?`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeletePatientFromPool(id);
      }
    });
  };

  const getClasses = useCallback(async () => {
    try {
      let apiUrl = `/usuario-externo?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.users);
          setCount(response.data?.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  const handleDeletePatientFromPool = async (id) => {
    try {
      await ApiService.delete(`/usuario-externo/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Usuário removido do acesso externo!", {
            variant: "success",
            
          });
          getClasses();
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "User",
      label: "Nome de usuário",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value.nome
        }
      },
    },
    {
      name: "User",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value.email
        }
      },
    },
    {
      name: "Colaborador_Inclusao",
      label: "Colaborador Inclusão",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value?.nome
        }
      },
    },
    {
      name: "createdAt",
      label: "Data de inclusão",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return getCustomDateTimeBd(value)
        }
      },
    },
    {
      name: "Colaborador_Alteracao",
      label: "Colaborador Alteração",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return value?.nome
        }
      },
    },
    {
      name: "updatedAt",
      label: "Data de alteração",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const providerName = tableMeta[5]

          return providerName ? getCustomDateTimeBd(value) : ''
        }
      },
    },
    {
      name: "acao",
      label: "Ação",
      options: {
        customBodyRender: (value, tableMeta) => {
          const patientId = tableMeta.rowData[0];
          return (
            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(`/user-pool/${patientId}`);
                }}
                disabled={!hasPermission('Usuário Externo', 'update')}
              >
                <VisibilityIcon sx={{ cursor: "pointer" }} />
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => modalAlert(patientId)}
                disabled={!hasPermission('Usuário Externo', 'delete')}
              >
                <DeleteIcon sx={{ cursor: "pointer" }} />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs
          title={'Usuário externo'}
          titleUrl={'/user-pool'}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        {/* <Typography variant="h1" gutterBottom style={{ marginBottom: "15px" }}>
          Turmas
        </Typography> */}
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item textAlign={"center"} xs={12}>
            <Button
              endIcon={<GroupAddIcon />}
              variant="contained"
              to="/NewUserPool"
              onClick={() => {
                navigate("/new-user-pool");
              }}
              disabled={!hasPermission('Usuário Externo', 'create')}
            >
              Novo acesso externo
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataTable columns={columns} data={users} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default UserPool;
