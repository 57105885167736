import Chip from "@mui/material/Chip";
import DataTable from "../DataTable";
import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Box, Grid, OutlinedInput, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import { DateRangePicker } from "react-dates";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { PermissionContext } from "app/contexts/PermissionContext";

const FiliaisList = () => {
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const [filiais, setFiliais] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermission } = useContext(PermissionContext);
    
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "sigla",
      label: "Sigla",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "acoes",
      label: "Ações",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const filialId = tableMeta.rowData[0];
          return (
            <Stack direction="row" gap={2}>
              <Button
                onClick={() => navigate(`/editar-filial/${filialId}`)}
                disabled={!hasPermission('Filiais', 'update')}
                style={{ marginLeft: '5px', minWidth: 'auto' }}>
                  <VisibilityIcon />
              </Button>

              <Button
                onClick={() => modalAlert(filialId)}
                disabled={!hasPermission('Filiais', 'delete')}
                style={{ marginLeft: '5px', minWidth: 'auto' }}>
                  <DeleteIcon />
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    selectToolbarPlacement: "above",
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja excluir a filial?`,
      text: `Você não poderá reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteFilial(id);
      }
    });
  };

  const getFiliais = async () => {
    try {
      let apiUrl = `/filial/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
  
      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      await ApiService.get(apiUrl).then((response) => {
        if (response.status === 200) {
          setFiliais(response.data?.filiais);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteFilial = async (id) => {
    try {
      await ApiService.delete(`/filial/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Excluído com sucesso!", {
            variant: "success",
          });
          getFiliais();
          setForceUpdate(!forceUpdate);
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  useEffect(() => {
    getFiliais();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  return (
    <div>
      <HeaderBreadcrumbs title={"Filiais"} titleUrl={"/filiais"} />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                <MUIDataTable
                  data={filiais}
                  columns={columns}
                  options={options}
                  key={forceUpdate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/nova-filial");
              }}
              disabled={!hasPermission('Filiais', 'create')}
            >
              Criar
            </Button>
          </Grid>
        </Grid>

        {/* deixei comentado abaixo por que nao sei se vai precisar */}
        {/* e se precisar ja ta ai ;p */}
        {/* <Grid container justifyContent="center" alignItems="center" rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item xs={12}>
                        <Paper sx={{ backgroundColor: "#f5f5f5", borderRadius: "10px", boxShadow: "none", }} >
                            <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Código</TableCell>
                                    <TableCell align="center">Inclusão</TableCell>
                                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                                    <TableCell align="center">Alteração</TableCell>
                                    <TableCell align="center"> Colaborador Alteração </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, }} >
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                    <TableCell align="center">
                                        placeholder
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid> */}
      </Paper>
    </div>
  );
};

export default FiliaisList;
