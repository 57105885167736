import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import SendIcon from '@mui/icons-material/Send';
import MUIDataTable from 'mui-datatables';
import { TextField } from '@mui/material';
import { getCustomDateTimeUtc } from '@jumbo/utils';
import moment from 'moment';

const Patient = ({ infos, setValue }) => {
    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    console.log(infos);
    const columns = [
        {
            name: 'data',
            label: 'Data',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const index = tableMeta?.rowIndex;
                    /* getCustomDateTimeUtc */
                    const weekDay = infos?.Agendamento[index]?.dia_semana
                    return (
                        <Typography>
                            {value
                            ?
                            `
                                ${value?.split('-')?.reverse()?.join('/')} 
                                ${infos?.Agendamento[index]?.horario}
                            ` 
                            : moment().day(weekDay).format('dddd')}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'status',
            label: 'Situação',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'tipo_de_agendamento',
            label: 'Agendamento',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Convenio.nome',
            label: 'Convênio',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Prestador.nome',
            label: 'Prestador',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Colaborador.nome',
            label: 'Colaborador que agendou',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Colaborador_Alteracao.nome',
            label: 'Colaborador que alterou',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'createdAt',
            label: 'Ocorrência criação',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography>
                            {`${getCustomDateTimeUtc(value)} `}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'updatedAt',
            label: 'Ocorrência alteração',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography>
                            {`${getCustomDateTimeUtc(value)} `}
                        </Typography>
                    );
                },
            },
        },
    ];

    const options = {
        selectableRows: 'none',
        download: false,
        print: false,
        searchAlwaysOpen: true,
        enableNestedDataAccess: '.',
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'of',
            },
        },
    };

    return (
        <div>
            <Paper
                style={{ padding: '10px', marginBottom: '15px' }}
                square={false}
                elevation={3}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h1' gutterBottom>
                            Dados Básicos
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h5'
                            gutterBottom
                            style={{ marginBottom: '15px' }}
                        >
                            Mãe:{' '}
                            {`${infos?.mae?.nome ?? ''} ${
                                infos?.mae?.profissao ? ' / ' + infos.mae.profissao : ''
                            } ${
                                infos?.mae?.telefone.length > 0
                                    ? ' / ' + infos?.mae?.telefone[0]?.ddd
                                    : ''
                            } ${
                                infos?.mae?.telefone.length > 0
                                    ? infos?.mae?.telefone[0]?.numero
                                    : ''
                            } `}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h5'
                            gutterBottom
                            style={{ marginBottom: '15px' }}
                        >
                            Pai:{' '}
                            {`${infos?.pai?.nome ?? ''} ${
                                infos?.pai?.profissao ? ' / ' + infos.pai.profissao : ''
                            } ${
                                infos?.pai?.telefone.length > 0
                                    ?  ' / ' + infos?.pai?.telefone[0].ddd
                                    : ''
                            } ${
                                infos?.pai?.telefone.length > 0
                                    ? infos?.pai?.telefone[0].numero
                                    : ''
                            } `}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            expanded={expanded === 'panel3'}
                            onChange={handleAccordionChange('panel3')}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon />}
                                aria-controls='panel1-content'
                                id='panel1-header'
                            >
                                <Typography>
                                    <span
                                        style={{
                                            verticalAlign: 'middle',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        <SpeakerNotesIcon />
                                    </span>
                                    Observação
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    rowSpacing={1}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <TextField
                                        fullWidth
                                        style={{
                                            marginTop: '25px',
                                            marginBottom: '10px',
                                        }}
                                        name='Pessoa.observacao'
                                        label='Observação'
                                        multiline
                                        rows={4}
                                        value={infos?.observacao}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h1' gutterBottom>
                        Consultas Anteriores
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={''}
                        data={infos?.Agendamento}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                style={{ marginTop: '15px' }}
            >
                <Grid item xs={12} md={4}>
                    <Button 
                        variant='outlined' 
                        endIcon={<SendIcon />} 
                        onClick={() =>
                            setValue(7)
                        }
                    >
                        Enviar Arquivos
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Patient;
