import { Box, Button, Card, IconButton, Tooltip, Typography, Zoom } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

export const ContactItem = ({ contact, handleEditContact, modalAlert }) => {
    return (
        <Card sx={{ mb: 2, padding: 2, borderRadius: 3 }} elevation={0}>
            <Box display='flex' justifyContent='space-between'>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Tipo de Contato
                    </Typography>

                    <Typography mt={1}>{contact?.tipo_de_contato}</Typography>
                </Box>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Contato
                    </Typography>

                    <Typography mt={1}>{contact?.contato}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Ação
                    </Typography>
                    <Box display={'flex'} gap={1}>
                       
                        <Tooltip title='Editar' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='editar'
                                color='info'
                                onClick={() => handleEditContact(contact)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title='Remover' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='remover'
                                color='error'
                                onClick={() => modalAlert(contact.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};
