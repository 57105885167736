import {
  Avatar,
  AvatarGroup,
  Badge,
  ClickAwayListener,
  Grow,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useSnackbar } from "notistack";
import ApiService from "app/services/config";
import { useParams } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";

const ChatHeader = ({
  selectedRoom,
  handleDeleteChatroom,
  handleOpenModalDetails,
}) => {
  const { hasPermission }  = useContext(PermissionContext);

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { userId: patientId } = useParams();
  const anchorRef = useRef(null);
  const Swal = useSwalWrapper();

  const modalAlert = () => {
    Swal.fire({
      title: `Tem certeza que deseja excluir a sala?`,
      text: `Você perderá as mensagens enviadas e não poderá reverter esta ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteChatroom();
      }
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open, selectedRoom]);

  return (
    <Paper
      sx={{
        width: "100%",
        padding: "10px",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="row"
        gap={1}
        alignContent={"center"}
        justifyContent={"flex-start"}
        width={"95%"}
      >
        <Link
          width={"90%"}
          onClick={handleOpenModalDetails}
          sx={{ textDecoration: "none", color: "#2D3737", cursor: "pointer" }}
        >
          <Stack direction="row" gap={1} width={"100%"} alignItems={"center"}>
            <Avatar>
              {String(selectedRoom?.nome).charAt(0).toUpperCase()}
            </Avatar>
            <Typography fontSize={16} fontWeight={500}>
              {selectedRoom?.nome}
            </Typography>
          </Stack>
        </Link>
        <AvatarGroup sx={{ display: "flex", alignItems: "center" }}>
          {selectedRoom &&
            selectedRoom?.users?.length > 0 &&
            selectedRoom?.users
              .filter((user) => Number(user.id) !== Number(patientId))
              .map((user) => (
                <Tooltip
                  key={user.id}
                  title={user?.nome}
                  sx={{ background: "none" }}
                >
                  <Avatar
                    src={`${process.env.REACT_APP_API_KEY}/public/uploads/${user?.avatar_url}`}
                    alt={user?.nome}
                    sx={{ width: 24, height: 24, backgroundColor: "#ecec" }}
                  >
                    {String(user?.nome).charAt(0).toUpperCase()}
                  </Avatar>
                </Tooltip>
              ))}
        </AvatarGroup>
      </Stack>
      {
        !hasPermission('Paciente Evolução', 'delete') ? undefined : (
          <Stack
            alignSelf={"flex-end"}
            sx={{ display: "flex", alignItems: "center", my: 1 }}
          >
            <MoreVertIcon
              color="primary"
              cursor={"pointer"}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            />
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={modalAlert} sx={{ color: "#C80223" }}>
                          Excluir sala
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        )
      }
    </Paper>
  );
};

export default ChatHeader;
