import React, { useState, useEffect, useRef, useContext } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Autocomplete, FormGroup, Grid, Stack, Tooltip, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import FormLabel from "@mui/material/FormLabel";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import MUIDataTable from "mui-datatables";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Search } from "@mui/icons-material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const STATUS_AGENDA = {
  agendado: "Agendado",
  aguardando: "Aguardando",
  atendido: "Atendido",
  caixa: "Caixa",
  desmarcou: "Desmarcou",
  em_atendimento: "Em atendimento",
  falta_cobrada: "Falta cobrada",
  falta_nao_justificada: "Falta",
};

const PendingSchedules = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [pendingSchedules, setPendingSchedules] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [userInput, setUserInput] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    prestador_id: null,
    data_inicio: null,
    data_final: null,
    todo_periodo: false,
    status: [],
  });
  const formikRef = useRef();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);

  function redirectToSchedules(user_id) {
    localStorage.setItem("tabIndex", 8);
    return navigate(`/people/edit/${user_id}`, {
      state: {
        backUrl: "/pendingschedules",
        tabIndex: 7
      }
    });
  }

  const modalAlert = (id, status) => {
    Swal.fire({
      title: `Tem certeza que deseja marcar o status como bloqueio ${
        status === "atendido" ? "atendido" : "falta"
      }?`,
      text: `Você pode reverter a ação posteriormente!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (status === "atendido") {
          handleMarcarStatusAtendido(id);
        }
        if (status === "falta") {
          handleMarcarStatusFalta(id);
        }
      }
    });
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users/all/prestadores?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDiaBloqueioAgendaPrestador = async (id) => {
    try {
      await ApiService.delete(`/closed/schedules/dia/${id}`).then(
        (response) => {
          if (response.status === 200) {
            console.log("Dia deletado!");
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteHorarioAgenda = async (ids) => {
    try {
      await ApiService.post(`/closed/schedules/deletar/horarios`, {
        ids,
      }).then((response) => {
        if (response.status === 201) {
          console.log("Horários excluídos!");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const [selectedStatus, setSelectedStatus] = React.useState([]);

  const [value, setValue] = React.useState("bloquear");

  const handleBloqChange = (event) => {
    setValue(event.target.value);
  };

  const handleStatusChange = (value) => {
    if (selectedStatus.some((status) => status === value[value.length - 1])) {
      const index = selectedStatus.findIndex(
        (status) => status === value[value.length - 1]
      );
      const newSelectedStatus = [
        ...selectedStatus.slice(0, index),
        ...selectedStatus.slice(index + 1),
      ];
      setSelectedStatus(newSelectedStatus);
      setFiltrosPesquisa((prevFiltros) => ({
        ...prevFiltros,
        status: newSelectedStatus,
      }));
    } else {
      const newSelectedStatus =
        typeof value === "string"
          ? value.split(",")
          : value.map((status) =>
              typeof status === "object" ? status : status
            );

      setSelectedStatus(newSelectedStatus);
      setFiltrosPesquisa((prevFiltros) => ({
        ...prevFiltros,
        status: newSelectedStatus,
      }));
    }
  };

  //Exemple Select
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  //end Exemple

  const [showHorarios, setShowHorarios] = useState(false);

  const handleSelecionarHorarios = () => {
    setShowHorarios(!showHorarios);
  };

  const handleDeleteBloqueio = async (id) => {
    try {
      await ApiService.delete(`/closed/schedules/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Excluído com sucesso!", {
            variant: "success",
          });
          handleLimparForm();
          setForceUpdate(!forceUpdate);
          setShowHorarios(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "User",
      label: "Cadastro Paciente",
      options: {
        filter: true,
        sort: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
                // variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(`/people/edit/${value?.id}`)
                }}
                disabled={!hasPermission('Agendamento Pendente', 'update')}
              >
                <ContactEmergencyIcon color="primary" sx={{ cursor: "pointer" }} />
            </Button>
          );
        },
      },
    },
    {
      name: "agenda_id",
      label: "Agenda",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[9];
          return (
            <CalendarMonthIcon
              sx={{ cursor: "pointer" }}
              color="primary"
              onClick={() => {
                redirectToSchedules(userId);
              }}
            />
          );
        },
      },
    },
    {
      name: "User",
      label: "Paciente",
      options: {
        sort: true,
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome;
        },
      },
    },
    {
      name: "status",
      label: "Situação",
      options: {
        sort: true,
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          let BG_COLOR = "primary";
          switch (value) {
            case "atendido":
              BG_COLOR = "success";
              break;
            case "agendado":
              BG_COLOR = "warning";
              break;
            case "falta_nao_justificada":
              BG_COLOR = "error";
              break;
          }
          return (
            <>
              <Chip color={BG_COLOR} label={<>{STATUS_AGENDA[value]}</>} />
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Atendido",
      options: {
        sort: true,
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          const agendaId = tableMeta.rowData[0];
          return (
            <CheckCircleIcon
              color={"success"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                modalAlert(agendaId, "atendido");
              }}
            />
          );
        },
      },
    },
    {
      name: "",
      label: "Falta",
      options: {
        sort: true,
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          const agendaId = tableMeta.rowData[0];
          return (
            <CancelIcon
              color="error"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                modalAlert(agendaId, "falta");
              }}
            />
          );
        },
      },
    },
    {
      name: "User",
      label: "Último atendimento",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.Atendimentos_Feitos?.length > 0 ? (
            <>
              {moment(value.Atendimentos_Feitos?.[0].createdAt).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </>
          ) : null;
        },
      },
    },
    {
      name: "Convenio",
      label: "Último agendamento",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome;
        },
      },
    },
    {
      name: "user_id",
      label: "ID Usuário",
      options: {
        display: false
      }
    }
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  const getPendingSchedules = async () => {
    try {
      let filtros = {};

      if (searchTerm !== null && searchTerm !== "") {
        filtros.searchTerm = searchTerm;
      }

      if (filtrosPesquisa.data_inicio && filtrosPesquisa.data_final) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (filtrosPesquisa.prestador_id) {
        filtros.prestador_id = filtrosPesquisa.prestador_id;
      }

      if (filtrosPesquisa.status && filtrosPesquisa.status.length > 0) {
        filtros.status = filtrosPesquisa.status;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortBy = sortBy;
      filtros.sortOrder = sortOrder;

      await ApiService.post(`/schedules/pending`, filtros)
        .then((response) => {
          if (response.status === 201) {
            setPendingSchedules(response.data?.pendingschedules);
            setCount(response.data?.count);
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar("Erro ao realizar a busca!", {
            variant: "error",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimparForm = () => {
    setUserSelected(null);
    setShowHorarios(!showHorarios);
    setForceUpdate(!forceUpdate);
    setFiltrosPesquisa({
      data_final: "",
      data_inicio: "",
      prestador_id: null,
      status: [],
      todo_periodo: false,
    });
    setUserSelected(null);
    getPendingSchedules();
  };

  const handleMarcarStatusAtendido = async (id) => {
    try {
      await ApiService.put(`/schedules/status/atendido/${id}`).then(
        (response) => {
          if (response.status === 200) {
            enqueueSnackbar("Status atualizado para atendido!", {
              variant: "success",
            });
            handleLimparForm();
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleMarcarStatusFalta = async (id) => {
    try {
      await ApiService.put(`/schedules/status/falta/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Status atualizado para falta!", {
            variant: "success",
          });
          handleLimparForm();
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    getPendingSchedules();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Operacional"}
        subtitle={"Agendamentos Pendentes"}
        titleUrl={"/pendingschedules"}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Divider style={{ height: "100%", marginBottom: "20px" }} />

        <Grid container sx={{
            width: "100%",
            p: "5px",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}>
          <Grid item>
            <Typography variant={"h2"}>Pesquisar</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            p: "5px",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Grid item xs={3} md={2.5}>
            <FormControl fullWidth>
              <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                <Autocomplete
                    fullWidth
                    disablePortal
                    getOptionLabel={(option) => option.nome}
                    options={optionsList}
                    loading={loading}
                    inputValue={userInput}
                    onInputChange={handleInputChange}
                    name={"prestador_id"}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setUserSelected((prevValues) => ({
                          selected: newValue,
                        }));
                      }
                    }}
                    noOptionsText={"Nenhum resultado encontrado"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Prestador"
                        placeholder="Prestador"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? "carregando..." : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Tooltip>
            </FormControl>
          </Grid>

          <Grid item xs={3} md={2.5}>
            <FormControl style={{ width: "100%" }}>
              <TextField
                fullWidth
                id="data-inicial"
                label="Data Inicial"
                name="data_inicio"
                disabled={filtrosPesquisa.todo_periodo}
                value={filtrosPesquisa.data_inicio}
                onChange={(event) => {
                  setFiltrosPesquisa((prevFiltros) => ({
                    ...prevFiltros,
                    data_inicio: event.target.value,
                  }));
                }}
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} md={2.5}>
            <FormControl style={{ width: "100%" }}>
              <TextField
                fullWidth
                id="data-final"
                label="Data Final"
                variant="outlined"
                disabled={filtrosPesquisa.todo_periodo}
                value={filtrosPesquisa.data_final}
                onChange={(event) => {
                  setFiltrosPesquisa((prevFiltros) => ({
                    ...prevFiltros,
                    data_final: event.target.value,
                  }));
                }}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} md={2.5}>
            <FormGroup>
              {console.log(filtrosPesquisa)}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtrosPesquisa.todo_periodo}
                    onChange={(event, newValue) => {
                      setUserSelected(newValue);
                      setFiltrosPesquisa((prevFiltros) => ({
                        ...prevFiltros,
                        todo_periodo: event.target.checked,
                      }));
                      if (event.target.checked) {
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          data_inicio: null,
                        }));
                        setFiltrosPesquisa((prevFiltros) => ({
                          ...prevFiltros,
                          data_final: null,
                        }));
                      }
                    }}
                  />
                }
                value={filtrosPesquisa.todo_periodo}
                label="Todo o período"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            width: "100%",
            p: "5px",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Grid item xs={3} md={2.5}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-multiple-chip-checkbox-label">
                Selecionar status
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-checkbox-label"
                id="demo-multiple-chip-checkbox"
                multiple
                value={filtrosPesquisa.status ?? []}
                name={"status"}
                onChange={(event) => {
                  handleStatusChange(event.target.value);
                  console.log(event.target.value);
                }}
                input={
                  <OutlinedInput
                    id="select-multiple-chip-checkbox"
                    label="Selecionar status"
                  />
                }
                renderValue={() => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {filtrosPesquisa.status.length === 1 ? (
                      <Chip
                        key={value}
                        label={STATUS_AGENDA[filtrosPesquisa.status[0]]}
                      />
                    ) : (
                      <Chip
                        key={value}
                        label={`${filtrosPesquisa.status.length} status selecionados`}
                      />
                    )}
                  </Box>
                )}
              >
                {Object.entries(STATUS_AGENDA).map(([chave, valor], index) => (
                  <MenuItem key={index} value={chave}>
                    <Checkbox
                      checked={filtrosPesquisa?.status?.some(
                        (status) => String(status) === String(chave)
                      )}
                    />
                    <ListItemText primary={valor} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              size="large"
              sx={{
                maxWidth: {
                  md: "150px",
                  mt: "1rem",
                },
              }}
              /* disabled */
              startIcon={<Search />}
              onClick={getPendingSchedules}
            >
              PROCURAR
            </LoadingButton>
          </Grid>
          <Grid item md={2.5}>
            <Button
              color="primary"
              type="submit"
              variant="outlined"
              size="large"
              sx={{
                maxWidth: {
                  md: "200px",
                  mt: "1rem",
                },
              }}
              /* disabled */
              startIcon={<FilterAltOffIcon />}
              onClick={handleLimparForm}
            >
              Limpar filtros
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper my={2} elevation={3}>
        <MUIDataTable
          data={pendingSchedules}
          columns={columns}
          options={options}
          key={forceUpdate}
        />
      </Paper>
    </div>
  );
};

export default PendingSchedules;
