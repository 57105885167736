import React from 'react';
import TodayIcon from '@mui/icons-material/Today';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PoolIcon from '@mui/icons-material/Pool';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArticleIcon from '@mui/icons-material/Article';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MenuIcon from '@mui/icons-material/Menu';
import LockResetIcon from '@mui/icons-material/LockReset';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import DescriptionIcon from '@mui/icons-material/Description';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Diversity1Icon from '@mui/icons-material/Diversity1';

const menus = [
    {
        label: 'Geral',
        type: 'section',
        children: [
            {
                uri: '/people',
                label: 'Pessoas',
                type: 'nav-item',
                icon: <GroupsIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/people/unified",
                label: 'Unificação',
                type: "nav-item",
                icon: <PersonIcon sx={{fontSize: 20}}/>,
            },
            {
                uri: '',
                label: 'Sair',
                type: 'nav-item',
                icon: <ExitToAppIcon sx={{ fontSize: 20 }} />,
            },
        ],
    },

    {
        label: 'Saúde',
        type: 'section',
        children: [
            {
                uri: '',
                label: 'Agenda',
                type: 'collapsible',
                icon: <CalendarMonthIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: '/SchedulesClosing',
                        label: 'Editar Horários',
                        type: 'nav-item',
                    },
                    {
                        uri: '/schedule-list',
                        label: 'Gerar Agenda', //até 4 Periodos
                        type: 'nav-item',
                    },
                ],
            },
            {
                uri: '/guidelines',
                label: 'Orientações',
                type: 'nav-item',
                icon: <DescriptionIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '',
                label: 'Piscina',
                type: 'collapsible',
                icon: <PoolIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: '/team',
                        label: 'Turma',
                        type: 'nav-item',
                    },
                    {
                        uri: '/user-pool',
                        label: 'Usuário Externo',
                        type: 'nav-item',
                    },
                ],
            },
            {
                uri: '',
                label: 'Operacional',
                type: 'collapsible',
                icon: <BuildCircleIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: '/pendingschedules',
                        label: 'Agendamento Pendente',
                        type: 'nav-item',
                    },
                ],
            },
            {
                uri: '',
                label: 'Relatórios',
                type: 'collapsible',
                icon: <ArticleIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: '/Birthdays',
                        label: 'Aniversariantes',
                        type: 'nav-item',
                    },
                    {
                        uri: '/relatorios/agendamentos',
                        label: 'Agendamentos',
                        type: 'nav-item',
                    },
                    {
                        uri: '/atendimentos',
                        label: 'Atendimentos',
                        type: 'nav-item',
                    },
                    {
                        uri: '/atendimentos-gerais',
                        label: 'Atendimentos geral',
                        type: 'nav-item',
                    },
                    {
                        uri: '/ActiveOrInactivePatient',
                        label: 'Paciente Ativo ou Inativo',
                        type: 'nav-item',
                    },
                    // {
                    //     uri: '',
                    //     label: 'Produçao',
                    //     type: 'nav-item',
                    // },
                ],
            },
            {
                uri: '/schedule-worker-logged',
                label: 'Agenda Prestador',
                type: 'nav-item',
                icon: <EventNoteIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/schedule-worker',
                label: 'Agenda Horizontal',
                type: 'nav-item',
                icon: <EventNoteIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/Pacient',
                label: 'Paciente',
                type: 'nav-item',
                icon: <EmojiPeopleIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/evolution',
                label: 'Paciente Evolução',
                type: 'nav-item',
                icon: <TrendingUpIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/GeneralArchivesSend',
                label: 'Arquivos Gerais',
                type: 'nav-item',
                icon: <UploadFileIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/evolution-parents',
                label: 'Acesso dos Pais',
                type: 'nav-item',
                icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
            },
        ],
    },

    {
        label: 'Configuração',
        type: 'section',
        children: [
            {
                uri: '/filiais',
                label: 'Filiais',
                type: 'nav-item',
                icon: <CorporateFareIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/Especialidades',
                label: 'Especialidades',
                type: 'nav-item',
                icon: <MenuIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/alterar-senha',
                label: 'Alterar Senha',
                type: 'nav-item',
                icon: <LockResetIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/role',
                label: 'Perfis',
                type: 'nav-item',
                icon: <AccountBoxIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: '/users',
                label: 'Usuários',
                type: 'nav-item',
                icon: <GroupIcon sx={{ fontSize: 20 }} />,
            },
        ],
    },
];

export default menus;
