import Chip from '@mui/material/Chip';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import HistoryIcon from '@mui/icons-material/History';
import Badge from '@mui/material/Badge';

import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip from '@mui/material/Tooltip';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import WestIcon from '@mui/icons-material/West';
const BirthdayReport = () => {
    function createData(DataNascimento, Idade, Nome, Telefone, Endereço, UltimoAtendimento ) {
        return {DataNascimento, Idade, Nome, Telefone, Endereço, UltimoAtendimento };
    }
    const rows = [
        createData(
            '02/04/2016',
            '8 anos',
            '2862 - MELISSA YUMI SUEHIRO',
            'CEL. PESSOAL (18) 996922248',
            'RUA AVIADOR NORBERTO VALLIN, 440,JARDIM BONGIOVANI - PRESIDENTE PRUDENTE-SP - CEP 19050420',
            '03/01/2024',
          ),
          createData(
            '02/04/2016',
            '8 anos',
            '2862 - MELISSA YUMI SUEHIRO',
            'CEL. PESSOAL (18) 996922248',
            'RUA AVIADOR NORBERTO VALLIN, 440,JARDIM BONGIOVANI - PRESIDENTE PRUDENTE-SP - CEP 19050420',
            '03/01/2024',
          ),
          createData(
            '02/04/2016',
            '8 anos',
            '2862 - MELISSA YUMI SUEHIRO',
            'CEL. PESSOAL (18) 996922248',
            'RUA AVIADOR NORBERTO VALLIN, 440,JARDIM BONGIOVANI - PRESIDENTE PRUDENTE-SP - CEP 19050420',
            '03/01/2024',
          ),
      ];
    return(
        <div>
            <Paper style={{ padding: '10px', marginBottom: '15px', overflowX: 'auto', overflowY: 'auto' }} square={false} elevation={3}>
                
                <Grid container alignItems="center" direction="row" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} md={9}>
                        <Typography variant='h2' gutterBottom style={{ marginBottom: '15px' }} >
                            Relatório de Aniversariantes
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} container justifyContent="flex-end">
                        <Grid item>
                            <Button variant="outlined" style={{ minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px', marginLeft:'10px' }}>
                                <PictureAsPdfIcon />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" style={{ minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px', marginLeft:'10px' }}>
                                <FormatIndentIncreaseIcon />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" style={{ minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px', marginLeft:'10px' }}>
                                <LocalPrintshopIcon />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" style={{ minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px', marginLeft:'10px' }}>
                                <WestIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider  style={{ height: '100%', marginBottom:'20px', marginTop:'20px'}} />
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Data de Nascimento</TableCell>
                                <TableCell align="center">Idade</TableCell>
                                <TableCell align="center">Nome</TableCell>
                                <TableCell align="center">Telefone</TableCell>
                                <TableCell align="center">Endereço</TableCell>
                                <TableCell align="center">Ultimo Atendimento</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell align="center">{row.DataNascimento}</TableCell>
                                    <TableCell align="center">{row.Idade}</TableCell>
                                    <TableCell align="center">{row.Nome}</TableCell>
                                    <TableCell align="center">{row.Telefone}</TableCell>
                                    <TableCell align="center">{row.Endereço}</TableCell>
                                    <TableCell align="center">{row.UltimoAtendimento}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            </Paper>
        </div>
    );
}

export default BirthdayReport