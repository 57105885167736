import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { useContext, useEffect, useState } from "react";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PermissionContext } from "app/contexts/PermissionContext";
import ApiService from "app/services/config";
import { Form, Formik } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useSnackbar } from "notistack";

function createData(
  codigo,
  inclusao,
  colaborador_inclusao,
  alteracao,
  colaborador_alteracao
) {
  return {
    codigo,
    inclusao,
    colaborador_inclusao,
    alteracao,
    colaborador_alteracao,
  };
}
const rows = [
  createData(
    1,
    "01/10/2021 17:24:49",
    "ADRIANO SA",
    "24/10/2021 18:08:00",
    "ADRIANO SA"
  ),
];

const validationSchema = yup.object().shape({
  colaborador_inclusao_id: yup
    .number()
    .required("ID do Colaborador obrigatório"),
  titulo_orientacao: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
});

const Guidelines = () => {
  const navigate = useNavigate();
  const colaboradorId = Number(localStorage.getItem("id"));
  const [orientacoes, setOrientacoes] = useState([]);
  const [orientacaoEscolhida, setOrientacaoEscolhida] = useState([]);
  const { hasPermission } = useContext(PermissionContext);
  const { enqueueSnackbar } = useSnackbar();


  const Swal = useSwalWrapper();

  const handleEscolherOrientacao = (orientacao) => {
    setOrientacaoEscolhida(orientacao);
  };

  const modalAlert = (id, setFieldValue) => {
    Swal.fire({
      title: `Tem certeza que deseja excluir a orientação?`,
      text: `Você não poderá reverter a ação posteriormente!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteOrientacao(id, setFieldValue);
      }
    });
  };

  const handleDeleteOrientacao = async (id, setFieldValue) => {
    try {
      await ApiService.delete(`/observations/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar('Orientação excluída com sucesso!', {
            variant: 'success',
            
          });
          getOrientacoes();
          setOrientacaoEscolhida([]);
          setFieldValue("titulo_orientacao", "");
          setFieldValue("descricao", "");
        }
      });
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro!', {
        variant: 'error',
        
      });
      console.error(error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const id = values.id;
      delete values.id;
      delete values.colaborador_alteracao;
      delete values.colaborador_inclusao;
      delete values.createdAt;
      delete values.deletedAt;
      delete values.updatedAt;
      await ApiService.put(`/observations/${id}`, values).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar('Orientação atualizada com sucesso!', {
            variant: 'success',
            
          });
          getOrientacoes();
        }
      });
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro!', {
        variant: 'error',
        
      });
      console.error(error);
    }
  };

  const getOrientacoes = async () => {
    try {
      await ApiService.get("/observations").then((response) => {
        if (response.status === 200) {
          setOrientacoes(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrientacoes();
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs
          title={'Orientações'}
          titleUrl={'/Guidelines'}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        {/* <Typography variant="h1" gutterBottom style={{ marginBottom: "15px" }}>
          Orientações
        </Typography> */}
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Formik
          initialValues={orientacaoEscolhida}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            errors,
            isValid,
          }) => (
            <Form sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={orientacoes}
                    getOptionLabel={(option) => option.titulo_orientacao}
                    onChange={(event, option) => {
                      return option ? handleEscolherOrientacao(option) : null;
                    }}
                    name={values.titulo_orientacao}
                    renderInput={(params) => (
                      <TextField {...params} label="Titulo" />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={6} />
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Descrição"
                    name="descricao"
                    onChange={handleChange}
                    value={values.descricao}
                    multiline
                    maxRows={4}
                    disabled
                    InputProps={{ style: { minHeight: "200px" } }}
                  />
                </Grid>
              </Grid>
              {orientacaoEscolhida && (
                <input
                  hidden
                  type="number"
                  name="colaborador_inclusao_id"
                  value={orientacaoEscolhida.colaborador_inclusao_id}
                />
              )}
              {console.log(values)}
              <input
                hidden
                type="number"
                name="colaborador_alteracao_id"
                value={Number(colaboradorId)}
              />
              <Divider
                style={{
                  height: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Código</TableCell>
                          <TableCell align="center">Inclusão</TableCell>
                          <TableCell align="center">
                            Colaborador Inclusão
                          </TableCell>
                          <TableCell align="center">Alteração</TableCell>
                          <TableCell align="center">
                            Colaborador Alteração
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orientacaoEscolhida && (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              {orientacaoEscolhida.id}
                            </TableCell>
                            <TableCell align="center">
                              {orientacaoEscolhida.createdAt
                              ? moment(orientacaoEscolhida.createdAt).format("DD/MM/YYYY HH:mm:ss")
                              : ""}
                            </TableCell>
                            <TableCell align="center">
                              {orientacaoEscolhida.colaborador_inclusao?.nome}
                            </TableCell>
                            <TableCell align="center">
                              {orientacaoEscolhida.updatedAt 
                              ? moment(orientacaoEscolhida.updatedAt).format("DD/MM/YYYY HH:mm:ss")
                              : ""}
                            </TableCell>
                            <TableCell align="center">
                              {orientacaoEscolhida.colaborador_alteracao?.nome}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
              <Divider
                style={{
                  height: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />

              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    onClick={() => {
                      navigate("/newguidelines");
                    }}
                    disabled={!hasPermission('Orientações', 'create')}
                  >
                    Novo
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    onClick={() => {
                      navigate("/newguidelines", { state: { values } });
                    }}
                    disabled={hasPermission('Orientações', 'update') && orientacaoEscolhida.length === 0}
                  >
                    Editar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    onClick={() => {
                        modalAlert(orientacaoEscolhida.id, setFieldValue);
                    }}
                    disabled={!hasPermission('Orientações', 'delete')}
                  >
                    Excluir
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default Guidelines;
