import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import ApiService from 'app/services/config';
import { useLocation, useParams } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Autocomplete, Paper, Tooltip, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { redirectToEvolution } from '../search/ScheduleVertical';

const InterventionPlanForm = () => {
  const { userId, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const { state } = useLocation()
  const fromTab = state?.fromTab || 0;
  const storedUserId = localStorage.getItem('id');
  console.log(id);

  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [avaliations, setAvaliations] = useState([]);

  const [interventionPlan, setInterventionPlan] = useState({
    questionario_id: '',
    plano_de_intervencao_modelo: '',
    plano_de_intervencao_geral: '',
    plano_de_intervencao_especifico: '',
    data_de_inclusao: '',
  });

  const getUsers = useCallback(async () => {
    try {
      await ApiService.get('/users')
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setUsers]);

  const getInfosDoc = useCallback(async () => {
    try {
      await ApiService.get(`/intervention-plans/${id}`)
        .then((response) => {
          console.log(response.data);

          setInterventionPlan(response.data.intervention);
          setUserSelected({ select: response?.data?.intervention.Prestador });
          setAvaliations(response.data.avaliationsAnswered);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setInterventionPlan, id]);

  const getInfosIntevention = useCallback(async () => {
    try {
      await ApiService.get(`/intervention-plans/avaliations/${userId}`)
        .then((response) => {
          console.log(response.data);

          /*  setInterventionPlan(response.data.intervention);
          setUserSelected({ select: response?.data?.intervention.Prestador }); */
          setAvaliations(response.data.avaliationsAnswered);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [userId]);

  useEffect(() => {
    if (id) {
      getInfosDoc();
    }
    getUsers();
    getInfosIntevention();
  }, [getUsers, getInfosIntevention, getInfosDoc, id]);

  const handleEvaluationChange = (event) => {
    const selectedEvaluationId = event.target.value;
    const selectedEvaluation = avaliations.find(
      (avaliation) => avaliation.id === selectedEvaluationId
    );

    console.log('aqui', selectedEvaluation);
    let modeloPlano = '';
    if (selectedEvaluation) {
      const evaluationName = selectedEvaluation.nome;
      if (evaluationName.includes('Nível 1')) {
        modeloPlano = 'nivel_1';
      } else if (evaluationName.includes('Nível 2')) {
        modeloPlano = 'nivel_2';
      } else if (evaluationName.includes('Nível 3')) {
        modeloPlano = 'nivel_3';
      } else if (evaluationName.includes('Nível 4')) {
        modeloPlano = 'nivel_4';
      }
      // Adicione mais condições conforme necessário
    }

    setInterventionPlan({
      ...interventionPlan,
      questionario_id: selectedEvaluationId,
      plano_de_intervencao_modelo: modeloPlano,
    });
  };

  const interventionPlanLabels = {
    nivel_1: 'Nível 1',
    nivel_2: 'Nível 2',
    nivel_3: 'Nível 3',
    nivel_4: 'Nível 4',
  };

  return (
    <>
      <HeaderBreadcrumbs
        title={'Paciente'}
        subtitle={
          id ? 'Editar Plano de Intervenção' : 'Adicionar Plano de Intervenção'
        }
        titleUrl={`/evolution/user/${userId}/edit`}
      />
      <Button
        sx={{ width: '80px', marginBottom: '1rem' }}
        variant='contained'
        color='success'
        onClick={() => redirectToEvolution(userId, navigate, fromTab)}
      >
        Voltar
      </Button>
      <Paper style={{ padding: '30px' }} square={false} elevation={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} align='center'>
            <Button
              variant='outlined'
              style={{ marginRight: '10px' }}
              onClick={() => {
                if (id) {
                  const docId = interventionPlan.id;
                  const userInterventionEdit = {
                    ...interventionPlan,
                    Prestador: undefined,
                    Questionario: undefined,
                    prestador_id: userSelected?.select?.id,
                    colaborador_id: Number(storedUserId),
                  };
                  ApiService.put(
                    `/intervention-plans/${docId}`,
                    userInterventionEdit
                  )
                    .then((response) => {
                      console.log(response.data);
                      enqueueSnackbar('Arquivo atualizado com sucesso!', {
                        variant: 'success',
                      });
                      navigate(`/evolution/user/${userId}/edit`);
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: 'error',
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: 'error',
                        });
                      }
                    });
                } else {
                  const userIntervention = {
                    user_id: Number(userId),
                    prestador_id: userSelected?.select?.id,
                    colaborador_id: Number(storedUserId),
                    plano_de_intervencao_modelo:
                      interventionPlan?.plano_de_intervencao_modelo,
                    plano_de_intervencao_geral:
                      interventionPlan?.plano_de_intervencao_geral,
                    plano_de_intervencao_especifico:
                      interventionPlan?.plano_de_intervencao_especifico,
                    questionario_id: interventionPlan.questionario_id,
                    data_de_inclusao: interventionPlan?.data_de_inclusao,
                  };
                  console.log(userIntervention);

                  ApiService.post(`/intervention-plans`, userIntervention)
                    .then((response) => {
                      console.log(response.data);
                      enqueueSnackbar('Arquivo criado com sucesso!', {
                        variant: 'success',
                      });

                      setInterventionPlan({
                        plano_de_intervencao_modelo: '',
                        plano_de_intervencao: '',
                        data_de_inclusao: '',
                      });

                      navigate(`/evolution/user/${userId}/edit`);
                    })
                    .catch((error) => {
                      const errorMessage = error.response?.data?.message;
                      console.log(error);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: 'error',
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: 'error',
                        });
                      }
                    });
                }
              }}
            >
              Salvar
            </Button>
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id='standard-basic'
              label='Data'
              variant='outlined'
              type='date'
              InputLabelProps={{ shrink: true }}
              value={interventionPlan?.data_de_inclusao}
              onChange={(event) => {
                setInterventionPlan({
                  ...interventionPlan,
                  data_de_inclusao: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              title='Digite o nome para buscar a pessoa no sistema'
              TransitionComponent={Zoom}
              placement='top-end'
            >
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option?.nome}
                options={users}
                value={userSelected?.select ?? null}
                onChange={(event, newValue) => {
                  setUserSelected((prevValues) => ({
                    ...prevValues,
                    select: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Prestador'
                    placeholder='Prestador'
                    fullWidth
                  />
                )}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <FormControl sx={{ minWidth: 240 }}>
              <InputLabel id='demo-controlled-open-select-label'>
                Selecione a avaliação
              </InputLabel>
              <Select
                value={interventionPlan?.questionario_id}
                label=' Selecione a avaliação'
                onChange={handleEvaluationChange}
              >
                <MenuItem value=''>
                  <em>Vazio</em>
                </MenuItem>
                {avaliations?.map((item) => (
                  <MenuItem value={item?.id}>{item?.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl sx={{ minWidth: 240 }}>
              <InputLabel id='demo-controlled-open-select-label'>
                Modelo de Plano de Intervenção
              </InputLabel>
              <Select
                disabled
                value={interventionPlan?.plano_de_intervencao_modelo}
                label='Modelo de Plano de Intervenção'
                onChange={(event) => {
                  setInterventionPlan({
                    ...interventionPlan,
                    plano_de_intervencao_modelo: event.target.value,
                  });
                }}
              >
                <MenuItem value={interventionPlan?.plano_de_intervencao_modelo}>
                  {
                    interventionPlanLabels[
                      interventionPlan?.plano_de_intervencao_modelo
                    ]
                  }
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Plano de Intervenção Geral'
              multiline
              rows={4}
              value={interventionPlan?.plano_de_intervencao_geral}
              onChange={(event) => {
                setInterventionPlan({
                  ...interventionPlan,
                  plano_de_intervencao_geral: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Plano de Intervenção Específico'
              multiline
              rows={4}
              value={interventionPlan?.plano_de_intervencao_especifico}
              onChange={(event) => {
                setInterventionPlan({
                  ...interventionPlan,
                  plano_de_intervencao_especifico: event.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default InterventionPlanForm;
