import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Zoom,
} from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApiService from 'app/services/config';
import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { formatDateWithMoment } from '@jumbo/utils';

export const SupervisionTherapists = ({ tabIndex }) => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const { userId } = useParams();

  const [quizzes, setQuizzes] = useState([]);
  const [userQuizz, setUserQuizz] = useState([]);
  const [quizSelected, setQuizSelected] = useState({
    supervisao: '',
  });

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(
        `/quizzes?userId=${userId}&type=supervisao`
      )
        .then((response) => {
          console.log(response.data);

          setQuizzes(response?.data?.quizzes);
          setUserQuizz(response?.data?.respostasAgrupadas);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, userId]);

  useEffect(() => {
    getQuizzes();
  }, [getQuizzes]);

  console.log(userQuizz);
  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'respostas',
      label: 'Ficha',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          return value[0]?.Pergunta?.Questionario?.nome;
        },
      },
    },
    {
      name: 'horario',
      label: 'Data e horário',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          return formatDateWithMoment(value);
        },
      },
    },

    {
      name: 'action',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quizId = tableMeta.rowData[1][0]?.Pergunta?.Questionario?.id;
          const queryData = data[tableMeta.rowIndex];
          console.log(tableMeta.rowIndex);
          return (
            <Box display={'flex'} gap={'1rem'}>
              <Tooltip title='Visualizar' TransitionComponent={Zoom}>
                <Link
                  to={{
                    pathname: `/sheet/${quizId}/user/${userId}/edit`,
                    search: `?date=${queryData?.horario}`,
                  }}
                  state={{ fromTab: tabIndex }}
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton aria-label='visualizar' color='info'>
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    /* changeRowsPerPage: perPage, */
    filterType: 'dropdown',
    filter: false,
    selectableRows: 'none',
    searchAlwaysOpen: true,
    searchable: true,
    /* serverSide: true,
    page: page,
    count: count, */
    selectToolbarPlacement: 'above',

    /* onTableChange: (action, tableState) => {
        console.log(action);
        switch (action) {
            case 'changePage':
                setPage(tableState.page);
                break;
            case 'sort':
                setSortBy(tableState.sortOrder.name);
                setSortOrder(tableState.sortOrder.direction);
                break;
            case 'search':
                handleSearch(tableState.searchText);
                break;
            case 'changeRowsPerPage':
                setPerPage(tableState.rowsPerPage);
                break;
            default:
                console.log('action not handled.');
        }
    }, */
    textLabels: {
      body: {
        noMatch: 'Nenhum resultado encontrado',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'of',
      },
    },
  };

  const data = Object.entries(userQuizz).map(([horario, respostas]) => ({
    horario,
    respostas,
  }));

  console.log(data);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider>Nova Ficha de Supervisão do Terapeuta</Divider>
        </Grid>
        <Grid item xs={4} container>
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='demo-simple-select-label'>Supervisões</InputLabel>
            <Select
              label='Supervisões'
              onChange={(e) =>
                setQuizSelected({
                  ...quizSelected,
                  supervisao: e.target.value,
                })
              }
            >
              {quizzes.map((quiz) => (
                <MenuItem value={quiz?.id}>{quiz?.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
            <Button
              variant='outlined'
              style={{ marginLeft: '5px' }}
              disabled={!quizSelected.supervisao}
              onClick={() => {
                navigate(`/sheet/${quizSelected.supervisao}/user/${userId}`, {
                  state: { fromTab: tabIndex },
                });
              }}
            >
              <QuizIcon />
            </Button>
          </Tooltip>

          {/* <Tooltip title='Visualizar gráfico' TransitionComponent={Zoom}>
            <Button
              variant='outlined'
              style={{ marginLeft: '5px' }}
              disabled={!quizSelected.checklist}
              onClick={() => {
                navigate(
                  `/quizzes/dashboard/${quizSelected.checklist}/user/${userId}`
                );
              }}
            >
              <AnalyticsIcon />
            </Button>
          </Tooltip> */}
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Visualizacao das fichas'}
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};
