import React, { useCallback, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { updateInputValue } from 'app/utils/appHelpers';
import DataTable from '../DataTable';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useParams } from 'react-router-dom';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { FieldArray } from 'formik';
import { RegistrationBoardItem } from 'app/components/RegistrationBoardItem/RegistrationBoardItem';
import { FormHelperText } from '@mui/material';

const Registros = ({
    infos,
    values,
    setFieldValue,
    registrationBoard,
    setRegistrationBoard,
    handleChange,
}) => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const registerBoardSchema = Yup.object().shape({
        conselho_regional: Yup.string().required(
            'Conselho Regional é obrigatório.'
        ),
        registro: Yup.string().required('Registro é obrigatório.'),
        uf: Yup.string().required('Estado é obrigatório.'),
    });

    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});
    const Swal = useSwalWrapper();

    const handleEditRegistrationBoard = (addressToEdit) => {
        console.log(addressToEdit);
        setRegistrationBoard(addressToEdit);
        setIsEditMode(true);
    };

    const modalAlert = (addressId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                console.log('bem bem antes', values.Registros);
                removeRegistrationBoard(addressId);
            }
        });
    };

    const removeRegistrationBoard = useCallback(
        async (registrationBoardId) => {
            ApiService.delete(`/registration-board/${registrationBoardId}`)
                .then((response) => {
                    const registerEdit =
                        registrationBoard.id === registrationBoardId;
                    const removedAddress = values.Registros.filter(
                        (register) => register.id !== registrationBoardId
                    );
                    setFieldValue('Registros', removedAddress);
                    enqueueSnackbar('Registro removido com sucesso!', {
                        variant: 'success',
                        
                    });
                    if (registerEdit) {
                        setIsEditMode(false);
                        setRegistrationBoard({
                            conselho_regional: '',
                            registro: '',
                            uf: '',
                            modificado: '',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [enqueueSnackbar, setFieldValue, values.Registros]
    );

    // Verifica se todos os campos do registro estão vazios
    const allFieldsEmpty = Object.values(registrationBoard).every(
        (value) => !value
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '15px' }}
                >
                    Registro em Conselho
                </Typography>
            </Grid>

            <Grid xs={4}>
                <FormControl
                    fullWidth
                    variant='outlined'
                    error={!!errors.conselho_regional}
                >
                    <InputLabel id='demo-simple-select-label'>
                        Conselho Regional
                    </InputLabel>
                    <Select
                        name='Registros.conselho_regional'
                        label='Conselho Regional'
                        value={registrationBoard?.conselho_regional}
                        onChange={(event) => {
                            setRegistrationBoard({
                                ...registrationBoard,
                                conselho_regional: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={'conselho1'}>conselho1</MenuItem>
                        <MenuItem value={'conselho2'}>conselho2</MenuItem>
                        <MenuItem value={'conselho3'}>conselho3</MenuItem>
                    </Select>
                    {errors.conselho_regional && (
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors.conselho_regional}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid xs={3}>
                <TextField
                    fullWidth
                    name='Registros.registro'
                    label='Registro'
                    value={registrationBoard?.registro}
                    onChange={(event) => {
                        setRegistrationBoard({
                            ...registrationBoard,
                            registro: event.target.value,
                        });
                    }}
                    error={!!errors.registro} // Verifica se há erro no campo 'registro'
                    helperText={errors.registro}
                />
            </Grid>
            <Grid sm={2}>
                <FormControl fullWidth variant='outlined' error={!!errors.uf}>
                    <InputLabel id='demo-simple-select-label'>
                        Estado
                    </InputLabel>
                    <Select
                        name='Registros.uf'
                        label='Estado'
                        value={registrationBoard.uf}
                        onChange={(event) => {
                            setRegistrationBoard({
                                ...registrationBoard,
                                uf: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={'AC'}>Acre</MenuItem>
                        <MenuItem value={'AL'}>Alagoas</MenuItem>
                        <MenuItem value={'AP'}>Amapá</MenuItem>
                        <MenuItem value={'AM'}>Amazonas</MenuItem>
                        <MenuItem value={'BA'}>Bahia</MenuItem>
                        <MenuItem value={'CE'}>Ceará</MenuItem>
                        <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                        <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                        <MenuItem value={'GO'}>Goiás</MenuItem>
                        <MenuItem value={'MA'}>Maranhão</MenuItem>
                        <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                        <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                        <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                        <MenuItem value={'PA'}>Pará</MenuItem>
                        <MenuItem value={'PB'}>Paraíba</MenuItem>
                        <MenuItem value={'PR'}>Paraná</MenuItem>
                        <MenuItem value={'PE'}>Pernambuco</MenuItem>
                        <MenuItem value={'PI'}>Piauí</MenuItem>
                        <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                        <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                        <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                        <MenuItem value={'RO'}>Rondônia</MenuItem>
                        <MenuItem value={'RR'}>Roraima</MenuItem>
                        <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                        <MenuItem value={'SP'}>São Paulo</MenuItem>
                        <MenuItem value={'SE'}>Sergipe</MenuItem>
                        <MenuItem value={'TO'}>Tocantins</MenuItem>
                    </Select>
                    {errors.uf && (
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors.uf}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid sm={3}>
                {id ? (
                    <Grid xs={6}>
                        <Button
                            variant='contained'
                            style={{ marginTop: '10px' }}
                            disabled={allFieldsEmpty}
                            onClick={() => {
                                registerBoardSchema
                                    .validate(registrationBoard, {
                                        abortEarly: false,
                                    })
                                    .then(() => {
                                        setErrors({});
                                        if (isEditMode) {
                                            console.log(registrationBoard);
                                            /* if (registrationBoard?.User)
                                                delete registrationBoard.User; */
                                            const registrationBoardId =
                                                registrationBoard.id;

                                            ApiService.put(
                                                `/registration-board/${registrationBoardId}`,
                                                registrationBoard
                                            )
                                                .then((response) => {
                                                    console.log(response.data);
                                                    enqueueSnackbar(
                                                        'Registro atualizado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    const updatedTodos =
                                                        values.Registros.map(
                                                            (todo) => {
                                                                if (
                                                                    todo.id ===
                                                                    response
                                                                        .data.id
                                                                ) {
                                                                    return response.data; // Retorna o registro atualizado
                                                                }
                                                                return todo;
                                                            }
                                                        );
                                                    setFieldValue(
                                                        'Registros',
                                                        updatedTodos
                                                    ); // Atualiza a lista de registros com o registro atualizado
                                                    setIsEditMode(false); // Sai do modo de edição
                                                    setRegistrationBoard({
                                                        conselho_regional: '',
                                                        registro: '',
                                                        uf: '',
                                                        modificado: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        } else {
                                            const userRegister = {
                                                user_id: Number(id),
                                                conselho_regional:
                                                    registrationBoard.conselho_regional,
                                                registro:
                                                    registrationBoard.registro,
                                                uf: registrationBoard.uf,
                                                modificado:
                                                    registrationBoard.modificado,
                                            };

                                            ApiService.post(
                                                `/registration-board`,
                                                userRegister
                                            )
                                                .then((response) => {
                                                    const newRegister = [
                                                        ...values.Registros,
                                                        response.data,
                                                    ];
                                                    enqueueSnackbar(
                                                        'Registro criado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    setFieldValue(
                                                        'Registros',
                                                        newRegister
                                                    );
                                                    setRegistrationBoard({
                                                        conselho_regional: '',
                                                        registro: '',
                                                        uf: '',
                                                        modificado: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        }
                                    })
                                    .catch((validationErrors) => {
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                enqueueSnackbar(error.message, {
                                                    variant: 'error',
                                                    
                                                });
                                            }
                                        );
                                        const errors = {};
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                errors[error.path] =
                                                    error.message;
                                            }
                                        );
                                        console.log(errors);
                                        setErrors(errors);
                                    });
                            }}
                        >
                            {isEditMode
                                ? 'Editar Registro'
                                : 'Incluir Registro'}
                        </Button>
                    </Grid>
                ) : undefined}
            </Grid>
            {id ? (
                <Grid item xs={12}>
                    <FieldArray name='Registros'>
                        {({ remove, push }) =>
                            values?.Registros?.map((reg) => (
                                <RegistrationBoardItem
                                    registration={reg}
                                    handleEditRegistrationBoard={
                                        handleEditRegistrationBoard
                                    }
                                    modalAlert={modalAlert}
                                />
                            ))
                        }
                    </FieldArray>
                </Grid>
            ) : undefined}
        </Grid>
    );
};

export default Registros;
