import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ApiService from 'app/services/config';
import { FilesItem } from 'app/components/FilesItem/FilesItem';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import FileListSlimTwo from 'app/components/FileUploadSlimTwo/FileListSlimTwo';
import MUIDataTable from 'mui-datatables';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { getCustomDateTimeUtc } from '@jumbo/utils';

const GeneralArchivesSend = () => {
  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();
  const { userId } = useParams();

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [pacients, setPacients] = useState([]);
  const [workerSelected, setWorkerSelected] = useState(null);
  const [pacientSelected, setPacientSelected] = useState(null);
  const [workerInput, setWorkerInput] = useState('');
  const [pacientInput, setPacientInput] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [filesInfo, setFilesInfo] = useState({
    nome: '',
    tipo_de_arquivo: '',
  });

  const [filesData, setFilesData] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users?searchTerm=${inputValue}`);
      const workers = response.data?.allProviders || [];
      /* const pacients = response.data?.allPacients || []; */

      setWorkers(workers);
      /* setPacients(pacients); */
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const getFiles = useCallback(async () => {
    try {
      await ApiService.get(/* '/files' */ `users/${userId}`)
        .then((response) => {
          console.log(response.data);

          setFilesData(response.data?.Arquivo);
          setPacients(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setFilesData, userId]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const handlePacientInputChange = (event, newInputValue) => {
    setPacientInput(newInputValue);
    if (newInputValue !== '') {
      fetchUsers(newInputValue);
    } else {
      /*  setPacients([]); */
      setPacientSelected(null);
    }
  };

  const handleWorkerInputChange = (event, newInputValue) => {
    setWorkerInput(newInputValue);
    if (newInputValue !== '') {
      fetchUsers(newInputValue);
    } else {
      setWorkers([]);
      setWorkerSelected(null);
    }
  };

  console.log('o paciente seleciondo', pacientSelected);
  console.log('o prestador selecionado', workerSelected);

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFilesSelected([...filesSelected, file]); // Mantém filesSelected em sincronia com files
    setPreviewImage([...previewImage, URL.createObjectURL(file)]);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFilesInfo([]);
    setPreviewImage([]);
    setFilesSelected([]);
    setFiles([]);
    setWorkerSelected(null);
    setPacientSelected(null);
    setOpen(false);
    setIsEditMode(false);
  };

  const removeFile = (index) => {
    const updatedFiles = filesSelected.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setFilesSelected(updatedFiles); // Mantém filesSelected em sincronia com files
    const updatedPreviewImage = [...previewImage];
    updatedPreviewImage.splice(index, 1); // Remove a imagem correspondente
    setPreviewImage(updatedPreviewImage);
  };

  const handleDownload = async (docName) => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_KEY}/public/uploads/arquivos/${docName}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', docName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Erro ao fazer download:', error);
    }
  };

  const modalAlert = (docsId) => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar?',
      text: 'Não será póssível reverter a ação!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeDoc(docsId);
      }
    });
  };

  const removeDoc = useCallback(
    async (docsId) => {
      ApiService.delete(`/files/${docsId}`)
        .then((response) => {
          enqueueSnackbar('Documento removido com sucesso!', {
            variant: 'success',
          });
          getFiles();
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: 'error',
            });
          }
        });
    },
    [enqueueSnackbar]
  );

  const handleEditFile = (fileToEdit) => {
    console.log(fileToEdit);
    setFilesInfo(fileToEdit);
    setPacientSelected({ selected: fileToEdit?.User });
    setWorkerSelected({ selected: fileToEdit?.Prestador });
    setFiles([fileToEdit]);
    setIsEditMode(true);
    setOpen(true);
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'User.nome',
      label: 'Paciente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Prestador.nome',
      label: 'Prestador',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'tipo_de_arquivo',
      label: 'Finalidade do arquivo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'nome',
      label: 'Info Complementar',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value ? (
              <Tooltip title='Visualizar arquivo' TransitionComponent={Zoom}>
                <IconButton
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_API_KEY}/public/uploads/arquivos/${value}`,
                      '_blank'
                    )
                  }
                >
                  <InsertDriveFileIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Typography mt={1}> Não possui </Typography>
            )}
          </>
        ),
      },
    },
    {
      name: 'createdAt',
      label: 'Data de Inserção',
      options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => (
              <>
                  <Typography>
                     { getCustomDateTimeUtc(value) }
                  </Typography>
              </>
          )
      },
    },
    {
      name: 'action',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const fileId = tableMeta.rowData[0];
          const docName = tableMeta.rowData[4];
          console.log(tableMeta.rowIndex);
          return (
            <Box display={'flex'} gap={1}>
              <Tooltip title='Download' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='download'
                  color='success'
                  download={docName}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => handleDownload(docName)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Editar' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='editar'
                  color='info'
                  onClick={() => handleEditFile(filesData[tableMeta?.rowIndex])}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title='Remover' TransitionComponent={Zoom}>
                <IconButton
                  aria-label='remover'
                  color='error'
                  onClick={() => modalAlert(fileId)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    searchAlwaysOpen: true,
    searchable: true,
    enableNestedDataAccess: '.',
    textLabels: {
      body: {
        noMatch: 'Nenhum resultado encontrado',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'de',
      },
    },
  };
  return (
    <div>
      <Grid container rowSpacing={2}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          margin={3}
          width={'100%'}
        >
          <Button variant='outlined' onClick={handleOpen}>
            Enviar Arquivos
          </Button>
        </Box>

        <Grid item xs={12}>
          <MUIDataTable
            title={'Arquivos'}
            data={filesData}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: 300, sm: 1000 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant='h1' gutterBottom>
            Envio de Arquivos
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField fullWidth value={pacients.nome} disabled />
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                title='Digite o nome para buscar a pessoa no sistema'
                TransitionComponent={Zoom}
                placement='top-end'
              >
                <Autocomplete
                  fullWidth
                  disablePortal
                  getOptionLabel={(option) => option.nome}
                  options={workers}
                  loading={loading}
                  inputValue={workerInput}
                  onInputChange={handleWorkerInputChange}
                  value={workerSelected?.selected ?? null}
                  onChange={(event, newValue) => {
                    setWorkerSelected((prevValues) => ({
                      selected: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Prestadores'
                      placeholder='Prestadores'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? 'carregando...' : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id='tipo_de_arquivo'>Tipo de arquivo</InputLabel>
                <Select
                  labelId='tipo_de_arquivo'
                  id='tipo_de_arquivo'
                  label='Tipo de Arquivo'
                  value={filesInfo?.tipo_de_arquivo}
                  onChange={(event) => {
                    setFilesInfo({
                      ...filesInfo,
                      tipo_de_arquivo: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={'atestado'}>Atestado</MenuItem>
                  <MenuItem value={'cirurgia'}>Cirurgia</MenuItem>
                  <MenuItem value={'contrato'}>Contrato</MenuItem>
                  <MenuItem value={'exame'}>Exame</MenuItem>
                  <MenuItem value={'outros'}>Outros</MenuItem>
                  <MenuItem value={'prontuario'}>Prontuário</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={3}
              >
                {!isEditMode && (
                  <Button
                    variant='contained'
                    component='label'
                    color='info'
                    sx={{
                      textAlign: 'center',
                      width: '15%',
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                    <input
                      type='file'
                      hidden
                      name='docs'
                      onChange={uploadHandler}
                    />
                  </Button>
                )}

                <Button
                  variant='outlined'
                  endIcon={<SendIcon />}
                  onClick={() => {
                    if (isEditMode) {
                      const docId = filesInfo.id;
                      const userFilesEdit = {
                        ...filesInfo,
                        Prestador: undefined,
                        User: undefined,
                        user_id: pacientSelected?.selected?.id,
                        prestador_id: workerSelected?.selected?.id,
                      };
                      ApiService.put(`/files/${docId}`, userFilesEdit)
                        .then((response) => {
                          console.log(response.data);
                          enqueueSnackbar('Arquivo atualizado com sucesso!', {
                            variant: 'success',
                          });

                          setFilesInfo([]);
                          setPreviewImage([]);
                          setFilesSelected([]);
                          setFiles([]);
                          setOpen(false);
                          getFiles();
                        })
                        .catch((error) => {
                          const errorMessage = error.response.data.message;
                          console.log(errorMessage);
                          if (Array.isArray(errorMessage)) {
                            enqueueSnackbar(`${errorMessage[0]}`, {
                              variant: 'error',
                            });
                          } else {
                            enqueueSnackbar(`${errorMessage}`, {
                              variant: 'error',
                            });
                          }
                        });
                    } else {
                      const userDoc = {
                        user_id: userId,
                        prestador_id: workerSelected?.selected?.id,
                        nome: filesInfo.nome,
                        tipo_de_arquivo: filesInfo.tipo_de_arquivo,
                      };
                      console.log(userDoc);

                      const formData = new FormData();
                      formData.append('user_id', userDoc.user_id);
                      formData.append('prestador_id', userDoc.prestador_id);

                      formData.append('nome', userDoc.nome);

                      formData.append(
                        'tipo_de_arquivo',
                        userDoc.tipo_de_arquivo
                      );

                      for (let i = 0; i < filesSelected.length; i++) {
                        formData.append('files', filesSelected[i]);
                      }

                      ApiService.post(`/files`, formData)
                        .then((response) => {
                          console.log(response.data);

                          enqueueSnackbar('Arquivo criado com sucesso!', {
                            variant: 'success',
                          });

                          setFilesInfo({
                            nome: '',
                            tipo_de_arquivo: '',
                          });
                          setPreviewImage([]);
                          setFilesSelected([]);
                          setWorkerSelected(null);
                          setPacientSelected(null);
                          setOpen(false);
                          getFiles();
                        })
                        .catch((error) => {
                          const errorMessage = error.response?.data?.message;
                          console.log(error);
                          if (Array.isArray(errorMessage)) {
                            enqueueSnackbar(`${errorMessage[0]}`, {
                              variant: 'error',
                            });
                          } else {
                            enqueueSnackbar(`${errorMessage}`, {
                              variant: 'error',
                            });
                          }
                        });
                    }
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FileListSlimTwo
                files={files?.Arquivo}
                filesSelected={filesSelected}
                removeFile={removeFile}
                previewImage={previewImage}
                isEditMode={isEditMode}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default GeneralArchivesSend;
