import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, FormControl, Grid, Tooltip, Zoom } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ApiService from "app/services/config";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import DataTable from "../DataTable";
import { PermissionContext } from "app/contexts/PermissionContext";

// const validationSchema = yup.object().shape({
//   colaborador_inclusao_id: yup
//     .number()
//     .required("ID do Colaborador obrigatório"),
//   nome_turma: yup.string().required("Campo obrigatório"),
//   descricao: yup.string().required("Campo obrigatório"),
//   alunosIds: yup
//     .array()
//     .of(yup.number())
//     .required("Escolha no mínimo 1 aluno")
//     .min(1, "Escolha no mínimo 1 aluno"),
// });

const NewUserPool = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const colaboradorId = Number(localStorage.getItem("id"));
  const initialValues = {
    user_id: null,
    createdAt: null,
    updatedAt: null,
    colaborador_inclusao_id: colaboradorId
  };
  const [alunos, setAlunos] = useState([]);
  const [alunoEscolhido, setAlunoEscolhido] = useState([]);
  const [patient, setPatient] = useState(initialValues);
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userSelected, setUserSelected] = useState(null);
  const [classes, setClasses] = useState([]);

  
  const { hasPermission } = useContext(PermissionContext);

  const modalAlert = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja remover da turma?`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleRemoveStudentFromClass(id);
      }
    });
  };

  const handleRemoveStudentFromClass = async (id) => {
    // try {
    //   await ApiService.delete(`/class/${id}`).then((response) => {
    //     if (response.status === 200) {
    //       enqueueSnackbar("Turma excluída com sucesso!", {
    //         variant: "success",
            
    //       });
    //       // getClasses();
    //     }
    //   });
    // } catch (error) {
    //   enqueueSnackbar("Ocorreu um erro!", {
    //     variant: "error",
        
    //   });
    //   console.log(error);
    // }
  };


  console.log('classes', classes)
  console.log('patient', patient)

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "nome_turma",
      label: "Nome",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "acao",
      label: "Ação",
      options: {
        customBodyRender: (value, tableMeta) => {
          const turmaId = tableMeta.rowData[0];
          return (
            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(`/team/${turmaId}`);
                }}
                disabled={!hasPermission('Turma', 'update')}
              >
                <VisibilityIcon sx={{ cursor: "pointer" }} />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users/all/not-in-the-pool/patients?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleSubmit = async (values) => {
    delete values.createdAt;
    delete values.updatedAt;

    try {
      await ApiService.post("/usuario-externo", values).then((response) => {
        if (response.status === 201) {
          enqueueSnackbar("Usuário incluído!", {
            variant: "success",
            
          });
          navigate("/user-pool");
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const Swal = useSwalWrapper();


  const getPatients = async () => {
    try {
      await ApiService.get("/users/all/patients").then((response) => {
        if (response.status === 200) {
          setAlunos(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async (id) => {
    try {
      await ApiService.get(`/usuario-externo/classes/${id}`).then((response) => {
        if (response.status === 200) {
          setPatient(response.data)
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getClasses = useCallback(async () => {
    try {
      let apiUrl = `/class/1`;

      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response.data);

          setClasses(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);
  

  useEffect(() => {
    getPatients();
    if (id) {
      getUser(id);
    }
  }, []);

  useEffect(() => {
    if(patient.id) {
      getClasses()
    }
  }, [patient])

  return (
    <div>
      <HeaderBreadcrumbs
          title={'Usuário externo'}
          subtitle={id ? "Atualizar acesso" : "Criar acesso"}
          titleUrl={'/user-pool'}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          // overflowX: "auto",
          // overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Formik
          initialValues={patient}
          // validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            errors,
            isValid,
          }) => (
            <Form sx={{ width: "100%" }}>
              <>
                {/* <Typography
                  variant="h1"
                  gutterBottom
                  style={{ marginBottom: "15px" }}
                >
                  {id ? "Atualizar Turma" : "Criar Turma"}
                </Typography> */}
                <Divider
                  style={{
                    height: "100%",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      {id 
                      ? <Typography variant={"h3"} color={"text.secondary"} sx={{ textAlign: 'center' }}>{patient?.User?.nome}</Typography>
                      : (
                        <>
                          <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                            <Autocomplete
                              fullWidth
                              disablePortal
                              getOptionLabel={(option) => option.nome}
                              options={optionsList}
                              loading={loading}
                              name={"user_id"}
                              inputValue={userInput}
                              onInputChange={handleInputChange}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setFieldValue("user_id", newValue.id);
                                }
                              }}
                              noOptionsText={"Nenhum resultado encontrado"}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Paciente"
                                  placeholder="Paciente"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? "carregando..." : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option?.id === value?.id
                              }
                            />
                          </Tooltip>
                          <ErrorMessage
                            name={"user_id"}
                            component={"div"}
                            style={{ color: "red" }}
                            />
                        </>
                      )}
                      </FormControl>
                    </Grid>
                  {!id && (
                    <Grid item xs={12}>
                      <Box display={"flex"} justifyContent={"center"} gap={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleSubmit(values);
                          }}
                        >
                          Incluir
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  {id && (
                    <>
                      <Divider
                        style={{
                          height: "100%",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      />

                      <Grid item xs={12}>
                        <DataTable title={"Turmas"} columns={columns} data={[classes]} />
                      </Grid>

                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "10px",
                            boxShadow: "none",
                          }}
                        >
                          <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Código</TableCell>
                                <TableCell align="center">Inclusão</TableCell>
                                <TableCell align="center">
                                  Colaborador Inclusão
                                </TableCell>
                                <TableCell align="center">Alteração</TableCell>
                                <TableCell align="center">
                                  Colaborador Alteração
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center">
                                  {id ? id : "---"}
                                </TableCell>
                                <TableCell align="center">
                                  {id && patient.createdAt
                                    ? moment(patient.createdAt).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )
                                    : "---"}
                                </TableCell>
                                <TableCell align="center">
                                  {id ? patient.Colaborador_Inclusao?.nome : "---"}
                                </TableCell>
                                <TableCell align="center">
                                  {id && patient.updatedAt && patient.Colaborador_Alteracao?.nome
                                    ? moment(patient.updatedAt).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )
                                    : "---"}
                                </TableCell>
                                <TableCell align="center">
                                  {id ? patient.Colaborador_Alteracao?.nome : "---"}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>
                    </>
                  )}
                  
                </Grid>
              </>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default NewUserPool;
