import {
  Autocomplete,
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useSnackbar } from "notistack";
import ApiService from "app/services/config";

const AnamnesisMenu = ({
  anamnesisSelected,
  setAnamnesisSelected,
  idQuestionario,
  userId,
  setIsEditMode,
  handleGetAnamnesis,
  anamnesis,
  setAnamnesis,
  answers,
  setAnswers,
  isFormSubmitted,
  setIsFormSubmitted,
  date
}) => {
  const Swal = useSwalWrapper();
  const { enqueueSnackbar } = useSnackbar();

  const modalAlert = () => {
    Swal.fire({
      title: `Tem certeza que deseja excluir as respostas?`,
      text: `Você perderá os dados das respostas registradas!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteAnamnese();
      }
    });
  };

  const handleDeleteAnamnese = async () => {
    try {
      await ApiService.delete(
        `/user-answered/anamnese/answers/${idQuestionario}/${userId}/${anamnesisSelected.data}`
      ).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Respostas excluídas!", {
            variant: "success",
          });
          setAnamnesisSelected(null);
          setAnswers(new Map());
          setIsEditMode(false);
          setIsFormSubmitted(!isFormSubmitted);
          handleGetAnamnesis();
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Ocorreu um erro ao excluir as respostas!", {
        variant: "error",
      });
    }
  };

  const handleEditAnamnesis = async (data = anamnesisSelected?.data) => {
    setIsEditMode(true);
    await ApiService.get(
      `/user-answered/anamnese/user/answers/${userId}/${idQuestionario}/${data}`
    )
      .then((response) => {
        if (response.status === 200) {
          const newAnswers = new Map(answers); 
    
          response.data.forEach((resposta) => {
            if (!newAnswers.has(resposta.pergunta_id)) {
              newAnswers.set(resposta.pergunta_id, resposta); 
            }
          });
    
          setAnswers(newAnswers); 
          enqueueSnackbar("Respostas consultadas com sucesso!", {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Ocorreu um erro ao buscar as respostas!", {
          variant: "error",
        });
        setIsEditMode(false);
      });
  };

  useEffect(() => {
    if(date) {
      handleEditAnamnesis(date);
      setAnamnesisSelected((prevAnamnesis) => ({
        ...prevAnamnesis,
        data: date
      }))
    }
    handleGetAnamnesis();
  }, [idQuestionario, date]);

  return (
    <Grid item xs={12}>
      <Card sx={{ mb: 2, padding: 2, borderRadius: 3 }} elevation={0}>
        <Box display="flex" justifyContent="space-between">
          <Box width={"50%"}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                moment(option?.createdAt).format("DD/MM/YYYY HH:mm:ss")
              }
              options={anamnesis}
              noOptionsText={"Nenhum registro encontrado"}
              value={anamnesisSelected?.data ?? null}
              onChange={(event, newValue) => {
                setAnamnesisSelected({
                  id: newValue?.Pergunta?.questionario_id,
                  data: newValue?.data_horario_resposta,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Questionários"
                  placeholder="Questionários"
                  fullWidth
                />
              )}
              fullWidth
            />
          </Box>
          <Box>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Ação
            </Typography>
            <Box display={"flex"} gap={1}>
              <Tooltip title="Visualizar" TransitionComponent={Zoom}>
                <IconButton
                  aria-label="visualizar"
                  color="info"
                  disabled={!anamnesisSelected}
                  onClick={() => {
                    handleEditAnamnesis();
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Remover" TransitionComponent={Zoom}>
                <IconButton
                  aria-label="remover"
                  color="error"
                  disabled={!anamnesisSelected}
                  onClick={() => modalAlert()}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default AnamnesisMenu;
