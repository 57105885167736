import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FinancialData from './FinancialData';
import Printouts from './Printouts';
import Details from './Details';
import Rescheduling from './Rescheduling';
import TabSchedule from './TabSchedule';
import ApiService from 'app/services/config';
import { useParams } from 'react-router-dom';
import Extension from './Extension';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PacientSchedule = () => {
    const { scheduleId } = useParams();

    const [value, setValue] = useState(0);
    const [schedule, setSchedule] = useState({
        User: {
            nome: '',
        },
        tipo_de_agendamento: '',
        status: '',
        telemedicina: false,
        obs_agendamento: '',
        acompanhante: '',
        updatedAt: '',
        Convenio: {
            id: '',
            nome: '',
            tipo_plano_convenio: '',
            numero_convenio: '',
        },
    });

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };
    console.log(scheduleId);
    const getSchedule = React.useCallback(async () => {
        try {
            await ApiService.get(`/schedules/${scheduleId}`)
                .then((response) => {
                    console.log(response.data);

                    setSchedule({
                        ...response.data,
                        User: {
                            ...response.data.User,
                            ultima_consulta: response.data?.User?.Agendamento[1]?.data
                        }
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setSchedule, scheduleId]);

    React.useEffect(() => {
        if (scheduleId) getSchedule();
    }, [scheduleId, getSchedule]);

    return (
        <>
            <HeaderBreadcrumbs
                title={'Agenda Prestador'}
                subtitle={'Agendamento'}
                titleUrl={'/schedule-worker'}
            />
            <Paper style={{ padding: '30px' }} square={false} elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={5} sm={4} md={3} lg={2}>
                        <Tabs
                            orientation='vertical'
                            value={value}
                            onChange={handleChangeTabs}
                            aria-label='basic tabs example'
                        >
                            <Tab label='Agenda' {...a11yProps(0)} />
                            <Tab label='Dados Financeiros' {...a11yProps(1)} disabled={!scheduleId}/>
                            <Tab label='Impressões' {...a11yProps(2)} disabled={!scheduleId}/>
                            <Tab label='Detalhes' {...a11yProps(3)} disabled={!scheduleId}/>
                            <Tab label='Reagendamento' {...a11yProps(4)} disabled={!scheduleId}/>
                            <Tab label='Extensao' {...a11yProps(5)} disabled={!scheduleId} />
                            <Tab label='Replicar' {...a11yProps(7)} disabled={!scheduleId} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={7} sm={8} md={9} lg={10}>
                        {/* Agenda */}
                        <CustomTabPanel value={value} index={0}>
                            <TabSchedule
                                schedule={schedule}
                                setSchedule={setSchedule}
                            />
                        </CustomTabPanel>

                        {/* Dados Financeiros */}
                        <CustomTabPanel value={value} index={1}>
                            <FinancialData />
                        </CustomTabPanel>

                        {/* Impressões */}
                        <CustomTabPanel value={value} index={2}>
                            <Printouts />
                        </CustomTabPanel>

                        {/* Detalhes */}
                        <CustomTabPanel value={value} index={3}>
                            <Details
                                schedule={schedule}
                                setSchedule={setSchedule}
                            />
                        </CustomTabPanel>

                        {/* Reagendamento */}
                        <CustomTabPanel value={value} index={4}>
                            <Rescheduling
                                schedule={schedule}
                                setSchedule={setSchedule}
                            />
                        </CustomTabPanel>

                        {/* Extensao */}
                        <CustomTabPanel value={value} index={5}>
                            <Extension />
                        </CustomTabPanel>

                        {/* Replicar */}
                        <CustomTabPanel
                            value={value}
                            index={6}
                        ></CustomTabPanel>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default PacientSchedule;
