import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import KeyIcon from '@mui/icons-material/Key';
import { capitalizeName } from '@jumbo/utils';

const Details = ({ schedule, setSchedule }) => {
    const [age, setAge] = React.useState('');
    console.log('sdfdfdf', schedule);
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Typography
                variant='h1'
                gutterBottom
                style={{ marginBottom: '15px' }}
            >
                Detalhes
            </Typography>
            <Divider style={{ height: '100%', marginBottom: '20px' }} />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Agendamento'
                        value={schedule?.id}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Atendente Reserva'
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Data Hora Reserva'
                        value={`${schedule?.data} - ${schedule?.horario}`}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Atendente'
                        value={schedule?.Colaborador?.nome}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Prestador'
                        InputProps={{ readOnly: true }}
                        value={schedule?.Prestador?.nome}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ minWidth: '100%' }} disabled>
                        <InputLabel id='demo-simple-select-disabled-label'>
                            Convenio
                        </InputLabel>
                        <Select
                            labelId='demo-simple-select-disabled-label'
                            id='demo-simple-select-disabled'
                            label='Convênio'
                            value={schedule?.Convenio?.id || ''}
                            /* onChange={handleAgreementChange} */
                        >
                            <MenuItem value=''>
                                <em>Vazio</em>
                            </MenuItem>
                            {schedule?.User?.convenio?.map((item) => (
                                <MenuItem
                                    value={item?.id}
                                    sx={{ textTransform: 'capitalize' }}
                                >
                                    {capitalizeName(item.nome)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Especialidade'
                        defaultValue=''
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Prontuário'
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl disabled>
                        <FormLabel id='demo-row-radio-buttons-group-label'>
                            Multiplos Atendentes
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={false}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label='SIM'
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label='NÃO'
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl disabled>
                        <FormLabel id='demo-row-radio-buttons-group-label'>
                            Documento Emitido
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={false}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label='SIM'
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label='NÃO'
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Numero Documento'
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Details;
