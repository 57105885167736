import { Grid, Tooltip, Zoom } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ApiService from 'app/services/config';
import { Form, Formik } from "formik";
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import Details from "./Details";
import Schedule from "./Schedule";

const ScheduleModel = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [userSelected, setUserSelected] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const { hasPermission } = useContext(PermissionContext);
    const [value, setValue] = useState(0);

    console.log('userSelected', userSelected)


    const initialValues = {
        Pessoa: {
          nome: "",
          nome_social: "",
          cpf: "",
          data_de_nascimento: "",
          mae_id: "",
          pai_id: "",
          pessoa_responsavel_id: "",
          parentesco_responsavel: "",
          uf_de_nascimento: "",
          cidade_de_nascimento: "",
          nacionalidade: "",
          estado_civil: "",
          profissao: "",
          religiao: "",
          sexo: "",
          rg: "",
          data_emissao_rg: "",
          orgao_emissor_rg: "",
          uf_emissao_rg: "",
          inscricao_municipal: "",
          titulo_eleitor: "",
          cnh: "",
          carteira_de_trabalho: "",
          cartao_pis: "",
          carteira_militar: "",
          passaporte: "",
          tipo_sanguineo: "",
          numero_filhos: null,
          codigo_importacao: "",
          envia_sms: false,
          emite_nota: false,
          observacao: "",
          codigo: "",
          perfil_id: "",
          especialidades: [],
        },
        /* PessoaResponsavel: {}, */
        Enderecos: {},
        Telefones: {},
        Contatos: {},
        Registros: {},
        Convenios: {},
        Autorizacao: {},
        /*  Consultas: {},
            Avisos: {}, */
      };

    const [infos, setInfos] = useState(initialValues);


    const [copyInfos, setCopyInfos] = useState({
        filial: '',
        validade_inicial: '',
        validade_final: '',
        agenda: '',
        id: '',
        Disponibilidade_Prestador: [],
    });

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        console.log('handleSubmit')
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ padding: '3 0 0 3' }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }


    const copyModel = {
        prestador_id: userSelected?.selected?.id,
        filial: copyInfos?.filial,
        somente_dias_uteis: true,
        tipo_criacao_agenda: 'periodo',
        validade_inicial: copyInfos?.validade_inicial,
        validade_final: copyInfos?.validade_final,
        multiplos_atendentes: false,
        quantidade_vagas_por_horarios: 1,
        servico_agendamento: 'prestador',
        Disponibilidade_Prestador: [
            {
                havera_atendimento: true,
                dia_semana: '1',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '2',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '3',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '4',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '5',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '19:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: true,
                dia_semana: '6',
                dia_especifico: '',
                filial: copyInfos?.filial,
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '1',
                        horario_inicio: '07:00',
                        horario_fim: '17:30',
                        tempo_atendimento: 30,
                        tipo_agendamento: 'consulta',
                    },
                ],
            },
            {
                havera_atendimento: false,
                dia_semana: '0',
                dia_especifico: '',
                filial: '',
                Disponibilidade_Prestador_Periodo: [
                    {
                        periodo: '',
                        horario_inicio: '',
                        horario_fim: '',
                        tempo_atendimento: 0,
                        tipo_agendamento: '',
                    },
                ],
            },
        ],
    };

    const fetchUsers = async (inputValue) => {
        setLoading(true);
        try {
            const response = await ApiService.get(
                `/users/all/prestadores?searchTerm=${inputValue}`
            );
            const users = response.data || [];
            console.log(users);
            setOptions(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setUserInput(newInputValue);
        if (newInputValue !== '') {
            fetchUsers(newInputValue);
        } else {
            setOptions([]);
            setUserSelected(null);
        }
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    console.log(copyInfos);
    console.log(userSelected);
    return (
        <div>
            <HeaderBreadcrumbs
                title={'Agenda Modelo'}
                titleUrl={'/ScheduleModel'}
            />
            <Paper sx={{ paddingY: 3 }} square={false} elevation={3}>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    spacing={3}
                >
                    <Grid item xs={11}>
                            <Typography variant='h2' gutterBottom>
                                Escolha o prestador
                            </Typography>
                            <Grid container alignItems='center' spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            getOptionLabel={(option) => option.nome}
                                            options={options}
                                            loading={loading}
                                            inputValue={userInput}
                                            onInputChange={handleInputChange}
                                            onChange={(event, newValue) => {
                                                setUserSelected((prevValues) => (
                                                    newValue?.id
                                                ));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Prestador'
                                                    placeholder='Prestador'
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading
                                                                    ? 'carregando...'
                                                                    : null}
                                                                {
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment
                                                                }
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {userSelected && (
                                <>
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />

                                    {/* <Paper style={{ padding: "30px" }} square={false} elevation={3}> */}
                                        <Formik
                                        initialValues={infos}
                                        enableReinitialize
                                        validateOnChange={false}
                                        onSubmit={handleSubmit}
                                        >
                                        {({ values, isSubmitting, handleChange, setFieldValue }) => (
                                            <Form style={{ width: "100%" }} noValidate autoComplete="off">
                                            <Grid container spacing={2}>
                                                <Grid item xs={5} sm={4} md={3} lg={2}>
                                                    <Tabs
                                                        orientation="vertical"
                                                        value={value}
                                                        onChange={handleChangeTabs}
                                                        aria-label="basic tabs example"
                                                    >
                                                        <Tab label="Agenda modelo" {...a11yProps(0)} />
                                                        <Tab label="Detalhes" {...a11yProps(1)} />
                                                    </Tabs>
                                                </Grid>

                                                <Grid item xs={7} sm={8} md={9} lg={10}>
                                                    <CustomTabPanel value={value} index={0}>
                                                        <Schedule prestadorId={userSelected} />
                                                    </CustomTabPanel>

                                                    <CustomTabPanel value={value} index={1}>
                                                        <Details prestadorId={userSelected} />
                                                    </CustomTabPanel>
                                                
                                                </Grid>
                                            </Grid>
                                        </Form>
                                        )}
                                    </Formik>
                                {/* </Paper> */}
                            </>
                        )}     
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default ScheduleModel;
