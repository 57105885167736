import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ApiService from 'app/services/config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { Box, IconButton, Tooltip, Zoom } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { formatDateWithMoment } from '@jumbo/utils';
import { QUIZZES_EVALUATIONS_IDS } from './InterdisciplinaryAssessment';

const DailyChips = ({ tabIndex }) => {
    const navigate = useNavigate();
    let { state } = useLocation();
    const { userId } = useParams();

    const [quizzes, setQuizzes] = useState([]);
    const [userQuizz, setUserQuizz] = useState([]);
    const [quizSelected, setQuizSelected] = useState({
        atendimento: '',
        terapia: '',
        outros: '',
        avaliacao: '',
        habilidade_motora: '',
        autocuidado: '',
       

        protocolo_de_reabilitacao_cognitiva: '',
        como_cheguei: '',
        terapia_snoezelen: '',
        psicologia: '',
        fisioterapia: '',
        terapia_ocupacional: '',
        psicopedagogia: '',
        fonoaudiologia: '',

    });

    const getQuizzes = useCallback(async () => {
        try {
            await ApiService.get(`/quizzes?userId=${userId}`)
                .then((response) => {
                    console.log('quizzes',response.data);

                    setQuizzes(response?.data?.quizzes);
                    setUserQuizz(response?.data?.respostasAgrupadas);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setQuizzes]);

    useEffect(() => {
        getQuizzes();
    }, [getQuizzes]);

    console.log(userQuizz);

    const columns = [
        {
            name: 'id',
            label: 'Id',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: 'respostas',
            label: 'Ficha',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const userId = tableMeta.rowData[0];
                    return value[0]?.Pergunta?.Questionario?.nome;
                },
            },
        },
        {
            name: 'horario',
            label: 'Data e horário',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const userId = tableMeta.rowData[0];
                    return formatDateWithMoment(value)
                },
            },
        },

        {
            name: 'action',
            label: 'Ações',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const quizId =
                        tableMeta.rowData[1][0]?.Pergunta?.Questionario?.id;
                    const queryData = data[tableMeta.rowIndex];
                    let urlQuizEdit = "";
                    let searchParams = "";
                    if(QUIZZES_EVALUATIONS_IDS.some(id => id === quizId)) {
                        urlQuizEdit = `/evaluation/${quizId}/patient/${userId}/edit`;
                        searchParams = `?date=${tableMeta.rowData[2]}`;
                    } else {
                        urlQuizEdit = `/sheet/${quizId}/user/${userId}/edit`;
                        searchParams = `?date=${queryData?.horario}`;
                    }
                    return (
                        <Box display={'flex'} gap={'1rem'}>
                            <Tooltip
                                title='Visualizar'
                                TransitionComponent={Zoom}
                            >
                                <Link
                                    to={{
                                        pathname: urlQuizEdit,
                                        search: searchParams,
                                    }}
                                    state={{ fromTab: tabIndex }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <IconButton
                                        aria-label='visualizar'
                                        color='info'
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
    ];

    const options = {
        /* changeRowsPerPage: perPage, */
        filterType: 'dropdown',
        filter: false,
        selectableRows: 'none',
        searchAlwaysOpen: true,
        searchable: true,
        /* serverSide: true,
        page: page,
        count: count, */
        selectToolbarPlacement: 'above',

        /* onTableChange: (action, tableState) => {
            console.log(action);
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'sort':
                    setSortBy(tableState.sortOrder.name);
                    setSortOrder(tableState.sortOrder.direction);
                    break;
                case 'search':
                    handleSearch(tableState.searchText);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log('action not handled.');
            }
        }, */
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'of',
            },
        },
    };

    const data = Object.entries(userQuizz).map(([horario, respostas]) => ({
        horario,
        respostas,
    }));

    console.log(data);

    return (
        <div>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Divider>Nova Ficha de Atendimento</Divider>
                </Grid>
                {/* <Grid item xs={4} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Atendimento Diário
                        </InputLabel>
                        <Select
                            label='Atendimento Diário'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                atendimento: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes('Atendimento') ? (
                                    <MenuItem value={quiz?.id}>
                                        {quiz?.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.atendimento}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.atendimento}/user/${userId}`, {
                                        state: {
                                            fromTab: 4
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.atendimento}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.atendimento}/user/${userId}`, {
                                        state: {
                                            fromTab: 4
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid> */}

                <Grid item xs={4} container style={{ marginTop: '10px' }}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Checklist - Protocolo de Reabilitação Cognitiva
                        </InputLabel>
                        <Select
                            label='Checklist - Protocolo de Reabilitação Cognitiva'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                protocolo_de_reabilitacao_cognitiva: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes(
                                    'Checklist - Protocolo de Reabilitação Cognitiva'
                                ) ? (
                                    <MenuItem value={quiz.id}>
                                        {quiz.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.protocolo_de_reabilitacao_cognitiva}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.protocolo_de_reabilitacao_cognitiva}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.outros}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.protocolo_de_reabilitacao_cognitiva}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={4} container style={{ marginTop: '10px' }}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Como cheguei / Como Estou
                        </InputLabel>
                        <Select
                            label='Como cheguei / Como Estou'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                como_cheguei: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes('Como cheguei / Como Estou') ? (
                                    <MenuItem value={quiz?.id}>
                                        {quiz?.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.como_cheguei}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.como_cheguei}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.como_cheguei}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.como_cheguei}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={4} container style={{ marginTop: '10px' }}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                        Terapia Snoezelen
                        </InputLabel>
                        <Select
                            label='Terapia Snoezelen'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                terapia_snoezelen: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes('Terapia Snoezelen') ? (
                                    <MenuItem value={quiz?.id}>
                                        {quiz?.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.terapia_snoezelen}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.terapia_snoezelen}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.terapia_snoezelen}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.terapia_snoezelen}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={4} container style={{ marginTop: '10px' }}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Psicologia
                        </InputLabel>
                        <Select
                            label='Psicologia'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                psicologia: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes('Cidade Terapêutica') 
                                || quiz.nome.includes('Psicologia - Checklist de intervenção')
                                || quiz.nome.includes('Terapia Estruturada')
                                ? (
                                    <MenuItem value={quiz?.id}>
                                        {quiz?.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.psicologia}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.psicologia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.psicologia}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.psicologia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={4} container style={{ marginTop: '10px' }}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Fisioterapia
                        </InputLabel>
                        <Select
                            label='Fisioterapia'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                fisioterapia: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes('Checklist de Psicomotricidade') 
                                || quiz.nome.includes('Terapia Ocupacional Aquática')
                                || quiz.nome.includes('Checklist de Terapia multisensorial')
                                ? (
                                    <MenuItem value={quiz?.id}>
                                        {quiz?.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.fisioterapia}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.fisioterapia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.fisioterapia}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.fisioterapia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={4} container style={{ marginTop: '10px' }}>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                        Terapia Ocupacional
                        </InputLabel>
                        <Select
                            label='Terapia Ocupacional'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                terapia_ocupacional: e.target.value
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes('Terapia na Cozinha') 
                                || quiz.nome.includes('Terapia de quarto')
                                || quiz.nome.includes('Terapia de banheiro')
                                || quiz.nome.includes('Habilidades motoras')
                                || quiz.nome.includes('Autocuidado')
                                ? (
                                    <MenuItem value={quiz?.id}>
                                        {quiz?.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.terapia_ocupacional}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.terapia_ocupacional}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.terapia_ocupacional}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.terapia_ocupacional}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                {/* 3 */}

                <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Psicopedagogia
                        </InputLabel>
                        <Select
                            label='psicopedagogia'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                psicopedagogia: e.target.value,
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes(
                                    'Psicopedagogia -'
                                ) ? (
                                    <MenuItem value={quiz.id}>
                                        {quiz.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.psicopedagogia}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.psicopedagogia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.psicopedagogia}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.psicopedagogia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                {/* 4 */}

                <Grid item xs={4} style={{ marginTop: '10px' }} container>
                    <FormControl fullWidth variant='outlined'>
                        <InputLabel id='demo-simple-select-label'>
                            Fonoaudiologia
                        </InputLabel>
                        <Select
                            label='Fonoaudiologia'
                            onChange={(e) => setQuizSelected({
                                ...quizSelected,
                                fonoaudiologia: e.target.value,
                            })}
                        >
                            {quizzes.map((quiz) =>
                                quiz.nome.includes(
                                    'Fonoaudiologia'
                                ) ? (
                                    <MenuItem value={quiz.id}>
                                        {quiz.nome}
                                    </MenuItem>
                                ) : undefined
                            )}
                            {/* adicionar ou remover opçoes conforme solicitado */}
                        </Select>
                    </FormControl>
                </Grid>
                
                <Grid item xs={2} style={{ marginTop: '19px' }}>
                    <Tooltip title='Criar ficha' TransitionComponent={Zoom}>
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.fonoaudiologia}
                            onClick={() => {
                                navigate(
                                    `/sheet/${quizSelected.fonoaudiologia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <QuizIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title='Visualizar gráfico'
                        TransitionComponent={Zoom}
                    >
                        <Button
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            disabled={!quizSelected.fonoaudiologia}
                            onClick={() => {
                                navigate(
                                    `/quizzes/dashboard/${quizSelected.fonoaudiologia}/user/${userId}`, {
                                        state: {
                                            fromTab: tabIndex
                                        }
                                    }
                                );
                            }}
                        >
                            <AnalyticsIcon />
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xs={12}>
                    <Divider>Fichas de atendimentos existentes</Divider>
                </Grid>

                <Grid item xs={8}>
                    {/* espacinho ;P */}
                </Grid>

                <Grid item xs={4}>
                    <Button variant='outlined' style={{ marginLeft: '5px' }}>
                        Visualizar todas as fichas
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {/* <DataTable
                        title={'Visualizacao das ultimas 90 fichas'}
                        columns={columns}
                    /> */}
                    <MUIDataTable
                        title={'Visualizacao das fichas'}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default DailyChips;
