export const CHAVES_NOMENCLATURA_CORRETAS = {
    nome: "Nome",
    nome_social: "Nome social",
    email: "E-mail",
    cpf: 'CPF',
    data_de_nascimento: "Data de nascimento",
    sexo: "Sexo",
    parentesco_responsavel: "Parentesco Responsável",
    uf_de_nascimento: "UF de nascimento",
    cidade_de_nascimento: "Cidade de nascimento",
    nacionalidade: "Nacionalidade",
    estado_civil: "Estado civil",
    profissao: "Profissão",
    religiao: "Religião",
    rg: "RG",
    data_emissao_rg: "Data de emissão RG",
    uf_emissao_rg: "UF de emissão RG",
    inscricao_municipal: "Inscrição municipal",
    cnh: "CNH",
    carteira_de_trabalho: "Carteira de trabalho",
    cartao_pis: "Cartão PIS",
    carteira_militar: "Carteira militar",
    passaporte: "Passaporte",
    tipo_sanguineo: "Tipo sanguíneo",
    codigo_importacao: "Código importação",
    envia_sms: "Envia SMS",
    emite_note: "Emite nota",
    observacao: "Observação",
    codigo: "Código",
    turma_id: "ID da turma"
}