import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import {
    Box,
    Grid,
    OutlinedInput,
    TextField,
    Button,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormLabel,
    CircularProgress,
    Typography,
} from '@mui/material';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback } from 'react';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatDateWithMoment, getCustomDateTimeUtc } from '@jumbo/utils';
import { redirectToEvolution } from '../search/ScheduleVertical';


const AllQuizzes = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { quizId, userId } = useParams();
    const location = useLocation();
    const state = location.state || {};
    const fromTab = state.fromTab || 0;
    const navigate = useNavigate();

    const storedColaboratorId = localStorage.getItem('id');

    const [isLoading, setIsLoading] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [who, setWho] = useState([]);
    const [whoUpdated, setWhoUpdated] = useState([]);

    const [quizInfo, setQuizInfo] = useState([]);

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    const date = query.get('date');

    const getQuizzes = useCallback(async () => {
        try {
            setIsLoading(true);
            await ApiService.get(`/quizzes/${quizId}?userId=${userId}`)
                .then((response) => {
                    console.log(response.data);
                    setQuizInfo(response.data);
                    setQuizzes(response.data?.secoes);
                    /*  setQuizzes(response.data?.pergunta); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }, [setQuizzes, quizId]);

    const getQuizzesAnswers = useCallback(async () => {
        try {
            await ApiService.get(
                `/quizzes/${quizId}/user/${userId}?date=${date}`
            )
                .then((response) => {
                    console.log(response.data);
                    setQuizInfo(response.data?.userQuizz);
                    setQuizzes(response.data?.userQuizz?.secoes);
                    
                    const quizzesFiltered = response.data?.userQuizz?.secoes?.filter(function(item) { return item.nome.includes('PENDÊNCIAS - NÍVEL 3') && item.perguntas.length > 0 })
                    console.log('dados filtrador', quizzesFiltered)
                    //setQuizzesPendents()

                    /*  setQuizzes(response.data?.userQuizz?.pergunta); */
                    setWho(response.data?.findWhoCreatedAndUpdated);
                    setWhoUpdated(response.data?.findWhoUpdated)

                    /* setAnswers(respostasFormatadas); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (location?.pathname.includes('edit')) {
            getQuizzesAnswers();
        } else {
            getQuizzes();
        }
    }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

    // ID do questionário que deve mostrar text field
    const showTextFieldQuestId = [
        23,
        24
    ]

    console.log('quizzes', quizzes)

    console.log(answers);

    const getSelectedAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) =>
                resposta?.Resposta_Usuario?.length > 0 &&
                resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.id;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    const getObsAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) => resposta?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.observacao;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    console.log(answers);

    const RESPOSTAS_FORMATADAS = {
        adquirido: 'Adquirido',
        suporte_parcial: 'Suporte Parcial',
        suporte_total: 'Suporte Total',
        nao: 'Não',
        nao_observado: 'Não observado',
        pre_silabico: 'Pré-silábico',
        silabico_sem_valor_sonoro: 'Silábico sem valor sonoro',
        silabico_com_valor_sonoro: 'Silábico com valor sonoro',
        silabico_alfabetico: 'Silábico alfabético',
        alfabetico: 'Alfabético',
        reconhece_em_sequencia: 'Reconhece em sequência',
        reconhece_em_forma_aleatoria: 'Reconhece em forma aleatória',
        sim: 'Sim',
        as_vezes: 'Às vezes',
        nunca: "Nunca",
        raramente: "Raramente",
        frequentemente: "Frequentemente",
    }

    const defaultLabel = 'Observação adicional';

    const labelMappings = {
        'Como Chegou': 'Como Chegou?',
        'Como Estou': 'Como Estou?',
        'Qual foi o lanche?': 'Qual foi o lanche?',
        'Insira a evolução': 'Insira a evolução',
        'Insira a informação': 'Insira a informação',
        'Descreva': 'Descreva',
    };

    const getLabel = (nome) => {
        for (const key in labelMappings) {
            if (nome.includes(key)) {
                return labelMappings[key];
            }
        }
        return defaultLabel;
    };

    return (
        <>
            {isLoading ? (
              
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <CircularProgress color="primary" />
                    <Typography>Carregando...</Typography>
                </Box>
                
                ) : 
                (
                    <div>
                        <HeaderBreadcrumbs
                            title={quizInfo?.nome}
                            titleUrl={
                                location?.pathname.includes('edit')
                                    ? `${location?.pathname}${location.search}`
                                    : location.pathname
                            }
                        />
                        <Button
                            sx={{ width: '80px', marginBottom: '1rem' }}
                            variant='contained'
                            color='success'
                            onClick={() => redirectToEvolution(userId, navigate, fromTab)}
                        >
                            Voltar
                        </Button>
                        {!location?.pathname.includes('edit') && 
                        (quizInfo?.nome == 'Atendimento Diário - Nível 3' || 
                            quizInfo?.nome == 'Checklist Denver Avaliação - Nível 2' || 
                            quizInfo?.nome == 'Checklist Denver Avaliação - Nível 3' || 
                            quizInfo?.nome == 'Checklist Denver Avaliação - Nível 4') &&
                            quizzes?.filter(function(item) { 
                                return item.nome.includes('PENDÊNCIAS - NÍVEL 1') && item.perguntas.length > 0 
                            }).length > 0  && (
                                <Paper
                                    style={{ padding: '10px', marginBottom: '15px' }}
                                    square={false}
                                    elevation={3}
                                >
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent='center'
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={10}>
                                            <Grid container spacing={2} alignItems={'center'}>
                                                <Grid item xs={12}>
                                                    {
                                                        quizzes?.filter(function(item) { 
                                                            return item.nome.includes('PENDÊNCIAS - NÍVEL 1') && item.perguntas.length > 0 
                                                        }).map((item) => (
                                                            <Accordion defaultExpanded>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls='panel1-content'
                                                                    id='panel1-header'
                                                                >
                                                                    {item?.nome}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        alignItems={'center'}
                                                                    >
                                                                        {item?.perguntas?.map(
                                                                            (pergunta) => (
                                                                                <Grid item xs={12}>
                                                                                    <FormControl
                                                                                        fullWidth
                                                                                    >
                                                                                        {!pergunta?.nome.includes(
                                                                                            'Observação'
                                                                                        ) &&
                                                                                        (!showTextFieldQuestId.includes(pergunta.questionario_id) ||
                                                                                            (
                                                                                                showTextFieldQuestId.includes(pergunta.questionario_id) &&
                                                                                                (
                                                                                                    pergunta.nome.includes('Reconhecimento de letras do alfabeto') ||
                                                                                                    pergunta.nome.includes('Nível de escrita')
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                        &&
                                                                                        !pergunta.nome.includes(
                                                                                            'Como Chegou'
                                                                                        ) &&
                                                                                        !pergunta.nome.includes(
                                                                                            'Como Estou'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Qual foi o lanche?'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a evolução'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a devolutiva'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a supervisão'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Descreva'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a informação'
                                                                                        ) ? (
                                                                                            <>
                                                                                                <InputLabel id='demo-simple-select-label'>
                                                                                                    {
                                                                                                        pergunta?.nome
                                                                                                    }
                                                                                                </InputLabel>
                                                                                                <Select
                                                                                                    labelId='demo-simple-select-label'
                                                                                                    id='demo-simple-select'
                                                                                                    value={getSelectedAnswer(
                                                                                                        pergunta?.Resposta,
                                                                                                        pergunta?.id
                                                                                                    )}
                                                                                                    label={
                                                                                                        pergunta?.nome
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setAnswers(
                                                                                                            (
                                                                                                                prevAnswers
                                                                                                            ) => {
                                                                                                                const userAnswered =
                                                                                                                    pergunta?.Resposta?.find(
                                                                                                                        (
                                                                                                                            resposta
                                                                                                                        ) =>
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.pergunta_id ===
                                                                                                                                pergunta?.id
                                                                                                                    );
                                                                                                                const newAnswer =
                                                                                                                    {
                                                                                                                        id: userAnswered
                                                                                                                            ?.Resposta_Usuario[0]
                                                                                                                            ?.id,
                                                                                                                        pergunta_id:
                                                                                                                            pergunta.id,
                                                                                                                        resposta_id:
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        data_horario_resposta:
                                                                                                                            userAnswered
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.data_horario_resposta ||
                                                                                                                            date,
                                                                                                                    };
                                                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                                const existingAnswerIndex =
                                                                                                                    prevAnswers.findIndex(
                                                                                                                        (
                                                                                                                            ans
                                                                                                                        ) =>
                                                                                                                            ans.pergunta_id ===
                                                                                                                            pergunta.id
                                                                                                                    );
                                                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                                if (
                                                                                                                    existingAnswerIndex !==
                                                                                                                    -1
                                                                                                                ) {
                                                                                                                    const updatedAnswers =
                                                                                                                        [
                                                                                                                            ...prevAnswers,
                                                                                                                        ];
                                                                                                                    updatedAnswers[
                                                                                                                        existingAnswerIndex
                                                                                                                    ] =
                                                                                                                        newAnswer;
                                                                                                                    return updatedAnswers;
                                                                                                                } else {
                                                                                                                    return [
                                                                                                                        ...prevAnswers,
                                                                                                                        newAnswer,
                                                                                                                    ];
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {pergunta?.Resposta?.map(
                                                                                                        (
                                                                                                            resposta
                                                                                                        ) => (
                                                                                                            <MenuItem
                                                                                                                value={
                                                                                                                    resposta?.id
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    RESPOSTAS_FORMATADAS[resposta?.resposta]
                                                                                                                }
                                                                                                                
                                                                                                            </MenuItem>
                                                                                                        )
                                                                                                    )}
                                                                                                </Select>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={12}
                                                                                            >
                                                                                                        
                                                                                                {showTextFieldQuestId.includes(pergunta.questionario_id) && 
                                                                                                    <FormLabel id='demo-simple-select-label'>
                                                                                                        {
                                                                                                            pergunta?.nome
                                                                                                        }
                                                                                                    </FormLabel>
                                                                                                }
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    id='outlined-multiline-static'
                                                                                                    multiline
                                                                                                    rows={
                                                                                                        4
                                                                                                    }
                                                                                                    label={
                                                                                                        showTextFieldQuestId.includes(pergunta.questionario_id)
                                                                                                        ? null
                                                                                                        : getLabel(pergunta.nome)
                                                                                                    }
                                                                                                    value={getObsAnswer(
                                                                                                        pergunta?.Resposta_Usuario,
                                                                                                        pergunta?.id
                                                                                                    )}
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setAnswers(
                                                                                                            (
                                                                                                                prevAnswers
                                                                                                            ) => {
                                                                                                                const userAnswered =
                                                                                                                    pergunta?.Resposta?.find(
                                                                                                                        (
                                                                                                                            resposta
                                                                                                                        ) =>
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.pergunta_id ===
                                                                                                                                pergunta?.id
                                                                                                                    );
                                                                                                                const newAnswer =
                                                                                                                    {
                                                                                                                        id: pergunta
                                                                                                                            ?.Resposta_Usuario[0]
                                                                                                                            ?.id,
                                                                                                                        pergunta_id:
                                                                                                                            pergunta.id,
                                                                                                                        observacao:
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        data_horario_resposta:
                                                                                                                            userAnswered
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.data_horario_resposta ||
                                                                                                                            date,
                                                                                                                    };
                                                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                                const existingAnswerIndex =
                                                                                                                    prevAnswers.findIndex(
                                                                                                                        (
                                                                                                                            ans
                                                                                                                        ) =>
                                                                                                                            ans.pergunta_id ===
                                                                                                                            pergunta.id
                                                                                                                    );
                                                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                                if (
                                                                                                                    existingAnswerIndex !==
                                                                                                                    -1
                                                                                                                ) {
                                                                                                                    const updatedAnswers =
                                                                                                                        [
                                                                                                                            ...prevAnswers,
                                                                                                                        ];
                                                                                                                    updatedAnswers[
                                                                                                                        existingAnswerIndex
                                                                                                                    ] =
                                                                                                                        newAnswer;
                                                                                                                    return updatedAnswers;
                                                                                                                } else {
                                                                                                                    return [
                                                                                                                        ...prevAnswers,
                                                                                                                        newAnswer,
                                                                                                                    ];
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            )
                                                                        )}
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))
                                                    }
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Button
                                                variant='outlined'
                                                onClick={() => {
                                                ApiService.put(
                                                    `/user-answered/user/${userId}/colaborator/${Number(
                                                        storedColaboratorId
                                                    )}`,
                                                    answers
                                                )
                                                    .then((response) => {
                                                        enqueueSnackbar(
                                                            'Ficha 1 atualizada com sucesso!',
                                                            {
                                                                variant: 'success',
                                                                
                                                            }
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        const errorMessage =
                                                            error.response.data.message;
                                                        console.log(errorMessage);
                                                        if (Array.isArray(errorMessage)) {
                                                            enqueueSnackbar(
                                                                `${errorMessage[0]}`,
                                                                {
                                                                    variant: 'error',
                                                                    
                                                                }
                                                            );
                                                        } else {
                                                            enqueueSnackbar(
                                                                `${errorMessage}`,
                                                                {
                                                                    variant: 'error',
                                                                    
                                                                }
                                                            );
                                                        }
                                                    });
                                                }}
                                            >
                                                Atualizar ficha nível 1
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )
                        }

                        {!location?.pathname.includes('edit') && 
                            (quizInfo?.nome == 'Atendimento Diário - Nível 3' || 
                            quizInfo?.nome == 'Checklist Denver Avaliação - Nível 3' || 
                            quizInfo?.nome == 'Checklist Denver Avaliação - Nível 4') &&
                            quizzes?.filter(function(item) { 
                                return item.nome.includes('PENDÊNCIAS - NÍVEL 2') && item.perguntas.length > 0 
                            }).length > 0 && (
                                <Paper
                                    style={{ padding: '10px', marginBottom: '15px' }}
                                    square={false}
                                    elevation={3}
                                >
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent='center'
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={10}>
                                            <Grid container spacing={2} alignItems={'center'}>
                                                <Grid item xs={12}>
                                                    {
                                                        quizzes?.filter(function(item) { 
                                                            return item.nome.includes('PENDÊNCIAS - NÍVEL 2') && item.perguntas.length > 0 
                                                        }).map((item) => (
                                                            <Accordion defaultExpanded>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls='panel1-content'
                                                                    id='panel1-header'
                                                                >
                                                                    {item?.nome}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        alignItems={'center'}
                                                                    >
                                                                        {item?.perguntas?.map(
                                                                            (pergunta) => (
                                                                                <Grid item xs={12}>
                                                                                    <FormControl
                                                                                        fullWidth
                                                                                    >
                                                                                        {!pergunta?.nome.includes(
                                                                                            'Observação'
                                                                                        ) &&
                                                                                        (!showTextFieldQuestId.includes(pergunta.questionario_id) ||
                                                                                            (
                                                                                                showTextFieldQuestId.includes(pergunta.questionario_id) &&
                                                                                                (
                                                                                                    pergunta.nome.includes('Reconhecimento de letras do alfabeto') ||
                                                                                                    pergunta.nome.includes('Nível de escrita')
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                        &&
                                                                                        !pergunta.nome.includes(
                                                                                            'Como Chegou'
                                                                                        ) &&
                                                                                        !pergunta.nome.includes(
                                                                                            'Como Estou'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Qual foi o lanche?'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a evolução'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a devolutiva'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a supervisão'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Descreva'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a informação'
                                                                                        ) ? (
                                                                                            <>
                                                                                                <InputLabel id='demo-simple-select-label'>
                                                                                                    {
                                                                                                        pergunta?.nome
                                                                                                    }
                                                                                                </InputLabel>
                                                                                                <Select
                                                                                                    labelId='demo-simple-select-label'
                                                                                                    id='demo-simple-select'
                                                                                                    value={getSelectedAnswer(
                                                                                                        pergunta?.Resposta,
                                                                                                        pergunta?.id
                                                                                                    )}
                                                                                                    label={
                                                                                                        pergunta?.nome
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setAnswers(
                                                                                                            (
                                                                                                                prevAnswers
                                                                                                            ) => {
                                                                                                                const userAnswered =
                                                                                                                    pergunta?.Resposta?.find(
                                                                                                                        (
                                                                                                                            resposta
                                                                                                                        ) =>
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.pergunta_id ===
                                                                                                                                pergunta?.id
                                                                                                                    );
                                                                                                                const newAnswer =
                                                                                                                    {
                                                                                                                        id: userAnswered
                                                                                                                            ?.Resposta_Usuario[0]
                                                                                                                            ?.id,
                                                                                                                        pergunta_id:
                                                                                                                            pergunta.id,
                                                                                                                        resposta_id:
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        data_horario_resposta:
                                                                                                                            userAnswered
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.data_horario_resposta ||
                                                                                                                            date,
                                                                                                                    };
                                                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                                const existingAnswerIndex =
                                                                                                                    prevAnswers.findIndex(
                                                                                                                        (
                                                                                                                            ans
                                                                                                                        ) =>
                                                                                                                            ans.pergunta_id ===
                                                                                                                            pergunta.id
                                                                                                                    );
                                                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                                if (
                                                                                                                    existingAnswerIndex !==
                                                                                                                    -1
                                                                                                                ) {
                                                                                                                    const updatedAnswers =
                                                                                                                        [
                                                                                                                            ...prevAnswers,
                                                                                                                        ];
                                                                                                                    updatedAnswers[
                                                                                                                        existingAnswerIndex
                                                                                                                    ] =
                                                                                                                        newAnswer;
                                                                                                                    return updatedAnswers;
                                                                                                                } else {
                                                                                                                    return [
                                                                                                                        ...prevAnswers,
                                                                                                                        newAnswer,
                                                                                                                    ];
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {pergunta?.Resposta?.map(
                                                                                                        (
                                                                                                            resposta
                                                                                                        ) => (
                                                                                                            <MenuItem
                                                                                                                value={
                                                                                                                    resposta?.id
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    RESPOSTAS_FORMATADAS[resposta?.resposta]
                                                                                                                }
                                                                                                                
                                                                                                            </MenuItem>
                                                                                                        )
                                                                                                    )}
                                                                                                </Select>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={12}
                                                                                            >
                                                                                                        
                                                                                                {showTextFieldQuestId.includes(pergunta.questionario_id) && 
                                                                                                    <FormLabel id='demo-simple-select-label'>
                                                                                                        {
                                                                                                            pergunta?.nome
                                                                                                        }
                                                                                                    </FormLabel>
                                                                                                }
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    id='outlined-multiline-static'
                                                                                                    multiline
                                                                                                    rows={
                                                                                                        4
                                                                                                    }
                                                                                                    label={
                                                                                                        showTextFieldQuestId.includes(pergunta.questionario_id)
                                                                                                        ? null
                                                                                                        : getLabel(pergunta.nome)
                                                                                                    }
                                                                                                    value={getObsAnswer(
                                                                                                        pergunta?.Resposta_Usuario,
                                                                                                        pergunta?.id
                                                                                                    )}
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setAnswers(
                                                                                                            (
                                                                                                                prevAnswers
                                                                                                            ) => {
                                                                                                                const userAnswered =
                                                                                                                    pergunta?.Resposta?.find(
                                                                                                                        (
                                                                                                                            resposta
                                                                                                                        ) =>
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.pergunta_id ===
                                                                                                                                pergunta?.id
                                                                                                                    );
                                                                                                                const newAnswer =
                                                                                                                    {
                                                                                                                        id: pergunta
                                                                                                                            ?.Resposta_Usuario[0]
                                                                                                                            ?.id,
                                                                                                                        pergunta_id:
                                                                                                                            pergunta.id,
                                                                                                                        observacao:
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        data_horario_resposta:
                                                                                                                            userAnswered
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.data_horario_resposta ||
                                                                                                                            date,
                                                                                                                    };
                                                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                                const existingAnswerIndex =
                                                                                                                    prevAnswers.findIndex(
                                                                                                                        (
                                                                                                                            ans
                                                                                                                        ) =>
                                                                                                                            ans.pergunta_id ===
                                                                                                                            pergunta.id
                                                                                                                    );
                                                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                                if (
                                                                                                                    existingAnswerIndex !==
                                                                                                                    -1
                                                                                                                ) {
                                                                                                                    const updatedAnswers =
                                                                                                                        [
                                                                                                                            ...prevAnswers,
                                                                                                                        ];
                                                                                                                    updatedAnswers[
                                                                                                                        existingAnswerIndex
                                                                                                                    ] =
                                                                                                                        newAnswer;
                                                                                                                    return updatedAnswers;
                                                                                                                } else {
                                                                                                                    return [
                                                                                                                        ...prevAnswers,
                                                                                                                        newAnswer,
                                                                                                                    ];
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            )
                                                                        )}
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))
                                                    }
                                                
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {location.pathname.includes('edit') && (
                                        <>
                                            <Divider
                                                style={{
                                                    height: '100%',
                                                    marginBottom: '20px',
                                                    marginTop: '20px',
                                                }}
                                            />
                                            <Grid
                                                container
                                                justifyContent='center'
                                                alignItems='center'
                                                rowSpacing={5}
                                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            >
                                                <Grid item xs={12}>
                                                    <Paper
                                                        sx={{
                                                            backgroundColor: '#f5f5f5',
                                                            borderRadius: '10px',
                                                            boxShadow: 'none',
                                                        }}
                                                    >
                                                        <Table sx={{ minWidth: 650 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align='center'>
                                                                        Código
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        Inclusão
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {' '}
                                                                        Colaborador Inclusão{' '}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        Alteração
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {' '}
                                                                        Colaborador Alteração{' '}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            { border: 0 },
                                                                    }}
                                                                >
                                                                    <TableCell align='center'>
                                                                        -
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {formatDateWithMoment(
                                                                            who?.data_horario_resposta
                                                                        ) ?? '-'}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {who?.Colaborador_Incluiu
                                                                            ?.nome ?? '-'}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {getCustomDateTimeUtc(
                                                                            whoUpdated?.updatedAt
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {whoUpdated?.Colaborador_Alteracao
                                                                            ?.nome ?? '-'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Button
                                                variant='outlined'
                                                onClick={() => {
                                                        ApiService.put(
                                                            `/user-answered/user/${userId}/colaborator/${Number(
                                                                storedColaboratorId
                                                            )}`,
                                                            answers
                                                        )
                                                            .then((response) => {
                                                                enqueueSnackbar(
                                                                    'Ficha 2 atualizada com sucesso!',
                                                                    {
                                                                        variant: 'success',
                                                                        
                                                                    }
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                const errorMessage =
                                                                    error.response.data.message;
                                                                console.log(errorMessage);
                                                                if (Array.isArray(errorMessage)) {
                                                                    enqueueSnackbar(
                                                                        `${errorMessage[0]}`,
                                                                        {
                                                                            variant: 'error',
                                                                            
                                                                        }
                                                                    );
                                                                } else {
                                                                    enqueueSnackbar(
                                                                        `${errorMessage}`,
                                                                        {
                                                                            variant: 'error',
                                                                            
                                                                        }
                                                                    );
                                                                }
                                                            });
                                                }}
                                            >
                                                Atualizar ficha nível 2
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )
                        }

                        {!location?.pathname.includes('edit') && 
                            (quizInfo?.nome == 'Checklist Denver Avaliação - Nível 4') &&
                            quizzes?.filter(function(item) { 
                                return item.nome.includes('PENDÊNCIAS - NÍVEL 3') && item.perguntas.length > 0 
                            }) && (
                                <Paper
                                    style={{ padding: '10px', marginBottom: '15px' }}
                                    square={false}
                                    elevation={3}
                                >
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent='center'
                                        alignItems={'center'}
                                    >
                                        <Grid item xs={10}>
                                            <Grid container spacing={2} alignItems={'center'}>
                                                <Grid item xs={12}>
                                                    {
                                                        quizzes?.filter(function(item) { 
                                                            return item.nome.includes('PENDÊNCIAS - NÍVEL 3') && item.perguntas.length > 0 
                                                        }).map((item) => (
                                                            <Accordion defaultExpanded>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls='panel1-content'
                                                                    id='panel1-header'
                                                                >
                                                                    {item?.nome}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        alignItems={'center'}
                                                                    >
                                                                        {item?.perguntas?.map(
                                                                            (pergunta) => (
                                                                                <Grid item xs={12}>
                                                                                    <FormControl
                                                                                        fullWidth
                                                                                    >
                                                                                        {!pergunta?.nome.includes(
                                                                                            'Observação'
                                                                                        ) &&
                                                                                        (!showTextFieldQuestId.includes(pergunta.questionario_id) ||
                                                                                            (
                                                                                                showTextFieldQuestId.includes(pergunta.questionario_id) &&
                                                                                                (
                                                                                                    pergunta.nome.includes('Reconhecimento de letras do alfabeto') ||
                                                                                                    pergunta.nome.includes('Nível de escrita')
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                        &&
                                                                                        !pergunta.nome.includes(
                                                                                            'Como Chegou'
                                                                                        ) &&
                                                                                        !pergunta.nome.includes(
                                                                                            'Como Estou'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Qual foi o lanche?'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a evolução'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a devolutiva'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a supervisão'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Descreva'
                                                                                        )&& 
                                                                                        !pergunta.nome.includes(
                                                                                            'Insira a informação'
                                                                                        ) ? (
                                                                                            <>
                                                                                                <InputLabel id='demo-simple-select-label'>
                                                                                                    {
                                                                                                        pergunta?.nome
                                                                                                    }
                                                                                                </InputLabel>
                                                                                                <Select
                                                                                                    labelId='demo-simple-select-label'
                                                                                                    id='demo-simple-select'
                                                                                                    value={getSelectedAnswer(
                                                                                                        pergunta?.Resposta,
                                                                                                        pergunta?.id
                                                                                                    )}
                                                                                                    label={
                                                                                                        pergunta?.nome
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setAnswers(
                                                                                                            (
                                                                                                                prevAnswers
                                                                                                            ) => {
                                                                                                                const userAnswered =
                                                                                                                    pergunta?.Resposta?.find(
                                                                                                                        (
                                                                                                                            resposta
                                                                                                                        ) =>
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.pergunta_id ===
                                                                                                                                pergunta?.id
                                                                                                                    );
                                                                                                                const newAnswer =
                                                                                                                    {
                                                                                                                        id: userAnswered
                                                                                                                            ?.Resposta_Usuario[0]
                                                                                                                            ?.id,
                                                                                                                        pergunta_id:
                                                                                                                            pergunta.id,
                                                                                                                        resposta_id:
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        data_horario_resposta:
                                                                                                                            userAnswered
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.data_horario_resposta ||
                                                                                                                            date,
                                                                                                                    };
                                                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                                const existingAnswerIndex =
                                                                                                                    prevAnswers.findIndex(
                                                                                                                        (
                                                                                                                            ans
                                                                                                                        ) =>
                                                                                                                            ans.pergunta_id ===
                                                                                                                            pergunta.id
                                                                                                                    );
                                                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                                if (
                                                                                                                    existingAnswerIndex !==
                                                                                                                    -1
                                                                                                                ) {
                                                                                                                    const updatedAnswers =
                                                                                                                        [
                                                                                                                            ...prevAnswers,
                                                                                                                        ];
                                                                                                                    updatedAnswers[
                                                                                                                        existingAnswerIndex
                                                                                                                    ] =
                                                                                                                        newAnswer;
                                                                                                                    return updatedAnswers;
                                                                                                                } else {
                                                                                                                    return [
                                                                                                                        ...prevAnswers,
                                                                                                                        newAnswer,
                                                                                                                    ];
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {pergunta?.Resposta?.map(
                                                                                                        (
                                                                                                            resposta
                                                                                                        ) => (
                                                                                                            <MenuItem
                                                                                                                value={
                                                                                                                    resposta?.id
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    RESPOSTAS_FORMATADAS[resposta?.resposta]
                                                                                                                }
                                                                                                                
                                                                                                            </MenuItem>
                                                                                                        )
                                                                                                    )}
                                                                                                </Select>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={12}
                                                                                            >
                                                                                                        
                                                                                                {showTextFieldQuestId.includes(pergunta.questionario_id) && 
                                                                                                    <FormLabel id='demo-simple-select-label'>
                                                                                                        {
                                                                                                            pergunta?.nome
                                                                                                        }
                                                                                                    </FormLabel>
                                                                                                }
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    id='outlined-multiline-static'
                                                                                                    multiline
                                                                                                    rows={
                                                                                                        4
                                                                                                    }
                                                                                                    label={
                                                                                                        showTextFieldQuestId.includes(pergunta.questionario_id)
                                                                                                        ? null
                                                                                                        : getLabel(pergunta.nome)
                                                                                                    }
                                                                                                    value={getObsAnswer(
                                                                                                        pergunta?.Resposta_Usuario,
                                                                                                        pergunta?.id
                                                                                                    )}
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setAnswers(
                                                                                                            (
                                                                                                                prevAnswers
                                                                                                            ) => {
                                                                                                                const userAnswered =
                                                                                                                    pergunta?.Resposta?.find(
                                                                                                                        (
                                                                                                                            resposta
                                                                                                                        ) =>
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            resposta
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.pergunta_id ===
                                                                                                                                pergunta?.id
                                                                                                                    );
                                                                                                                const newAnswer =
                                                                                                                    {
                                                                                                                        id: pergunta
                                                                                                                            ?.Resposta_Usuario[0]
                                                                                                                            ?.id,
                                                                                                                        pergunta_id:
                                                                                                                            pergunta.id,
                                                                                                                        observacao:
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        data_horario_resposta:
                                                                                                                            userAnswered
                                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                                ?.data_horario_resposta ||
                                                                                                                            date,
                                                                                                                    };
                                                                                                                // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                                const existingAnswerIndex =
                                                                                                                    prevAnswers.findIndex(
                                                                                                                        (
                                                                                                                            ans
                                                                                                                        ) =>
                                                                                                                            ans.pergunta_id ===
                                                                                                                            pergunta.id
                                                                                                                    );
                                                                                                                // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                                if (
                                                                                                                    existingAnswerIndex !==
                                                                                                                    -1
                                                                                                                ) {
                                                                                                                    const updatedAnswers =
                                                                                                                        [
                                                                                                                            ...prevAnswers,
                                                                                                                        ];
                                                                                                                    updatedAnswers[
                                                                                                                        existingAnswerIndex
                                                                                                                    ] =
                                                                                                                        newAnswer;
                                                                                                                    return updatedAnswers;
                                                                                                                } else {
                                                                                                                    return [
                                                                                                                        ...prevAnswers,
                                                                                                                        newAnswer,
                                                                                                                    ];
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            )
                                                                        )}
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))
                                                    }
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {location.pathname.includes('edit') && (
                                        <>
                                            <Divider
                                                style={{
                                                    height: '100%',
                                                    marginBottom: '20px',
                                                    marginTop: '20px',
                                                }}
                                            />
                                            <Grid
                                                container
                                                justifyContent='center'
                                                alignItems='center'
                                                rowSpacing={5}
                                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            >
                                                <Grid item xs={12}>
                                                    <Paper
                                                        sx={{
                                                            backgroundColor: '#f5f5f5',
                                                            borderRadius: '10px',
                                                            boxShadow: 'none',
                                                        }}
                                                    >
                                                        <Table sx={{ minWidth: 650 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align='center'>
                                                                        Código
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        Inclusão
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {' '}
                                                                        Colaborador Inclusão{' '}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        Alteração
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {' '}
                                                                        Colaborador Alteração{' '}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            { border: 0 },
                                                                    }}
                                                                >
                                                                    <TableCell align='center'>
                                                                        -
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {formatDateWithMoment(
                                                                            who?.data_horario_resposta
                                                                        ) ?? '-'}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {who?.Colaborador_Incluiu
                                                                            ?.nome ?? '-'}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {getCustomDateTimeUtc(
                                                                            whoUpdated?.updatedAt
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        {whoUpdated?.Colaborador_Alteracao
                                                                            ?.nome ?? '-'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Button
                                                variant='outlined'
                                                onClick={() => {
                                                        ApiService.put(
                                                            `/user-answered/user/${userId}/colaborator/${Number(
                                                                storedColaboratorId
                                                            )}`,
                                                            answers
                                                        )
                                                            .then((response) => {
                                                                enqueueSnackbar(
                                                                    'Ficha 3 atualizada com sucesso!',
                                                                    {
                                                                        variant: 'success',
                                                                        
                                                                    }
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                const errorMessage =
                                                                    error.response.data.message;
                                                                console.log(errorMessage);
                                                                if (Array.isArray(errorMessage)) {
                                                                    enqueueSnackbar(
                                                                        `${errorMessage[0]}`,
                                                                        {
                                                                            variant: 'error',
                                                                            
                                                                        }
                                                                    );
                                                                } else {
                                                                    enqueueSnackbar(
                                                                        `${errorMessage}`,
                                                                        {
                                                                            variant: 'error',
                                                                            
                                                                        }
                                                                    );
                                                                }
                                                            });
                                                }}
                                            >
                                                Atualizar ficha nível 3
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )
                        }


                        <Paper
                            style={{ padding: '10px', marginBottom: '15px' }}
                            square={false}
                            elevation={3}
                        >
                            <Divider
                                style={{
                                    height: '100%',
                                    marginBottom: '20px',
                                    marginTop: '20px',
                                }}
                            />
                            <Grid
                                container
                                spacing={2}
                                justifyContent='center'
                                alignItems={'center'}
                            >
                                <Grid item xs={10}>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs={12}>
                                            {quizzes?.filter(function(item) { return !item.nome.includes('PENDÊNCIAS') }).map((item) => (
                                                <Accordion defaultExpanded>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls='panel1-content'
                                                        id='panel1-header'
                                                    >
                                                        {item?.nome}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            alignItems={'center'}
                                                        >
                                                            {item?.perguntas?.map(
                                                                (pergunta) => (
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            fullWidth
                                                                        >
                                                                            {!pergunta?.nome.includes(
                                                                                'Observação'
                                                                            ) &&
                                                                            (!showTextFieldQuestId.includes(pergunta.questionario_id) ||
                                                                                (
                                                                                    showTextFieldQuestId.includes(pergunta.questionario_id) &&
                                                                                    (
                                                                                        pergunta.nome.includes('Reconhecimento de letras do alfabeto') ||
                                                                                        pergunta.nome.includes('Nível de escrita')
                                                                                    )
                                                                                )
                                                                            )
                                                                            &&
                                                                            !pergunta.nome.includes(
                                                                                'Como Chegou'
                                                                            ) &&
                                                                            !pergunta.nome.includes(
                                                                                'Como Estou'
                                                                            )&& 
                                                                            !pergunta.nome.includes(
                                                                                'Qual foi o lanche?'
                                                                            )&& 
                                                                            !pergunta.nome.includes(
                                                                                'Insira a evolução'
                                                                            )&& 
                                                                            !pergunta.nome.includes(
                                                                                'Insira a devolutiva'
                                                                            )&& 
                                                                            !pergunta.nome.includes(
                                                                                'Insira a supervisão'
                                                                            )&& 
                                                                            !pergunta.nome.includes(
                                                                                'Descreva'
                                                                            )&& 
                                                                            !pergunta.nome.includes(
                                                                                'Insira a informação'
                                                                            ) ? (
                                                                                <>
                                                                                    <InputLabel id='demo-simple-select-label'>
                                                                                        {
                                                                                            pergunta?.nome
                                                                                        }
                                                                                    </InputLabel>
                                                                                    <Select
                                                                                        labelId='demo-simple-select-label'
                                                                                        id='demo-simple-select'
                                                                                        value={getSelectedAnswer(
                                                                                            pergunta?.Resposta,
                                                                                            pergunta?.id
                                                                                        )}
                                                                                        label={
                                                                                            pergunta?.nome
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setAnswers(
                                                                                                (
                                                                                                    prevAnswers
                                                                                                ) => {
                                                                                                    const userAnswered =
                                                                                                        pergunta?.Resposta?.find(
                                                                                                            (
                                                                                                                resposta
                                                                                                            ) =>
                                                                                                                resposta
                                                                                                                    ?.Resposta_Usuario
                                                                                                                    ?.length >
                                                                                                                    0 &&
                                                                                                                resposta
                                                                                                                    ?.Resposta_Usuario[0]
                                                                                                                    ?.pergunta_id ===
                                                                                                                    pergunta?.id
                                                                                                        );
                                                                                                    const newAnswer =
                                                                                                        {
                                                                                                            id: userAnswered
                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                ?.id,
                                                                                                            pergunta_id:
                                                                                                                pergunta.id,
                                                                                                            resposta_id:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                            data_horario_resposta:
                                                                                                                userAnswered
                                                                                                                    ?.Resposta_Usuario[0]
                                                                                                                    ?.data_horario_resposta ||
                                                                                                                date,
                                                                                                        };
                                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                    const existingAnswerIndex =
                                                                                                        prevAnswers.findIndex(
                                                                                                            (
                                                                                                                ans
                                                                                                            ) =>
                                                                                                                ans.pergunta_id ===
                                                                                                                pergunta.id
                                                                                                        );
                                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                    if (
                                                                                                        existingAnswerIndex !==
                                                                                                        -1
                                                                                                    ) {
                                                                                                        const updatedAnswers =
                                                                                                            [
                                                                                                                ...prevAnswers,
                                                                                                            ];
                                                                                                        updatedAnswers[
                                                                                                            existingAnswerIndex
                                                                                                        ] =
                                                                                                            newAnswer;
                                                                                                        return updatedAnswers;
                                                                                                    } else {
                                                                                                        return [
                                                                                                            ...prevAnswers,
                                                                                                            newAnswer,
                                                                                                        ];
                                                                                                    }
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {pergunta?.Resposta?.map(
                                                                                            (
                                                                                                resposta
                                                                                            ) => (
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        resposta?.id
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        RESPOSTAS_FORMATADAS[resposta?.resposta]
                                                                                                    }
                                                                                                    
                                                                                                </MenuItem>
                                                                                            )
                                                                                        )}
                                                                                    </Select>
                                                                                </>
                                                                            ) : (
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                >
                                                                                            
                                                                                    {showTextFieldQuestId.includes(pergunta.questionario_id) && 
                                                                                        <FormLabel id='demo-simple-select-label'>
                                                                                            {
                                                                                                pergunta?.nome
                                                                                            }
                                                                                        </FormLabel>
                                                                                    }
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        id='outlined-multiline-static'
                                                                                        multiline
                                                                                        rows={
                                                                                            4
                                                                                        }
                                                                                        label={
                                                                                            showTextFieldQuestId.includes(pergunta.questionario_id)
                                                                                            ? null
                                                                                            : getLabel(pergunta.nome)
                                                                                        }
                                                                                        value={getObsAnswer(
                                                                                            pergunta?.Resposta_Usuario,
                                                                                            pergunta?.id
                                                                                        )}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setAnswers(
                                                                                                (
                                                                                                    prevAnswers
                                                                                                ) => {
                                                                                                    const userAnswered =
                                                                                                        pergunta?.Resposta?.find(
                                                                                                            (
                                                                                                                resposta
                                                                                                            ) =>
                                                                                                                resposta
                                                                                                                    ?.Resposta_Usuario
                                                                                                                    ?.length >
                                                                                                                    0 &&
                                                                                                                resposta
                                                                                                                    ?.Resposta_Usuario[0]
                                                                                                                    ?.pergunta_id ===
                                                                                                                    pergunta?.id
                                                                                                        );
                                                                                                    const newAnswer =
                                                                                                        {
                                                                                                            id: pergunta
                                                                                                                ?.Resposta_Usuario[0]
                                                                                                                ?.id,
                                                                                                            pergunta_id:
                                                                                                                pergunta.id,
                                                                                                            observacao:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                            data_horario_resposta:
                                                                                                                userAnswered
                                                                                                                    ?.Resposta_Usuario[0]
                                                                                                                    ?.data_horario_resposta ||
                                                                                                                date,
                                                                                                        };
                                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                                    const existingAnswerIndex =
                                                                                                        prevAnswers.findIndex(
                                                                                                            (
                                                                                                                ans
                                                                                                            ) =>
                                                                                                                ans.pergunta_id ===
                                                                                                                pergunta.id
                                                                                                        );
                                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                                    if (
                                                                                                        existingAnswerIndex !==
                                                                                                        -1
                                                                                                    ) {
                                                                                                        const updatedAnswers =
                                                                                                            [
                                                                                                                ...prevAnswers,
                                                                                                            ];
                                                                                                        updatedAnswers[
                                                                                                            existingAnswerIndex
                                                                                                        ] =
                                                                                                            newAnswer;
                                                                                                        return updatedAnswers;
                                                                                                    } else {
                                                                                                        return [
                                                                                                            ...prevAnswers,
                                                                                                            newAnswer,
                                                                                                        ];
                                                                                                    }
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {location.pathname.includes('edit') && (
                                <>
                                    <Divider
                                        style={{
                                            height: '100%',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                    />
                                    <Grid
                                        container
                                        justifyContent='center'
                                        alignItems='center'
                                        rowSpacing={5}
                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                    >
                                        <Grid item xs={12}>
                                            <Paper
                                                sx={{
                                                    backgroundColor: '#f5f5f5',
                                                    borderRadius: '10px',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <Table sx={{ minWidth: 650 }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align='center'>
                                                                Código
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                Inclusão
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {' '}
                                                                Colaborador Inclusão{' '}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                Alteração
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {' '}
                                                                Colaborador Alteração{' '}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                    { border: 0 },
                                                            }}
                                                        >
                                                            <TableCell align='center'>
                                                                -
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {formatDateWithMoment(
                                                                    who?.data_horario_resposta
                                                                ) ?? '-'}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {who?.Colaborador_Incluiu
                                                                    ?.nome ?? '-'}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {getCustomDateTimeUtc(
                                                                    whoUpdated?.updatedAt
                                                                )}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {whoUpdated?.Colaborador_Alteracao
                                                                    ?.nome ?? '-'}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Divider
                                style={{
                                    height: '100%',
                                    marginBottom: '20px',
                                    marginTop: '20px',
                                }}
                            />
                            <Grid container justifyContent='center' alignItems='center'>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            if (location?.pathname.includes('edit')) {
                                                console.log("id quem atualizadou", storedColaboratorId)
                                                ApiService.put(
                                                    `/user-answered/user/${userId}/colaborator/${Number(
                                                        storedColaboratorId
                                                    )}`,
                                                    answers
                                                )
                                                    .then((response) => {
                                                        enqueueSnackbar(
                                                            'Ficha criada com sucesso!',
                                                            {
                                                                variant: 'success',
                                                                
                                                            }
                                                        );
                                                        getQuizzesAnswers()
                                                    })
                                                    .catch((error) => {
                                                        const errorMessage =
                                                            error.response.data.message;
                                                        console.log(errorMessage);
                                                        if (Array.isArray(errorMessage)) {
                                                            enqueueSnackbar(
                                                                `${errorMessage[0]}`,
                                                                {
                                                                    variant: 'error',
                                                                    
                                                                }
                                                            );
                                                        } else {
                                                            enqueueSnackbar(
                                                                `${errorMessage}`,
                                                                {
                                                                    variant: 'error',
                                                                    
                                                                }
                                                            );
                                                        }
                                                    });
                                            } else {
                                                ApiService.post(
                                                    `/user-answered/user/${userId}/colaborator/${Number(
                                                        storedColaboratorId
                                                    )}`,
                                                    answers
                                                )
                                                    .then((response) => {
                                                        enqueueSnackbar(
                                                            'Ficha criada com sucesso!',
                                                            {
                                                                variant: 'success',
                                                                
                                                            }
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        const errorMessage =
                                                            error.response.data.message;
                                                        console.log(errorMessage);
                                                        if (Array.isArray(errorMessage)) {
                                                            enqueueSnackbar(
                                                                `${errorMessage[0]}`,
                                                                {
                                                                    variant: 'error',
                                                                    
                                                                }
                                                            );
                                                        } else {
                                                            enqueueSnackbar(
                                                                `${errorMessage}`,
                                                                {
                                                                    variant: 'error',
                                                                    
                                                                }
                                                            );
                                                        }
                                                    });
                                            }
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                )
            }
        </>
    );
};

export default AllQuizzes;
