import Chip from '@mui/material/Chip';
import DataTable from '../DataTable';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Box, Grid, OutlinedInput } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SearchIcon from '@mui/icons-material/Search';
import Div from '@jumbo/shared/Div';
import { DateRangePicker } from 'react-dates';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { formatDateWithMoment, getCustomDateTimeUtc } from '@jumbo/utils';

const LevelTwo = () => {
    const socialComunicaçao = [
        'Identifica e aponta partes do corpo em si e no outro',
        'Segue comandos de "para" ou "espera" sem ajuda ou gestos',
        'Segue Instrucoes verbais de darlapontar/mostrar',
        'Olha e aponta para imagens nomeadas em um livro',
        'Entende conceitos espaciais iniciais (entrar, sair, subir, descer)',
        'Atende Comando verbal para pegar objetos que nao estao diretamente a sua frente',
        'Atende comandos verbais para realizar açoes com objetos (ex: Mamadeira)',
        'Usa sinais elou gestos com vocalização para se expressar',
        'Participa vocalizando e participando de rotinas sociais sensoriais elou cancoes',
        'Divide brinquedos e jogos com o parceiro',
        'Realiza a Finalizaçao das brincadeiras /jogos (guarda, guarda .. )',
        'Verbaliza espontaneamente durante jogos e brincadeiras (joga a bola! 1,2,3 e ja! .. )',
        'Da significado de forma funcional para nomes e acoes com objetos',
        'Nomeia imagens e objetos de forma espontânea',
        'Verbaliza com diversas entonaçoes durante brincadeiras e cancoes',
        'Faz pedidos e recusas com palavras e contato visual',
        'Nomeia pessoas proximas',
        'Combina gestos e verbalizaçao para dizer "sim" ou "nao"',
        'Pergunta ou por aproximaçao "oque e isso?" quando se depara com algo desconhecido',
    ];
    const socialComportamento = [
        'Responde a "olha" alternando o olhar entre a pessoa e objeto virando-se para eles',
        'Da ou pega o objeto de outra pessoa alternando a o olhar entre eles',
        'Mostra os objetos de forma espontanea, entregando ao adulto',
        'Aponta para objetos de seu interesse',
        'Compartilha sorrisos e olhares durante brincadeiras',
        'Inicia e mantem contato visual',
        'Verbaliza pedidos',
        'Retribui comportamentos afetuosos (abracos, beijos)',
        'Dança com os outros em jogos de circulo ao som de musica (ex: Cirandinha)',
        'Corre em jogos de perseguiçao (pega-pega)',
        'Chama a atencao do outro chamando pelo nome',
        'Se ajusta a atividade em grupo de jogos ou canções',
        'Entende e atende comandos de "sua vez" e "minha vez" compartilhando com o parceiro',
        'Recebe os objetos quando oferecido',
        'Realiza jogos como memoria, loto, quebra cabecas sozinho ou com parceiro',
    ];
    const imitaçao = [
        'Imita o comportamento dos outro durante atividade',
        'Imita uma variacao de sons, vogais ou comandos realizando aproximaçoes verbais durante comunicacao"',
        'Imita sons de animais, ou outros sons em contexto com historia',
        'Imita acoes e verbalizacao durante canções',
        'Imita açoes fingindo em si ou no outro (ex: Médico) ',
        'Imita frases de duas palavras',
    ];
    const cogniçao = [
        'Corresponde/agrupa por forma, tamanhos ou outros semelhantes',
        'Pergunta e/ou procura por objetos desaparecidos',
        'Agrupa objetos em quantidade contando',
    ];
    const jogoLudico = [
        'Combina objetos de jogo (ex: xicara no pires, colher no prato)',
        'Realiza onomatopeias (sons ludicos) durante as brincadeiras (ex: Som dotelefone, relogio)',
        'Utiliza um objeto de forma usual (ex: roupa na boneca, pente para o cabelo)',
        'Realiza tentativa e erro para resolver problemas, sendo tentativas flexivas nao repetitivas',
        'Imita açoes fingindo em si ou no outro (ex: Medico)',
        'Imita frases de duas palavras',
    ];
    const jogoIndependente = [
        'Se mantem ocupado com materais/jogos simples de forma adequada com a orientacao ocasional de adulto',
        'Pega o material/brinquedo, traz para a mesa, completa a atividade do jogo, guarda e se afasta (começo, melo e fim)',
    ];
    const motricidade = [
        'Introduz pecas no jogo de encaixe',
        'Empilha blocos',
        'Junta/coloca blocos de montar (legos)',
        'Imita acoes simples com massinha (rolar, puxar, esticar)',
        'Abre e fecha uma variedade de tampas (incluindo roscas)',
        'Imita tracos, rabiscos e pontos em papel',
        'Corta com tesoura (movimento da tesoura com poleaar e indicador)',
        'Completa jogos de puzzles',
    ];

    const { enqueueSnackbar } = useSnackbar();
    const { quizId, userId } = useParams();
    const location = useLocation();

    const storedColaboratorId = localStorage.getItem('id');

    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [who, setWho] = useState([]);

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    const date = query.get('date');

    const getQuizzes = useCallback(async () => {
        try {
            await ApiService.get(`/quizzes/${quizId}`)
                .then((response) => {
                    console.log(response.data?.pergunta);

                    setQuizzes(response.data?.pergunta);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setQuizzes, quizId]);

    const getQuizzesAnswers = useCallback(async () => {
        try {
            await ApiService.get(
                `/quizzes/${quizId}/user/${userId}?date=${date}`
            )
                .then((response) => {
                    console.log(response.data);

                    setQuizzes(response.data?.userQuizz?.pergunta);
                    setWho(response.data?.findWhoCreatedAndUpdated);

                    /* setAnswers(respostasFormatadas); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [date, quizId, userId]);

    useEffect(() => {
        if (location?.pathname.includes('edit')) {
            getQuizzesAnswers();
        } else {
            getQuizzes();
        }
    }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

    console.log(answers);

    const getSelectedAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) =>
                resposta?.Resposta_Usuario?.length > 0 &&
                resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.id;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    const getObsAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) => resposta?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.observacao;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    return (
        <div>
            <HeaderBreadcrumbs
                title={'Ficha Diaria - NIVEL 2'}
                titleUrl={'/'}
            />
            <Paper
                style={{ padding: '10px', marginBottom: '15px' }}
                square={false}
                elevation={3}
            >
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignItems={'center'}
                >
                    <Grid item xs={10}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Social / Comunicação
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Social / Comunicação' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Social / Comportamento
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Social / Comportamento' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Imitação
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Imitação' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Cognição
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Cognição' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Jogo Lúdico
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Jogo Lúdico' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Jogo Independente
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Jogo Independente' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 2 - Motricidade Fina e Grossa
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 2 - Motricidade Fina e Grossa' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {location.pathname.includes('edit') && (
                    <>
                        <Divider
                            style={{
                                height: '100%',
                                marginBottom: '20px',
                                marginTop: '20px',
                            }}
                        />
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            rowSpacing={5}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '10px',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>
                                                    Código
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Inclusão
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Inclusão{' '}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Alteração
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Alteração{' '}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell align='center'>
                                                    -
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {formatDateWithMoment(
                                                        who?.data_horario_resposta
                                                    ) ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Incluiu
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {getCustomDateTimeUtc(
                                                        who?.updatedAt
                                                    )}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Alteracao
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                if (location?.pathname.includes('edit')) {
                                    ApiService.put(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                } else {
                                    ApiService.post(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                }
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default LevelTwo;
