import React, { useContext } from 'react';
import Stack from "@mui/material/Stack";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import SearchGlobal from "../../../../shared/SearchGlobal";
import {IconButton, Slide, useMediaQuery} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { Link } from 'react-router-dom';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { PermissionContext } from "app/contexts/PermissionContext";
import DateRangeIcon from '@mui/icons-material/DateRange';

const Header = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const [dropdownSearchVisibility, setDropdownSearchVisibility] = React.useState(false);
    const {headerTheme} = useJumboHeaderTheme();
    const { hasPermission } = useContext(PermissionContext);
    const showDropdownSearch = useMediaQuery('(max-width:575px)');

    return (
        <React.Fragment>
            {(
                sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
                sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
                (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
            ) && (
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{
                        ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                        mr: 3,
                    }}
                    onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
                >
                    {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
            )}
            {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />}
            <Stack direction="row" spacing={3} >
                {hasPermission('Agenda Horizontal', 'read') && (
                    <Link to="/schedule-worker" underline="none" color="inherit">
                        <Tooltip title="Agenda Horizontal" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <CalendarViewDayIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Agenda Vertical', 'read') && (
                    <Link to="/ScheduleVertical" underline="none" color="inherit">
                        <Tooltip title="Agenda Vertical" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <CalendarViewWeekIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {/* Criar permissão pra essa tela */}
                {hasPermission('Agenda Vertical', 'read') && (
                    <Link to="/ScheduleModel" underline="none" color="inherit">
                        <Tooltip title="Agenda modelo prestador" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <DateRangeIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Copiar Argumento', 'read') && (
                    <Link to="/ScheduleCopy" underline="none" color="inherit">
                        <Tooltip title="Cópia de agendamento" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <ContentCopyIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Pessoas', 'read') && (
                    <Link to="/people" underline="none" color="inherit">
                        <Tooltip title="Pessoa Completo" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <PersonIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Unificação', 'read') && (
                    <Link to="/people/unification" underline="none" color="inherit">
                        <Tooltip title="Unificação" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <GroupIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Registros de convênio', 'read') && (
                    <Link to="/NewAgreement" underline="none" color="inherit">
                        <Tooltip title="Registro de Convênio" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <MedicalInformationIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Consulta de Agendamentos', 'read') && (
                    <Link to="/schedule-query" underline="none" color="inherit">
                        <Tooltip title="Consulta de Agendamentos" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <PersonSearchIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {hasPermission('Atendimentos', 'read') && (
                    <Link to="/Atendimentos" underline="none" color="inherit">
                        <Tooltip title="Atendimentos" enterDelay={0}>
                            <IconButton edge="start" aria-label="link to page"  sx={{ backgroundColor: 'gray', '&:hover': { backgroundColor: '#e96690', transition: 'background-color 0.3s ease-in-out'} }}>
                                <ContactPhoneIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ ml: "auto" }}>
                <AuthUserDropdown />
            </Stack>
        </React.Fragment>
    );
};

export default Header;
