import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Modal from '@mui/material/Modal';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';

import { FieldArray, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import ApiService from 'app/services/config';
import DataTable from '../../DataTable';

import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { Checkbox, FormGroup } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AnamnesisItem } from 'app/components/AnamnesisItem/AnamnesisItem';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const FoodAnamnesisForm = ({
    values,
    infos,
    setFieldValue,
    foodAnamnesis,
    setFoodAnamnesis,
}) => {
    const { id, userId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const [expanded, setExpanded] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [foodAnamnesisSelected, setFoodAnamnesisSelected] = useState(null);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleEditAnamnesis = (anamnesisToEdit) => {
        console.log(anamnesisToEdit);
        setFoodAnamnesis(anamnesisToEdit);
        setIsEditMode(true);
    };

    const modalAlert = (anamnesisId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                removeAnamnesis(anamnesisId);
            }
        });
    };

    const removeAnamnesis = useCallback(
        async (anamnesisId) => {
            ApiService.delete(`/food-anamnesis/${anamnesisId}`)
                .then((response) => {
                    const anamnesisEdit = foodAnamnesis.id === anamnesisId;
                    const removedAnamnesis = values.Anamnese_Alimentar.filter(
                        (item) => item.id !== anamnesisId
                    );
                    setFieldValue('Anamnese_Alimentar', removedAnamnesis);
                    enqueueSnackbar('Anamnese removida com sucesso!', {
                        variant: 'success',
                        
                    });
                    setFoodAnamnesisSelected(null);
                    if (anamnesisEdit) {
                        setIsEditMode(false);
                        setFoodAnamnesis({
                            data: '',
                            historico_alimentar: [
                                {
                                    restricao: false,
                                    qual_restricao: '',
                                    via_alimentacao: '',
                                    qual_mista: '',
                                    apresenta_alergia: false,
                                    qual_alergia: '',
                                    apresenta_intolerancia: false,
                                    qual_intolerancia: '',
                                    problema_respiratorio_recorrente: false,
                                    qual_problema_respiratorio_recorrente: '',
                                    historico_rge: false,
                                    periodo_diagnostico_rge: '',
                                    tratamento_rge: false,
                                    outro_problema_gastrico_diagnosticado: false,
                                    qual_problema_gastrico_diagnosticado: '',
                                    uso_regular_medicacao: false,
                                    qual_medicacao: '',
                                    episodios_engasgo: false,
                                    detalhe_episodio_engasgo: '',
                                    episodio_regurgitamento: false,
                                    data_episodio_regurgitamento: '',
                                    processo_introducao_alimentar: '',
                                    texturas_aceitas: false,
                                    mastigacao_crianca: '',
                                    comentario: '',
                                    deglutinacao_crianca: '',
                                },
                            ],
                            comportamento_alimentar: [
                                {
                                    dificuldades_mastigar_alimentos: false,
                                    evita_comer_frutas: false,
                                    gofa_ou_vomita_apos_refeicao: false,
                                    come_grande_quantidade: false,
                                    engole_alimento_sem_mastigar_direito: false,
                                    evita_comer_vegetais_crus_ou_cozidos: false,
                                    sempre_usa_mesmos_utensilios: false,
                                    pega_comida_sem_permissao_fora_horario_refeicao: false,
                                    mastiga_boca_aberta: false,
                                    dificuldade_sentar_se_mesa: false,
                                    come_sempre_mesmo_lugar: false,
                                    pega_comida_outras_pessoas_sem_permissao: false,
                                    dificuldade_usar_talheres_utensilios: false,
                                    leva_objetos_estranhos_a_boca: false,
                                    quer_comer_alimentos_cores_semelhantes: false,
                                    ritual_rigido_comer: false,
                                    dificuldade_lateralizar_alimento_boca: false,
                                    derrama_muita_comida_mesa_roupa: false,
                                    quer_comer_sempre_mesmos_alimentos: false,
                                    tosse_durante_deglutinacao: false,
                                    inquietacao_agitacao_dificulta_sentar_se_mesa: false,
                                    vomita_durante_ou_logo_apos_refeicao: false,
                                    quer_comer_alimentos_mesma_marca_embalagem: false,
                                    tosse_seca_apos_alimentacao: false,
                                },
                            ],
                            habito_alimentar: [
                                {
                                    alimento_preferido_crianca: '',
                                    outros_comportamentos_relatar: '',
                                    tempo_aproximado_refeicao: '',
                                    local_refeicoes_filho: '',
                                    quem_escolhe_tipo_quantidade_alimento: '',
                                    uso_talheres: false,
                                    tipo_talher_uso: '',
                                    utiliza_mamadeira_chupeta: false,
                                    quantas_vezes_mamadeira_chupeta_dia: 0,
                                    chupa_dedo_bruxismo_roe_unha: false,
                                    qual_chupa_dedo_bruxismo_roe_unha: '',
                                    familiar_seletivo: false,
                                    qual_familiar_seletivo: '',
                                    importancia_alimentacao: '',
                                    familia_faz_refeicoes_juntas: '',
                                    problema_questoes_sensoriais: false,
                                    qual_problema_sensorial: '',
                                },
                            ],
                        });
                        setFoodAnamnesisSelected(null);
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [
            enqueueSnackbar,
            setFieldValue,
            values.Anamnese_Alimentar,
            foodAnamnesis,
            setFoodAnamnesis,
        ]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AnamnesisItem
                    anamnesis={values.Anamnese_Alimentar}
                    handleEditAnamnesis={handleEditAnamnesis}
                    modalAlert={modalAlert}
                    anamnesisSelected={foodAnamnesisSelected}
                    setAnamnesisSelected={setFoodAnamnesisSelected}
                />
            </Grid>
            <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleAccordionChange('panel8')}
            >
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls='panel1-content'
                    id='panel1-header'
                >
                    <Typography>
                        <span
                            style={{
                                verticalAlign: 'middle',
                                paddingRight: '10px',
                            }}
                        ></span>
                        Alimentação
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        Container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                id='standard-basic'
                                label='Data'
                                variant='outlined'
                                type='date'
                                InputLabelProps={{ shrink: true }}
                                value={foodAnamnesis?.data}
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        data: event.target.value,
                                    });
                                }}
                                sx={{ width: '23%' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Restrição
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.restricao
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    restricao: JSON.parse(
                                                        event.target.value
                                                    ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual Restrição?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_restricao
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_restricao:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Via Alimentação
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.via_alimentacao
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    via_alimentacao:
                                                        event.target.value,
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={'VO'}
                                        control={<Radio />}
                                        label='VO'
                                    />
                                    <FormControlLabel
                                        value='SNE'
                                        control={<Radio />}
                                        label='SNE'
                                    />
                                    <FormControlLabel
                                        value='SNG'
                                        control={<Radio />}
                                        label='SNG'
                                    />
                                    <FormControlLabel
                                        value='Mista'
                                        control={<Radio />}
                                        label='Mista'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual Mista?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_mista
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_mista: event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Apresenta Alergias?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.apresenta_alergia
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    apresenta_alergia:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual alergia?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_alergia
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_alergia:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Apresenta Intolerância?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.apresenta_intolerancia
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    apresenta_intolerancia:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual intolerância?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_intolerancia
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_intolerancia:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Tem Problemas Respiratorios Recorrentes?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.problema_respiratorio_recorrente
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    problema_respiratorio_recorrente:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quais?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_problema_respiratorio_recorrente
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_problema_respiratorio_recorrente:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Histórico de RGE
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.historico_rge
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    historico_rge: JSON.parse(
                                                        event.target.value
                                                    ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Período do Diagnóstico'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.periodo_diagnostico_rge
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                periodo_diagnostico_rge:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Tratamento RGE
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.tratamento_rge
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    tratamento_rge: JSON.parse(
                                                        event.target.value
                                                    ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Outro Problema Gástrico Diagnosticado?{' '}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.outro_problema_gastrico_diagnosticado
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    outro_problema_gastrico_diagnosticado:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_problema_gastrico_diagnosticado
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_problema_gastrico_diagnosticado:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Uso Regular de Medicação?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.uso_regular_medicacao
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    uso_regular_medicacao:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual medicação?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.qual_medicacao
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                qual_medicacao:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Há Episódio de Engasgos?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.episodios_engasgo
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    episodios_engasgo:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Detalhe:'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.detalhe_episodio_engasgo
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                detalhe_episodio_engasgo:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Há Episódio de Regurgitamento
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.episodio_regurgitamento
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    episodio_regurgitamento:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quando?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.data_episodio_regurgitamento
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                data_episodio_regurgitamento:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='observacao'
                                label='Como Foi o Processo de Introdução Alimentar'
                                multiline
                                rows={4}
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.processo_introducao_alimentar
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                processo_introducao_alimentar:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Texturas Aceitas?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.texturas_aceitas
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    texturas_aceitas:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Como e a Matigação da Criança
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.historico_alimentar[0]
                                            ?.mastigacao_crianca
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            historico_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .historico_alimentar[0],
                                                    mastigacao_crianca:
                                                        event.target.value,
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value='rapida'
                                        control={<Radio />}
                                        label='Rapida'
                                    />
                                    <FormControlLabel
                                        value='lenta'
                                        control={<Radio />}
                                        label='Lenta'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Comente'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.comentario
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                comentario: event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Como e a deglutinacao da crianca? Engasga com facilidade. Emite som ao engolir? Faz movimentos de cabeca associados?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.historico_alimentar[0]
                                        ?.deglutinacao_crianca
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        historico_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .historico_alimentar[0],
                                                deglutinacao_crianca:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormGroup>
                                <FormLabel component='legend'>
                                    Qual desses comportamentos seu filho
                                    apresenta durante a alimentacao hoje?
                                </FormLabel>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.dificuldades_mastigar_alimentos
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        dificuldades_mastigar_alimentos:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Dificuldades para mastigar os alimentos'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.evita_comer_frutas
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        evita_comer_frutas:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Evita comer frutas'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.gofa_ou_vomita_apos_refeicao
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        gofa_ou_vomita_apos_refeicao:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Gofa ou vomita imediatamente apos a refeiçao'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.come_grande_quantidade
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        come_grande_quantidade:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Come grande quantidade em curto espaco de tempo'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.engole_alimento_sem_mastigar_direito
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        engole_alimento_sem_mastigar_direito:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Engole alimento sem mastigar direito'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.evita_comer_vegetais_crus_ou_cozidos
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        evita_comer_vegetais_crus_ou_cozidos:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Evita comer vegetais crus e/ou cozidos'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.sempre_usa_mesmos_utensilios
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        sempre_usa_mesmos_utensilios:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Sempre usa os mesmos utensilios'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.pega_comida_sem_permissao_fora_horario_refeicao
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        pega_comida_sem_permissao_fora_horario_refeicao:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Pega comida sem permissão e fora do horario da refeiçao'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.mastiga_boca_aberta
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        mastiga_boca_aberta:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Mastiga com a boca aberta'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.dificuldade_sentar_se_mesa
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        dificuldade_sentar_se_mesa:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Dificuldade para sentar-se a mesa'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.come_sempre_mesmo_lugar
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        come_sempre_mesmo_lugar:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Come sempre no mesmo lugar'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.pega_comida_outras_pessoas_sem_permissao
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        pega_comida_outras_pessoas_sem_permissao:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Sem permissao, pega comida de outras pessoas durante a refeiçao'
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.dificuldade_usar_talheres_utensilios
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        dificuldade_usar_talheres_utensilios:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Dificuldade para usar talheres e outros utensilios'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.leva_objetos_estranhos_a_boca
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        leva_objetos_estranhos_a_boca:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Leva objetos estranhos a boca'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.quer_comer_alimentos_cores_semelhantes
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        quer_comer_alimentos_cores_semelhantes:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Quer comer alimentos de cores semelhantes'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.ritual_rigido_comer
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        ritual_rigido_comer:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Ritual rigido para comer'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.dificuldade_lateralizar_alimento_boca
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        dificuldade_lateralizar_alimento_boca:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Dificuldade para lateralizar o alimento na boca'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.derrama_muita_comida_mesa_roupa
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        derrama_muita_comida_mesa_roupa:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Derrama muita comida na mesa/roupa'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.quer_comer_sempre_mesmos_alimentos
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        quer_comer_sempre_mesmos_alimentos:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Quer comer sempre os mesmos alimentos'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.tosse_durante_deglutinacao
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        tosse_durante_deglutinacao:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Apresenta tosse durante deglutinaçao'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.inquietacao_agitacao_dificulta_sentar_se_mesa
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        inquietacao_agitacao_dificulta_sentar_se_mesa:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Possui iquietacao/agitacao que dificulta sentar-se a mesa'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.vomita_durante_ou_logo_apos_refeicao
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        vomita_durante_ou_logo_apos_refeicao:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Vomita durante ou logo apos a refeiçao'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.quer_comer_alimentos_mesma_marca_embalagem
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        quer_comer_alimentos_mesma_marca_embalagem:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Quer comer alimentos de mesma marca ou embalagem'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        foodAnamnesis
                                                            ?.comportamento_alimentar[0]
                                                            ?.tosse_seca_apos_alimentacao
                                                    }
                                                    onChange={(event) => {
                                                        setFoodAnamnesis({
                                                            ...foodAnamnesis,
                                                            comportamento_alimentar:
                                                                [
                                                                    {
                                                                        ...foodAnamnesis
                                                                            .comportamento_alimentar[0],
                                                                        tosse_seca_apos_alimentacao:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                        });
                                                    }}
                                                />
                                            }
                                            label='Apresenta tosse seca apos a alimentaçao'
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual Alimento Preferido da Criança?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.alimento_preferido_crianca
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                alimento_preferido_crianca:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='observacao'
                                label='Outros Comportamentos que Queira Relatar'
                                multiline
                                rows={4}
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.outros_comportamentos_relatar
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                outros_comportamentos_relatar:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual tempo aproximado gasta em uma refeiçao?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.tempo_aproximado_refeicao
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                tempo_aproximado_refeicao:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Onde sao feitas as refeições do seu filho?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.local_refeicoes_filho
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                local_refeicoes_filho:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quem escolhe o tipo e quantidade de alimento que seu filho come?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.quem_escolhe_tipo_quantidade_alimento
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                quem_escolhe_tipo_quantidade_alimento:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        {/*  <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quem escolhe o tipo e quantidade de alimento que seu filho come?'
                                variant='outlined'
                            />
                        </Grid> */}

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Faz uso de talheres?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.habito_alimentar[0]
                                            ?.uso_talheres
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            habito_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .habito_alimentar[0],
                                                    uso_talheres: JSON.parse(
                                                        event.target.value
                                                    ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quais?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.tipo_talher_uso
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                tipo_talher_uso:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Utiliza mamadeira ou chupeta?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.habito_alimentar[0]
                                            ?.utiliza_mamadeira_chupeta
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            habito_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .habito_alimentar[0],
                                                    utiliza_mamadeira_chupeta:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quanto Tempo por dia?'
                                variant='outlined'
                                type='number'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.quantas_vezes_mamadeira_chupeta_dia
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                quantas_vezes_mamadeira_chupeta_dia:
                                                    JSON.parse(
                                                        event.target.value
                                                    ),
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Chupa dedo, tem bruxismo ou roe unha?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.habito_alimentar[0]
                                            ?.chupa_dedo_bruxismo_roe_unha
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            habito_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .habito_alimentar[0],
                                                    chupa_dedo_bruxismo_roe_unha:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.qual_chupa_dedo_bruxismo_roe_unha
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                qual_chupa_dedo_bruxismo_roe_unha:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Ha algum familiar seletivo?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.habito_alimentar[0]
                                            ?.familiar_seletivo
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            habito_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .habito_alimentar[0],
                                                    familiar_seletivo:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Quem?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.qual_familiar_seletivo
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                qual_familiar_seletivo:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual a importancia da alimentacao para voce e porque gostaria que seu filho comesse bem?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.importancia_alimentacao
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                importancia_alimentacao:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='A familia faz as refeiçoes juntas? Como e esse momento para voces?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.familia_faz_refeicoes_juntas
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                familia_faz_refeicoes_juntas:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <FormControl>
                                <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Ha algum problema com questoes sensoriais?
                                    Ex: escovar os dentes, pentear o cabelo, se
                                    sujar, tintas, areia, grama ou
                                    hipersensibilidade auditiva?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={
                                        foodAnamnesis?.habito_alimentar[0]
                                            ?.problema_questoes_sensoriais
                                    }
                                    onChange={(event) => {
                                        setFoodAnamnesis({
                                            ...foodAnamnesis,
                                            habito_alimentar: [
                                                {
                                                    ...foodAnamnesis
                                                        .habito_alimentar[0],
                                                    problema_questoes_sensoriais:
                                                        JSON.parse(
                                                            event.target.value
                                                        ),
                                                },
                                            ],
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Sim'
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Não'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id='outlined-basic'
                                label='Qual?'
                                variant='outlined'
                                value={
                                    foodAnamnesis?.habito_alimentar[0]
                                        ?.qual_problema_sensorial
                                }
                                onChange={(event) => {
                                    setFoodAnamnesis({
                                        ...foodAnamnesis,
                                        habito_alimentar: [
                                            {
                                                ...foodAnamnesis
                                                    .habito_alimentar[0],
                                                qual_problema_sensorial:
                                                    event.target.value,
                                            },
                                        ],
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid item xs={12} md={6}>
                <Button
                    variant='outlined'
                    endIcon={<SendIcon />}
                    onClick={() => {
                        if (isEditMode) {
                            const anamnesisId = foodAnamnesis.id;
                            console.log(foodAnamnesis);
                            const userAnamnesisEdit = {
                                ...foodAnamnesis,

                                historico_alimentar: [
                                    {
                                        ...foodAnamnesis
                                            ?.historico_alimentar[0],
                                        anamnese_alimentar_id: undefined,
                                    },
                                ],
                                comportamento_alimentar: [
                                    {
                                        ...foodAnamnesis
                                            ?.comportamento_alimentar[0],
                                        anamnese_alimentar_id: undefined,
                                    },
                                ],
                                habito_alimentar: [
                                    {
                                        ...foodAnamnesis?.habito_alimentar[0],
                                        anamnese_alimentar_id: undefined,
                                    },
                                ],
                            };
                            console.log(userAnamnesisEdit);

                            ApiService.put(
                                `/food-anamnesis/${anamnesisId}`,
                                userAnamnesisEdit
                            )
                                .then((response) => {
                                    console.log(response.data);
                                    enqueueSnackbar(
                                        'Anamnese atualizada com sucesso!',
                                        {
                                            variant: 'success',
                                            
                                        }
                                    );
                                    const updatedTodos =
                                        values.Anamnese_Alimentar.map(
                                            (todo) => {
                                                if (
                                                    todo.id === response.data.id
                                                ) {
                                                    return response.data; // Retorna a anamnese atualizado
                                                }
                                                return todo;
                                            }
                                        );
                                    setFieldValue(
                                        'Anamnese_Alimentar',
                                        updatedTodos
                                    ); // Atualiza a lista de anamnese com o endereço atualizado
                                    setIsEditMode(false); // Sai do modo de edição
                                    setFoodAnamnesis({
                                        // Limpa o estado do anamnese
                                        data: '',
                                        historico_alimentar: [
                                            {
                                                restricao: false,
                                                qual_restricao: '',
                                                via_alimentacao: '',
                                                qual_mista: '',
                                                apresenta_alergia: false,
                                                qual_alergia: '',
                                                apresenta_intolerancia: false,
                                                qual_intolerancia: '',
                                                problema_respiratorio_recorrente: false,
                                                qual_problema_respiratorio_recorrente:
                                                    '',
                                                historico_rge: false,
                                                periodo_diagnostico_rge: '',
                                                tratamento_rge: false,
                                                outro_problema_gastrico_diagnosticado: false,
                                                qual_problema_gastrico_diagnosticado:
                                                    '',
                                                uso_regular_medicacao: false,
                                                qual_medicacao: '',
                                                episodios_engasgo: false,
                                                detalhe_episodio_engasgo: '',
                                                episodio_regurgitamento: false,
                                                data_episodio_regurgitamento:
                                                    '',
                                                processo_introducao_alimentar:
                                                    '',
                                                texturas_aceitas: false,
                                                mastigacao_crianca: '',
                                                comentario: '',
                                                deglutinacao_crianca: '',
                                            },
                                        ],
                                        comportamento_alimentar: [
                                            {
                                                dificuldades_mastigar_alimentos: false,
                                                evita_comer_frutas: false,
                                                gofa_ou_vomita_apos_refeicao: false,
                                                come_grande_quantidade: false,
                                                engole_alimento_sem_mastigar_direito: false,
                                                evita_comer_vegetais_crus_ou_cozidos: false,
                                                sempre_usa_mesmos_utensilios: false,
                                                pega_comida_sem_permissao_fora_horario_refeicao: false,
                                                mastiga_boca_aberta: false,
                                                dificuldade_sentar_se_mesa: false,
                                                come_sempre_mesmo_lugar: false,
                                                pega_comida_outras_pessoas_sem_permissao: false,
                                                dificuldade_usar_talheres_utensilios: false,
                                                leva_objetos_estranhos_a_boca: false,
                                                quer_comer_alimentos_cores_semelhantes: false,
                                                ritual_rigido_comer: false,
                                                dificuldade_lateralizar_alimento_boca: false,
                                                derrama_muita_comida_mesa_roupa: false,
                                                quer_comer_sempre_mesmos_alimentos: false,
                                                tosse_durante_deglutinacao: false,
                                                inquietacao_agitacao_dificulta_sentar_se_mesa: false,
                                                vomita_durante_ou_logo_apos_refeicao: false,
                                                quer_comer_alimentos_mesma_marca_embalagem: false,
                                                tosse_seca_apos_alimentacao: false,
                                            },
                                        ],
                                        habito_alimentar: [
                                            {
                                                alimento_preferido_crianca: '',
                                                outros_comportamentos_relatar:
                                                    '',
                                                tempo_aproximado_refeicao: '',
                                                local_refeicoes_filho: '',
                                                quem_escolhe_tipo_quantidade_alimento:
                                                    '',
                                                uso_talheres: false,
                                                tipo_talher_uso: '',
                                                utiliza_mamadeira_chupeta: false,
                                                quantas_vezes_mamadeira_chupeta_dia: 0,
                                                chupa_dedo_bruxismo_roe_unha: false,
                                                qual_chupa_dedo_bruxismo_roe_unha:
                                                    '',
                                                familiar_seletivo: false,
                                                qual_familiar_seletivo: '',
                                                importancia_alimentacao: '',
                                                familia_faz_refeicoes_juntas:
                                                    '',
                                                problema_questoes_sensoriais: false,
                                                qual_problema_sensorial: '',
                                            },
                                        ],
                                    });
                                    setFoodAnamnesisSelected(null);
                                })
                                .catch((error) => {
                                    const errorMessage =
                                        error.response.data.message;
                                    console.log(errorMessage);
                                    if (Array.isArray(errorMessage)) {
                                        enqueueSnackbar(`${errorMessage[0]}`, {
                                            variant: 'error',
                                            
                                        });
                                    } else {
                                        enqueueSnackbar(`${errorMessage}`, {
                                            variant: 'error',
                                            
                                        });
                                    }
                                });
                        } else {
                            const userAnamnesis = {
                                user_id: Number(userId),
                                data: foodAnamnesis.data,
                                historico_alimentar: [
                                    {
                                        ...foodAnamnesis.historico_alimentar[0],
                                    },
                                ],
                                comportamento_alimentar: [
                                    {
                                        ...foodAnamnesis
                                            .comportamento_alimentar[0],
                                    },
                                ],
                                habito_alimentar: [
                                    {
                                        ...foodAnamnesis.habito_alimentar[0],
                                    },
                                ],
                            };
                            console.log(userAnamnesis);

                            ApiService.post(`/food-anamnesis`, userAnamnesis)
                                .then((response) => {
                                    console.log(response);
                                    const newAnamnese = [
                                        ...values.Anamnese_Alimentar,
                                        response.data,
                                    ];
                                    enqueueSnackbar(
                                        'Anamnese criada com sucesso!',
                                        {
                                            variant: 'success',
                                            
                                        }
                                    );
                                    setFieldValue(
                                        'Anamnese_Alimentar',
                                        newAnamnese
                                    );
                                    setFoodAnamnesis({
                                        data: '',
                                        historico_alimentar: [
                                            {
                                                restricao: false,
                                                qual_restricao: '',
                                                via_alimentacao: '',
                                                qual_mista: '',
                                                apresenta_alergia: false,
                                                qual_alergia: '',
                                                apresenta_intolerancia: false,
                                                qual_intolerancia: '',
                                                problema_respiratorio_recorrente: false,
                                                qual_problema_respiratorio_recorrente:
                                                    '',
                                                historico_rge: false,
                                                periodo_diagnostico_rge: '',
                                                tratamento_rge: false,
                                                outro_problema_gastrico_diagnosticado: false,
                                                qual_problema_gastrico_diagnosticado:
                                                    '',
                                                uso_regular_medicacao: false,
                                                qual_medicacao: '',
                                                episodios_engasgo: false,
                                                detalhe_episodio_engasgo: '',
                                                episodio_regurgitamento: false,
                                                data_episodio_regurgitamento:
                                                    '',
                                                processo_introducao_alimentar:
                                                    '',
                                                texturas_aceitas: false,
                                                mastigacao_crianca: '',
                                                comentario: '',
                                                deglutinacao_crianca: '',
                                            },
                                        ],
                                        comportamento_alimentar: [
                                            {
                                                dificuldades_mastigar_alimentos: false,
                                                evita_comer_frutas: false,
                                                gofa_ou_vomita_apos_refeicao: false,
                                                come_grande_quantidade: false,
                                                engole_alimento_sem_mastigar_direito: false,
                                                evita_comer_vegetais_crus_ou_cozidos: false,
                                                sempre_usa_mesmos_utensilios: false,
                                                pega_comida_sem_permissao_fora_horario_refeicao: false,
                                                mastiga_boca_aberta: false,
                                                dificuldade_sentar_se_mesa: false,
                                                come_sempre_mesmo_lugar: false,
                                                pega_comida_outras_pessoas_sem_permissao: false,
                                                dificuldade_usar_talheres_utensilios: false,
                                                leva_objetos_estranhos_a_boca: false,
                                                quer_comer_alimentos_cores_semelhantes: false,
                                                ritual_rigido_comer: false,
                                                dificuldade_lateralizar_alimento_boca: false,
                                                derrama_muita_comida_mesa_roupa: false,
                                                quer_comer_sempre_mesmos_alimentos: false,
                                                tosse_durante_deglutinacao: false,
                                                inquietacao_agitacao_dificulta_sentar_se_mesa: false,
                                                vomita_durante_ou_logo_apos_refeicao: false,
                                                quer_comer_alimentos_mesma_marca_embalagem: false,
                                                tosse_seca_apos_alimentacao: false,
                                            },
                                        ],
                                        habito_alimentar: [
                                            {
                                                alimento_preferido_crianca: '',
                                                outros_comportamentos_relatar:
                                                    '',
                                                tempo_aproximado_refeicao: '',
                                                local_refeicoes_filho: '',
                                                quem_escolhe_tipo_quantidade_alimento:
                                                    '',
                                                uso_talheres: false,
                                                tipo_talher_uso: '',
                                                utiliza_mamadeira_chupeta: false,
                                                quantas_vezes_mamadeira_chupeta_dia: 0,
                                                chupa_dedo_bruxismo_roe_unha: false,
                                                qual_chupa_dedo_bruxismo_roe_unha:
                                                    '',
                                                familiar_seletivo: false,
                                                qual_familiar_seletivo: '',
                                                importancia_alimentacao: '',
                                                familia_faz_refeicoes_juntas:
                                                    '',
                                                problema_questoes_sensoriais: false,
                                                qual_problema_sensorial: '',
                                            },
                                        ],
                                    });
                                    setFoodAnamnesisSelected(null);
                                })
                                .catch((error) => {
                                    const errorMessage =
                                        error.response.data.message;
                                    console.log(errorMessage);
                                    if (Array.isArray(errorMessage)) {
                                        enqueueSnackbar(`${errorMessage[0]}`, {
                                            variant: 'error',
                                            
                                        });
                                    } else {
                                        enqueueSnackbar(`${errorMessage}`, {
                                            variant: 'error',
                                            
                                        });
                                    }
                                });
                        }
                    }}
                >
                    {isEditMode ? 'Salvar' : 'Salvar'}
                </Button>
            </Grid>
        </Grid>
    );
};

export default FoodAnamnesisForm;
