import moment from 'moment';
import { USE_IMAGE_PLACEHOLDERS } from './constants/paths';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

/* export const updateInputValue = (user, values, data) => {
    console.log(user)
    console.log(values)
    console.log(data)
    return user && (user[data] == values[data]) ? user[data] || '' : values[data] || '';
}; */

export const updateInputValue = (user, values, data, section) => {
    // Verifica se a seção (Pessoa ou Enderecos) está presente no objeto 'values'
    if (
        values &&
        values[section] &&
        user &&
        user[section] &&
        user[section][data] === values[section][data]
    ) {
        return user[section][data] || '';
    } else {
        return values[section][data] || '';
    }
};

export const generatePdf = async (
    hours,
    pacient,
    day,
    entryTime,
    issuer
) => {
    const loadImageAsBase64 = (url) => {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
                .catch(reject);
        });
    };

    const dayFormat = moment(day).format('DD/MM/YYYY');
    const currentDate = moment().format('DD/MM/YYYY - HH:mm');
    /* const currentTime = moment().format('HH:mm'); */
    const exitTime = moment(entryTime, 'HH:mm')
        .add(hours, 'hours')
        .format('HH:mm');

    const logoBase64 = await loadImageAsBase64('/images/logo_mae_coruja.png');

    const documentDefinition = {
        content: [
            {
                columns: [
                    [
                        {
                            image: logoBase64,
                            width: 80,
                            style: 'spacing',
                        },
                        {
                            text: [
                                {
                                    text: 'Impresso: ',
                                    bold: true,
                                },
                                `${currentDate}`,
                            ],
                            style: 'small',
                            alignment: 'left',
                        },
                    ],
                    {
                        text: 'ESPAÇO MÃE CORUJA',
                        style: 'header',
                        alignment: 'center',
                    },
                    [
                        { text: '\n\n\n\n\n\n\n' },
                        {
                            text: [
                                {
                                    text: 'Emitente: ',
                                    bold: true,
                                },
                                `${issuer}`,
                            ],
                            style: 'small',
                            alignment: 'right',
                        },
                    ],
                ],
            },
            { text: '\n\n' },
            {
                text: 'DECLARAÇÃO DE COMPARECIMENTO',
                style: 'title',
                alignment: 'center',
            },
            { text: '\n\n' },
            {
                text: `Para fim de comprovação de motivo, de alteração no horário de trabalho, declaro que ${pacient} esteve em atendimento no dia ${dayFormat} no horário das ${entryTime} hrs às ${exitTime} hrs.`,
                style: 'content',
                alignment: 'justify',
            },
            { text: '\n\n\n\n' },
            { text: '_________________________', alignment: 'center' },
            { text: 'Assinatura', alignment: 'center' },
        ],
        styles: {
            header: {
                fontSize: 12,
                bold: true,
                marginBottom: 15,
            },
            title: {
                fontSize: 16,
                bold: true,
                marginBottom: 15,
            },
            content: {
                fontSize: 12,
                marginBottom: 15,
            },
            small: {
                fontSize: 10,
            },
            spacing: {
                marginBottom: 7,
            },
        },
    };

    pdfMake
        .createPdf(documentDefinition)
        .download(`declaracao_de_comparacimento_de_${hours}h.pdf`);
};
