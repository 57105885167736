import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SendIcon from "@mui/icons-material/Send";
import AnamnesisMenu from "app/components/AnamnesisMenu/AnamnesisMenu";
import AnamnesisQuestion from "app/components/AnamnesisQuestion/AnamnesisQuestion";
import { Formik } from "formik";

const TeensAnamnesisForm = ({ nestedTab, questionaryId }) => {
  const { id, userId } = useParams();
  const QUESTIONARY_ID = questionaryId || 58; // ID DO QUESTIONÁRIO DE ANAMNESE ADOLESCENTE
  const { enqueueSnackbar } = useSnackbar();
  const storedColaboratorId = localStorage.getItem("id");

  const [anamnesisSelected, setAnamnesisSelected] = useState(null);
  const [anamnesis, setAnamnesis] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(new Map());
  const [isLoading, setIsLoading] = useState(false);


  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleGetAnamnesis = async () => {
    try {
      await ApiService.get(
        `/user-answered/anamnese/distinct/date/${userId}/${QUESTIONARY_ID}`
      ).then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          setAnamnesis(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetQuestions = async () => {
    try {
      setIsLoading(true);
      await ApiService.get(`/quizzes/quiz/questions/${QUESTIONARY_ID}`).then(
        (response) => {
          if (response.status === 200) {
            setQuestions(response.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Erro ao obter o questionário", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetQuestions();
    setAnswers(new Map());
  }, [QUESTIONARY_ID, questionaryId, nestedTab, isFormSubmitted]);
  return (
    <>
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center">
          <Stack
            direction="column"
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress color="primary" />
            <Typography>Carregando...</Typography>
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid container rowSpacing={2}>
            <AnamnesisMenu
              anamnesisSelected={anamnesisSelected}
              setAnamnesisSelected={setAnamnesisSelected}
              idQuestionario={QUESTIONARY_ID}
              userId={userId}
              setIsEditMode={setIsEditMode}
              handleGetAnamnesis={handleGetAnamnesis}
              answers={answers}
              setAnswers={setAnswers}
              anamnesis={anamnesis}
              setAnamnesis={setAnamnesis}
              isFormSubmitted={isFormSubmitted}
              setIsFormSubmitted={setIsFormSubmitted}
            />
            {questions &&
              questions?.secoes?.map((secao, index) => (
                <Grid item xs={12}>
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography>{secao?.nome}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {secao?.Subsecao?.length === 0 ? (
                        <>
                          {secao?.perguntas?.map((pergunta) => (
                            <AnamnesisQuestion
                              nome={pergunta?.nome}
                              idPergunta={pergunta.id}
                              respostas={pergunta?.Resposta}
                              answers={answers}
                              setAnswers={setAnswers}
                              isFormSubmitted={isFormSubmitted}
                              isEditMode={isEditMode}
                              anamnesisSelected={anamnesisSelected}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {secao?.Subsecao?.map((subsecao) =>
                            subsecao.perguntas?.map((pergunta) => (
                              <AnamnesisQuestion
                                nome={pergunta?.nome}
                                idPergunta={pergunta.id}
                                respostas={pergunta?.Resposta}
                                answers={answers}
                                setAnswers={setAnswers}
                                isFormSubmitted={isFormSubmitted}
                                isEditMode={isEditMode}
                                anamnesisSelected={anamnesisSelected}
                              />
                            ))
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            my={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="outlined"
              endIcon={<SendIcon />}
              onClick={() => {
                answers.delete(undefined);
                const arrayRespostas = answers.values().toArray();
                if (isEditMode) {
                  ApiService.put(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    arrayRespostas
                  )
                    .then((response) => {
                      if (response.status === 200) {
                        enqueueSnackbar("Anamnese atualizada com sucesso!", {
                          variant: "success",
                        });
                        setAnamnesisSelected(null);
                        setIsEditMode(false);
                        setIsFormSubmitted(!isFormSubmitted);
                        setAnswers(new Map());
                      }
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    arrayRespostas
                  )
                    .then((response) => {
                      if (response.status === 201) {
                        enqueueSnackbar("Anamnese registrada com sucesso!", {
                          variant: "success",
                        });
                        setAnswers(new Map());
                        setIsFormSubmitted(!isFormSubmitted);
                        handleGetAnamnesis();
                      }
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                        });
                      }
                    });
                }
              }}
            >
              {isEditMode ? "Salvar" : "Criar"}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default TeensAnamnesisForm;
