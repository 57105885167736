import {
  capitalizeName,
  getCustomDateTimeUtc
} from "@jumbo/utils";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import KeyIcon from "@mui/icons-material/Key";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Autocomplete, Box, Chip, Grid, Tooltip, Zoom } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ApiService from "app/services/config";
import moment from "moment";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";

const TabSchedule = ({ schedule, setSchedule }) => {
  const navigate = useNavigate();
  let { state, pathname, search } = useLocation();
  const { scheduleId } = useParams();

  const DIAS_SEMANA = {
    0: "Domingo",
    1: "Segunda-feira",
    2: "Terça-feira",
    3: "Quarta-feira",
    4: "Quinta-feira",
    5: "Sexta-feira",
    6: "Sábado",
  };


  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();

  const storedColaboratorId = localStorage.getItem("id");

  const [users, setUsers] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [days, setDays] = useState([]);
  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [hours, setHours] = useState([]);
  // console.log('userSelected', userSelected)
  const [userInput, setUserInput] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users/all/patients?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  let query = useQuery();
  const workerId = query.get("workerId");
  const date = query.get("date");
  const hour = query.get("hour");
  const model = query.get("model");
  // console.log('workerId', workerId)

  const handleAgreementChange = (e) => {
    const selectedConvenio = e.target.value;
    // Encontre o objeto de convenio correspondente aos dados selecionados
    const selectedConvenioData = schedule.User.convenio.find(
      (convenio) => convenio.id === selectedConvenio
    );
    // Atualize o estado com os dados selecionados
    setSchedule((prevState) => ({
      ...prevState,
      convenio_id: selectedConvenio,
      Convenio: {
        ...prevState.Convenio,
        id: selectedConvenio,
        tipo_plano_convenio: selectedConvenioData?.tipo_plano_convenio || "", // Preencha o tipo de plano do convênio, se disponível
        numero_convenio: selectedConvenioData?.numero_convenio || "", // Preencha o número do convênio, se disponível
      },
    }));
  };

  const modalAlert = (scheduleId) => {
    Swal.fire({
      title: "Tem certeza que deseja cancelar agendamento?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        removeSchedule(scheduleId);
      }
    });
  };

  const removeSchedule = React.useCallback(
    async (scheduleId) => {
      ApiService.delete(`/schedules/${scheduleId}`)
        .then((response) => {
          enqueueSnackbar("Agendamento cancelado com sucesso!", {
            variant: "success",
          });
          navigate("/schedule-worker");
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error.response?.data?.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar, navigate]
  );

  const getUsers = React.useCallback(async () => {
    try {
      await ApiService.get("/users")
        .then((response) => {
          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setUsers]);

  const getDates = (start, end, interval) => {
    const dates = [];
    let date = start;
    dates.push(moment(new Date(date.setSeconds(date.getSeconds()))).format('HH:mm'));

    while(date < end) {
      date = new Date(date.setSeconds(date.getSeconds() + interval));
      dates.push(moment(date).format('HH:mm'));
    }
    return dates;
  }

  const calculateAvailableHours = (schedule, day_of_week) => {

    const dates = [];
    for(let i = 0 ; i < schedule.length; i++){
      if(day_of_week !== schedule[i].dia_semana) continue;

      for (let j = 0; j < schedule[i].Disponibilidade_Prestador_Periodo.length; j++) {
          let horario_inicio = schedule[i].Disponibilidade_Prestador_Periodo[j].horario_inicio
          let hora_inicio = horario_inicio.slice(0, 2)
          let minuto_inicio = horario_inicio.slice(-2)

          let horario_fim = schedule[i].Disponibilidade_Prestador_Periodo[j].horario_fim
          let hora_fim = horario_fim.slice(0, 2)
          let minuto_fim = horario_fim.slice(-2)

          let tempo_atendimento = schedule[i].Disponibilidade_Prestador_Periodo[j].tempo_atendimento;

          const start = new Date(2022, 1, 1);
          start.setHours(hora_inicio, minuto_inicio, 0, 0);
          const end = new Date(2022, 1, 1);
          end.setHours(hora_fim, minuto_fim, 0, 0);

          const interval = tempo_atendimento * 60;

          const dt = getDates(start, end, interval)
          dates.push(...dt)

      }
    }
    
    return dates;
  };
  
  const getDaysSchedule = React.useCallback(async () => {
    try {
      if (workerId) {
        await ApiService.get(`/worker-availability-infos/model/days/${workerId}`)
          .then((response) => {
            const daysOfWeek = response.data.map((day) =>  day.dia_semana )

            setDays(daysOfWeek)
            setScheduleData(response.data)

          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getUserInfo = React.useCallback(async () => {
    try {
      const userId = userSelected?.selected?.id;
      if (userId) {
        await ApiService.get(`/users/${userId}`)
          .then((response) => {
            setUserInfo(response.data);
            const user = response.data;
            // console.log(user);
            setSchedule({
              User: {
                id: user?.id,
                nome: user?.nome,
                convenio: user?.convenio,
                telefone: user?.telefone,
                ultima_consulta: user?.Agendamento[0]?.data
              },
              /* tipo_de_agendamento: '',
                            status: '',
                            telemedicina: '',
                            obs_agendamento: '',
                            acompanhante: '',
                            updatedAt: '', */
            });
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  }, [setSchedule, userSelected?.selected]);

  

  useEffect(() => {
    if (!scheduleId) {
      getUsers();
      getUserInfo();
      getDaysSchedule();
    }
  }, [getUsers, getUserInfo, scheduleId, getDaysSchedule]);

  return (
    <>
      <Divider textAlign="left" style={{ marginBottom: "10px" }}>
        <Typography variant="h4" gutterBottom>
          Dados do Paciente
        </Typography>
      </Divider>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={1}>
          <Button 
            variant="outlined"
            component={RouterLink} 
            to={'/people/create'} 
            state={{backUrl: `${pathname}${search}`}}
            disabled={scheduleId}
          >
            <PersonAddIcon />
          </Button>
        </Grid>
        <Grid item xs={5}>
          {scheduleId ? (
            <TextField
              id="outlined-read-only-input"
              fullWidth
              label="Nome do Paciente"
              value={schedule?.User?.nome}
              disabled
            />
          ) : (
            <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
              <Autocomplete
                fullWidth
                disablePortal
                getOptionLabel={(option) => option.nome}
                options={optionsList}
                loading={loading}
                inputValue={userInput}
                onInputChange={handleInputChange}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setUserSelected((prevValues) => ({
                      selected: newValue,
                    }));
                  }
                }}
                noOptionsText={"Nenhum resultado encontrado"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Paciente"
                    placeholder="Paciente"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? "carregando..." : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Última Consulta"
            type="date"
            value={schedule.User?.ultima_consulta || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>

      <Divider
        textAlign="left"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Typography variant="h4" gutterBottom>
          Dados do Agendamento
        </Typography>
      </Divider>
      <Grid container spacing={2}>
          { model 
          ? 
          <>
            <Grid item xs={2}>
              <FormControl fullWidth>
                  <InputLabel id="dia_semana">
                    Dia da semana
                  </InputLabel>
                  <Select
                  fullWidth
                    labelId="dia_semana"
                    id="demo-simple-select-disabled"
                    label="Dia da semana"
                    value={schedule?.dia_semana || ""}
                    disabled={userSelected === null ? true : false}
                    onChange={(e) =>{
                      setSchedule({
                        ...schedule,
                        dia_semana: e.target.value,
                      })
                      setDayOfWeek(e.target.value)
                    }
                    }
                  >
                    {days.map((day, i) =>
                      <MenuItem value={day}>{DIAS_SEMANA[day]}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
              <FormControl fullWidth>
                  <InputLabel id="horario">
                    Hora
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="horario"
                    id="demo-simple-select-disabled"
                    label="Hora"
                    value={schedule?.horario || ""}
                    // disabled={schedule.dia_semana !== null}
                    onChange={(e) =>
                      setSchedule({
                        ...schedule,
                        horario: e.target.value,
                      })
                    }
                  >
                    {calculateAvailableHours(scheduleData, dayOfWeek).map((hour, i) => 
                      <MenuItem value={hour}>{hour}</MenuItem>
                    )}

                    {/* <MenuItem value={"07:30"}>07:30</MenuItem>
                    <MenuItem value={"08:00"}>08:00</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </>
          : 
            <Grid item xs={3}>
              <TextField
                id="outlined-read-only-input"
                fullWidth
                label="Data e Hora"
                InputProps={{ readOnly: true }}
                value={
                  scheduleId
                    ? schedule.data ? 
                      `${schedule?.data?.split("-")?.reverse()?.join("/")} - ${
                          schedule?.horario
                      }`
                      : `${DIAS_SEMANA[schedule?.dia_semana]} - ${schedule?.horario}`
                      
                    : `${moment(date).format('DD/MM/YYYY')} - ${hour}`
                }
              />
            </Grid>
          }
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="tipo_de_agendamento">
              Tipo de Agendamento
            </InputLabel>
            <Select
              labelId="tipo_de_agendamento"
              id="demo-simple-select-disabled"
              label="Tipo de Agendamento"
              value={schedule?.tipo_de_agendamento || ""}
              onChange={(e) =>
                setSchedule({
                  ...schedule,
                  tipo_de_agendamento: e.target.value,
                })
              }
            >
              <MenuItem value="">
                <em>Vazio</em>
              </MenuItem>
              <MenuItem value={"primeira_consulta"}>1ª Consulta</MenuItem>
              <MenuItem value={"aplicacao"}>Aplicação</MenuItem>
              <MenuItem value={"aquatica"}>Aquática</MenuItem>
              <MenuItem value={"avaliacao"}>Avaliação</MenuItem>
              <MenuItem value={"primeira_avaliacao"}>1ª Avaliação</MenuItem>
              <MenuItem value={"segunda_avaliacao"}>2ª Avaliação</MenuItem>
              <MenuItem value={"terceira_avaliacao"}>3ª Avaliação</MenuItem>
              <MenuItem value={"consulta"}>Consulta</MenuItem>
              <MenuItem value={"devolutiva"}>Devolutiva</MenuItem>
              <MenuItem value={"encaixe"}>Encaixe</MenuItem>
              <MenuItem value={"experiencia_mc"}>Experiência MC</MenuItem>
              <MenuItem value={"reavaliacao"}>Reavaliação</MenuItem>
              <MenuItem value={"retorno"}>Retorno</MenuItem>
              <MenuItem value={"reuniao_escolar"}>Reunião Escolar</MenuItem>
              <MenuItem value={"reuniao_outros"}>Reunião Outros</MenuItem>
              <MenuItem value={"revisao_laudos_relatorios"}>
                Revisão Laudos/Relatórios
              </MenuItem>
              <MenuItem value={"supervisao"}>Supervisão</MenuItem>
              <MenuItem value={"terapia"}>Terapia</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs="3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-disabled-label">
              Situação Agenda
            </InputLabel>
            <Select
              labelId="demo-simple-select-disabled-label"
              id="demo-simple-select-disabled"
              label="Situação Agenda"
              value={schedule?.status || ""}
              onChange={(e) =>
                setSchedule({
                  ...schedule,
                  status: e.target.value,
                })
              }
            >
              <MenuItem value="">
                <em>Vazio</em>
              </MenuItem>
              <MenuItem value={"agendado"}>Agendado</MenuItem>
              <MenuItem value={"agendando"}>Agendando</MenuItem>
              <MenuItem value={"aguardando"}>Aguardando</MenuItem>
              <MenuItem value={"atendido"}>Atendido</MenuItem>
              <MenuItem value={"confirmado_presenca"}>
                Confirmado Presença
              </MenuItem>
              <MenuItem value={"desistiu"}>Desistiu</MenuItem>
              <MenuItem value={"desmarcou"}>Desmarcou</MenuItem>
              <MenuItem value={"em_atendimento"}>Em Atendimento</MenuItem>
              <MenuItem value={"em_dilatacao_primeira_gota"}>
                Em Dilatação 1ª Gota
              </MenuItem>
              <MenuItem value={"em_dilatacao_segunda_gota"}>
                Em Dilatação 2ª Gota
              </MenuItem>
              <MenuItem value={"falta_justificada"}>Falta Justificada</MenuItem>
              <MenuItem value={"falta_nao_justificada"}>
                Falta Não Justificada
              </MenuItem>
              <MenuItem value={"livre"}>Livre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs="3">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Telemedicina
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={schedule?.telemedicina}
              onChange={(e) =>
                setSchedule({
                  ...schedule,
                  telemedicina: JSON.parse(e.target.value),
                })
              }
            >
              <FormControlLabel value={true} control={<Radio />} label="SIM" />
              <FormControlLabel value={false} control={<Radio />} label="NÃO" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Divider
        textAlign="left"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Typography variant="h4" gutterBottom>
          Dados do Convênio
        </Typography>
      </Divider>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-disabled-label">
              Convênio
            </InputLabel>
            <Select
              labelId="demo-simple-select-disabled-label"
              id="demo-simple-select-disabled"
              label="Convênio"
              value={schedule?.Convenio?.id || ""}
              onChange={handleAgreementChange}
            >
              <MenuItem value="">
                <em>Vazio</em>
              </MenuItem>
              {schedule?.User?.convenio?.map((item) => (
                <MenuItem value={item?.id} sx={{ textTransform: "capitalize" }}>
                  {capitalizeName(item.nome)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth disabled>
            <InputLabel id="demo-simple-select-disabled-label">
              Tipo Plano Convenio
            </InputLabel>
            <Select
              labelId="demo-simple-select-disabled-label"
              id="demo-simple-select-disabled"
              label="Tipo Plano Convenio"
              value={schedule?.Convenio?.tipo_plano_convenio || ""}
              onChange={(e) =>
                setSchedule({
                  ...schedule,
                  Convenio: {
                    tipo_plano_convenio: e.target.value,
                  },
                })
              }
            >
              <MenuItem value="">
                <em>Vazio</em>
              </MenuItem>
              {schedule?.User?.convenio?.map((item) => (
                <MenuItem
                  value={item?.tipo_plano_convenio}
                  sx={{ textTransform: "capitalize" }}
                >
                  {capitalizeName(item.tipo_plano_convenio)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth disabled>
            <InputLabel id="demo-simple-select-disabled-label">
              Cartão Convenio
            </InputLabel>
            <Select
              labelId="demo-simple-select-disabled-label"
              id="demo-simple-select-disabled"
              label="Cartão Convênio"
              value={schedule?.Convenio?.numero_convenio || ""}
              onChange={(e) =>
                setSchedule({
                  ...schedule,
                  Convenio: {
                    numero_convenio: e.target.value,
                  },
                })
              }
            >
              <MenuItem value="">
                <em>Vazio</em>
              </MenuItem>
              {/* <MenuItem value={'123456'}>123456</MenuItem> */}
              {schedule?.User?.convenio?.map((item) => (
                <MenuItem
                  value={item?.numero_convenio || ""}
                  sx={{ textTransform: "capitalize" }}
                >
                  {capitalizeName(item.numero_convenio)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Autorização"
            defaultValue="Exigido"
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* verifica se essas autorizações vem do relacionamento convenio-autorização  */}
        {/* <Grid item xs={3}>
                        <FormControl fullWidth disabled>
                            <InputLabel id='demo-simple-select-disabled-label'>
                                Autorizações
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-disabled-label'
                                id='demo-simple-select-disabled'
                                label='Autorizações'
                            >
                                <MenuItem value=''>
                                    <em>Vazio</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}

        <Grid item xs={3}>
          <Button variant="outlined">
            <KeyIcon />
          </Button>
        </Grid>
      </Grid>

      <Divider
        textAlign="left"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Typography variant="h4" gutterBottom>
          Telefones
        </Typography>
      </Divider>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Tipo do Telefone</TableCell>
            <TableCell align="left">DDD</TableCell>
            <TableCell align="left">Telefone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule?.User?.telefone?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell>{row.tipo_de_telefone}</TableCell>
              <TableCell align="left">{row.ddd}</TableCell>
              <TableCell align="left">{row.numero}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Divider
        textAlign="left"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Typography variant="h4" gutterBottom>
          Observações gerais do agendamento
        </Typography>
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs="8">
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Informações complementares do agendamento"
            multiline
            rows={4}
            value={schedule?.obs_agendamento}
            onChange={(e) =>
              setSchedule({
                ...schedule,
                obs_agendamento: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs="4">
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Acompanhante"
            value={schedule?.acompanhante}
            onChange={(e) =>
              setSchedule({
                ...schedule,
                acompanhante: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      {scheduleId ? (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography>Alterado</Typography>
            <Chip
              label={getCustomDateTimeUtc(schedule?.updatedAt)}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>Agendado</Typography>
            <Chip
              label={schedule?.data
                ? `${schedule?.data?.split("-")?.reverse()?.join("/")} - ${
                  schedule?.horario
                }`
                : `${DIAS_SEMANA[schedule?.dia_semana]} - ${schedule?.horario}`
              }
              /* color='#FF8224' */
              sx={{ background: "#FF8224", color: "#ffffff" }}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>Chegou na Clinica</Typography>
            <Chip
              /* label={getCustomDateTimeUtc(schedule?.updatedAt)} */
              label={getCustomDateTimeUtc(schedule?.chegou_na_clinica)}
              sx={{ background: "#007EBA", color: "#ffffff" }}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>Inicio Atendimento</Typography>
            <Chip
              /* label={getCustomDateTimeUtc(schedule?.updatedAt)} */
              label={getCustomDateTimeUtc(schedule?.atendimento_inicio)}
              sx={{
                background:
                  "linear-gradient(to right, #007EBA 50%, #AF1E87 50%)",
                color: "#ffffff",
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>Fim Atendimento</Typography>
            <Chip
              label={getCustomDateTimeUtc(schedule?.atendimento_fim)}
              sx={{ background: "#AF1E87", color: "#ffffff" }}
              size="small"
            />
          </Grid>

          {/* <Grid item xs={4}>
                        <TextField
                            id='outlined-read-only-input'
                            fullWidth
                            label='Presença Não Confirmada'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id='outlined-read-only-input'
                            fullWidth
                            label='Ficha'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid> */}
        </Grid>
      ) : undefined}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginTop={2}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => modalAlert(schedule.id)}
        >
          Cancelar agendamento
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            if (scheduleId) {
              const userScheduleEdit = {
                ...schedule,
                colaborador_alteracao_id: Number(storedColaboratorId),
                updatedAt: undefined,
                User: undefined,
                Convenio: undefined,
                Prestador: undefined,
                Colaborador: undefined,
                chegou_na_clinica:
                  schedule.status === "confirmado_presenca"
                    ? new Date()
                    : undefined,
                atendimento_inicio:
                  schedule.status === "em_atendimento" ? new Date() : undefined,
                atendimento_fim:
                  schedule.status === "atendido" ? new Date() : undefined,
              };

              ApiService.put(`/schedules/${scheduleId}`, userScheduleEdit)
                .then((response) => {
                  // console.log(response.data);
                  enqueueSnackbar("Agendamento atualizado com sucesso!", {
                    variant: "success",
                  });
                  /* const updatedTodos = values.Fono.map(
                                    (todo) => {
                                        if (todo.id === response.data.id) {
                                            return response.data; // Retorna a fono atualizado
                                        }
                                        return todo;
                                    }
                                ); */
                })
                .catch((error) => {
                  const errorMessage = error.response.data.message;
                  console.log(errorMessage);
                  if (Array.isArray(errorMessage)) {
                    enqueueSnackbar(`${errorMessage[0]}`, {
                      variant: "error",
                    });
                  } else {
                    enqueueSnackbar(`${errorMessage}`, {
                      variant: "error",
                    });
                  }
                });
            } else {
              console.log(schedule);
              const scheduleCreated = {
                ...schedule,
                user_id: Number(userSelected?.selected?.id),
                prestador_id: Number(workerId),
                colaborador_id: Number(storedColaboratorId),
                convenio_id: schedule?.convenio_id
                  ? Number(schedule?.convenio_id)
                  : undefined,
                data: date,
                horario: model ? schedule.horario : hour,
                Convenio: undefined,
                User: undefined,
                Colaborador: undefined,
                agenda_modelo: model ? true : false
              };
              console.log(scheduleCreated);

              ApiService.post(`/schedules`, scheduleCreated)
                .then((response) => {
                  console.log(response.data);
                  enqueueSnackbar("Agendamento criado com sucesso!", {
                    variant: "success",
                  });
                  /* const updatedTodos = values.Fono.map(
                                    (todo) => {
                                        if (todo.id === response.data.id) {
                                            return response.data; // Retorna a fono atualizado
                                        }
                                        return todo;
                                    }
                                ); */
                })
                .catch((error) => {
                  const errorMessage = error.response.data.message;
                  console.log(errorMessage);
                  if (Array.isArray(errorMessage)) {
                    enqueueSnackbar(`${errorMessage[0]}`, {
                      variant: "error",
                    });
                  } else {
                    enqueueSnackbar(`${errorMessage}`, {
                      variant: "error",
                    });
                  }
                });
            }
          }}
        >
          Salvar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() =>
            navigate(state?.backUrl ? state.backUrl : "/schedule-worker")
          }
        >
          Voltar
        </Button>
      </Box>
    </>
  );
};

export default TabSchedule;
