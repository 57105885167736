import React, {useCallback, useState} from 'react';
// import {connections} from "./data";
import {List} from "@mui/material";
import ListItemProfile from "../ListRowProfile/ListItemProfile";
import { useParams } from "react-router-dom";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const ListRowProfile = ({ data , setAddStudents, updateProfileList, profileUser}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const { enqueueSnackbar } = useSnackbar();

    const [itemsList, setItemsList] = useState(data);
    const includeStudentToClass = useCallback((record) => {
        console.log('record',record)
        try {
            ApiService.post(`roles/perfil/${id}/usuario/${record.id}`)
            .then((response) => {
                console.log('response.data', response.data)
                let newArray = profileUser;
                let teste = {
                    id: response.data.id,
                    perfil_id: response.data.perfil_id,
                    user_id: response.data.user_id,
                    User: {
                        id: record.id,
                        nome: record.nome,
                        email: record.email
                    }
                }
                // record.usuario_perfil_id = response.data.usuario_perfil_id;
                // newArray.push(record)
                updateProfileList(teste);

                enqueueSnackbar("Usuário adicionado com sucesso!", {
                    variant: "success",
                    
                });
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }

    }, [itemsList]);

    return (
            <List disablePadding>
                {
                    itemsList.map((item, index) => (
                        <ListItemProfile item={item} key={index} includeStudentToClass={includeStudentToClass}/>
                    ))
                }
            </List>
    );
};

export default ListRowProfile;
