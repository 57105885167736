import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import DataTable from '../DataTable';
import { getCustomDateTimeUtc } from '@jumbo/utils';
import { Box, Chip } from '@mui/material';
import { useParams } from 'react-router-dom';
import ApiService from 'app/services/config';
import moment from 'moment';

const Consultation = ({ infos }) => {
    const {id} = useParams();
    const [consultas, setConsultas] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState('');

    const getConsultas = async () => {
        try {
            let apiUrl = `/healthcare/paciente/${id}/?page=${
                page + 1
            }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

            if (searchTerm) {
                apiUrl += `&searchTerm=${searchTerm}`;
            }
            await ApiService.get(apiUrl)
            .then((response) => {
                if(response.status === 200) {
                    setConsultas(response.data?.atendimentos);
                    setCount(response.data?.count);
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
        {
            name: 'createdAt',
            label: 'Data',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const index = tableMeta?.rowIndex;
                    /* getCustomDateTimeUtc */
                    return (
                        <Typography>
                            {moment(value).format("DD/MM/YYYY")}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'status',
            label: 'Situação',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                            <Chip
                                sx={{ background: '#FF8224', borderRadius: '50%', width: '1rem', height: '1rem'}}
                                size='small'
                            />
                            <Typography textTransform={'capitalize'}>{value}</Typography>
                        </Box>
                    );
                },
            },
        },
        {
            name: 'tipo_de_agendamento',
            label: 'Agendamento',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Convenio.nome',
            label: 'Convênio',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'prestador.nome',
            label: 'Prestador',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Colaborador.nome',
            label: 'Colaborador que agendou',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'Colaborador_Alteracao.nome',
            label: 'Colaborador que alterou',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'createdAt',
            label: 'Ocorrência criação',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography>
                            {`${getCustomDateTimeUtc(value)} `}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'updatedAt',
            label: 'Ocorrência alteração',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography>
                            {`${getCustomDateTimeUtc(value)} `}
                        </Typography>
                    );
                },
            },
        },
    ];
    const options = {
        selectableRows: 'none',
        download: false,
        print: false,
        searchAlwaysOpen: true,
        enableNestedDataAccess: '.',
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'of',
            },
        },
    };

    useEffect(() => {
        getConsultas();
    }, [id]);

    return (
        <div>
            <Grid container style={{ marginBottom: '10px' }}>
                <Grid xs={8}>
                    <Typography
                        variant='h1'
                        gutterBottom
                        style={{ marginBottom: '15px' }}
                    >
                        Consultas
                    </Typography>
                </Grid>
            </Grid>
            <MUIDataTable
                title={''}
                data={consultas}
                columns={columns}
                options={options}
            />
        </div>
    );
};

export default Consultation;
