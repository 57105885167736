import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback } from "react";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

const todasPerguntas = [
  {
    categoria: "COMUNICAÇÃO RECEPTIVA",
    perguntas: [
      "Compreende uma variedade de conceitos de relações físicas descritivas",
      "Recupera 10-15 itens usando 2-3 pistas múltiplas (p.e., tamanho, quantidade, cor, nome do objeto",
      "Compreende pronomes de gênero",
      "Compreende comparativos: maior, mais baixo, menor, mais, menos, alguns, muitos, etc",
      "Compreende relações espaciais envolvendo objetos e locuções preposicionais: atrás, na parte de trás de, em frente de, etc",
      "Compreende negações (p.e., a caixa sem bolas, o rapaz que não está sentado)",
      "Compreende possessivos e relações parte-todo",
      'Demonstra atenção a pequenas histórias e compreensão das partes da história respondendo a questões simples de " O quê" e "Quem"',
      'Responde a questões de "Sim"/Não" para identidade',
      "Responde a questões sobre estados físicos",
      "Responde a questões de informação pessoal",
      'Compreende "igual" e "diferente"',
      "Compreende conceitos de quantidade",
      "Identifica as características dos objetos",
      "Responde a perguntas relacionadas com a categoria dos objetos/imagens",
      "Compreende o tempo passado e o futuro",
      "Compreende a voz passiva",
      "Compreende as relações temporais",
      "Segue instruções verbais de três partes não relacionadas",
    ],
  },
  {
    categoria: "COMUNICAÇÃO EXPRESSIVA",
    perguntas: [
      'Responde a questões complexas ("Quem?", "Como?")',
      'Descreve a função dos objetos em resposta a uma pergunta (p.e., "O que você faz com uma colher?")',
      "Fala com enunciados de três ou quatro palavras consistentemente",
      "Usa uma variedade se sintagmas nominais",
      "Usa locuções preposicionais (p.e.,debaixo, próximo de, atrás, por trás de, em frente de)",
      "Usa uma variedade de expressões verbais (p.e., ele chora, ela gosta dele, ele caiu, ele estava feliz, ele está feliz, poderia, deveria, faria)",
      "Demonstra uma produção precisa de pelo menos 80% de todas as consoantes e misturas de consoantes dentro do discurso conectado",
      "Descreve experiências recentes usando enunciados de três a quatro palavras",
      "Pede permissão para continuar uma atividade",
      "Usa formas plurais",
      "Usa possessivos (p.e., dele, dela, o chapéu da mãe)",
      "Usa o passado regular",
      "Usa artigos como um, uma, o",
      "Usa comparativos e superlativos",
      "Usa a negativa",
      "Usa verbos no presente",
      "Usa palavras para descrever estados físicos",
      'Responde a perguntas sobre estados físicos: "O que faz quando está...?"',
      "Usa as categorias dos nomes para objetos familiares",
      "Descreve as características dos objetos",
      "Usa pronomes reflexivos",
      "Atende o telefone apropriadamente, incluindo cumprimentar a pessoa",
      "Participa numa conversa iniciada por um adulto em duas ou três tomadas de vez envolvendo uma variedade de funções (p.e., comentar reciprocamente, responder ou perguntar informações)",
      "Inicia e mantém uma conversa com um adulto sobre um tópico gerado por si mesma",
      "Descreve sequências de dois ou três eventos de atividades (p.e., ir visitar a avó)",
      'Expressa "Não sei" acompanhado do gesto',
      "Pede explicações se não entendeu o que foi dito",
      "Envolve-se numa variedade de tópicos durante uma conversa",
      "Reformula a própria comunicação quando o ouvinte não está compreendendo",
      "Responde a perguntas sobre si e sobre os outros",
    ],
  },
  {
    categoria: "COMPETÊNCIAS SOCIAIS: ADULTOS E PARES",
    perguntas: [
      "Convida os pares para brincar",
      'Usa termos de cortesia como "Com licença" ou "Desculpe"',
      "Procura os outros para conforto em situações de grupo",
      "Expressa os seus sentimentos de forma apropriada",
      "Toma a vez no jogo informal autonomamente",
      "Descreve um acontecimento ou experiência a um parceiro",
      "Identifica o que a faz sentir-se feliz, triste, zangada, assustada",
      "Identifica as emoções dos outros com base em fatores situacionais",
      "Começa a desenvolver estratégias para lidar com as situações que a deixam aborrecida, zangada ou assustada",
    ],
  },
  {
    categoria: "COGNIÇÃO",
    perguntas: [
      "Conta de cor até 20",
      "Conta objetos com a correspondência 1:1 até 10",
      'Dá a alguém "um", "alguns", "muitos", "um pouco", "todos", "mais" e "a maioria"',
      "Dá a alguém quantidades até 10",
      "Conhece termos de conceitos de quantidade",
      "Conhece termos de relações espaciais",
      "Corresponde e compreende 5-10 associações objeto/palavra",
      "Consegue ler algumas palavras",
      "Consegue identificar o nome escrito num conjunto de cinco palavras",
      '"Lê" sinais e símbolos',
      "Identifica números e letras",
      "Percebe opostos e analogias",
    ],
  },
  {
    categoria: "JOGO",
    perguntas: [
      "Desenvolve ações de figuras durante o jogo",
      "Usa objetos substitutos para simbolizar os adereços do jogo",
      "Nomeia as ações e adereços simulados durante o jogo",
      "Liga espontaneamente três ou mais comportamentos relacionados num tema de jogo",
      "Direciona o parceiro no jogo",
      "Representa vários eventos da vida (p.e., festa de aniversário, McDonald's, médico), incluindo o uso de guiões verbais",
      "Representa vários temas de histórias no jogo",
      "Assume o papel de um personagem e representa-o",
      "Segue a liderança de outra pessoa durante o jogo",
    ],
  },
  {
    categoria: "MOTRICIDADE FINA",
    perguntas: [
      "Pinta uma imagem com precisão, com várias cores",
      "Imita triângulos, letras utilizando material de desenho apropriado",
      "Desenha linhas e formas e algumas letras e números de cor",
      "Imita e copia uma variedade de letras,números e formas",
      "Escreve o seu primeiro nome sem modelo",
      "Traça formas e letras",
      "Pinta formas desenhadas",
      "Une pontos com material de desenho",
      "Desenha linhas para corresponder imagens, palavras ou formas",
      "Copia uma variedade de desenhos representacionais simples (p.e., rosto, árvore, casa, flor)",
      "Dobra um papel ao meio e coloca-o num envelope",
      "Recorta ângulos, linhas direitas e curvas",
      "Recorta formas simples",
      "Completa projetos de expressão plástica de três passos- cortar, colorir e colar",
      "Usa pincel, selos, marcadores, lápis, borrachas para completar atividades de expressão plástica",
      "Usa a preensão tripla para segurar o material de desenho",
      "Faz construções com uma variedade de materiais de construção com o próprio desenho e copia modelos simples a partir de modelos de imagens 3D",
      "Resolve puzzles de encaixe, puzzles de fôrma e puzzles de tabuleiro",
      "Usa fita-cola, clipes e chaves apropriadamente",
    ],
  },
  {
    categoria: "MOTRICIDADE GROSSA",
    perguntas: [
      "Joga à apanhada com uma bola de recreio com um par",
      "Atira uma bola de tênis ou de basebol para outra pessoa com direcionalidade por cima da cabeça",
      "Usa o equipamento do recreio autonomamente, incluindo o balanço e o carrossel",
      "Pontapeia uma bola em movimento",
      "Joga vários jogos com bolas: atira a bola ao cesto, bate na bola com um bastão, lança a bola, joga golfe, lança saquinhos de feijão para acertar no orifício",
      "Anda confiantemente de bicicleta com rodinhas; é capaz de controlar a velocidade, manobrar e travar",
      "Galopa e salta",
      "Anda sem perder o equilíbrio numa trave, em carris de combio e no lancil do passeio",
      'Joga jogos motores típicos (p.e., "Red Light, Green Light", "Red Rover", "Freeze Tag")',
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL",
    perguntas: [
      "Gere todas etapas envolvidas na ida ao banheiro de forma autônoma ao nível dos pares",
      "Vai ao banheiro sozinha quando precisa",
      "Lava as mãos de forma autônoma ao nível dos pares",
      "Lava a cara com a esponja autonomamente",
      "Escova e penteia o cabelo autonomamente",
      "Dá assistência ativa no banho, seca-se sozinha depois do banho",
      "Realiza todas as etapas de escovação dos dentes de forma autônoma, embora o adulto também possa escovar-lhes os dentes para uma maior perfeição",
      "Aperta a própria roupa botões, molas e fechos",
      "Assoa o nariz quando se chama à atenção, usa um lenço quando espirra, tapa a boca quando tosse ou o nariz quando espirra",
      "Para na rua, atravessa depois de olhar para os dois lados quando acompanhada",
      "Anda em segurança ao lado de um adulto autonomamente em estacionamento, lojas, etc",
      "Ajuda a pôr a mesa",
      "Usar a faca para passar",
      "Limpa quando derruba ou derrama",
      "Serve-se de água de um recipiente pequeno",
      "Por os pratos na lava louça ou balcão ou pia",
      "Fazer um lanche em duas etapas",
      "Ajudar nas tarefas de culinária: mexe, despeja, etc",
    ],
  },
];

const InterdisciplinaryFour = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { quizzId, userId } = useParams();
  const location = useLocation();

  const storedColaboratorId = localStorage.getItem("id");

  const [quizzes, setQuizzes] = useState([]);
  const [answers, setAnswers] = useState([]);

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const date = query.get("date");

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}`)
        .then((response) => {
          console.log(response.data?.pergunta);

          setQuizzes(response.data?.pergunta);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, quizzId]);

  const getQuizzesAnswers = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}/user/${userId}?date=${date}`)
        .then((response) => {
          console.log(response.data);

          setQuizzes(response.data?.pergunta);

          /* setAnswers(respostasFormatadas); */
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname.includes("edit")) {
      getQuizzesAnswers();
    } else {
      getQuizzes();
    }
  }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

  console.log(answers);

  const getSelectedAnswer = (respostas, perguntaId) => {
    // Verifica se há uma resposta salva no estado answers para a pergunta específica
    const respostaSalva = answers.find(
      (resposta) => resposta.pergunta_id === perguntaId
    );

    // Se houver uma resposta salva, retorna o ID dessa resposta
    if (respostaSalva) {
      return respostaSalva.resposta_id;
    }

    // Se não houver uma resposta salva, verifica se há uma resposta do servidor
    const respostaUsuario = respostas.find(
      (resposta) =>
        resposta?.Resposta_Usuario?.length > 0 &&
        resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
    );

    // Se houver uma resposta do servidor, retorna o ID dessa resposta
    if (respostaUsuario) {
      return respostaUsuario.id;
    }

    // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
    return "";
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Aba de Avaliação Interdisciplinar"}
        subtitle={"Nível 4"}
        titleUrl={"/"}
      />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                {todasPerguntas.map((categoria, index) => (
                  <Accordion defaultExpanded my={2}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography variant={"h3"} fontWeight={500}>
                        {categoria.categoria}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        {categoria.perguntas.map((pergunta, index) => (
                          <Grid item xs={12} key={index}>
                            <Stack
                              direction="row"
                              gap={2}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              width="100%"
                            >
                              <Typography>
                                {index + 1} - {pergunta}
                              </Typography>
                              <Select sx={{ minWidth: "150px" }}>
                                <MenuItem value={"adquirido"}>
                                  Adquirido
                                </MenuItem>
                                <MenuItem value={"parcial"}>Parcial</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"nao"}>Não</MenuItem>
                                <MenuItem value={"nao_observado"}>
                                  Não observado
                                </MenuItem>
                              </Select>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">Inclusão</TableCell>
                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                    <TableCell align="center">Alteração</TableCell>
                    <TableCell align="center">
                      {" "}
                      Colaborador Alteração{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                if (location?.pathname.includes("edit")) {
                  ApiService.put(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                }
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default InterdisciplinaryFour;
