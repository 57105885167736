import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ApiService from 'app/services/config';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip, Typography, Zoom, useMediaQuery } from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Dashboard } from '../fichaDiaria/Dashboard';
import { useTheme } from '@mui/material/styles';

const InfosForParents = ({ tabIndex }) => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const { userId } = useParams();

  const [quizzes, setQuizzes] = useState([]);
  const [userQuizz, setUserQuizz] = useState([]);
  const [quizSelected, setQuizSelected] = useState({
    avaliacao: '',
    sensorial: '',
    fono: '',
    psicopedagogia: '',
  });
  const [evaluationSelected, setEvaluationSelected] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const theme = useTheme();
  const matchesDown = useMediaQuery(theme.breakpoints.down('md'));

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes?userId=${userId}`)
        .then((response) => {
          console.log('getQuizzes', response.data);

          setQuizzes(response?.data?.quizzes);
          setUserQuizz(response?.data?.respostasAgrupadas);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes]);

  useEffect(() => {
    getQuizzes();
  }, [getQuizzes]);

  console.log('userQuizz', userQuizz);

  const handleEvaluationChange = (e) => {
    setEvaluationSelected(e.target.value);
    setShowDashboard(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Selecione a opção desejada no menu para visualizar a evolução do
            paciente através de gráficos detalhados.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} sx={{ mt: { xs: 0, sm: '10px' } }}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel id='demo-simple-select-label'>Avaliações</InputLabel>
            <Select label='Avaliações' onChange={handleEvaluationChange}>
              <MenuItem value={11}>
                Checklist Denver Avaliação - Nível 1
              </MenuItem>
              <MenuItem value={12}>
                Checklist Denver Avaliação - Nível 2
              </MenuItem>
              <MenuItem value={13}>
                Checklist Denver Avaliação - Nível 3
              </MenuItem>
              <MenuItem value={14}>
                Checklist Denver Avaliação - Nível 4
              </MenuItem>
              <MenuItem value={62}>Teste M-Chat</MenuItem>
              <MenuItem value={64}>PROTEA-R</MenuItem>
              <MenuItem value={65}>
                Questionário de Avaliação de Autismo (ASQ)
              </MenuItem>
              <MenuItem value={66}>
                ATA - Escala de Avaliação de Traços Autísticos
              </MenuItem>
              <MenuItem value={67}>
                CARS (Escala de Classificação de Autismo na Infância)
              </MenuItem>
              <MenuItem value={69}>
                Vineland - Escala de Comportamento Adaptativo
              </MenuItem>
              <MenuItem value={70}>
                Guia Portage de Educação Pré-Escolar - 0 a 1 ano
              </MenuItem>
              <MenuItem value={71}>
                Guia Portage de Educação Pré-Escolar - 1 a 2 anos
              </MenuItem>
              <MenuItem value={72}>
                Guia Portage de Educação Pré-Escolar - 2 a 3 anos
              </MenuItem>
              <MenuItem value={73}>
                Guia Portage de Educação Pré-Escolar - 3 a 4 anos
              </MenuItem>
              <MenuItem value={74}>
                Guia Portage de Educação Pré-Escolar - 4 a 5 anos
              </MenuItem>
              <MenuItem value={75}>
                Guia Portage de Educação Pré-Escolar - 5 a 6 anos
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} sx={{ mt: { xs: 0, sm: '19px' } }}>
          {matchesDown ? (
            <Button
              fullWidth
              variant='outlined'
              disabled={!evaluationSelected}
              onClick={() => setShowDashboard(true)}
            >
             Gerar Gráfico
            </Button>
          ) : (
            <Tooltip title='Visualizar gráfico' TransitionComponent={Zoom}>
              <Button
                variant='outlined'
                disabled={!evaluationSelected}
                onClick={() => setShowDashboard(true)}
              >
                <AnalyticsIcon />
              </Button>
            </Tooltip>
          )}
        </Grid>

        {showDashboard && (
          <Grid item xs={12}>
            <Dashboard quizIdForParents={evaluationSelected} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default InfosForParents;
