import Chip from "@mui/material/Chip";
import DataTable from "../DataTable";
import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Box, Grid, OutlinedInput } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { PermissionContext } from "app/contexts/PermissionContext";
import ReactInputMask from "react-input-mask";

const validationSchema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório"),
  sigla: yup.string().required("Campo obrigatório"),
});

const FiliamForm = () => {
  const initialValues = {
    nome: "",
    sigla: "",
    logradouro: "",
    cidade: "",
    bairro: "",
    cep: "",
    estado: "",
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [filial, setFilial] = useState(initialValues);
  const { hasPermission } = useContext(PermissionContext);

  const [cepFormatado, setCepFormatado] = useState();

  const handleSubmit = async (values) => {
    try {
      if (id) {
        const valuesEditados = values;
        delete valuesEditados.id;
        delete valuesEditados.createdAt;
        delete valuesEditados.updatedAt;
        delete valuesEditados.deletedAt;
        await ApiService.put(`/filial/${id}`, values).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Atualizado com sucesso!", {
              variant: "success",
            });
            navigate("/filiais");
          }
        });
      } else {
        await ApiService.post("/filial", values).then((response) => {
          if (response.status === 201) {
            enqueueSnackbar("Criado com sucesso!", {
              variant: "success",
            });
            navigate("/filiais");
          }
        });
      }
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const getFilial = async (id) => {
    try {
      await ApiService.get(`/filial/${id}`).then((response) => {
        if (response.status === 200) {
          setFilial(response.data);
          setCepFormatado(response.data?.cep);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getFilial(id);
    }
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs title={"Filiais"} titleUrl={"/filiais"} />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Formik
          initialValues={filial}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems={"center"}
              >
                <Grid item xs={8}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        name="nome"
                        value={values.nome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Nome"
                        variant="outlined"
                      />
                      <ErrorMessage name="nome" component="div" style={{ color: "red" }} />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        name="sigla"
                        value={values.sigla}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Sigla"
                        variant="outlined"
                      />
                      <ErrorMessage name="sigla" component="div" style={{ color: "red" }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        name="logradouro"
                        value={values.logradouro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Logradouro"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        name="bairro"
                        value={values.bairro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Bairro"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems={"center"} my={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        name="cidade"
                        value={values.cidade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="outlined-basic"
                        label="Cidade"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                    <ReactInputMask 
                          mask="99999-999"
                          maskChar=""
                          value={cepFormatado}
                          onChange={(e) => {
                            setCepFormatado(e.target.value);
                            setFieldValue("cep", e.target.value);
                          }}
                        >
                          {(inputProps) => (
                            <TextField
                              fullWidth
                              name="cep"
                              label="CEP"
                              sx={{ background: "#ffffff" }}
                              {...inputProps}
                            />
                          )}
                        </ReactInputMask>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        name="estado"
                        value={values.estado}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="outlined-basic"
                        label="Estado"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                style={{
                  height: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => handleSubmit(values)}
                    disabled={id ? !hasPermission('Filiais', 'update') : !hasPermission('Filiais', 'create')}
                  >
                    {id ? "Atualizar" : "Criar"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/filiais");
                    }}
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default FiliamForm;
