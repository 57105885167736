import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { useContext, useEffect, useState } from "react";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { PermissionContext } from "app/contexts/PermissionContext";
import ApiService from "app/services/config";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { ErrorMessage, Form, Formik } from "formik";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import moment from "moment";
import { useSnackbar } from "notistack";

const validationSchema = yup.object().shape({
  colaborador_inclusao_id: yup
    .number()
    .required("ID do Colaborador obrigatório"),
  titulo_orientacao: yup.string().required("Campo obrigatório"),
  descricao: yup.string().required("Campo obrigatório"),
});

function createData(
  codigo,
  inclusao,
  colaborador_inclusao,
  alteracao,
  colaborador_alteracao
) {
  return {
    codigo,
    inclusao,
    colaborador_inclusao,
    alteracao,
    colaborador_alteracao,
  };
}
const rows = [
  createData(
    1,
    "01/10/2021 17:24:49",
    "ADRIANO SA",
    "24/10/2021 18:08:00",
    "ADRIANO SA"
  ),
];
const Guidelines = () => {
  const location = useLocation();
  const { hasPermission } = useContext(PermissionContext);
  const { enqueueSnackbar } = useSnackbar();

  const userName = localStorage.getItem("username");
  const colaboradorId = Number(localStorage.getItem("id"));
  const initialValues = {
    colaborador_inclusao_id: colaboradorId,
    titulo_orientacao: "",
    descricao: "",
  };
  const [editorState, setEditorState] = useState(() =>
    location.state
    ? EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(location?.state?.values?.descricao)
      )
    )
    : EditorState.createEmpty()
  );

  const navigate = useNavigate();
  const Swal = useSwalWrapper();

  const modalAlert = () => {
    Swal.fire({
      title: `Tem certeza que deseja cancelar o cadastro da orientação?`,
      text: `Você perderá os dados já preenchidos!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return navigate('/guidelines');
      }
    });
  };
  
  const handleSubmit = async (values) => {
    const descricao = editorState.getCurrentContent().getPlainText();
    try {
      if(values.id){
        const id = values.id;
        delete values.id;
        delete values.colaborador_alteracao;
        delete values.colaborador_inclusao;
        delete values.createdAt;
        delete values.deletedAt;
        delete values.updatedAt;
        await ApiService.put(`/observations/${id}`, { ...values, descricao }).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar('Orientação atualizada com sucesso!', {
              variant: 'success',
              
            });
            setEditorState(() => EditorState.createEmpty());
            navigate("/guidelines");
          }
        });
      } else {
        await ApiService.post("/observations", { ...values, descricao }).then(
          (response) => {
            if (response.status === 201) {
              enqueueSnackbar('Orientação criada com sucesso!', {
                variant: 'success',
                
              });
              setEditorState(() => EditorState.createEmpty());
              navigate("/guidelines");
            }
          }
        );
      }
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro!', {
        variant: 'error',
        
      });
      console.log(error);
    }
  };

  return (
    <div>
      <HeaderBreadcrumbs
          title={'Orientações'}
          subtitle={location?.state?.values?.id ? 'Editar Orientação' : 'Criar Orientação'}
          titleUrl={'/Guidelines'}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        {/* <Typography variant="h1" gutterBottom style={{ marginBottom: "15px" }}>
          Orientações
        </Typography> */}
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Formik
          initialValues={location?.state?.values ?? initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            errors,
            isValid,
          }) => (
            <Form sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name={"titulo_orientacao"}
                    value={values.titulo_orientacao}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Titulo"
                    variant="outlined"
                  />
                  <ErrorMessage
                    name="titulo_orientacao"
                    component={"div"}
                    style={{ color: "red" }}
                  />
                </Grid>
                <Grid xs={12} md={6} />
                <Grid item xs={12} md={12}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "remove",
                        "history",
                      ],
                    }}
                    onEditorStateChange={(newState) => setEditorState(newState)}
                  />
                </Grid>
              </Grid>
              <Divider
                style={{
                  height: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Código</TableCell>
                          <TableCell align="center">Inclusão</TableCell>
                          <TableCell align="center">
                            Colaborador Inclusão
                          </TableCell>
                          <TableCell align="center">Alteração</TableCell>
                          <TableCell align="center">
                            Colaborador Alteração
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{location?.state?.values?.id}</TableCell>
                          <TableCell align="center">
                            {location.state
                            ? moment(location?.state?.values?.createdAt).format("DD/MM/YYYY HH:mm:ss")
                            : ""}
                          </TableCell>
                          <TableCell align="center">{userName}</TableCell>
                          <TableCell align="center">
                            {location.state
                            ? moment(location?.state?.values?.updatedAt).format("DD/MM/YYYY HH:mm:ss")
                            : ""}
                          </TableCell>
                          <TableCell align="center">
                            {location?.state?.values?.colaborador_alteracao?.nome}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
              <Divider
                style={{
                  height: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    onClick={() => handleSubmit(values)}
                    disabled={!hasPermission('Orientações', 'create')}
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    onClick={() => {
                        modalAlert();
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default Guidelines;
