import React from 'react';
import FileItemSlimTwo from './FileItemSlimTwo.js';

const FileListSlimTwo = ({
    files,
    filesSelected,
    removeFile,
    previewImage,
    isEditMode,

}) => {
    return (
        <>
            <ul style={{ padding: '0 2rem' }}>
                {files &&
                    files?.map(
                        (f, index) =>
                            f?.nome && (
                                <FileItemSlimTwo
                                    key={f?.nome}
                                    file={f}
                                    index={index}
                                    removeFile={removeFile}
                                    isEditMode={isEditMode}
                                />
                            )
                    )}
            </ul>
            <ul style={{ padding: '0 2rem' }}>
                {filesSelected &&
                    filesSelected?.map((f, index) => (
                        <FileItemSlimTwo
                            key={f?.nome}
                            file={f}
                            index={index}
                            removeFile={removeFile}
                            previewImage={previewImage[index]}
                            isEditMode={isEditMode}
                        />
                    ))}
            </ul>
        </>
    );
};

export default FileListSlimTwo;
