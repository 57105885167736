export const horariosAgenda = {
  5: [
    { horario: "08:00" },
    { horario: "08:05" },
    { horario: "08:10" },
    { horario: "08:15" },
    { horario: "08:20" },
    { horario: "08:25" },
    { horario: "08:30" },
    { horario: "08:35" },
    { horario: "08:40" },
    { horario: "08:45" },
    { horario: "08:50" },
    { horario: "08:55" },
    { horario: "09:00" },
    { horario: "09:05" },
    { horario: "09:10" },
    { horario: "09:15" },
    { horario: "09:20" },
    { horario: "09:25" },
    { horario: "09:30" },
    { horario: "09:35" },
    { horario: "09:40" },
    { horario: "09:45" },
    { horario: "09:50" },
    { horario: "09:55" },
    { horario: "10:00" },
    { horario: "10:05" },
    { horario: "10:10" },
    { horario: "10:15" },
    { horario: "10:20" },
    { horario: "10:25" },
    { horario: "10:30" },
    { horario: "10:35" },
    { horario: "10:40" },
    { horario: "10:45" },
    { horario: "10:50" },
    { horario: "10:55" },
    { horario: "11:00" },
    { horario: "11:05" },
    { horario: "11:10" },
    { horario: "11:15" },
    { horario: "11:20" },
    { horario: "11:25" },
    { horario: "11:30" },
    { horario: "11:35" },
    { horario: "11:40" },
    { horario: "11:45" },
    { horario: "11:50" },
    { horario: "11:55" },
    { horario: "12:00" },
    { horario: "12:05" },
    { horario: "12:10" },
    { horario: "12:15" },
    { horario: "12:20" },
    { horario: "12:25" },
    { horario: "12:30" },
    { horario: "12:35" },
    { horario: "12:40" },
    { horario: "12:45" },
    { horario: "12:50" },
    { horario: "12:55" },
    { horario: "13:00" },
    { horario: "13:05" },
    { horario: "13:10" },
    { horario: "13:15" },
    { horario: "13:20" },
    { horario: "13:25" },
    { horario: "13:30" },
    { horario: "13:35" },
    { horario: "13:40" },
    { horario: "13:45" },
    { horario: "13:50" },
    { horario: "13:55" },
    { horario: "14:00" },
    { horario: "14:05" },
    { horario: "14:10" },
    { horario: "14:15" },
    { horario: "14:20" },
    { horario: "14:25" },
    { horario: "14:30" },
    { horario: "14:35" },
    { horario: "14:40" },
    { horario: "14:45" },
    { horario: "14:50" },
    { horario: "14:55" },
    { horario: "15:00" },
    { horario: "15:05" },
    { horario: "15:10" },
    { horario: "15:15" },
    { horario: "15:20" },
    { horario: "15:25" },
    { horario: "15:30" },
    { horario: "15:35" },
    { horario: "15:40" },
    { horario: "15:45" },
    { horario: "15:50" },
    { horario: "15:55" },
    { horario: "16:00" },
    { horario: "16:05" },
    { horario: "16:10" },
    { horario: "16:15" },
    { horario: "16:20" },
    { horario: "16:25" },
    { horario: "16:30" },
    { horario: "16:35" },
    { horario: "16:40" },
    { horario: "16:45" },
    { horario: "16:50" },
    { horario: "16:55" },
    { horario: "17:00" },
    { horario: "17:05" },
    { horario: "17:10" },
    { horario: "17:15" },
    { horario: "17:20" },
    { horario: "17:25" },
    { horario: "17:30" },
    { horario: "17:35" },
    { horario: "17:40" },
    { horario: "17:45" },
    { horario: "17:50" },
    { horario: "17:55" },
  ],
  10: [
    { horario: "08:00" },
    { horario: "08:10" },
    { horario: "08:20" },
    { horario: "08:30" },
    { horario: "08:40" },
    { horario: "08:50" },
    { horario: "09:00" },
    { horario: "09:10" },
    { horario: "09:20" },
    { horario: "09:30" },
    { horario: "09:40" },
    { horario: "09:50" },
    { horario: "10:00" },
    { horario: "10:10" },
    { horario: "10:20" },
    { horario: "10:30" },
    { horario: "10:40" },
    { horario: "10:50" },
    { horario: "11:00" },
    { horario: "11:10" },
    { horario: "11:20" },
    { horario: "11:30" },
    { horario: "11:40" },
    { horario: "11:50" },
    { horario: "12:00" },
    { horario: "12:10" },
    { horario: "12:20" },
    { horario: "12:30" },
    { horario: "12:40" },
    { horario: "12:50" },
    { horario: "13:00" },
    { horario: "13:10" },
    { horario: "13:20" },
    { horario: "13:30" },
    { horario: "13:40" },
    { horario: "13:50" },
    { horario: "14:00" },
    { horario: "14:10" },
    { horario: "14:20" },
    { horario: "14:30" },
    { horario: "14:40" },
    { horario: "14:50" },
    { horario: "15:00" },
    { horario: "15:10" },
    { horario: "15:20" },
    { horario: "15:30" },
    { horario: "15:40" },
    { horario: "15:50" },
    { horario: "16:00" },
    { horario: "16:10" },
    { horario: "16:20" },
    { horario: "16:30" },
    { horario: "16:40" },
    { horario: "16:50" },
    { horario: "17:00" },
    { horario: "17:10" },
    { horario: "17:20" },
    { horario: "17:30" },
    { horario: "17:40" },
    { horario: "17:50" },
  ],
  20: [
    { horario: "08:00" }, { horario: "08:20" }, { horario: "08:40" }, { horario: "09:00" }, { horario: "09:20" },
    { horario: "09:40" }, { horario: "10:00" }, { horario: "10:20" }, { horario: "10:40" }, { horario: "11:00" },
    { horario: "11:20" }, { horario: "11:40" }, { horario: "12:00" }, { horario: "12:20" }, { horario: "12:40" },
    { horario: "13:00" }, { horario: "13:20" }, { horario: "13:40" }, { horario: "14:00" }, { horario: "14:20" },
    { horario: "14:40" }, { horario: "15:00" }, { horario: "15:20" }, { horario: "15:40" }, { horario: "16:00" },
    { horario: "16:20" }, { horario: "16:40" }, { horario: "17:00" }, { horario: "17:20" }, { horario: "17:40" },
  ],
  30: [
    { horario: "08:00" },
    { horario: "08:30" },
    { horario: "09:00" },
    { horario: "09:30" },
    { horario: "10:00" },
    { horario: "10:30" },
    { horario: "11:00" },
    { horario: "11:30" },
    { horario: "12:00" },
    { horario: "12:30" },
    { horario: "13:00" },
    { horario: "13:30" },
    { horario: "14:00" },
    { horario: "14:30" },
    { horario: "15:00" },
    { horario: "15:30" },
    { horario: "16:00" },
    { horario: "16:30" },
    { horario: "17:00" },
    { horario: "17:30" },
  ],
};
