import * as React from 'react';
import {
    Grid,
    Box,
    Tooltip,
    IconButton,
    Zoom,
    Typography,
    Paper,
    Divider,
    Button,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from '../DataTable';
import { useState, useCallback, useEffect } from 'react';
import ApiService from 'app/services/config';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const ScheduleList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState('');
    const { hasPermission } = React.useContext(PermissionContext);
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const modalAlert = (id) => {
        Swal.fire({
          title: `Tem certeza que deseja excluir?`,
          text: `Você não poderá reverter a ação!`,
          icon: `warning`,
          showCancelButton: true,
          confirmButtonText: `Sim!`,
          cancelButtonText: `Não!`,
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            return handleDelete(id);
          }
        });
      };

    const getUsers = useCallback(async () => {
        try {
            let apiUrl = `/worker-availability-infos?page=${
                page + 1
            }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

            if (searchTerm) {
                apiUrl += `&searchTerm=${searchTerm}`;
            }
            ApiService.get(apiUrl)
                .then((response) => {
                    console.log(response.data);

                    setUsers(response.data?.allUsersFiltered?.schedule);
                    setCount(response.data?.total);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [page, perPage, sortBy, sortOrder, searchTerm]);

    const handleSearch = async (newSearchTerm) => {
        if (
            searchTerm === '' ||
            searchTerm === null ||
            searchTerm === undefined
        ) {
            setPage(1);
        }
        setSearchTerm(newSearchTerm);
        setPage(0);
    };

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleDelete = useCallback(async (id) => {
        ApiService.delete(`/worker-availability-infos/${id}`)
            .then((response) => {
                getUsers();

                enqueueSnackbar("Excluído com sucesso!", {
                    variant: "success",
                });
            })
            .catch((error) => {
                enqueueSnackbar("Ocorreu um erro!", {
                    variant: "error",
                });
                const message = error.response.data.message;
                console.error(message);
            });
    }, []);

    const columns = [
        {
            name: 'action',
            label: 'Ações',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const id = tableMeta?.rowData[1];
                    console.log(tableMeta);
                    return (
                        <Box display={'flex'} gap={'1rem'}>
                            <Tooltip
                                title='Visualizar'
                                TransitionComponent={Zoom}
                            >
                                <Link
                                    to={!hasPermission('Gerar Agenda', 'update') ? '#' : `/period/${id}`}
                                    state={{
                                        backUrl: location.pathname,
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <IconButton
                                        aria-label='visualizar'
                                        color='info'
                                        disabled={!hasPermission('Gerar Agenda', 'update')}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>

                            <Tooltip title='Remover' TransitionComponent={Zoom}>
                                <IconButton
                                    aria-label='remover'
                                    color='error'
                                    disabled={!hasPermission('Gerar Agenda', 'delete')}
                                    onClick={() => modalAlert(id)} // Função para deletar usuário
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'Código',
            options: {
                filter: false,
                sort: false,
                /* display: false, */
            },
        },
        /* {
            name: '',
            label: 'Serviço Agenda',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, */
        {
            name: 'Prestador.nome',
            label: 'Agendado',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: { minWidth: '300px', maxWidth: '300px' },
                }),
            },
        },
        {
            name: 'validade_inicial',
            label: 'Data inicial',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Typography variant={'body1'}>
                            {`${value?.split('-')?.reverse()?.join('/')}`}
                        </Typography>
                    </>
                ),
            },
        },
        {
            name: 'validade_final',
            label: 'Data final',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <>
                        <Typography variant={'body1'}>
                            {`${value?.split('-')?.reverse()?.join('/')}`}
                        </Typography>
                    </>
                ),
            },
        },
        {
            name: 'Disponibilidade_Prestador',
            label: 'Segunda-feira',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const workerAvailability = value;
                    return (
                        <Box
                            display={'flex'}
                            gap={'1rem'}
                            flexDirection={'column'}
                        >
                            {workerAvailability?.map((item) =>
                                item?.dia_semana === '1'
                                    ? item?.Disponibilidade_Prestador_Periodo?.map(
                                          (el) => (
                                              <Typography>
                                                  {`${el?.horario_inicio}/${el?.horario_fim}`}
                                              </Typography>
                                          )
                                      )
                                    : undefined
                            )}
                        </Box>
                    );
                },
            },
        },
        {
            name: 'Disponibilidade_Prestador',
            label: 'Terça-feira',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const workerAvailability = value;
                    return (
                        <Box
                            display={'flex'}
                            gap={'1rem'}
                            flexDirection={'column'}
                        >
                            {workerAvailability?.map((item) =>
                                item?.dia_semana === '2'
                                    ? item?.Disponibilidade_Prestador_Periodo?.map(
                                          (el) => (
                                              <Typography>
                                                  {`${el?.horario_inicio}/${el?.horario_fim}`}
                                              </Typography>
                                          )
                                      )
                                    : undefined
                            )}
                        </Box>
                    );
                },
            },
        },
        {
            name: 'Disponibilidade_Prestador',
            label: 'Quarta-feira',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const workerAvailability = value;
                    return (
                        <Box
                            display={'flex'}
                            gap={'1rem'}
                            flexDirection={'column'}
                        >
                            {workerAvailability?.map((item) =>
                                item?.dia_semana === '3'
                                    ? item?.Disponibilidade_Prestador_Periodo?.map(
                                          (el) => (
                                              <Typography>
                                                  {`${el?.horario_inicio}/${el?.horario_fim}`}
                                              </Typography>
                                          )
                                      )
                                    : undefined
                            )}
                        </Box>
                    );
                },
            },
        },
        {
            name: 'Disponibilidade_Prestador',
            label: 'Quinta-feira',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const workerAvailability = value;
                    return (
                        <Box
                            display={'flex'}
                            gap={'1rem'}
                            flexDirection={'column'}
                        >
                            {workerAvailability?.map((item) =>
                                item?.dia_semana === '4'
                                    ? item?.Disponibilidade_Prestador_Periodo?.map(
                                          (el) => (
                                              <Typography>
                                                  {`${el?.horario_inicio}/${el?.horario_fim}`}
                                              </Typography>
                                          )
                                      )
                                    : undefined
                            )}
                        </Box>
                    );
                },
            },
        },
        {
            name: 'Disponibilidade_Prestador',
            label: 'Sexta-feira',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const workerAvailability = value;
                    return (
                        <Box
                            display={'flex'}
                            gap={'1rem'}
                            flexDirection={'column'}
                        >
                            {workerAvailability?.map((item) =>
                                item?.dia_semana === '5'
                                    ? item?.Disponibilidade_Prestador_Periodo?.map(
                                          (el) => (
                                              <Typography>
                                                  {`${el?.horario_inicio}/${el?.horario_fim}`}
                                              </Typography>
                                          )
                                      )
                                    : undefined
                            )}
                        </Box>
                    );
                },
            },
        },
        {
            name: 'Disponibilidade_Prestador',
            label: 'Sábado',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const workerAvailability = value;
                    return (
                        <Box
                            display={'flex'}
                            gap={'1rem'}
                            flexDirection={'column'}
                        >
                            {workerAvailability?.map((item) =>
                                item?.dia_semana === '6'
                                    ? item?.Disponibilidade_Prestador_Periodo?.map(
                                          (el) => (
                                              <Typography>
                                                  {`${el?.horario_inicio}/${el?.horario_fim}`}
                                              </Typography>
                                          )
                                      )
                                    : undefined
                            )}
                        </Box>
                    );
                },
            },
        },
    ];

    const options = {
        changeRowsPerPage: perPage,
        filterType: 'dropdown',
        filter: false,
        selectableRows: 'none',
        searchAlwaysOpen: true,
        searchable: true,
        serverSide: true,
        page: page,
        count: count,
        selectToolbarPlacement: 'above',
        enableNestedDataAccess: '.',

        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'sort':
                    setSortBy(tableState.sortOrder.name);
                    setSortOrder(tableState.sortOrder.direction);
                    break;
                case 'search':
                    handleSearch(tableState.searchText);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'de',
            },
        },
    };

    return (
        <>
            <HeaderBreadcrumbs
                title={'Consulta - Periodo de Agendamento'}
                titleUrl={'/schedule-list'}
            />

            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item textAlign={'center'} xs={12}>
                    <Button
                        variant='contained'
                        to='/period/new'
                        onClick={() => {
                            navigate('/period/new');
                        }}
                        disabled={!hasPermission('Gerar Agenda', 'create')}
                    >
                        Novo período
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={'Pessoas'}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ScheduleList;
