import React, { useCallback, useContext, useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import ArticleIcon from "@mui/icons-material/Article";
import Autocomplete from "@mui/material/Autocomplete";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { updateInputValue } from "app/utils/appHelpers";
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  Modal,
  OutlinedInput,
  Paper,
  Table,
  TableBody, 
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";
import { formatDateWithMoment, getCustomDateTimeUtc } from "@jumbo/utils";

function Person({
  infos,
  setInfos,
  values,
  userSelected,
  setUserSelected,
  handleChange,
  setFieldValue,
}) {

  const userId = Number(localStorage.getItem("id"));
  const [expanded, setExpanded] = useState(false);
  const [especialidades, setEspecialidades] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { hasPermission } = useContext(PermissionContext);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    /* values = {
      ...values,
      PessoaResponsavel: {
        ...values.PessoaResponsavel,
        nome_responsavel: '',
        data_de_nascimento_responsavel: '',
        cpf: '',
        sexo_responsavel: ''
      }
    } */
    
    setOpen(false)
  
  };

  console.log(values)

  const getRoles = useCallback(async () => {
    try {
      await ApiService.get("/roles").then((response) => {
        if (response.status === 200) {
          setRoles(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getUsers = useCallback(async () => {
    try {
      await ApiService.get("/users")
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setUsers]);

  useEffect(() => {
    getRoles();
    getUsers();
  }, [getUsers]);

  const optionsAllUsers = users?.map((result) => {
    return {
      value: result.id,
      label: result.nome,
    };
  });

  const getEspecialidades = async () => {
    try {
      await ApiService.get("/specialties").then((response) => {
        if (response.status === 200) {
          setEspecialidades(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteEspecialidadeUser = async (especialidadeId) => {
    try {
      await ApiService.delete(`/specialties/user/${especialidadeId}`)
      .then((response) => {
        if(response.status === 200) {
          console.log("Especialidade deletada!");
        }
      }).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getEspecialidades();
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={4}
      >
        <Typography variant="h1" marginBottom={0}>
          Dados Pessoais
        </Typography>
        {id ? (
          <Button
            variant="contained"
            disabled={!hasPermission('Pessoas', 'update')}
            onClick={() => {
              /* console.log(userSelected)
                            return */
              const editValues = {
                ...values.Pessoa,
                mae_id: userSelected?.mae?.id,
                pai_id: userSelected?.pai?.id,
                pessoa_responsavel_id: userSelected?.pessoa_responsavel?.id,
                envia_sms: values.Pessoa.envia_sms === "true" ? true : false,
                emite_nota: values.Pessoa.emite_nota === "true" ? true : false,
                colaborador_alteracao_id: userId,
              };

              ApiService.put(`/users/${id}`, {
                Pessoa: editValues,
              })
                .then((response) => {
                  const newUser = [...users, response.data];
                  enqueueSnackbar("Usuário atualizado com sucesso!", {
                    variant: "success",
                    
                  });
                  setFieldValue("Pessoa", response.data);
                  setUsers(newUser);
                  navigate('/people');
                })
                .catch((error) => {
                  const errorMessage = error.response.data.message;
                  console.log(errorMessage);
                  if (Array.isArray(errorMessage)) {
                    enqueueSnackbar(`${String(errorMessage[0]).split('.')[1]}`, {
                      variant: "error",
                      
                    });
                  } else {
                    enqueueSnackbar(`${errorMessage}`, {
                      variant: "error",
                      
                    });
                  }
                });
            }}
          >
            Atualizar
          </Button>
        ) : undefined}
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={6} md={4}>
          <JumboTextField
            fullWidth
            name="Pessoa.nome"
            label="Nome"
            value={values.Pessoa.nome}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <JumboTextField
            fullWidth
            name="Pessoa.nome_social"
            label="Nome Social"
            value={values.Pessoa.nome_social}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12} sm={2}>
          <InputMask
            mask="999.999.999-99"
            value={values.Pessoa.cpf}
            onChange={handleChange}
          >
            {(inputProps) => (
              <TextField
                id="cpf"
                fullWidth
                name="Pessoa.cpf"
                label="CPF"
                {...inputProps}
              />
            )}
          </InputMask>
        </Grid>
        <Grid xs={12} sm={2}>
          <JumboTextField
            fullWidth
            name="Pessoa.data_de_nascimento"
            type="date"
            label="Data de Nascimento"
            value={values.Pessoa?.data_de_nascimento}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container my={2} spacing={2}>
        <Grid xs={12} sm={2}>
          <InputLabel id="perfil-acl-usuario">Tipo de usuário</InputLabel>
          <Select
            labelId="perfil-acl-usuario"
            value={values.Pessoa?.perfil_id}
            name={"Pessoa.perfil_id"}
            label="Tipo de usuário"
            onChange={(event) =>
              setFieldValue("Pessoa.perfil_id", Number(event.target.value))
            }
            fullWidth
          >
            {roles &&
              roles.map((role) => (
                <MenuItem value={role.id}>{role.name}</MenuItem>
              ))}
          </Select>
        </Grid>
        {values.Pessoa.perfil_id === 1 && (
          <Grid xs={12} sm={2}>
            <InputLabel id="demo-multiple-chip-checkbox-label">
              Especialidades
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-checkbox-label"
              id="demo-multiple-chip-checkbox"
              multiple
              value={values.Pessoa.especialidades.map(
                (especialidade) => especialidade.especialidade_id
              )}
              name={"dias"}
              fullWidth
              onChange={(event) => {
                const selectedEspecialidadesIds = event.target.value;
                const selectedEspecialidades = selectedEspecialidadesIds.map(
                  (id) => ({ especialidade_id: id })
                );
                setFieldValue("Pessoa.especialidades", selectedEspecialidades);
              }}
              input={
                <OutlinedInput
                  id="select-multiple-chip-checkbox"
                  label="Especialidades"
                />
              }
              renderValue={() => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {values.Pessoa.especialidades.length === 1 ? (
                    <Chip
                      key={values.Pessoa.especialidades[0].especialidadeId}
                      label={
                        especialidades.find(
                          (especialidade) =>
                            especialidade.id ===
                            values.Pessoa.especialidades[0].especialidade_id
                        )?.descricao
                      }
                    />
                  ) : (
                    <Chip
                      label={`${values.Pessoa.especialidades.length} especialidades selecionados`}
                    />
                  )}
                </Box>
              )}
            >
              {especialidades.length > 0 &&
                especialidades.map((especialidade) => (
                  <MenuItem key={especialidade.id} value={especialidade.id}>
                    <Checkbox
                      checked={values.Pessoa?.especialidades?.some(
                        (espec) => espec.especialidade_id === especialidade.id
                      )}
                      onChange={(event) => {
                        const { checked } = event.target;
                        const especialidade_id = especialidade.id;
                        const idEspecialidade = values.Pessoa?.especialidades?.find(
                          (espec) => espec.especialidade_id === especialidade.id
                        )?.id
                        if (checked) {
                          setFieldValue("Pessoa.especialidades", [
                            ...values.Pessoa.especialidades,
                            { especialidade_id },
                          ]);
                        } else {
                          if(idEspecialidade) {
                            handleDeleteEspecialidadeUser(idEspecialidade);
                          }
                          setFieldValue(
                            "Pessoa.especialidades",
                            values.Pessoa.especialidades.filter(
                              (espec) =>
                                espec.especialidade_id !== especialidade_id
                            )
                          );
                        }
                      }}
                    />
                    <ListItemText primary={especialidade.descricao} />
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        )}
      </Grid>
      <Divider style={{ marginTop: "25px", marginBottom: "10px" }}>
        Exemplo
      </Divider>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>
            <span
              style={{
                verticalAlign: "middle",
                paddingRight: "10px",
              }}
            >
              <PostAddIcon />
            </span>
            Dados Extras
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid container xs={12} sm={6} md={6}>
              <Grid xs={10} style={{ paddingRight: 0 }}>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option.nome}
                  options={users}
                  value={userSelected?.mae ?? null}
                  onChange={(event, newValue) => {
                    setUserSelected((prevValues) => ({
                      ...prevValues,
                      mae: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nome da Mãe"
                      placeholder="Nome da Mãe"
                    />
                  )}
                />
              </Grid>
              <Grid xs={2} style={{ paddingLeft: 0 }}>
                <Button
                  onClick={handleOpen}
                  startIcon={<PersonAddAlt1Icon />}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} sm={6} md={6}>
              <Grid xs={10} style={{ paddingRight: 0 }}>
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) => option.nome}
                  options={users}
                  value={userSelected?.pai ?? null}
                  onChange={(event, newValue) => {
                    setUserSelected((prevValues) => ({
                      ...prevValues,
                      pai: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nome do Pai"
                      placeholder="Nome da Pai"
                    />
                  )}
                />
              </Grid>
              <Grid xs={2} style={{ paddingLeft: 0 }}>
                <Button
                  startIcon={<PersonAddAlt1Icon />}
                  onClick={handleOpen}
                />
              </Grid>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.nome}
                options={users}
                value={userSelected?.pessoa_responsavel ?? null}
                onChange={(event, newValue) => {
                  setUserSelected((prevValues) => ({
                    ...prevValues,
                    pessoa_responsavel: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pessoa Responsável" />
                )}
                sx={{ marginTop: "25px", marginBottom: "10px" }}
              />
            </Grid>

            <Grid xs={12} sm={6} md={6}>
              <FormControl
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="Pessoa.parentesco_responsavel">
                  Parentesco Responsável
                </InputLabel>
                <Select
                  name="Pessoa.parentesco_responsavel"
                  value={values.Pessoa?.parentesco_responsavel}
                  label="Parentesco Responsável"
                  onChange={handleChange}
                >
                  <MenuItem value={"Mãe"}>Mae</MenuItem>
                  <MenuItem value={"Pai"}>Pai</MenuItem>
                  <MenuItem value={"Avô"}>Avo</MenuItem>
                  <MenuItem value={"Avó"}>Avo</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6} md={6}>
              <FormControl
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="Pessoa.uf_de_nascimento">
                  Uf de Nascimento
                </InputLabel>
                <Select
                  name="Pessoa.uf_de_nascimento"
                  value={values.Pessoa?.uf_de_nascimento}
                  label="Uf de Nascimento"
                  onChange={handleChange}
                >
                  <MenuItem value={"AC"}>Acre</MenuItem>
                  <MenuItem value={"AL"}>Alagoas</MenuItem>
                  <MenuItem value={"AP"}>Amapá</MenuItem>
                  <MenuItem value={"AM"}>Amazonas</MenuItem>
                  <MenuItem value={"BA"}>Bahia</MenuItem>
                  <MenuItem value={"CE"}>Ceará</MenuItem>
                  <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                  <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                  <MenuItem value={"GO"}>Goiás</MenuItem>
                  <MenuItem value={"MA"}>Maranhão</MenuItem>
                  <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                  <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                  <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                  <MenuItem value={"PA"}>Pará</MenuItem>
                  <MenuItem value={"PB"}>Paraíba</MenuItem>
                  <MenuItem value={"PR"}>Paraná</MenuItem>
                  <MenuItem value={"PE"}>Pernambuco</MenuItem>
                  <MenuItem value={"PI"}>Piauí</MenuItem>
                  <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                  <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                  <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                  <MenuItem value={"RO"}>Rondônia</MenuItem>
                  <MenuItem value={"RR"}>Roraima</MenuItem>
                  <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                  <MenuItem value={"SP"}>São Paulo</MenuItem>
                  <MenuItem value={"SE"}>Sergipe</MenuItem>
                  <MenuItem value={"TO"}>Tocantins</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <FormControl
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="Pessoa.estado_civil">Estado Civil</InputLabel>
                <Select
                  name="Pessoa.estado_civil"
                  value={values.Pessoa.estado_civil}
                  label="Estado Civil"
                  onChange={handleChange}
                >
                  <MenuItem value={"Solteiro"}>Solteiro</MenuItem>
                  <MenuItem value={"Casado"}>Casado</MenuItem>
                  <MenuItem value={"Divorciado"}>Divorciado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <JumboTextField
                fullWidth
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                name="Pessoa.religiao"
                label="Religião"
                value={values.Pessoa?.religiao}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <JumboTextField
                fullWidth
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                name="Pessoa.profissao"
                label="Profissão"
                value={values.Pessoa?.profissao}
              />
            </Grid>

            <Grid xs={12} sm={6} md={6}>
              <JumboTextField
                fullWidth
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                name="Pessoa.cidade_de_nascimento"
                label="Cidade Nascimento"
                value={values.Pessoa?.cidade_de_nascimento}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <JumboTextField
                fullWidth
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                }}
                name="Pessoa.nacionalidade"
                label="Cidade Nacionalidade"
                value={values.Pessoa?.nacionalidade}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <FormControl>
                <FormLabel id="Pessoa.sexo">Sexo</FormLabel>
                <RadioGroup
                  row
                  name="Pessoa.sexo"
                  value={values.Pessoa?.sexo}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Feminino"
                    control={<Radio />}
                    label="Feminino"
                  />
                  <FormControlLabel
                    value="Masculino"
                    control={<Radio />}
                    label="Masculino"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>
            <span
              style={{
                verticalAlign: "middle",
                paddingRight: "10px",
              }}
            >
              <ArticleIcon />
            </span>
            Dados Documentais
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid xs={12} sm={6} lg={4}>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.rg"
                  label="RG"
                  value={values.Pessoa?.rg}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.inscricao_municipal"
                  label="Inscrição Municipal"
                  value={values.Pessoa?.inscricao_municipal}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.cartao_pis"
                  label="Cartâo Pis"
                  value={values.Pessoa?.cartao_pis}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.carteira_de_trabalho"
                  label="Carteira de Trabalho"
                  value={values.Pessoa?.carteira_de_trabalho}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} lg={3}>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.data_emissao_rg"
                  label="Data Emissão RG"
                  type="date"
                  value={values.Pessoa?.data_emissao_rg}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.titulo_eleitor"
                  label="Titulo Eleitor"
                  value={values.Pessoa?.titulo_eleitor}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.carteira_militar"
                  label="Carteira Militar"
                  value={values.Pessoa?.carteira_militar}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.codigo_importacao"
                  label="Código Importação"
                  value={values.Pessoa?.codigo_importacao}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} lg={3}>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.orgao_emissor_rg"
                  label="Orgão Emissor RG"
                  value={values.Pessoa?.orgao_emissor_rg}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.cnh"
                  label="CNH"
                  value={values.Pessoa?.cnh}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.passaporte"
                  label="Passaporte"
                  value={values.Pessoa?.passaporte}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <FormControl>
                  <FormLabel
                    style={{ marginTop: "20px" }}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Envia SMS
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="Pessoa.envia_sms"
                    value={values.Pessoa.envia_sms}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} lg={2}>
              <Grid xs={12} sm={2}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.numero_filhos"
                  label="Nº de Filhos"
                  value={values.Pessoa?.numero_filhos}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <FormControl
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel id="Pessoa.uf_emissao_rg">
                    UF Emissor RG
                  </InputLabel>
                  <Select
                    name="Pessoa.uf_emissao_rg"
                    value={values.Pessoa?.uf_emissao_rg}
                    label="UF Emissor RG"
                    onChange={handleChange}
                  >
                    <MenuItem value={"AC"}>Acre</MenuItem>
                    <MenuItem value={"AL"}>Alagoas</MenuItem>
                    <MenuItem value={"AP"}>Amapá</MenuItem>
                    <MenuItem value={"AM"}>Amazonas</MenuItem>
                    <MenuItem value={"BA"}>Bahia</MenuItem>
                    <MenuItem value={"CE"}>Ceará</MenuItem>
                    <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                    <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                    <MenuItem value={"GO"}>Goiás</MenuItem>
                    <MenuItem value={"MA"}>Maranhão</MenuItem>
                    <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                    <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                    <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                    <MenuItem value={"PA"}>Pará</MenuItem>
                    <MenuItem value={"PB"}>Paraíba</MenuItem>
                    <MenuItem value={"PR"}>Paraná</MenuItem>
                    <MenuItem value={"PE"}>Pernambuco</MenuItem>
                    <MenuItem value={"PI"}>Piauí</MenuItem>
                    <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                    <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                    <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                    <MenuItem value={"RO"}>Rondônia</MenuItem>
                    <MenuItem value={"RR"}>Roraima</MenuItem>
                    <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                    <MenuItem value={"SP"}>São Paulo</MenuItem>
                    <MenuItem value={"SE"}>Sergipe</MenuItem>
                    <MenuItem value={"TO"}>Tocantins</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={3}>
                <JumboTextField
                  fullWidth
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                  }}
                  name="Pessoa.tipo_sanguineo"
                  label="Tipo Sanguineo"
                  value={values.Pessoa?.tipo_sanguineo}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <FormControl>
                  <FormLabel
                    style={{ marginTop: "20px" }}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Emite Nota
                  </FormLabel>
                  <RadioGroup
                    row
                    name="Pessoa.emite_nota"
                    value={values.Pessoa.emite_nota}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>
            <span
              style={{
                verticalAlign: "middle",
                paddingRight: "10px",
              }}
            >
              <SpeakerNotesIcon />
            </span>
            Observação
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <JumboTextField
              fullWidth
              style={{ marginTop: "25px", marginBottom: "10px" }}
              name="Pessoa.observacao"
              label="Observação"
              multiline
              rows={4}
              value={values.Pessoa?.observacao}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* <Grid xs={12} sm={4} md={2}>
          <Typography variant="h3" gutterBottom>
            Código
          </Typography>
        </Grid>
        <Grid xs={12} sm={4} md={2}>
          <Typography variant="h3" gutterBottom>
            Inclusão
        </Grid>
        <Grid xs={12} sm={4} md={3}>
          <Typography variant="h3" gutterBottom>
            Colaborador Inclusão
          </Typography>
          <Typography variant="h3" gutterBottom>
            {values?.Pessoa.colaborador_inclusao?.nome}
          </Typography>
        </Grid>
        <Grid xs={12} sm={4} md={2}>
          <Typography variant="h3" gutterBottom>
            Alteração
          </Typography>
        </Grid>
        <Grid xs={12} sm={4} md={3}>
          <Typography variant="h3" gutterBottom>
            Colaborador Alteração
          </Typography>
          <Typography variant="h3" gutterBottom>
            {values?.Pessoa.colaborador_alteracao?.nome}
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Table sx={{ minWidth: 650, background: '#f5f5f5' }}>
              <TableHead>
                  <TableRow>
                      <TableCell align='center'>
                          Código
                      </TableCell>
                      <TableCell align='center'>
                          Inclusão
                      </TableCell>
                      <TableCell align='center'>
                          {' '}
                          Colaborador Inclusão{' '}
                      </TableCell>
                      <TableCell align='center'>
                          Alteração
                      </TableCell>
                      <TableCell align='center'>
                          {' '}
                          Colaborador Alteração{' '}
                      </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow
                      sx={{
                          '&:last-child td, &:last-child th':
                              { border: 0 },
                      }}
                  >
                      <TableCell align='center'>
                          -
                      </TableCell>
                      <TableCell align='center'>
                          {getCustomDateTimeUtc(
                              values?.Pessoa?.createdAt
                          ) ?? '-'}
                      </TableCell>
                      <TableCell align='center'>
                          {values?.Pessoa.colaborador_inclusao?.nome ?? '-'}
                      </TableCell>
                      <TableCell align='center'>
                      {getCustomDateTimeUtc(
                              values?.Pessoa?.updatedAt
                          ) ?? '-'}
                      </TableCell>
                      <TableCell align='center'>
                          {values?.Pessoa.colaborador_alteracao?.nome ?? '-'}
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 600 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h1" gutterBottom>
            Nova Pessoa
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12} sm={4} md={4}>
              <JumboTextField
                fullWidth
                label="Nome"
                name="PessoaResponsavel.nome_responsavel"
                value={values.PessoaResponsavel?.nome_responsavel}
              />
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <JumboTextField
                fullWidth
                type="date"
                label="Nascimento"
                name="PessoaResponsavel.data_de_nascimento_responsavel"
                value={values.PessoaResponsavel?.data_de_nascimento_responsavel}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid xs={12} sm={4} md={4}>
              <InputMask
                mask="999.999.999-99"
                value={values.PessoaResponsavel?.cpf_responsavel}
                onChange={handleChange}
              >
                {(inputProps) => (
                  <TextField
                    id="cpf"
                    fullWidth
                    name="PessoaResponsavel.cpf_responsavel"
                    label="CPF"
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid xs={12} sm={4} md={12}>
              <FormControl>
                <FormLabel id="PessoaResponsavel.sexo">Sexo</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="PessoaResponsavel.sexo_responsavel"
                  name="PessoaResponsavel.sexo_responsavel"
                  value={values.PessoaResponsavel?.sexo_responsavel}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Feminino"
                    control={<Radio />}
                    label="Feminino"
                  />
                  <FormControlLabel
                    value="Masculino"
                    control={<Radio />}
                    label="Masculino"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4} md={12}>
              <Button
                endIcon={<PersonAddAlt1Icon />}
                variant="contained"
                onClick={() => {
                  console.log(values);
                  ApiService.post(`/users/parents`, values.PessoaResponsavel)
                    .then((response) => {
                      const newUser = [...users, response.data];
                      const gender = response.data.sexo
                      console.log(response.data);
                      enqueueSnackbar("Responsável adicionado com sucesso!", {
                        variant: "success",
                        
                      });
                      /*  setFieldValue(
                                                'Pessoa',
                                                response.data
                                            ); */
                      setUsers(newUser);
                      if(gender === 'Feminino') {
                       /*  setUserSelected({ mae: response.data }); */
                        setUserSelected((prevValues) => ({
                          ...prevValues,
                          mae: response.data,
                        }));
                      }else {
                        setUserSelected((prevValues) => ({
                          ...prevValues,
                          pai: response.data,
                        }));
                      }
                      
                      setFieldValue(
                        
                        'PessoaResponsavel',
                        { 
                          nome_responsavel: '',
                          data_de_nascimento_responsavel: '',
                          cpf_responsavel: '',
                          sexo_responsavel: ''
                        },
                        
                      );

                      handleClose()
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                }}
              >
                Incluir
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default Person;
