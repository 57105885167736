import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ApiService from "app/services/config";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import { Avatar, Box, Button, Grid, IconButton, Input, InputAdornment, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Delete, PhotoCamera } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";


  const UsersForm = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams()
    const initialValues = {
      name: "",
      values: []
    }
    const location = useLocation();
    const Swal = useSwalWrapper();

    const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
      status: false,
      mensagem: "",
    });

    const roleId = location?.state?.roleId

    const validationSchema = yup.object().shape({
        nome: yup
            .string()
            .required('Preenchimento obrigatório'),
        email: yup
          .string("Insira um email válido")
          .email("Email inválido")
          .required("Preenchimento obrigatório"),
        // perfil_id: yup.string("Selecione um perfil")
        // .required("Preenchimento obrigatório"),
        senha: yup.string().nullable(),
        confirmar_senha: yup
          .string()
          .oneOf([yup.ref("senha"), null], "As senhas devem ser iguais")
          // .test('passwords-match', 'As senhas devem ser iguais', function (value) { return this.parent.senha === value })
          // .required("Preenchimento obrigatório"),
        // .test('passwords-match', 'As senhas devem ser iguais', function (value) { return this.parent.senha === value })
    }, []);

    const modalAlert = () => {
      Swal.fire({
        title: "Tem certeza que deseja apagar?",
        text: "Não será póssível reverter a ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          handleRemoveFotoPerfil();
        }
      });
    };

    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);

    const [showPassword, setShowPassword] = useState({
      password: false,
      confirmPassword: false
    });
   

    // const [role_id, setRoleId] = useState(null);

    const handlePreviewFotoPerfil = async (event) => {
      event.preventDefault();
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
  
        if (
          selectedFile.type !== "image/jpeg" &&
          selectedFile.type !== "image/png"
        ) {
          setPreviewFotoPerfil(null);
          setErroUploadFotoPerfil({
            status: true,
            mensagem: "Formato de imagem inválido. Só é permitido .jpeg ou .png",
          });
          console.log("Tipo de imagem inválida", selectedFile.type);
          return;
        } else {
          setErroUploadFotoPerfil({
            status: false,
            mensagem: "",
          });
  
          reader.onloadend = () => {
            setPreviewFotoPerfil(reader.result);
          };
  
          reader.readAsDataURL(selectedFile);
        }
      }
    };

    const handleRemoveFotoPerfil = async () => {
      setPreviewFotoPerfil(null);
      setErroUploadFotoPerfil({
        status: false,
        mensagem: "",
      });
      try {
        await ApiService.put(`/users/remover-foto-perfil/${id}`).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Foto removida com sucesso!", {
              variant: "success",
              
            });
            setPreviewFotoPerfil(null);
            setUser((prevUser) => ({
              ...prevUser,
              avatar_url: null,
            }));
            localStorage.setItem("avatarUrl", null);

          }
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Erro ao remover a imagem de perfil", {
          variant: "error",
          
        });
      }
    };

    const handleUploadImagemPerfil = async (event) => {
      const fotoPerfil = event.target.files[0];
      const formData = new FormData();
      formData.append("foto", fotoPerfil);
      try {
        await ApiService.put(`users/upload-foto-perfil/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          if (response.status === 200) {
            setUser((prevUser) => ({
              ...prevUser,
              avatar_url: response.data.avatar_url,
            }));
            localStorage.setItem("avatarUrl", response.data.avatar_url);
            enqueueSnackbar("Foto salva com sucesso!", {
              variant: "success",
              
            });
          }
        });
      } catch (error) {
        enqueueSnackbar("Erro ao fazer upload da imagem", {
          variant: "error",
          
        });
        console.error("Erro ao fazer upload da imagem: ", error);
      }
    };
    

    const getUserById = useCallback(async () => {
      try {
          ApiService.get(`/users/${id}`)
          .then((response) => {
              console.log('setuser', response.data);
              setUser(response.data);
              if (response.data.avatar_url) {
                setPreviewFotoPerfil(
                  `${process.env.REACT_APP_API_KEY}/public/uploads/${response.data.avatar_url}`
                );
              }
          })
          .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
          })
      } catch (err) {
        console.log(err);
      }
    }, []);

    const getRoles = useCallback(async () => {
      try {
        await ApiService.get("/roles").then((response) => {
          if (response.status === 200) {
            setRoles(response.data);
          }
        });
      } catch (error) {
        console.error(error);
      }
    }, []);
  
    React.useEffect(() => {
      getUserById();
      getRoles()
    }, []);

    React.useEffect(() => {
      setErroUploadFotoPerfil(erroUploadFotoPerfil);
    }, [erroUploadFotoPerfil]);


    const handleSubmit = async (values, { setSubmitting }) => {
      console.log('handleSubmit', values)
      ApiService.put(`users/update/${id}`, { nome: values.nome, email: values.email, senha: values.senha, perfil_id: values.perfil_id})
        .then((response) => {
            enqueueSnackbar("Atualizado com sucesso!", {
              variant: "success",
              
            });
            console.log('Atualizado com sucesso')
          })
          .catch((error) => {
          console.log('Ocorreu um erro')
          enqueueSnackbar("Ocorreu um erro", {
            variant: "error",
            
          });

          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              } else if (error.request) {
              console.log(error.request);
              } else {
              console.log('error', error.message);
              }
          })
        }
    
    return(
        <div>
            <Grid container>
                <HeaderBreadcrumbs
                    title={'Usuários'}
                    subtitle={"Liberar acesso"}
                    titleUrl={'/users'}
                />
            </Grid>
            
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <Paper style={{ padding: '10px', marginBottom: '15px', overflowX: 'auto', overflowY: 'auto' }} square={false} elevation={3}>
                        <Formik
                            initialValues={user}
                            validationSchema={validationSchema}
                            enableReinitialize
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={handleSubmit}
                        >
                            {({values, isSubmitting, handleChange, handleBlur, setFieldValue}) => (
                                <Form style={{width: '100%'}} noValidate autoComplete='off'>
                                    <Grid container spacing={3} alignContent={'center'}>
                                      <Grid item xs={12} >
                                        <Box
                                          width={"100%"}
                                          display={"flex"}
                                          flexDirection={"column"}
                                          alignItems={"center"}
                                        >
                                          <Typography fontSize={"16px"} style={{ margin: 2 }}>
                                            Foto de perfil
                                          </Typography>
                                          <Avatar
                                            alt="Foto do perfil"
                                            src={
                                              user.avatar_url
                                                ? `${process.env.REACT_APP_API_KEY}/public/uploads/${user.avatar_url}`
                                                : previewFotoPerfil
                                            }
                                            sx={{
                                              width: 140,
                                              height: 140,
                                              marginTop: 2,
                                              bgcolor: "success",
                                            }}
                                          >
                                            {user.name ? user.name.charAt(0).toUpperCase() : "P"}
                                          </Avatar>
                                        </Box>
                                        <Box
                                          width={"100%"}
                                          display={"flex"}
                                          marginTop={2}
                                          justifyContent={"center"}
                                          gap={1}
                                        >
                                          <form></form>
                                          <IconButton
                                            color="info"
                                            aria-label="upload picture"
                                            component="label"
                                          >
                                            <input
                                              name="foto"
                                              hidden
                                              accept="image/png, image/jpg, image/jpeg"
                                              type="file"
                                              onChange={(event) => {
                                                handlePreviewFotoPerfil(event);
                                                handleUploadImagemPerfil(event);
                                              }}
                                            />
                                            <PhotoCamera />
                                          </IconButton>
                                          {previewFotoPerfil && (
                                            <>
                                              <IconButton onClick={modalAlert}>
                                                <Delete color="error" />
                                              </IconButton>
                                            </>
                                          )}
                                          {erroUploadFotoPerfil.status && (
                                            <Box sx={{ textAlign: "center" }}>
                                              <Typography color="error">
                                                {erroUploadFotoPerfil.mensagem}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      </Grid>


                                        <Grid item xs={4} >
                                            <JumboTextField
                                                fullWidth
                                                name="nome"
                                                label="Nome"
                                                value={values.nome}
                                                // onChange={handleChange}
                                                // handleChange={handleChange}
                                                // disabled={!hasPermission('Perfis', 'editar')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={3} >
                                            <JumboTextField
                                                fullWidth
                                                name="email"
                                                label="Email"
                                                value={values.email}
                                                // onChange={handleChange}
                                                // handleChange={handleChange}
                                                // disabled={!hasPermission('Perfis', 'editar')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                          <Grid item xs={2} >
                                            <FormControl fullWidth>
                                              <InputLabel id="perfil_id-label">
                                                Perfil
                                              </InputLabel>
                                              <Select
                                                labelId="perfil_id-label"
                                                id="perfil_id"
                                                value={values.perfil_id ?? roleId}
                                                label="Perfil"
                                                name="perfil_id"
                                                onChange={handleChange}
                                                defaultValue={roleId}
                                              >
                                                {roles.length > 0 &&
                                                  roles.map((role) => (
                                                    <MenuItem
                                                      value={role.id}
                                                      key={role.id}
                                                    >
                                                      {role.name}
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                          </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} alignContent={'center'} sx={{ marginTop: 2, marginBottom: 2 }}>
                                      <Grid item xs={3} >
                                          <JumboTextField
                                              fullWidth
                                              name="senha"
                                              label="Senha"
                                              value={values.senha}
                                              // onChange={handleChange}
                                              // handleChange={handleChange}
                                              // disabled={!hasPermission('Perfis', 'editar')}
                                              type={
                                                showPassword.password ? "text" : "password"
                                              }
                                              InputLabelProps={{
                                                  shrink: true,
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(prevState => ({
                                                              ...prevState,
                                                              password: !prevState.password
                                                              }))
                                                            }
                                                            edge="end"
                                                        >
                                                            {showPassword.password ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                          />
                                      </Grid>

                                      <Grid item xs={3} >
                                          <JumboTextField
                                              fullWidth
                                              name="confirmar_senha"
                                              label="Confirmar senha"
                                              value={values.confirmar_senha}
                                              // onChange={handleChange}
                                              // handleChange={handleChange}
                                              // disabled={!hasPermission('Perfis', 'editar')}
                                              type={
                                                showPassword.confirmPassword ? "text" : "password"
                                              }
                                              InputLabelProps={{
                                                  shrink: true,
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(prevState => ({
                                                              ...prevState,
                                                              confirmPassword: !prevState.confirmPassword
                                                              }))
                                                            }
                                                            edge="end"
                                                        >
                                                            {showPassword.confirmPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                          />
                                      </Grid>
                                    </Grid>

                                    <Grid item>
                                        <LoadingButton
                                          color="success"
                                          type="submit"
                                          variant="contained"
                                          size="large"
                                          sx={{
                                            maxWidth: {
                                              md: "150px",
                                              mt: "1rem",
                                            },
                                          }}
                                          /* disabled */
                                          loading={isSubmitting}
                                        >
                                          {"Salvar"}
                                        </LoadingButton>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default UsersForm