import { Box, Button, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import ApiService from 'app/services/config';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Chart from 'react-apexcharts';
import { useLocation, useNavigate, useParams,  } from 'react-router-dom';
import { redirectToEvolution } from '../search/ScheduleVertical';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/pt-br';

export const Dashboard = ({ quizIdForParents = undefined }) => {
    const { quizId, userId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const fromTab = location?.state?.fromTab || 0;
    
    const [infos, setInfos] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(15, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);

    const theme = useTheme();
    const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const matchesXlDown = useMediaQuery(theme.breakpoints.down('xl'));

    const commonOptions = {
        xaxis: {
            categories: infos?.dashboard?.secoes?.map((item) => item?.nome) || ['Respostas'],
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val && Math.round(val) + '%';
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val && Math.round(val) + '%';
            },
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
    };

    const optionsBar = {
        chart: {
            id: 'basic-bar',
        },
        title: {
            text: `Aquisição: ${infos?.dashboard?.nome}`,
        },
        xaxis: {
            categories:
                infos?.dashboard?.secoes?.map((item) => item?.nome) || [],
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val && Math.round(val) + '%';
                },
            },
        },
        legend: {
            offsetX: -100,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '80%',
            },
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            formatter: function (val) {
                return val && Math.round(val) + '%';
            },
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
    };

    const optionsRadar = {
        chart: {
            id: 'radar-chart',
            type: 'radar',
        },
        title: {
            text: `Adquirido: ${infos?.dashboard?.nome}`,
        },
        xaxis: {
            categories:
                infos?.dashboard?.secoes?.map((item) => item?.nome) || [],
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return '';
                },
            },
        },
        plotOptions: {
            radar: {
                size: 140,
                polygons: {
                    strokeColor: '#e9e9e9',
                    fill: {
                        colors: ['#f8f8f8', '#fff'],
                    },
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val && Math.round(val) + '%';
            },
        },
    };

    const optionsLine = {
        chart: {
            id: 'line-chart',
            type: 'line',
            toolbar: {
                show: false,
            },
        },
        title: {
            text: `Aquisição: ${infos?.dashboard?.nome}`,
        },
        xaxis: {
            categories:
                infos?.dashboard?.secoes?.map((item) => item?.nome) || [],
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val && Math.round(val) + '%';
                },
            },
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val && Math.round(val) + '%';
                    },
                },
            },
        },
        markers: {
            size: 5,
        },
        legend: {
            offsetY: 0,
            offsetX: -80,
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            formatter: function (val) {
                return val && Math.round(val) + '%';
            },
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
    };


    const optionsBarWithNotSection= {
        chart: {
            id: 'basic-bar',
        },
        title: {
            text: `Aquisição: ${infos?.dashboardWithNotSection?.nome}`,
        },
        xaxis: {
            categories: ['Respostas'],
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val && Math.round(val) + '%';
                },
            },
        },
        legend: {
            offsetX: -100,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '80%',
            },
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            formatter: function (val) {
                return val && Math.round(val) + '%';
            },
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
    };

    const getInfos = useCallback(async () => {
        if (!startDate || !endDate) return;

        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        try {
            await ApiService.get(
                `/quizzes/dashboard/${quizId || quizIdForParents}/user/${userId}?start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
                .then((response) => {
                    console.log(response.data);
                    setInfos(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [quizId, userId, startDate, endDate, quizIdForParents]);

    useEffect(() => {
        getInfos();
    }, [getInfos]);

    // se for o questionario x entao chama funcao y e assim por diante
    const getChartData = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntasPorSecao = infos?.dashboard?.secoes?.reduce(
            (acc, secao) => {
                acc[secao.id] = secao?._count?.perguntas;
                return acc;
            },
            {}
        );

        const dataAdquirido = infos?.userCountAnswerAdquirido?.secoes?.map(
            (secao) => {
                const totalPerguntas = totalPerguntasPorSecao[secao.id];
                const perguntasAdquirido = secao?._count?.perguntas;
                return (perguntasAdquirido / totalPerguntas) * 100;
            }
        );

        const dataSuporteParcial =
            infos?.userCountAnswerSuporteParcial?.secoes?.map((secao) => {
                const totalPerguntas = totalPerguntasPorSecao[secao.id];
                const perguntasSuporteParcial = secao?._count?.perguntas;
                return (perguntasSuporteParcial / totalPerguntas) * 100;
            });

        const dataSuporteTotal =
            infos?.userCountAnswerSuporteTotal?.secoes?.map((secao) => {
                const totalPerguntas = totalPerguntasPorSecao[secao.id];
                const perguntasSuporteTotal = secao?._count?.perguntas;
                return (perguntasSuporteTotal / totalPerguntas) * 100;
            });

        const dataNao = infos?.userCountAnswerNao?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });

        const dataNaoObservado =
            infos?.userCountAnswerNaoObservado?.secoes?.map((secao) => {
                const totalPerguntas = totalPerguntasPorSecao[secao.id];
                const perguntasNaoObservado = secao?._count?.perguntas;
                return (perguntasNaoObservado / totalPerguntas) * 100;
            });

        return {
            series: [
                {
                    name: 'Adquirido',
                    data: dataAdquirido || [],
                },
                {
                    name: 'Suporte Parcial',
                    data: dataSuporteParcial || [],
                },
                {
                    name: 'Suporte Total',
                    data: dataSuporteTotal || [],
                },
                {
                    name: 'Não',
                    data: dataNao || [],
                },

                {
                    name: 'Não Observado',
                    data: dataNaoObservado || [],
                },
            ],
        };
    };

    const getChartDataWithSection = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntasPorSecao = infos?.dashboard?.secoes?.reduce(
            (acc, secao) => {
                acc[secao.id] = secao?._count?.perguntas;
                return acc;
            },
            {}
        );

        const dataSim = infos?.userCountAnswerSimWithSection?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });
    
        const dataNao = infos?.userCountAnswerNaoWithSection?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });

        return {
            series: [
                
                {
                    name: 'Sim',
                    data: dataSim || [],
                },
                {
                    name: 'Não',
                    data: dataNao || [],
                },
            ],
        };
    };

    const getChartDataWithNotSection = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntas = infos?.dashboardWithNotSection?._count?.pergunta || 0;
        const perguntasSim = infos?.userCountAnswerSimWithNotSection?._count?.pergunta || 0;
        
        const dataSim = totalPerguntas > 0 ? (perguntasSim / totalPerguntas) * 100 : 0;
        
        const perguntasNao = infos?.userCountAnswerNaoWithNotSection?._count?.pergunta || 0;
        console.log(perguntasNao)

        const dataNao = totalPerguntas > 0 ? (perguntasNao / totalPerguntas) * 100 : 0;
        console.log(dataNao)

        return {
            series: [
                {
                    name: 'Sim',
                    data: [dataSim] || [],
                },
                {
                    name: 'Não',
                    data: [dataNao] || [],
                },
            ],
        };
    };

    const getChartDataPortage = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntasPorSecao = infos?.dashboard?.secoes?.reduce(
            (acc, secao) => {
                acc[secao.id] = secao?._count?.perguntas;
                return acc;
            },
            {}
        );

        const dataSim = infos?.userCountAnswerSimWithSection?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });
    
        const dataNao = infos?.userCountAnswerNaoWithSection?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });

        const dataAsVezes = infos?.userCountAnswerAsVezes?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });

        return {
            series: [
                
                {
                    name: 'Sim',
                    data: dataSim || [],
                },
                {
                    name: 'Não',
                    data: dataNao || [],
                },
                {
                    name: 'As vezes',
                    data: dataAsVezes || [],
                }
            ],
        };
    };

    const getChartDataCars = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntasPorSecao = infos?.dashboard?.secoes?.reduce(
            (acc, secao) => {
                acc[secao.id] = secao?._count?.perguntas;
                return acc;
            },
            {}
        );

        const data1 = infos?.userCountAnswer1?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas1 = secao?._count?.perguntas;
            return (perguntas1 / totalPerguntas) * 100;
        });
    
        const data15 = infos?.userCountAnswer15?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas15 = secao?._count?.perguntas;
            return (perguntas15 / totalPerguntas) * 100;
        });

        const data2 = infos?.userCountAnswer2?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas2 = secao?._count?.perguntas;
            return (perguntas2 / totalPerguntas) * 100;
        });

        const data25 = infos?.userCountAnswer25?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas25 = secao?._count?.perguntas;
            return (perguntas25 / totalPerguntas) * 100;
        });

        const data3 = infos?.userCountAnswer3?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas3 = secao?._count?.perguntas;
            return (perguntas3 / totalPerguntas) * 100;
        });

        const data35 = infos?.userCountAnswer35?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas35 = secao?._count?.perguntas;
            return (perguntas35 / totalPerguntas) * 100;
        });

        const data4 = infos?.userCountAnswer4?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas4 = secao?._count?.perguntas;
            return (perguntas4 / totalPerguntas) * 100;
        });

        return {
            series: [
                
                {
                    name: '1 ponto',
                    data: data1 || [],
                },
                {
                    name: '1,5 pontos',
                    data: data15 || [],
                },
                {
                    name: '2 pontos',
                    data: data2 || [],
                },
                {
                    name: '2,5 pontos',
                    data: data25 || [],
                },
                {
                    name: '3 pontos',
                    data: data3 || [],
                },
                {
                    name: '3,5 pontos',
                    data: data35 || [],
                },
                {
                    name: '4 pontos',
                    data: data4 || [],
                },
            ],
        };
    };

    const getChartDataProteaR = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntasPorSecao = infos?.dashboard?.secoes?.reduce(
            (acc, secao) => {
                acc[secao.id] = secao?._count?.perguntas;
                return acc;
            },
            {}
        );

        const dataSim = infos?.userCountAnswerSimWithSection?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });
    
        const dataNao = infos?.userCountAnswerNaoWithSection?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntasNao = secao?._count?.perguntas;
            return (perguntasNao / totalPerguntas) * 100;
        });

        return {
            series: [
                
                {
                    name: 'Sim',
                    data: dataSim || [],
                },
                {
                    name: 'Não',
                    data: dataNao || [],
                },
            ],
        };
    };
   
    const getChartDataVineland = (infos) => {
        if (!infos) return { series: [] };

        const totalPerguntasPorSecao = infos?.dashboard?.secoes?.reduce(
            (acc, secao) => {
                acc[secao.id] = secao?._count?.perguntas;
                return acc;
            },
            {}
        );

        const data0 = infos?.userCountVineland0?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas1 = secao?._count?.perguntas;
            return (perguntas1 / totalPerguntas) * 100;
        });

        const data1 = infos?.userCountVineland1?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas1 = secao?._count?.perguntas;
            return (perguntas1 / totalPerguntas) * 100;
        });

        const data2 = infos?.userCountVineland2?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas2 = secao?._count?.perguntas;
            return (perguntas2 / totalPerguntas) * 100;
        });

        const dataN = infos?.userCountVinelandN?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas25 = secao?._count?.perguntas;
            return (perguntas25 / totalPerguntas) * 100;
        });

        const dataD = infos?.userCountVinelandD?.secoes?.map((secao) => {
            const totalPerguntas = totalPerguntasPorSecao[secao.id];
            const perguntas3 = secao?._count?.perguntas;
            return (perguntas3 / totalPerguntas) * 100;
        });


        return {
            series: [
                
                {
                    name: '0 - Não, nunca',
                    data: data0 || [],
                },
                {
                    name: '1 - Algumas vezes, ou parcialmente',
                    data: data1 || [],
                },
                {
                    name: '2 - Sim, normalmente',
                    data: data2 || [],
                },
                {
                    name: 'N - Não teve oportunidade',
                    data: dataN || [],
                },
                {
                    name: 'D - Desconhecido',
                    data: dataD || [],
                },
            ],
        };
    };

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    };

    const renderChartById = (id, infos) => {
        let chartData, options;
        switch (id) {
            case 11:
                chartData = getChartData(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 12:
                chartData = getChartData(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 13:
                chartData = getChartData(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 14:
                chartData = getChartData(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 62:
                chartData = getChartDataWithNotSection(infos);
                options = { ...commonOptions, ...optionsBarWithNotSection };
                break;
            case 64:
                chartData = getChartDataProteaR(infos);
                options = { ...commonOptions, ...optionsBarWithNotSection };
                break;
            case 65:
                chartData = getChartDataWithSection(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 66:
                chartData = getChartDataWithSection(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 67:
                chartData = getChartDataCars(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 69:
                chartData = getChartDataVineland(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 70:
                chartData = getChartDataPortage(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 71:
                chartData = getChartDataPortage(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 72:
                chartData = getChartDataPortage(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 73:
                chartData = getChartDataPortage(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 74:
                chartData = getChartDataPortage(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            case 75:
                chartData = getChartDataPortage(infos);
                options = { ...commonOptions, ...optionsBar };
                break;
            default:
                return null;
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={2}
            >
                <div className={matchesXlDown ? 'chart-container' : undefined}>
                    <Chart
                        options={options}
                        series={chartData?.series}
                        type='bar'
                        width='900'
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                    />

                </div>

                <div className={matchesXlDown ? 'chart-container' : undefined}>
                    <Chart
                        options={{ ...options, ...optionsRadar }}
                        series={[chartData?.series[0]]}
                        type='radar'
                        width='800'
                    />

                </div>

                <div className={matchesXlDown ? 'chart-container' : undefined}>
                    <Chart
                        options={{ ...options, ...optionsLine }}
                        series={chartData?.series}
                        type='line'
                        width='900'
                    />
                </div>
            </Box>
        );
    };

    return (
        <>
            <HeaderBreadcrumbs
                title={infos?.dashboard?.nome}
                titleUrl={
                    location?.pathname.includes('edit')
                        ? `${location?.pathname}${location.search}`
                        : location.pathname
                }
            />
            {
                quizIdForParents
                ?
                    undefined
                :
                <Button
                    sx={{ width: '80px', marginBottom: '1rem' }}
                    variant='contained'
                    color='success'
                    onClick={() => redirectToEvolution(userId, navigate, fromTab)}
                >
                    Voltar
                </Button>
            }

            <Paper sx={{ paddingY: 3 }}>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={2}
                    flexDirection={matchesMdDown ? 'column' : 'row'}
                >
                    <Typography>Filtrar por data:</Typography>
                    <DateRangePicker
                        /* small={true} */
                        startDate={startDate}
                        startDatePlaceholderText='Data inicial'
                        startDateId='data_inicio'
                        endDate={endDate}
                        endDateId='data_final'
                        endDatePlaceholderText='Data final'
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        isOutsideRange={() => false}
                        orientation={matchesXlDown ? 'vertical' : 'horizontal'}
                        
                    />
                </Box>
            </Paper>

            <Paper>
               {/*  {chartData.series.length > 0 && infos?.dashboard?.secoes?.length > 0 ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={2}
                    >
                        <Chart
                            options={optionsBar}
                            series={chartData?.series}
                            type='bar'
                            width='900'
                        />
                        {infos?.dashboard?.secoes?.length > 1 && (
                            <>
                                <Chart
                                    options={optionsRadar}
                                    series={[chartData?.series[0]]}
                                    type='radar'
                                    width='800'
                                />
                                <Chart
                                    options={optionsLine}
                                    series={chartData?.series}
                                    type='line'
                                    width='900'
                                />
                            </>
                        )}
                        
                    </Box>
                ) : undefined}
                {
                    infos?.dashboardWithNotSection?.pergunta?.length > 0 && (

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            gap={2}
                        >
                            <Chart
                                options={optionsBarWithNotSection}
                                series={chartDataWithNotSection?.series}
                                type='bar'
                                width='900'
                            />
                                {infos?.dashboardWithNotSection?.pergunta.length > 0 && (
                                    <>
                                        <Chart
                                            options={optionsRadarWithNotSection}
                                            series={[chartDataWithNotSection?.series[0]]}
                                            type='radar'
                                            width='800'
                                        />
                                        <Chart
                                            options={optionsLineWithNotSection}
                                            series={chartDataWithNotSection?.series}
                                            type='line'
                                            width='900'
                                        />
                                    </>
                                )}
                        </Box>
                    )
                } */}
                {renderChartById(Number(quizId || quizIdForParents), infos)}
            </Paper>
        </>
    );
};
