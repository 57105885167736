import Chip from "@mui/material/Chip";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import React, { useState, useCallback, useContext } from "react";
import { Autocomplete, CircularProgress, Stack, TextField, Zoom } from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useSnackbar } from "notistack";

import ApiService from "app/services/config";
import dayjs from "dayjs";
import pt from "dayjs/locale/pt-br";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ScheduleIcon from "@mui/icons-material/Schedule";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ptBRFns from "date-fns/locale/pt-BR";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { makeStyles } from "@mui/styles";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const redirectToEvolution = (user_id, navigate, tabIndex) => {
  localStorage.setItem("tabIndex", tabIndex);

  return navigate(`/evolution/user/${user_id}/edit`);
};

const ScheduleVertical = () => {
  const navigate = useNavigate();
  const useStyles = makeStyles({
    content: {
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  
  const [schedule, setSchedule] = useState([]);
  const [patients, setPatients] = useState([]);
  const [numberOfHours, setNumberOfHours] = useState(0);
  const [numberOfAvailableTimes, setNumberOfAvailableTimes] = useState(0);
  const [providerName, setProviderName] = useState(null);
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const [dateMonth, setDateMonth] = useState(dayjs().startOf('month'));
  const [providerId, setProviderId] = useState(localStorage.getItem("id"));
  const [date, setDate] = useState(new Date());
  const [appointmentsAvailablePerDay, setAppointmentsAvailablePerDay] = useState([]);
  const [numberOfSpacesPerTime, setNumberOfSpacesPerTime] = useState();
  const [validDaysSchedule, setValidDaysSchedule] = useState({
      initialDate: dayjs().startOf('month').toString(),
      finalDate: dayjs().endOf('month').toString(),
      daysOfWeek: [],
      daysOfWeekNumberVacancies: [],
  });

  const modifiers = {
      blank: date => !dayjs(date).isBetween(validDaysSchedule.initialDate, validDaysSchedule.finalDate, 'day', '[]'),
      green: date =>  dayjs(date).isBetween(validDaysSchedule.initialDate, validDaysSchedule.finalDate, 'day', '[]') && validDaysSchedule.daysOfWeek.includes(dayjs(date).day()),
      red: date => validDaysSchedule.daysOfWeekNumberVacancies[validDaysSchedule.daysOfWeek.findIndex(x => x == dayjs(date).day().toString())] <= appointmentsAvailablePerDay.filter(x => x.data == dayjs(date).format('YYYY-MM-DD'))[0]?._count?.data,
  }

  const modifiersClassNames = {
      red: '-red',
      blank: '-blank',
      green: '-green',
      selected: '-selectedTest'
  }
  
  const [blockingReason, setBlockingReason] = useState(null);

  const [dayOfWeek, setDayOfWeek] = useState(dayjs(date).locale(pt).format('dddd'));
  const [dayFormatted, setDayFormatted] = useState(dayjs(date).format('DD/MM/YYYY'));

  const Swal = useSwalWrapper();
  const { enqueueSnackbar } = useSnackbar();

  const modalAlert = (scheduleId) => {
      Swal.fire({
          title: 'Tem certeza que deseja apagar?',
          text: 'Não será possível reverter a ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          reverseButtons: true,
      }).then((result) => {
          if (result.value) {
              cancelSchedule(scheduleId);
          }
    });
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/prestadores?searchTerm=${inputValue}`
      );
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const printSchedulePdf = (patients) => {
    const doc = new jsPDF();
    const headers = [["Horário", "Tipo agendamento", "Paciente", "Telefone"]];

    patients.sort((a, b) => parseFloat(a.hora) - parseFloat(b.hora));
    const data = patients.map((p) => {
      return [p.hora, p.tipoAgenda, p.paciente, p.telefone];
    });

    const content = {
      startY: 55,
      head: headers,
      body: data,
    };
    doc.setFont("Helvetica", "");
    doc.setTextColor("#E96690");

    doc.setFontSize(14);
    doc.text("Agenda médica", 100, 20);

    doc.setTextColor("#2980BA");
    doc.setFontSize(12);

    doc.text(`Dr.(a) ${providerName}`, 15, 50);
    doc.text(`Data agendamento: ${date.format("DD/MM/YYYY")}`, 135, 50);

    const url = `${ASSET_IMAGES}/logo_mae_coruja_small.png`;
    doc.addImage(url, 20, 5, 29, 35);
    doc.autoTable(content);
    doc.save("agenda.pdf");
  };

  const formatStatus = (status) => {
    switch (status) {
      case "agendado":
        return { bgColor: "#FF8224", label: "Agendado" };
      case "em_atendimento":
        return {
          bgColor: "linear-gradient(to right, #007EBA 50%, #AF1E87 50%)",
          label: "Em atendimento",
        };
      case "atendido":
        return { bgColor: "#AF1E87", label: "Atendido" };
      case "confirmado_presenca":
        return { bgColor: "#007EBA", label: "Confirmada presença" };
      default:
        return { bgColor: "#3BD2A2", label: "Disponível" };
    }
  };

  const cancelSchedule = useCallback(
    async (scheduleId) => {
      ApiService.delete(`/schedules/${scheduleId}`)
        .then((response) => {
          enqueueSnackbar("Agendamento apagado com sucesso", {
            variant: "success",
            
          });
          getSchedule(date);
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: "error",
              
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: "error",
              
            });
          }
        });
    },
    [date]
  );

  const fitInTheSchedule = useCallback(
    async (scheduleId) => {
      ApiService.post(`/schedules/fit-in-the-schedule/${scheduleId}`)
        .then((response) => {
          console.log("response", response);
          enqueueSnackbar("Agendamento encaixado com sucesso", {
            variant: "success",
            
          });
          getSchedule(date);
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${errorMessage[0]}`, {
              variant: "error",
              
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: "error",
              
            });
          }
        });
    },
    [date]
  );

  const getSchedule = React.useCallback(
    async (dt) => {
      setIsLoading(true);
      try {
        let apiUrl = `/schedules/get-schedule-today?date=${dt}&providerId=${providerId}`;

        await ApiService.get(apiUrl)
          .then((response) => {
            const blockingReasonsMessage = {
              feriado: "Feriado",
              ferias: "Férias",
              motivo_nao_informado: "Motivo não informado",
              viagem: "Viagem",
            };
            setPatients(response.data.patients);
            setSchedule(response.data.availableTime);
            setNumberOfAvailableTimes(response.data.numberOfAvailableTimes);
            setNumberOfHours(response.data.numberOfHours);
            setProviderName(response.data.providerName);
            setNumberOfSpacesPerTime(response.data.numberOfSpacesPerTime);
            // setStartDate(response.data.startDate)
            // setEndDate(response.data.endDate)
            setAppointmentsAvailablePerDay(
              response.data.appointmentsAvailablePerDay
            );
            setDayOfWeek(dayjs(date).locale(pt).format("dddd"));
            setDayFormatted(dayjs(date).format("DD/MM/YYYY"));
            setBlockingReason(
              response.data.blockingReason
                ? blockingReasonsMessage[response.data.blockingReason]
                : null
            );
            setValidDaysSchedule(response.data.validDaysSchedule);

            setTimeout(() => {
              setIsLoading(false);
            }, 300);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [date, providerId]
  );

  const getProviders = React.useCallback(async () => {
    try {
      await ApiService.get(`/users/providers`)
        .then((response) => {
          setProviders(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  React.useEffect(() => {
    getProviders();
    getSchedule(date);
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Agenda"}
        subtitle={"Agenda Vertical"}
        titleUrl={"/schedule-worker"}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        {/* <Typography variant='h2' gutterBottom style={{ marginBottom: '15px' }} >
                    Agenda vertical
                </Typography> */}
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="space-around"
        >
          {/* <Grid item xs={6} sm={4} md={4} lg={3} xl={2}> */}
          {isLoading ? (
            <CircularProgress style={{ alignSelf: "center" }} />
          ) : (
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Agenda futura
              </FormLabel>
              <DatePickerCalendar
                locale={ptBRFns}
                date={date}
                modifiers={modifiers}
                modifiersClassNames={modifiersClassNames}
                weekdayFormat="EEEEE"
                onDateChange={(event) => {
                  setDate(event);
                }}
              />
            </Grid>
          )}

          <Grid item xs={8} sm={5} md={4} lg={3}>
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={pt}
                localeText={
                  ptBR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <Stack>
                  <DesktopDatePicker
                    sx={{ background: "#ffffff" }}
                    views={["month", "year"]}
                    label="Filtrar por ano"
                    value={dateMonth}
                    onChange={(event) => {
                      const newValue = dayjs(event.$d).startOf("month");
                      console.log("newValue", newValue);

                      setDateMonth(newValue);
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                <Autocomplete 
                  fullWidth
                  disablePortal
                  getOptionLabel={(option) => option.nome}
                  options={optionsList}
                  loading={loading}
                  inputValue={userInput}
                  onInputChange={handleInputChange}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setUserSelected((prevValues) => ({
                        selected: newValue,
                      }));
                      setProviderId(newValue.id)
                    }
                  }}
                  noOptionsText={"Nenhum resultado encontrado"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Prestador"
                      placeholder="Prestador"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? "carregando..." : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Tooltip>
            </FormControl>

            <Button
              fullWidth
              endIcon={<SearchIcon />}
              variant="outlined"
              onClick={() => getSchedule(date)}
            >
              Buscar
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={2}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Todos Os Dias</FormLabel>
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"
                                onChange={(x) =>setIsEveryDay(x.target.value)}
                                value={isEveryDay}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="Sim" />
                                <FormControlLabel value="false" control={<Radio />} label="Não"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}
        </Grid>

        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Paper
              style={{
                padding: "10px",
                marginBottom: "15px",
                overflowX: "auto",
                overflowY: "auto",
              }}
              square={false}
              elevation={3}
            >
              <Grid item>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{ marginBottom: "15px" }}
                >
                  Agenda Prestador
                </Typography>
              </Grid>
              <Grid item>
                <Divider
                  style={{
                    height: "100%",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                />
              </Grid>
              <Grid item>
                <TableContainer sx={{ marginBottom: "10px" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead
                      sx={{
                        backgroundColor: "primary.main",
                        borderRadius: "8px",
                      }}
                    >
                      <TableRow>
                        <TableCell align="center" style={{ color: "white" }}>
                          Dia
                        </TableCell>
                        <TableCell align="center" style={{ color: "white" }}>
                          Data
                        </TableCell>
                        <TableCell align="center" style={{ color: "white" }}>
                          Quantidade Horario
                        </TableCell>
                        <TableCell align="center" style={{ color: "white" }}>
                          Horarios livres
                        </TableCell>
                        <TableCell align="center" style={{ color: "white" }}>
                          Imprimir
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{dayOfWeek} </TableCell>
                        <TableCell align="center">{dayFormatted}</TableCell>
                        <TableCell align="center">{numberOfHours}</TableCell>
                        <TableCell align="center">
                          {numberOfAvailableTimes}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Imprimir agenda" enterDelay={0}>
                            <Button
                              onClick={() => printSchedulePdf(patients)}
                              variant="outlined"
                              style={{
                                marginLeft: "5px",
                                minWidth: "auto",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <EditCalendarIcon />
                            </Button>
                          </Tooltip>
                          {/* <Tooltip title="Reagendar" enterDelay={0}>
                                                            <Link to="/ScheduleVertical" style={{ textDecoration: 'none' }}>
                                                                <Button variant="outlined" style={{ marginLeft: '5px', minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
                                                                    <EditNoteIcon />
                                                                </Button>
                                                            </Link>
                                                        </Tooltip>
                                                        <Tooltip title="Reagendar" enterDelay={0}>
                                                            <Link to="/ScheduleVertical" style={{ textDecoration: 'none' }}>
                                                                <Button variant="outlined" style={{ marginLeft: '5px', minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
                                                                    <LocalOfferIcon />
                                                                </Button>
                                                            </Link>
                                                        </Tooltip> */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  {schedule.map((consulta, index) => (
                    <Accordion key={index} defaultExpanded>
                      <AccordionSummary
                        classes={{ content: classes.content }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        {consulta.bloqueado == true ? (
                          <>
                            <Chip
                              label={consulta.horario}
                              color="secondary"
                              sx={{ fontSize: "20px", marginRight: 1 }}
                              icon={<ScheduleIcon />}
                            />
                            <Tooltip
                              title={`Motivo: ${blockingReason}`}
                              enterDelay={0}
                            >
                              <Chip
                                label={"Horário Bloqueado"}
                                color="secondary"
                                sx={{ fontSize: "20px" }}
                                icon={<InfoOutlinedIcon />}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          <Chip
                            label={consulta.horario}
                            color="success"
                            sx={{ fontSize: "20px" }}
                            icon={<ScheduleIcon />}
                          />
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center">Status</TableCell>
                              <TableCell align="center">Obs</TableCell>
                              <TableCell align="center">Hora</TableCell>
                              <TableCell align="center">Paciente</TableCell>
                              <TableCell align="center">Telefone</TableCell>
                              <TableCell align="center">
                                Tipo agendamento
                              </TableCell>
                              <TableCell align="center">Convênio</TableCell>
                              {/* <TableCell align="center">Valor</TableCell> */}
                              <TableCell align="center">Opçoes</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {patients
                              .filter(
                                (patient) => patient.hora == consulta.horario
                              )
                              .map((row, index) => (
                                <TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <Tooltip
                                      title="Editar agendamento"
                                      enterDelay={0}
                                    >
                                      <Link
                                        to={`/schedule-worker/pacient-schedule/${row.id}`}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <Button
                                          variant="outlined"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          <EditIcon />
                                        </Button>
                                      </Link>
                                    </Tooltip>

                                    <Tooltip
                                      title="Encaixar horário"
                                      enterDelay={0}
                                    >
                                      <Button
                                        variant="outlined"
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                          fitInTheSchedule(row.id);
                                        }}
                                      >
                                        <ContentCopyIcon />
                                      </Button>
                                    </Tooltip>
                                  </TableCell>

                                  <TableCell align="center">
                                    {
                                      <Chip
                                        label={formatStatus(row.status).label}
                                        sx={{
                                          background: formatStatus(row.status)
                                            .bgColor,
                                          color: "#ffffff",
                                        }}
                                        size="small"
                                      />
                                    }
                                    {row.encaixe && (
                                      <Chip
                                        label={"Encaixe"}
                                        sx={{
                                          background: "#ccc",
                                          color: "#ffffff",
                                          marginLeft: 0.5,
                                        }}
                                        size="small"
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.obs ? (
                                      <Tooltip title={row.obs} enterDelay={0}>
                                        <ChatBubbleIcon />
                                      </Tooltip>
                                    ) : (
                                      <Typography>-</Typography>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.hora}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.paciente}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.telefone}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.tipoAgenda}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.convenio}
                                  </TableCell>
                                  {/* <TableCell align="center">{row.valor}</TableCell> */}
                                  {/* <Tooltip title="Reagendar" enterDelay={0}>
                                                            <Link to="/ScheduleVertical" style={{ textDecoration: 'none' }}>
                                                            <Button variant="outlined" style={{ marginLeft: '5px', minWidth: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
                                                                <HistoryIcon />
                                                            </Button>
                                                            </Link>
                                                        </Tooltip> */}
                                  <TableCell align="center">
                                    {row.user_id && (
                                      <Tooltip
                                        title="Visualizar ficha do paciente"
                                        enterDelay={0}
                                        sx={{ marginRight: "5px" }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            redirectToEvolution(
                                              row.user_id,
                                              navigate,
                                              8
                                            )
                                          }
                                        >
                                          <VisibilityIcon />
                                        </Button>
                                      </Tooltip>
                                    )}

                                    <Tooltip
                                      title="Cancelar agendamento"
                                      enterDelay={0}
                                    >
                                      <Button
                                        variant="outlined"
                                        onClick={() => modalAlert(row.id)}
                                      >
                                        <AutoDeleteIcon />
                                      </Button>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}

                            {patients.reduce(
                              (n, patient) =>
                                patient.hora == consulta.horario &&
                                !patient.encaixe
                                  ? n + 1
                                  : n,
                              0
                            ) < numberOfSpacesPerTime &&
                              consulta.bloqueado !== true && (
                                <TableCell align="center">
                                  <Tooltip
                                    title="Criar agendamento"
                                    enterDelay={0}
                                  >
                                    <Link
                                      to={`/schedule-worker/pacient-schedule/new?workerId=${providerId}&date=${dayjs(
                                        date
                                      ).format("YYYY-MM-DD")}&hour=${
                                        consulta.horario
                                      }`}
                                      state={{ backUrl: '/ScheduleVertical' }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <Button
                                        variant="outlined"
                                        style={{ marginLeft: "5px" }}
                                      >
                                        <AddCircleIcon />
                                      </Button>
                                    </Link>
                                  </Tooltip>
                                </TableCell>
                              )}

                            {/* <TableRow>
                                                    <TableCell align="right" colSpan={8}></TableCell>
                                                    <TableCell align="center" colSpan={1}>Valor Total : R$ 2000</TableCell>
                                                </TableRow> */}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  {schedule.length === 0 && (
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      Nenhum horário disponível
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: '20px' }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item>
                        <Typography variant="h1"> Valor total: R$ 100,00 </Typography>
                    </Grid>
                </Grid> */}
      </Paper>
    </div>
  );
};

export default ScheduleVertical;
