import React, { useCallback, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { updateInputValue } from 'app/utils/appHelpers';
import DataTable from '../DataTable';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ApiService from 'app/services/config';
import { FormHelperText } from '@mui/material';
import { FieldArray } from 'formik';
import { ContactItem } from 'app/components/ContactItem/ContactItem';
import InputMask from 'react-input-mask';

function Contacts({
    infos,
    values,
    setFieldValue,
    contact,
    setContact,
    handleChange,
}) {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});
    const allFieldsEmpty = Object.values(contact).every((value) => !value);

    const contactSchema = Yup.object().shape({
        tipo_de_contato: Yup.string().required(
            'O tipo de contato é obrigatório.'
        ),
        contato: Yup.string()
            .when('tipo_de_contato', {
                is: 'Email',
                then: Yup.string()
                    .email('Digite um email válido.')
                    .required('Campo obrigatório'),
            })
            .when('tipo_de_contato', {
                is: 'WhatsApp',
                then: Yup.string()
                    .matches(
                        /^\(\d{2}\)\d{5}-\d{4}$/,
                        'Digite um número de telefone válido.'
                    )
                    .required('Campo obrigatório'),
            }),
    });

    const handleEditContact = (contactToEdit) => {
        setContact(contactToEdit);
        setIsEditMode(true);
    };
    const modalAlert = (contactId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                console.log('bem bem antes', values.Contatos);
                removeTelephone(contactId);
            }
        });
    };

    const removeTelephone = useCallback(
        async (contactId) => {
            ApiService.delete(`/general-contacts/${contactId}`)
                .then((response) => {
                    const contactEdit = contact.id === contactId;
                    const removedContact = values.Contatos.filter(
                        (contact) => contact.id !== contactId
                    );
                    setFieldValue('Contatos', removedContact);
                    enqueueSnackbar('Contato removido com sucesso!', {
                        variant: 'success',
                        
                    });
                    if (contactEdit) {
                        setIsEditMode(false);
                        setContact({
                            // Limpa o estado do contato
                            tipo_de_contato: '',
                            contato: '',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [enqueueSnackbar, setFieldValue, values.Contatos]
    );
    console.log(contact);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '15px' }}
                >
                    Contatos
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <FormControl
                    fullWidth
                    variant='outlined'
                    error={!!errors.tipo_de_contato}
                >
                    <InputLabel id='demo-simple-select-label'>
                        Tipo de Contato
                    </InputLabel>
                    <Select
                        name='Contatos.tipo_de_contato'
                        label='Tipo de Contato'
                        value={contact.tipo_de_contato}
                        onChange={(event) => {
                            setContact({
                                ...contact,
                                tipo_de_contato: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={'Email'}>Email</MenuItem>
                        <MenuItem value={'WhatsApp'}>WhatsApp</MenuItem>
                        {/* adicionar ou remover opçoes conforme solicitado */}
                    </Select>
                    {errors.tipo_de_contato && (
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors.tipo_de_contato}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                {contact.tipo_de_contato === 'Email' ? (
                    <TextField
                        fullWidth
                        name='Contatos.contato'
                        label='Contato'
                        value={contact.contato}
                        onChange={(event) => {
                            setContact({
                                ...contact,
                                contato: event.target.value,
                            });
                        }}
                        error={!!errors.contato}
                        helperText={errors.contato}
                    />
                ) : (
                    <InputMask
                        mask='(99)99999-9999'
                        value={contact.contato}
                        onChange={(event) => {
                            setContact({
                                ...contact,
                                contato: event.target.value,
                            });
                        }}
                    >
                        {(inputProps) => (
                            <TextField
                                fullWidth
                                name='Contatos.contato'
                                label='Contato'
                                {...inputProps}
                                error={!!errors.contato}
                                helperText={errors.contato}
                            />
                        )}
                    </InputMask>
                )}
            </Grid>
            <Grid sm={3}>
                {id ? (
                    <Grid xs={6}>
                        <Button
                            variant='contained'
                            style={{ marginTop: '10px' }}
                            disabled={allFieldsEmpty}
                            onClick={() => {
                                contactSchema
                                    .validate(contact, { abortEarly: false })
                                    .then(() => {
                                        setErrors({});
                                        if (isEditMode) {
                                            console.log(contact);
                                            const contactId = contact.id;

                                            ApiService.put(
                                                `/general-contacts/${contactId}`,
                                                contact
                                            )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Contato atualizado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    const updatedTodos =
                                                        values.Contatos.map(
                                                            (todo) => {
                                                                if (
                                                                    todo.id ===
                                                                    response
                                                                        .data.id
                                                                ) {
                                                                    return response.data; // Retorna o telefone atualizado
                                                                }
                                                                return todo;
                                                            }
                                                        );
                                                    setFieldValue(
                                                        'Contatos',
                                                        updatedTodos
                                                    ); // Atualiza a lista de contatos com o contato atualizado
                                                    setIsEditMode(false); // Sai do modo de edição
                                                    setContact({
                                                        // Limpa o estado do contato
                                                        tipo_de_contato: '',
                                                        contato: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        } else {
                                            const userContact = {
                                                user_id: Number(id),
                                                tipo_de_contato:
                                                    contact.tipo_de_contato,
                                                contato: contact.contato,
                                            };

                                            ApiService.post(
                                                `/general-contacts`,
                                                userContact
                                            )
                                                .then((response) => {
                                                    const newContact = [
                                                        ...values.Contatos,
                                                        response.data,
                                                    ];
                                                    enqueueSnackbar(
                                                        'Telefone criado com sucesso!',
                                                        {
                                                            variant: 'success',
                                                            
                                                        }
                                                    );
                                                    setFieldValue(
                                                        'Contatos',
                                                        newContact
                                                    );
                                                    setContact({
                                                        // Limpa o estado do contato
                                                        tipo_de_contato: '',
                                                        contato: '',
                                                    });
                                                })
                                                .catch((error) => {
                                                    const errorMessage =
                                                        error.response.data
                                                            .message;
                                                    console.log(errorMessage);
                                                    if (
                                                        Array.isArray(
                                                            errorMessage
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            `${errorMessage[0]}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    } else {
                                                        enqueueSnackbar(
                                                            `${errorMessage}`,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                });
                                        }
                                    })
                                    .catch((validationErrors) => {
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                enqueueSnackbar(error.message, {
                                                    variant: 'error',
                                                    
                                                });
                                            }
                                        );
                                        const errors = {};
                                        validationErrors.inner.forEach(
                                            (error) => {
                                                errors[error.path] =
                                                    error.message;
                                            }
                                        );
                                        console.log(errors);
                                        setErrors(errors);
                                    });
                            }}
                        >
                            {isEditMode ? 'Editar Contato' : 'Incluir Contato'}
                        </Button>
                    </Grid>
                ) : undefined}
            </Grid>
            {id ? (
                <Grid item xs={12}>
                    <FieldArray name='Contatos'>
                        {({ remove, push }) =>
                            values?.Contatos?.map((cont) => (
                                <ContactItem
                                    contact={cont}
                                    handleEditContact={handleEditContact}
                                    modalAlert={modalAlert}
                                />
                            ))
                        }
                    </FieldArray>
                </Grid>
            ) : undefined}
        </Grid>
    );
}
export default Contacts;
