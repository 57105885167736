import Page from "@jumbo/shared/Page";
import NewAgreement from "app/pages/Agreement/NewAgreement";
import GeneralArchivesSend from "app/pages/GeneralArchivesSend";
import PacientSchedule from "app/pages/agenda_prestador/PacientSchedule";
import PendingSchedules from "app/pages/agenda_prestador/PendingSchedules";
import PeriodForm from "app/pages/agenda_prestador/PeriodForm";
import ScheduleList from "app/pages/agenda_prestador/ScheduleList";
import ScheduleWorker from "app/pages/agenda_prestador/ScheduleWorker";
import SchedulesClosing from "app/pages/agenda_prestador/SchedulesClosing";
import Especialidades from "app/pages/especializaçao/Especialidades";
import NovaEspecialidade from "app/pages/especializaçao/NovaEspecialidade";
import EvolutionSheetIndex from "app/pages/evolutionSheet/EvolutionSheetIndex";
import GeneralDocumentsForm from "app/pages/evolutionSheet/GeneralDocumentsForm";
import InterventionPlanForm from "app/pages/evolutionSheet/InterventionPlanForm";
import PatientList from "app/pages/evolutionSheet/PatientList";
import InterdisciplinaryFour from "app/pages/fichaDiaria/InterdisciplinaryFour";
import InterdisciplinaryOne from "app/pages/fichaDiaria/InterdisciplinaryOne";
import InterdisciplinaryThree from "app/pages/fichaDiaria/InterdisciplinaryThree";
import InterdisciplinaryTwo from "app/pages/fichaDiaria/InterdisciplinaryTwo";
import LevelFour from "app/pages/fichaDiaria/LevelFour";
import LevelOne from "app/pages/fichaDiaria/LevelOne";
import LevelThree from "app/pages/fichaDiaria/LevelThree";
import LevelTwo from "app/pages/fichaDiaria/LevelTwo";
import PsychologyFour from "app/pages/fichaDiaria/PsychologyFour";
import PsychologyOne from "app/pages/fichaDiaria/PsychologyOne";
import PsychologyThree from "app/pages/fichaDiaria/PsychologyThree";
import PsychologyTwo from "app/pages/fichaDiaria/PsychologyTwo";
import Guidelines from "app/pages/health/Guidelines";
import NewGuidelines from "app/pages/health/NewGuidelines";
import Pacient from "app/pages/pacient/Pacient";
import NewTeam from "app/pages/pool/NewTeam";
import Team from "app/pages/pool/Team";
import ProtocoloReabilitação from "app/pages/protocolo/ProtocoloReabilitação";
import ProfileForm from "app/pages/role/ProfileForm";
import ProfileList from "app/pages/role/ProfileList";
import ActiveOrInactivePatient from "app/pages/search/ActiveOrInactivePatient";
import Atendimentos from "app/pages/search/Atendimentos";
import BirthdayReport from "app/pages/search/BirthdayReport";
import Birthdays from "app/pages/search/Birthdays";
import ScheduleCopy from "app/pages/search/ScheduleCopy";
import ScheduleQuery from "app/pages/search/ScheduleQuery";
import { ScheduleQueryItem } from "app/pages/search/ScheduleQueryItem";
import ScheduleVertical from "app/pages/search/ScheduleVertical";
import NewUserPool from "app/pages/user-pool/NewUserPool";
import UserPool from "app/pages/user-pool/UserPool";
import UsersForm from "app/pages/users/UsersForm";
import UsersList from "app/pages/users/UsersList";
import Login from "../pages/login";
import Index from "../pages/people/Index";
import PeopleList from "../pages/people/People";
import Unification from "../pages/unification/Unification";
import Unified from "../pages/unification/Unified";
import ProtectedRoute from "./ProtectedRoute";
import PeerTherapy from "app/pages/fichaDiaria/PeerTherapy";
import KitchenTherapy from "app/pages/fichaDiaria/KitchenTherapy";
import AquaticOccupationalTherapy from "app/pages/fichaDiaria/AquaticOccupationalTherapy";
import Teste from "app/pages/fichaDiaria/AllQuizzes";
import AllQuizzes from "app/pages/fichaDiaria/AllQuizzes";
import { Dashboard } from "app/pages/fichaDiaria/Dashboard";
import ScheduleReport from "app/pages/search/ScheduleReport";
import AtendimentosGerais from "app/pages/search/AtendimentosGerais";
import FiliaisList from "app/pages/filiais/FiliaisList";
import FiliamForm from "app/pages/filiais/FilialForm";
import UpdatePassword from "app/pages/users/UpdatePassword";
import InterventionPlanFiles from "app/pages/evolutionSheet/InterventionPlanFiles";
import ScheduleModel from "app/pages/scheduleModel/ScheduleModel";


import GeneralQuizzesForm from "app/pages/evolutionSheet/GeneralQuizzesForm";
import EvolutionSheetIndexForParents from "app/pages/evolutionSheet/EvolutionSheetIndexForParents";
import PatientListForParents from "app/pages/evolutionSheet/PatientListForParents";
import Home from "app/pages/home";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  /* {
        path: "/",
        element:
        <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'read'}
      component={PeopleList}
    />,
     <Page component={Home} />
    }, */

  {
    path: "/login",
    element: <Page component={Login} layout={"solo-page"} />,
  },
];

const routesForAuthenticatedOnly = [
  {
    path: "/",
    
    /* element: <ProtectedRoute
      permission={'Agenda'} 
      requiredRole={'read'}
      component={ScheduleVertical}
      MainComponent={ScheduleVertical}
    />, */
    /* element: <Page component={ScheduleVertical} />, */
    element: <Page component={Home} />,
  },
  {
    path: "/people",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'read'}
      component={PeopleList}
      MainComponent={PeopleList}
    />,
  },
  {
    path: "/people/unification",
    element:
    <ProtectedRoute
      permission={'Unificação'} 
      requiredRole={'read'}
      component={Unification}
      MainComponent={Unification}
    />,
  },
  {
    path: "/people/unified",
    element:
    <ProtectedRoute
      permission={'Unificação'} 
      requiredRole={'read'}
      component={Unified}
      MainComponent={Unified}
    />,
  },
  {
    path: "/people/create",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={Index}
      MainComponent={Index}
    />,
  },
  {
    path: "/people/edit/:id",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={Index}
      MainComponent={Index}
    />,
  },

  {
    path: "/evolution",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'read'}
      component={PatientList}
      MainComponent={PatientList}
    />,
  },

  {
    path: "/evolution/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'update'}
      component={Index}
      MainComponent={EvolutionSheetIndex}
    />,
  },

  {
    path: "/evolution/user/:userId/docs/new",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'create'}
      component={Index}
      MainComponent={GeneralDocumentsForm}
    />,
  },

  {
    path: "/evolution/user/:userId/docs/edit/:id",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'update'}
      component={Index}
      MainComponent={GeneralDocumentsForm}
    />,
  },

  {
    path: "/evolution/user/:userId/intervention/new",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'create'}
      component={PeopleList}
      MainComponent={InterventionPlanForm}
    />,
  },
  {
    path: "/evolution/user/:userId/intervention/edit/:id",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'update'}
      component={Index}
      MainComponent={InterventionPlanForm}
    />,
  },
  {
    path: "/evolution/user/:userId/intervention/file/:id",
    element:
    <ProtectedRoute
      permission={'Paciente Evolução'} 
      requiredRole={'update'}
      component={Index}
      MainComponent={InterventionPlanFiles}
    />,
  },

  
  {
    path: "/evolution-parents",
    element:
    <ProtectedRoute
      permission={'Acesso dos Pais'} 
      requiredRole={'read'}
      component={PatientListForParents}
      MainComponent={PatientListForParents}
    />,
  },

  {
    path: "/evolution-parents/user/:userId/",
    element:
    <ProtectedRoute
      permission={'Acesso dos Pais'} 
      requiredRole={'read'}
      component={EvolutionSheetIndexForParents}
      MainComponent={EvolutionSheetIndexForParents}
    />,
  },

  {
    path: "/schedule-worker",
    element:
    <ProtectedRoute
      permission={'Agenda Horizontal'} 
      requiredRole={'read'}
      component={ScheduleWorker}
      MainComponent={ScheduleWorker}
    />,
  },
  {
    path: "/schedule-worker/pacient-schedule/:scheduleId",
    element:
    <ProtectedRoute
      permission={'Agenda Horizontal'} 
      requiredRole={'update'}
      component={PacientSchedule}
      MainComponent={PacientSchedule}
    />,
  },
  {
    path: "/schedule-worker/pacient-schedule/new",
    element:
    <ProtectedRoute
      permission={'Agenda Horizontal'} 
      requiredRole={'create'}
      component={PacientSchedule}
      MainComponent={PacientSchedule}
    />,
  },
  {
    path: "/schedule-worker-logged",
    element:
    <ProtectedRoute
      permission={'Agenda Prestador'} 
      requiredRole={'read'}
      component={ScheduleWorker}
      MainComponent={ScheduleWorker}
    />,
  },
  {
    path: "/schedule-list",
    element:
    <ProtectedRoute
      permission={'Gerar Agenda'} 
      requiredRole={'read'}
      component={ScheduleList}
      MainComponent={ScheduleList}
    />,
  },
  {
    path: "/period/new",
    element:
    <ProtectedRoute
      permission={'Agenda'} 
      requiredRole={'create'}
      component={PeriodForm}
      MainComponent={PeriodForm}
    />,
  },
  {
    path: "/period/:id",
    element:
    <ProtectedRoute
      permission={'Agenda'} 
      requiredRole={'update'}
      component={PeriodForm}
      MainComponent={PeriodForm}
    />,
  },
  {
    path: "/SchedulesClosing",
    element:
    <ProtectedRoute
      permission={'Editar Horários'} 
      requiredRole={'read'}
      component={SchedulesClosing}
      MainComponent={SchedulesClosing}
    />,
  },
  {
    path: "/Guidelines",
    element:
    <ProtectedRoute
      permission={'Orientações'} 
      requiredRole={'read'}
      component={Guidelines}
      MainComponent={Guidelines}
    />,
  },
  {
    path: "/NewGuidelines",
    element:
    <ProtectedRoute
      permission={'Orientações'} 
      requiredRole={'create'}
      component={NewGuidelines}
      MainComponent={NewGuidelines}
    />,
  },
  {
    path: "/Team",
    element:
    <ProtectedRoute
      permission={'Turma'} 
      requiredRole={'read'}
      component={Team}
      MainComponent={Team}
    />,
  },
  {
    path: "/NewTeam",
    element:
    <ProtectedRoute
      permission={'Turma'} 
      requiredRole={'create'}
      component={NewTeam}
      MainComponent={NewTeam}
    />,
  },
  {
    path: "/team/:id",
    element:
    <ProtectedRoute
      permission={'Turma'} 
      requiredRole={'update'}
      component={NewTeam}
      MainComponent={NewTeam}
    />,
  },
  {
    path: "/schedule-query",
    element:
    <ProtectedRoute
      permission={'Consulta de Agendamentos'} 
      requiredRole={'read'}
      component={ScheduleQuery}
      MainComponent={ScheduleQuery}
    />,
  },
  {
    path: "/schedule-query-list",
    element:
    <ProtectedRoute
      permission={'Agenda'} 
      requiredRole={'read'}
      component={ScheduleQueryItem}
      MainComponent={ScheduleQueryItem}
    />,
  },
  {
    path: "/ScheduleCopy",
    element:
    <ProtectedRoute
      permission={'Copiar Argumento'} 
      requiredRole={'create'}
      component={ScheduleCopy}
      MainComponent={ScheduleCopy}
    />,
  },
  {
    path: "/ScheduleModel",
    element:
    <ProtectedRoute
      permission={'Copiar Argumento'} // Criar permissão pra essa tela
      requiredRole={'create'}
      component={ScheduleModel}
      MainComponent={ScheduleModel}
    />,
  },
  {
    path: "/ScheduleVertical",
    element:
    <ProtectedRoute
      permission={'Agenda Vertical'} 
      requiredRole={'read'}
      component={ScheduleVertical}
      MainComponent={ScheduleVertical}
    />,
  },
  {
    path: "/Pacient",
    element:
    <ProtectedRoute
      permission={'Paciente'} 
      requiredRole={'read'}
      component={Pacient}
      MainComponent={Pacient}
    />,
  },
  {
    path: "/Birthdays",
    element:
    <ProtectedRoute
      permission={'Relatórios'} 
      requiredRole={'read'}
      component={Birthdays}
      MainComponent={Birthdays}
    />,
  },
  {
    path: "/relatorios/agendamentos",
    element:
    <ProtectedRoute
      permission={'Relatórios'} 
      requiredRole={'read'}
      component={ScheduleReport}
      MainComponent={ScheduleReport}
    />,
  },
  {
    path: "/BirthdayReport",
    element:
    <ProtectedRoute
      permission={'Relatórios'} 
      requiredRole={'read'}
      component={BirthdayReport}
      MainComponent={BirthdayReport}
    />,
  },
  {
    path: "/ActiveOrInactivePatient",
    element:
    <ProtectedRoute
      permission={'Relatórios'} 
      requiredRole={'read'}
      component={ActiveOrInactivePatient}
      MainComponent={ActiveOrInactivePatient}
    />,
  },
  {
    path: "/Atendimentos",
    element:
    <ProtectedRoute
      permission={'Relatórios'} 
      requiredRole={'read'}
      component={Atendimentos}
      MainComponent={Atendimentos}
    />,
  },
  {
    path: "/atendimentos-gerais",
    element:
    <ProtectedRoute
      permission={'Relatórios'} 
      requiredRole={'read'}
      component={AtendimentosGerais}
      MainComponent={AtendimentosGerais}
    />,
  },
  {
    path: "/NewAgreement",
    element:
    <ProtectedRoute
      permission={'Registros de convênio'} 
      requiredRole={'read'}
      component={NewAgreement}
      MainComponent={NewAgreement}
    />,
  },

  {
    path: "/sheet/:quizId/user/:userId",
    element: <AllQuizzes component={AllQuizzes} />,
  },
  {
    path: "/evaluation/:evaluationId/patient/:patientId",
    element: <GeneralQuizzesForm component={GeneralQuizzesForm} />,
  },
  {
    path: "/evaluation/:evaluationId/patient/:patientId/edit",
    element: <GeneralQuizzesForm component={GeneralQuizzesForm} />,
  },
  {
    path: "/sheet/:quizId/user/:userId/edit",
    element: <AllQuizzes component={AllQuizzes} />,
  },

  {
    path: "/quizzes/dashboard/:quizId/user/:userId",
    element: <Dashboard component={Dashboard} />,
  },

  {
    path: "/LevelOne/:quizzId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={LevelOne}
      MainComponent={LevelOne}
    />,
  },
  {
    path: "/LevelOne/:quizzId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={LevelOne}
      MainComponent={LevelOne}
    />,
  },
  {
    path: "/LevelTwo/:quizId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={LevelTwo}
      MainComponent={LevelTwo}
    />,
  },
  {
    path: "/LevelTwo/:quizId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={LevelTwo}
      MainComponent={LevelTwo}
    />,
  },
  {
    path: "/LevelThree/:quizId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={LevelThree}
      MainComponent={LevelThree}
    />,
  },
  {
    path: "/LevelThree/:quizId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={LevelThree}
      MainComponent={LevelThree}
    />,
  },
  {
    path: "/LevelFour/:quizId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={LevelFour}
      MainComponent={LevelFour}
    />,
  },
  {
    path: "/LevelFour/:quizId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={LevelFour}
      MainComponent={LevelFour}
    />,
  },
  {
    path: "/PeerTherapy/:quizId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={PeerTherapy}
      MainComponent={PeerTherapy}
    />,
  },
  {
    path: "/PeerTherapy/:quizId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={PeerTherapy}
      MainComponent={PeerTherapy}
    />,
  },
  {
    path: "/KitchenTherapy/:quizId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={KitchenTherapy}
      MainComponent={KitchenTherapy}
    />,
  },
  {
    path: "/KitchenTherapy/:quizId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={KitchenTherapy}
      MainComponent={KitchenTherapy}
    />,
  },
  {
    path: "/AquaticOccupationalTherapy/:quizId/user/:userId",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={AquaticOccupationalTherapy}
      MainComponent={AquaticOccupationalTherapy}
    />,
  },
  {
    path: "/AquaticOccupationalTherapy/:quizId/user/:userId/edit",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'update'}
      component={AquaticOccupationalTherapy}
      MainComponent={AquaticOccupationalTherapy}
    />,
  },
  {
    path: "/ProtocoloReabilitação",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={ProtocoloReabilitação}
      MainComponent={ProtocoloReabilitação}
    />,
  },
  {
    path: "/Especialidades",
    element:
    <ProtectedRoute
      permission={'Especialidades'} 
      requiredRole={'read'}
      component={Especialidades}
      MainComponent={Especialidades}
    />,
  },
  {
    path: "/NovaEspecialidade",
    element:
    <ProtectedRoute
      permission={'Especialidades'} 
      requiredRole={'create'}
      component={NovaEspecialidade}
      MainComponent={NovaEspecialidade}
    />,
  },
  {
    path: "/EditarEspecialidade/:id",
    element:
    <ProtectedRoute
      permission={'Especialidades'} 
      requiredRole={'update'}
      component={NovaEspecialidade}
      MainComponent={NovaEspecialidade}
    />,
  },
  {
    path: "/pendingschedules",
    element:
    <ProtectedRoute
      permission={'Operacional'} 
      requiredRole={'read'}
      component={PendingSchedules}
      MainComponent={PendingSchedules}
    />,
  },
  {
    path: "/interdisciplinaryone",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={InterdisciplinaryOne}
      MainComponent={InterdisciplinaryOne}
    />,
  },
  {
    path: "/interdisciplinarytwo",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={InterdisciplinaryTwo}
      MainComponent={InterdisciplinaryTwo}
    />,
  },
  {
    path: "/interdisciplinarythree",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={InterdisciplinaryThree}
      MainComponent={InterdisciplinaryThree}
    />,
  },
  {
    path: "/interdisciplinaryfour",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={InterdisciplinaryFour}
      MainComponent={InterdisciplinaryFour}
    />,
  },
  {
    path: "/psychologyone",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={PsychologyOne}
      MainComponent={PsychologyOne}
    />,
  },
  {
    path: "/psychologytwo",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={PsychologyTwo}
      MainComponent={PsychologyTwo}
    />,
  },
  {
    path: "/psychologythree",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={PsychologyThree}
      MainComponent={PsychologyThree}
    />,
  },
  {
    path: "/psychologyfour",
    element:
    <ProtectedRoute
      permission={'Pessoas'} 
      requiredRole={'create'}
      component={PsychologyFour}
      MainComponent={PsychologyFour}
    />,
  },
  {
    path: "/GeneralArchivesSend",
    element:
    <ProtectedRoute
      permission={'Arquivos Gerais'} 
      requiredRole={'read'}
      component={GeneralArchivesSend}
      MainComponent={GeneralArchivesSend}
    />,
  },
  {
    path: "/role",
    element:
    <ProtectedRoute
      permission={'Perfis'} 
      requiredRole={'read'}
      component={ProfileList}
      MainComponent={ProfileList}
    />,
  },
  {
    path: "/role/create",
    element:
    <ProtectedRoute
      permission={'Perfis'} 
      requiredRole={'create'}
      component={ProfileForm}
      MainComponent={ProfileForm}
    />,
  },
  {
    path: "/role/edit/:id",
    element:
    <ProtectedRoute
      permission={'Perfis'} 
      requiredRole={'update'}
      component={ProfileForm}
      MainComponent={ProfileForm}
    />,
  },
  {
    path: "/users",
    element:
    <ProtectedRoute
      permission={'Usuários'} 
      requiredRole={'read'}
      component={UsersList}
      MainComponent={UsersList}
    />,
  },
  {
    path: "/users/edit/:id",
    element:
    <ProtectedRoute
      permission={'Usuários'} 
      requiredRole={'update'}
      component={UsersForm}
      MainComponent={UsersForm}
    />,
  },
  {
    path: "/filiais",
    element:
    <ProtectedRoute
      permission={'Filiais'} 
      requiredRole={'read'}
      component={FiliaisList}
      MainComponent={FiliaisList}
    />,
  },
  {
    path: "/nova-filial",
    element:
    <ProtectedRoute
      permission={'Filiais'} 
      requiredRole={'create'}
      component={FiliamForm}
      MainComponent={FiliamForm}
    />,
  },
  {
    path: "/editar-filial/:id",
    element:
    <ProtectedRoute
      permission={'Filiais'} 
      requiredRole={'update'}
      component={FiliamForm}
      MainComponent={FiliamForm}
    />,
  },
  {
    path: "/user-pool",
    element:
    <ProtectedRoute
      permission={'Usuário Externo'} 
      requiredRole={'read'}
      component={UserPool}
      MainComponent={UserPool}
    />,
  },
  {
    path: "/new-user-pool",
    element:
    <ProtectedRoute
      permission={'Usuário Externo'} 
      requiredRole={'create'}
      component={NewUserPool}
      MainComponent={NewUserPool}
    />,
  },
  {
    path: "/user-pool/:id",
    element:
    <ProtectedRoute
      permission={'Usuário Externo'} 
      requiredRole={'update'}
      component={NewUserPool}
      MainComponent={NewUserPool}
    />
  },{
    path: "/alterar-senha",
    element:
    <ProtectedRoute
      permission={'Alterar Senha'} 
      requiredRole={'update'}
      component={UpdatePassword}
      MainComponent={UpdatePassword}
    />,
  },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default, routesForAuthenticatedOnly, routesForNotAuthenticatedOnly, routesForPublic
};

