import React, { useCallback, useEffect, useRef, useState } from "react";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";

import ListItem from "@mui/material/ListItem";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";

import { pinnedData } from "./data";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/material";
import { CHAVES_NOMENCLATURA_CORRETAS } from "app/utils/retornarChavesValorPessoa";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

function Unification() {
  const userId = Number(localStorage.getItem("id"));
  const initialValues = {
    Pessoa: {
      nome: null,
      nome_social: null,
      email: null,
      cpf: null,
      data_de_nascimento: null,
      sexo: null,
      parentesco_responsavel: null,
      uf_de_nascimento: null,
      cidade_de_nascimento: null,
      nacionalidade: null,
      estado_civil: null,
      profissao: null,
      religiao: null,
      rg: null,
      data_emissao_rg: null,
      uf_emissao_rg: null,
      inscricao_municipal: null,
      cnh: null,
      carteira_de_trabalho: null,
      cartao_pis: null,
      carteira_militar: null,
      passaporte: null,
      tipo_sanguineo: null,
      codigo_importacao: null,
      envia_sms: null,
      emite_nota: null,
      observacao: null,
      codigo: null,
      turma_id: null,
    },
    id_pessoa_mantida: "",
    id_pessoa_unificada: "",
    colaborador_que_criou_unificacao_id: userId,
    colaborador_que_atualizou_unificacao_id: userId,
  };
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = React.useState([0]);
  const [pessoas, setPessoas] = useState([]);
  const [pessoaParaManter, setPessoaParaManter] = useState([]);
  const [pessoaParaUnificar, setPessoaParaUnificar] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [uniInput, setUniInput] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef([]);
  const navigate = useNavigate();

  /* const getPessoas = async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        if (response.status === 200) {
          setPessoas(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }; */

  const getPessoa = async (id, type) => {
    console.log(`ID: ${id} - Tipo: ${type}`);
    try {
      const response = await ApiService.get(`/users/${id}`);
      if (response.status === 200) {
        const data = response?.data;
        let {
          PessoaResponsavel,
          turma_id,
          Convenio_Autorizacao,
          Anamnese,
          Anamnese_Alimentar,
          Fono,
          Avaliacao,
          Evolucao,
          Info_Documento,
          Arquivo,
          Plano_De_Intervencao,
          perfis,
          ...cleanedPessoa
        } = response?.data || {};
        cleanedPessoa = {
          ...cleanedPessoa,
          // perfil_id:
          //   response?.data?.perfis?.[response?.data?.perfis?.length - 1].id,
        };
        const organizerInfos = {
          Pessoa: cleanedPessoa,
          Enderecos: data?.endereco,
          Telefones: data?.telefone,
          Contatos: data?.contatos_gerais,
          Registros: data?.registro_conselho,
          Convenios: data?.convenio,
          Autorizacao: data?.Convenio_Autorizacao,
        };
        if (type === "manter") {
          setPessoaParaManter(response.data);
        } else if (type === "unificar") {
          setPessoaParaUnificar(response.data);
        }
      }
    } catch (error) {
      enqueueSnackbar("Erro ao obter o usuário!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

 /*  useEffect(() => {
    getPessoas();
  }, []);
 */
  const handleSubmit = async (values) => {
    try {
      await ApiService.post("/unification", values).then((response) => {
        if (response.status === 201) {
          enqueueSnackbar("Unificação concluída!", {
            variant: "success",
            
          });
          navigate("/people/unified");
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

  const handleToggle = (chave, valor) => () => {
    const currentIndex = checked.indexOf(chave);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(chave);
      formRef.current.setFieldValue(`Pessoa.${chave}`, valor);
    } else {
      newChecked.splice(currentIndex, 1);
      delete formRef.current.values[`Pessoa.${chave}`];
    }

    setChecked(newChecked);
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
        const response = await ApiService.get(
            `/users?searchTerm=${inputValue}`
        );
        const users = response.data?.usersWithoutPassword || [];
        setPessoas(users);
       /*  setOptionsPacient(users); */
    } catch (error) {
        console.error('Error fetching users:', error);
    } finally {
        setLoading(false);
    }
};

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== '') {
        fetchUsers(newInputValue);
    } else {
        setPessoas([]);
        /* setUserSelected(null); */
    }
  };

  const handleUniInputChange = (event, newInputValue) => {
    setUniInput(newInputValue);
    if (newInputValue !== '') {
        fetchUsers(newInputValue);
    } else {
        setPessoas([]);
       /*  setPacientSelected(null); */
    }
};

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Pessoa"}
        subtitle={"Unificação"}
        titleUrl={"/people"}
      />
      <Paper
        style={{ padding: "30px", margin: "0 100px" }}
        square={false}
        elevation={3}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <>
                <Grid container>
                  <Typography variant="h1" gutterBottom>
                    Unificação de Cadastro de Pessoas
                  </Typography>
                </Grid>
                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid xs={6}>
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={pessoas}
                      getOptionLabel={(option) =>
                        option?.nome ?? "Desconhecido"
                      }
                      onChange={(event, option) => {
                        if (option && option.id) {
                          getPessoa(option.id, "manter");
                          setFieldValue("id_pessoa_mantida", Number(option.id));
                        }
                      }}
                      sx={{
                        width: "100%",
                        marginBottom: "15px",
                        marginTop: "10px",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Pessoa pra MANTER" />
                      )}
                    /> */}
                    <Autocomplete
                      fullWidth
                      disablePortal
                      getOptionLabel={(option) =>
                        option?.nome ?? "Desconhecido"
                      }
                      options={pessoas}
                      loading={loading}
                      inputValue={userInput}
                      onInputChange={handleInputChange}
                      onChange={(event, option) => {
                        if (option && option.id) {
                          getPessoa(option.id, "manter");
                          setFieldValue("id_pessoa_mantida", Number(option.id));
                        }
                      }}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label='Pessoa pra MANTER'
                              placeholder='Pessoa pra MANTER'
                              InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <>
                                          {loading
                                              ? 'carregando...'
                                              : null}
                                          {
                                              params.InputProps
                                                  .endAdornment
                                          }
                                      </>
                                  ),
                              }}
                          />
                      )}
                      isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                      }
                    />
                  </Grid>
                  <Grid xs={6}>
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={pessoas}
                      getOptionLabel={(option) =>
                        option?.nome ?? "Desconhecido"
                      }
                      onChange={(event, option) => {
                        console.log(option);
                        if (option && option.id) {
                          getPessoa(option.id, "unificar");
                          setFieldValue(
                            "id_pessoa_unificada",
                            Number(option.id)
                          );
                        }
                      }}
                      sx={{
                        width: "100%",
                        marginBottom: "15px",
                        marginTop: "10px",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Pessoa para UNIFICAR" />
                      )}
                    /> */}
                     <Autocomplete
                      fullWidth
                      disablePortal
                      getOptionLabel={(option) =>
                        option?.nome ?? "Desconhecido"
                      }
                      options={pessoas}
                      loading={loading}
                      inputValue={uniInput}
                      onInputChange={handleUniInputChange}
                      onChange={(event, option) => {
                        if (option && option.id) {
                          getPessoa(option.id, "unificar");
                          setFieldValue(
                            "id_pessoa_unificada",
                            Number(option.id)
                          );
                        }
                      }}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label='Pessoa para UNIFICAR'
                              placeholder='Pessoa para UNIFICAR'
                              InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <>
                                          {loading
                                              ? 'carregando...'
                                              : null}
                                          {
                                              params.InputProps
                                                  .endAdornment
                                          }
                                      </>
                                  ),
                              }}
                          />
                      )}
                      isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                      }
                    />
                  </Grid>
                  <Grid xs={6}>
                    {pessoaParaManter && (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 420,
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 300,
                          bgcolor: "background.paper",
                          "& ul": {
                            padding: 0,
                          },
                        }}
                        subheader={<li />}
                      >
                        {console.log(values)}
                        {Object.entries(pessoaParaManter).map(
                          ([chave, valor], index) => {
                            {
                              console.log(chave, valor);
                            }
                            if (
                              typeof chave === "string" &&
                              CHAVES_NOMENCLATURA_CORRETAS[chave]
                            ) {
                              return (
                                <ListItem key={index}>
                                  <ListItemText>
                                    <Stack direction={"row"} gap={3}>
                                      <Typography
                                        fontWeight={600}
                                        fontSize={16}
                                      >
                                        {CHAVES_NOMENCLATURA_CORRETAS[chave]
                                          ? CHAVES_NOMENCLATURA_CORRETAS[chave]
                                          : null}
                                        :
                                      </Typography>
                                      <Typography fontSize={16}>
                                        {valor}
                                      </Typography>
                                    </Stack>
                                  </ListItemText>
                                </ListItem>
                              );
                            } else if (
                              typeof chave === "string" &&
                              typeof valor === "object"
                            ) {
                              {
                                valor &&
                                  Object.entries(valor)?.map(
                                    ([propriedade, valorPropriedade]) => (
                                      <ListItem key={index}>
                                        <ListItemText>
                                          <Stack direction={"row"} gap={3}>
                                            <Typography
                                              fontWeight={600}
                                              fontSize={16}
                                            >
                                              {propriedade}:
                                            </Typography>
                                            <Typography fontSize={16}>
                                              {valorPropriedade}
                                            </Typography>
                                          </Stack>
                                        </ListItemText>
                                      </ListItem>
                                    )
                                  );
                              }
                            }
                          }
                        )}
                      </List>
                    )}
                  </Grid>
                  <Grid xs={6}>
                    {pessoaParaUnificar && (
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 420,
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 300,
                          bgcolor: "background.paper",
                          "& ul": {
                            padding: 0,
                          },
                        }}
                        subheader={<li />}
                      >
                        {Object.entries(pessoaParaUnificar).map(
                          ([chave, valor], index) => {
                            if (
                              typeof chave === "string" &&
                              CHAVES_NOMENCLATURA_CORRETAS[chave]
                            ) {
                              return (
                                <ListItem key={index} disablePadding>
                                  <ListItemButton
                                    onClick={handleToggle(chave, valor)}
                                    dense
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={
                                          checked.indexOf(chave) !== -1 &&
                                          valor !== null &&
                                          valor !== ""
                                        }
                                        disabled={
                                          valor === null || valor === ""
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                      />
                                    </ListItemIcon>
                                    <Stack direction={"row"} gap={3}>
                                      <Typography
                                        fontWeight={600}
                                        fontSize={16}
                                      >
                                        {CHAVES_NOMENCLATURA_CORRETAS[chave] ??
                                          null}
                                        :
                                      </Typography>
                                      <Typography fontSize={16}>
                                        {valor}
                                      </Typography>
                                    </Stack>
                                  </ListItemButton>
                                </ListItem>
                              );
                            }
                          }
                        )}
                      </List>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: 20 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Executar Procedimento
                  </Button>
                </Grid>
              </>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

export default Unification;
