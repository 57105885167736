import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import ApiService from 'app/services/config';
import { useLocation, useParams } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Autocomplete, Paper, Tooltip, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { redirectToEvolution } from '../search/ScheduleVertical';
import { GenerateInterventionPlanPDF } from 'app/components/GenerateInterventionPlanPDF/GenerateInterventionPlanPDF';
import FileListForInterventionPlan from 'app/components/FileListForInterventionPlan/FileListForInterventionPlan';

const InterventionPlanFiles = () => {
  const { userId, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const { state } = useLocation()
  const fromTab = state?.fromTab || 0;

  const storedUserId = localStorage.getItem('id');
  console.log(id);

  const [users, setUsers] = useState([]);
  const [patient, setPatient] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [avaliations, setAvaliations] = useState([]);

  const [interventionPlan, setInterventionPlan] = useState({
    questionario_id: '',
    plano_de_intervencao_modelo: '',
    plano_de_intervencao_geral: '',
    plano_de_intervencao_especifico: '',
    data_de_inclusao: '',
  });

  const getUsers = async () => {
    try {
      await ApiService.get('/users')
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

 
  const getInfosDoc = async () => {
    try {
      await ApiService.get(
        `/intervention-plans/${id}?type=avaliacao interdisciplinar`
      )
        .then((response) => {
          console.log(response.data);

          setInterventionPlan(response.data.intervention);
          setUserSelected({ select: response?.data?.intervention.Prestador });
          setAvaliations(response.data.avaliationsAnswered);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetUserInfo = async () => {
    try {
      await ApiService.get(`/users/${userId}`)
      .then(response => {
        if(response.status === 200) {
          setPatient({
            nome: response.data.nome,
            data_de_nascimento: response.data.data_de_nascimento,
          })
        }
      })
    } catch (error) { 
      console.log(error);
    }
  }

  useEffect(() => {
    if (id) {
      getInfosDoc();
    }
    getUsers();
  }, [id]);

  useEffect(() => {
    handleGetUserInfo();
  }, [])

  const handleEvaluationChange = (event) => {
    const selectedEvaluationId = event.target.value;
    const selectedEvaluation = avaliations.find(
      (avaliation) => avaliation.id === selectedEvaluationId
    );

    let modeloPlano = '';
    if (selectedEvaluation) {
      const evaluationName = selectedEvaluation.nome;
      if (evaluationName.includes('Nível 1')) {
        modeloPlano = 'nivel_1';
      } else if (evaluationName.includes('Nível 2')) {
        modeloPlano = 'nivel_2';
      } else if (evaluationName.includes('Nível 3')) {
        modeloPlano = 'nivel_3';
      } else if (evaluationName.includes('Nível 4')) {
        modeloPlano = 'nivel_4';
      }
      // Adicione mais condições conforme necessário
    }

    setInterventionPlan({
      ...interventionPlan,
      questionario_id: selectedEvaluationId,
      plano_de_intervencao_modelo: modeloPlano,
    });
  };

  const interventionPlanLabels = {
    nivel_1: 'Nível 1',
    nivel_2: 'Nível 2',
    nivel_3: 'Nível 3',
    nivel_4: 'Nível 4',
  };

  return (
    <>
      <HeaderBreadcrumbs
        title={'Paciente'}
        subtitle={
          id
            ? 'Gerar PDF do Plano de Intervenção'
            : 'Adicionar Plano de Intervenção'
        }
        titleUrl={`/evolution/user/${userId}/edit`}
      />
      <Button
        sx={{ width: '80px', marginBottom: '1rem' }}
        variant='contained'
        color='success'
        onClick={() => redirectToEvolution(userId, navigate, fromTab)}
      >
        Voltar
      </Button>
      <Paper style={{ padding: '30px' }} square={false} elevation={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} align='center'>
            {/* <Button
              variant='outlined'
              style={{ marginRight: '10px' }}
              onClick={() => {
                if (id) {
                  const docId = interventionPlan.id;
                  const userInterventionEdit = {
                    ...interventionPlan,
                    Prestador: undefined,
                    prestador_id: userSelected?.select?.id,
                    colaborador_id: Number(storedUserId),
                  };
                  
                } 
              }}
            >
              Gerar PDF
            </Button> */}
            
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id='standard-basic'
              label='Data'
              variant='outlined'
              type='date'
              disabled
              InputLabelProps={{ shrink: true }}
              value={interventionPlan?.data_de_inclusao}
              onChange={(event) => {
                setInterventionPlan({
                  ...interventionPlan,
                  data_de_inclusao: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              title='Digite o nome para buscar a pessoa no sistema'
              TransitionComponent={Zoom}
              placement='top-end'
            >
              <Autocomplete
                disabled
                disablePortal
                getOptionLabel={(option) => option?.nome}
                options={users}
                value={userSelected?.select ?? null}
                onChange={(event, newValue) => {
                  setUserSelected((prevValues) => ({
                    ...prevValues,
                    select: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Prestador'
                    placeholder='Prestador'
                    fullWidth
                  />
                )}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <FormControl sx={{ minWidth: 240 }}>
              <InputLabel id='demo-controlled-open-select-label'>
                Selecione a avaliação
              </InputLabel>
              <Select
                disabled
                value={interventionPlan?.questionario_id}
                label=' Selecione a avaliação'
                onChange={handleEvaluationChange}
              >
                <MenuItem value=''>
                  <em>Vazio</em>
                </MenuItem>
                {avaliations?.map((item) => (
                  <MenuItem value={item?.id}>{item?.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl sx={{ minWidth: 240 }}>
              <InputLabel id='demo-controlled-open-select-label'>
                Modelo de Plano de Intervenção
              </InputLabel>
              <Select
                disabled
                value={interventionPlan?.plano_de_intervencao_modelo}
                label='Modelo de Plano de Intervenção'
                onChange={(event) => {
                  setInterventionPlan({
                    ...interventionPlan,
                    plano_de_intervencao_modelo: event.target.value,
                  });
                }}
              >
                <MenuItem value={interventionPlan?.plano_de_intervencao_modelo}>
                  {
                    interventionPlanLabels[
                      interventionPlan?.plano_de_intervencao_modelo
                    ]
                  }
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label='Plano de Intervenção Geral'
              multiline
              rows={4}
              value={interventionPlan?.plano_de_intervencao_geral}
              onChange={(event) => {
                setInterventionPlan({
                  ...interventionPlan,
                  plano_de_intervencao_geral: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label='Plano de Intervenção Específico'
              multiline
              rows={4}
              value={interventionPlan?.plano_de_intervencao_especifico}
              onChange={(event) => {
                setInterventionPlan({
                  ...interventionPlan,
                  plano_de_intervencao_especifico: event.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
        {patient && interventionPlan && (
          <GenerateInterventionPlanPDF tabIndex={10} interventionName={interventionPlan} patient={patient} />
        )}
        <FileListForInterventionPlan/>
      </Paper>
    </>
  );
};

export default InterventionPlanFiles;
