import React from 'react';
import FileItemSlim from './FileItemSlim.js';

const FileListSlim = ({
    files,
    filesSelected,
    removeFile,
    previewImage,
    modalAlert,
}) => {
    return (
        <>
            <ul style={{ padding: '0 2rem' }}>
                {files &&
                    files.map((f, index) => (
                        <FileItemSlim
                            key={f.nome}
                            file={f}
                            index={index}
                            removeFile={removeFile}
                            modalAlert={modalAlert}
                        />
                    ))}
            </ul>
            <ul style={{ padding: '0 2rem' }}>
                {filesSelected &&
                    filesSelected.map((f, index) => (
                        <FileItemSlim
                            key={f.nome}
                            file={f}
                            index={index}
                            removeFile={removeFile}
                            previewImage={previewImage[index]}
                            modalAlert={modalAlert}
                        />
                    ))}
            </ul>
        </>
    );
};

export default FileListSlim;
