import { PermissionContext } from "app/contexts/PermissionContext";
import { Navigate } from "react-router-dom";
import { useContext } from "react";

function ProtectedRoute({permission, requiredRole, component, MainComponent}) {
    const { hasPermission } = useContext(PermissionContext);
  
    if (!hasPermission(permission, requiredRole)) {
      return <Navigate to="/" />;
    }
  
    return <MainComponent component={component} />;
  }
  
  export default ProtectedRoute;
