import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Zoom,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import FormLabel from "@mui/material/FormLabel";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import MUIDataTable from "mui-datatables";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { PermissionContext } from "app/contexts/PermissionContext";
import BlockIcon from '@mui/icons-material/Block';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Link } from "react-router-dom";
import { horariosAgenda } from "app/utils/constants/horariosAgenda";
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const DIAS_SEMANA = {
  0: "Domingo",
  1: "Segunda-feira",
  2: "Terça-feira",
  3: "Quarta-feira",
  4: "Quinta-feira",
  5: "Sexta-feira",
  6: "Sábado",
};


const validationSchema = yup.object().shape({
  prestador_id: yup.number().nullable(),
  especialidade_id: yup.number().nullable(),
  data_inicio: yup
    .date()
    .required("Campo obrigatório")
    .typeError("Data inválida")
    .test({
      name: "validacao-datas",
      message: "A data de início deve ser anterior ou igual à data de fim",
      test: (value, { parent }) => {
        const { data_inicio, data_fim } = parent;
        return data_inicio <= data_fim;
      },
    }),
  data_fim: yup
    .date()
    .required("Campo obrigatório")
    .typeError("Data inválida")
    .test({
      name: "validacao-datas",
      message: "A data de fim deve ser posterior ou igual à data de início",
      test: (value, { parent }) => {
        const { data_inicio, data_fim } = parent;
        return data_fim >= data_inicio;
      },
    }),
  tipo_bloqueio: yup.string().nullable(),
  dias: yup
    .array()
    .of(
      yup.object().shape({
        dia: yup.string(),
        id: yup.number().optional(),
      })
    ),
  horarios_bloqueados: yup.array().of(
    yup.object().shape({
      horario: yup.string(),
      id: yup.number().optional(),
    })
  ),
});

const SchedulesClosing = () => {

  let initialValues = {
    prestador_id: null,
    especialidade_id: null,
    filial_id: null,
    data_inicio: "",
    data_fim: "",
    tipo_bloqueio: "",
    tipo_acao: "",
    dias: [],
    horarios_bloqueados: [],
    periodo: null
  };
  const { enqueueSnackbar } = useSnackbar();
  const [horariosBloqueados, setHorariosBloqueados] = useState([]);
  const [horarioBloqueado, setHorarioBloqueado] = useState(initialValues);
  const [horarios, setHorarios] = useState([
    { horario: "08:00" },
    { horario: "09:00" },
    { horario: "10:00" },
    { horario: "11:00" },
    { horario: "12:00" },
    { horario: "13:00" },
    { horario: "14:00" },
    { horario: "15:00" },
    { horario: "16:00" },
    { horario: "17:00" },
  ]);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(null);
  const [especialidades, setEspecialidades] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [isEdicao, setIsEdicao] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(horarios);
  const [right, setRight] = React.useState([]);
  const [userInput, setUserInput] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const formikRef = useRef();
  const Swal = useSwalWrapper();
  const { hasPermission } = useContext(PermissionContext);

  
  const modalAlert = (id) => {
    Swal.fire({
      title: `Tem certeza que deseja excluir o bloqueio de horário?`,
      text: `Você não poderá reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return handleDeleteBloqueio(id);
      }
    });
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(`/users/all/prestadores?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHorariosBloqueados = async () => {
    try {
      let apiUrl = `/closed/schedules?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      await ApiService.get(apiUrl).then((response) => {
        setHorariosBloqueados(response.data?.closedsSchedules);
        setCount(response.data?.total);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    formikRef.current.setFieldValue("horarios_bloqueados", right);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    formikRef.current.setFieldValue("horarios_bloqueados", right);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    if (checked.length > 0) {
      for (const horario of checked) {
        if (horario.ids) {
          handleDeleteHorarioAgenda(horario.ids);
        }
      }
    } else {
      if (checked.ids) {
        handleDeleteHorarioAgenda(checked.ids);
      }
    }
    formikRef.current.setFieldValue("horarios_bloqueados", right);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    for (const horario of checked) {
      if (horario.ids) {
        handleDeleteHorarioAgenda(horario.ids);
      }
    }
    for (const horario of right) {
      if (horario.ids) {
        handleDeleteHorarioAgenda(horario.ids);
      }
    }
    setRight([]);
    formikRef.current.setFieldValue("horarios_bloqueados", right);
  };

  const handleChangeHorarios = useCallback((periodo = 30) => {
    console.log("Chamou horários")
    setHorarios(horariosAgenda[periodo]);
  }, [periodoSelecionado]);

  const handleDeleteDiaBloqueioAgendaPrestador = async (id) => {
    try {
      await ApiService.delete(`/closed/schedules/dia/${id}`).then(
        (response) => {
          if (response.status === 200) {
            console.log("Dia deletado!");
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteHorarioAgenda = async (ids) => {
    try {
      await ApiService.post(`/closed/schedules/deletar/horarios`, {
        ids,
      }).then((response) => {
        if (response.status === 201) {
          console.log("Horários excluídos!");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditHorarioBloqueado = async (id) => {
    try {
      await ApiService.get(`/closed/schedules/${id}`).then((response) => {
        if (response.status === 200) {
          window.scroll(0, 0);
          enqueueSnackbar("Buscado com sucesso!", {
            variant: "success",
          });

          if(response.data?.periodo) {
            setHorarios(horariosAgenda[response.data?.periodo]);
          }

          const dias = response.data?.dias?.map((dia) => ({
            dia: dia.dia,
            id: dia.id,
          }));

          const horariosBloqueados = response.data?.dias?.flatMap(
            (dia) =>
              dia.horarios_bloqueados?.map((horario) => ({
                horario: horario.horario,
                id: horario.id,
              })) ?? []
          );
          const idsHorarios = horariosBloqueados.map((horario) => horario.id);

          const horariosUnicos = Array.from(
            new Set(horariosBloqueados.map((horario) => horario.horario))
          ).map((horarioUnico) => {
            const ids = idsHorarios.filter((id, index) => {
              return horariosBloqueados[index].horario === horarioUnico;
            });
            return {
              horario: horarioUnico,
              ids: ids,
            };
          });

          // setRight(horarios.map((horario) => horario.horario));
          setRight(horariosUnicos);
          setLeft(
            horarios?.filter(
              (horario) =>
                !horariosUnicos.some((h) => h.horario === horario.horario)
            )
          );
          if (response.data?.prestador) {
            setUserSelected(response.data?.prestador);
          }
          console.log('response.data', response.data)
          setHorarioBloqueado({
            ...response.data,
            data_inicio: moment
              .parseZone(response.data?.data_inicio)
              .format("YYYY-MM-DD"),
            data_fim: moment
              .parseZone(response.data?.data_fim)
              .format("YYYY-MM-DD"),
            dias,
            horarios_bloqueados: horariosBloqueados,
            tipo_bloqueio: response.data?.tipo_bloqueio,
            tipo_acao: response.data?.tipo_acao,
            prestador_id: response.data?.prestador_id,
            periodo: response.data?.periodo
          });
          formikRef.current.setFieldValue(
            "tipo_bloqueio",
            response.data?.tipo_bloqueio
          );
          formikRef.current.setFieldValue(
            "periodo",
            response.data?.periodo
          );
          setShowHorarios(!showHorarios);
          setIsEdicao(false);
        }
      });
      setIsEdicao(true);
    } catch (error) {
      console.error(error);
    }
  };

  const customList = (items) => (
    <Paper sx={{ width: 270, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.horario}-label`;
          return (
            <ListItemButton
              key={value.horario}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.horario || value} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  const [selectedDays, setSelectedDays] = React.useState([]);

  const [value, setValue] = React.useState("bloquear");

  const handleBloqChange = (event) => {
    setValue(event.target.value);
  };

  const handleDayChange = (value) => {
    if (selectedDays.some((dia) => dia.dia === value[value.length - 1])) {
      const index = selectedDays.findIndex(
        (dia) => dia.dia === value[value.length - 1]
      );
      const newSelectedDays = [
        ...selectedDays.slice(0, index),
        ...selectedDays.slice(index + 1),
      ];
      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
      if (selectedDays[index].id) {
        handleDeleteDiaBloqueioAgendaPrestador(selectedDays[index].id);
      }
    } else {
      const newSelectedDays =
        typeof value === "string"
          ? value.split(",")
          : value.map((dia) => (typeof dia === "object" ? dia : { dia }));

      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
    }
  };

  //Exemple Select
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  //end Exemple

  const [showHorarios, setShowHorarios] = useState(false);

  const handleSelecionarHorarios = () => {
    setShowHorarios(!showHorarios);
  };

  const handleDeleteBloqueio = async (id) => {
    try {
      await ApiService.delete(`/closed/schedules/${id}`).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Excluído com sucesso!", {
            variant: "success",
          });
          handleLimparForm();
          setForceUpdate(!forceUpdate);
          setShowHorarios(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (right.length === 0) {
      enqueueSnackbar("Selecione um horário!", {
        variant: "warning",
      });
      return;
    }

    const horarios_bloqueados = right?.map((hora) => ({
      horario: hora,
    }));
    delete values.filial_id;
    const allValues = { ...values, horarios_bloqueados };
    try {
      if (isEdicao === true) {
        delete allValues.id;
        delete allValues.prestador;
        delete allValues.especialidade;
        await ApiService.put(
          `/closed/schedules/${horarioBloqueado.id}`,
          allValues
        ).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Atualizado com sucesso!", {
              variant: "success",
            });
            handleLimparForm();
            setPeriodoSelecionado(null);
          }
        });
      } else {
        await ApiService.post("/closed/schedules/create", allValues).then(
          (response) => {
            if (response.status === 201) {
              enqueueSnackbar("Salvo com sucesso!", {
                variant: "success",
              });
              handleLimparForm();
              setPeriodoSelecionado(null);
            }
          }
        );
      }
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
      });
      console.log(error);
    }
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "prestador",
      label: "Prestador/Especialidade",
      options: {
        sort: true,
        filter: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          const prestador = value;
          const especialidade = tableMeta.rowData[6];

          if (prestador) {
            return value?.nome;
          } else {
            return especialidade?.descricao;
          }
        },
      },
    },
    {
      name: "data_inicio",
      label: "Data de Início",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.parseZone(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "data_fim",
      label: "Data de Fim",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.parseZone(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "tipo_acao",
      label: "Tipo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const motivoBloqueio = tableMeta.rowData[7];
          const MOTIVOS_BLOQUEIO = {
            feriado: "Feriado",
            ferias: "Férias",
            motivo_nao_informado: "Motivo não informado",
            viagem: "Viagem",
            null: "N/I"
          }
          return (
              <Tooltip title={value === "bloquear" ? `BLOQUEIO POR MOTIVO DE: ${MOTIVOS_BLOQUEIO[motivoBloqueio]}` : "EXCLUÍDO"} TransitionComponent={Zoom}>
                {value === "bloquear" ? <BlockIcon color="error" sx={{cursor: "pointer"}} /> : <HighlightOffIcon color="error" sx={{cursor: "pointer"}} /> }
              </Tooltip>
          );
        },
      }
    },
    {
      name: "acoes",
      label: "Ações",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const bloqueioId = tableMeta.rowData[0];
          console.log(`BLOQUEIO ID ${bloqueioId}`);
          return (
            <Stack direction="row" gap={2}>
              <Tooltip title="Visualizar" TransitionComponent={Zoom}>
                <IconButton
                  aria-label="visualizar"
                  color="info"
                  disabled={!hasPermission("Editar Horários", "update")}
                  onClick={() => {
                    handleEditHorarioBloqueado(bloqueioId);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remover" TransitionComponent={Zoom}>
                <IconButton
                  aria-label="remover"
                  color="error"
                  disabled={!hasPermission("Editar Horários", "delete")}
                  onClick={() => modalAlert(bloqueioId)} // Função para deletar usuário
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    },
    {
      name: "especialidade",
      label: "Especialidade",
      options: {
        display: false,
        sort: true,
        filter: true,
      },
    },
    {
      name: "tipo_bloqueio",
      label: "Motivo do Bloqueio",
      options: {
        display: false,
        sort: true,
        filter: true,
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  const getEspecialidades = async () => {
    try {
      await ApiService.get("/specialties").then((response) => {
        if (response.status === 200) {
          setEspecialidades(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getFiliais = async () => {
    try {
      await ApiService.get("/filial/all").then((response) => {
        if (response.status === 200) {
          setFiliais(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimparForm = () => {
    setUserSelected(null);
    setRight([]);
    setLeft(horarios);
    setShowHorarios(!showHorarios);
    fetchHorariosBloqueados();
    setHorarioBloqueado([]);
    setForceUpdate(!forceUpdate);
    setHorarioBloqueado({
      prestador_id: null,
      especialidade_id: null,
      data_inicio: "",
      data_fim: "",
      tipo_bloqueio: "",
      tipo_acao: "",
      dias: [],
      horarios_bloqueados: [],
      periodo: null,
    });
    setIsEdicao(false);
  };

  useEffect(() => {
    fetchHorariosBloqueados();
    getEspecialidades();
    getFiliais();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  
  useEffect(() => {
    setLeft(horarios);
  }, [horarios]);


  return (
    <div>
      <HeaderBreadcrumbs
        title={"Agenda"}
        subtitle={"Edição de horários"}
        titleUrl={"/SchedulesClosing"}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Divider style={{ height: "100%", marginBottom: "20px" }} />
        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          initialValues={horarioBloqueado}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          enableReinitialize
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Grid
                container
                sx={{
                  width: "100%",
                  p: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Filial
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="filial_id"
                      value={values.filial_id || ''}
                      label="Filial"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {filiais.length > 0 &&
                        filiais.map((filial) => (
                          <MenuItem
                            value={filial.id}
                            key={filial.id}
                          >
                            {filial.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Especialidade
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={values.prestador_id !== null}
                      value={values.especialidade_id || ''}
                      label="Especialidade"
                      name={"especialidade_id"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {especialidades.length > 0 &&
                        especialidades.map((especialidade) => (
                          <MenuItem
                            value={especialidade.id}
                            key={especialidade.id}
                          >
                            {especialidade.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl fullWidth>
                    <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                      <Autocomplete
                        fullWidth
                        disablePortal
                        getOptionLabel={(option) => option.nome}
                        options={optionsList}
                        loading={loading}
                        name={"prestador_id"}
                        inputValue={userInput}
                        value={userSelected ?? null}
                        onInputChange={handleInputChange}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserSelected(newValue);
                            setFieldValue("prestador_id", newValue.id);
                          }
                        }}
                        noOptionsText={"Nenhum resultado encontrado"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Prestador"
                            placeholder="Prestador"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? "carregando..." : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                      />
                    </Tooltip>
                    <ErrorMessage
                      name={"prestador_id"}
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-chip-checkbox-label">
                      Selecionar Dias
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-checkbox-label"
                      id="demo-multiple-chip-checkbox"
                      multiple
                      value={values.dias ?? []}
                      name={"dias"}
                      onChange={(event) => {
                        handleDayChange(event.target.value);
                        console.log(event.target.value);
                      }}
                      onBlur={handleBlur}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip-checkbox"
                          label="Selecionar Dias"
                        />
                      }
                      renderValue={() => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {values.dias.length === 1 ? (
                            <Chip
                              key={value}
                              label={DIAS_SEMANA[values.dias[0]?.dia]}
                            />
                          ) : (
                            <Chip
                              key={value}
                              label={`${values.dias.length} dias selecionados`}
                            />
                          )}
                          {/* {values.dias.map((value) => (
                            <Chip key={value} label={DIAS_SEMANA[value.dia]} />
                          ))} */}
                        </Box>
                      )}
                    >
                      {Object.entries(DIAS_SEMANA).map(
                        ([chave, valor], index) => (
                          <MenuItem key={index} value={chave}>
                            <Checkbox
                              checked={values?.dias?.some(
                                (dia) => String(dia?.dia) === String(chave)
                              )}
                            />
                            <ListItemText primary={valor} />
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <ErrorMessage
                      name={"dias"}
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  width: "100%",
                  p: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginY: 2,
                  gap: 2,
                }}
              >
                <Grid item xs={12} md={1.2}>
                <FormControl fullWidth>
                  <InputLabel id="periodo-label">Período</InputLabel>
                  <Select
                    labelId="periodo-label"
                    onChange={(event) => {
                      const periodo = event.target.value;
                      setFieldValue("periodo", periodo);
                      setPeriodoSelecionado(periodo);
                      handleChangeHorarios(periodo);
                    }}
                    value={values.periodo ?? ''}
                    label="Período"
                  >
                    <MenuItem value={5}>5 min</MenuItem>
                    <MenuItem value={10}>10 min</MenuItem>
                    <MenuItem value={20}>20 min</MenuItem>
                    <MenuItem value={30}>30 min</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      id="data-inicial"
                      label="Data Inicial"
                      name="data_inicio"
                      value={values.data_inicio}
                      onChange={(event) => {
                        setFieldValue("data_inicio", event.target.value);
                      }}
                      onBlur={handleBlur}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage
                      name={"data_inicio"}
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      id="data-final"
                      label="Data Final"
                      variant="outlined"
                      name="data_fim"
                      value={values.data_fim}
                      onChange={(event) => {
                        setFieldValue("data_fim", event.target.value);
                      }}
                      onBlur={handleBlur}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage
                      name={"data_fim"}
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Tipo da ação</FormLabel>
                    <RadioGroup
                      aria-label="tipo_acao"
                      name="tipo_acao"
                      value={values.tipo_acao}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="bloquear"
                        control={<Radio />}
                        label="Bloquear"
                      />
                      <FormControlLabel
                        value="excluir"
                        control={<Radio />}
                        label="Excluir"
                      />
                    </RadioGroup>
                    <ErrorMessage
                      name={"tipo_acao"}
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </FormControl>
                </Grid>
                {values.tipo_acao === "bloquear" ? (
                  <Grid item xs={12} md={4} lg={2.5}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="bloqueio-select">
                        Motivo do bloqueio
                      </InputLabel>
                      <Select
                        id="bloqueio-select"
                        placeholder="Selecionar motivo do bloqueio"
                        name="tipo_bloqueio"
                        value={values.tipo_bloqueio}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={"Motivo do bloqueio"}
                      >
                        <MenuItem value={null}>Escolher</MenuItem>
                        <MenuItem value={"feriado"}>FERIADO</MenuItem>
                        <MenuItem value={"ferias"}>FÉRIAS</MenuItem>
                        <MenuItem value={"motivo_nao_informado"}>
                          MOTIVO NÃO INFORMADO
                        </MenuItem>
                        <MenuItem value={"viagem"}>VIAGEM</MenuItem>
                      </Select>
                      <ErrorMessage
                        name={"tipo_bloqueio"}
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </FormControl>
                  </Grid>
                  ): <Grid item xs={12} md={4} lg={2.5}/>}
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                }}
              >
                  {!showHorarios && (
                    <Button
                      variant="contained"
                      onClick={handleSelecionarHorarios}
                      sx={{ display: "block", margin: "auto" }}
                    >
                      Selecionar Horários
                    </Button>
                  )}
                  {showHorarios && (
                  <Paper
                    style={{
                      padding: "30px",
                      marginBottom: "15px",
                      overflowX: "auto",
                      minWidth: "1000px",
                      overflowY: "auto",
                      backgroundColor: "#e8e8e8"
                    }}
                    square={false}
                    elevation={3}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      rowSpacing={5}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h1">Horários</Typography>
                            <Typography variant="h5">Deixa na coluna da direita os horários que deseja bloquear</Typography>
                          </Grid>
                          <Grid item xs="12"></Grid>
                          <Grid item>{left && customList(left)}</Grid>
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Tooltip title='Bloquear todos os horários' TransitionComponent={Zoom}>
                                <Button
                                  sx={{ my: 0.5 }}
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    handleAllRight();
                                  }}
                                  disabled={left?.length === 0}
                                  aria-label="move all right"
                                >
                                  ≫
                                </Button>
                              </Tooltip>
                              <Tooltip title='Bloquear os selecionados' TransitionComponent={Zoom}>
                                <Button
                                  sx={{ my: 0.5 }}
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    handleCheckedRight();
                                  }}
                                  disabled={leftChecked.length === 0}
                                  aria-label="move selected right"
                                >
                                  &gt;
                                </Button>
                              </Tooltip>
                              <Tooltip title='Liberar os selecionados' TransitionComponent={Zoom}>
                                <Button
                                  sx={{ my: 0.5 }}
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    handleCheckedLeft();
                                  }}
                                  disabled={rightChecked.length === 0}
                                  aria-label="move selected left"
                                >
                                  &lt;
                                </Button>
                              </Tooltip>
                              <Tooltip title='Liberar todos os horários' TransitionComponent={Zoom}>
                                <Button
                                  sx={{ my: 0.5 }}
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    handleAllLeft();
                                  }}
                                  disabled={right.length === 0}
                                  aria-label="move all left"
                                >
                                  ≪
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid item>{customList(right)}</Grid>
                          <Grid item xs="12"></Grid>
                          <Grid item>
                            {showHorarios && (
                              <LoadingButton
                                color="success"
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                  maxWidth: {
                                    md: "150px",
                                    mt: "1rem",
                                  },
                                }}
                                /* disabled */
                                loading={isSubmitting}
                              >
                                {isEdicao === true ? "Atualizar" : "Processar"}
                              </LoadingButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                  )}
                  <ErrorMessage
                    name="horarios_bloqueados"
                    component="div"
                    style={{ color: "red" }}
                  />
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
      <Paper my={2} elevation={3}>
        <MUIDataTable
          data={horariosBloqueados}
          columns={columns}
          options={options}
          key={forceUpdate}
        />
      </Paper>
    </div>
  );
};

export default SchedulesClosing;
