import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Box, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { generatePdf } from 'app/utils/appHelpers';
import { useState } from 'react';

const Printouts = () => {
    const [infos, setInfos] = useState({
        pacient: '',
        day: '',
        entryHour: '',
    });

    const handleGeneratePdf = (hours) => {
        const pacient = infos.pacient;
        const day = infos.day;
        const entryHour = infos.entryHour;
        const issuer = localStorage.getItem('username');

        generatePdf(hours, pacient, day, entryHour, issuer);
    };
    return (
        <>
            <Paper
                style={{
                    padding: '10px',
                    marginBottom: '15px',
                    overflowX: 'auto',
                    maxHeight: '650px',
                    overflowY: 'auto',
                }}
                square={false}
                elevation={3}
            >
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '15px' }}
                >
                    Impressões
                </Typography>
                <Divider style={{ height: '100%', marginBottom: '20px' }} />
                <Grid
                    container
                    rowSpacing={1}
                    spacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12}>
                        <Card>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Box>
                                    <CardContent>
                                        <Typography
                                            variant='h5'
                                            textAlign={'center'}
                                        >
                                            DECLARAÇAO DE COMPARECIMENTO
                                        </Typography>
                                        <Box width={'100%'}>
                                            <Typography
                                                variant='h6'
                                                textAlign={'center'}
                                                marginBottom={2}
                                            >
                                                INFORMAÇÕES PARA A DECLARAÇAO
                                            </Typography>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label='Nome da pessoa que irá na declaração'
                                                        value={infos.pacient}
                                                        onChange={(e) =>
                                                            setInfos({
                                                                ...infos,
                                                                pacient:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label='Dia do comparecimento'
                                                        value={infos.day}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(e) =>
                                                            setInfos({
                                                                ...infos,
                                                                day: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        label='Horário da entrada'
                                                        value={infos.entryHour}
                                                        type='time'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(e) =>
                                                            setInfos({
                                                                ...infos,
                                                                entryHour:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant='outlined'
                                            onClick={() => handleGeneratePdf(1)}
                                            disabled={
                                                !infos.day ||
                                                !infos.pacient ||
                                                !infos.entryHour
                                            }
                                        >
                                            1 HORA
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            onClick={() => handleGeneratePdf(2)}
                                            disabled={
                                                !infos.day ||
                                                !infos.pacient ||
                                                !infos.entryHour
                                            }
                                        >
                                            2 HORAS
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            onClick={() => handleGeneratePdf(3)}
                                            disabled={
                                                !infos.day ||
                                                !infos.pacient ||
                                                !infos.entryHour
                                            }
                                        >
                                            3 HORAS
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            onClick={() => handleGeneratePdf(4)}
                                            disabled={
                                                !infos.day ||
                                                !infos.pacient ||
                                                !infos.entryHour
                                            }
                                        >
                                            4 HORAS
                                        </Button>
                                    </CardActions>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>

                    {/* <Grid item xs='6'>
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Recibo Modelo Cupom A4
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant='outlined'>1 via</Button>
                                <Button variant='outlined'>2 vias</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs='6'>
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Recibo Modelo Cupom nao fiscal
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant='outlined'>2 vias</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs='6'>
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Ficha de Prontuario
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant='outlined'>Em Branco</Button>
                                <Button variant='outlined'>
                                    com dados do Paciente
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs='6'>
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Lembrete de Agendamento em Branco
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant='outlined'>imprimir</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs='6'>
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Termo Covid-19
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant='outlined'>imprimir</Button>
                            </CardActions>
                        </Card>
                    </Grid> */}
                </Grid>
            </Paper>
        </>
    );
};

export default Printouts;
