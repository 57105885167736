import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ApiService from 'app/services/config';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';

export const ScheduleQueryItem = () => {
    const location = useLocation();

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    const userSelected = JSON.parse(query.get('userSelected'));
    const pacientSelected = JSON.parse(query.get('pacientSelected'));

    const [infos, setInfos] = useState([]);

    const getAllUsers = useCallback(async () => {
        try {
            const userId = userSelected ?? null;
            const pacientId = pacientSelected ?? null;
            let id;
            let apiUrl;

            if (userId && pacientId) {
                id = userId;
                apiUrl = `/users/all/schedule/${id}?pacientSelected=${pacientId}`; // Corrigido
                console.log('primeiro');
                console.log(id);
            } else if (pacientId && !userId) {
                id = pacientId;
                apiUrl = `/users/all/schedule/${id}`; // Corrigido
                console.log('segundo');
            } else {
                id = userId;
                apiUrl = `/users/all/schedule/${id}`; // Corrigido
                console.log('primeiro');
            }

            await ApiService.get(apiUrl)
                .then((response) => {
                    const workerSchedule = response.data?.Agendamento_Prestador;
                    if (workerSchedule.length > 0) {
                        const groupByUsername = workerSchedule?.reduce(
                            (x, y) => {
                                (x[y.User?.nome] = x[y.User?.nome] || []).push(
                                    y
                                );

                                return x;
                            },
                            {}
                        );
                        setInfos(groupByUsername);
                    } else {
                        setInfos(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [userSelected, pacientSelected]);

    useEffect(() => {
        if (userSelected || pacientSelected) {
            getAllUsers();
        }
        getAllUsers();
    }, [userSelected, pacientSelected, getAllUsers]);

    return (
        <>
            <HeaderBreadcrumbs
                title={'Agenda'}
                subtitle={'Consulta de Agendamentos'}
                titleUrl={'/schedule-query'}
            />

            <Grid
                container
                justifyContent='center'
                alignItems='center'
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                {infos?.Agendamento?.length > 0 ? (
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {infos?.nome}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label='simple table'
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>
                                                Prestador
                                            </TableCell>
                                            <TableCell align='center'>
                                                Data e Hora
                                            </TableCell>
                                            <TableCell align='center'>
                                                Tipo de Agendamento
                                            </TableCell>
                                            <TableCell align='center'>
                                                Convênio
                                            </TableCell>
                                            <TableCell align='center'>
                                                Cadastro
                                            </TableCell>
                                            <TableCell align='center'>
                                                Ficha
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {infos.Agendamento.map(
                                            (schedule, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align='center'>
                                                        {
                                                            schedule?.Prestador
                                                                ?.nome
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {schedule.data}{' '}
                                                        {schedule?.horario}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {
                                                            schedule?.tipo_de_agendamento
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {
                                                            schedule?.Convenio
                                                                ?.nome
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Link
                                                            to={`/people/edit/${infos?.id}`}
                                                            state={{
                                                                backUrl:
                                                                    location.pathname,
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                            <Link
                                                                to={`/evolution/user/${schedule?.User?.id}/edit`}
                                                                state={{
                                                                    backUrl: `${location?.pathname}${location?.search}`,
                                                                }}
                                                                onClick={() => { localStorage.setItem('tabIndex', '5'); }}
                                                            >
                                                                <ArticleIcon />
                                                            </Link>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        {Object.entries(infos).map(
                            ([name, schedules], index) => (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${
                                            index + 1
                                        }-content`}
                                        id={`panel${index + 1}-header`}
                                    >
                                        {name}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label='simple table'
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='center'>
                                                        Prestador
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Data e Hora
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Tipo de Agendamento
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Convênio
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Cadastro
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Ficha
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {schedules?.map(
                                                    (schedule, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align='center'>
                                                                {
                                                                    schedule
                                                                        ?.Prestador
                                                                        ?.nome
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {schedule.data}{' '}
                                                                {
                                                                    schedule?.horario
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    schedule?.tipo_de_agendamento
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    schedule
                                                                        ?.Convenio
                                                                        ?.nome
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Link
                                                                    to={`/people/edit/${schedule?.User?.id}`}
                                                                    state={{
                                                                        backUrl: `${location?.pathname}${location?.search}`,
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Link
                                                                    to={`/evolution/user/${schedule?.User?.id}/edit`}
                                                                    state={{
                                                                        backUrl: `${location?.pathname}${location?.search}`,
                                                                    }}
                                                                    onClick={() => { localStorage.setItem('tabIndex', '5'); }}
                                                                >
                                                                    <ArticleIcon />
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};
