import React from 'react';
import Paper from '@mui/material/Paper';
import { Autocomplete, Grid, Tooltip, Zoom } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ApiService from 'app/services/config';
import { useState } from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Scrollbars from 'react-custom-scrollbars-2';

const Rescheduling = ({ schedule, setSchedule }) => {
    const navigate = useNavigate();
    let { state } = useLocation();
    const { scheduleId } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const storedColaboratorId = localStorage.getItem('id');

    const [age, setAge] = React.useState('');
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    const [selectedDateIndex, setSelectedDateIndex] = useState(null);
    const [selectedHourIndex, setSelectedHourIndex] = useState(null);
    const [rescheduling, setRescheduling] = useState({
        data: '',
        horario: '',
        encaixe: false,
    });

    const isScheduleAvailable = (worker, date, hour) => {
        // Lógica para verificar a disponibilidade do prestador
        const availability = worker?.Disponibilidade_Prestador_Infos?.find((info) =>
        info.Disponibilidade_Prestador?.some((dp) =>
            dp.Disponibilidade_Prestador_Periodo?.some(
                (periodo) =>
                    // Verifica se o dia da semana corresponde ao dia selecionado
                    moment(date).isoWeekday().toString() === dp.dia_semana &&
                    // Verifica se o horário está dentro do período de disponibilidade
                    periodo.horario_inicio <= hour &&
                    periodo.horario_fim >= hour
            )
        )
    );

        // Verifica se há agendamento na data e hora específicas
        if (!availability) {
            return false; // Prestador não disponível
        }

        const scheduling = worker?.Agendamento_Prestador?.find(
            (ag) =>
                ag.data === moment(date).format('YYYY-MM-DD') &&
                ag.horario === hour
        );

        return !scheduling; // Retorna true se não houver agendamento para a data e hora específicas
    };

    const isAnyScheduleAvailableForDay = (date, worker) => {
        return worker?.Disponibilidade_Prestador_Infos?.some((info) =>
            info.Disponibilidade_Prestador?.some(
                (dp) => dp.dia_semana === moment(date).isoWeekday().toString()
            )
        );
    };

    const calculateAvailableDays = () => {
        const availableDays = [];
        for (let i = 0; i < 90; i++) {
            const day = moment().add(i, 'days').format('YYYY-MM-DD');
            if (isAnyScheduleAvailableForDay(day, schedule?.Prestador)) {
                availableDays.push(day);
            }
        }
        return availableDays;
    };

    // Função para determinar os horários disponíveis para o reagendamento em um dia específico
    const calculateAvailableHours = (data, worker) => {
        const availableHours = [];
        for (let hour = 7; hour <= 19; hour++) {
            for (let minute of ['00', '30']) {
                const time = `${hour.toString().padStart(2, '0')}:${minute}`;
                const scheduling = worker?.Agendamento_Prestador?.find(
                    (ag) =>
                        ag.data === moment(data).format('YYYY-MM-DD') &&
                        ag.horario === time
                );
                if (!scheduling && isScheduleAvailable(worker, data, time)) {
                    availableHours.push(time);
                }
            }
        }
        return availableHours;
    };

    // Função para manipular a seleção de data
    const handleSelectedDate = (data) => {
        /* setDateSelected(data); */
        setRescheduling({
            ...rescheduling,
            data: data,
        });
    };

    const handleSelectedHour = (hour) => {
        setRescheduling({
            ...rescheduling,
            horario: hour,
        });
    };

    const handleListDateItemClick = (index) => {
        setSelectedDateIndex(index);
    };

    const handleListHourItemClick = (index) => {
        setSelectedHourIndex(index);
    };

    // Simulando o objeto do trabalhador
    const getAllUsers = React.useCallback(async () => {
        try {
            await ApiService.get('/users')
                .then((response) => {
                    setUsers(response.data?.allProviders);
                    setUserSelected({
                        selected: schedule?.Prestador,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setUsers, schedule?.Prestador]);

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                        <Autocomplete
                            fullWidth
                            disablePortal
                            getOptionLabel={(option) => option.nome}
                            options={users}
                            value={userSelected?.selected ?? null}
                            onChange={(event, newValue) => {
                                setUserSelected((prevValues) => ({
                                    ...prevValues,
                                    selected: newValue,
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Prestadores'
                                    placeholder='Prestadores'
                                />
                            )}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Qntd Horario'
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Qntd Livre'
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Qntd Encaixe'
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl>
                        <FormLabel id='demo-row-radio-buttons-group-label'>
                            Gerar Encaixe
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={rescheduling?.encaixe}
                            onChange={(e) =>
                                setRescheduling({
                                    ...rescheduling,
                                    encaixe: JSON.parse(e.target.value),
                                })
                            }
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label='SIM'
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label='NÃO'
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='outlined-read-only-input'
                        fullWidth
                        label='Observação'
                        multiline
                        rows={3}
                        value={schedule?.obs_agendamento}
                        onChange={(e) =>
                            setSchedule({
                                ...schedule,
                                obs_agendamento: e.target.value,
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ paddingTop: 3 }}>
                        <Box display={'flex'} gap={6} width={'100%'}>
                            {/* Lista de datas disponíveis */}
                            <Box width={'100%'}>
                                <Typography variant='h4' marginX={3}>
                                    Selecione uma data para reagendar:
                                </Typography>
                                <Scrollbars
                                    style={{ width: '100%', height: 310 }}
                                >
                                    <Box width='100%'>
                                        <List
                                            sx={{
                                                width: '100%',
                                            }}
                                        >
                                            {calculateAvailableDays().map(
                                                (day, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemButton
                                                            onClick={() => {
                                                                handleSelectedDate(
                                                                    day
                                                                );
                                                                handleListDateItemClick(
                                                                    index
                                                                );
                                                            }}
                                                            selected={
                                                                selectedDateIndex ===
                                                                index
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={moment(
                                                                    day
                                                                ).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Box>
                                </Scrollbars>
                            </Box>

                            {/* Lista de horários disponíveis para a data selecionada */}
                            {rescheduling?.data && (
                                <Box width='100%'>
                                    <Typography variant='h4' marginX={3}>
                                        Horários disponíveis para{' '}
                                        {moment(rescheduling?.data).format(
                                            'DD/MM/YYYY'
                                        )}
                                        :
                                    </Typography>
                                    <Scrollbars
                                        style={{ width: '100%', height: 310 }}
                                    >
                                        <Box
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <List
                                                sx={{
                                                    width: '100%',
                                                    position: 'relative',
                                                }}
                                            >
                                                {calculateAvailableHours(
                                                    rescheduling?.data,
                                                    schedule?.Prestador
                                                ).map((hour, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemButton
                                                            onClick={() => {
                                                                handleSelectedHour(
                                                                    hour
                                                                );
                                                                handleListHourItemClick(
                                                                    index
                                                                );
                                                            }}
                                                            selected={
                                                                selectedHourIndex ===
                                                                index
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={hour}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Box>
                                    </Scrollbars>
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs='3'>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            const scheduleCreated = {
                                agendamento_anterior_id: schedule?.id,
                                user_id: Number(schedule?.user_id),
                                prestador_id: Number(
                                    userSelected?.selected?.id
                                ),
                                colaborador_id: Number(storedColaboratorId),
                                convenio_id: schedule?.convenio_id
                                    ? Number(schedule?.convenio_id)
                                    : undefined,
                                data: rescheduling?.data,
                                horario: rescheduling?.horario,
                                encaixe: rescheduling?.encaixe,
                                status: schedule?.status,
                                tipo_de_agendamento:
                                    schedule?.tipo_de_agendamento,
                                telemedicina: schedule?.telemedicina,
                                obs_agendamento: schedule?.obs_agendamento,
                                acompanhante: schedule?.acompanhante,
                            };
                            console.log(scheduleCreated);

                            ApiService.post(`/schedules`, scheduleCreated)
                                .then((response) => {
                                    console.log(response.data);
                                    enqueueSnackbar('Reagendado com sucesso!', {
                                        variant: 'success',
                                    });
                                })
                                .catch((error) => {
                                    const errorMessage =
                                        error.response.data.message;
                                    console.log(errorMessage);
                                    if (Array.isArray(errorMessage)) {
                                        enqueueSnackbar(`${errorMessage[0]}`, {
                                            variant: 'error',
                                        });
                                    } else {
                                        enqueueSnackbar(`${errorMessage}`, {
                                            variant: 'error',
                                        });
                                    }
                                });
                        }}
                    >
                        Reagendar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default Rescheduling;
