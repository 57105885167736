import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Box, Grid, Zoom } from '@mui/material';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip from '@mui/material/Tooltip';
import ApiService from 'app/services/config';

const ScheduleQuery = () => {
    const [userSelected, setUserSelected] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [options, setOptions] = useState([]);

    const [pacientSelected, setPacientSelected] = useState(null);
    const [pacientInput, setPacientInput] = useState('');
    const [optionsPacient, setOptionsPacient] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchUsers = async (inputValue) => {
        setLoading(true);
        try {
            const response = await ApiService.get(
                `/users?searchTerm=${inputValue}`
            );
            const users = response.data?.usersWithoutPassword || [];
            setOptions(users);
            setOptionsPacient(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setUserInput(newInputValue);
        if (newInputValue !== '') {
            fetchUsers(newInputValue);
        } else {
            setOptions([]);
            setUserSelected(null);
        }
    };

    const handlePacientInputChange = (event, newInputValue) => {
        setPacientInput(newInputValue);
        if (newInputValue !== '') {
            fetchUsers(newInputValue);
        } else {
            setOptionsPacient([]);
            setPacientSelected(null);
        }
    };

    return (
        <>
            <HeaderBreadcrumbs
                title={'Agenda'}
                subtitle={'Consulta'}
                titleUrl={'/schedule-query'}
            />
            <Paper
                sx={{
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                square={false}
                elevation={3}
            >
                <Paper
                    sx={{
                        padding: '10px',
                        marginBottom: '15px',
                        width: '900px',
                    }}
                    square={false}
                    elevation={3}
                >
                    <Typography
                        variant='h2'
                        gutterBottom
                        style={{ marginBottom: '15px' }}
                    >
                        Consulta de Agendamentos
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    getOptionLabel={(option) => option.nome}
                                    options={options.filter(
                                        (option) =>
                                            option.Agendamento_Prestador.length > 0
                                    )}
                                    loading={loading}
                                    inputValue={userInput}
                                    onInputChange={handleInputChange}
                                    onChange={(event, newValue) => {
                                        setUserSelected((prevValues) => ({
                                            selected: newValue,
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Prestador'
                                            placeholder='Prestador'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading
                                                            ? 'carregando...'
                                                            : null}
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                            <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    getOptionLabel={(option) => option.nome}
                                    options={optionsPacient.filter(
                                        (option) => option.Agendamento.length > 0
                                    )}
                                    loading={loading}
                                    inputValue={pacientInput}
                                    onInputChange={handlePacientInputChange}
                                    onChange={(event, newValue) => {
                                        setPacientSelected((prevValues) => ({
                                            selected: newValue,
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Paciente'
                                            placeholder='Paciente'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading
                                                            ? 'carregando...'
                                                            : null}
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                />
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} textAlign={'center'}>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={1}
                            >
                                {/*  search: `?userSelected=${userSelected?.selected?.id}&pacientSelected=${pacientSelected?.selected?.id}` */}
                                <Link
                                    to={{
                                        pathname: `/schedule-query-list`,

                                        search: `?userSelected=${userSelected?.selected?.id ?? null}&pacientSelected=${pacientSelected?.selected?.id ?? null}`,
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Button variant='outlined'>Buscar</Button>
                                </Link>

                                <Button variant='outlined'>Limpar</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </>
    );
};

export default ScheduleQuery;
