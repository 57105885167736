import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, Button, Card } from '@mui/material';

const FileItemSlimTwo = ({
    file,
    removeFile,
    index,
    previewImage,
    isEditMode,
}) => {
    console.log(file);
    console.log(previewImage);
    return (
        <>
            <Card
                key={file?.nome}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem 0.9rem',
                    margin: '1rem',
                }}
            >
                <Box display={'flex'} alignItems={'center'} gap={3}>
                    {file.nome ? (
                        <>
                            <Avatar
                                alt='imagem do arquivo'
                                src={`${process.env.REACT_APP_API_KEY}/public/uploads/arquivos/${file.nome}`}
                                sx={{ width: 56, height: 56, borderRadius: 2 }}
                                variant='square'
                            />
                            <a
                                href={`${process.env.REACT_APP_API_KEY}/public/uploads/arquivos/${file.nome}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {file.nome}
                            </a>
                        </>
                    ) : (
                        <>
                            {previewImage && (
                                <Avatar
                                    alt='imagem do arquivo'
                                    src={previewImage}
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        borderRadius: 2,
                                    }}
                                    variant='square'
                                />
                            )}
                            {file?.name && (
                                <a
                                    href={URL.createObjectURL(file)}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {file.name}
                                </a>
                            )}
                        </>
                    )}
                </Box>

                {!isEditMode && (
                    <Box display='flex'>
                        <Button
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            disableElevation
                            variant={'contained'}
                            size={'small'}
                            color={'secondary'}
                            onClick={() => {
                                removeFile(index);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </Box>
                )}
            </Card>
        </>
    );
};

export default FileItemSlimTwo;
