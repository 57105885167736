import React, { useContext} from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Button from "@mui/material/Button";
// import { PermissionContext } from 'app/contexts/PermissionContext';

const ListItemProfile = ({item, includeStudentToClass}) => {
    // const { hasPermission } = useContext(PermissionContext);
    
    return (
        <ListItem sx={{p: theme => theme.spacing(1, 3)}}>
            <ListItemAvatar>
                <Avatar alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`}/>
            </ListItemAvatar>
            <ListItemText
                primary={<Typography variant='h5' mb={.5}>{item.nome}</Typography>}
                secondary={item.cpf}
            />
            <Button
                size={"small"}
                variant={"contained"}
                color={"success"}
                disableElevation
                onClick={() => includeStudentToClass(item)}
                // {...(item.follow) ? {color: "inherit"} : {}}
                // disabled={!hasPermission('Perfis', 'editar')}
                sx={{
                    minWidth: 78,
                    textTransform: 'none',
                    p: theme => theme.spacing(.5, 1.5)
                }}
            >
                Incluir
            </Button>
        </ListItem>

    )
};
/* Todo item, includeStudentToClass prop define */
export default ListItemProfile;
