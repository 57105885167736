import Chip from '@mui/material/Chip';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import HistoryIcon from '@mui/icons-material/History';
import Badge from '@mui/material/Badge';
import DataTable from '../DataTable';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip from '@mui/material/Tooltip';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ApiService from 'app/services/config';
import { useCallback } from 'react';
import MUIDataTable from 'mui-datatables';
import { setMonth } from 'date-fns';
import { calculateAge, formatDate } from '@jumbo/utils';
import { useSnackbar } from 'notistack';
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import moment from 'moment';

const ScheduleReport = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [userSelected, setUserSelected] = useState(null);
    const [month, setMonth] = useState('');
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [userInput, setUserInput] = useState('');
    const [optionsList, setOptionsList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState('');

    const fetchUsers = async (inputValue) => {
        setLoading(true);
        try {
            const response = await ApiService.get(
                `/users/all/prestadores?searchTerm=${inputValue}`
            );
            const users = response.data;
            setOptionsList(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setUserInput(newInputValue);
        if (newInputValue !== '') {
            fetchUsers(newInputValue);
        } else {
            setOptionsList([]);
            setUserSelected(null);
        }
    };

    const handleSearch = async (newSearchTerm) => {
        if (
            searchTerm === '' ||
            searchTerm === null ||
            searchTerm === undefined
        ) {
            setPage(1);
        }
        setSearchTerm(newSearchTerm);
        setPage(0);
    };

    const getUsers = async (isManual = false) => {
        try {
            let apiUrl = `/users/reports?date=${date}&page=${
                page + 1
            }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

            if (searchTerm) {
                apiUrl += `&searchTerm=${searchTerm}`;
            }
            ApiService.get(apiUrl)
                .then((response) => {
                    console.log(response.data);

                    setUsers(response.data);
                    setCount(response.data?.total);

                    if (isManual) {
                        enqueueSnackbar('Busca realizada!', {
                            variant: 'success',
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getUsers();
    }, [page, perPage, sortBy, sortOrder, searchTerm]);

    const columns = [
        {
            name: 'id',
            label: 'Id',
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: 'data',
            label: 'Data agendamento',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Typography>{`${formatDate(value)}`}</Typography>;
                },
            },
        },
        {
            name: 'User.nome',
            label: 'Nome',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'User.telefone',
            label: 'Telefone',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.map((item) => (
                        <Typography>
                            {`${item?.tipo_de_telefone} - ${item?.ddd}${item?.numero}`}
                        </Typography>
                    ));
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue
                        .map(
                            (item) =>
                                `${item.tipo_de_telefone} - ${item.ddd}${item.numero}`
                        )
                        .join('\n');
                },
            },
        },
        {
            name: 'User.Agendamento',
            label: 'Agendamentos',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.map((item) => (
                        <>
                            <Typography>{`${item?.horario} - ${item?.Prestador?.nome}`}</Typography>
                        </>
                    ));
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue
                        .map(
                            (item) =>
                                `${item?.horario} - ${item?.Prestador?.nome}`
                        )
                        .join('\n');
                },
            },
        },
    ];

    const options = {
        changeRowsPerPage: perPage,
        filterType: 'dropdown',
        filter: false,
        selectableRows: 'none',
        searchAlwaysOpen: true,
        searchable: true,
        serverSide: true,
        page: page,
        count: count,
        selectToolbarPlacement: 'above',
        enableNestedDataAccess: '.',
        onDownload: (buildHead, buildBody, columns, data) => {
            const alteredData = data?.map((row, index) => ({
                index,
                data: row?.data?.map((field, index) => {
                    // look for the existence of a toStringOverride on the matching column for this field:
                    const mapper = columns?.[index]?.toStringOverride;
                    // And apply it, if it exists:
                    return mapper ? mapper(field) : field;
                }),
            }));

            // Taken from sourcecode:
            return `${buildHead(columns)}${buildBody(alteredData)}`.trim();
        },

        onTableChange: (action, tableState) => {
            console.log(action);
            console.log(tableState);
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'sort':
                    setSortBy(tableState.sortOrder.name);
                    setSortOrder(tableState.sortOrder.direction);
                    break;
                case 'search':
                    handleSearch(tableState.searchText);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        textLabels: {
            body: {
                noMatch: 'Nenhum resultado encontrado',
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: 'Próxima Página',
                previous: 'Página Anterior',
                rowsPerPage: 'Itens por Página:',
                displayRows: 'of',
            },
        },
    };

    return (
        <div>
            <HeaderBreadcrumbs
                title={'Relatórios'}
                subtitle={'Agendamentos'}
                titleUrl={'/relatorios/agendamentos'}
            />
            <Paper
                style={{
                    padding: '10px',
                    marginBottom: '15px',
                    overflowX: 'auto',
                    overflowY: 'auto',
                }}
                square={false}
                elevation={3}
            >
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                    mb={5}
                >
                    <Grid item xs={8}>
                            <Grid item>
                                <Typography
                                    variant='h2'
                                    gutterBottom
                                    style={{ marginBottom: '15px' }}
                                >
                                    Filtro de agendamentos
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider
                                    style={{
                                        height: '100%',
                                        marginBottom: '20px',
                                        marginTop: '20px',
                                    }}
                                />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            // fullWidth
                                            id='validade-final'
                                            label='Data agendamento'
                                            variant='outlined'
                                            type='date'
                                            InputLabelProps={{ shrink: true }}
                                            value={date ?? null}
                                            onChange={(e) =>
                                                setDate(e.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} md={2}></Grid>
                                <Grid item xs={12} md={3} lg={2}>
                                    <Button
                                        fullWidth
                                        endIcon={<ReceiptLongIcon />}
                                        variant='contained'
                                        onClick={() => getUsers(true)}
                                    >
                                        Gerar
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={3} lg={2}>
                                    <Button
                                        fullWidth
                                        endIcon={<FilterAltOffIcon />}
                                        variant='outlined'
                                        onClick={() => setDate('')}
                                    >
                                        Limpar
                                    </Button>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>

                <MUIDataTable
                    title={'Relatório de Agendamentos'}
                    data={users}
                    columns={columns}
                    options={options}
                />
            </Paper>
        </div>
    );
};

export default ScheduleReport;
