import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { redirectToEvolution } from "../search/ScheduleVertical";
import { useSnackbar } from "notistack";
import ApiService from "app/services/config";
import AnamnesisMenu from "app/components/AnamnesisMenu/AnamnesisMenu";
import AnamnesisQuestion from "app/components/AnamnesisQuestion/AnamnesisQuestion";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SendIcon from "@mui/icons-material/Send";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";

const GeneralQuizzesForm = () => {
  const { evaluationId, patientId } = useParams();
  const storedColaboratorId = localStorage.getItem("id");
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const date = String(location?.search).split("=")[1];
  const state = location.state || {};
  const fromTab = state.fromTab || 0;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [evaluations, setEvaluations] = useState(null);
  const [evaluationSelected, setEvaluationSelected] = useState(null);
  const [answers, setAnswers] = useState(new Map());

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleGetEvaluations = async () => {
    try {
      await ApiService.get(
        `/user-answered/anamnese/distinct/date/${patientId}/${evaluationId}`
      ).then((response) => {
        if (response.status === 200) {
          setEvaluations(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetQuestions = async () => {
    try {
      setIsLoading(true);
      await ApiService.get(`/quizzes/quiz/questions/${evaluationId}`).then(
        (response) => {
          if (response.status === 200) {
            console.log(response.data);
            // Se for o id 62 (M-CHAT), a API retorna as perguntas
            // Pela propriedade pergunta
            // Pois não tem seções
            if (Number(evaluationId) === 62) {
              console.log(`EVALUATION ID: ${Number(evaluationId)}`);
              console.log(`EVALUATION ID: ${Number(evaluationId) === 62}`);
              setQuestions(response.data?.pergunta);
            } else {
              setQuestions(response.data);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Erro ao obter o questionário", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };


  useEffect(() => {
    handleGetQuestions();
    setAnswers(new Map());
  }, [evaluationId, isFormSubmitted]);

  return (
    <>
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center">
          <Stack
            direction="column"
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress color="primary" />
            <Typography>Carregando...</Typography>
          </Stack>
        </Grid>
      ) : (
        <Grid container rowSpacing={2}>
          <Grid item xs={8}>
            <HeaderBreadcrumbs title={questions?.nome} />
            <Button
              sx={{ width: "80px", marginBottom: "1rem" }}
              variant="contained"
              color="success"
              onClick={() => redirectToEvolution(patientId, navigate, fromTab)}
            >
              Voltar
            </Button>
          </Grid>
          <Grid container rowSpacing={2}>
            <AnamnesisMenu
              anamnesisSelected={evaluationSelected}
              setAnamnesisSelected={setEvaluationSelected}
              idQuestionario={evaluationId}
              userId={patientId}
              setIsEditMode={setIsEditMode}
              handleGetAnamnesis={handleGetEvaluations}
              answers={answers}
              setAnswers={setAnswers}
              anamnesis={evaluations}
              setAnamnesis={setEvaluations}
              isFormSubmitted={isFormSubmitted}
              setIsFormSubmitted={setIsFormSubmitted}
              date={date} 
            />
            {questions &&
              (questions?.secoes
                ? questions?.secoes?.map((secao, index) => (
                    <Grid item xs={12} key={index}>
                      <Accordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleAccordionChange(`panel${index}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography>{secao?.nome}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {secao?.Subsecao?.length === 0 ? (
                            <>
                              {secao?.perguntas?.map((pergunta) => (
                                <AnamnesisQuestion
                                  key={pergunta.id}
                                  nome={pergunta?.nome}
                                  idPergunta={pergunta.id}
                                  respostas={pergunta?.Resposta}
                                  answers={answers}
                                  setAnswers={setAnswers}
                                  isFormSubmitted={isFormSubmitted}
                                  isEditMode={isEditMode}
                                  anamnesisSelected={evaluationSelected}
                                />
                              ))}
                              {secao?.nome?.includes("XXIII. APARECIMENTO DOS SINTOMAS ANTES DOS 36 MESES (DSM-IV)*") && 
                                (
                                  <Typography>
                                  * Nota do blog: o último item da escala (XXIII) não possui subescalas/subitens e
                                    refere-se à visão geral dos sintomas assinalados no decorrer da escala. Se os sintomas
                                    assinalados nos itens anteriores já estavam presentes antes dos 3 anos (36 meses) de
                                    idade, marque 2 pontos para o item XXIII. Se apareceram depois dessa idade, não
                                    pontuar este item.
                                  </Typography>
                                )
                              }
                              
                            </>
                          ) : (
                            <>
                              {secao?.Subsecao?.map((subsecao) =>
                                subsecao.perguntas?.map((pergunta) => (
                                  <AnamnesisQuestion
                                    key={pergunta.id}
                                    nome={pergunta?.nome}
                                    idPergunta={pergunta.id}
                                    respostas={pergunta?.Resposta}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    isFormSubmitted={isFormSubmitted}
                                    isEditMode={isEditMode}
                                    anamnesisSelected={evaluationSelected}
                                  />
                                ))
                              )}
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))
                : questions?.map((pergunta) => (
                    <Grid item xs={12} key={pergunta.id}>
                      <Paper elevation={1} sx={{p: 1}}>
                        <AnamnesisQuestion
                          nome={pergunta?.nome}
                          idPergunta={pergunta.id}
                          respostas={pergunta?.Resposta}
                          answers={answers}
                          setAnswers={setAnswers}
                          isFormSubmitted={isFormSubmitted}
                          isEditMode={isEditMode}
                          anamnesisSelected={evaluationSelected}
                        />
                      </Paper>
                    </Grid>
                  )))}
          </Grid>
          <Grid
            container
            my={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="outlined"
              endIcon={<SendIcon />}
              disabled={isSubmitting}
              onClick={() => {
                answers.delete(undefined);
                setIsSubmitting(true);
                const arrayRespostas = answers.values().toArray();
                if (isEditMode) {
                  ApiService.put(
                    `/user-answered/user/${patientId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    arrayRespostas
                  )
                    .then((response) => {
                      if (response.status === 200) {
                        enqueueSnackbar("Ficha atualizada com sucesso!", {
                          variant: "success",
                        });
                        if(date) {
                          navigate(`/evaluation/${evaluationId}/patient/${patientId}/`)
                        }
                        setEvaluationSelected(null);
                        setIsEditMode(false);
                        setIsFormSubmitted(!isFormSubmitted);
                        setAnswers(new Map());
                        setIsSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      setIsSubmitting(false);
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${patientId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    arrayRespostas
                  )
                    .then((response) => {
                      if (response.status === 201) {
                        enqueueSnackbar("Ficha registrada com sucesso!", {
                          variant: "success",
                        });
                        setAnswers(new Map());
                        setIsFormSubmitted(!isFormSubmitted);
                        handleGetEvaluations();
                        setIsSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      setIsSubmitting(false);
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                        });
                      }
                    });
                }
              }}
            >
              {isEditMode ? "Salvar" : "Criar"}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GeneralQuizzesForm;
