import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Modal from "@mui/material/Modal";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";

import Person from "./Person";
import Locations from "./Locations";
import Phones from "./Phones";
import Contacts from "./Contacts";
import Registros from "./Registros";
import Agreements from "./Agreements";
import Authorization from "./Authorization";
import Consultation from "./Consultation";
import { Form, Formik } from "formik";
import { Autocomplete, LoadingButton } from "@mui/lab";
import ApiService from "app/services/config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import FilesList from "../evolutionSheet/FilesList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Index() {
  const userId = Number(localStorage.getItem("id"));

  const initialValues = {
    Pessoa: {
      nome: "",
      nome_social: "",
      cpf: "",
      data_de_nascimento: "",
      mae_id: "",
      pai_id: "",
      pessoa_responsavel_id: "",
      parentesco_responsavel: "",
      uf_de_nascimento: "",
      cidade_de_nascimento: "",
      nacionalidade: "",
      estado_civil: "",
      profissao: "",
      religiao: "",
      sexo: "",
      rg: "",
      data_emissao_rg: "",
      orgao_emissor_rg: "",
      uf_emissao_rg: "",
      inscricao_municipal: "",
      titulo_eleitor: "",
      cnh: "",
      carteira_de_trabalho: "",
      cartao_pis: "",
      carteira_militar: "",
      passaporte: "",
      tipo_sanguineo: "",
      numero_filhos: null,
      codigo_importacao: "",
      envia_sms: false,
      emite_nota: false,
      observacao: "",
      codigo: "",
      perfil_id: "",
      colaborador_inclusao_id: userId,
      colaborador_alteracao_id: userId,
      especialidades: [],
    },
    /* PessoaResponsavel: {}, */
    Enderecos: {},
    Telefones: {},
    Contatos: {},
    Registros: {},
    Convenios: {},
    Autorizacao: {},
    /*  Consultas: {},
        Avisos: {}, */
  };

  const [address, setAddress] = useState({
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    uf: "",
    bairro: "",
    cidade: "",
    tipo_de_endereco: "",
  });

  const [telephone, setTelephone] = useState({
    tipo_de_telefone: "",
    ddd: "",
    numero: "",
  });

  const [contact, setContact] = useState({
    tipo_de_contato: "",
    contato: "",
  });

  const [registrationBoard, setRegistrationBoard] = useState({
    conselho_regional: "",
    registro: "",
    uf: "",
    /*  modificado: '', */
  });

  const [agreement, setAgreement] = useState({
    nome: "",
    numero_convenio: "",
    validade: "",
    acomodacao: false,
    rede_atendimento: "",
    plano_regulamentado: "",
    abrangencia: "",
    cobertura: false,
    tipo_plano_convenio: "",
    intercambio: false,
    regulamentacao: false,
    codigo_seguranca: "",
  });

  const [agreementAuth, setAgreementAuth] = useState({
    convenio_id: "",
    colaborador_solicitante_id: "",
    procedimento: "",
    data_autorizacao: "",
    codigo_autorizacao: "",
    guia: "",
    cid: "",
    facial: false,
    numero_total_sessoes: "",
    quantidade_horario_sessao: "",
    metodo_de_tratamento: "",
    quantidade_por_frequencia: "",
    frequencia: "",
    sessoes_realizadas: "",
    valor: "",
    desconto: "",
    valor_final: "",
    codigo: "",
    observacao: "",
  });

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  let { state } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [infos, setInfos] = useState(initialValues);
  const [userSelected, setUserSelected] = useState(null);
  const [reqUserSelected, setReqUserSelected] = useState(null);
  const [agreementSelected, setAgreementSelected] = useState(null);
  const [infosSelected, setInfosSelected] = useState(null);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getInfosById = useCallback(async () => {
    try {
      await ApiService.get(`/users/${id}`)
        .then((response) => {
          const data = response?.data;
          let {
            PessoaResponsavel,
            turma_id,
            Convenio_Autorizacao,
            Anamnese,
            Anamnese_Alimentar,
            Fono,
            Avaliacao,
            Evolucao,
            Agendamento,
            Info_Documento,
            Arquivo,
            Plano_De_Intervencao,
            Video,
            perfis,
            ...cleanedPessoa
          } = response?.data || {};
          cleanedPessoa = {
            ...cleanedPessoa,
            perfil_id:
              response?.data?.perfis?.[response?.data?.perfis?.length - 1]
                ?.perfil_id,
            especialidades: response?.data?.especialidades,
            usuario_perfil_id: perfis[0]?.id,
          };
          const organizerInfos = {
            username: data?.nome,
            Pessoa: cleanedPessoa,
            Agendamento: data?.Agendamento,
            Enderecos: data?.endereco,
            Telefones: data?.telefone,
            Contatos: data?.contatos_gerais,
            Registros: data?.registro_conselho,
            Convenios: data?.convenio,
            Autorizacao: data?.Convenio_Autorizacao,
            Arquivo: data?.Arquivo
          };
          setInfos(organizerInfos);
          setUserSelected(organizerInfos?.Pessoa);
          setInfosSelected(organizerInfos?.Autorizacao);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getInfosById();
    }
  }, [getInfosById, id, agreement]);

  useEffect(() => {
    if(state?.tabIndex) {
      handleChangeTabs(null, state?.tabIndex);
      console.log("Tem tabIndex ", state?.tabIndex)
    }
  }, [state?.tabIndex])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (values.PessoaResponsavel) delete values.PessoaResponsavel;

    console.log(values);

    const allValues = {
      ...values,
      Pessoa: {
        ...values.Pessoa,
        mae_id: userSelected?.mae?.id,
        pai_id: userSelected?.pai?.id,
        pessoa_responsavel_id: userSelected?.pessoa_responsavel?.id,
        numero_filhos: Number(values.Pessoa.numero_filhos),
        envia_sms: Boolean(values.Pessoa.envia_sms),
        emite_nota: Boolean(values.Pessoa.emite_nota),
        perfil_id: values.perfil_id,
        colaborador_inclusao_id: userId,
      },
      Enderecos: address,
      Telefones: telephone,
      Contatos: contact,
      Registros: registrationBoard,
      Convenios: {
        ...agreement,
        acomodacao: Boolean(values.Convenios.acomodacao),
        cobertura: Boolean(values.Convenios.cobertura),
        intercambio: Boolean(values.Convenios.intercambio),
        regulamentacao: Boolean(values.Convenios.regulamentacao),
      },
      Autorizacao: {
        ...agreementAuth,
        convenio_id: infosSelected?.Convenio?.id,
        colaborador_solicitante_id: infosSelected?.colaborador_solicitante?.id,
        facial: Boolean(agreementAuth.facial),
        numero_total_sessoes: Number(agreementAuth.numero_total_sessoes),
        quantidade_horario_sessao: Number(
          agreementAuth.quantidade_horario_sessao
        ),
        metodo_de_tratamento: agreementAuth.metodo_de_tratamento,
        quantidade_por_frequencia: Number(
          agreementAuth.quantidade_por_frequencia
        ),
      },
    };
    for (let key in allValues) {
      if (allValues.hasOwnProperty(key)) {
        for (let innerKey in allValues[key]) {
          if (allValues[key].hasOwnProperty(innerKey)) {
            if (
              allValues[key][innerKey] === "" ||
              allValues[key][innerKey] === null ||
              allValues[key][innerKey] === undefined
            ) {
              delete allValues[key][innerKey];
            }
          }
        }
      }
    }

    allValues.Pessoa.perfil_id = values?.Pessoa?.perfil_id;

    console.log(allValues);

    if (id) {
      ApiService.put(`/users/${id}`, allValues)
        .then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Usuário atualizado com sucesso!", {
              variant: "success",
              
            });
            navigate("/people");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          console.log(errorMessage);
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${String(errorMessage[0]).split('.')[1]}`, {
              variant: "error",
              
            });
          } else {
            enqueueSnackbar(`${String(errorMessage[0]).split('.')[1]}`, {
              variant: "error",
              
            });
          }
        });
    } else {
      await ApiService.post("/users", allValues)
        .then((response) => {
          console.log(response.data);

          if (response.status === 201) {
            enqueueSnackbar("Usuário Criado com sucesso!", {
              variant: "success",
              
            });
            if(state?.backUrl.includes('/schedule-worker/pacient-schedule/new')) {
              navigate(state.backUrl)
            }else {
              navigate("/people");
            }
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          if (Array.isArray(errorMessage)) {
            enqueueSnackbar(`${String(errorMessage[0]).split('.')[1]}`, {
              variant: "error",
              
            });
          } else {
            enqueueSnackbar(`${errorMessage}`, {
              variant: "error",
              
            });
          }
        });
    }

    setSubmitting(false);
  };

  return (
    <>
      <HeaderBreadcrumbs
        title={"Pessoa"}
        subtitle={id ? "Editar Pessoa" : "Criar Pessoa"}
        titleUrl={"/People"}
      />
      <Button
        sx={{ width: "80px", marginBottom: "1rem" }}
        variant="contained"
        color="success"
        onClick={() => {
          navigate(state?.backUrl ? state.backUrl : "/People")
        }}
      >
        Voltar
      </Button>
      <Paper style={{ padding: "30px" }} square={false} elevation={3}>
        <Formik
          initialValues={infos}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={5} sm={4} md={3} lg={2}>
                  <input
                    type="number"
                    hidden
                    value={userId}
                    name={"Pessoa.colaborador_alteracao_id"}
                  />
                  <input
                    type="number"
                    hidden
                    value={userId}
                    name={"Pessoa.colaborador_inclusao_id"}
                  />
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Pessoa" {...a11yProps(0)} />
                    <Tab label="Endereços" {...a11yProps(1)} />
                    <Tab label="Telefones" {...a11yProps(2)} />
                    <Tab label="Contatos" {...a11yProps(3)} />
                    <Tab label="Registros" {...a11yProps(4)} />
                    <Tab label="Convênios" {...a11yProps(5)} />
                    {id ? (
                      <Tab
                        label="Autorização"
                        onClick={() => getInfosById()}
                        {...a11yProps(6)}
                      />
                    ) : undefined}
                    <Tab label="Consultas" {...a11yProps(7)} />
                    {
                      id ? (
                        <Tab label="Arquivos" {...a11yProps(8)} />
                      )
                      : undefined
                    }
                    <Tab disabled label="Avisos" {...a11yProps(9)} />
                  </Tabs>
                  {id ? undefined : (
                    <Grid item xs={12} textAlign={"center"}>
                      <LoadingButton
                        color="success"
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                          maxWidth: {
                            md: "150px",
                            mt: "1rem",
                          },
                        }}
                        /* disabled */
                        loading={isSubmitting}
                      >
                        Salvar
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={7} sm={8} md={9} lg={10}>
                  {/* cadastro de pessoas */}
                  <CustomTabPanel value={value} index={0}>
                    <Person
                      handleChange={handleChange}
                      values={values}
                      infos={infos}
                      setInfos={setInfos}
                      setFieldValue={setFieldValue}
                      userSelected={userSelected}
                      setUserSelected={setUserSelected}
                    />
                  </CustomTabPanel>

                  {/* Endereços */}
                  <CustomTabPanel value={value} index={1}>
                    <Locations
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      address={address}
                      setAddress={setAddress}
                    />
                  </CustomTabPanel>

                  {/* Telefones */}
                  <CustomTabPanel value={value} index={2}>
                    <Phones
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      setTelephone={setTelephone}
                      telephone={telephone}
                      handleChange={handleChange}
                    />
                  </CustomTabPanel>

                  {/* Contatos */}
                  <CustomTabPanel value={value} index={3}>
                    <Contacts
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      contact={contact}
                      setContact={setContact}
                      handleChange={handleChange}
                    />
                  </CustomTabPanel>

                  {/* Registros */}
                  <CustomTabPanel value={value} index={4}>
                    <Registros
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      registrationBoard={registrationBoard}
                      setRegistrationBoard={setRegistrationBoard}
                      handleChange={handleChange}
                    />
                  </CustomTabPanel>

                  {/* Convênios */}
                  <CustomTabPanel value={value} index={5}>
                    <Agreements
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      agreement={agreement}
                      setAgreement={setAgreement}
                      handleChange={handleChange}
                    />
                  </CustomTabPanel>

                  {/* Autorização */}
                  <CustomTabPanel value={value} index={6}>
                    <Authorization
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      agreementAuth={agreementAuth}
                      setAgreementAuth={setAgreementAuth}
                      handleChange={handleChange}
                      reqUserSelected={reqUserSelected}
                      setReqUserSelected={setReqUserSelected}
                      /* agreementSelected={agreementSelected}
                                            setAgreementSelected={setAgreementSelected} */
                      infosSelected={infosSelected}
                      setInfosSelected={setInfosSelected}
                    />
                  </CustomTabPanel>

                  {/* Consultas */}
                  <CustomTabPanel value={value} index={7}>
                    <Consultation infos={infos} />
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={8}>
                    <FilesList
                      values={values}
                      infos={infos}
                      setFieldValue={setFieldValue}
                      userId={id}
                    />
                  </CustomTabPanel>
                  {/* Avisos */}
                  <CustomTabPanel value={value} index={8}>
                    {/*  */}
                  </CustomTabPanel>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
}

export default Index;
