import * as React from 'react';
import Box from '@mui/material/Box';
import exemple from './exemple';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import List from '@mui/material/List';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

const Extension = () => {
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(['08:00', '08:30', '09:00', '09:30']);
    const [right, setRight] = React.useState(['10:00', '10:30', '11:00', '11:30']);

    const [left1, setLeft1] = React.useState(['12:00', '12:30', '13:00', '13:30']);
    const [right1, setRight1] = React.useState(['14:00', '14:30', '15:00', '15:30']);

  
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
  
    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const handleAllRight = () => {
      setRight(right.concat(left));
      setLeft([]);
    };
  
    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };
  
    const handleAllLeft = () => {
      setLeft(left.concat(right));
      setRight([]);
    };
  
    const customList = (items) => (
      <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
  
            return (
              <ListItemButton
                key={value}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} />
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
    );
  

    return (
        <>
            <Paper style={{ padding: '10px', marginBottom: '15px', overflowX: 'auto', maxHeight: '650px', overflowY: 'auto' }} square={false} elevation={3}>
                <Typography variant='h1' gutterBottom style={{ marginBottom: '15px'}}>
                    Extensão
                </Typography>
                <Divider style={{ height: '100%', marginBottom:'15px'}} />
                <Grid container rowSpacing={1}>
                    <Grid container alignItems="center" justifyContent="center" item xs={6}>
                        <Typography variant='h1' gutterBottom style={{ marginBottom: '15px'}}>
                            Estender Agendamento
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center" item xs={6}>
                        <Typography variant='h1' gutterBottom style={{ marginBottom: '15px'}}>
                            Excluir Horarios Estendidos
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item>
                                {customList(left)}
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {customList(right)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" style={{ height: '100%', marginLeft:'20px', marginRight:'20px' }} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item>
                                {customList(left1)}
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {customList(right1)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Button style={{marginTop:'10px'}} variant="outlined">Alterar Extensão de Agendamento</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default Extension