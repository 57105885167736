import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ApiService from 'app/services/config';
import {
  Box,
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
  Zoom,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';

const PatientListForParents = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [count, setCount] = useState('');
  const { hasPermission } = useContext(PermissionContext);
  const storedPermissions = JSON.parse(
    localStorage.getItem('storedPermissions')
  );
  const isUserhasParentPermission = storedPermissions.some(
    (item) => item.perfil_id === 3
  );

  const storedUserId = localStorage.getItem('id') || undefined;

  const getUsers = useCallback(async () => {
    try {
      let apiUrl = `users/pacientes/filtrados?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdLogged=${storedUserId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      /*  let apiUrl = `/users/all/patients`; */
      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response.data);

          setUsers(response.data?.users);
          setCount(response.data?.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === '' || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const deleteUser = useCallback(async (id) => {
    ApiService.delete(`/users/${id}`)
      .then((response) => {
        getUsers();
      })
      .catch((error) => {
        const message = error.response.data.message;
        console.error(message);
      });
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'action',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[0];
          return (
            <Box display={'flex'} gap={'1rem'}>
              <Tooltip title='Visualizar' TransitionComponent={Zoom}>
                <Link
                  to={
                    !hasPermission('Acesso dos Pais', 'read')
                      ? '/evolution-parents'
                      : `/evolution-parents/user/${userId}`
                  }
                  state={{ backUrl: '/evolution-parents' }}
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton
                    aria-label='visualizar'
                    color='info'
                    disabled={!hasPermission('Acesso dos Pais', 'read')}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: 'dropdown',
    filter: false,
    selectableRows: 'none',
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: 'above',

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          break;
        case 'sort':
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case 'search':
          handleSearch(tableState.searchText);
          break;
        case 'changeRowsPerPage':
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log('action not handled.');
      }
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum resultado encontrado',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Itens por Página:',
        displayRows: 'of',
      },
    },
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={'Acesso dos Pais'}
        subtitle={'Paciente'}
        titleUrl={'/evolution-parents'}
      />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Pacientes'}
            data={users}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientListForParents;
