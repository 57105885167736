import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Grid, Box, Autocomplete, Tooltip, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ApiService from "app/services/config";
import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import "moment/locale/pt-br";
import { useSnackbar } from "notistack";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const PeriodForm = () => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  moment.locale("pt-br");

  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState([]);

  //Exemple Select
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [removedItems, setRemovedItems] = useState([]);
  const [availability, setAvailability] = useState({
    somente_dias_uteis: false,
    tipo_criacao_agenda: "periodo",
    validade_inicial: "",
    validade_final: "",
    multiplos_atendentes: false,
    quantidade_vagas_por_horarios: "",
    servico_agendamento: "prestador",
    Disponibilidade_Prestador: [
      {
        havera_atendimento: false,
        dia_semana: "1",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
      {
        havera_atendimento: false,
        dia_semana: "2",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
      {
        havera_atendimento: false,
        dia_semana: "3",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
      {
        havera_atendimento: false,
        dia_semana: "4",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
      {
        havera_atendimento: false,
        dia_semana: "5",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
      {
        havera_atendimento: false,
        dia_semana: "6",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
      {
        havera_atendimento: false,
        dia_semana: "0",
        dia_especifico: "",
        filial: "",
        Disponibilidade_Prestador_Periodo: [
          {
            periodo: "",
            horario_inicio: "",
            horario_fim: "",
            tempo_atendimento: 0,
            tipo_agendamento: "",
          },
        ],
      },
    ],
  });

  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);

  const getWorkerAvailability = useCallback(async () => {
    try {
      await ApiService.get(`/worker-availability-infos/${id}`)
        .then((response) => {
          console.log(response.data);

          setAvailability(response.data);
          setUserSelected({
            selected: response.data?.Prestador,
          });
        
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [id, setAvailability]);

  const getAllUsers = React.useCallback(async () => {
    try {
      await ApiService.get("/users")
        .then((response) => {
          setUsers(response.data?.usersWithoutPassword);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getWorkerAvailability();
      getAllUsers();
    }
    getAllUsers();
  }, [id, getWorkerAvailability, getAllUsers]);

  const handleInputPrestadorChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleInputChange = (e, index, periodoIndex) => {
    const { name, value } = e.target;
    const list = [...availability.Disponibilidade_Prestador];
    const periodo = list[index].Disponibilidade_Prestador_Periodo[periodoIndex];

    periodo[name] = value;

    setAvailability((prevState) => {
      const updatedList = prevState.Disponibilidade_Prestador.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            Disponibilidade_Prestador_Periodo:
              el.Disponibilidade_Prestador_Periodo.map(
                (periodoItem, pIndex) => {
                  if (pIndex === periodoIndex) {
                    return {
                      ...periodoItem,
                      [name]: value,
                    };
                  }
                  return periodoItem;
                }
              ),
          };
        }
        return el;
      });
      return {
        ...prevState,
        Disponibilidade_Prestador: updatedList,
      };
    });
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/prestadores?searchTerm=${inputValue}`
      );
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveClick = (diaIndex, periodoIndex) => {
    setAvailability((prevState) => {
      const updatedList = prevState.Disponibilidade_Prestador.map(
        (el, index) => {
          if (index === diaIndex) {
            const updatedPeriods = el.Disponibilidade_Prestador_Periodo.filter(
              (_, pIndex) => pIndex !== periodoIndex
            );
            // Adicionar o item removido ao estado de itens removidos
            const removedItem =
              el.Disponibilidade_Prestador_Periodo[periodoIndex];
            setRemovedItems((prevRemovedItems) => [
              ...prevRemovedItems,
              removedItem,
            ]);
            return {
              ...el,
              Disponibilidade_Prestador_Periodo: updatedPeriods,
            };
          }
          return el;
        }
      );
      return {
        ...prevState,
        Disponibilidade_Prestador: updatedList,
      };
    });
  };

  const handleAddClick = (diaIndex) => {
    setAvailability((prevState) => {
      const updatedList = prevState.Disponibilidade_Prestador.map(
        (el, index) => {
          if (index === diaIndex) {
            return {
              ...el,
              Disponibilidade_Prestador_Periodo: [
                ...el.Disponibilidade_Prestador_Periodo,
                {
                  periodo: "",
                  horario_inicio: "",
                  horario_fim: "",
                  tempo_atendimento: "",
                  tipo_agendamento: "",
                },
              ],
            };
          }
          return el;
        }
      );
      return {
        ...prevState,
        Disponibilidade_Prestador: updatedList,
      };
    });
  };

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([0, 1, 2, 3]);
  const [right, setRight] = React.useState([4, 5, 6, 7]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`List item ${value + 1}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <>
      <HeaderBreadcrumbs
        title={"Consulta - Periodo de Agendamento"}
        subtitle={"Inclusão - Períodos de Utilização de Prestadores"}
        titleUrl={"/schedule-list"}
      />
      <Paper
        sx={{
          padding: "1rem",
          marginBottom: "15px",
          overflowX: "auto",
          maxHeight: "670px",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Somente dias úteis *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={availability?.somente_dias_uteis}
                    onChange={(event) =>
                      setAvailability({
                        ...availability,
                        somente_dias_uteis: JSON.parse(event.target.value),
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="SIM"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="NÃO"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="tipo-criacao-agenda-label">
                    Tipo criação agenda
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="tipo-criacao-agenda-label"
                    name="tipo-criacao-agenda-group"
                    value={availability?.tipo_criacao_agenda}
                    onChange={(event) =>
                      setAvailability({
                        ...availability,
                        tipo_criacao_agenda: event.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="periodo"
                      control={<Radio />}
                      label="Periodo"
                    />
                    <FormControlLabel
                      value="dias_especificos"
                      control={<Radio />}
                      label="Dias especificos"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="servico-agendamento-label">
                    Serviço Agendamento *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="servico-agendamento-label"
                    name="servico-agendamento-group"
                    value={availability.servico_agendamento}
                    onChange={(e) =>
                      setAvailability({
                        ...availability,
                        servico_agendamento: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="prestador"
                      control={<Radio />}
                      label="Prestador"
                    />
                    <FormControlLabel
                      value="sala"
                      control={<Radio />}
                      label="Sala"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Múltiplos Atendente *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={availability?.multiplos_atendentes}
                    onChange={(event) =>
                      setAvailability({
                        ...availability,
                        multiplos_atendentes: JSON.parse(event.target.value),
                      })
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="SIM"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="NÃO"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {availability.tipo_criacao_agenda === "periodo" && (
                <>
                  <Grid item xs={12} md={3}>
                    <FormControl>
                      <TextField
                        fullWidth
                        id="validade-inicial"
                        label="Validade Inicial"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={availability.validade_inicial}
                        onChange={(e) =>
                          setAvailability({
                            ...availability,
                            validade_inicial: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormControl>
                      <TextField
                        fullWidth
                        id="validade-final"
                        label="Validade Final"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={availability.validade_final}
                        onChange={(e) =>
                          setAvailability({
                            ...availability,
                            validade_final: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Quant. vagas por Horário"
                  variant="outlined"
                  type="number"
                  value={availability?.quantidade_vagas_por_horarios}
                  onChange={(e) =>
                    setAvailability({
                      ...availability,
                      quantidade_vagas_por_horarios: JSON.parse(e.target.value),
                    })
                  }
                />
              </Grid>

              {availability.servico_agendamento === "prestador" && (
                <Grid item xs={12} md={4}>
                  <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      getOptionLabel={(option) => option.nome}
                      options={optionsList}
                      loading={loading}
                      inputValue={userInput}
                      onInputChange={handleInputPrestadorChange}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUserSelected((prevValues) => ({
                            selected: newValue,
                          }));
                        }
                      }}
                      noOptionsText={"Nenhum resultado encontrado"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Prestador"
                          placeholder="Prestador"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? "carregando..." : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={userSelected?.selected || null}
                    />
                  </Tooltip>
                </Grid>
              )}
              {availability.servico_agendamento === "sala" && (
                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Sala</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="Sala"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          {availability.tipo_criacao_agenda === "dias_especificos" && (
            <Grid
              container
              spacing={2}
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ marginBottom: "15px" }}
                >
                  Dias Especificos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ height: "100%" }} />
              </Grid>
              <Grid item>{customList(left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList(right)}</Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider style={{ height: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                {/* segunda */}

                {availability?.Disponibilidade_Prestador?.map((el, index) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="segunda-content"
                      id="segunda-header"
                    >
                      {moment()
                        .isoWeekday(Number(el?.dia_semana))
                        .format("dddd")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Box display={"flex"} width={"100%"} paddingLeft={2}>
                          <Grid item xs={12} md={2}>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Haverá Atendimento ? *
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={Boolean(el?.havera_atendimento)}
                                onChange={(e) => {
                                  setAvailability((prevState) => {
                                    const updatedList =
                                      prevState.Disponibilidade_Prestador.map(
                                        (dia, indexItem) => {
                                          if (indexItem === index) {
                                            return {
                                              ...dia,
                                              havera_atendimento: JSON.parse(
                                                e.target.value
                                              ),
                                            };
                                          }
                                          return dia;
                                        }
                                      );
                                    return {
                                      ...prevState,
                                      Disponibilidade_Prestador: updatedList,
                                    };
                                  });
                                }}
                              >
                                <FormControlLabel
                                  value={true}
                                  control={<Radio />}
                                  label="SIM"
                                />
                                <FormControlLabel
                                  value={false}
                                  control={<Radio />}
                                  label="NÃO"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Filial
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Filial"
                                value={el?.filial}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setAvailability((prevState) => {
                                    const updatedList =
                                      prevState.Disponibilidade_Prestador.map(
                                        (dia, indexItem) => {
                                          if (indexItem === index) {
                                            return {
                                              ...dia,
                                              filial: value,
                                            };
                                          }
                                          return dia;
                                        }
                                      );
                                    return {
                                      ...prevState,
                                      Disponibilidade_Prestador: updatedList,
                                    };
                                  });
                                }}
                              >
                                <MenuItem value={"pp"}>PP</MenuItem>
                                <MenuItem value={"aa"}>AA</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Box>

                        {el.havera_atendimento &&
                          el.Disponibilidade_Prestador_Periodo?.map(
                            (item, indexPeriod) => (
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                width={"100%"}
                                paddingLeft={2}
                                marginTop={2}
                                gap={2}
                              >
                                <Grid item xs={12} md={1}>
                                  <Typography gutterBottom>
                                    {`Periodo ${indexPeriod + 1}`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Tempo de Atendimento
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Tempo de atendimento"
                                      name="tempo_atendimento"
                                      value={item.tempo_atendimento}
                                      onChange={(e) =>
                                        handleInputChange(e, index, indexPeriod)
                                      }
                                    >
                                      <MenuItem value={5}>05</MenuItem>
                                      <MenuItem value={10}>10</MenuItem>
                                      <MenuItem value={20}>20</MenuItem>
                                      <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label="Inicio"
                                    variant="outlined"
                                    type="time"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name="horario_inicio"
                                    value={item?.horario_inicio}
                                    onChange={(e) =>
                                      handleInputChange(e, index, indexPeriod)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label="Final"
                                    variant="outlined"
                                    type="time"
                                    name="horario_fim"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={item?.horario_fim}
                                    onChange={(e) =>
                                      handleInputChange(e, index, indexPeriod)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Tipo Agendamento
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Tipo Agendamento"
                                      name="tipo_agendamento"
                                      value={item.tipo_agendamento}
                                      onChange={(e) =>
                                        handleInputChange(e, index, indexPeriod)
                                      }
                                    >
                                      <MenuItem value={"consulta"}>
                                        Consulta
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                  {item.horario_inicio === "" ||
                                  item.horario_fim === "" ||
                                  item.tempo_atendimento === "" ||
                                  item.tipo_agendamento === ""
                                    ? null
                                    : el.Disponibilidade_Prestador_Periodo
                                        .length !== 1 && (
                                        <Button
                                          color="error"
                                          variant="contained"
                                          size="large"
                                          component="label"
                                          onClick={() =>
                                            handleRemoveClick(
                                              index,
                                              indexPeriod
                                            )
                                          }
                                          sx={{
                                            height: "60px",
                                            borderRadius: "50%",
                                            marginRight: {
                                              xs: "0.5rem",
                                            },
                                            fontSize: {
                                              xs: "0.7rem",
                                            },
                                          }}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      )}
                                  {el.Disponibilidade_Prestador_Periodo.length -
                                    1 ===
                                    indexPeriod && (
                                    <Button
                                      sx={{
                                        height: "53.13px",
                                        fontSize: {
                                          xs: "0.7rem",
                                        },
                                        padding: {
                                          lg: "8px 22px",
                                          xs: "8px 5px",
                                        },
                                      }}
                                      color="info"
                                      variant="contained"
                                      size="large"
                                      component="label"
                                      disabled={
                                        item.horario_inicio === "" ||
                                        item.horario_fim === "" ||
                                        item.tempo_atendimento === "" ||
                                        item.tipo_agendamento === ""
                                      }
                                      onClick={() => handleAddClick(index)}
                                    >
                                      <AddIcon />
                                    </Button>
                                  )}
                                </Grid>
                              </Box>
                            )
                          )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {/*  <Grid item xs={12} sm={4} md={2}>
                                <Typography variant='h3' gutterBottom>
                                    Código
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant='h3' gutterBottom>
                                    Inclusão
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant='h3' gutterBottom>
                                    Colaborador Inclusão
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant='h3' gutterBottom>
                                    Alteração
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant='h3' gutterBottom>
                                    Colaborador Alteração
                                </Typography>
                            </Grid> */}
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Box display={"flex"} justifyContent={"center"} gap={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (id) {
                        const workerAvailabilityEdit = {
                          ...availability,
                          prestador_id: userSelected?.selected?.id,
                          Prestador: undefined,
                          Periodos_Removidos: removedItems,
                        };

                        console.log(workerAvailabilityEdit);

                        ApiService.put(
                          `/worker-availability-infos/${id}`,
                          workerAvailabilityEdit
                        )
                          .then((response) => {
                            console.log(response.data);
                            enqueueSnackbar(
                              "Agendamento atualizado com sucesso!",
                              {
                                variant: "success",
                              }
                            );
                          })
                          .catch((error) => {
                            const errorMessage = error.response.data.message;
                            console.log(errorMessage);
                            if (Array.isArray(errorMessage)) {
                              enqueueSnackbar(`${errorMessage[0]}`, {
                                variant: "error",
                              });
                            } else {
                              enqueueSnackbar(`${errorMessage}`, {
                                variant: "error",
                              });
                            }
                          });
                      } else {
                        const scheduleCreated = {
                          ...availability,
                          prestador_id: userSelected?.selected?.id,
                          Prestador: undefined,
                        };
                        console.log(scheduleCreated);

                        ApiService.post(
                          `/worker-availability-infos`,
                          scheduleCreated
                        )
                          .then((response) => {
                            console.log(response.data);
                            enqueueSnackbar("Agendamento criado com sucesso!", {
                              variant: "success",
                            });
                          })
                          .catch((error) => {
                            const errorMessage = error.response.data.message;
                            console.log(errorMessage);
                            if (Array.isArray(errorMessage)) {
                              enqueueSnackbar(`${errorMessage[0]}`, {
                                variant: "error",
                              });
                            } else {
                              enqueueSnackbar(`${errorMessage}`, {
                                variant: "error",
                              });
                            }
                          });
                      }
                    }}
                  >
                    Salvar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(
                        state?.backUrl ? state.backUrl : "/schedule-list"
                      )
                    }
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PeriodForm;
