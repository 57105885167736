import Chip from '@mui/material/Chip';
import DataTable from '../DataTable';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Box, Grid, OutlinedInput } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SearchIcon from '@mui/icons-material/Search';
import Div from '@jumbo/shared/Div';
import { DateRangePicker } from 'react-dates';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatDateWithMoment, getCustomDateTimeUtc } from '@jumbo/utils';

const LevelThree = () => {
    const socialComunicaçao = [
        'Presta atencao e se junta a atividades com adulto durante a leitura de um livro.',
        'Segue ordem novas envolvendo objetos/ ações.',
        'Realiza pareamento de imagens e objetos concretos.',
        'Responde de forma adequada a perguntas usando "sim" e "nao" para sua preferencias.',
        'Percebe relações espaciais envolvendo objetos (em cima da mesa, embaixo do tapete, perto da escada ... ).',
        'Diferencia conceitos como grande e pequeno.',
        'Diferencia pelo menos quatro cores diferentes quando solicitado.',
        'Identifica som pelo objeto (som de telefone, sino, chuva ... ).',
        'Compreende a função dos objetos (cortar, calçar, beber).',
        'Compreende pronomes como "meu", "teu", "nosso", "dele" .',
        'Combina duas ou tres palavras.',
        'Nomeia acoes das pessoas, imagens ou dele mesmo.',
        'Gesticula e vocaliza "nao sei" em contexto.',
        'Transmite uma mensagem simples a outra pessoa (vai dizer olá à mamae).',
        'Usa palavras e gestos para descrever experiencias .',
        'Responde de forma adequada as questoes de "o que", "quem", "onde".',
        'Faz perguntas simples usando entonaçao correta.',
        'Responde a perguntas com informacoes simples (nome, idade, cor da camisa).',
    ];
    const socialComportamento = [
        'Participa na rotina de jogos dramáticos',
        'Toma iniciativa em jogos de tabuleiro simples',
        'Utiliza termos de cortesia "por favor", "obrigado" e "desculpa."',
        'Imita comandos em jogos de açao. Ex: segue o lider.',
        'Participa de jogo de faz de conta com o parceiro.',
        'Chama a atençao dos outros para objetos verbalizando e gesticulando para comentar, mostra e pedir.',
        'Identifica sentimentos (feliz, triste, zangado e assutada) em figuras, cartoes e/ou no outro.',
        'Faz o seu proprio rosto refletir uma emoção.',
    ];
    const cogniçao = [
        'Faz correspondencia de letras com nomes.',
        'Faz correspondencia de letras com figuras.',
        'Faz correspondencia de letras com palavras.',
        'Faz correspondencia de numeros.',
        'Identifica letras, numeros formas e cores.',
        'Corresponde relações entre quanidade.',
        'Conta o número de objetos.',
        'Narra sequência de imagem em ordem correta (começo, meio e fim).',
        'Realiza categorizacao de objetos / imagens.',
    ];
    const jogos = [
        'Jogo construtivo envolvendo vários objetos.',
        'Realiza ações numa sequencia de jogo. Ex: Tirar a roupa da boneca + fazer xixi + tomar a mamadeira (3 ações)',
        'Coloa figuras de pessoas dentro de objetos (a pessoa no carro, na casa)',
        'Brinca de forma espontanea com bonecas ou animais.',
        'Organiza todos os objetos para brincar com determinado tema.',
    ];
    const motricidade = [
        'Monsta quebra-cabeça de cinco a seis peças conectadas.',
        'Copia desenho de um circulo, cruz, quadrado e linha diagonal.',
        'Realiza enfiamentos de ponto corrido.',
        'Traça linhas e curvas com o dedo ou matéria de escrever.',
        'Traça várias formas.',
        'Utiliza utensilios para pegar/largar objetos (pincel, garfo)',
        'Manuseia tesoura e utiliza a mão oposta para utilizar o papel.',
        'Corta linhas e curvas',
        'Realiza corte e colagem em duas etapas (corte e cola, carimba, dobra o papel e corta)',
        'Manusela de diferentes formas a massa de modelar.',
    ];
    const motricidadeGrossa = [
        'Chuta de maneira adequada e com equilibrio.',
        'Joga jogo de perseguição com adultos ou pares (com equilibrio e mudando de direçao).',
        'Imita ações com movimentos para músicas.',
        'Salta com os dois pes juntos.',
        'Salta ao pé imcopleto (salto)',
        'Ativo o alvo',
    ];

    const { enqueueSnackbar } = useSnackbar();
    const { quizId, userId } = useParams();
    const location = useLocation();

    const storedColaboratorId = localStorage.getItem('id');

    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [who, setWho] = useState([]);

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    const date = query.get('date');

    const getQuizzes = useCallback(async () => {
        try {
            await ApiService.get(`/quizzes/${quizId}`)
                .then((response) => {
                    console.log(response.data?.pergunta);

                    setQuizzes(response.data?.pergunta);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setQuizzes, quizId]);

    const getQuizzesAnswers = useCallback(async () => {
        try {
            await ApiService.get(
                `/quizzes/${quizId}/user/${userId}?date=${date}`
            )
                .then((response) => {
                    console.log(response.data);

                    setQuizzes(response.data?.userQuizz?.pergunta);
                    setWho(response.data?.findWhoCreatedAndUpdated);

                    /* setAnswers(respostasFormatadas); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [date, quizId, userId]);

    useEffect(() => {
        if (location?.pathname.includes('edit')) {
            getQuizzesAnswers();
        } else {
            getQuizzes();
        }
    }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

    console.log(answers);

    const getSelectedAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) =>
                resposta?.Resposta_Usuario?.length > 0 &&
                resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.id;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    const getObsAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) => resposta?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.observacao;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    return (
        <div>
            <HeaderBreadcrumbs
                title={'Ficha Diária - NÍVEL 3'}
                titleUrl={'/'}
            />
            <Paper
                style={{ padding: '10px', marginBottom: '15px' }}
                square={false}
                elevation={3}
            >
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignItems={'center'}
                >
                    <Grid item xs={10}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 3 - Social / Comunicação
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 3 - Social / Comunicação' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 3 - Social / Comportamento
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 3 - Social / Comportamento' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 3 - Cognição
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 3 - Cognição' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 3 - Jogos
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 3 - Jogos' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 3 - Motricidade Fina e Grossa
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 3 - Motricidade Fina e Grossa' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 3 - Motricidade Grossa
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 3 - Motricidade Grossa' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {location.pathname.includes('edit') && (
                    <>
                        <Divider
                            style={{
                                height: '100%',
                                marginBottom: '20px',
                                marginTop: '20px',
                            }}
                        />
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            rowSpacing={5}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '10px',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>
                                                    Código
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Inclusão
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Inclusão{' '}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Alteração
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Alteração{' '}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell align='center'>
                                                    -
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {formatDateWithMoment(
                                                        who?.data_horario_resposta
                                                    ) ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Incluiu
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {getCustomDateTimeUtc(
                                                        who?.updatedAt
                                                    )}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Alteracao
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                if (location?.pathname.includes('edit')) {
                                    ApiService.put(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                } else {
                                    ApiService.post(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                }
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default LevelThree;
