import React from 'react';
import { Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Div from '@jumbo/shared/Div';
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const HeaderBreadcrumbs = ({id, title, subtitle, titleUrl}) => {
   
    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 4
            }}
        >
            <BreadcrumbsContent role="presentation">
                <Breadcrumbs 
                     separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <NavLink 
                        to={titleUrl}
                        className={(navData) => (navData.isActive ? 'active' : 'link')}
                    >
                        {title}
                    </NavLink>
                    <NavLink
                        to={"#"}
                        className={(navData) => (navData.isActive ? 'active' : 'link')}
                    >
                        {subtitle}
                    </NavLink>
                </Breadcrumbs>
            </BreadcrumbsContent>
        </Div>
        
    );
};


const BreadcrumbsContent = styled('div')`

    .link, .active {
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 400;
        font-family: NoirPro,Arial;
        color: #37373C;
    }

   /*  .link {
        color: #37373C;
    }

    .active {
        color: blue;
    } */
`

export default HeaderBreadcrumbs;