import Chip from '@mui/material/Chip';
import DataTable from '../DataTable';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Box, Grid, OutlinedInput } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SearchIcon from '@mui/icons-material/Search';
import Div from '@jumbo/shared/Div';
import { DateRangePicker } from 'react-dates';
import ApiService from 'app/services/config';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatDateWithMoment, getCustomDateTimeUtc } from '@jumbo/utils';

const LevelFour = () => {
    const socialComunicaçao = [
        'Pega objetos solicitados com alguma caracteristica. Ex. "pega o quadro azul".',
        'Compreende pronome de gênero (artigos A, O e feminino e masculino).',
        'Compreende maior, menor, baixo, alto.',
        'Compreende variações especiais (cima, baixo, atras, frente).',
        'Compreende negaçoes (frases negativas). Ex: O rapaz nao esta sentado.',
        'Compreende relações de posse e parte-todo (meu, seu, nosso).',
        'Responde sim/nao para perguntas objetivas.',
        'Responde questoes sobre estados fisicos, informaçao pessoal.',
        'Compreende igual e diferente.',
        'Compreende conceitos de qualidade.',
        'Identifica caracteristicas dos objetivos.',
        'Compreende o tempo passado/futuro.',
        'Segue instrucoes de todas as partes.',
        'Responde a questoes complexas (Quem? Como? Por que?).',
        'Descreve a funçao de objetos em resposta a uma pergunta ("o que vou fazer com uma colher?)',
        'Se comunica de forma complexa (uso de frases longas, adjetivos etc). variedade de expressoes verbais).',
        'Descreve experiencias recentes usando frases complexas .*',
        'Solicitar primeiro para realizar alguma atividade .',
        'Usa frases/palavras colocando corretamente na sequência/plural .',
        'Participa de conversas iniciada, comentando, respondendo e perguntando.',
        'Inicia e mantem conversa guiada por si mesmo.',
        'Solicita explicacao caso nao entenda o que foi dito .',
        'Reforma a propria comunicacao quando o ouvinte nao compreende.',
        'Responde a perguntas sobre si mesmo e sobre os outros',
    ];
    const socialComportamento = [
        'Convida o outro para brincar',
        'Usa termos como "desculpa" e "obrigada"',
        'Expressa os sentimentos de forma adequada.',
        'Toma a vez no jogo de forma autonoma.',
        'Identifica o que fez sentir-se feliz, triste, zangada, assustada.',
        'Identifica emocoes dos outros com base na situação.',
    ];
    const cogniçao = [
        'Conta de cor ate 20',
        'Conta objetos ate 10',
        'Compreende conceitos de qualidade.',
        'Realiza cinco associaçoes de objetos/palavras.',
        'Consegue ler algumas palavras.',
        'Consegue identificar o nome escrito.',
        'Lê sinais e simbolos.',
        'Identifica numeros e letras.',
        'Relaciona parecido/diferente.',
    ];
    const jogos = [
        'Realiza narração durante o jogo. Ex: estou cozinhando .',
        'Substitui o uso de um objeto durante faz de conta. Ex: finge que e um ferro de passar.',
        'Conversa com parceiro durante o jogo.',
        'Representa varios temas/historias no jogo.',
        'Assume o papel de seu personagem e o representa.',
    ];
    const jogoIndependente = [
        'Se mantem ocupado com materais/jogos simples de forma adequada com a orientacao ocasional de adulto',
        'Pega o material/brinquedo, traz para a mesa, completa a atividade do jogo, guarda e se afasta (comeco, meio e fim)',
    ];
    const motricidade = ['Pinta com precisao e varia cores.'];

    const { enqueueSnackbar } = useSnackbar();
    const { quizId, userId } = useParams();
    const location = useLocation();

    const storedColaboratorId = localStorage.getItem('id');

    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [who, setWho] = useState([]);

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    const date = query.get('date');

    const getQuizzes = useCallback(async () => {
        try {
            await ApiService.get(`/quizzes/${quizId}`)
                .then((response) => {
                    console.log(response.data?.pergunta);

                    setQuizzes(response.data?.pergunta);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setQuizzes, quizId]);

    const getQuizzesAnswers = useCallback(async () => {
        try {
            await ApiService.get(
                `/quizzes/${quizId}/user/${userId}?date=${date}`
            )
                .then((response) => {
                    console.log(response.data);

                    setQuizzes(response.data?.userQuizz?.pergunta);
                    setWho(response.data?.findWhoCreatedAndUpdated);

                    /* setAnswers(respostasFormatadas); */
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        if (location?.pathname.includes('edit')) {
            getQuizzesAnswers();
        } else {
            getQuizzes();
        }
    }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

    console.log(answers);

    const getSelectedAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) =>
                resposta?.Resposta_Usuario?.length > 0 &&
                resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.id;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    const getObsAnswer = (respostas, perguntaId) => {
        // Verifica se há uma resposta salva no estado answers para a pergunta específica
        const respostaSalva = answers.find(
            (resposta) => resposta.pergunta_id === perguntaId
        );

        // Se houver uma resposta salva, retorna o ID dessa resposta
        if (respostaSalva) {
            return respostaSalva.resposta_id || respostaSalva.observacao;
        }

        // Se não houver uma resposta salva, verifica se há uma resposta do servidor
        const respostaUsuario = respostas.find(
            (resposta) => resposta?.pergunta_id === perguntaId
        );

        // Se houver uma resposta do servidor, retorna o ID dessa resposta
        if (respostaUsuario) {
            return respostaUsuario.observacao;
        }

        // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
        return '';
    };

    console.log(answers);

    return (
        <div>
            <HeaderBreadcrumbs
                title={'Ficha Diária - NÍVEL 4'}
                titleUrl={'/'}
            />
            <Paper
                style={{ padding: '10px', marginBottom: '15px' }}
                square={false}
                elevation={3}
            >
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignItems={'center'}
                >
                    <Grid item xs={10}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Social / Comunicaçao
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Social / Comunicação' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Social / Comportamento
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Social / Comportamento' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Cognição
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Cognição' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Jogos
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Jogos' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Jogo Lúdico
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Jogo Lúdico' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Jogo Independente
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Jogo Independente' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1-content'
                                        id='panel1-header'
                                    >
                                        NÍVEL 4 - Motricidade Fina e Grossa
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            {quizzes?.map((item) =>
                                                item?.Secao?.nome ===
                                                'NÍVEL 4 - Motricidade Fina e Grossa' ? (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            {item?.nome !==
                                                            'Observação adicional' ? (
                                                                <>
                                                                    <InputLabel id='demo-simple-select-label'>
                                                                        {
                                                                            item?.nome
                                                                        }
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId='demo-simple-select-label'
                                                                        id='demo-simple-select'
                                                                        value={getSelectedAnswer(
                                                                            item?.Resposta,
                                                                            item?.id
                                                                        )}
                                                                        label={
                                                                            item?.nome
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: userAnswered
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            resposta_id:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {item?.Resposta?.map(
                                                                            (
                                                                                resposta
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        resposta?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        resposta?.resposta
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id='outlined-multiline-static'
                                                                        multiline
                                                                        rows={4}
                                                                        label='Observaçao adicional'
                                                                        value={getObsAnswer(
                                                                            item?.Resposta_Usuario,
                                                                            item?.id
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAnswers(
                                                                                (
                                                                                    prevAnswers
                                                                                ) => {
                                                                                    const userAnswered =
                                                                                        item?.Resposta?.find(
                                                                                            (
                                                                                                resposta
                                                                                            ) =>
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                resposta
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.pergunta_id ===
                                                                                                    item?.id
                                                                                        );
                                                                                    const newAnswer =
                                                                                        {
                                                                                            id: item
                                                                                                ?.Resposta_Usuario[0]
                                                                                                ?.id,
                                                                                            pergunta_id:
                                                                                                item.id,
                                                                                            observacao:
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            data_horario_resposta:
                                                                                                userAnswered
                                                                                                    ?.Resposta_Usuario[0]
                                                                                                    ?.data_horario_resposta ||
                                                                                                date,
                                                                                        };
                                                                                    // Verifica se já existe uma resposta com o mesmo pergunta_id
                                                                                    const existingAnswerIndex =
                                                                                        prevAnswers.findIndex(
                                                                                            (
                                                                                                ans
                                                                                            ) =>
                                                                                                ans.pergunta_id ===
                                                                                                item.id
                                                                                        );
                                                                                    // Se sim, substitui a resposta existente, senão adiciona uma nova
                                                                                    if (
                                                                                        existingAnswerIndex !==
                                                                                        -1
                                                                                    ) {
                                                                                        const updatedAnswers =
                                                                                            [
                                                                                                ...prevAnswers,
                                                                                            ];
                                                                                        updatedAnswers[
                                                                                            existingAnswerIndex
                                                                                        ] =
                                                                                            newAnswer;
                                                                                        return updatedAnswers;
                                                                                    } else {
                                                                                        return [
                                                                                            ...prevAnswers,
                                                                                            newAnswer,
                                                                                        ];
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : undefined
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {location.pathname.includes('edit') && (
                    <>
                        <Divider
                            style={{
                                height: '100%',
                                marginBottom: '20px',
                                marginTop: '20px',
                            }}
                        />
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            rowSpacing={5}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '10px',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>
                                                    Código
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Inclusão
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Inclusão{' '}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    Alteração
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    Colaborador Alteração{' '}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell align='center'>
                                                    -
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {formatDateWithMoment(
                                                        who?.data_horario_resposta
                                                    ) ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Incluiu
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {getCustomDateTimeUtc(
                                                        who?.updatedAt
                                                    )}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {who?.Colaborador_Alteracao
                                                        ?.nome ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Divider
                    style={{
                        height: '100%',
                        marginBottom: '20px',
                        marginTop: '20px',
                    }}
                />
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                if (location?.pathname.includes('edit')) {
                                    ApiService.put(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                } else {
                                    ApiService.post(
                                        `/user-answered/user/${userId}/colaborator/${Number(
                                            storedColaboratorId
                                        )}`,
                                        answers
                                    )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Ficha criada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                }
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default LevelFour;
