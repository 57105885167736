import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback } from "react";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

const todasPerguntas = [
  {
    categoria: "Checklist de intervenção",
    perguntas: [
      "Comunicação social",
      "Comportamento social",
      "Cognição",
      "Jogos",
      "Jogo lúdico",
      "Jogo independente",
      "Motricidade fina e grossa",
      "Identificar emoções",
    ],
  },
];

const PsychologyFour = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { quizzId, userId } = useParams();
  const location = useLocation();

  const storedColaboratorId = localStorage.getItem("id");

  const [quizzes, setQuizzes] = useState([]);
  const [answers, setAnswers] = useState([]);

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const date = query.get("date");

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}`)
        .then((response) => {
          console.log(response.data?.pergunta);

          setQuizzes(response.data?.pergunta);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, quizzId]);

  const getQuizzesAnswers = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}/user/${userId}?date=${date}`)
        .then((response) => {
          console.log(response.data);

          setQuizzes(response.data?.pergunta);

          /* setAnswers(respostasFormatadas); */
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname.includes("edit")) {
      getQuizzesAnswers();
    } else {
      getQuizzes();
    }
  }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

  console.log(answers);

  const getSelectedAnswer = (respostas, perguntaId) => {
    // Verifica se há uma resposta salva no estado answers para a pergunta específica
    const respostaSalva = answers.find(
      (resposta) => resposta.pergunta_id === perguntaId
    );

    // Se houver uma resposta salva, retorna o ID dessa resposta
    if (respostaSalva) {
      return respostaSalva.resposta_id;
    }

    // Se não houver uma resposta salva, verifica se há uma resposta do servidor
    const respostaUsuario = respostas.find(
      (resposta) =>
        resposta?.Resposta_Usuario?.length > 0 &&
        resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
    );

    // Se houver uma resposta do servidor, retorna o ID dessa resposta
    if (respostaUsuario) {
      return respostaUsuario.id;
    }

    // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
    return "";
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Aba de Avaliação Interdisciplinar"}
        subtitle={"Nível 4"}
        titleUrl={"/"}
      />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                {todasPerguntas.map((categoria, index) => (
                  <Accordion defaultExpanded my={2}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography variant={"h3"} fontWeight={500}>
                        {categoria.categoria}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        {categoria.perguntas.map((pergunta, index) => (
                          <Grid item xs={12} key={index}>
                            <Stack
                              direction="row"
                              gap={2}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              width="100%"
                            >
                              <Typography>
                                {index + 1} - {pergunta}
                              </Typography>
                              <Select sx={{ minWidth: "150px" }}>
                                <MenuItem value={"adquirido"}>
                                  Adquirido
                                </MenuItem>
                                <MenuItem value={"parcial"}>Parcial</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"nao"}>Não</MenuItem>
                                <MenuItem value={"nao_observado"}>
                                  Não observado
                                </MenuItem>
                              </Select>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">Inclusão</TableCell>
                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                    <TableCell align="center">Alteração</TableCell>
                    <TableCell align="center">
                      {" "}
                      Colaborador Alteração{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                if (location?.pathname.includes("edit")) {
                  ApiService.put(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                }
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default PsychologyFour;
