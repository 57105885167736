import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Avatar,
  Box,
  Divider,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import ApiService from "app/services/config";
import * as yup from "yup";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { makeStyles } from "@mui/styles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import socket from "app/services/websockets";
import { PermissionContext } from "app/contexts/PermissionContext";

const useStyles = makeStyles(() => ({
  highestZIndex: {
    zIndex: 9999,
  },
}));

const validationSchema = yup.object().shape({
  users: yup
    .array()
    .required("Campo obrigatório")
    .min(2, "Selecione pelo menos 1 prestador"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 600,
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ChatDetails = ({
  selectedRoom,
  handleSelectRoom,
  isModalDetailsOpen,
  setIsModalDetailsOpen,
}) => {
  const USER_LOGGED_ID = Number(localStorage.getItem("id"));
  const {hasPermission}  = useContext(PermissionContext);
  const { userId } = useParams();
  let initialValues = {
    users: [],
    responsaveis: [],
  };
  const [optionsList, setOptionsList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { userId: patientId } = useParams();
  const [isAddingUsersToRoom, setIsAddingUsersToRoom] = useState(false);

  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userSelected, setUserSelected] = useState(null);

  const [loadingResponsavel, setLoadingResponsavel] = useState(false);
  const [optionsResponsavelList, setOptionsResponsavelList] = useState([]);
  const [userResponsavelInput, setUserResponsavelInput] = useState("");
  const [userResponsavelSelected, setUserResponsavelSelected] = useState(null);

  const [confirmRemoveUserId, setConfirmRemoveUserId] = useState(null);

  const Swal = useSwalWrapper();
  const classes = useStyles();

  const websocket = socket;

  const handleClick = (userId, username) => {
    if (confirmRemoveUserId === userId) {
      handleRemoveUserFromChatroom(userId, username);
    } else {
      setConfirmRemoveUserId(userId);
      enqueueSnackbar("Clique novamente para confirmar a exclusão.", {
        variant: "info",
      });
    }
  };

  const handleRemoveUserFromChatroom = async (userId, username) => {
    try {
      await ApiService.put(
        `/chatroom/remove-user-from-chatroom/${selectedRoom.id}/${userId}`
      ).then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Usuário removido da sala!", {
            variant: "success",
          });
          websocket.emit('removeUser', {
            roomId: selectedRoom.id,
            username,
            newRoomState: response.data
          })
        }
        handleSelectRoom(response.data);
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Ocorreu um erro ao remover o usuário da sala!", {
        variant: "error",
      });
    }
  };

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/prestadores?searchTerm=${inputValue}`
      );
      const users = response.data.filter(
        (user) =>
          !selectedRoom.users.some((roomUser) => roomUser.id === user.id)
      );
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersResponsaveis = async (inputValue) => {
    setLoadingResponsavel(true);
    try {
      const response = await ApiService.get(
        `/users/all/responsaveis?searchTerm=${inputValue}&pacienteId=${userId}`
      );
      const users = response.data.filter(
        (user) =>
          !selectedRoom.users.some((roomUser) => roomUser.id === user.id)
      );
      setOptionsResponsavelList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingResponsavel(false);
    }
  };

  const handleInputResponsavelChange = (event, newInputValue) => {
    setUserResponsavelInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsersResponsaveis(newInputValue);
    } else {
      setOptionsResponsavelList([]);
      setUserResponsavelSelected(null);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleSubmit = async (values) => {
    if(values.users?.length === 0 && values.responsaveis?.length === 0) {
      enqueueSnackbar("Selecione um prestador ou um responsável!", {
        variant: "warning"
      });
      return;
    }
    const prestadoresIds = values?.users?.map(user => user.id);
    const responsaveisIds = values?.responsaveis?.map(user => user.id);
    const nomesPrestadoresAdicionados = values?.users?.map(user => user.nome);
    const nomesResponsaveisAdicionados = values?.responsaveis?.map(user => user.nome);
    const usersIds = [...prestadoresIds, ...responsaveisIds];
    const nomesUsuarios = [...nomesPrestadoresAdicionados, ...nomesResponsaveisAdicionados];
    try {
      await ApiService.put(`/chatroom/add-users-to-chatroom/${selectedRoom.id}`, {usersIds: usersIds})
      .then((response) => {
        if(response.status === 200) {
          const usersIdsLength = usersIds.length
          enqueueSnackbar(`Usuário${usersIdsLength > 1 ? 's' : ''} adicionado${usersIdsLength > 1 ? 's' : ''} com sucesso!`, {
            variant: "success"
          });
          websocket.emit("usersAddedToRoom", {
            roomId: selectedRoom.id,
            usersIds,
            nomesUsuarios,
            newRoomState: response.data
          })
          handleSelectRoom(response.data);
          setIsAddingUsersToRoom(false);
          setUserSelected([]);
          setUserResponsavelSelected([]);
          initialValues = {
            users: [],
            responsaveis: [],
          };
        }
      })
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Ocorreu um erro ao adicionar os usuários!", {
        variant: "error"
      })
    }
  }

  useEffect(() => {
    setIsAddingUsersToRoom(false);
  }, [isModalDetailsOpen, selectedRoom]);

  return (
    <Modal
      open={isModalDetailsOpen}
      onClose={setIsModalDetailsOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Div sx={style}>
        <Typography id="modal-modal-title" variant="h2" component="h2">
          Detalhes da sala
        </Typography>
        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            handleChange,
            isSubmitting,
            handleBlur,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form style={{ width: "100%" }}>
              <Stack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}
                gap={2}
                my={1}
              >
                <Avatar sx={{ width: 64, height: 64 }}>
                  {String(selectedRoom?.nome).charAt(0).toUpperCase()}
                </Avatar>
                <Typography variant="h3">{selectedRoom?.nome}</Typography>
                <Typography>
                  Criado em{" "}
                  {moment(selectedRoom?.createdAt).format("DD/MM/YYYY")}
                </Typography>
                <Divider />
              </Stack>
                <Box
                width="100%"
                direction={"row"}
                justifyContent={"space-evenly"}
                >
                    <Stack direction={"row"} gap={2}>
                      <Typography variant="h4">Participantes</Typography>
                      { !hasPermission('Paciente Evolução', 'create') ? undefined : (
                          <Tooltip title="Adicionar participantes">
                            <PersonAddIcon
                              color="primary"
                              cursor="pointer"
                              onClick={() => {
                                setIsAddingUsersToRoom(!isAddingUsersToRoom);
                              }}
                            />
                          </Tooltip>
                        )
                      }
                    </Stack>
                  </Box>
              {isAddingUsersToRoom && (
                <Box width="100%">
                  <Box my={2}>
                    <Tooltip
                      title="Digite o nome para buscar a pessoa no sistema"
                      TransitionComponent={Zoom}
                      placement="top-end"
                    >
                      <FieldArray name="users">
                        {({ push, remove }) => (
                          <Autocomplete
                            fullWidth
                            disablePortal
                            getOptionLabel={(option) => option?.nome ?? ""}
                            options={optionsList}
                            loading={loading}
                            inputValue={userInput}
                            onInputChange={handleInputChange}
                            name="users"
                            multiple
                            value={values.users}
                            onBlur={handleBlur}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const actualUser = values?.users?.some(
                                  (user) =>
                                    user?.id ===
                                    newValue[newValue.length === 1 ? 0 : -1]?.id
                                );
                                if (actualUser) {
                                  const filteredUsers = values?.users?.filter(
                                    (user) =>
                                      user?.id ===
                                      newValue[newValue.length - 1].id
                                  );
                                  setFieldValue("users", filteredUsers);
                                } else if (!actualUser && newValue?.length > 0) {
                                  setFieldValue("users", [
                                    ...values.users,
                                    newValue[newValue.length - 1],
                                  ]);
                                }
                              }
                            }}
                            noOptionsText={"Nenhum resultado encontrado"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Prestadores"
                                placeholder="Prestadores"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? "carregando..." : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option?.id === value?.id
                            }
                          />
                        )}
                      </FieldArray>
                      <FormHelperText>
                        Se você faz parte da conversa, inclua o seu usuário, caso contrário, não verá a sala criada
                      </FormHelperText>
                      <ErrorMessage
                        name="users"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Tooltip>
                  </Box>
                  <Box my={2}>
                    <Tooltip
                      title="Digite o nome para buscar a pessoa no sistema"
                      TransitionComponent={Zoom}
                      placement="top-end"
                    >
                      <FieldArray name="responsaveis">
                        {({ push, remove }) => (
                          <Autocomplete
                            fullWidth
                            disablePortal
                            getOptionLabel={(option) => option?.nome ?? ""}
                            options={optionsResponsavelList}
                            loading={loadingResponsavel}
                            inputValue={userResponsavelInput}
                            onInputChange={handleInputResponsavelChange}
                            name="responsaveis"
                            multiple
                            value={values.responsaveis}
                            onBlur={handleBlur}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                const actualUser = values?.responsaveis?.some(
                                  (user) =>
                                    user?.id ===
                                    newValue[newValue.length === 1 ? 0 : -1]?.id
                                );
                                if (actualUser) {
                                  const filteredUsers =
                                    values?.responsaveis?.filter(
                                      (user) =>
                                        user?.id ===
                                        newValue[newValue.length - 1].id
                                    );
                                  setFieldValue("responsaveis", filteredUsers);
                                } else if (!actualUser) {
                                  setFieldValue("responsaveis", [
                                    ...values.responsaveis,
                                    newValue[newValue.length - 1],
                                  ]);
                                }
                              }
                            }}
                            noOptionsText={"Nenhum resultado encontrado"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Responsáveis"
                                placeholder="Responsáveis"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? "carregando..." : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option?.id === value?.id
                            }
                          />
                        )}
                      </FieldArray>
                      <ErrorMessage
                        name="users"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Tooltip>
                  </Box>
                  <Stack width="100%" justifyContent={"center"} my={2}>
                    <Button color="primary" variant="contained" onClick={() => {
                      handleSubmit(values);
                    }}>
                      Adicionar
                    </Button>
                  </Stack>
                </Box>
              )}
              <Box sx={{ maxHeight: 200, overflowY: "scroll" }}>
                <List>
                  {selectedRoom?.users
                    .filter((user) => Number(user.id) !== Number(patientId))
                    .map((user) => (
                      <ListItem key={user.id}>
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                        >
                          <Avatar
                            src={`${process.env.REACT_APP_API_KEY}/public/uploads/${user.avatar_url}`}
                          >
                            {user.avatar_url
                              ? null
                              : String(user.nome).charAt(0).toUpperCase()}
                          </Avatar>
                          <ListItemText
                            primary={user?.nome}
                            secondary={
                              user.especialidades &&
                              user?.especialidades?.length > 0 ? (
                                <Stack direction="row" spacing={1}>
                                  {user?.especialidades?.map(
                                    (especialidade, index) => (
                                      <Typography key={index} variant="caption">
                                        {
                                          especialidade?.especialidade
                                            ?.descricao
                                        }{" "}
                                      </Typography>
                                    )
                                  )}
                                </Stack>
                              ) : null
                            }
                          />
                          {
                            !hasPermission('Paciente Evolução', 'delete') ? undefined : (
                              <Tooltip title="Remover da sala">
                                <DeleteIcon
                                  color="error"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick(user.id, user.nome)}
                                />
                              </Tooltip>
                            )
                          }
                        </Stack>
                      </ListItem>
                    ))}
                </List>
              </Box>

              <Stack direction={"row"} gap={2} my={2} justifyContent={"center"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={setIsModalDetailsOpen}
                >
                  Fechar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Div>
    </Modal>
  );
};

export default ChatDetails;
