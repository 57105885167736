import React, { useCallback, useState } from 'react';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { updateInputValue } from 'app/utils/appHelpers';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import { useParams } from 'react-router-dom';
import ApiService from 'app/services/config';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FieldArray } from 'formik';

import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Box, Button, FormHelperText } from '@mui/material';
import { AgreementItem } from 'app/components/AgreementItem/AgreementItem';

function Agreements({
    infos,
    values,
    setFieldValue,
    handleChange,
    agreement,
    setAgreement,
}) {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});
    const allFieldsEmpty = Object.values(agreement).every((value) => !value);

    const agreementSchema = Yup.object().shape({
        nome: Yup.string().required('Campo convênio é obrigatório.'),
    });

    const handleEditAgreement = (agreementToEdit) => {
        setAgreement(agreementToEdit);
        setIsEditMode(true);
    };
    const modalAlert = (agreementId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                console.log('bem bem antes', values.Telefones);
                removeAgreement(agreementId);
            }
        });
    };

    const removeAgreement = useCallback(
        async (agreementId) => {
            ApiService.delete(`/agreements/${agreementId}`)
                .then((response) => {
                    const agreementEdit = agreement.id === agreementId;
                    const removedAgreement = values.Convenios.filter(
                        (tel) => tel.id !== agreementId
                    );
                    setFieldValue('Convenios', removedAgreement);
                    enqueueSnackbar('Convênio removido com sucesso!', {
                        variant: 'success',
                        
                    });
                    if (agreementEdit) {
                        setIsEditMode(false);
                        setAgreement({
                            nome: '',
                            numero_convenio: '',
                            validade: '',
                            acomodacao: '',
                            rede_atendimento: '',
                            plano_regulamentado: '',
                            abrangencia: '',
                            cobertura: '',
                            tipo_plano_convenio: '',
                            intercambio: '',
                            regulamentacao: '',
                            codigo_seguranca: '',
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [enqueueSnackbar, setFieldValue, values.Convenios]
    );
        console.log('agreement', agreement)
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography
                        variant='h1'
                        gutterBottom
                        style={{ marginBottom: '15px' }}
                    >
                        Convênios
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Grid xs={3}>
                        <FormControl
                            fullWidth
                            variant='outlined'
                            error={!!errors.nome}
                        >
                            <InputLabel id='Convenios.nome'>
                                Convênio
                            </InputLabel>
                            <Select
                                name='Convenios.nome'
                                label='Convênio'
                                value={agreement.nome}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        nome: event.target.value,
                                    });
                                }}
                            >
                                <MenuItem value={'Hapvida'}>Hapvida</MenuItem>
                                <MenuItem value={'Unimed'}>Unimed</MenuItem>
                                {/* adicionar ou remover opçoes conforme solicitado */}
                            </Select>
                            {errors.nome && (
                                <FormHelperText sx={{ color: 'red' }}>
                                    {errors.nome}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel id='demo-simple-select-label'>
                                Abrangencia
                            </InputLabel>
                            <Select
                                name='Convenios.abrangencia'
                                label='Abrangencia'
                                value={agreement.abrangencia}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        abrangencia: event.target.value,
                                    });
                                }}
                            >
                                <MenuItem value={'Parcial'}>Parcial</MenuItem>
                                <MenuItem value={'Total'}>Total</MenuItem>
                                {/* adicionar ou remover opçoes conforme solicitado */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl>
                            <FormLabel id='Convenios.acomodacao'>
                                Acomodação
                            </FormLabel>
                            <RadioGroup
                                row
                                name='Convenios.acomodacao'
                                value={agreement.acomodacao}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        acomodacao: event.target.value,
                                    });
                                }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='SIM'
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='NÃO'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl>
                            <FormLabel id='Convenios.intercambio'>
                                Intercambio
                            </FormLabel>
                            <RadioGroup
                                row
                                name='Convenios.intercambio'
                                value={agreement.intercambio}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        intercambio: event.target.value,
                                    });
                                }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='SIM'
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='NÃO'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid xs={2}>
                        <TextField
                            fullWidth
                            name='Convenios.numero_convenio'
                            label='Nro Cartão'
                            value={agreement.numero_convenio}
                            onChange={(event) => {
                                setAgreement({
                                    ...agreement,
                                    numero_convenio: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel id='Convenios.rede_atendimento'>
                                Rede Atendimento
                            </InputLabel>
                            <Select
                                name='Convenios.rede_atendimento'
                                label='Rede Atendimento'
                                value={agreement.rede_atendimento}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        rede_atendimento: event.target.value,
                                    });
                                }}
                            >
                                <MenuItem value={'Rede1'}>Rede1</MenuItem>
                                <MenuItem value={'Rede2'}>Rede2</MenuItem>
                                {/* adicionar ou remover opçoes conforme solicitado */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl>
                            <FormLabel id='Convenios.cobertura'>
                                Cobertura Parcial/Temporaria
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='Convenios.cobertura'
                                value={agreement.cobertura}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        cobertura: event.target.value,
                                    });
                                }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='SIM'
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='NÃO'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl>
                            <FormLabel id='Convenios.regulamentacao'>
                                Regulamentado
                            </FormLabel>
                            <RadioGroup
                                row
                                name='Convenios.regulamentacao'
                                value={agreement.regulamentacao}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        regulamentacao: event.target.value,
                                    });
                                }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='SIM'
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='NÃO'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid xs={2}>
                        <TextField
                            fullWidth
                            label="Validade"
                            name="Convenios.validade"
                            value={agreement.validade}
                            onChange={(event) => {
                                setAgreement({
                                    ...agreement,
                                    validade: event.target.value,
                                });
                            }}
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <br />
                    <Grid xs={2}>
                        <TextField
                            fullWidth
                            name='Convenios.plano_regulamentado'
                            label='Plano Regulamentado'
                            value={agreement.plano_regulamentado}
                            onChange={(event) => {
                                setAgreement({
                                    ...agreement,
                                    plano_regulamentado: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <br />
                    <Grid xs={3}>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel id='demo-simple-select-label'>
                                Tipo Plano Convênio
                            </InputLabel>
                            <Select
                                name='Convenios.tipo_plano_convenio'
                                label='Tipo Plano Convênio'
                                value={agreement.tipo_plano_convenio}
                                onChange={(event) => {
                                    setAgreement({
                                        ...agreement,
                                        tipo_plano_convenio: event.target.value,
                                    });
                                }}
                            >
                                <MenuItem value={'plano1'}>plano1</MenuItem>
                                <MenuItem value={'plano2'}>plano2</MenuItem>
                                {/* adicionar ou remover opçoes conforme solicitado */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid xs={2}>
                        <TextField
                            fullWidth
                            name='Convenios.codigo_seguranca'
                            label='Código de Segurança'
                            value={agreement.codigo_seguranca}
                            onChange={(event) => {
                                setAgreement({
                                    ...agreement,
                                    codigo_seguranca: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <br />
                    <Grid item xs={4}>
                        {id ? (
                            <Grid xs={6}>
                                <Button
                                    variant='contained'
                                    style={{ marginTop: '10px' }}
                                    disabled={allFieldsEmpty}
                                    onClick={() => {
                                        agreementSchema
                                            .validate(agreement, {
                                                abortEarly: false,
                                            })
                                            .then(() => {
                                                setErrors({});
                                                if (isEditMode) {
                                                    const agreementId =
                                                        agreement.id;

                                                    const editValues = {
                                                        ...agreement,
                                                        acomodacao:
                                                            agreement.acomodacao ===
                                                            'true'
                                                                ? true
                                                                : false,
                                                        cobertura:
                                                            agreement.cobertura ===
                                                            'true'
                                                                ? true
                                                                : false,
                                                        intercambio:
                                                            agreement.intercambio ===
                                                            'true'
                                                                ? true
                                                                : false,
                                                        regulamentacao:
                                                            agreement.regulamentacao ===
                                                            'true'
                                                                ? true
                                                                : false,
                                                    };

                                                    ApiService.put(
                                                        `/agreements/${agreementId}`,
                                                        editValues
                                                    )
                                                        .then((response) => {
                                                            console.log(
                                                                response.data
                                                            );
                                                            enqueueSnackbar(
                                                                'Convênio atualizado com sucesso!',
                                                                {
                                                                    variant:
                                                                        'success',
                                                                    
                                                                }
                                                            );
                                                            const updatedTodos =
                                                                values.Convenios.map(
                                                                    (todo) => {
                                                                        if (
                                                                            todo.id ===
                                                                            response
                                                                                .data
                                                                                .id
                                                                        ) {
                                                                            return response.data; // Retorna o convenio atualizado
                                                                        }
                                                                        return todo;
                                                                    }
                                                                );
                                                            setFieldValue(
                                                                'Convenios',
                                                                updatedTodos
                                                            ); // Atualiza a lista de convenios com o convenio atualizado
                                                            setIsEditMode(
                                                                false
                                                            ); // Sai do modo de edição
                                                            setAgreement({
                                                                nome: '',
                                                                numero_convenio:
                                                                    '',
                                                                validade: '',
                                                                acomodacao: false,
                                                                rede_atendimento:
                                                                    '',
                                                                plano_regulamentado:
                                                                    '',
                                                                abrangencia: '',
                                                                cobertura: false,
                                                                tipo_plano_convenio:
                                                                    '',
                                                                intercambio: false,
                                                                regulamentacao: false,
                                                                codigo_seguranca:
                                                                    '',
                                                            });
                                                        })
                                                        .catch((error) => {
                                                            const errorMessage =
                                                                error.response
                                                                    .data
                                                                    .message;
                                                            console.log(
                                                                errorMessage
                                                            );
                                                            if (
                                                                Array.isArray(
                                                                    errorMessage
                                                                )
                                                            ) {
                                                                enqueueSnackbar(
                                                                    `${errorMessage[0]}`,
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                        
                                                                    }
                                                                );
                                                            } else {
                                                                enqueueSnackbar(
                                                                    `${errorMessage}`,
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                        
                                                                    }
                                                                );
                                                            }
                                                        });
                                                } else {
                                                    const userAgreement = {
                                                        user_id: Number(id),
                                                        nome: agreement.nome,
                                                        numero_convenio:
                                                            agreement.numero_convenio,
                                                        validade:
                                                            agreement.validade,
                                                        acomodacao: Boolean(
                                                            agreement.acomodacao
                                                        ),
                                                        rede_atendimento:
                                                            agreement.rede_atendimento,
                                                        plano_regulamentado:
                                                            agreement.plano_regulamentado,
                                                        abrangencia:
                                                            agreement.abrangencia,
                                                        cobertura: Boolean(
                                                            agreement.cobertura
                                                        ),

                                                        tipo_plano_convenio:
                                                            agreement.tipo_plano_convenio,
                                                        intercambio: Boolean(
                                                            agreement.intercambio
                                                        ),
                                                        regulamentacao: Boolean(
                                                            agreement.regulamentacao
                                                        ),
                                                        codigo_seguranca:
                                                            agreement.codigo_seguranca,
                                                    };

                                                    ApiService.post(
                                                        `/agreements`,
                                                        userAgreement
                                                    )
                                                        .then((response) => {
                                                            const newAgreement =
                                                                [
                                                                    ...values.Convenios,
                                                                    response.data,
                                                                ];
                                                            enqueueSnackbar(
                                                                'Convênio criado com sucesso!',
                                                                {
                                                                    variant:
                                                                        'success',
                                                                    
                                                                }
                                                            );
                                                            setFieldValue(
                                                                'Convenios',
                                                                newAgreement
                                                            );
                                                            setAgreement({
                                                                nome: '',
                                                                numero_convenio:
                                                                    '',
                                                                validade: '',
                                                                acomodacao: false,
                                                                rede_atendimento:
                                                                    '',
                                                                plano_regulamentado:
                                                                    '',
                                                                abrangencia: '',
                                                                cobertura: false,
                                                                tipo_plano_convenio:
                                                                    '',
                                                                intercambio: false,
                                                                regulamentacao: false,
                                                                codigo_seguranca:
                                                                    '',
                                                            });
                                                        })
                                                        .catch((error) => {
                                                            const errorMessage =
                                                                error.response
                                                                    .data
                                                                    .message;
                                                            console.log(
                                                                errorMessage
                                                            );
                                                            if (
                                                                Array.isArray(
                                                                    errorMessage
                                                                )
                                                            ) {
                                                                enqueueSnackbar(
                                                                    `${errorMessage[0]}`,
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                        
                                                                    }
                                                                );
                                                            } else {
                                                                enqueueSnackbar(
                                                                    `${errorMessage}`,
                                                                    {
                                                                        variant:
                                                                            'error',
                                                                        
                                                                    }
                                                                );
                                                            }
                                                        });
                                                }
                                            })
                                            .catch((validationErrors) => {
                                                validationErrors.inner.forEach(
                                                    (error) => {
                                                        enqueueSnackbar(
                                                            error.message,
                                                            {
                                                                variant:
                                                                    'error',
                                                                
                                                            }
                                                        );
                                                    }
                                                );
                                                const errors = {};
                                                validationErrors.inner.forEach(
                                                    (error) => {
                                                        errors[error.path] =
                                                            error.message;
                                                    }
                                                );
                                                console.log(errors);
                                                setErrors(errors);
                                            });
                                    }}
                                >
                                    {isEditMode
                                        ? 'Editar Convênio'
                                        : 'Incluir Convênio'}
                                </Button>
                            </Grid>
                        ) : undefined}
                    </Grid>
                </Grid>
                {id ? (
                    <Box sx={{ overflowX: 'auto' }}>
                        <Grid item xs={12}>
                            <FieldArray name='Convenios'>
                                {({ remove, push }) =>
                                    values?.Convenios?.map((agremeent) => (
                                        <AgreementItem
                                            /*  key={agreement.id} */
                                            agreement={agremeent}
                                            handleEditAgreement={
                                                handleEditAgreement
                                            }
                                            modalAlert={modalAlert}
                                        />
                                    ))
                                }
                            </FieldArray>
                        </Grid>
                    </Box>
                ) : undefined}
            </Grid>
        </div>
    );
}

export default Agreements;
