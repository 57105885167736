import React, { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import {
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { PermissionContext } from "app/contexts/PermissionContext";

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { setAuthToken } = useJumboAuth();
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");
    const id = localStorage.getItem("id");
    const avatarUrlLocalStorage = localStorage.getItem("avatarUrl");
    const { hasPermission } = useContext(PermissionContext);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onLogout = () => {
        setAuthToken(null);
        navigate("/login");
    };

    const handleEditClick = () => {
        // Recolhe o dropdown
        setAnchorEl(null);

        // Redireciona para a página de edição de perfil
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate(`/people/edit/${id}`);
    };

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={`${process.env.REACT_APP_API_KEY}/public/uploads/${avatarUrlLocalStorage}`}
                        // src={authUser.profile_pic}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: "pointer" }}
                    />
                }
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
            >
                <Div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        p: (theme) => theme.spacing(2.5),
                    }}
                >
                    <Avatar
                        src={`${process.env.REACT_APP_API_KEY}/public/uploads/${avatarUrlLocalStorage}`}
                        alt={authUser.name}
                        sx={{ width: 60, height: 60, mb: 2 }}
                    />
                    <Typography sx={{ textAlign: "center" }} variant={"h5"}>
                        {username}
                    </Typography>
                    <Typography
                        sx={{ textAlign: "center" }}
                        variant={"body1"}
                        color="text.secondary"
                    >
                        {email}
                    </Typography>
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {
                            hasPermission('Pessoas', 'update') && (
                                <ListItemButton onClick={handleEditClick}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <PersonOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Editar Usuário"
                                        sx={{ my: 0 }}
                                    />
                                </ListItemButton>
                            )
                        }
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sair" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
