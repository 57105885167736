import {
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

const RESPOSTAS_FORMATADAS = {
  hospital: "Hospital",
  casa: "Casa",
  com_medico: "Com médico",
  com_parteira: "Com parteira",
  forceps: "Forcéps",
  cesareo: "Cesáreo",
  geral: "Geral",
  raquidiana: "Raquidiana",
  peridural: "Peridural",
  nenhumina: "Nenhuma",
  cabeca: "Cabeça",
  face: "Face",
  maos: "Mãos",
  pes: "Pés",
  nadegas: "Nádegas",
  outra: "Outra",
  calmo: "Calmo",
  sua_quando_dorme: "Sua quando dorme",
  sonambulismo: "Sonambulismo",
  agitado: "Agitado",
  fala_dormindo: "Fala dormindo",
  range_os_dentes: "Range os dentes",
  baba_quando_dorme: "Baba quando dorme",
  ficou_feliz: "Ficou feliz",
  ficou_surpresa: "Ficou supresa",
  ficou_triste: "Ficou triste",
  ficou_com_medo: "Ficou com medo",
  corrigia: "Corrigia",
  achava_bonito: "Achava bonito",
  achava_engracado: "Achava engraçado",
  sozinha: "Sozinha",
  acompanhada: "Acompanhada",
  retraido: "Retraído",
  extrovertido: "Extrovertido",
  em_grupos: "Em grupos",
  isolada: "Isolada",
  sim: "Sim",
  nao: "Não",
  normal: "Normal",
  exagerada: "Exagerada",
  travada: "Travada",
  imprecisa: "Imprecisa",
  lento: "Lento",
  realiza: "Realiza",
  nao_realiza: "Não realiza",
  inclinada: "Inclinada",
  anteriorizada: "Anteriorizada",
  nasal: "Nasal",
  silenciosa: "Silenciosa",
  oral: "Oral",
  ruidosa: "Ruidosa",
  buconasal: "Buconasal",
  superior: "Superior",
  médio: "Médio",
  inferior: "Inferior",
  alterada: "Alterada",
  uso_ar_reserva: "Uso de ar reserva",
  D: "D",
  E: "E",
  inteligivel: "Inteligível",
  nao_apresenta: "Nãoa apresenta",
  moderada: "Moderada",
  grave: "Grave",
  preciso: "Preciso",
  travado: "Travado",
  exagerado: "Exagerado",
  brusco: "Brusco",
  aspirado: "Aspirado",
  uso_alternado: "Uso alternado",
  fala_alto: "Fala alto",
  fala_em_volume_normal_alterna: "Fala em volume normal/alterna",
  fala_baixo: "Fala baixo",
  adequada: "Adequada",
  limitada: "Limitada",
  "0% - ausência de gagueira": "0% - ausência de gagueira",
  "2% - gagueira ligeira": "2% - gagueira ligeira",
  "2-5% - gagueira suave": "2-5% - gagueira suave",
  "5-8% - gagueira regular": "5-8% - gagueira regular",
  "8-12% - gagueira severa": "8-12% - gagueira severa",
  "+25% - gagueira grave": "+25% - gagueira grave",
  canhoto: "Canhoto",
  destro: "Destro",
  ambos: "Ambos",
  paciente_verbal: "Paciente verbal",
  paciente_nao_verbal: "Paciente não verbal",
  "0": 0,
  "1": 1,
  nunca: "Nunca",
  raramente: "Raramente",
  as_vezes: "As vezes",
  frequentemente: "Frequentemente",
  pre_silabico: "Pré-silábico",
  silabico_sem_valor_sonoro: "Silábico sem valor sonoro",
  silabico_com_valor_sonoro: "Silábico com valor sonoro",
  silabico_alfabetico: "Silábico alfabético",
  alfabetico: "Alfabético"
};

const RESPOSTAS_FORMATADAS_VINELAND = {
  "2": "2 - Sim, normalmente",
  "1": "1 - Algumas vezes, ou parcialmente",
  "0": "0 - Não, nunca",
  "N": "N - Não teve oportunidade",
  "D": "D - Desconhecido"
}

const AnamnesisQuestion = ({
  idPergunta,
  nome,
  respostas,
  answers,
  setAnswers,
  isFormSubmitted,
  isEditMode,
  anamnesisSelected
}) => {
  const { evaluationId } = useParams();
  
  const handleAnswerChange = useCallback(
    (event) => {
      const respostaSelecionada = event.target.value;
      const pergunta_id = idPergunta;
      const objetoResposta = {
        id: answers?.get(idPergunta)?.id,
        pergunta_id: pergunta_id,
        observacao: respostaSelecionada,
        data_horario_resposta: answers?.values()?.next().value?.data_horario_resposta
      };

      setAnswers(new Map(answers).set(pergunta_id, objetoResposta));
    },
    [answers, idPergunta, setAnswers, isEditMode]
  );

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12} my={2}>
        <FormLabel>{nome}</FormLabel>
        {respostas?.length > 0 ? (
          <Select
            fullWidth
            value={
              answers?.get(idPergunta)?.observacao || ''
            }
            onChange={handleAnswerChange}
          >
            <MenuItem value="">Selecionar</MenuItem>
            {respostas?.map((respostaPergunta) => (
              <MenuItem value={respostaPergunta?.resposta}>
                {
                  evaluationId == 69 
                  ?
                  RESPOSTAS_FORMATADAS_VINELAND[respostaPergunta.resposta]
                  :
                  RESPOSTAS_FORMATADAS[respostaPergunta.resposta] 
                  || 
                  <Typography variant="inherit" noWrap>
                    {respostaPergunta?.resposta}
                  </Typography>
                }
                
              </MenuItem>
            ))}
          </Select>
        ) : String(nome).split("?").length <= 2 &&
          !String(nome).endsWith(":") &&
          !String(nome).endsWith(")") ? (
          <>
            {/* Esse trecho do código é para perguntas com respostas 'SIM' e 'NÃO' */}
            {/* Daí, verífico se a pergunta é uma só pelo tamanho do array dividido pelo separador '?' */}
            <Select
              fullWidth
              value={
                answers?.get(idPergunta)?.observacao || ''
              }
              onChange={handleAnswerChange}
            >
              <MenuItem value="">Selecionar</MenuItem>
              <MenuItem value={"sim"}>Sim</MenuItem>
              <MenuItem value={"nao"}>Não</MenuItem>
            </Select>
          </>
        ) : (
          <TextField
            placeholder={"Preencha as informações necessárias"}
            fullWidth
            multiline
            minRows={3}
            value={
              answers?.get(idPergunta)?.observacao
            }
            onChange={handleAnswerChange}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AnamnesisQuestion;
