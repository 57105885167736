import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useSnackbar } from 'notistack';
import ApiService from 'app/services/config';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import SendIcon from '@mui/icons-material/Send';
import { FieldArray } from 'formik';
import { AvaliationItem } from 'app/components/AvaliationItem/AvaliationItem';

const Avaliation = ({
    values,
    infos,
    setFieldValue,
    avaliation,
    setAvaliation,
}) => {
    const { id, userId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const [isEditMode, setIsEditMode] = useState(false);
    const [avaliationSelected, setAvaliationSelected] = useState(null);

    const handleEditAvaliation = (avaliationToEdit) => {
        console.log(avaliationToEdit);
        setAvaliation(avaliationToEdit);
        setIsEditMode(true);
    };

    const modalAlert = (avaliationId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                removeAvaliation(avaliationId);
            }
        });
    };

    const removeAvaliation = useCallback(
        async (avaliationId) => {
            ApiService.delete(`/assessments/${avaliationId}`)
                .then((response) => {
                    const avaliationEdit = avaliation.id === avaliationId;
                    const removedAvaliation = values.Avaliacao.filter(
                        (item) => item.id !== avaliationId
                    );
                    setFieldValue('Avaliacao', removedAvaliation);
                    enqueueSnackbar('Avaliação removida com sucesso!', {
                        variant: 'success',
                    });
                    setAvaliationSelected(null);
                    if (avaliationEdit) {
                        setIsEditMode(false);
                        setAvaliation({
                            // Limpa o estado do avaliacao
                            linguagem_oral: '',
                            fluencia: '',
                            conhecimentos_previos: '',
                            linguagem_escrita: '',
                            aspectos_congnitivos: '',
                            reconhece_em_sequencia_alfabeto: false,
                            reconhece_em_aleatorio_alfabeto: false,
                            detalhes_de_reconhece_alfabeto: '',
                            reconhece_vogais: '',
                            reconhece_numeros: '',
                            reconhece_quantidades: '',
                            reconhece_em_sequencia_numeros: '',
                            reconhece_formas_geometricas: '',
                            reconhece_grandezas: '',
                            reconhece_cores: '',
                            reconhece_escrita: '',
                            nivel_de_escrita: '',
                            escrita_de_frases: '',
                            leitura: '',
                            observacoes: '',
                            data: '',
                        });
                        setAvaliationSelected(null);
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                        });
                    }
                });
        },
        [
            enqueueSnackbar,
            setFieldValue,
            values.Avaliacao,
            avaliation,
            setAvaliation,
        ]
    );

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
                <AvaliationItem
                    avaliation={values.Avaliacao}
                    handleEditAvaliation={handleEditAvaliation}
                    modalAlert={modalAlert}
                    avaliationSelected={avaliationSelected}
                    setAvaliationSelected={setAvaliationSelected}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='standard-basic'
                    label='Data'
                    variant='outlined'
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    value={avaliation?.data}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            data: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Linguagem Oral'
                    multiline
                    rows={2}
                    value={avaliation?.linguagem_oral}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            linguagem_oral: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Fluência'
                    multiline
                    rows={2}
                    value={avaliation?.fluencia}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            fluencia: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Conhecimentos Prévios'
                    multiline
                    rows={2}
                    value={avaliation?.conhecimentos_previos}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            conhecimentos_previos: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Linguagem Escrita'
                    multiline
                    rows={2}
                    value={avaliation?.linguagem_escrita}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            linguagem_escrita: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label='Aspectos Cognitivos'
                    multiline
                    rows={2}
                    value={avaliation?.aspectos_congnitivos}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            aspectos_congnitivos: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider textAlign='left'>
                    Reconhecimento das letras do alfabeto
                </Divider>
            </Grid>

            <Grid item xs={3}>
                <FormControl>
                    <FormLabel id='demo-row-radio-buttons-group-label'>
                        Reconhece em sequencia
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={avaliation?.reconhece_em_sequencia_alfabeto}
                        onChange={(event) => {
                            setAvaliation({
                                ...avaliation,
                                reconhece_em_sequencia_alfabeto: JSON.parse(
                                    event.target.value
                                ),
                            });
                        }}
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl>
                    <FormLabel id='demo-row-radio-buttons-group-label'>
                        Reconhece em forma aleatória?
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={avaliation?.reconhece_em_aleatorio_alfabeto}
                        onChange={(event) => {
                            setAvaliation({
                                ...avaliation,
                                reconhece_em_aleatorio_alfabeto: JSON.parse(
                                    event.target.value
                                ),
                            });
                        }}
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                {/* espacinho ;P */}
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Mais detalhes das duas alternativas acima'
                    multiline
                    rows={2}
                    value={avaliation?.detalhes_de_reconhece_alfabeto}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            detalhes_de_reconhece_alfabeto: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Reconhecimento das vogais?'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_vogais}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_vogais: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Reconhecimento dos numeros'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_numeros}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_numeros: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Quantidades'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_quantidades}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_quantidades: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Valor posicional dos numeros'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_em_sequencia_numeros}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_em_sequencia_numeros: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Formas geométricas'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_formas_geometricas}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_formas_geometricas: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Grandezas (maior e menor, alto e baixo, leve e pesado.)'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_grandezas}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_grandezas: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Cores (identificação, pareamento de cores)'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_cores}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_cores: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label='Escrita: Ditado de palavras do mesmo campo semantico'
                    multiline
                    rows={2}
                    value={avaliation?.reconhece_escrita}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            reconhece_escrita: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl>
                    <FormLabel id='demo-row-radio-buttons-group-label'>
                        Niveis de escrita
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={avaliation?.nivel_de_escrita}
                        onChange={(event) => {
                            setAvaliation({
                                ...avaliation,
                                nivel_de_escrita: event.target.value,
                            });
                        }}
                    >
                        <FormControlLabel
                            value='pre_silabico'
                            control={<Radio />}
                            label='Pre-silabico'
                        />
                        <FormControlLabel
                            value='silabico_sem_valor_sonoro'
                            control={<Radio />}
                            label='Silábico sem valor sonoro'
                        />
                        <FormControlLabel
                            value='silabico_com_valor_sonoro'
                            control={<Radio />}
                            label='Silabico com valor sonoro'
                        />
                        <FormControlLabel
                            value='silabico_alfabetico'
                            control={<Radio />}
                            label='Silábico alfabético'
                        />
                        <FormControlLabel
                            value='alfabetico'
                            control={<Radio />}
                            label='Alfabético'
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Escrita de frases'
                    multiline
                    rows={2}
                    value={avaliation?.escrita_de_frases}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            escrita_de_frases: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label='Leitura'
                    multiline
                    rows={2}
                    value={avaliation?.leitura}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            leitura: event.target.value,
                        });
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label='Observações Gerais'
                    multiline
                    rows={2}
                    value={avaliation?.observacoes}
                    onChange={(event) => {
                        setAvaliation({
                            ...avaliation,
                            observacoes: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant='outlined'
                    endIcon={<SendIcon />}
                    onClick={() => {
                        if (isEditMode) {
                            const avaliationId = avaliation.id;
                            console.log(avaliation);
                            const userAvaliationEdit = {
                                ...avaliation,
                            };
                            console.log(userAvaliationEdit);

                            ApiService.put(
                                `/assessments/${avaliationId}`,
                                userAvaliationEdit
                            )
                                .then((response) => {
                                    console.log(response.data);
                                    enqueueSnackbar(
                                        'Avaliação atualizada com sucesso!',
                                        {
                                            variant: 'success',
                                        }
                                    );
                                    const updatedTodos = values.Avaliacao.map(
                                        (todo) => {
                                            if (todo.id === response.data.id) {
                                                return response.data; // Retorna a avaliacao atualizado
                                            }
                                            return todo;
                                        }
                                    );
                                    setFieldValue('Avaliacao', updatedTodos); // Atualiza a lista de avaliacao com o endereço atualizado
                                    setIsEditMode(false); // Sai do modo de edição
                                    setAvaliation({
                                        // Limpa o estado do avaliacao
                                        linguagem_oral: '',
                                        fluencia: '',
                                        conhecimentos_previos: '',
                                        linguagem_escrita: '',
                                        aspectos_congnitivos: '',
                                        reconhece_em_sequencia_alfabeto: false,
                                        reconhece_em_aleatorio_alfabeto: false,
                                        detalhes_de_reconhece_alfabeto: '',
                                        reconhece_vogais: '',
                                        reconhece_numeros: '',
                                        reconhece_quantidades: '',
                                        reconhece_em_sequencia_numeros: '',
                                        reconhece_formas_geometricas: '',
                                        reconhece_grandezas: '',
                                        reconhece_cores: '',
                                        reconhece_escrita: '',
                                        nivel_de_escrita: '',
                                        escrita_de_frases: '',
                                        leitura: '',
                                        observacoes: '',
                                        data: '',
                                    });
                                    setAvaliationSelected(null);
                                })
                                .catch((error) => {
                                    const errorMessage =
                                        error.response.data.message;
                                    console.log(errorMessage);
                                    if (Array.isArray(errorMessage)) {
                                        enqueueSnackbar(`${errorMessage[0]}`, {
                                            variant: 'error',
                                        });
                                    } else {
                                        enqueueSnackbar(`${errorMessage}`, {
                                            variant: 'error',
                                        });
                                    }
                                });
                        } else {
                            const userAvaliation = {
                                user_id: Number(userId),
                                linguagem_oral: avaliation.linguagem_oral,
                                fluencia: avaliation.fluencia,
                                conhecimentos_previos:
                                    avaliation.conhecimentos_previos,
                                linguagem_escrita: avaliation.linguagem_escrita,
                                aspectos_congnitivos:
                                    avaliation.aspectos_congnitivos,
                                reconhece_em_sequencia_alfabeto:
                                    avaliation.reconhece_em_sequencia_alfabeto,
                                reconhece_em_aleatorio_alfabeto:
                                    avaliation.reconhece_em_aleatorio_alfabeto,
                                detalhes_de_reconhece_alfabeto:
                                    avaliation.detalhes_de_reconhece_alfabeto,
                                reconhece_vogais: avaliation.reconhece_vogais,
                                reconhece_numeros: avaliation.reconhece_numeros,
                                reconhece_quantidades:
                                    avaliation.reconhece_quantidades,
                                reconhece_em_sequencia_numeros:
                                    avaliation.reconhece_em_sequencia_numeros,
                                reconhece_formas_geometricas:
                                    avaliation.reconhece_formas_geometricas,
                                reconhece_grandezas:
                                    avaliation.reconhece_grandezas,
                                reconhece_cores: avaliation.reconhece_cores,
                                reconhece_escrita: avaliation.reconhece_escrita,
                                nivel_de_escrita: avaliation.nivel_de_escrita,
                                escrita_de_frases: avaliation.escrita_de_frases,
                                leitura: avaliation.leitura,
                                observacoes: avaliation.observacoes,
                                data: avaliation.data,
                            };
                            console.log(userAvaliation);

                            ApiService.post(`/assessments`, userAvaliation)
                                .then((response) => {
                                    console.log(response);
                                    const newAvaliation = [
                                        ...values.Avaliacao,
                                        response.data,
                                    ];
                                    enqueueSnackbar(
                                        'Avaliação criada com sucesso!',
                                        {
                                            variant: 'success',
                                        }
                                    );
                                    setFieldValue('Avaliacao', newAvaliation);
                                    setAvaliation({
                                        // Limpa o estado do avaliacao
                                        linguagem_oral: '',
                                        fluencia: '',
                                        conhecimentos_previos: '',
                                        linguagem_escrita: '',
                                        aspectos_congnitivos: '',
                                        reconhece_em_sequencia_alfabeto: false,
                                        reconhece_em_aleatorio_alfabeto: false,
                                        detalhes_de_reconhece_alfabeto: '',
                                        reconhece_vogais: '',
                                        reconhece_numeros: '',
                                        reconhece_quantidades: '',
                                        reconhece_em_sequencia_numeros: '',
                                        reconhece_formas_geometricas: '',
                                        reconhece_grandezas: '',
                                        reconhece_cores: '',
                                        reconhece_escrita: '',
                                        nivel_de_escrita: '',
                                        escrita_de_frases: '',
                                        leitura: '',
                                        observacoes: '',
                                        data: '',
                                    });
                                })
                                .catch((error) => {
                                    const errorMessage =
                                        error.response.data.message;
                                    console.log(errorMessage);
                                    if (Array.isArray(errorMessage)) {
                                        enqueueSnackbar(`${errorMessage[0]}`, {
                                            variant: 'error',
                                        });
                                    } else {
                                        enqueueSnackbar(`${errorMessage}`, {
                                            variant: 'error',
                                        });
                                    }
                                });
                        }
                    }}
                >
                    {isEditMode ? 'Salvar' : 'Salvar'}
                </Button>
            </Grid>

            {/* <Grid item xs={12}>
                <Divider />
            </Grid> */}

            {/* <Grid item xs={12} md={3}>
                <Typography variant='h4' gutterBottom>
                    Código
                </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant='h4' gutterBottom>
                    Data de Inclusão
                </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant='h4' gutterBottom>
                    Colaborador de inclusão
                </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant='h4' gutterBottom>
                    Paciente
                </Typography>
            </Grid> */}
        </Grid>
    );
};

export default Avaliation;
