import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Divider,
  FormHelperText,
  Stack,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import ApiService from "app/services/config";
import * as yup from "yup";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import socket from "app/services/websockets";

const validationSchema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório"),
  users: yup
    .array()
    .required("Campo obrigatório")
    .min(2, "Selecione pelo menos 1 prestador"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ChatCreateRoomModal = ({
  isModalCreateRoomOpen,
  setIsModalCreateRoomOpen,
}) => {
  const USER_LOGGED_ID = Number(localStorage.getItem('id'));
  const { userId } = useParams();
  const initialValues = {
    nome: "",
    users: [],
    responsaveis: [],
  };
  const [optionsList, setOptionsList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userSelected, setUserSelected] = useState(null);

  const [loadingResponsavel, setLoadingResponsavel] = useState(false);
  const [optionsResponsavelList, setOptionsResponsavelList] = useState([]);
  const [userResponsavelInput, setUserResponsavelInput] = useState("");
  const [userResponsavelSelected, setUserResponsavelSelected] = useState(null);

  const websocket = socket;

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/prestadores?searchTerm=${inputValue}`
      );
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersResponsaveis = async (inputValue) => {
    setLoadingResponsavel(true);
    try {
      const response = await ApiService.get(
        `/users/all/responsaveis?searchTerm=${userResponsavelInput}&pacienteId=${Number(userId)}`
      );
      const users = response.data;
      setOptionsResponsavelList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingResponsavel(false);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleInputResponsavelChange = (event, newInputValue) => {
    setUserResponsavelInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsersResponsaveis(newInputValue);
    } else {
      setOptionsResponsavelList([]);
      setUserResponsavelSelected(null);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const roomUsers = [...values.users, ...values.responsaveis, {id: Number(userId)}];
      const valuesEdit = {
        ...values,
        users: roomUsers
      }
      if(roomUsers.length === 1 && roomUsers[0].id === Number(userId)) {
        enqueueSnackbar("Selecione um prestador!", {
          variant: "error",
        })
      }
      if(values?.responsaveis?.length === 0) {
        enqueueSnackbar("Selecione um responsável!", {
          variant: "error",
        });
        return;
      }
      delete valuesEdit.responsaveis;
      await ApiService.post('/chatroom', valuesEdit).then((response) => {
        if(response.status === 201) {
          setIsModalCreateRoomOpen();
          enqueueSnackbar("Sala criada com sucesso!", {
            variant: "success",
          });
          const {id, nome, users} = response.data;
          websocket.emit('newRoom', {
            id,
            nome,
            users
          })
        }
      });
    } catch (error) { 
      enqueueSnackbar("Ocorreu um erro ao criar a sala!", {
        variant: "error",
      });   
      console.error(error);
    }
  };
  return (
    <Modal
      open={isModalCreateRoomOpen}
      onClose={setIsModalCreateRoomOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Div sx={style}>
        <Typography id="modal-modal-title" variant="h2" component="h2">
          Criar nova sala
        </Typography>
        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            isSubmitting,
            handleBlur,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form style={{ width: "100%" }}>
              <Box my={2}>
                <TextField
                  variant="outlined"
                  label="Nome da Sala"
                  name="nome"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nome}
                  fullWidth
                  required
                />
                <ErrorMessage
                  name="nome"
                  component={"div"}
                  style={{ color: "red" }}
                />
              </Box>
              <Box my={2}>
                <Tooltip
                  title="Digite o nome para buscar a pessoa no sistema"
                  TransitionComponent={Zoom}
                  placement="top-end"
                >
                  <FieldArray name="users">
                    {({ push, remove }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        getOptionLabel={(option) => option?.nome ?? null}
                        options={optionsList}
                        loading={loading}
                        inputValue={userInput}
                        onInputChange={handleInputChange}
                        name="users"
                        multiple
                        value={values.users}
                        onBlur={handleBlur}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            const actualUser = values?.users?.some(
                              (user) => user?.id === newValue[newValue.length - 1]?.id
                            );
                            if (actualUser) {
                              const filteredUsers = values?.users?.filter(
                                (user) => user?.id === newValue[newValue.length - 1].id
                              );
                              setFieldValue("users", filteredUsers);
                            } else if(!actualUser) {
                              setFieldValue("users", [...values.users, newValue[newValue.length - 1]]);
                            }
                          }
                        }}
                        noOptionsText={"Nenhum resultado encontrado"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Prestadores"
                            placeholder="Prestadores"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? "carregando..." : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                      />
                    )}
                  </FieldArray>
                  <FormHelperText>Se você faz parte da conversa, inclua o seu usuário</FormHelperText>
                  <ErrorMessage name="users" component={"div"} style={{ color: "red" }} />
                </Tooltip>
              </Box>
              <Box my={2}>
                <Tooltip
                  title="Digite o nome para buscar a pessoa no sistema"
                  TransitionComponent={Zoom}
                  placement="top-end"
                >
                  <FieldArray name="responsaveis">
                    {({ push, remove }) => (
                      <Autocomplete
                        fullWidth
                        disablePortal
                        getOptionLabel={(option) => option?.nome ?? null}
                        options={optionsResponsavelList}
                        loading={loadingResponsavel}
                        inputValue={userResponsavelInput}
                        onInputChange={handleInputResponsavelChange}
                        name="responsaveis"
                        multiple
                        value={values.responsaveis}
                        onBlur={handleBlur}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            const actualUser = values?.responsaveis?.some(
                              (user) => user?.id === newValue[newValue.length === 1 ? 0 : -1]?.id
                            );
                            if (actualUser) {
                              const filteredUsers = values?.responsaveis?.filter(
                                (user) => user?.id === newValue[newValue.length - 1].id
                              );
                              setFieldValue("responsaveis", filteredUsers);
                            } else if(!actualUser) {
                              setFieldValue("responsaveis", [...values.responsaveis, newValue[newValue.length - 1]]);
                            }
                          }
                        }}
                        noOptionsText={"Nenhum resultado encontrado"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Responsáveis"
                            placeholder="Responsáveis"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? "carregando..." : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                      />
                    )}
                  </FieldArray>
                  <ErrorMessage name="users" component={"div"} style={{ color: "red" }} />
                </Tooltip>
              </Box>
              <Stack direction={"row"} gap={2} my={2} justifyContent={"center"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Criar
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={setIsModalCreateRoomOpen}
                >
                  Fechar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Div>
    </Modal>
  );
};

export default ChatCreateRoomModal;
