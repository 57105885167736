import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Tooltip, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import WorkerColumn from "./WorkerColumn";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useDebouncedFetchUsers = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const ScheduleWorker = () => {
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [dateSelected, setDateSelected] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState([]); 
  const location = useLocation();

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue === "") {
      setOptionsList([]);
      setUserSelected(null);
    }
  };
  
  const debouncedInput = useDebouncedFetchUsers(userInput, 2000);

  React.useEffect(() => {
    if (debouncedInput) {
      fetchUsers(debouncedInput);
    }
  }, [debouncedInput]);

  const fetchUsers = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/prestadores?searchTerm=${inputValue}`
      );
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const isScheduleAvailable = (info, hour) => {
    
    const isWithinValidity = moment(dateSelected).isBetween(
      info.validade_inicial,
      info.validade_final,
      'day',
      '[]'
    );

    if (!isWithinValidity) {
      return false;
    }

    const availability = info?.Disponibilidade_Prestador.map(
      (disponibilidade) =>
        disponibilidade?.Disponibilidade_Prestador_Periodo?.some(
          (periodo) =>
            moment(dateSelected).isoWeekday().toString() ===
              disponibilidade.dia_semana &&
            periodo.horario_inicio <= hour &&
            periodo.horario_fim >= hour
        )
    );

    // Verifica se pelo menos um período está disponível
    const isAvailable = availability?.some((available) => available);

    return isAvailable;
  };

  const generatePDF = (users) => {
    const hours = [];
    for (let hour = 7; hour <= 19; hour++) {
      for (let minute of ['00', '30']) {
        hours.push(`${hour.toString().padStart(2, '0')}:${minute}`);
      }
    }

    const rows = users.map((worker) => {
      const workerHeader = { text: worker.nome, style: { fontSize: 18,
        bold: true,} };
      const workerRows = hours.map((hour, i) => {
        const schedule = worker?.Agendamento_Prestador?.find(
          (ag) => ag.horario === hour
        );
        const disponivel = worker?.Disponibilidade_Prestador_Infos?.some((info) =>
          isScheduleAvailable(info, hour)
        );

        let statusLabel = 'Não disponível';
        
        if (schedule) {
          statusLabel = schedule?.User?.nome;
        } else if (disponivel) {
          statusLabel = 'Disponível';
        }

        return [hour, statusLabel];
      });
      
      return [
        workerHeader,
        {
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [{ text: 'Horário', style: 'tableHeader' }, { text: 'Status', style: 'tableHeader' }],
              ...workerRows
            ]
          },
          layout: 'lightHorizontalLines',
          margin: [0, 10, 0, 10]
        }
      ];
    });

    const docDefinition = {
      content: [
        { text: `Agendas do dia: ${moment(dateSelected).format("DD-MM-YYYY")}`, style: 'header' },
        ...rows
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          marginBottom: 10
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        }
      }
    };

    pdfMake.createPdf(docDefinition).download(`agenda_horizontal_${moment(dateSelected).format('DD-MM-YYYY')}.pdf`);
    
  };

  const getUsers = React.useCallback(async (generate = undefined) => {
    try {
      const name = userSelected?.selected?.nome;
      const storedUserId = localStorage.getItem('id');

      const id = location.pathname == '/schedule-worker-logged' ? storedUserId : null;

      let apiUrl = `/users?worker=${name ?? "%"}&calendar=${dateSelected}&id=${id}&type=Prestador`;

      await ApiService.get(apiUrl)
        .then((response) => {
          setUsers(response.data?.usersWithoutPassword);
          console.log({generate})
          if(generate){
            generatePDF(response.data?.usersWithoutPassword)
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setUsers, userSelected?.selected?.nome, dateSelected, location, generatePDF]);

  React.useEffect(() => {
    getUsers();
  }, [location.pathname]);

  return (
    <>
      {/* <Typography variant='h1'>Agenda Prestador</Typography> */}
      <HeaderBreadcrumbs title={location.pathname == '/schedule-worker-logged' ? "Agenda Prestador" : "Agenda Horizontal"} />
      <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <span></span>
        <Button variant="contained" onClick={() => {
          getUsers(true)
        }}>
          Gerar PDF
        </Button>
      </Box>
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          maxHeight: "650px",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Box display={"flex"} alignItems={"center"} gap={2}>
          {location.pathname == '/schedule-worker' && (
            <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
              <Autocomplete
                fullWidth
                disablePortal
                getOptionLabel={(option) => option.nome}
                options={optionsList}
                loading={loading}
                inputValue={userInput}
                onInputChange={handleInputChange}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setUserSelected((prevValues) => ({
                      selected: newValue,
                    }));
                  }
                }}
                noOptionsText={"Nenhum resultado encontrado"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prestadores"
                    placeholder="Prestadores"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? "carregando..." : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </Tooltip>
          )}

          <TextField
            fullWidth
            label="Calendário"
            variant="outlined"
            type="date"
            InputLabelProps={{ shrink: true }}
            sx={{ width: "15%" }}
            value={dateSelected}
            onChange={(e) => setDateSelected(e.target.value)}
          />

          <Button variant="contained" onClick={() => getUsers()}>
            <SearchIcon />
          </Button>
        </Box>
      </Paper>

      <Paper
        style={{
          padding: "0 10px 0 0",
          marginBottom: "15px",
          overflowX: "auto",
          maxHeight: "650px",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Box display={"flex"}>
          {users?.map((worker, index) => (
            <Box display={"flex"} flexDirection={"column"}>
              <Paper
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
                elevation={3}
              >
                <Typography
                  noWrap
                  variant="h3"
                  padding={2}
                  sx={{ textAlign: "center" }}
                >
                  {worker.nome}
                </Typography>
              </Paper>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: "16px",
                }}
              >
                <WorkerColumn worker={worker} dateSelected={dateSelected} workerId={worker?.id} />
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
    </>
  );
};

export default ScheduleWorker;
