import React, { useContext, useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import ChatGlobalSearch from "../ChatGlobalSearch";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ChatIcon from "@mui/icons-material/Chat";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ChatCreateRoomModal from "../ChatCreateRoomModal/ChatCreateRoomModal";
import socket from "app/services/websockets";
import { PermissionContext } from "app/contexts/PermissionContext";

const ChatAppSidebar = ({ selectedRoom, onRoomSelect, getChatRoomsForCurrentUser, chatRoomList }) => {

  const { hasPermission } = useContext(PermissionContext);

  const USER_LOGGED_ID = Number(localStorage.getItem("id"));
 
  const [isModalCreateRoomOpen, setIsModalCreateRoomOpen] = useState(false);

  const handleOpenModalCreateRoom = () => {
    setIsModalCreateRoomOpen(!isModalCreateRoomOpen);
  };

  const [activeTab, setActiveTab] = React.useState("salas");

  const websocket = socket;

  useEffect(() => {

    websocket.on("connect", () => {
      console.log("Websocket conectado!");
    });

    websocket.on("disconnect", () => {
      console.log("Desconectado do servidor de WebSocket");
    });

    websocket.on('message', (message) => {
      if(message?.users_ids?.some(userId => userId === USER_LOGGED_ID)) {
        getChatRoomsForCurrentUser();
      }
    });

    websocket.on('createdRoom', (room) => {
      if(room?.users?.some(user => user.id === USER_LOGGED_ID)) {
        getChatRoomsForCurrentUser();
      }
    });

    websocket.on('roomRemoved', (room) => {
      if(room?.users?.some(user => user.id === USER_LOGGED_ID)) {
        getChatRoomsForCurrentUser();
      }
    });

  }, [websocket]);

  useEffect(() => {
    getChatRoomsForCurrentUser();
  }, [isModalCreateRoomOpen, selectedRoom]);
  return (
    <React.Fragment>
      <TabContext value={activeTab}>
        <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="fullWidth"
            onChange={(event, newTab) => setActiveTab(newTab)}
          >
            <Tab
              label="Salas"
              value={"salas"}
              icon={<ChatIcon />}
              iconPosition="start"
            />
          </TabList>
        </Div>
        <JumboScrollbar
          style={{ minHeight: 200 }}
          autoHide
          autoHideDuration={200}
          autoHideTimeout={500}
          autoHeightMin={30}
        >
          {chatRoomList && chatRoomList.length > 0 ? (
            chatRoomList.map((sala) => (
              <ListItem button key={sala.id} onClick={() => onRoomSelect(sala)}>
                <ListItemText primary={sala.nome} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary={"Nenhuma sala criada."} />
            </ListItem>
          )}
        </JumboScrollbar>
        {
          !hasPermission('Paciente Evolução', 'create') ? undefined : (
            <Button
              fullwidth
              variant="outlined"
              color="primary"
              startIcon={<AddCommentIcon />}
              onClick={() => {
                handleOpenModalCreateRoom();
              }}
            >
              Nova Sala
            </Button>
          )
        }
        <ChatCreateRoomModal
          isModalCreateRoomOpen={isModalCreateRoomOpen}
          setIsModalCreateRoomOpen={handleOpenModalCreateRoom}
        />
      </TabContext>
    </React.Fragment>
  );
};

export default ChatAppSidebar;
