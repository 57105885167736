import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import PatientsList from "./PatientsList";
import PeriodModelForm from "./PeriodModelForm";

function Details({ prestadorId }) {


    return (
        <div>
            <Grid container>
                <Typography
                    variant='h1'
                    gutterBottom
                    style={{ marginBottom: '15px' }}
                >
                    Detalhes
                </Typography>
            </Grid>

            <Accordion
                // expanded={expanded === 'panel1'}
                // onChange={handleAccordionChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls='panel1-content'
                    id='panel1-header'
                >
                    <Typography>Horário padrão</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <PeriodModelForm prestadorId={prestadorId}/>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion
                // expanded={expanded === 'panel1'}
                // onChange={handleAccordionChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls='panel1-content'
                    id='panel1-header'
                >
                    <Typography>Pacientes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <PatientsList prestadorId={prestadorId}/>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default Details;
