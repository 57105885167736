import Chip from "@mui/material/Chip";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import HistoryIcon from "@mui/icons-material/History";
import Badge from "@mui/material/Badge";
import DataTable from "../DataTable";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Grid, Zoom } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Tooltip from "@mui/material/Tooltip";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ApiService from "app/services/config";
import { useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { setMonth } from "date-fns";
import { calculateAge, formatDate } from "@jumbo/utils";
import { useSnackbar } from "notistack";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import moment from "moment";

const AtendimentosGerais = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [prestadorSelected, setPrestadorSelected] = useState(null);
  const [pacienteSelected, setPacienteSelected] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [userPrestadorInput, setUserPrestadorInput] = useState("");
  const [userPacienteInput, setUserPacienteInput] = useState("");
  const [optionsPrestadoresList, setOptionsPrestadoresList] = useState([]);
  const [optionsPacientesList, setOptionsPacientesList] = useState([]);
  const [loadingPrestadores, setLoadingPrestadores] = useState(false);
  const [loadingPacientes, setLoadingPacientes] = useState(false);

  const [atendimentos, setAtendimentos] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");

  const [clearFiltersKey, setClearFiltersKey] = useState(false);


  const fetchPrestadores = async (inputValue) => {
    setLoadingPrestadores(true);
    try {
      const response = await ApiService.get(`/users/all/prestadores?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsPrestadoresList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingPrestadores(false);
    }
  };

  const handleInputPrestadorChange = (event, newInputValue) => {
    setUserPrestadorInput(newInputValue);
    if (newInputValue !== "") {
      fetchPrestadores(newInputValue);
    } else {
      setOptionsPrestadoresList([]);
      setPrestadorSelected(null);
    }
  };


  const fetchPacientes = async (inputValue) => {
    setLoadingPacientes(true);
    try {
      const response = await ApiService.get(`/users/all/patients?searchTerm=${inputValue}`);
      const users = response.data;
      setOptionsPacientesList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingPacientes(false);
    }
  };

  const handleInputPacienteChange = (event, newInputValue) => {
    setUserPacienteInput(newInputValue);
    if (newInputValue !== "") {
      fetchPacientes(newInputValue);
    } else {
      setOptionsPacientesList([]);
      setPacienteSelected(null);
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const getAtendimentos = async () => {
    try {

      /* if (minDate && !maxDate) {
        return enqueueSnackbar("Preencha o campo de data máxima de inclusão", {
          variant: "info",
        });
      };

      if (!minDate && maxDate) {
        return enqueueSnackbar("Preencha o campo de data mínima de inclusão", {
          variant: "info",
        });
      }; */

     /*  if (month === "") {
        return enqueueSnackbar("Selecione um mês", {
          variant: "info",
          
        });
      } */
     
      let apiUrl = `/healthcare/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (pacienteSelected) {
        apiUrl += `&pacienteId=${pacienteSelected?.id}`;
      }

      if (prestadorSelected) {
        apiUrl += `&prestadorId=${prestadorSelected?.selected?.id}`;
      }

      if (minDate) {
        apiUrl += `&minData=${minDate}`;
      }

      if(maxDate) {
        apiUrl += `&maxData=${maxDate}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response.data);

          setAtendimentos(response.data?.atendimentos);
          setCount(response.data?.total);
          
          if (!searchTerm) {
            enqueueSnackbar("Busca realizada!", {
              variant: "success",
              
            });
            
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleLimparFiltros = () => {
    console.log("LIMPAR FILTROS")
    setPacienteSelected(null);
    setPrestadorSelected(null);
    setMinDate("");
    setMaxDate("");
    setClearFiltersKey(!clearFiltersKey);
    getAtendimentos();
  }

  useEffect(() => {
    getAtendimentos();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "createdAt",
      label: "Inclusão",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{moment(value).format('DD/MM/YYYY')}</Typography>;
        },
      },
    },
    {
      name: "prestador",
      label: "Colaborador",
      options: {
        filter: false,
        sort: false,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value?.nome}</Typography>
        }
      },
    },
    {
        name: "paciente",
        label: "Paciente",
        options: {
          filter: false,
          sort: false,
          enableNestedDataAccess: ".",
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Typography>{value?.nome}</Typography>
          }
        },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",
    enableNestedDataAccess: ".",
    onDownload: (buildHead, buildBody, columns, data) => {
      const alteredData = data?.map((row, index) => ({
        index,
        data: row?.data?.map((field, index) => {
          // look for the existence of a toStringOverride on the matching column for this field:
          const mapper = columns?.[index]?.toStringOverride;
          // And apply it, if it exists:
          return mapper ? mapper(field) : field;
        }),
      }));

      // Taken from sourcecode:
      return `${buildHead(columns)}${buildBody(alteredData)}`.trim();
    },

    onTableChange: (action, tableState) => {
      console.log(action);
      console.log(tableState);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Relatórios"}
        subtitle={"Atendimento Geral"}
        titleUrl={"/atendimentos-gerais"}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={10} mb={5}>
              <Grid item>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{ marginBottom: "15px" }}
                >
                  Filtro de atendimento
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} lg={4}>
                  <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                    <Autocomplete
                        key={clearFiltersKey}
                        fullWidth
                        disablePortal
                        getOptionLabel={(option) => option.nome}
                        options={optionsPrestadoresList}
                        loading={loadingPrestadores}
                        inputValue={userPrestadorInput}
                        onInputChange={handleInputPrestadorChange}
                        onChange={(event, newValue) => {
                        if (newValue) {
                            setPrestadorSelected((prevValues) => ({
                            selected: newValue,
                            }));
                        }
                        }}
                        noOptionsText={"Nenhum resultado encontrado"}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Prestador"
                                placeholder="Prestador"
                                InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                    {loadingPrestadores ? "carregando..." : null}
                                    {params.InputProps.endAdornment}
                                    </>
                                ),
                                }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                        }
                      />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={5} lg={4}>
                      <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
                        <Autocomplete
                            key={clearFiltersKey}
                            fullWidth
                            disablePortal
                            getOptionLabel={(option) => option.nome}
                            options={optionsPacientesList}
                            loading={loadingPacientes}
                            inputValue={userPacienteInput}
                            onInputChange={handleInputPacienteChange}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                setPacienteSelected(newValue);
                                }
                            }}
                            noOptionsText={"Nenhum resultado encontrado"}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Paciente"
                                placeholder="Paciente"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <>
                                        {loadingPacientes ? "carregando..." : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                    ),
                                }}
                                />
                            )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                      />
                    </Tooltip>
                </Grid>
                {/* <Grid item xs={0} md={4}></Grid> */}
                <Grid item xs={12} md={3} lg={2}>
                  <FormControl fullWidth>
                    <TextField
                      // fullWidth
                      id="validade-final"
                      label="Data Minima Inclusão*"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={minDate ?? null}
                      onChange={(e) => setMinDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <FormControl fullWidth>
                    <TextField
                      // fullWidth
                      id="validade-final"
                      label="Data Máxima Inclusão*"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={maxDate ?? null}
                      onChange={(e) => setMaxDate(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={0} md={2} lg={8}></Grid>
                <Grid item xs={12} md={2} sx={{ display: 'flex',  alignItems: 'center' }}>
                  <Button
                    fullWidth
                    endIcon={<ReceiptLongIcon />}
                    variant="contained"
                    onClick={() => getAtendimentos()}
                  >
                    Gerar
                  </Button>
                </Grid>
                <Grid item xs={12} md={2} sx={{ display: 'flex',  alignItems: 'center' }}>
                  <Button
                    fullWidth
                    endIcon={<FilterAltOffIcon />}
                    variant="outlined"
                    onClick={() => handleLimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        {/* exibir relatorio com os Aniversariantes conforme a busca  */}
        <MUIDataTable
          title={"Lista de Atendimento Geral"}
          data={atendimentos}
          columns={columns}
          options={options}
        />
      </Paper>
    </div>
  );
};

export default AtendimentosGerais;
