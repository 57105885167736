import FaceIcon from '@mui/icons-material/Face';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PermissionContext } from "app/contexts/PermissionContext";
import ApiService from 'app/services/config';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';


const WorkerColumn = ({ worker, dateSelected, isDisabled = false, index = null, workerId }) => {
    const { userId } = useParams();
    const location = useLocation();
    const { hasPermission } = React.useContext(PermissionContext);
    const [closedsSchedules, setClosedSchedules] = useState([]);
    const hours = [];
    for (let hour = 7; hour <= 19; hour++) {
        for (let minute of ['00', '30']) {
            hours.push(`${hour.toString().padStart(2, '0')}:${minute}`);
        }
    }
    const statusColors = {
        agendado: '#FF8224',
        atendido: '#AF1E87',
        aguardando: '#007EBA',
        confirmado_presenca: '#007EBA',
        em_atendimento: 'linear-gradient(to right, #007EBA 50%, #AF1E87 50%)',
        bloqueado: '#E96690'
    };

    const handleGetClosedsSchedulesWorker = useCallback(async () => {
        try {
            await ApiService.get(`/closed/schedules/bloqueios?prestadorId=${workerId}`)
            .then((response) => {
                if(response.status === 200) {
                    setClosedSchedules(response.data);
                }
            })
        } catch (error) {

        }
    }, [worker]);

    const isScheduleAvailable = (info, hour) => {
        // Verifica se a data selecionada está dentro da validade do prestador
        const isWithinValidity = moment(dateSelected).isBetween(
            info.validade_inicial,
            info.validade_final,
            'day',
            '[]'
        );

        if (!isWithinValidity) {
            return false; // Não disponível se a data não estiver dentro da validade
        }

        const availability = info?.Disponibilidade_Prestador.map(
            (disponibilidade) =>
                disponibilidade?.Disponibilidade_Prestador_Periodo?.some(
                    (periodo) =>
                        // Verifica se o dia da semana corresponde ao dia selecionado
                        moment(dateSelected).isoWeekday().toString() ===
                            disponibilidade.dia_semana &&
                        // Verifica se o horário está dentro do período de disponibilidade
                        periodo.horario_inicio <= hour &&
                        periodo.horario_fim >= hour
                )
        );

        // Verifica se pelo menos um período está disponível
        const isAvailable = availability?.some((available) => available);

        if (!isAvailable) {
            return false; // Não disponível se nenhum período está disponível
        }

        // Verifica se há agendamento para a hora específica
        const scheduling = worker?.Agendamento_Prestador?.find(
            (ag) => ag.horario === hour 
        );
        

        return !scheduling; // Retorna true se não houver agendamento para a hora específica
    };

    // id 89
    if(workerId === 89) {
        console.log(`Horários bloqueados de ${worker?.nome}`);
        console.log(closedsSchedules);
    }

    useEffect(() => {
        handleGetClosedsSchedulesWorker();
        return () => {
            setClosedSchedules([]);
        }
    }, [worker]);
    return (
        <List>
            {hours.map((hour, i) => {
                const schedule = worker?.Agendamento_Prestador?.find(
                    (ag) => index !== null ? ag.horario === hour && ag.dia_semana == index : ag.horario === hour
                );
                const disponivel =
                    worker?.Disponibilidade_Prestador_Infos?.some((info) =>
                        isScheduleAvailable(info, hour)
                    );

                const diaSemana = moment(dateSelected).isoWeekday().toString();
                const bloqueado = closedsSchedules?.some((bloqueio) => {
                    const data_inicio = moment(bloqueio?.data_inicio, 'YYYY-MM-DD');
                    const data_final = moment(bloqueio?.data_fim, 'YYYY-MM-DD');
                    const data_selecionada = moment(dateSelected, 'YYYY-MM-DD');
                    if(moment(dateSelected).isBetween(bloqueio?.data_inicio, bloqueio?.data_fim, '[]') 
                        || (moment(data_selecionada).isSame(moment(data_inicio)) && moment(data_selecionada).isSame(moment(data_final)))) {
                        return bloqueio?.dias.some((dia) => {
                            if(diaSemana === dia?.dia) {
                                return dia?.horarios_bloqueados.some((hora) => {
                                    return hour === hora?.horario;
                                });
                            }
                            return false;
                        });
                    }
                    return false;
                });
                

                return (
                    <ListItem
                        key={`horario${i}`}
                        sx={{ background: i % 2 === 0 ? '#d9d9d9' : '' }}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <ListItemText primary={hour} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    schedule ? (
                                        <Link
                                            to={`/schedule-worker/pacient-schedule/${schedule.id}`}
                                            state={{
                                                backUrl: location.pathname,
                                            }}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Chip
                                                icon={<FaceIcon />}
                                                color='primary'
                                                label={schedule?.User?.nome}
                                                sx={{
                                                    background:
                                                        statusColors[
                                                            schedule?.status
                                                        ] || '',
                                                }}
                                            />
                                        </Link>
                                    ) : bloqueado ? (
                                        <Chip icon={<BlockIcon />} color={'primary'} sx={{background: statusColors['bloqueado']}} label="Bloqueado" />) 
                                        
                                    : disponivel ? (
                                        <Link
                                            to={{
                                                pathname: hasPermission('Agenda Prestador', 'create') ? `/schedule-worker/pacient-schedule/new`: '/schedule-worker',
                                                search: hasPermission('Agenda Prestador', 'create') ? `?workerId=${worker?.id}&date=${dateSelected}&hour=${hour}` : null,
                                            }}
                                            state={{
                                                backUrl: location.pathname,
                                            }}
                                            style={{ textDecoration: 'none' }}
                                            disabled={!hasPermission('Agenda Prestador', 'create') || isDisabled}
                                        >
                                            <Chip
                                                color='success'
                                                label={'Disponível'}
                                                disabled={!hasPermission('Agenda Prestador', 'create') || isDisabled}
                                            />
                                        </Link>) 
                                        : (
                                        <Chip
                                            color='default'
                                            label={'Não disponível'}
                                            disabled={!hasPermission('Agenda Prestador', 'create') || isDisabled}
                                        />
                                    )
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default WorkerColumn;
