import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback } from "react";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

const todasPerguntas = [
  {
    categoria: "COMUNICAÇÃO RECEPTIVA",
    perguntas: [
      "Segue instruções para 'parar' ou 'esperar' sem ajudas ou gestos",
      "Segue 8-10 instruções verbais de um comportamento envolvendo ações corporais e ações com objetos",
      "Identifica apontando ou mostrando várias partes do corpo nomeadas, em si própria ou em outra pessoa",
      "Responde a instruções verbais para dar/apontar/mostrar 8-10 objetos específicos nas rotinas de jogo natural, vestir e alimentação (p.e., bebê, cadeira, carro, bloco, corpo, urso)",
      "Identifica apontando e dirigindo o olhar para três imagens nomeadas num livro (incluindo copo, carro, cão, gato, bebê)",
      "Entende conceitos espaciais iniciais (p.e., entre, sob)",
      "Olha para pessoas e fotografias de pessoas quando nomeadas- família, animais, professores",
      "Obtém 8-10 objetos verbalmente solicitados na sala, mas não diretamente à frente da criança requerendo alguma procura",
      "Mediante pedidos verbais (com pistas gestuais) completa duas ações (comandos duplos) com um objeto",
      "Aponta para partes do corpo nomeadas numa imagem",
    ],
  },
  {
    categoria: "COMUNICAÇÃO EXPRESSIVA",
    perguntas: [
      "Usa os sinais ou gestos-alvo com vocalização para se expressar as quatro funções: pedir, terminar, partilhar, pedir ajuda, protestar",
      "Produz 6-10 palavras ou aproximações dentro do contexto de rotinas familiares, rotinas sociais sensoriais e canções",
      "Produz espontaneamente várias palavras associadas a uma rotina de jogo (rola, vai, para)",
      "Usa de forma funcional 20 ou mais aproximações de nomes (nomes de objetos, animais, pessoas) e não nominais (palavras que se referem a ações ou outras relações: desapareceram todos, cima, etc.)",
      "Nomeia objetos e imagens espontaneamente",
      "Vocaliza com uma variedade de entoações durante canções",
      "Faz pedidos e recusa usando palavras isoladas com contato visual",
      "Nomeia ações em contexto (p.e., durante os movimentos corporais e/ou ações com objetos)",
      "Faz aproximações ao nome de três pessoas importantes (incluindo a própria)",
      "Abana a cabeça e diz 'Não' para recusar",
      "Acena com a cabeça e diz 'Sim' para afirmar",
      "Pergunta (aproximação) 'O que é isso?' quando se depara com algo desconhecido",
    ],
  },
  {
    categoria: "COMPORTAMENTOS DE ATENÇÃO CONJUNTA",
    perguntas: [
      "Responde a 'Olha' e ao objeto oferecido com na alternância do olhar, virando o corpo e olhando para o objeto oferecido",
      "Responde a 'Olha' e ao apontar, orientando-se para a pessoa/objeto distal indicado",
      "Dá ou pega no objeto de outra pessoa coordenando com o contato visual",
      "Responde a 'Mostra-me' estendendo o objeto ao adulto",
      "Mostra objetos de forma espontânea",
      "Segue ou olha para um ponto de forma espontânea (sem pista verbal) para olhar para o alvo",
      "Aponta espontaneamente para os objetos de interesse",
      "Partilha sorrisos com o adulto alternando o olhar durante uma atividade agradável com um objeto",
    ],
  },
  {
    categoria: "COMPETÊNCIAS SOCIAIS: ADULTOS OU PARES",
    perguntas: [
      "Inicia e mantém o contato visual para comunicar",
      "Verbaliza pedidos ou inicia fisicamente jogos sociais familiares",
      "Retribui comportamentos afetuosos: abraços, beijos, a pessoas familiares",
      "Usa gestos ou palavras para conseguir a atenção dos adultos",
      "Responde a cumprimentos sociais com 'Olá' ou 'Adeus' e acena imitativamente",
      "Pede ajuda verbal ou gestualmente",
      "Coordena consistentemente o contato visual com a vocalização e/ou gesto para direcionar a comunicação",
      "Dança com os outros em jogos de círculo ao som da música",
      "Corre com os outros em jogos de 'perseguição'",
      "Obtém a atenção do outro utilizando o nome da pessoa ou jogo e inicia o jogo ou atividade social",
    ],
  },
  {
    categoria: "COMPETÊNCIAS SOCIAIS COM PARES",
    perguntas: [
      "Dá um objeto ao parceiro quando ele pede",
      "Junta-se a canções/jogos de dedos familiares em contexto de grupo",
      "Prossegue uma atividade quando o par se junta num jogo paralelo",
      "Responde apropriadamente à saudação do par",
      "Troca de vez com o par em ações simples com brinquedos quando o par pede; Dá e leva de volta",
      "Senta-se em grupo com os pares e atende a instruções familiares do adulto",
      "Recebe objetos dos pares quando estes oferecem",
      "Passa objetos aos pares na mesa ou em grupo quando pedido",
      "Imita o comportamento dos pares ocasionalmente em atividades de jogo",
      "Joga jogos de correspondência de imagens (Memória, loto, etc.) sozinho e com os pares",
    ],
  },
  {
    categoria: "IMITAÇÃO",
    perguntas: [
      "Imita uma variedade de sons de vogais e consoantes durante aproximações verbais em comunicações significativas",
      "Imita sons de animais e outros sons",
      "Imita palavras isoladas reconhecíveis, espontânea e frequentemente em interações",
      "Imita os movimentos para cinco músicas, imita pelo menos 10 ações diferentes",
      "Imita/faz aproximações de ações em sequência em canções",
      "Imita ações com objetos passos múltiplos (ações de jogo)",
      "Imita ações a fingir para si mesma e com miniaturas",
      "Imita duas sequências de movimentos em rotinas de canção/jogo",
      "Imita frases de duas palavras",
    ],
  },
  {
    categoria: "COGNIÇÃO",
    perguntas: [
      "Corresponde/agrupa por formas",
      "Corresponde/agrupa por tamanho",
      "Corresponde/agrupa desenhos e contornos",
      "Classifica objetos semelhantes por grupos",
      "Agrupa objetos comuns em grupos funcionais",
      "Procura/pergunta por objeto desaparecidos",
      "Corresponde/agrupa em duas dimensões",
      "Corresponde por quantidade, de um a três",
    ],
  },
  {
    categoria: "JOGO DE REPRESENTAÇÃO",
    perguntas: [
      "Combina objetos relacionados no jogo (xícara no pires, colher no prato)",
      "Imita/produz efeitos sonoros no jogo (vocaliza ao telefone, faz barulhos de carros, sons de animais com animais)",
      "Realiza uma ação única com um adereço numa boneca ou animal",
      "Combina ações funcionalmente relacionadas com o tema do jogo (alimenta e dá bebida, deita na cama e tapa)",
      "Demonstra uma abordagem de tentativa e erro para resolver problemas com brinquedos de construção; os esquemas são flexíveis e não repetitivos",
    ],
  },
  {
    categoria: "JOGO INDEPENDENTE",
    perguntas: [
      "Brinca de forma adequada e flexível durante 10 minutos apenas com atenção ocasional ao adulto",
      "Mantém-se ocupada com materiais simples de uso múltiplo de forma adequada durante pelos menos 10 minutos de cada vez com orientação ocasional do adulto",
      "Obtém os materiais, traz para a mesa, completa a atividade de jogo e afasta-se",
    ],
  },
  {
    categoria: "MOTRICIDADE FINA",
    perguntas: [
      "Introduz três ou mais peças no jogo de encaixe",
      "Empilha 8-10 blocos de 2,5 cm",
      "Copia três ou mais pilhas de blocos simples",
      "Junta cinco ou mais legos, contas, brinquedos 'tinker', blocos 'bristle' de formas variadas",
      "Imita cinco ou mais ações simples com plasticina (massinha) - rolar, puxar, bater, apertar",
      "Cola vários autocolantes em folhas",
      "Abre e fecha uma variedade de recipientes, incluindo tampas de enroscar",
      "Abre e fecha grandes fechos de correr",
      "Amarra objetos grandes com cordéis, corda grossa ou tubos de aquário",
      "Imita traços, rabiscos e pontos com marcadores e lápis de cor",
      "Corta papel com tesouras",
      "Coloca peças do jogo de damas e moedas numa ranhura",
      "Coloca uma variedade de contas em diferentes tipos de fio",
      "Completa puzzles de quatro a seis peças geométricas",
    ],
  },
  {
    categoria: "MOTRICIDADE GROSSA",
    perguntas: [
      "Corre com boa postura e controlo direcional",
      "Anda e corre, parando ou mudando de direção sem queda em terreno variado, mesmo a velocidades moderadas",
      "Tira os pés do chão para subir um objeto alto sem ajuda",
      "Equilibra-se numa perna para calçar uma peça de roupa",
      "Desce escadas alternando os pés, segurando no corrimão, sem ajuda",
      "Salta no mesmo lugar",
      "Salta com os dois pés em frente com impulsão",
      "Salta para a frente de uma linha elevada com ambos os pés",
      "Salta para a frente numa distância de 20 cm de uma plataforma, aterrando com ambos os pés",
      "Rola de trás para a frente",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL: ALIMENTAÇÃO",
    perguntas: [
      "Come sozinho com uma colher ou garfo",
      "Bebe de uma chávena ou copo com uma mão",
      "Usa uma caneca com uma mão",
      "Come sozinho com uma colher de sopa",
      "Bebe de um copo sem tampa com uma mão",
      "Usa uma faca de criança para cortar manteiga ou pão macio",
      "Tira a roupa",
      "Veste a parte de cima da roupa",
      "Tira e põe calçado",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL: VESTIR",
    perguntas: [
      "Despe a roupa sozinha e deposita-a no cesto (sem fechos)",
      "Completa alguns passos ao vestir algumas peças de roupa sozinha (precisa de ajuda com fechos)",
      "Tira o casaco, o chápeu (sem fechos), e coloca-os no cabide (ou gaveta)",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL: HIGIENE",
    perguntas: [
      "Limpa o rosto com um pano quente quando instruída",
      "Limpa o nariz quando instruída",
      "Participa em todos os passos da lavagem de mãos",
      "Coopera na lavagem/corte do cabelo",
      "Brinca com cinco brinquedos para o banho adequadamente",
      "Arruma os brinquedos quando solicitado no final do banho",
      "Ajuda na colocação da loção",
      "Usa corretamente a escova de dentes",
      "Vai dormir sozinha depois do ritual de preparação para deitar",
      "Mostra conhecimento acerca da sequência da rotina da hora de deitar",
    ],
  },
  {
    categoria: "INDEPENDÊNCIA PESSOAL: TAREFAS",
    perguntas: [
      "Separa os talheres da máquina de lavar louça para a bandeja dos talheres",
      "Tira a roupa da máquina de secar para o cesto",
      "Emparelha as meias",
      "Põe água/comida na tigela do animal",
    ],
  },
];

const InterdisciplinaryTwo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { quizzId, userId } = useParams();
  const location = useLocation();

  const storedColaboratorId = localStorage.getItem("id");

  const [quizzes, setQuizzes] = useState([]);
  const [answers, setAnswers] = useState([]);

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const date = query.get("date");

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}`)
        .then((response) => {
          console.log(response.data?.pergunta);

          setQuizzes(response.data?.pergunta);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, quizzId]);

  const getQuizzesAnswers = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}/user/${userId}?date=${date}`)
        .then((response) => {
          console.log(response.data);

          setQuizzes(response.data?.pergunta);

          /* setAnswers(respostasFormatadas); */
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname.includes("edit")) {
      getQuizzesAnswers();
    } else {
      getQuizzes();
    }
  }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

  console.log(answers);

  const getSelectedAnswer = (respostas, perguntaId) => {
    // Verifica se há uma resposta salva no estado answers para a pergunta específica
    const respostaSalva = answers.find(
      (resposta) => resposta.pergunta_id === perguntaId
    );

    // Se houver uma resposta salva, retorna o ID dessa resposta
    if (respostaSalva) {
      return respostaSalva.resposta_id;
    }

    // Se não houver uma resposta salva, verifica se há uma resposta do servidor
    const respostaUsuario = respostas.find(
      (resposta) =>
        resposta?.Resposta_Usuario?.length > 0 &&
        resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
    );

    // Se houver uma resposta do servidor, retorna o ID dessa resposta
    if (respostaUsuario) {
      return respostaUsuario.id;
    }

    // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
    return "";
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Aba de Avaliação Interdisciplinar"}
        subtitle={"Nível 2"}
        titleUrl={"/"}
      />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                {todasPerguntas.map((categoria, index) => (
                  <Accordion defaultExpanded my={2}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography variant={"h3"} fontWeight={500}>
                        {categoria.categoria}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        {categoria.perguntas.map((pergunta, index) => (
                          <Grid item xs={12} key={index}>
                            <Stack
                              direction="row"
                              gap={2}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              width="100%"
                            >
                              <Typography>
                                {index + 1} - {pergunta}
                              </Typography>
                              <Select sx={{ minWidth: "150px" }}>
                                <MenuItem value={"adquirido"}>
                                  Adquirido
                                </MenuItem>
                                <MenuItem value={"parcial"}>Parcial</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"nao"}>Não</MenuItem>
                                <MenuItem value={"nao_observado"}>
                                  Não observado
                                </MenuItem>
                              </Select>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">Inclusão</TableCell>
                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                    <TableCell align="center">Alteração</TableCell>
                    <TableCell align="center">
                      {" "}
                      Colaborador Alteração{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                if (location?.pathname.includes("edit")) {
                  ApiService.put(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                }
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default InterdisciplinaryTwo;
