import React from 'react';


const Home = () => {
    return (
        <div>
            {/* <h2>this is a simple placeholder</h2>
            <p>This page will be the <strong> HOME </strong> page.</p>
            <p>Happy Coding!</p> */}
        </div>
    );
};

export default Home;