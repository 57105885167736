import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ApiService from 'app/services/config';
import SendIcon from '@mui/icons-material/Send';
import { Paper } from '@mui/material';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { redirectToEvolution } from '../search/ScheduleVertical';

const GeneralDocumentsForm = ({ values, infos, setFieldValue }) => {
    const { id, userId } = useParams();
    let { state } = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const storedUsername = localStorage.getItem('username');
    const storedUserId = localStorage.getItem('id');

    const [files, setFiles] = useState([]);
    const [filesSelected, setFilesSelected] = useState([]);
    const [isEditingFile, setIsEditingFile] = useState(false);
    const [textFile, setTextFile] = useState(null);

    const [docsGeneral, setDocsGeneral] = useState({
        cid_1: '',
        cid_2: '',
        cid_3: '',
        modelo_documento_tipo: '',
        modelo_documento: '',
        titulo: '',
    });

    const [isEditMode, setIsEditMode] = useState(false);

    const [previewImage, setPreviewImage] = useState([]);

    const getInfosDoc = useCallback(async () => {
        try {
            await ApiService.get(`/docs-infos/${id}`)
                .then((response) => {
                    console.log(response.data);

                    setDocsGeneral(response.data);
                    setFiles(response.data?.Documento_Geral);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, [setDocsGeneral, id]);

    useEffect(() => {
        if (id) getInfosDoc();
    }, [getInfosDoc, id]);

    const uploadHandler = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        /*  setFiles([...files, file]); */

        setFilesSelected([...filesSelected, file]); // Mantém filesSelected em sincronia com files
        setPreviewImage([...previewImage, URL.createObjectURL(file)]);
    };

    const removeFile = (index) => {
        /* setFiles(files.filter((file, i) => i !== index)); */

        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        setFilesSelected(updatedFiles); // Mantém filesSelected em sincronia com files
        const updatedPreviewImage = [...previewImage];
        updatedPreviewImage.splice(index, 1); // Remove a imagem correspondente
        setPreviewImage(updatedPreviewImage);
    };

    const handleInputChangeFile = (e) => {
        setTextFile(e.target.value);
    };

    const handleEditDocs = (docsEdit) => {
        console.log(docsEdit);
        setDocsGeneral(docsEdit);
        setIsEditMode(true);
    };

    const modalAlert = (docsId) => {
        console.log(docsId);
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                removeDoc(docsId);
            }
        });
    };

    const removeDoc = useCallback(
        async (docsId) => {
            ApiService.delete(`/docs-general/${docsId}`)
                .then((response) => {
                    const removedEvolution = files?.filter(
                        (item) => item.id !== docsId
                    );
                    console.log(removedEvolution);
                    setFiles(removedEvolution);
                    enqueueSnackbar('Documento removido com sucesso!', {
                        variant: 'success',
                        
                    });
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [enqueueSnackbar, files]
    );

    return (
        <div>
             <HeaderBreadcrumbs
                title={'Paciente'}
                subtitle={id ? 'Editar Documento' : 'Adicionar Documento'}
                titleUrl={`/evolution/user/${userId}/edit`}
            />
            <Button
                sx={{ width: '80px', marginBottom: '1rem' }}
                variant='contained'
                color='success'
                onClick={() =>
                    redirectToEvolution(
                      userId,
                      navigate,
                      6
                    )
                  }
            >
                Voltar
            </Button>
            <Paper style={{ padding: '30px' }} square={false} elevation={3}>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12} container justifyContent='center'>
                        <Button
                            variant='outlined'
                            endIcon={<SendIcon />}
                            onClick={() => {
                                if (id) {
                                    const docId = docsGeneral.id;
                                    const userEvolutionEdit = {
                                        ...docsGeneral,
                                        prestador_id: Number(storedUserId),
                                        colaborador_id: Number(storedUserId),
                                    };
                                    console.log(userEvolutionEdit);

                                    const formData = new FormData();
                                    formData.append(
                                        'user_id',
                                        userEvolutionEdit.user_id
                                    );
                                    formData.append(
                                        'prestador_id',
                                        userEvolutionEdit.prestador_id
                                    );
                                    formData.append(
                                        'colaborador_id',
                                        userEvolutionEdit.colaborador_id
                                    );
                                    formData.append('cid_1', docsGeneral.cid_1);
                                    formData.append('cid_2', docsGeneral.cid_2);
                                    formData.append('cid_3', docsGeneral.cid_3);
                                    formData.append(
                                        'modelo_documento_tipo',
                                        docsGeneral.modelo_documento_tipo
                                    );
                                    formData.append(
                                        'titulo',
                                        docsGeneral.titulo
                                    );
                                    formData.append(
                                        'modelo_documento',
                                        docsGeneral.modelo_documento
                                    );

                                    for (
                                        let i = 0;
                                        i < filesSelected.length;
                                        i++
                                    ) {
                                        if (filesSelected[i] instanceof File) {
                                            formData.append(
                                                'files',
                                                filesSelected[i]
                                            );
                                        }
                                    }

                                    ApiService.put(
                                        `/docs-infos/${docId}`,
                                        formData
                                    )
                                        .then((response) => {
                                            console.log(response.data);
                                            enqueueSnackbar(
                                                'Doc atualizada com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );
                                            setPreviewImage([]);
                                            setFilesSelected([]);
                                            getInfosDoc();
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response.data.message;
                                            console.log(errorMessage);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                } else {
                                    const userDoc = {
                                        user_id: Number(userId),
                                        prestador_id: Number(storedUserId),
                                        colaborador_id: Number(storedUserId),
                                        cid_1: docsGeneral.cid_1,
                                        cid_2: docsGeneral.cid_2,
                                        cid_3: docsGeneral.cid_3,
                                        modelo_documento_tipo:
                                            docsGeneral.modelo_documento_tipo,
                                        modelo_documento:
                                            docsGeneral.modelo_documento,
                                        titulo: docsGeneral.titulo,
                                    };
                                    console.log(userDoc);

                                    const formData = new FormData();
                                    formData.append('user_id', userDoc.user_id);
                                    formData.append(
                                        'prestador_id',
                                        userDoc.prestador_id
                                    );
                                    formData.append(
                                        'colaborador_id',
                                        userDoc.colaborador_id
                                    );
                                    formData.append('cid_1', userDoc.cid_1);
                                    formData.append('cid_2', userDoc.cid_2);
                                    formData.append('cid_3', userDoc.cid_3);
                                    formData.append(
                                        'modelo_documento_tipo',
                                        userDoc.modelo_documento_tipo
                                    );
                                    formData.append('titulo', userDoc.titulo);
                                    formData.append(
                                        'modelo_documento',
                                        userDoc.modelo_documento
                                    );

                                    for (
                                        let i = 0;
                                        i < filesSelected.length;
                                        i++
                                    ) {
                                        formData.append(
                                            'files',
                                            filesSelected[i]
                                        );
                                    }

                                    ApiService.post(`/docs-infos`, formData)
                                        .then((response) => {
                                            console.log(response.data);
                                            console.log(values);
                                            /* const newDoc = [
                                                ...[values.Info_Documento],
                                                response.data,
                                            ]; */
                                            /*  console.log(newDoc); */
                                            enqueueSnackbar(
                                                'Doc criado com sucesso!',
                                                {
                                                    variant: 'success',
                                                    
                                                }
                                            );

                                            setDocsGeneral({
                                                // Limpa o estado de doc
                                                cid_1: '',
                                                cid_2: '',
                                                cid_3: '',
                                                modelo_documento_tipo: '',
                                                modelo_documento: '',
                                                titulo: '',
                                            });
                                            setPreviewImage([]);
                                            setFilesSelected([]);
                                        })
                                        .catch((error) => {
                                            const errorMessage =
                                                error.response?.data?.message;
                                            console.log(error);
                                            if (Array.isArray(errorMessage)) {
                                                enqueueSnackbar(
                                                    `${errorMessage[0]}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            } else {
                                                enqueueSnackbar(
                                                    `${errorMessage}`,
                                                    {
                                                        variant: 'error',
                                                        
                                                    }
                                                );
                                            }
                                        });
                                }
                            }}
                        >
                            {isEditMode ? 'Salvar' : 'Salvar'}
                        </Button>
                        {/* <Button variant='outlined' style={{ marginLeft: '10px' }}>
                        Cancelar
                    </Button> */}
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id='standard-basic'
                            label='CID 1'
                            variant='outlined'
                            value={docsGeneral?.cid_1}
                            onChange={(event) => {
                                setDocsGeneral({
                                    ...docsGeneral,
                                    cid_1: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id='standard-basic'
                            label='CID 2'
                            variant='outlined'
                            value={docsGeneral?.cid_2}
                            onChange={(event) => {
                                setDocsGeneral({
                                    ...docsGeneral,
                                    cid_2: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id='standard-basic'
                            label='CID 3'
                            variant='outlined'
                            value={docsGeneral?.cid_3}
                            onChange={(event) => {
                                setDocsGeneral({
                                    ...docsGeneral,
                                    cid_3: event.target.value,
                                });
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id='standard-basic'
                            label='Titulo'
                            variant='outlined'
                            value={docsGeneral?.titulo}
                            onChange={(event) => {
                                setDocsGeneral({
                                    ...docsGeneral,
                                    titulo: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            component='label'
                            color='info'
                            sx={{
                                height: '53.13px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload
                            <input
                                type='file'
                                hidden
                                name='docs'
                                onChange={uploadHandler}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FileListSlim
                            files={files}
                            filesSelected={filesSelected}
                            removeFile={removeFile}
                            previewImage={previewImage}
                            modalAlert={modalAlert}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default GeneralDocumentsForm;
