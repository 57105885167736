
import { Checkbox, Grid, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ApiService from "app/services/config";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSearch from "@jumbo/components/JumboSearch";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import ListRowProfile from "../ListRowProfile/ListRowProfile";


  const ProfileForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const Swal = useSwalWrapper();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams()
    const initialValues = {
      name: "",
      values: []
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Preenchimento obrigatório'),
    }, []);

    const [roles, setRoles] = useState([]);
    const [profiles, setProfiles] = useState(initialValues);
    const [allProfiles, setAllProfiles] = useState(initialValues);
    const [screens, setScreens] = useState([]);
    const [profilesScreens, setProfilesScreens] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [rowId, setRowId] = useState([{}]);
    const [optionsList, setOptionsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userSelected, setUserSelected] = useState(null);
    const [addProfiles, setAddProfiles] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null)
    const [searchTermUserRole, setSearchTermUserRole] = useState(null)
    
    const [isInputFocused, setIsInputFocused] = useState(false);

    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');

    
    const [userInput, setUserInput] = useState('');
    // console.log('profilesScreens', profilesScreens)
    // console.log('id', id)
    console.log('profiles', profiles)
    // console.log('screens', screens)
    // console.log('roles', roles)
    // console.log('rowId', rowId)

    const handleSearch = async (newSearchTerm) => {
      if (
          searchTermUserRole === '' ||
          searchTermUserRole === null ||
          searchTermUserRole === undefined
      ) {
          setPage(1);
      }
      setSearchTermUserRole(newSearchTerm);
      setPage(0);
  };

    useEffect(() => {
      if (searchTermUserRole == undefined || searchTermUserRole == "") {
        setProfiles(allProfiles);
  
        return;
      }
  
      const delayDebounceFn = setTimeout(() => {
        searchUserOnRole(searchTermUserRole);
      }, 1500);
  
      return () => clearTimeout(delayDebounceFn);
    }, [searchTermUserRole]);

    const searchUserOnRole = useCallback(
      async (e) => {
        try {
          await ApiService.get(`roles/${id}/usuarios`, { params: { search: e } })
            .then((response) => {
              const arr =[];

              response.data.map((x) => {
                arr.push({
                  id: 1,
                  user_id: 1,
                  perfil_id: 1,
                  User:{
                    id: x.id,
                    nome: x.nome,
                    email: x.email,
                  }
                })
              })
              let t2 = profiles;
              t2.users = arr;
              // profiles.users = arr;
              setProfiles(t2);
              console.log('atualizou profiles')
            })
            .catch((error) => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("error", error.message);
              }
            });
        } catch (err) {
          console.log(err);
        }
      },
      []
    );

    const updateProfileList = useCallback(async (data) => {
      // console.log('updateProfileList', data)
      console.log('updateProfileList1', profiles)
      console.log('updateProfileList2', profiles.users)
      profiles.users.push(data)
      setProfiles(profiles);
      setAddProfiles(null);
    }, [profiles]);

    const handleInputFocus = () => {
      setIsInputFocused(true);
    }
  
    const handleInputBlur = () => {
      setIsInputFocused(false);
    }


  useEffect(() => {
    if(searchTerm == undefined || searchTerm == ''){
        setAddProfiles(null);
        return;
    } 

    const delayDebounceFn = setTimeout(() => {
      searchUserWithoutRole(searchTerm)
    }, 1500)

    
    return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchUserWithoutRole = useCallback(async (e) => {
        try {
            ApiService.get(`/roles/usuarios-sem-perfil/${id}`, {params: {search: e}})
            .then((response) => {
                setAddProfiles(response.data)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const fetchUsers = async (inputValue) => {
        setLoading(true);
        try {
            const response = await ApiService.get(
                `/users?searchTerm=${inputValue}`
            );
            const users = response.data?.usersWithoutPassword || [];
            console.log('users', users)
            setOptionsList(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChangeUser = (event, newInputValue) => {
        setUserInput(newInputValue);
        if (newInputValue !== '') {
            fetchUsers(newInputValue);
        } else {
            setOptionsList([]);
            setUserSelected(null);
        }
    };

    const handleInputChange = (e, states, tela_id) => {
 
        const {name, checked} = e.target;
         
        const list = [...states]
        // console.log('e', e)
        // console.log('states', states)
        // console.log('tela_id', tela_id)
        const el = list.map((item, index) => {
             if(item.id == tela_id){ 
                 item.id = tela_id;
                 
                 list[index][name] = checked
        
                 return item
             } else {
                 return item
             
             }
         })
 
         setRowId(el)
     }

    
    const getRoles = React.useCallback(async () => {
        try {
            await ApiService.get(`/roles`)
                .then((response) => {
                  setRoles(response.data)
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    const modalAlert = (id) => {
      Swal.fire({
        title: `Tem certeza que deseja remover desse perfil?`,
        text: `Você não poderá reverter a ação!`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonText: `Sim!`,
        cancelButtonText: `Não!`,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          return handleDeleteUserFromRole(id);
        }
      });
    };

    const handleDeleteUserFromRole = async (id) => {
      try {
        await ApiService.delete(`roles/user-role/${id}`).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Excluído com sucesso!", {
              variant: "success",
              
            });
            getRoles();
            getProfileById();
            // setForceUpdate(!forceUpdate);
          }
        });
      } catch (error) {
        enqueueSnackbar("Ocorreu um erro!", {
          variant: "error",
          
        });
        console.error(error);
      }
    };

    const getProfileById = useCallback(async () => {
      try {
          ApiService.get(`/roles/${id}`)
          .then((response) => {
              // console.log(response.data);
              setProfiles(response.data.perfil);
              setAllProfiles(response.data.perfil)
            if(response.data.userPerfil.length > 0){
              setProfilesScreens(response.data.profileScreenMarked)
          }

          })
          .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
          })
      } catch (err) {
        console.log(err);
      }
  }, []);

  const getScreens = useCallback(async () => {
    try {
        ApiService.get(`/roles/telas`)
        .then((response) => {
          setScreens(response.data);
        })
        .catch((error) => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
        })
    } catch (err) {
      console.log(err);
    }
}, []);
  
    React.useEffect(() => {
      if(id) {
          getProfileById();
      };
      getScreens();
      getRoles();
    }, []);

    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          display: false,
        },
      },
      {
        name: "User",
        label: "Nome",
        options: {
          sort: true,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.nome;
          },
        },
      },
      {
        name: "acoes",
        label: "Ações",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const perfilId = tableMeta.rowData[0];

            return (
              <Stack direction="row" gap={2}>
                <DeleteIcon
                  color="error"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    modalAlert(perfilId);
                  }}
                />
              </Stack>
            );
          },
        },
      },
    ];
  
    const options = {
      // changeRowsPerPage: perPage,
      filterType: "dropdown",
      filter: false,
      selectableRows: "none",
      searchAlwaysOpen: true,
      searchable: true,
      serverSide: false,
      // page: page,
      // count: count,
      selectToolbarPlacement: "above",
  
      /* onTableChange: (action, tableState) => {
        console.log(action);
        switch (action) {
          case "changePage":
            setPage(tableState.page);
            break;
          case "sort":
            setSortBy(tableState.sortOrder.name);
            setSortOrder(tableState.sortOrder.direction);
            break;
          case "search":
            handleSearch(tableState.searchText);
            break;
          case "changeRowsPerPage":
            setPerPage(tableState.rowsPerPage);
            break;
          default:
            console.log("action not handled.");
        }
      }, */
      textLabels: {
        body: {
          noMatch: "Nenhum registro encontrado",
          toolTip: "Ordenar",
        },
        pagination: {
          next: "Próxima Página",
          previous: "Página Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Pesquisar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Ver Colunas",
          filterTable: "Filtrar Tabela",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESETAR",
        },
        viewColumns: {
          title: "Mostrar Colunas",
          titleAria: "Mostrar/Esconder Colunas da Tabela",
        },
        selectedRows: {
          text: "linha(s) selecionada(s)",
          delete: "Excluir",
          deleteAria: "Excluir linhas selecionadas",
        },
      },
    };

    const handleSubmit = async (values, { setSubmitting }) => {
      // console.log('values', values)
      // console.log('id', id)
      if(id) {
          ApiService.put(`roles/perfil-telas/${id}`, { perfil_nome: values.name, values: rowId.length > 1 ? rowId : [] })
          .then((response) => {
                enqueueSnackbar("Atualizado com sucesso!", {
                  variant: "success",
                  
                });
                console.log('Atualizado com sucesso')
              })
              .catch((error) => {
              console.log('Ocorreu um erro')
              enqueueSnackbar("Ocorreu um erro", {
                variant: "error",
                
              });

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  } else if (error.request) {
                  console.log(error.request);
                  } else {
                  console.log('error', error.message);
                  }
              })
      }else {
          await ApiService.post('roles/perfil-telas', { perfil_nome: values.name, values: rowId.length > 1 ? rowId : [] })
          .then((response) => {
            console.log('Salvo com sucesso')
            enqueueSnackbar("Criado com sucesso!", {
              variant: "success",
              
            });
          })
          .catch((error) => {
            console.log('Ocorreu um erro')
            enqueueSnackbar("Ocorreu um erro", {
              variant: "error",
              
            });
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              } else if (error.request) {
              console.log(error.request);
              } else {
              console.log('error', error.message);
              }
          })
      }


      setSubmitting(false);
  } 

    return(
        <div>
            <Grid container>
                <HeaderBreadcrumbs
                    title={'Perfis'}
                    subtitle={id ? "Editar Perfil" : "Criar Perfil"}
                    titleUrl={'/role'}
                />
            </Grid>
            
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <Paper style={{ padding: '10px', marginBottom: '15px', overflowX: 'auto', overflowY: 'auto' }} square={false} elevation={3}>
                        <Formik
                            initialValues={profiles}
                            validationSchema={validationSchema}
                            enableReinitialize
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={handleSubmit}
                            onTableChange={(action, tableState) => {
                              console.log('action', action)
                              console.log('tableState', tableState)
                            }}
                        >
                            {({values, isSubmitting, handleChange}) => (
                                <Form style={{width: '100%'}} noValidate autoComplete='off'>
                                    <Grid container spacing={3} alignContent={'center'}>
                                        <Grid item xs={6} >
                                            <JumboTextField
                                                fullWidth
                                                name="name"
                                                label="Nome"
                                                value={values.name}
                                                // onChange={handleChange}
                                                // handleChange={handleChange}
                                                // disabled={!hasPermission('Perfis', 'editar')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TableContainer >
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table"
                                            
                                                >
                                                    <TableHead>
                                                    <TableRow>
                                                        <TableCell component="th">Nome</TableCell>
                                                        <TableCell component="th">Visualizar</TableCell>
                                                        <TableCell component="th">Criar</TableCell>
                                                        <TableCell component="th">Editar</TableCell>
                                                        <TableCell component="th">Deletar</TableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                
                                                    {
                                                        id && profilesScreens.length > 0
                                                        ?
                                                        (
                                                            profilesScreens.map((item, index) => (
                                                            <TableRow key={item.tela_id}>
                                                                <TableCell>
                                                                    {item.nome}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="read"
                                                                        checked={item.read}
                                                                        onClick={(e) => handleInputChange(e, profilesScreens, item.id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="create"  
                                                                        checked={item.create}          
                                                                        onClick={(e) => handleInputChange(e, profilesScreens, item.id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="update"
                                                                        checked={item.update}
                                                                        onClick={(e) => handleInputChange(e, profilesScreens, item.id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Checkbox
                                                                        name="delete"
                                                                        checked={item.delete}
                                                                        onClick={(e) => handleInputChange(e, profilesScreens, item.id)}
                                                                        disabled={isDisabled}
                                                                    />
                                                                </TableCell>

                                                            </TableRow>  
                                                            ))
                                                        )
                                                        :
                                                        (

                                                            screens.map((screen, index) => (
                                                                <>
                                                                    <TableRow
                                                                        key={screen.tela_id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        id={screen.tela_id}
                                                                    >
                                                                        <TableCell>
                                                                            {screen.nome}
                                                                        </TableCell>
                                                                                                                
                                                                        <>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    name="read"
                                                                                    onClick={(e) => handleInputChange(e, screens, screen.id)}
                                                                                    disabled={isDisabled}
                                                                                />
                                                                            </TableCell>

                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    name="create"  
                                                                                    onClick={(e) => handleInputChange(e, screens, screen.id)}
                                                                                    disabled={isDisabled}
                                                                                />
                                                                            </TableCell>

                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    name="update"
                                                                                    onClick={(e) => handleInputChange(e, screens, screen.id)}
                                                                                    disabled={isDisabled}
                                                                                />
                                                                            </TableCell>

                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    name="delete"
                                                                                    onClick={(e) => handleInputChange(e, screens, screen.id)}
                                                                                    disabled={isDisabled}
                                                                                />
                                                                            </TableCell>

                                                                        </>
                                                                                                                        
                                                                    </TableRow>
                                                                
                                                                </>
                                                            ))
                                                        )
                                                    } 
                
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            

                                            <Typography variant={"h4"} mb={1} sx={{ marginTop: '2rem' }}>Adicionar usuário</Typography>


                                            {/* <Autocomplete
                                              fullWidth
                                              disablePortal
                                              getOptionLabel={(option) => option.nome}
                                              options={optionsList}
                                              loading={loading}
                                              inputValue={userInput}
                                              onInputChange={handleInputChangeUser}
                                              onChange={(event, newValue) => {
                                                  setUserSelected((prevValues) => ({
                                                      selected: newValue,
                                                  }));
                                              }}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      label='Paciente'
                                                      placeholder='Paciente'
                                                      InputProps={{
                                                          ...params.InputProps,
                                                          endAdornment: (
                                                              <>
                                                                  {loading
                                                                      ? 'carregando...'
                                                                      : null}
                                                                  {
                                                                      params
                                                                          .InputProps
                                                                          .endAdornment
                                                                  }
                                                              </>
                                                          ),
                                                      }}
                                                  />
                                              )}
                                              isOptionEqualToValue={(option, value) =>
                                                  option.id === value.id
                                              }
                                          /> */}

                                            <JumboSearch
                                                onChange={(e) => setSearchTerm(e)}
                                                placeholder="Busque por Nome, CPF ou E-mail."
                                                sx={{
                                                    width: '440px',
                                                    marginBottom: '1rem'
                                                }}
                                                onFocus={handleInputFocus} 
                                                onBlur={handleInputBlur}
                                            />

                                          {
                                              (addProfiles != null && addProfiles.length > 0) && <ListRowProfile data={addProfiles} updateProfileList={(data) => updateProfileList(data)} profileUser={profiles.users}/>
                                          }
                                          {
                                              (addProfiles != null && addProfiles.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                                          }

                                            <Typography variant={"h4"} mb={1} sx={{ marginTop: '2rem' }}>Lista de usuarios com este perfil</Typography>

                                            <MUIDataTable
                                                data={profiles.users}
                                                columns={columns}
                                                options={options}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <LoadingButton
                                          color="success"
                                          type="submit"
                                          variant="contained"
                                          size="large"
                                          sx={{
                                            maxWidth: {
                                              md: "150px",
                                              mt: "1rem",
                                            },
                                            marginTop: 2
                                          }}
                                          /* disabled */
                                          loading={isSubmitting}
                                        >
                                          {"Salvar"}
                                        </LoadingButton>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default ProfileForm