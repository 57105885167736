import {
    Box,
    Button,
    Card,
    IconButton,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

export const RegistrationBoardItem = ({
    registration,
    handleEditRegistrationBoard,
    modalAlert,
}) => {
    return (
        <Card sx={{ mb: 2, padding: 2, borderRadius: 3 }} elevation={0}>
            <Box display='flex' justifyContent='space-between'>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Conselhor Regional
                    </Typography>

                    <Typography mt={1}>
                        {registration?.conselho_regional}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Registro
                    </Typography>

                    <Typography mt={1}>{registration?.registro}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Estado
                    </Typography>

                    <Typography mt={1}>{registration?.uf}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Colaborador
                    </Typography>

                    <Typography mt={1}>{registration.User?.nome}</Typography>
                </Box>

                <Box>
                    <Typography
                        fontSize={'12px'}
                        variant={'h6'}
                        color={'text.secondary'}
                        mb={0.25}
                    >
                        Ação
                    </Typography>
                    <Box display={'flex'} gap={1}>
                        <Tooltip title='Editar' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='editar'
                                color='info'
                                onClick={() =>
                                    handleEditRegistrationBoard(registration)
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title='Remover' TransitionComponent={Zoom}>
                            <IconButton
                                aria-label='remover'
                                color='error'
                                onClick={() => modalAlert(registration.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};
