import React, { useState } from "react";
import Typography from "@mui/material/Typography";

import { Box, Button, Tab, Tabs } from "@mui/material";
import FoodAnamnesisForm from "./anamnesis/FoodAnamnesisForm";
import { AnamnesisForm } from "./anamnesis/AnamnesisForm";
import AnamnesisExtensionForm from "./anamnesis/AnamnesisExtensionForm";
import SendIcon from "@mui/icons-material/Send";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import ChildrensAnamnesisForm from "./anamnesis/ChildrensAnamnesisForm";
import TeensAnamnesisForm from "./anamnesis/TeensAnamnesisForm";

const Anamnesis = ({
  values,
  infos,
  setFieldValue,
  anamnesis,
  setAnamnesis,
  foodAnamnesis,
  setFoodAnamnesis,
  childrensAnamnesis,
  setChildrensAnamnesis,
  phono,
  setPhono,
}) => {
  const { id, userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [nestedTab, setNestedTab] = React.useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [phonoSelected, setPhonoSelected] = useState(null);
  const [anamnesisSelected, setAnamnesisSelected] = useState(null);

  const handleNestedTabChange = (event, newValue) => {
    setNestedTab(newValue);
  };

  const resetValuesForAnamnesis = {
    // Limpa o estado do anamnese
    queixa: "",
    parecer: "",
    data: "",
    Gestacao: [
      {
        condicoes_saude_mae_gravidez: "",
        queda_ou_susto_forte: "",
        condicoes_emocionais_mae: "",
        idade_gestacional: null,
        peso_nascimento: null,
        comprimento_nascimento: null,
        tipo_parto: "",
        tipo_parto_obs: "",
        idade_andar: null,
        idade_deixar_fraldas: null,
        data_problema_percebido: "",
        procurou_especialistas: false,
        faz_tratamento: false,
        acompanhamento_psicologico_SUS: false,
      },
    ],
    Sono: [
      {
        tranquilo: false,
        inquieto: false,
        agitado: false,
        refere_pesadelos: false,
        acorda_varias_vezes: false,
        horario_de_dormir: "",
        dorme_sozinho: "",
      },
    ],
    Saude: [
      {
        sofreu_acidente: false,
        submeteu_cirurgia: false,
        possui_alergias: false,
        bronquite_ou_asma: false,
        problema_visao: false,
        problema_audicao: false,
        dor_cabeca: false,
        desmaiou_alguma_vez: false,
        teve_ou_tem_convulsoes: false,
        problema_desmaio_convulsoes_familia_apresenta: false,
      },
    ],
    Alimentacao: [
      {
        paciente_amamentado: false,
        alimenta_se_bem: false,
        toma_cafe_da_manha: false,
        faz_lanche_manha: false,
        almoco: false,
        faz_lanche_tarde: false,
        jantar: false,
        faz_lanche_noite: false,
        leva_lanche_escola: false,
      },
    ],
    Desenvolvimento: [
      {
        tranquilidade: false,
        agressividade: false,
        amorosa: false, //
        quietude: false,
        fechada: false,
        passividade: false,
        medrosa: false,
        desligada: false,
        reacao_contrariedade: "",
        atividades_preferidas: "",
        brinca_carrinho_boneca: false,
        aceita_dividir_brinquedos: false,
        gosta_escola: false,
        repetiu_serie_escola: false,
        gosta_estudar: false,
        horario_estudo: false,
        pais_ajudam_estudo: false,
      },
    ],
  };

  const resetValuesForAnamnesisExtension = {
    queixa: "",
    data: "",
    Historico_Maternal_Gestacional: [
      {
        gravidez: "",
        tabagismo: false,
        etilismo: false,
        intercorrencias_pre_natais: false,
        qual_intercorrencias_pre_natais: "",
        consanguinidade: false,
        grau_de_parentesco: "",
        antecedentes_familiares: false,
        observacoes: "",
      },
    ],
    Parto_Periodo_Neonatal: [
      {
        idade_gestacional_semanas: "",
        idade_gestacional_dias: "",
        prematuridade: "", // pre-termo
        tipo_de_parto: "",
        adequacao_de_peso: "", // aig, cic, pig
        apgar_1: "",
        apgar_2: "",
        apgar_3: "",
        intercorrencias_peri_pos_natais_internacao: false,
        tempo_peri_pos_natais_internacao: "",
        amamentou: false,
        tempo_amamentou: "",
        dificuldades_na_amamentacao: false,
        quais_dificuldades_na_amamentacao: "",
        fez_uso_de_mamadeira: false,
        tempo_uso_de_mamadeira: "",
        fez_uso_de_chupeta: false,
        tempo_uso_de_chupeta: "",
        habitos_orais_deleterios: false,
        tempo_orais_deleterios: "",
        observacao: "",
      },
    ],
    Habito_Sono: [
      {
        dorme_bem: false,
        dorme_de_luz_acesa: false,
        baba_no_travesseiro: false,
        ronca: false,
        sono_agitado: false,
        dorme_com_boca_aberta: false,
      },
    ],
    Desenvolvimento_Neuropsicomotor: [
      {
        sustentar_a_cabeca: false,
        quando_sustentar_a_cabeca: "",
        sentar_sem_apoio: false,
        quando_sentar_sem_apoio: "",
        engatinhar: false,
        quando_engatinhar: "",
        andar: false,
        quando_andar: "",
        controle_de_esfincter: false,
        quando_controle_de_esfincter: "",
        vocalizacoes: false,
        quando_vocalizacoes: "",
        balbuciar: false,
        quando_balbuciar: "",
        falar: false,
        quando_falar: "",
        formar_primeiras_frases: false,
        quando_formar_primeiras_frases: "",
      },
    ],
    Percepcao_Visual_Auditiva: [
      {
        identifica_cores: false,
        acuidade_visual: "",
        outra_acuidade_visual: "",
        uso_lentes_corretivas: "",
        reacao_luzes: "",
        percebe_sons: false,
        discrimina_sons: false,
        compreende_executa_comandos: false,
        latencia_compreensao_execucao: false,
        realizou_exames_auditivos: false,
        resultados_exames_auditivos: "",
        uso_aparelho_de_amplificacao_sonora_individual: "",
      },
    ],
    Acompanhamento_Especializado: [
      {
        realizou_ou_realiza: false,
        local_realizou_ou_realiza: "",
        ido_ao_dentista: false,
        ultima_ida: "",
        desde_quando: "",
        fonoaudiologia: false,
        terapia_ocupacional: false,
        psicologia: false,
        observacoes: "",
        condutas: "",
        encaminhamentos: "",
      },
    ],
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        marginBottom={3}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          value={nestedTab}
          onChange={handleNestedTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Anamnese" />
          <Tab label="Anamnese Alimentar" />
          <Tab label="Anamnese Infantil" />
          <Tab label="Anamnese Adolescente" />
        </Tabs>
      </Box>

      {nestedTab === 0 && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          alignItems={"start"}
        >
          <AnamnesisForm
            values={values}
            infos={infos}
            setFieldValue={setFieldValue}
            anamnesis={anamnesis}
            setAnamnesis={setAnamnesis}
            phono={phono}
            setPhono={setPhono}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            anamnesisSelected={anamnesisSelected}
            setAnamnesisSelected={setAnamnesisSelected}
            resetValuesForAnamnesis={resetValuesForAnamnesis}
            resetValuesForAnamnesisExtension={resetValuesForAnamnesisExtension}
          />
          <AnamnesisExtensionForm
            values={values}
            infos={infos}
            setFieldValue={setFieldValue}
            phono={phono}
            setPhono={setPhono}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            phonoSelected={phonoSelected}
            setPhonoSelected={setPhonoSelected}
          />
          <Button
            variant="outlined"
            endIcon={<SendIcon />}
            onClick={() => {
              if (isEditMode) {
                const phonoId = phono.id;

                const anamnesisId = anamnesis.id;
                const userPhonoEdit = {
                  ...phono,

                  Historico_Maternal_Gestacional: [
                    {
                      ...phono?.Historico_Maternal_Gestacional[0],
                      fono_id: undefined,
                    },
                  ],
                  Parto_Periodo_Neonatal: [
                    {
                      ...phono?.Parto_Periodo_Neonatal[0],
                      fono_id: undefined,
                      idade_gestacional_semanas: Number(
                        phono?.Parto_Periodo_Neonatal[0]
                          .idade_gestacional_semanas
                      ),
                      idade_gestacional_dias: Number(
                        phono?.Parto_Periodo_Neonatal[0].idade_gestacional_dias
                      ),
                      apgar_1: Number(phono?.Parto_Periodo_Neonatal[0].apgar_1),
                      apgar_2: Number(phono?.Parto_Periodo_Neonatal[0].apgar_2),
                      apgar_3: Number(phono?.Parto_Periodo_Neonatal[0].apgar_3),
                    },
                  ],
                  Habito_Sono: [
                    {
                      ...phono?.Habito_Sono[0],
                      fono_id: undefined,
                    },
                  ],
                  Desenvolvimento_Neuropsicomotor: [
                    {
                      ...phono?.Desenvolvimento_Neuropsicomotor[0],
                      fono_id: undefined,
                    },
                  ],
                  Percepcao_Visual_Auditiva: [
                    {
                      ...phono?.Percepcao_Visual_Auditiva[0],
                      fono_id: undefined,
                    },
                  ],
                  Acompanhamento_Especializado: [
                    {
                      ...phono?.Acompanhamento_Especializado[0],
                      fono_id: undefined,
                    },
                  ],
                };
                const userAnamnesisEdit = {
                  ...anamnesis,
                  Gestacao: {
                    ...anamnesis?.Gestacao[0],
                    idade_gestacional: anamnesis?.Gestacao[0].idade_gestacional
                      ? Number(anamnesis?.Gestacao[0].idade_gestacional)
                      : null,
                    peso_nascimento: anamnesis?.Gestacao[0].peso_nascimento
                      ? Number(anamnesis?.Gestacao[0].peso_nascimento)
                      : null,
                    idade_andar: anamnesis?.Gestacao[0].idade_andar
                      ? Number(anamnesis?.Gestacao[0].idade_andar)
                      : null,
                    idade_deixar_fraldas: anamnesis?.Gestacao[0]
                      .idade_deixar_fraldas
                      ? Number(anamnesis?.Gestacao[0].idade_deixar_fraldas)
                      : null,
                    procurou_especialistas: Boolean(
                      anamnesis.Gestacao[0].procurou_especialistas === "true"
                        ? true
                        : false
                    ),
                    faz_tratamento: Boolean(
                      anamnesis.Gestacao[0].faz_tratamento === "true"
                        ? true
                        : false
                    ),
                    acompanhamento_psicologico_SUS: Boolean(
                      anamnesis.Gestacao[0].acompanhamento_psicologico_SUS ===
                        "true"
                        ? true
                        : false
                    ),
                  },
                  Sono: {
                    ...anamnesis?.Sono[0],
                  },
                  Saude: {
                    ...anamnesis?.Saude[0],
                  },
                  Alimentacao: {
                    ...anamnesis?.Alimentacao[0],
                  },
                  Desenvolvimento: {
                    ...anamnesis?.Desenvolvimento[0],
                  },

                  Anamnese_Extensao: userPhonoEdit,
                };

                ApiService.put(`/anamnesis/${anamnesisId}`, userAnamnesisEdit)
                  .then((response) => {
                    console.log(response.data);
                    enqueueSnackbar("Anamnese atualizada com sucesso!", {
                      variant: "success",
                    });
                    const updatedTodos = values.Anamnese.map((todo) => {
                      if (todo.id === response.data.id) {
                        return response.data; // Retorna a anamnese atualizado
                      }
                      return todo;
                    });
                    setFieldValue("Anamnese", updatedTodos); // Atualiza a lista de anamnese com o endereço atualizado
                    setIsEditMode(false); // Sai do modo de edição
                    setAnamnesis(resetValuesForAnamnesis);
                    setPhono(resetValuesForAnamnesisExtension);
                    setAnamnesisSelected(null);
                  })
                  .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                      enqueueSnackbar(`${errorMessage[0]}`, {
                        variant: "error",
                      });
                    } else {
                      enqueueSnackbar(`${errorMessage}`, {
                        variant: "error",
                      });
                    }
                  });
              } else {
                const userPhono = {
                  user_id: Number(userId),
                  queixa: phono?.queixa,
                  data: phono.data,
                  Historico_Maternal_Gestacional: [
                    {
                      ...phono.Historico_Maternal_Gestacional[0],
                    },
                  ],
                  Parto_Periodo_Neonatal: [
                    {
                      ...phono.Parto_Periodo_Neonatal[0],
                      idade_gestacional_semanas: Number(
                        phono.Parto_Periodo_Neonatal[0]
                          .idade_gestacional_semanas
                      ),
                      idade_gestacional_dias: Number(
                        phono.Parto_Periodo_Neonatal[0].idade_gestacional_dias
                      ),
                      apgar_1: Number(phono.Parto_Periodo_Neonatal[0].apgar_1),
                      apgar_2: Number(phono.Parto_Periodo_Neonatal[0].apgar_2),
                      apgar_3: Number(phono.Parto_Periodo_Neonatal[0].apgar_3),
                    },
                  ],
                  Habito_Sono: [
                    {
                      ...phono.Habito_Sono[0],
                    },
                  ],
                  Desenvolvimento_Neuropsicomotor: [
                    {
                      ...phono.Desenvolvimento_Neuropsicomotor[0],
                    },
                  ],
                  Percepcao_Visual_Auditiva: [
                    {
                      ...phono.Percepcao_Visual_Auditiva[0],
                    },
                  ],
                  Acompanhamento_Especializado: [
                    {
                      ...phono.Acompanhamento_Especializado[0],
                    },
                  ],
                };
                const userAnamnesis = {
                  user_id: Number(userId),
                  queixa: anamnesis.queixa,
                  parecer: anamnesis.parecer,
                  data: anamnesis.data,
                  Gestacao: [
                    {
                      ...anamnesis.Gestacao[0],

                      idade_gestacional: anamnesis?.Gestacao[0]
                        .idade_gestacional
                        ? Number(anamnesis?.Gestacao[0].idade_gestacional)
                        : null,
                      peso_nascimento: anamnesis?.Gestacao[0].peso_nascimento
                        ? Number(anamnesis?.Gestacao[0].peso_nascimento)
                        : null,
                      idade_andar: anamnesis?.Gestacao[0].idade_andar
                        ? Number(anamnesis?.Gestacao[0].idade_andar)
                        : null,
                      idade_deixar_fraldas: anamnesis?.Gestacao[0]
                        .idade_deixar_fraldas
                        ? Number(anamnesis?.Gestacao[0].idade_deixar_fraldas)
                        : null,

                      procurou_especialistas: Boolean(
                        anamnesis.Gestacao[0].procurou_especialistas === "true"
                          ? true
                          : false
                      ),
                      faz_tratamento: Boolean(
                        anamnesis.Gestacao[0].faz_tratamento === "true"
                          ? true
                          : false
                      ),
                      acompanhamento_psicologico_SUS: Boolean(
                        anamnesis.Gestacao[0].acompanhamento_psicologico_SUS ===
                          "true"
                          ? true
                          : false
                      ),
                    },
                  ],
                  Sono: [
                    {
                      ...anamnesis.Sono[0],
                    },
                  ],
                  Saude: [
                    {
                      ...anamnesis.Saude[0],
                    },
                  ],
                  Alimentacao: [
                    {
                      ...anamnesis.Alimentacao[0],
                    },
                  ],
                  Desenvolvimento: [
                    {
                      ...anamnesis.Desenvolvimento[0],
                    },
                  ],
                  Anamnese_Extensao: userPhono,
                };

                ApiService.post(`/anamnesis`, userAnamnesis)
                  .then((response) => {
                    console.log(response);
                    const newAnamnese = [...values.Anamnese, response.data];
                    enqueueSnackbar("Anamnese criada com sucesso!", {
                      variant: "success",
                    });
                    setFieldValue("Anamnese", newAnamnese);
                    setAnamnesis(resetValuesForAnamnesis);
                    setPhono(resetValuesForAnamnesisExtension);
                    setAnamnesisSelected(null);
                  })
                  .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                      enqueueSnackbar(`${errorMessage[0]}`, {
                        variant: "error",
                      });
                    } else {
                      enqueueSnackbar(`${errorMessage}`, {
                        variant: "error",
                      });
                    }
                  });
              }
            }}
          >
            Salvar
          </Button>
        </Box>
      )}
      {nestedTab === 1 && (
        <FoodAnamnesisForm
          values={values}
          infos={infos}
          setFieldValue={setFieldValue}
          foodAnamnesis={foodAnamnesis}
          setFoodAnamnesis={setFoodAnamnesis}
        />
      )}
      {nestedTab === 2 && <ChildrensAnamnesisForm nestedTab={nestedTab} />}
      {nestedTab === 3 && <TeensAnamnesisForm nestedTab={nestedTab} />}
    </>
  );
};

export default Anamnesis;
