import React, { useCallback, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSnackbar } from 'notistack';
import ApiService from 'app/services/config';
import { useParams } from 'react-router-dom';
import { AnamnesisItem } from 'app/components/AnamnesisItem/AnamnesisItem';
import { FieldArray } from 'formik';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

export const AnamnesisForm = ({
    values,
    infos,
    setFieldValue,
    anamnesis,
    setAnamnesis,
    phono,
    setPhono,
    isEditMode,
    setIsEditMode,
    anamnesisSelected,
    setAnamnesisSelected,
    resetValuesForAnamnesis,
    resetValuesForAnamnesisExtension
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const Swal = useSwalWrapper();

    const [expanded, setExpanded] = useState(false);
    /* const [isEditMode, setIsEditMode] = useState(false); */
    /* const [anamnesisSelected, setAnamnesisSelected] = useState(null); */

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        console.log(newExpanded);
        setExpanded(newExpanded ? panel : false);
    };

    const handleEditAnamnesis = (anamnesisToEdit) => {
        console.log(anamnesisToEdit);
        setAnamnesis(anamnesisToEdit);
        setPhono(anamnesisToEdit.Anamnese_Extensao[0])
        setIsEditMode(true);
    };

    console.log(anamnesis);

    const modalAlert = (anamnesisId) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: 'Não será póssível reverter a ação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                console.log('bem bem antes', values.Telefones);
                removeAnamnesis(anamnesisId);
            }
        });
    };

    const removeAnamnesis = useCallback(
        async (anamnesisId) => {
            ApiService.delete(`/anamnesis/${anamnesisId}`)
                .then((response) => {
                    const anamnesisEdit = anamnesis.id === anamnesisId;
                    const removedAnamnesis = values.Anamnese.filter(
                        (item) => item.id !== anamnesisId
                    );
                    setFieldValue('Anamnese', removedAnamnesis);
                    enqueueSnackbar('Anamnese removida com sucesso!', {
                        variant: 'success',
                        
                    });
                    setAnamnesisSelected(null);
                    if (anamnesisEdit) {
                        setIsEditMode(false);
                        setAnamnesis(resetValuesForAnamnesis);
                        setPhono(resetValuesForAnamnesisExtension);
                        setAnamnesisSelected(null);
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    console.log(errorMessage);
                    if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                            variant: 'error',
                            
                        });
                    } else {
                        enqueueSnackbar(`${errorMessage}`, {
                            variant: 'error',
                            
                        });
                    }
                });
        },
        [
            enqueueSnackbar,
            setFieldValue,
            values.Anamnese,
            anamnesis,
            setAnamnesis,
        ]
    );

    return (
        <>
            <Grid container rowSpacing={2}>
                {/* Queixa */}
                <Grid item xs={12}>
                    <AnamnesisItem
                        anamnesis={values.Anamnese}
                        handleEditAnamnesis={handleEditAnamnesis}
                        modalAlert={modalAlert}
                        anamnesisSelected={anamnesisSelected}
                        setAnamnesisSelected={setAnamnesisSelected}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded === 'panel7'}
                        onChange={handleAccordionChange('panel7')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel7-content'
                            id='panel7-header'
                        >
                            <Typography>Queixa</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid></Grid>
                            <TextField
                                fullWidth
                                name='Anamnese.queixa'
                                label='Queixa do Paciente'
                                multiline
                                rows={4}
                                value={anamnesis?.queixa}
                                onChange={(event) => {
                                    setAnamnesis({
                                        ...anamnesis,
                                        queixa: event.target.value,
                                    });
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Gestação */}
                <Grid item xs={12}>
                    <Accordion
                        expanded={
                            expanded === 'panel1' /* ||
                            anamnesis?.Gestacao?.length > 0 */
                        }
                        onChange={handleAccordionChange('panel1')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                ></span>
                                Gestação
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Quais as condicoes de saude da mae durante a gravidez?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.condicoes_saude_mae_gravidez
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        condicoes_saude_mae_gravidez:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Levou alguma queda ou susto forte?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.queda_ou_susto_forte
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        queda_ou_susto_forte:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Quais as condicoes emocionais da mae durante a gravidez?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.condicoes_emocionais_mae
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        condicoes_emocionais_mae:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='O paciente nasceu com quantos meses?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.idade_gestacional
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        idade_gestacional:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Nasceu com quantos quilos?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.peso_nascimento
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        peso_nascimento:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Qual o comprimento no nascimento?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.comprimento_nascimento
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        comprimento_nascimento:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Andou com que idade?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]?.idade_andar
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        idade_andar:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Deixou de usar fraldas com que idade?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.idade_deixar_fraldas
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        idade_deixar_fraldas:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Desde quando percebeu esse problema?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Gestacao[0]
                                                ?.data_problema_percebido
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Gestacao: [
                                                    {
                                                        ...anamnesis
                                                            .Gestacao[0],
                                                        data_problema_percebido:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Qual tipo de parto?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Gestacao[0]
                                                    ?.tipo_parto
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Gestacao: [
                                                        {
                                                            ...anamnesis
                                                                .Gestacao[0],
                                                            tipo_parto:
                                                                event.target
                                                                    .value,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value='normal'
                                                control={<Radio />}
                                                label='Normal'
                                            />
                                            <FormControlLabel
                                                value='cesariana'
                                                control={<Radio />}
                                                label='Cesariana'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Já procuraram especialistas?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Gestacao[0]
                                                    ?.procurou_especialistas
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Gestacao: [
                                                        {
                                                            ...anamnesis
                                                                .Gestacao[0],
                                                            procurou_especialistas:
                                                                event.target
                                                                    .value,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            O paciente está fazendo algum tipo
                                            de tratamento?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Gestacao[0]
                                                    ?.faz_tratamento
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Gestacao: [
                                                        {
                                                            ...anamnesis
                                                                .Gestacao[0],
                                                            faz_tratamento:
                                                                event.target
                                                                    .value,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Faz acompanhamento com Psicologo do
                                            SUS?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Gestacao[0]
                                                    ?.acompanhamento_psicologico_SUS
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Gestacao: [
                                                        {
                                                            ...anamnesis
                                                                .Gestacao[0],
                                                            acompanhamento_psicologico_SUS:
                                                                event.target
                                                                    .value,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Sono */}
                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded === 'panel2'}
                        onChange={handleAccordionChange('panel2')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                ></span>
                                Sono
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} md={12}>
                                    <FormControl>
                                        <FormLabel component='legend'>
                                            Tipo de Sono
                                        </FormLabel>
                                        <FormGroup aria-label='position' row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis?.Sono[0]
                                                                ?.tranquilo
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Sono: [
                                                                    {
                                                                        ...anamnesis
                                                                            .Sono[0],
                                                                        tranquilo:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Tranquilo'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis?.Sono[0]
                                                                ?.inquieto
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Sono: [
                                                                    {
                                                                        ...anamnesis
                                                                            .Sono[0],
                                                                        inquieto:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Inquieto'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis?.Sono[0]
                                                                ?.agitado
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Sono: [
                                                                    {
                                                                        ...anamnesis
                                                                            .Sono[0],
                                                                        agitado:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Agitado'
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis?.Sono[0]
                                                                ?.refere_pesadelos
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Sono: [
                                                                    {
                                                                        ...anamnesis
                                                                            .Sono[0],
                                                                        refere_pesadelos:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Refere pesadelos'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis?.Sono[0]
                                                                ?.acorda_varias_vezes
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Sono: [
                                                                    {
                                                                        ...anamnesis
                                                                            .Sono[0],
                                                                        acorda_varias_vezes:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Acorda várias vezes'
                                                labelPlacement='start'
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Qual o horario de dormir?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Sono[0]
                                                ?.horario_de_dormir
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Sono: [
                                                    {
                                                        ...anamnesis.Sono[0],
                                                        horario_de_dormir:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Dorme Sozinho ou Com Alguem?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Sono[0]
                                                    ?.dorme_sozinho
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Sono: [
                                                        {
                                                            ...anamnesis
                                                                .Sono[0],
                                                            dorme_sozinho:
                                                                event.target
                                                                    .value,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value='sozinho'
                                                control={<Radio />}
                                                label='Sozinho'
                                            />
                                            <FormControlLabel
                                                value='com_alguem'
                                                control={<Radio />}
                                                label='Com Alguem'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Saúde */}
                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded === 'panel3'}
                        onChange={handleAccordionChange('panel3')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                ></span>
                                Saúde
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            A criança sofreu algum acidente?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.sofreu_acidente
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            sofreu_acidente:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Submeteu-se a alguma cirurgia?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.submeteu_cirurgia
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            submeteu_cirurgia:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Possui alergias?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.possui_alergias
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            possui_alergias:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Tem Bronquite ou Asma?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.bronquite_ou_asma
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            bronquite_ou_asma:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Apresenta algum problema de Visao?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.problema_visao
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            problema_visao:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Apresenta algum problema de Audiçao?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.problema_audicao
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            problema_audicao:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Apresenta dor de cabeça?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]?.dor_cabeca
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            dor_cabeca:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Já desmaiou alguma vez?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.desmaiou_alguma_vez
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            desmaiou_alguma_vez:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Teve ou tem convulsoes?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.teve_ou_tem_convulsoes
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            teve_ou_tem_convulsoes:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Familia apresenta algum problema de
                                            Desmaios, Convusoes ou Ataques?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Saude[0]
                                                    ?.problema_desmaio_convulsoes_familia_apresenta
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Saude: [
                                                        {
                                                            ...anamnesis
                                                                .Saude[0],
                                                            problema_desmaio_convulsoes_familia_apresenta:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Alimentação */}
                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded === 'panel4'}
                        onChange={handleAccordionChange('panel4')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                ></span>
                                Alimentação
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            O paciente foi amamentado?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.paciente_amamentado
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            paciente_amamentado:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Alimenta-se bem?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.alimenta_se_bem
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            alimenta_se_bem:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Toma cafe da Manha?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.toma_cafe_da_manha
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            toma_cafe_da_manha:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Faz algum lanche pela manha?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.faz_lanche_manha
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            faz_lanche_manha:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Almoço
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.almoco
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            almoco: JSON.parse(
                                                                event.target
                                                                    .value
                                                            ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Faz algum lanche pela tarde?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.faz_lanche_tarde
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            faz_lanche_tarde:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Jantar
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.jantar
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            jantar: JSON.parse(
                                                                event.target
                                                                    .value
                                                            ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Faz algum lanche pela Noite?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.faz_lanche_noite
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            faz_lanche_noite:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Leva lanche para escola?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Alimentacao[0]
                                                    ?.leva_lanche_escola
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Alimentacao: [
                                                        {
                                                            ...anamnesis
                                                                .Alimentacao[0],
                                                            leva_lanche_escola:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Desenvolvimento */}
                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded === 'panel5'}
                        onChange={handleAccordionChange('panel5')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                ></span>
                                Desenvolvimento
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel component='legend'>
                                            Como é o relacionamento da criança
                                            com os pais
                                        </FormLabel>
                                        <FormGroup aria-label='position' row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.tranquilidade
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            tranquilidade:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Tranquila'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.agressividade
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            agressividade:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Agressiva'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.amorosa
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            amorosa:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Amorosa'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.quietude
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            quietude:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Muito quieta'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.fechada
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            fechada:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Fechada'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.passividade
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            passividade:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Passiva'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.medrosa
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            medrosa:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Medrosa'
                                                labelPlacement='start'
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            anamnesis
                                                                ?.Desenvolvimento[0]
                                                                ?.desligada
                                                        }
                                                        onChange={(event) => {
                                                            setAnamnesis({
                                                                ...anamnesis,
                                                                Desenvolvimento:
                                                                    [
                                                                        {
                                                                            ...anamnesis
                                                                                .Desenvolvimento[0],
                                                                            desligada:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        },
                                                                    ],
                                                            });
                                                        }}
                                                    />
                                                }
                                                label='Desligada'
                                                labelPlacement='start'
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Como reage quando é contrariado?'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Desenvolvimento[0]
                                                ?.reacao_contrariedade
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Desenvolvimento: [
                                                    {
                                                        ...anamnesis
                                                            .Desenvolvimento[0],
                                                        reacao_contrariedade:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id='outlined-basic'
                                        label='Atividades Preferidas'
                                        variant='outlined'
                                        value={
                                            anamnesis?.Desenvolvimento[0]
                                                ?.atividades_preferidas
                                        }
                                        onChange={(event) => {
                                            setAnamnesis({
                                                ...anamnesis,
                                                Desenvolvimento: [
                                                    {
                                                        ...anamnesis
                                                            .Desenvolvimento[0],
                                                        atividades_preferidas:
                                                            event.target.value,
                                                    },
                                                ],
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Brinca com Carrinho / Boneca?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.brinca_carrinho_boneca
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            brinca_carrinho_boneca:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Aceita dividir brinquedos?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.aceita_dividir_brinquedos
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            aceita_dividir_brinquedos:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Gosta de ir a escola?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.gosta_escola
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            gosta_escola:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Repetiu alguma seria na escola?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.repetiu_serie_escola
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            repetiu_serie_escola:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Gosta de estudar?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.gosta_estudar
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            gosta_estudar:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Tem horário de estudo?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.horario_estudo
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            horario_estudo:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                            Os pais ajudam nas atividades
                                            escolares?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby='demo-row-radio-buttons-group-label'
                                            name='row-radio-buttons-group'
                                            value={
                                                anamnesis?.Desenvolvimento[0]
                                                    ?.pais_ajudam_estudo
                                            }
                                            onChange={(event) => {
                                                setAnamnesis({
                                                    ...anamnesis,
                                                    Desenvolvimento: [
                                                        {
                                                            ...anamnesis
                                                                .Desenvolvimento[0],
                                                            pais_ajudam_estudo:
                                                                JSON.parse(
                                                                    event.target
                                                                        .value
                                                                ),
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label='Sim'
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label='Não'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Conclusão */}
                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded === 'panel6'}
                        onChange={handleAccordionChange('panel6')}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                        >
                            <Typography>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        paddingRight: '10px',
                                    }}
                                ></span>
                                Conclusão
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={3}
                            >
                                <TextField
                                    fullWidth
                                    label='Parecer'
                                    multiline
                                    rows={4}
                                    value={anamnesis?.parecer}
                                    onChange={(event) => {
                                        setAnamnesis({
                                            ...anamnesis,
                                            parecer: event.target.value,
                                        });
                                    }}
                                />

                                <TextField
                                    id='standard-basic'
                                    label='Data'
                                    variant='outlined'
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    value={anamnesis?.data}
                                    onChange={(event) => {
                                        setAnamnesis({
                                            ...anamnesis,
                                            data: event.target.value,
                                        });
                                    }}
                                    sx={{ width: '23%' }}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* <Grid item xs={12}>
                    <Divider>Registro</Divider>
                </Grid>

                <Grid item xs={12}>
                    <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <Box display={'flex'} gap={19}>
                            <Box>
                                <Typography variant='h4'>Código</Typography>
                                <Typography variant='h5'>123456</Typography>
                            </Box>
                            <Box>
                                <Typography variant='h4'>Paciente</Typography>
                                <Typography variant='h5'>Juan Claro</Typography>
                            </Box>
                        </Box>
                        <Box
                            display={'flex'}
                            gap={3}
                            justifyContent={'space-between'}
                        >
                            <Box>
                                <Typography variant='h4'>
                                    Data de Inclusão
                                </Typography>
                                <Typography variant='h5'>
                                    11/03/2024 13:55:20
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h4'>
                                    Colaborador de Inclusão
                                </Typography>
                                <Typography variant='h5'>
                                    Tharsila Borges
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h4'>
                                    Data de Alteração
                                </Typography>
                                <Typography variant='h5'>
                                    11/03/2024 13:55:20
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h4'>
                                    Colaborador de Alteração
                                </Typography>
                                <Typography variant='h5'>
                                    Tharsila Borges
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid> */}
            </Grid>
        </>
    );
};
