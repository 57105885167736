
import { Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import React, { useCallback, useContext, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApiService from "app/services/config";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { PermissionContext } from "app/contexts/PermissionContext";

  const ProfileList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const Swal = useSwalWrapper();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams()
    const initialValues = {
      nome: "",
    }
    const [roles, setRoles] = useState([]);
    const [profiles, setProfiles] = useState(initialValues);
    const [screens, setScreens] = useState([]);
    const [profilesScreens, setProfilesScreens] = useState([]);
    const { hasPermission } = useContext(PermissionContext);

    const getRoles = React.useCallback(async () => {
        try {
            await ApiService.get(`/roles`)
                .then((response) => {
                  setRoles(response.data)
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }, []);

    const modalAlert = (id) => {
      Swal.fire({
        title: `Tem certeza que deseja excluir o perfil?`,
        text: `Você não poderá reverter a ação!`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonText: `Sim!`,
        cancelButtonText: `Não!`,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          return handleDeleteRole(id);
        }
      });
    };

    const handleDeleteRole = async (id) => {
      try {
        await ApiService.delete(`/roles/${id}`).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Excluído com sucesso!", {
              variant: "success",
              
            });
            getRoles();
            // setForceUpdate(!forceUpdate);
          }
        });
      } catch (error) {
        enqueueSnackbar("Ocorreu um erro!", {
          variant: "error",
          
        });
        console.error(error);
      }
    };

    const getProfileById = useCallback(async () => {
      try {
          ApiService.get(`/perfil/${id}`)
          .then((response) => {
              console.log(response.data);
              setProfiles(response.data.perfil);
            if(response.data.userPerfil.length > 0){
              setProfilesScreens(response.data.profileScreenMarked)
          }

          })
          .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('error', error.message);
              }
          })
      } catch (err) {
        console.log(err);
      }
  }, []);

  const getScreens = useCallback(async () => {
    try {
        ApiService.get(`/telas`)
        .then((response) => {
          setScreens(response.data);
        })
        .catch((error) => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
        })
    } catch (err) {
      console.log(err);
    }
}, []);
  
    React.useEffect(() => {
      if(id) {
          getProfileById();
      };
      getScreens();
      getRoles();
    }, []);

    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          display: false,
        },
      },
      {
        name: "name",
        label: "Nome",
        options: {
          sort: true,
          filter: true,
        },
      },
      {
        name: "acoes",
        label: "Ações",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const perfilId = tableMeta.rowData[0];

            return (
              <Stack direction="row" gap={2}>
                <Button
                  onClick={() => navigate(`/role/edit/${perfilId}`)}
                  disabled={!hasPermission('Perfis', 'update')}
                  style={{ marginLeft: '5px', minWidth: 'auto' }}>
                    <VisibilityIcon />
                </Button>

                <Button
                  onClick={() => modalAlert(perfilId)}
                  disabled={!hasPermission('Perfis', 'delete')}
                  style={{ marginLeft: '5px', minWidth: 'auto' }}>
                    <DeleteIcon />
                </Button>
              </Stack>
            );
          },
        },
      },
    ];
  
    const options = {
      // changeRowsPerPage: perPage,
      filterType: "dropdown",
      filter: false,
      selectableRows: "none",
      searchAlwaysOpen: true,
      searchable: true,
      serverSide: true,
      // page: page,
      // count: count,
      selectToolbarPlacement: "above",
  
      // onTableChange: (action, tableState) => {
      //   console.log(action);
      //   switch (action) {
      //     case "changePage":
      //       setPage(tableState.page);
      //       break;
      //     case "sort":
      //       setSortBy(tableState.sortOrder.name);
      //       setSortOrder(tableState.sortOrder.direction);
      //       break;
      //     case "search":
      //       handleSearch(tableState.searchText);
      //       break;
      //     case "changeRowsPerPage":
      //       setPerPage(tableState.rowsPerPage);
      //       break;
      //     default:
      //       console.log("action not handled.");
      //   }
      // },
      textLabels: {
        body: {
          noMatch: "Nenhum registro encontrado",
          toolTip: "Ordenar",
        },
        pagination: {
          next: "Próxima Página",
          previous: "Página Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Pesquisar",
          downloadCsv: "Download CSV",
          print: "Imprimir",
          viewColumns: "Ver Colunas",
          filterTable: "Filtrar Tabela",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESETAR",
        },
        viewColumns: {
          title: "Mostrar Colunas",
          titleAria: "Mostrar/Esconder Colunas da Tabela",
        },
        selectedRows: {
          text: "linha(s) selecionada(s)",
          delete: "Excluir",
          deleteAria: "Excluir linhas selecionadas",
        },
      },
    };

    console.log('roles',roles)
    return(
      <div>
            <Grid container>
                <HeaderBreadcrumbs
                    title={'Perfis'}
                    titleUrl={'/role'}
                />
            </Grid>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <MUIDataTable
                        data={roles}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
            <br />
            <Link to={!hasPermission('Perfis', 'create') ? '/role' : '/role/create'} style={{ textDecoration: 'none' }}>
                <Button variant='contained' startIcon={<PersonAddIcon />} disabled={!hasPermission('Perfis', 'create')}>
                    Criar
                </Button>
            </Link>
        </div>
    );
}

export default ProfileList