import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback } from "react";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

const todasPerguntas = [
  {
    categoria: "Comunicação receptiva",
    perguntas: [
      "Localiza os sons voltando-se para fonte sonora",
      "Olha para sons vocais lúdicos (vibração da língua com ruído, assobio)",
      "Responde à voz, voltando-se para a pessoa",
      "Olha para as imagens indicadas à medida que o adulto aponta para as imagens de um livro",
      "Segue um ponto proximal para colocar objetos em recipientes, peças de puzzle, etc",
      "Olha quando lhe mostram um objeto e dizem: “...(nome), olha",
      "Olha para o parceiro quando chamado pelo nome",
      "Segue um ponto proximal de um objeto ou localização",
      "Segue um ponto distal para recuperar um brinquedo",
      "Olha, alcança ou sorri em resposta aos gestos e voz dos adultos em jogos sociais",
      "Olha, alcança, sorri e/ou gesticula em resposta à linguagem/gestos dos adultos em canções",
      "Responde interrompendo ações momentaneamente em resposta a palavras inibitórias (p.e., 'Não', 'Para')",
      "Dá o objeto pedido verbalmente quando combinado com a mão estendida de um adulto",
      'Executa uma instrução de rotina de uma etapa envolvendo ações corporais combinadas com pistas verbais/gestuais (p.e.,"Senta", "Vem aqui", "Guarda")',
      "Executa uma instrução verbal de rotina envolvendo ações corporais sem acompanhamento do gesto",
    ],
  },
  {
    categoria: "Comunicação expressiva",
    perguntas: [
      "Usa o alcance dirigido para fazer pedidos",
      "Vocaliza com intenção",
      "Pede ajuda entregando um objeto ao adulto",
      "Toma a vez vocalizando com o parceiro de comunicação",
      "Exprime recusa afastando um objeto ou devolvendo o objeto à outra pessoa",
      "Aponta para um sítio próximo para pedir objetos desejados",
      "Estabelece contato visual para obter um objeto desejado quando o adulto bloqueia o acesso/retém o objeto desejado",
      "Aponta para indicar uma escolha entre dois objetos",
      "Combina a vocalização e o olhar para pedidos intencionais",
      "Aponta para um sítio distante para pedir objetos desejados",
      "Aponta para um lugar distante para indicar uma escolha entre dois objetos",
      "Vocaliza com balbucios reduplicados CVCV (não necessariamente aproximações de palavras)",
      "Produz cinco ou mais consoantes em vocalizações espontâneas",
      "Produz CVCV com sequências CV diferentes (balbucio diversificado)",
    ],
  },
  {
    categoria: "Competências sociais",
    perguntas: [
      "Aceita breves atividades sociais sensoriais e o toque",
      "Usa a ajuda motora para iniciar ou continuar uma rotina social sensorial",
      "Segue brevemente a outra pessoa com contato visual",
      "Mantém o envolvimento em rotinas sociais sensoriais por 2 minutos",
      "Responde a objetos/atividades preferidos com o olhar, alcance, sorrisos e movimentos",
      "Observa e interage com o adulto que a imita durante atividades paralelas com brinquedos",
      "Tem um repertório de 5-10 jogos sociais sensoriais",
      "Responde aos cumprimentos olhando, virando-se, etc",
      "Responde à saudação com gestos ou vocalização",
      "Partilha sorrisos com o parceiro durante um jogo coordenado",
    ],
  },
  {
    categoria: "Imitação",
    perguntas: [
      "Imita 8-10 ações de um esquema com objetos",
      "Imita 10 ações motoras visíveis dentro de uma canção/rotinas de jogo",
      "Imita seis ações motoras invisíveis na cabeça ou face em canções/rotinas de jogo",
      "Imita seis movimentos orofaciais",
    ],
  },
  {
    categoria: "Cognição",
    perguntas: [
      "Corresponde/agrupa objetos idênticos",
      "Corresponde/agrupa imagens idênticas",
      "Corresponde/agrupa imagens e objetos",
      "Corresponde/agrupa objetos pela cor",
    ],
  },
  {
    categoria: "Jogo",
    perguntas: [
      "Adequa o comportamento às qualidades de cinco objetos diferentes (Uso funcional do objeto)",
      "Brinca de forma autônoma e apropriada com 10 brinquedos de uma ação",
      "Brinca de forma autônoma com brinquedos que exigem a repetição da mesma ação em vários objetos (anel empilhador, copos para empilhar)",
      "Demonstra comportamentos de jogo apropriados numa variedade de brinquedos de uma ação: atira a bola, empilha blocos, introduz pinos nos orifícios, rola o carro",
      "Brinca de forma autónoma com brinquedos que requerem duas ações motoras diferentes (tirar, introduzir)",
      "Brinca de forma autônoma com brinquedos que requerem várias ações motoras diferentes (p.e., introduzir, abrir, remover, fechar)",
      "Demonstra ações convencionais em si própria com uma variedade de objetos",
      "Completa a atividade de jogo e a afasta",
    ],
  },
  {
    categoria: "Motricidade fina",
    perguntas: [
      "Coloca uma ou duas peças num jogo de encaixe",
      "Coloca anéis num anel empilhador",
      "Completa um puzzle de três peças de madeira (pelo menos 2 a 3 peças autonomamente)",
      "Introduz pinos num quadro de pinos",
      "Aperta botões em cinco tipos de brinquedos de causa e efeito",
      "Desmancha encaixes de contas, Legos",
      "Usa um movimento de pinça ou a preensão de três dedos conforme o brinquedo",
      "Empilha três grandes blocos numa torre (ou copos para empilhar)",
      "Faz marcas, linhas, rabiscos e pontos com marcadores/lápis de cor",
      "Bate com um martelo de brincar em bolas, pinos, etc",
      "Usa pá, balde, despeja areia, água, arroz, etc",
      "Empilha grandes legos",
    ],
  },
  {
    categoria: "Motricidade grossa",
    perguntas: [
      "Dá pontapés na bola grande",
      "Sobe e desce escadas com ajuda, sem alternar os pés",
      "Sobe um ou dois degraus numa escada pequena para deslizar",
      "Sobe e desce de equipamentos",
      "Protege-se quando fica sem equilíbrio",
      "Contorna os objetos no chão, em vez de os pisar",
      "Atira bolas e saquinhos de feijão em qualquer direção",
      "Rola brinquedos para frente e para trás com outra pessoa",
    ],
  },
  {
    categoria: "Comportamento",
    perguntas: [
      "Exibe dificuldades mínimas de comportamento severo",
      "Senta-se numa cadeira ou de frente para o adulto durante atividades que lhe dão prazer sem dificuldades por 1-2 minutos",
      "Envolve-se voluntariamente em jogos simples na cadeira e no chão com adultos, por 5 minutos",
      "Tolera a proximidade e a interação com o adulto (com pedidos mínimos) sem problemas de comportamento em intervalos de 20 minutos",
      "Interage de forma adequada com membros da família (ou seja, sem agressão ou outras interações inapropriadas)",
    ],
  },
  {
    categoria: "Independência pessoal: alimentação",
    perguntas: [
      "Come refeições e lanches à mesa",
      "Come a refeição de forma autônoma",
      "Usa um copo aberto",
      "Usa uma colher",
      "Usa um garfo",
      "Come alimentos de várias texturas, tipos e grupos",
      "Tolera novos alimentos no prato",
      "Bebe no canudo",
    ],
  },
  {
    categoria: "Independência pessoal: vestir",
    perguntas: [
      "Remove cada peça de roupa com ajuda",
      "Veste cada peça de roupa com ajuda",
    ],
  },
  {
    categoria: "Independência pessoal: higiene",
    perguntas: [
      "Põe as mãos debaixo de água corrente",
      "Seca as mãos com uma toalha",
      "Esfrega a toalha de rosto ou o toalhão pelo corpo",
      "Tolera pentear o cabelo, assoar o nariz e escovar os dentes",
      "Ajuda com a escova de dentes/pente",
      "Põe a escova de dentes na boca",
    ],
  },
  {
    categoria: "Independência pessoal: tarefas",
    perguntas: ["Põe a roupa suja no cesto", "Põe os lenços de papel no lixo"],
  },
];

const InterdisciplinaryOne = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { quizzId, userId } = useParams();
  const location = useLocation();

  const storedColaboratorId = localStorage.getItem("id");

  const [quizzes, setQuizzes] = useState([]);
  const [answers, setAnswers] = useState([]);

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const date = query.get("date");

  const getQuizzes = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}`)
        .then((response) => {
          console.log(response.data?.pergunta);

          setQuizzes(response.data?.pergunta);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [setQuizzes, quizzId]);

  const getQuizzesAnswers = useCallback(async () => {
    try {
      await ApiService.get(`/quizzes/${quizzId}/user/${userId}?date=${date}`)
        .then((response) => {
          console.log(response.data);

          setQuizzes(response.data?.pergunta);

          /* setAnswers(respostasFormatadas); */
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname.includes("edit")) {
      getQuizzesAnswers();
    } else {
      getQuizzes();
    }
  }, [getQuizzes, getQuizzesAnswers, location?.pathname]);

  console.log(answers);

  const getSelectedAnswer = (respostas, perguntaId) => {
    // Verifica se há uma resposta salva no estado answers para a pergunta específica
    const respostaSalva = answers.find(
      (resposta) => resposta.pergunta_id === perguntaId
    );

    // Se houver uma resposta salva, retorna o ID dessa resposta
    if (respostaSalva) {
      return respostaSalva.resposta_id;
    }

    // Se não houver uma resposta salva, verifica se há uma resposta do servidor
    const respostaUsuario = respostas.find(
      (resposta) =>
        resposta?.Resposta_Usuario?.length > 0 &&
        resposta?.Resposta_Usuario[0]?.pergunta_id === perguntaId
    );

    // Se houver uma resposta do servidor, retorna o ID dessa resposta
    if (respostaUsuario) {
      return respostaUsuario.id;
    }

    // Se não houver nenhuma resposta salva ou do servidor, retorna vazio
    return "";
  };

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Aba de Avaliação Interdisciplinar"}
        subtitle={"Nível 1"}
        titleUrl={"/"}
      />
      <Paper
        style={{ padding: "10px", marginBottom: "15px" }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12}>
                {todasPerguntas.map((categoria, index) => (
                  <Accordion defaultExpanded my={2}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography variant={"h3"} fontWeight={500}>{categoria.categoria}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        {categoria.perguntas.map((pergunta, index) => (
                          <Grid item xs={12} key={index}>
                            <Stack
                              direction="row"
                              gap={2}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              width="100%"
                            >
                              <Typography>
                                {index + 1} - {pergunta}
                              </Typography>
                              <Select sx={{ minWidth: "150px" }}>
                                <MenuItem value={"adquirido"}>
                                  Adquirido
                                </MenuItem>
                                <MenuItem value={"parcial"}>Parcial</MenuItem>
                                <MenuItem value={"total"}>Total</MenuItem>
                                <MenuItem value={"nao"}>Não</MenuItem>
                                <MenuItem value={"nao_observado"}>
                                  Não observado
                                </MenuItem>
                              </Select>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Código</TableCell>
                    <TableCell align="center">Inclusão</TableCell>
                    <TableCell align="center"> Colaborador Inclusão </TableCell>
                    <TableCell align="center">Alteração</TableCell>
                    <TableCell align="center">
                      {" "}
                      Colaborador Alteração{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                    <TableCell align="center">placeholder</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider
          style={{
            height: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                if (location?.pathname.includes("edit")) {
                  ApiService.put(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                } else {
                  ApiService.post(
                    `/user-answered/user/${userId}/colaborator/${Number(
                      storedColaboratorId
                    )}`,
                    answers
                  )
                    .then((response) => {
                      enqueueSnackbar("Ficha criada com sucesso!", {
                        variant: "success",
                        
                      });
                    })
                    .catch((error) => {
                      const errorMessage = error.response.data.message;
                      console.log(errorMessage);
                      if (Array.isArray(errorMessage)) {
                        enqueueSnackbar(`${errorMessage[0]}`, {
                          variant: "error",
                          
                        });
                      } else {
                        enqueueSnackbar(`${errorMessage}`, {
                          variant: "error",
                          
                        });
                      }
                    });
                }
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default InterdisciplinaryOne;
