import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { findAllByDisplayValue } from '@testing-library/react';

const AnamnesisExtensionForm = ({
  phono,
  setPhono,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid container rowSpacing={2}>
      {/* História Clínica Pregressa */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleAccordionChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>História Clínica Pregressa</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <TextField
                id='standard-basic'
                label='Data'
                variant='outlined'
                type='date'
                InputLabelProps={{ shrink: true }}
                value={phono?.data}
                onChange={(event) => {
                  setPhono({
                    ...phono,
                    data: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Queixa do Paciente'
                multiline
                rows={4}
                value={phono?.queixa}
                onChange={(event) => {
                  setPhono({
                    ...phono,
                    queixa: event.target.value,
                  });
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* Hístoria Materna e Gestacional */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleAccordionChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              ></span>
              Hístoria Materna e Gestacional
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Gravidez
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Historico_Maternal_Gestacional[0]?.gravidez}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Historico_Maternal_Gestacional: [
                          {
                            ...phono.Historico_Maternal_Gestacional[0],
                            gravidez: event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='desejada'
                      control={<Radio />}
                      label='Desejada'
                    />
                    <FormControlLabel
                      value='planejada'
                      control={<Radio />}
                      label='Planejada'
                    />
                    <FormControlLabel
                      value='aceita'
                      control={<Radio />}
                      label='Aceita'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Etilismo
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Historico_Maternal_Gestacional[0]?.etilismo}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Historico_Maternal_Gestacional: [
                          {
                            ...phono.Historico_Maternal_Gestacional[0],
                            etilismo: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Intercorrencias pré-natais
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Historico_Maternal_Gestacional[0]
                        ?.intercorrencias_pre_natais
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Historico_Maternal_Gestacional: [
                          {
                            ...phono.Historico_Maternal_Gestacional[0],
                            intercorrencias_pre_natais: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Consanguinidade
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Historico_Maternal_Gestacional[0]?.consanguinidade
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Historico_Maternal_Gestacional: [
                          {
                            ...phono.Historico_Maternal_Gestacional[0],
                            consanguinidade: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Antecedentes familiares
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Historico_Maternal_Gestacional[0]
                        ?.antecedentes_familiares
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Historico_Maternal_Gestacional: [
                          {
                            ...phono.Historico_Maternal_Gestacional[0],
                            antecedentes_familiares: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Tabagismo
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Historico_Maternal_Gestacional[0]?.tabagismo}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Historico_Maternal_Gestacional: [
                          {
                            ...phono.Historico_Maternal_Gestacional[0],
                            tabagismo: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Qual intercorrencia?'
                  variant='outlined'
                  value={
                    phono?.Historico_Maternal_Gestacional[0]
                      ?.qual_intercorrencias_pre_natais
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Historico_Maternal_Gestacional: [
                        {
                          ...phono.Historico_Maternal_Gestacional[0],
                          qual_intercorrencias_pre_natais: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              {/* lista de seleçao */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Grau de parentesco'
                  variant='outlined'
                  value={
                    phono?.Historico_Maternal_Gestacional[0]?.grau_de_parentesco
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Historico_Maternal_Gestacional: [
                        {
                          ...phono.Historico_Maternal_Gestacional[0],
                          grau_de_parentesco: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Observações'
                  multiline
                  rows={4}
                  value={phono?.Historico_Maternal_Gestacional[0]?.observacoes}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Historico_Maternal_Gestacional: [
                        {
                          ...phono.Historico_Maternal_Gestacional[0],
                          observacoes: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* Parto e Período Neonatal */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleAccordionChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              ></span>
              Parto e Período Neonatal
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='I.G. (Semanas)'
                  variant='outlined'
                  value={
                    phono?.Parto_Periodo_Neonatal[0]?.idade_gestacional_semanas
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          idade_gestacional_semanas: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='I.G. (Dias)'
                  variant='outlined'
                  value={
                    phono?.Parto_Periodo_Neonatal[0]?.idade_gestacional_dias
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          idade_gestacional_dias: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Apgar 1'
                  variant='outlined'
                  value={phono?.Parto_Periodo_Neonatal[0]?.apgar_1}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          apgar_1: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Apgar 2'
                  variant='outlined'
                  value={phono?.Parto_Periodo_Neonatal[0]?.apgar_2}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          apgar_2: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Apgar 3'
                  variant='outlined'
                  value={phono?.Parto_Periodo_Neonatal[0]?.apgar_3}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          apgar_3: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Tipo de Parto
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Parto_Periodo_Neonatal[0]?.tipo_de_parto}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            tipo_de_parto: event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='normal'
                      control={<Radio />}
                      label='Normal'
                    />
                    <FormControlLabel
                      value='cesariano'
                      control={<Radio />}
                      label='Cesariano'
                    />
                    <FormControlLabel
                      value='forceps'
                      control={<Radio />}
                      label='Fórceps'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Adequacao de Peso
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Parto_Periodo_Neonatal[0]?.adequacao_de_peso}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            adequacao_de_peso: event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='aig'
                      control={<Radio />}
                      label='AIG'
                    />
                    <FormControlLabel
                      value='cic'
                      control={<Radio />}
                      label='CIC'
                    />
                    <FormControlLabel
                      value='pig'
                      control={<Radio />}
                      label='PIG'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Prematuridade
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Parto_Periodo_Neonatal[0]?.prematuridade}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            prematuridade: event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='pre_termo'
                      control={<Radio />}
                      label='Pré-Termo'
                    />
                    <FormControlLabel
                      value='a_termo'
                      control={<Radio />}
                      label='A Termo'
                    />
                    <FormControlLabel
                      value='pos_termo'
                      control={<Radio />}
                      label='Pós-Termo'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Intercorrências peri e/ou pós natais com necessidade de
                    internaçao?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Parto_Periodo_Neonatal[0]
                        ?.intercorrencias_peri_pos_natais_internacao
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            intercorrencias_peri_pos_natais_internacao:
                              JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={findAllByDisplayValue}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Tempo'
                  variant='outlined'
                  value={
                    phono?.Parto_Periodo_Neonatal[0]
                      ?.tempo_peri_pos_natais_internacao
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          tempo_peri_pos_natais_internacao: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Amamentou?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Parto_Periodo_Neonatal[0]?.amamentou}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            amamentou: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Tempo'
                  variant='outlined'
                  value={phono?.Parto_Periodo_Neonatal[0]?.tempo_amamentou}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          tempo_amamentou: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Dificuldades Na Amamentação?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Parto_Periodo_Neonatal[0]
                        ?.dificuldades_na_amamentacao
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            dificuldades_na_amamentacao: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quais?'
                  variant='outlined'
                  value={
                    phono?.Parto_Periodo_Neonatal[0]
                      ?.quais_dificuldades_na_amamentacao
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          quais_dificuldades_na_amamentacao: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Fez Uso da Mamadeira?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Parto_Periodo_Neonatal[0]?.fez_uso_de_mamadeira
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            fez_uso_de_mamadeira: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Tempo'
                  variant='outlined'
                  value={
                    phono?.Parto_Periodo_Neonatal[0]?.tempo_uso_de_mamadeira
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          tempo_uso_de_mamadeira: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Fez Uso da Chupeta?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Parto_Periodo_Neonatal[0]?.fez_uso_de_chupeta}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            fez_uso_de_chupeta: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Tempo'
                  variant='outlined'
                  value={phono?.Parto_Periodo_Neonatal[0]?.tempo_uso_de_chupeta}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          tempo_uso_de_chupeta: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Hábitos Orais Deletérios?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Parto_Periodo_Neonatal[0]?.habitos_orais_deleterios
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Parto_Periodo_Neonatal: [
                          {
                            ...phono.Parto_Periodo_Neonatal[0],
                            habitos_orais_deleterios: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Tempo'
                  variant='outlined'
                  value={
                    phono?.Parto_Periodo_Neonatal[0]?.tempo_orais_deleterios
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          tempo_orais_deleterios: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Observação'
                  multiline
                  rows={4}
                  value={phono?.Parto_Periodo_Neonatal[0]?.observacao}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Parto_Periodo_Neonatal: [
                        {
                          ...phono.Parto_Periodo_Neonatal[0],
                          observacao: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* Sono */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleAccordionChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              ></span>
              Sono
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <FormControl>
                  {/* Multipla escolha? mudar dps */}
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Qualidade do Sono
                  </FormLabel>
                  <FormGroup aria-label='position' row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={phono?.Habito_Sono[0]?.dorme_bem}
                          onChange={(event) => {
                            setPhono({
                              ...phono,
                              Habito_Sono: [
                                {
                                  ...phono.Habito_Sono[0],
                                  dorme_bem: event.target.checked,
                                },
                              ],
                            });
                          }}
                        />
                      }
                      label='Dorme bem'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={phono?.Habito_Sono[0]?.dorme_de_luz_acesa}
                          onChange={(event) => {
                            setPhono({
                              ...phono,
                              Habito_Sono: [
                                {
                                  ...phono.Habito_Sono[0],
                                  dorme_de_luz_acesa: event.target.checked,
                                },
                              ],
                            });
                          }}
                        />
                      }
                      label='Dorme com luz acesa'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={phono?.Habito_Sono[0]?.baba_no_travesseiro}
                          onChange={(event) => {
                            setPhono({
                              ...phono,
                              Habito_Sono: [
                                {
                                  ...phono.Habito_Sono[0],
                                  baba_no_travesseiro: event.target.checked,
                                },
                              ],
                            });
                          }}
                        />
                      }
                      label='Baba no travesseiro'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={phono?.Habito_Sono[0]?.ronca}
                          onChange={(event) => {
                            setPhono({
                              ...phono,
                              Habito_Sono: [
                                {
                                  ...phono.Habito_Sono[0],
                                  ronca: event.target.checked,
                                },
                              ],
                            });
                          }}
                        />
                      }
                      label='Ronca'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={phono?.Habito_Sono[0]?.sono_agitado}
                          onChange={(event) => {
                            setPhono({
                              ...phono,
                              Habito_Sono: [
                                {
                                  ...phono.Habito_Sono[0],
                                  sono_agitado: event.target.checked,
                                },
                              ],
                            });
                          }}
                        />
                      }
                      label='Tem sono agitado'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={phono?.Habito_Sono[0]?.dorme_com_boca_aberta}
                          onChange={(event) => {
                            setPhono({
                              ...phono,
                              Habito_Sono: [
                                {
                                  ...phono.Habito_Sono[0],
                                  dorme_com_boca_aberta: event.target.checked,
                                },
                              ],
                            });
                          }}
                        />
                      }
                      label='Dorme com a boca aberta'
                      labelPlacement='end'
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* Desenvovimento Neuropsicomotor */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleAccordionChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              ></span>
              Desenvovimento Neuropsicomotor
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* deixar os campos de texto aparecendo apenas ao selecionar sim */}
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Sustentar a cabeça
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Desenvolvimento_Neuropsicomotor[0]
                        ?.sustentar_a_cabeca
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            sustentar_a_cabeca: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]
                      ?.quando_sustentar_a_cabeca
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_sustentar_a_cabeca: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Sentar sem apoio
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Desenvolvimento_Neuropsicomotor[0]
                        ?.sentar_sem_apoio
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            sentar_sem_apoio: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]
                      ?.quando_sentar_sem_apoio
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_sentar_sem_apoio: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Engatinhar
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Desenvolvimento_Neuropsicomotor[0]?.engatinhar
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            engatinhar: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]?.quando_engatinhar
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_engatinhar: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Andar
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Desenvolvimento_Neuropsicomotor[0]?.andar}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            andar: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]?.quando_andar
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_andar: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Controle de esfincter
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Desenvolvimento_Neuropsicomotor[0]
                        ?.controle_de_esfincter
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            controle_de_esfincter: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]
                      ?.quando_controle_de_esfincter
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_controle_de_esfincter: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Vocalizações
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Desenvolvimento_Neuropsicomotor[0]?.vocalizacoes
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            vocalizacoes: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]
                      ?.quando_vocalizacoes
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_vocalizacoes: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Balbuciar
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Desenvolvimento_Neuropsicomotor[0]?.balbuciar}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            balbuciar: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]?.quando_balbuciar
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_balbuciar: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Falar
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Desenvolvimento_Neuropsicomotor[0]?.falar}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            falar: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]?.quando_falar
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_falar: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Formar primeiras frases
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Desenvolvimento_Neuropsicomotor[0]
                        ?.formar_primeiras_frases
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Desenvolvimento_Neuropsicomotor: [
                          {
                            ...phono.Desenvolvimento_Neuropsicomotor[0],
                            formar_primeiras_frases: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando?'
                  variant='outlined'
                  value={
                    phono?.Desenvolvimento_Neuropsicomotor[0]
                      ?.quando_formar_primeiras_frases
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Desenvolvimento_Neuropsicomotor: [
                        {
                          ...phono.Desenvolvimento_Neuropsicomotor[0],
                          quando_formar_primeiras_frases: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* Percepção Visual e Auditiva */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleAccordionChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              ></span>
              Percepção Visual e Auditiva
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Identifica Cores?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Percepcao_Visual_Auditiva[0]?.identifica_cores
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            identifica_cores: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Acuidade Visual
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Percepcao_Visual_Auditiva[0]?.acuidade_visual}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            acuidade_visual: event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='adequada'
                      control={<Radio />}
                      label='Adequada'
                    />
                    <FormControlLabel
                      value='miopia'
                      control={<Radio />}
                      label='Miopia'
                    />
                    <FormControlLabel
                      value='hipermetropia'
                      control={<Radio />}
                      label='Hipermetropia'
                    />
                    <FormControlLabel
                      value='astigmatismo'
                      control={<Radio />}
                      label='Astigmatismo'
                    />
                    <FormControlLabel
                      value='outro'
                      control={<Radio />}
                      label='Outro'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Outro'
                  variant='outlined'
                  value={
                    phono?.Percepcao_Visual_Auditiva[0]?.outra_acuidade_visual
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Percepcao_Visual_Auditiva: [
                        {
                          ...phono.Percepcao_Visual_Auditiva[0],
                          outra_acuidade_visual: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Usa Lentes Corretivas
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Percepcao_Visual_Auditiva[0]?.uso_lentes_corretivas
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            uso_lentes_corretivas: event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='sim'
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value='nao'
                      control={<Radio />}
                      label='Não'
                    />
                    <FormControlLabel
                      value='ja_fez_uso'
                      control={<Radio />}
                      label='Ja Fez Uso'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Reação a Luz, Brilho e Cores'
                  multiline
                  rows={4}
                  value={phono?.Percepcao_Visual_Auditiva[0]?.reacao_luzes}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Percepcao_Visual_Auditiva: [
                        {
                          ...phono.Percepcao_Visual_Auditiva[0],
                          reacao_luzes: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Percebe Sons?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Percepcao_Visual_Auditiva[0]?.percebe_sons}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            percebe_sons: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Discrimina Sons?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={phono?.Percepcao_Visual_Auditiva[0]?.discrimina_sons}
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            discrimina_sons: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Compreende e Executa Um Comando?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Percepcao_Visual_Auditiva[0]
                        ?.compreende_executa_comandos
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            compreende_executa_comandos: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Latência Para a Compreensão e Execução?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Percepcao_Visual_Auditiva[0]
                        ?.latencia_compreensao_execucao
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            latencia_compreensao_execucao: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Ja Realizou Exames Auditivos
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Percepcao_Visual_Auditiva[0]
                        ?.realizou_exames_auditivos
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            realizou_exames_auditivos: JSON.parse(
                              event.target.value
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Resultado'
                  variant='outlined'
                  value={
                    phono?.Percepcao_Visual_Auditiva[0]
                      ?.resultados_exames_auditivos
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Percepcao_Visual_Auditiva: [
                        {
                          ...phono.Percepcao_Visual_Auditiva[0],
                          resultados_exames_auditivos: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Uso de AASI
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Percepcao_Visual_Auditiva[0]
                        ?.uso_aparelho_de_amplificacao_sonora_individual
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Percepcao_Visual_Auditiva: [
                          {
                            ...phono.Percepcao_Visual_Auditiva[0],
                            uso_aparelho_de_amplificacao_sonora_individual:
                              event.target.value,
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value='sim'
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value='nao'
                      control={<Radio />}
                      label='Não'
                    />
                    <FormControlLabel
                      value='ja_fez_uso'
                      control={<Radio />}
                      label='Ja Fez Uso'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Acompanhamento Especializado */}
      <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'panel9'}
          onChange={handleAccordionChange('panel9')}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>
              <span
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              ></span>
              Acompanhamento Especializado
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Já realizou / realiza?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Acompanhamento_Especializado[0]
                        ?.realizou_ou_realiza
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Acompanhamento_Especializado: [
                          {
                            ...phono.Acompanhamento_Especializado[0],
                            realizou_ou_realiza: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Local'
                  variant='outlined'
                  value={
                    phono?.Acompanhamento_Especializado[0]
                      ?.local_realizou_ou_realiza
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Acompanhamento_Especializado: [
                        {
                          ...phono.Acompanhamento_Especializado[0],
                          local_realizou_ou_realiza: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel id='demo-row-radio-buttons-group-label'>
                    Tem ido ao dentista
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      phono?.Acompanhamento_Especializado[0]?.ido_ao_dentista
                    }
                    onChange={(event) => {
                      setPhono({
                        ...phono,
                        Acompanhamento_Especializado: [
                          {
                            ...phono.Acompanhamento_Especializado[0],
                            ido_ao_dentista: JSON.parse(event.target.value),
                          },
                        ],
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Quando foi a ultima ida?'
                  variant='outlined'
                  value={phono?.Acompanhamento_Especializado[0]?.ultima_ida}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Acompanhamento_Especializado: [
                        {
                          ...phono.Acompanhamento_Especializado[0],
                          ultima_ida: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Desde quando?'
                  variant='outlined'
                  value={phono?.Acompanhamento_Especializado[0]?.desde_quando}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Acompanhamento_Especializado: [
                        {
                          ...phono.Acompanhamento_Especializado[0],
                          desde_quando: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel component='legend'>
                    Qual acompanhamento realizou?
                  </FormLabel>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              phono?.Acompanhamento_Especializado[0]
                                ?.fonoaudiologia
                            }
                            onChange={(event) => {
                              setPhono({
                                ...phono,
                                Acompanhamento_Especializado: [
                                  {
                                    ...phono.Acompanhamento_Especializado[0],
                                    fonoaudiologia: event.target.checked,
                                  },
                                ],
                              });
                            }}
                          />
                        }
                        label='Fonoaudiologia'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              phono?.Acompanhamento_Especializado[0]
                                ?.terapia_ocupacional
                            }
                            onChange={(event) => {
                              setPhono({
                                ...phono,
                                Acompanhamento_Especializado: [
                                  {
                                    ...phono.Acompanhamento_Especializado[0],
                                    terapia_ocupacional: event.target.checked,
                                  },
                                ],
                              });
                            }}
                          />
                        }
                        label='Terapia Ocupacional'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              phono?.Acompanhamento_Especializado[0]?.psicologia
                            }
                            onChange={(event) => {
                              setPhono({
                                ...phono,
                                Acompanhamento_Especializado: [
                                  {
                                    ...phono.Acompanhamento_Especializado[0],
                                    psicologia: event.target.checked,
                                  },
                                ],
                              });
                            }}
                          />
                        }
                        label='Psicologia'
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Observações relevantes'
                  multiline
                  rows={4}
                  value={phono?.Acompanhamento_Especializado[0]?.observacoes}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Acompanhamento_Especializado: [
                        {
                          ...phono.Acompanhamento_Especializado[0],
                          observacoes: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Condutas'
                  multiline
                  rows={4}
                  value={phono?.Acompanhamento_Especializado[0]?.condutas}
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Acompanhamento_Especializado: [
                        {
                          ...phono.Acompanhamento_Especializado[0],
                          condutas: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Encaminhamentos'
                  multiline
                  rows={4}
                  value={
                    phono?.Acompanhamento_Especializado[0]?.encaminhamentos
                  }
                  onChange={(event) => {
                    setPhono({
                      ...phono,
                      Acompanhamento_Especializado: [
                        {
                          ...phono.Acompanhamento_Especializado[0],
                          encaminhamentos: event.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default AnamnesisExtensionForm;
