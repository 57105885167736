import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import DataTable from "../DataTable";
import ApiService from "app/services/config";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";

function Unified() {
  const [unifications, setUnifications] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const { hasPermission } = useContext(PermissionContext);

  const getUnifications = useCallback(async () => {
    try {
      let apiUrl = `/unification?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          console.log(response.data);

          setUnifications(response.data?.unifications);
          setCount(response.data?.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  useEffect(() => {
    getUnifications();
  }, [getUnifications]);

  const columns = [
    {
      name: "id_pessoa_mantida",
      label: "ID Pessoa Mantida",
      options: {
        filter: true,
      },
    },
    {
      name: "pessoa_mantidade",
      label: "Nome",
      options: {
        enableNestedDataAccess: ".",
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome ?? "";
        },
      },
    },
    {
      name: "id_pessoa_unificada",
      label: "ID Pessoa Unificada",
      options: {
        filter: true,
      },
    },
    {
      name: "pessoa_unificada",
      label: "Nome",
      options: {
        enableNestedDataAccess: ".",
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome ?? "";
        },
      },
    },
    {
      name: "id",
      label: "ID Unificação",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Data da Unificação",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, update) => {
          return <>{moment(value).format("DD/MM/YYYY HH:mm:ss")}</>;
        },
      },
    },
    {
      name: "colaborador_que_criou_unificacao",
      label: "Colaborador Unificador",
      options: {
        enableNestedDataAccess: ".",
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.nome ?? "";
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <div>
      <Grid container>
        <Typography variant="h1" gutterBottom style={{ marginBottom: "15px" }}>
          Pessoas Unificadas
        </Typography>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <MUIDataTable
            columns={columns}
            data={unifications}
            options={options}
          />
        </Grid>
      </Grid>
      <br />

      <Link to={!hasPermission('Unificação', 'create') ? '/people/unified' : '/people/unification'}>
        <Button variant="contained" startIcon={<CallMergeIcon />} disabled={!hasPermission('Unificação', 'create')}>
          Unificar
        </Button>
      </Link>
    </div>
  );
}

export default Unified;
