import {config} from "../../../app/config/main";

export const storeToken = (token, username, email, id, storedPermissions, avatarUrl) => {
    localStorage.setItem('tokenMC', token);
    localStorage.setItem('username', username);
    localStorage.setItem('email', email);
    localStorage.setItem('id', id);
    localStorage.setItem('avatarUrl', avatarUrl);
    localStorage.setItem('storedPermissions', JSON.stringify(storedPermissions));
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        config.authSetting.axiosObject.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeToken = () => {
    localStorage.removeItem('tokenMC');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('avatarUrl');
    localStorage.removeItem('id');
    localStorage.removeItem('storedPermissions');
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        delete config.authSetting.axiosObject.defaults.headers.common['Authorization'];
};