import { Grid, Zoom } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";

import ReactInputMask from "react-input-mask";

import SearchIcon from "@mui/icons-material/Search";

import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ApiService from "app/services/config";

import dayjs from "dayjs";
import { PermissionContext } from "app/contexts/PermissionContext";

const Pacient = () => {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const { hasPermission } = useContext(PermissionContext);
  const [userSelected, setUserSelected] = useState(null);
  const [maeSelected, setMaeSelected] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [maeInput, setMaeInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMaeInput, setLoadingMaeInput] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [optionsMaeList, setOptionsMaeList] = useState([]);
  const [formattedTelefone, setFormattedTelefone] = useState("");
  const [clearFiltersKey, setClearFiltersKey] = useState(false);

  function redirectToEvolution(user_id) {
    localStorage.setItem("tabIndex", 5);
    return navigate(`/evolution/user/${user_id}/edit`, {
      state: {
        backUrl: "/pacient"
      }
    });
  }

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
    if (newInputValue !== "") {
      fetchPacientes(newInputValue);
    } else {
      setOptionsList([]);
      setUserSelected(null);
    }
  };

  const handleMaeInputChange = (event, newInputValue) => {
    setMaeInput(newInputValue);
    if (newInputValue !== "") {
      fetchUsers(newInputValue);
    } else {
      setOptionsMaeList([]);
      setUserSelected(null);
    }
  };

  const handleLimparFiltrosBusca = () => {
    setUserSelected(null);
    setUserInput("");
    setMaeSelected(null);
    setMaeInput("");
    setFormattedTelefone("");
    setClearFiltersKey(!clearFiltersKey);
  };

  const fetchPacientes = async (inputValue) => {
    setLoading(true);
    try {
      const response = await ApiService.get(
        `/users/all/patients?searchTerm=${inputValue}`
      );
      const users = response.data;
      setOptionsList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async (inputValue) => {
    setLoadingMaeInput(true);
    try {
      const response = await ApiService.get(`/users?searchTerm=${inputValue}`);
      const users = response.data.usersWithoutPassword;
      setOptionsMaeList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingMaeInput(false);
    }
  };

  const getPatients = async () => {
    try {
      let apiUrl = `/users/all/patients`;

      const params = {};
      if (userSelected?.id) {
        params.pacienteId = Number(userSelected.id);
      }
      if (formattedTelefone) {
        const soNumerosTelefoneSemDDD = formattedTelefone?.split(")")[1];
        params.telefone = soNumerosTelefoneSemDDD;
      }
      if (maeSelected?.id) {
        params.maeId = Number(maeSelected.id);
      }

      const queryString = new URLSearchParams(params).toString();
      if (queryString) {
        apiUrl += `?${queryString}`;
      }

      const response = await ApiService.get(apiUrl);
      setPatients(response.data);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  return (
    <div>
      <HeaderBreadcrumbs title={"Pacientes"} titleUrl={"/Pacient"} />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
        <Grid container sx={{
            width: "100%",
            p: "5px",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}>
          <Grid item>
            <Typography variant={"h2"}>Pesquisar</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} md={3}>
            <Tooltip title='Digite o nome para buscar a pessoa no sistema' TransitionComponent={Zoom} placement="top-end">
              <Autocomplete
                key={clearFiltersKey}
                fullWidth
                disablePortal
                getOptionLabel={(option) => option.nome}
                options={optionsList}
                loading={loading}
                inputValue={userInput}
                onInputChange={handleInputChange}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setUserSelected(newValue);
                  }
                }}
                noOptionsText={"Nenhum resultado encontrado"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Paciente"
                    placeholder="Paciente"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? "carregando..." : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={3}>
            <ReactInputMask
              mask="(99) 99999-9999"
              maskChar=""
              value={formattedTelefone}
              onChange={(e) => {
                setFormattedTelefone(e.target.value);
              }}
            >
              {(inputProps) => (
                <TextField
                  fullWidth
                  name="telefone"
                  label="Telefone"
                  sx={{ background: "#ffffff" }}
                  {...inputProps}
                />
              )}
            </ReactInputMask>
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              key={clearFiltersKey}
              fullWidth
              disablePortal
              getOptionLabel={(option) => option.nome}
              options={optionsMaeList}
              loading={loadingMaeInput}
              inputValue={maeInput}
              onInputChange={handleMaeInputChange}
              onChange={(event, newValue) => {
                if (newValue) {
                  setMaeSelected(newValue);
                }
              }}
              noOptionsText={"Nenhum resultado encontrado"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mãe"
                  placeholder="Mãe"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? "carregando..." : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="center"
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              style={{ marginTop: "10px" }}
              endIcon={<SearchIcon />}
              variant="contained"
              onClick={getPatients}
            >
              Buscar
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              style={{ marginTop: "10px" }}
              endIcon={<FilterAltOffIcon />}
              variant="outlined"
              onClick={() => {
                handleLimparFiltrosBusca();
              }}
            >
              Limpar filtros
            </Button>
          </Grid>
        </Grid>

        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} md={9}>
              <Typography
                variant="h2"
                gutterBottom
                style={{ marginBottom: "15px" }}
              >
                Pacientes Encontrados
              </Typography>
              <Grid
                container
                alignItems="center"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <List sx={{ Width: "100%", bgcolor: "background.paper" }}>
                    {patients.map((patient) => (
                      <Accordion key={patient.id}>
                        <AccordionSummary
                          style={{ fontSize: "16px" }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <PersonIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={patient?.nome}
                              secondary={
                                <Typography color="textSecondary">
                                  {patient?.data_de_nascimento && (
                                    <>
                                      {dayjs(
                                        patient?.data_de_nascimento
                                      ).format("DD/MM/YYYY")}
                                    </>
                                  )}
                                  <br />
                                  {patient?.endereco[0]?.logradouro}
                                  {patient?.endereco[0]?.numero
                                    ? " n. " + patient?.endereco[0]?.numero
                                    : null}
                                  {patient?.endereco[0]?.bairro
                                    ? ", " + patient?.endereco[0]?.bairro
                                    : null}
                                  {patient?.endereco[0]?.cidade
                                    ? ", " + patient?.endereco[0]?.cidade
                                    : null}
                                  {patient?.endereco[0]?.uf
                                    ? "/" + patient?.endereco[0]?.uf
                                    : null}
                                  {patient?.endereco[0]?.cep
                                    ? " - " + patient?.endereco[0]?.cep
                                    : null}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            rowSpacing={2}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={12} md={4}>
                              <Link
                                to={
                                  !hasPermission("Paciente Evolução", "update")
                                    ? "/Pacient"
                                    : `/people/edit/${patient.id}`
                                }
                                style={{ textDecoration: "none" }}
                                state={{backUrl: "/pacient"}}
                              >
                                <Button
                                  fullWidth
                                  style={{ marginTop: "10px" }}
                                  variant="outlined"
                                  disabled={
                                    !hasPermission(
                                      "Paciente Evolução",
                                      "update"
                                    )
                                  }
                                >
                                  Ficha Do Paciente
                                </Button>
                              </Link>
                            </Grid>
                            <Grid item xs={12}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">
                                      Prestador | Ficha
                                    </TableCell>
                                    <TableCell align="center">
                                      Último Atendimento
                                    </TableCell>
                                    <TableCell align="center">
                                      Último Agendamento
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {patient?.Agendamento.map((row) => (
                                    <TableRow
                                      key={row.id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell align="center">
                                        <Tooltip
                                          title="Visualizar"
                                          enterDelay={0}
                                        >
                                          <Button
                                            onClick={() =>
                                              redirectToEvolution(patient.id)
                                            }
                                            variant="outlined"
                                            disabled={
                                              !hasPermission(
                                                "Paciente Evolução",
                                                "update"
                                              )
                                            }
                                            style={{
                                              marginLeft: "5px",
                                              minWidth: "auto",
                                              paddingLeft: "20px",
                                              paddingRight: "20px",
                                            }}
                                          >
                                            <VisibilityIcon />
                                          </Button>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        {row?.Prestador?.nome}
                                      </TableCell>
                                      <TableCell align="center">
                                        {(() => {
                                          const evolucoesFiltradas = patient?.Evolucao.filter(
                                            (evolucao) =>
                                              evolucao.user_id === row.user_id &&
                                              evolucao.prestador_id === row.prestador_id
                                          );

                                          if (!evolucoesFiltradas || evolucoesFiltradas.length === 0) {
                                            return "-";
                                          }

                                          return evolucoesFiltradas.map((evolucao) => 
                                            dayjs(evolucao.data).format("DD/MM/YYYY")
                                          )
                                        })()}
                                      </TableCell>

                                      <TableCell align="center">
                                        {dayjs(row.data).format("DD/MM/YYYY") +
                                          " " +
                                          row.horario}
                                      </TableCell>
                                    </TableRow>
                                  ))}

                                  {patient?.Agendamento.length === 0 && (
                                    <TableRow
                                    key={1}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">
                                      {'-'}
                                    </TableCell>
                                    <TableCell align="center">
                                      {'-'}
                                    </TableCell>
                                    <TableCell align="center">
                                      {'-'}
                                    </TableCell>
                                  </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </List>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Pacient;
