import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import MUIDataTable from "mui-datatables";
import ApiService from "app/services/config";
import { useSnackbar } from "notistack";
import moment from "moment";
const ActiveOrInactivePatient = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [listaPacientes, setListaPacientes] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [statusPacientes, setStatusPacientes] = useState("");
  const [periodo, setPeriodo] = useState("");

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const columns = [
    {
      name: "nome",
      label: "Paciente",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Atendimentos_Feitos",
      label: "Último Atendimento",
      options: {
        enableNestedDataAccess: ".",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const ultimoAtendimento =
            value.length > 0
              ? value
                  .filter((atendimento) => atendimento.createdAt !== undefined && atendimento.createdAt !== null)
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((atendimento) => atendimento.createdAt)[0] || "N/I"
              : "N/I";
          return <>{ultimoAtendimento !== "N/I" ? moment(ultimoAtendimento).format("DD/MM/YYYY HH:mm:ss") : "N/I"}</>;
        },
      },
    },
    {
      name: "",
      label: "Convênio",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "telefone",
      label: "Telefones",
      options: {
        enableNestedDataAccess: ".",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const telefones =
            value.length > 0
              ? value.map((telefone) => telefone.numero + ", ")
              : "N/I";
          return <>{telefones}</>;
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  const getPacientes = async () => {
    try {
      let apiUrl = `/users/pacientes/ativos/inativos?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&isAtivo=${statusPacientes}&periodo=${periodo}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      await ApiService.get(apiUrl).then((response) => {
        if (response.status === 200) {
          if (!searchTerm) {
            enqueueSnackbar("Busca realizada!", {
              variant: "success",
              
            });
            
          }
          setListaPacientes(response.data.pacientes);
          setCount(response.data.total);
        }
      });
    } catch (error) {
      enqueueSnackbar("Erro ao realizar a busca!", {
        variant: "error",
        
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if(periodo === "" || statusPacientes === "") return;
    getPacientes();
  }, [page, perPage, sortBy, sortOrder, searchTerm]);

  return (
    <div>
      <HeaderBreadcrumbs
        title={"Relatórios"}
        subtitle={"Pacientes Ativos / Inativos"}
        titleUrl={"/ActiveOrInactivePatient"}
      />
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <Grid item>
          <Typography variant={"h2"}>
            Lista de pacientes por situação de atividade
          </Typography>
        </Grid>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={age}
                label="Age"
                //onChange={handleChange}
              >
                <MenuItem value={"pp"}>PP</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="periodo-label">Período</InputLabel>
              <Select
                labelId="periodo-label"
                id="demo-simple-select"
                value={periodo}
                label="Período"
                onChange={(event) => {
                  const periodo = event.target.value;
                  setPeriodo(periodo);
                }}
              >
                <MenuItem value={"40_dias"}>40 dias</MenuItem>
                <MenuItem value={"120_dias"}>120 dias</MenuItem>
                <MenuItem value={"1_ano"}>1 ano</MenuItem>
                <MenuItem value={"2_anos"}>2 anos</MenuItem>
                <MenuItem value={"3_anos"}>3 anos</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} textAlign={"center"}>
            <FormControl>
              <FormLabel id="situacao-label">Situação</FormLabel>
              <RadioGroup
                row
                aria-labelledby="situacao-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setStatusPacientes(true);
                    }
                  }}
                  label="Ativo"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setStatusPacientes(false);
                    }
                  }}
                  label="Inativo"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              endIcon={<SearchIcon />}
              variant="contained"
              disabled={periodo === "" || statusPacientes === ""}
              onClick={getPacientes}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Divider
          style={{ height: "100%", marginBottom: "20px", marginTop: "20px" }}
        />
      </Paper>
      <Paper
        style={{
          padding: "10px",
          marginBottom: "15px",
          overflowX: "auto",
          overflowY: "auto",
        }}
        square={false}
        elevation={3}
      >
        <MUIDataTable
          title={[
            `Pacientes ${
              statusPacientes === "ativo" ? "ativos" : "inativos"
            } no período de PERÍODO`,
          ]}
          data={listaPacientes}
          columns={columns}
          options={options}
          key={forceUpdate ? "force-update" : "normal"}
        />
      </Paper>
    </div>
  );
};

export default ActiveOrInactivePatient;
